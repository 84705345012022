import React from "react";
import { Row, Col } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { getDownloadPreSignedUrl, fetchNmscQueLibraryLink } from '../../../actions/libraryAction';
import ViewLibFolderModal from './viewLibFolderModal';
import '../index.css';

class LibraryLink extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            linkDetails: [],
            linkFiles: [],
            linkFolders: [],
            linkFileDetails: [],
            linkFolderDetails: [],
            filesAndFolders: [],
            selectedFolder: '',
            checkedFiles: [],
            viewLibraryModal: false
        }
        this.fileNameFormatter = this.fileNameFormatter.bind(this);
        this.viewLibraryModal = this.viewLibraryModal.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ linkDetails: [], linkFiles: [], linkFileDetails: [], linkFolderDetails: [], filesAndFolders: [] });
        let fileLinkIds = [];
        let folderLinkIds = [];
        if (nextProps.libraryReducer.queLibLinks) {
            const links = [...nextProps.libraryReducer.queLibLinks];
            links.map((link) => {
                if (link.fileId !== 0) {
                    fileLinkIds.push(link.fileId);
                } else {
                    folderLinkIds.push(link.folderId);
                }
                return null;
            });
            this.setState({ linkDetails: links, linkFiles: fileLinkIds, linkFolders: folderLinkIds });
        }
        if (nextProps.libraryReducer.queLibFiles) {
            const files = [...nextProps.libraryReducer.queLibFiles];
            let fileIds = [];
            files.map((file) => {
                fileIds.push(file.fileId);
                return null;
            })
            if (JSON.stringify(fileLinkIds) === JSON.stringify(fileIds)) {
                this.setState({ linkFileDetails: files });
            }
            else {
                this.setState({ linkFileDetails: [] });
            }
        }
        if (nextProps.libraryReducer.queLibFolders) {
            let folders = [...nextProps.libraryReducer.queLibFolders];
            let folderIds = [];
            folders.map((folder) => {
                folder.fileName = folder.name;
                folder.fileType = "DIR";
                folderIds.push(folder.id);
                return null;
            })
            if (JSON.stringify(folderLinkIds) === JSON.stringify(folderIds)) {
                this.setState({ linkFolderDetails: folders });
            }
            else {
                this.setState({ linkFolderDetails: [] });
            }
        }
        if (nextProps.checkedFiles) {
            this.setState({ checkedFiles: nextProps.checkedFiles });
        }
    }
    componentWillMount() {
        if(this.props.questionId !== ''){
            this.props.dispatch(fetchNmscQueLibraryLink(this.props.questionId));
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.questionId !== this.props.questionId && this.props.questionId !== '') {
            this.props.dispatch(fetchNmscQueLibraryLink(this.props.questionId));
        }
    }
    viewLibraryModal = (row) => {
        this.setState({ selectedFolder: row.id, viewLibraryModal: !this.state.viewLibraryModal, modalFor: "Add" });
    }
    downloadFile = (fileId, fileType) => {
        if (fileType !== 'DIR') {
            // alert("Download File with Id: " + fileId + " and file type: " + fileType);
            this.props.dispatch(getDownloadPreSignedUrl(fileId, false));

        }
    }
    
    fileNameFormatter(row) {
        let icon = 'images/folder.png';
        if (row.fileType === 'doc' || row.fileType === 'docx') {
            icon = 'images/word.png';
        }
        if (row.fileType === 'xls' || row.fileType === 'xlsx') {
            icon = 'images/excel.png';
        }
        if (row.fileType === 'pdf') {
            icon = 'images/pdf.png';
        }
        if (row.fileType === 'jpg' || row.fileType === 'jpeg' || row.fileType === 'gif' || row.fileType === 'bmp'
        || row.fileType === 'png' || row.fileType === 'tif') {
            icon = 'images/image-icon.png';
        }

        if (row.fileType === 'ppt' || row.fileType === 'pptx') {
            icon = 'images/ppt-icon.png';
        }

        if (row.fileType === 'avi' || row.fileType === 'flv' || row.fileType === 'wmv' || row.fileType === 'mov' || row.fileType === 'mp4') {
            icon = 'images/video-icon.png';
        }
        if (row.fileType === 'txt') {
            icon = 'images/text.png';
        }
        return (
            <div style={{ cursor: 'pointer', paddingRight: '3px' }}>
                <input type="checkbox" style={{ marginRight: '1.5rem' }} onChange={() => this.selectFiles(row)} />
                <img src={icon} height="20" width="20" style={{ marginRight: '4px' }} alt=""/>
                {row.fileType === "DIR" ?
                    <span onClick={() => { this.viewLibraryModal(row) }}>{row.fileName}</span>
                    :
                    <span onClick={() => this.downloadFile(row.fileId, row.fileType)}>{row.fileName}</span>
                }
            </div>
        );
    }

    render() {
        const fileDetails = this.state.linkFileDetails;
        const folderDetails = this.state.linkFolderDetails;
        return (
            <Row className="">
                <Col xs="12" sm="12" md="12" lg="12">
                    <ul className="TrailUL">
                        {fileDetails.map((file) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(file)}</li>
                        })}
                        {folderDetails.map((folder) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(folder)}</li>
                        })}
                    </ul>
                </Col>
                <div>
                    {this.state.viewLibraryModal === true ?
                        <ViewLibFolderModal
                            mainModal={this.state.viewLibraryModal}
                            addMainQuestion={this.viewLibraryModal}
                            folderId={this.state.selectedFolder}
                            modalFor={this.state.modalFor}
                            questionId={this.props.questionId}
                            versionId={this.props.versionId}
                            isNMSCAdminUser={this.state.isNMSCAdminUser}
                            isNotAdmin={this.state.isNotAdmin}
                        /> : null}
                </div>
            </Row>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(LibraryLink)));
