import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { getDownloadPreSignedUrl, fetchQueLibraryLink, fetchNmscQueLibraryLink, fetchNmscSubQueLibraryLink } from '../../../actions/libraryAction';
import ViewLibFolderModal from './viewLibFolderModal';
import '../index.css';

class LibraryLink extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            linkDetails: [],
            linkFiles: [],
            linkFolders: [],
            linkFileDetails: [],
            linkFolderDetails: [],
            filesAndFolders: [],
            selectedFolder: '',
            checkedFiles: [],
            viewLibraryModal: false,
            subLinkFileDetails: [],
            subLinkFolderDetails: []
        }
        this.fileNameFormatter = this.fileNameFormatter.bind(this);
        this.viewLibraryModal = this.viewLibraryModal.bind(this);
        this.selectFiles = this.selectFiles.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        this.setState({ linkDetails: [], linkFiles: [], linkFileDetails: [], linkFolderDetails: [], filesAndFolders: [] });
        let fileLinkIds = [];
        let folderLinkIds = [];
        if (nextProps.libraryReducer.queLibLinks) {
            const links = [...nextProps.libraryReducer.queLibLinks];
            links.map((link) => {
                if (link.fileId !== 0) {
                    fileLinkIds.push(link.fileId);
                } else {
                    folderLinkIds.push(link.folderId);
                }
            });
            this.setState({ linkDetails: links, linkFiles: fileLinkIds, linkFolders: folderLinkIds });
        }
        if (nextProps.libraryReducer.queLibFiles) {
            const files = [...nextProps.libraryReducer.queLibFiles];
            let fileIds = [];
            files.map((file) => {
                fileIds.push(file.fileId);
            })
            if (JSON.stringify(fileLinkIds) === JSON.stringify(fileIds)) {
                this.setState({ linkFileDetails: files });
            }
            else {
                this.setState({ linkFileDetails: [] });
            }
        }
        if (nextProps.libraryReducer.queLibFolders) {
            let folders = [...nextProps.libraryReducer.queLibFolders];
            let folderIds = [];
            folders.map((folder) => {
                folder.fileName = folder.name;
                folder.fileType = "DIR";
                folderIds.push(folder.id);
            })
            if (JSON.stringify(folderLinkIds) === JSON.stringify(folderIds)) {
                this.setState({ linkFolderDetails: folders });
            }
            else {
                this.setState({ linkFolderDetails: [] });
            }
        }

        // for sub question
        if (nextProps.libraryReducer.subQueLibLinks) {
            const links = [...nextProps.libraryReducer.subQueLibLinks];
            links.map((link) => {
                if (link.fileId !== 0) {
                    fileLinkIds.push(link.fileId);
                } else {
                    folderLinkIds.push(link.folderId);
                }
            });
            this.setState({ linkDetails: links, linkFiles: fileLinkIds, linkFolders: folderLinkIds });
        }
        if (nextProps.libraryReducer.subQueLibFiles) {
            const files = [...nextProps.libraryReducer.subQueLibFiles];
            let fileIds = [];
            files.map((file) => {
                fileIds.push(file.fileId);
            })
            let commonIds = fileLinkIds.filter(id => fileIds.includes(id));
            
            if (commonIds?.length > 0) {
                this.setState({ subLinkFileDetails: files });
            }
            else {
                this.setState({ subLinkFileDetails: [] });
            }
        }
        if (nextProps.libraryReducer.subQueLibFolders) {
            let folders = [...nextProps.libraryReducer.subQueLibFolders];
            let folderIds = [];
            folders.map((folder) => {
                folder.fileName = folder.name;
                folder.fileType = "DIR";
                folderIds.push(folder.id);
            })
            if (JSON.stringify(folderLinkIds) === JSON.stringify(folderIds)) {
                this.setState({ subLinkFolderDetails: folders });
            }
            else {
                this.setState({ subLinkFolderDetails: [] });
            }
        }
        if (nextProps.checkedFiles) {
            this.setState({ checkedFiles: nextProps.checkedFiles });
        }
    }
    componentWillMount() {

        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isNotAdmin = false;
                    isNMSCAdminUser = true;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;

            }
        }

        this.setState({isNMSCAdminUser:isNMSCAdminUser});
        this.setState({isNotAdmin:isNotAdmin});
        if (this.props.isAttachment) {
            this.props.dispatch(fetchNmscSubQueLibraryLink(this.props.subQuestionId));
        } else {
            if (isNMSCAdminUser || isNotAdmin) {
                this.props.dispatch(fetchNmscQueLibraryLink(this.props.questionId));
            } else {
                this.props.dispatch(fetchQueLibraryLink(this.props.questionId));
            }
        }
        

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.questionId !== this.props.questionId) {
            if (this.state.isNMSCAdminUser || this.state.isNotAdmin) {
                this.props.dispatch(fetchNmscQueLibraryLink(this.props.questionId));
            }
            else {
                this.props.dispatch(fetchQueLibraryLink(this.props.questionId));
            }
        }
        //for subQuestions
        if (prevProps.subQuestionId !== this.props.subQuestionId) {
            if(this.props.isAttachment){
                this.props.dispatch(fetchNmscSubQueLibraryLink(this.props.subQuestionId));
            }
        }
        /** Old code, if file selected in Question automatically checked in subQuestion */
        // if (prevProps.checkedFiles !== this.props.checkedFiles) {
        //     this.setState({ checkedFiles: this.props.checkedFiles });
        // }

        // if (prevState.linkDetails !== this.state.linkDetails) {
        //     this.props.saveLibraryLink(this.state.linkDetails);
        // }
    }
    viewLibraryModal = (row) => {
        this.setState({ selectedFolder: row.id, viewLibraryModal: !this.state.viewLibraryModal, modalFor: "Add" });
    }
    downloadFile = (fileId, fileType) => {
        if (fileType !== 'DIR') {
            // alert("Download File with Id: " + fileId + " and file type: " + fileType);
            if (this.state.isNMSCAdminUser || this.state.isNotAdmin) {
                this.props.dispatch(getDownloadPreSignedUrl(fileId, false));
            }
            else {
                this.props.dispatch(getDownloadPreSignedUrl(fileId, true));
            }

        }
    }
    selectFiles(row) {
        let checkedFile = [];  // Initialize an empty array
        checkedFile.push(...checkedFile, row.fileId); 
       // this.props.dispatch(getFiles(row.fileId));

        
        let checkFile = [...this.state.checkedFiles];
        let linkDetails = [...this.state.linkDetails];
        
        let index = 9999;
        let linkId = 9999;
        if (row.fileType === "DIR") {
            console.log(row);
            linkDetails.map((file) => {
                if (file.folderId === row.id && linkId === 9999) {
                    linkId = file.linkId;
                }
            })
            checkFile.map((file) => {
                if (file === linkId && index === 9999) {
                    index = checkFile.indexOf(file);
                }
            })
        } else {
            linkDetails.map((file) => {
                if (file.fileId === row.fileId && linkId === 9999) {
                    linkId = file.linkId;
                }
            })
            checkFile.map((file) => {
                if (file === linkId && index === 9999) {
                    index = checkFile.indexOf(file);

                }
            })
        }
        if (index !== 9999) {
            checkFile.splice(index, 1);
        } else {
            checkFile.push(linkId)
        }

        this.setState({ checkedFiles: checkFile });
        this.props.selectLibraryLinks(checkFile);
    }
    fileNameFormatter(row) {
        let icon = 'images/folder.png';
        if (row.fileType === 'doc' || row.fileType === 'docx') {
            icon = 'images/word.png';
        }
        if (row.fileType === 'xls' || row.fileType === 'xlsx') {
            icon = 'images/excel.png';
        }
        if (row.fileType === 'pdf') {
            icon = 'images/pdf.png';
        }
        if (row.fileType === 'jpg' || row.fileType === 'jpeg' || row.fileType === 'gif' || row.fileType === 'bmp'
        || row.fileType === 'png' || row.fileType === 'tif') {
            icon = 'images/image-icon.png';
        }

        if (row.fileType === 'ppt' || row.fileType === 'pptx') {
            icon = 'images/ppt-icon.png';
        }

        if (row.fileType === 'avi' || row.fileType === 'flv' || row.fileType === 'wmv' || row.fileType === 'mov' || row.fileType === 'mp4') {
            icon = 'images/video-icon.png';
        }
        if (row.fileType === 'txt') {
            icon = 'images/text.png';
        }
        return (
            <div style={{ cursor: 'pointer', paddingRight: '3px' }}>
               {!this.props.isAttachmentSub && <input type="checkbox" style={{ marginRight: '1.5rem' }} onChange={() => this.selectFiles(row)} />}
                <img src={icon}  height={this.props.isAttachmentSub ? 40 : 20} width={this.props.isAttachmentSub ? 40 : 20}  style={{ marginRight: '4px' }} alt=""/>
                {row.fileType === "DIR" ?
                    <span onClick={() => { this.viewLibraryModal(row) }}>{row.fileName}</span>
                    :
                    <span onClick={() => this.downloadFile(row.fileId, row.fileType)}>{row.fileName}</span>
                }
            </div>
        );
    }

    render() {
        const fileDetails = this.state.linkFileDetails;
        const folderDetails = this.state.linkFolderDetails;
        // for suQuestion
        const subFileDetails = this.state.subLinkFileDetails;
        const subfolderDetails = this.state.subLinkFolderDetails;
        return (
            <Row className="">
                {this.props.isAttachment ?<Col xs="12" sm="12" md="12" lg="12">
                    {this.props.subQueLibLinks && this.props.subQueLibLinks.length > 0 ? <ul className="TrailUL">
                        {subFileDetails.map((file) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(file)}</li>
                        })}
                        {subfolderDetails.map((folder) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(folder)}</li>
                        })}
                    </ul> :<h6 style={{ textAlign: 'center' }}>No Attachments Available !</h6>}
                </Col> :<Col xs="12" sm="12" md="12" lg="12">
                    <ul className="TrailUL">
                        {fileDetails.map((file) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(file)}</li>
                        })}
                        {folderDetails.map((folder) => {
                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(folder)}</li>
                        })}
                    </ul>
                </Col>}
                <div>
                    {this.state.viewLibraryModal === true ?
                        <ViewLibFolderModal
                            mainModal={this.state.viewLibraryModal}
                            addMainQuestion={this.viewLibraryModal}
                            folderId={this.state.selectedFolder}
                            modalFor={this.state.modalFor}
                            isNMSCAdminUser={this.state.isNMSCAdminUser}
                            isNotAdmin={this.state.isNotAdmin}
                        /> : null}
                </div>
            </Row>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        libraryReducer: state.libraryReducer,
        subQueLibLinks: state.libraryReducer.subQueLibLinks
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(LibraryLink)));
