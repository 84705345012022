import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Col, Fa } from "mdbreact";
import { withTheme } from '../../../themes/theming';
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import '../index.css';
import { toastr } from 'react-redux-toastr';
import * as AppConst from '../../../AppConstant';
import EvaluationSerach from "./evaluationSerach";
import { checkForPendingParameters, launchTargetActivity,closeEvaluationCheck,closeEvaluation, launchEvaluationsUpdate } from '../../../actions/activityAction'
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery')
window.jQuery = $;

class MainUserContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      isSearchClicked: false,
      modal: false,
      modalFor: "",
      showSearchPanel: true,

      activityDto: {
        nmscCodeList: [],
        selectedNmscCodeList : [],
        businessFunctionList : [],
        selectedBusinessFunction: [],
        statusList:[],
        userId: this.props.user.userMdl.username,
        userNmscCode : this.props.user.userMdl.nmsc,
        ceaYear: this.props.user.userMdl.ceaDefaultYears.value,
        programID: this.props.user.userMdl.activeProgramID,
        program: this.props.user.userMdl.activeProgram,
        loggedInUser: this.props.user.userMdl.username,
        rows: 10,
        page: 1,
        sizePerPage: 10,
        businessOwner:false,
        activeProgramName : this.props.user.userMdl.activeProgramName

    },
    actionDTO :{
      businessOwner: this.props.user.userMdl.username, 
      activeProgramID: this.props.user.userMdl.activeProgramID,
      ceaYear: this.props.user.userMdl.ceaDefaultYears.value
    },
      data: [],
      records: 0,
      total: 0,
      sortField: null,
      sortOrder: "asc"
    };
  }

  componentWillMount() {
    let activityDto = {...this.state.activityDto}
    if(this.props.user.userMdl.nmsc){
      if(this.props.user.userMdl.nmsc.toUpperCase() !== 'TME' && this.props.user.userMdl.nmsc.toUpperCase() !== 'TMME'){
        activityDto["nmscCodeList"] = [this.props.user.userMdl.nmsc]
      }
    }
    if (this.props.location.query !== undefined && 
      this.props.location.query.activitySearchDto !== undefined) {
        activityDto = this.props.location.query.activitySearchDto;        
        this.toggleSearch()       
    }
    this.setState({ activityDto }, () =>this.props.dispatch(launchTargetActivity(activityDto)))  
    this.props.dispatch(closeEvaluationCheck(this.state.actionDTO,this.props.user.userMdl.activeProgram))

  }
  componentWillUnmount() {
  }
  updateSearchDto = (userDTO) => {
  }

  closePopUP =(result)=>{
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
          return (
              <div className={"closeConfirmBox-" + this.props.theme.name}>
                  <div className="closeConfirmBoxTitle">
                      {I18n.t('standardMessages.warning')}
                  </div>
                  <div className="closeConfirmBoxBody">
                      {result ? 
                          I18n.t("ceaEvaluation.pendingParameterMsg")+I18n.t("ceaEvaluation.closeEvaluationConfirmMsg"):I18n.t("ceaEvaluation.closeEvaluationConfirmMsg")
                      }
                  </div>
                  <div className="closeConfirmBoxFooter" style={{ borderTop: "0px" }}>
                      <button
                          className={"button-" + this.props.theme.name}
                          onClick={()=>{
                            this.props.dispatch(this.closeEvaluation)
                            onClose();
                          }
                          }>
                          {I18n.t("standardMessages.yes")}
                      </button>
                      <button className={"buttonCancel-" + this.props.theme.name} onClick={() => {
                          onClose()
                          }}>
                          {I18n.t("standardMessages.no")}
                      </button>
                  </div>
              </div>
          );
      }
  });
   }

  

  search = (activityDTO) => {
    this.setState({ activityDto: activityDTO });
    this.setState({
      showSearchPanel: true,
      //isSearchClicked: true,
      sortField: null
    });
  this.props.dispatch(launchTargetActivity(activityDTO))

  };

  selectedTab = (index, label) => {
    this.setState({ selectedTab: index });
  };
  toggleSearch = toggle => {
    this.setState({
      showSearchFlag: !this.state.showSearchFlag
    });
  };

  toggleModal = action => {
    this.setState({ modal: !this.state.modal })
  };



  buttonFormatter = (cell, row) => {
    let linkIcon = 'eye';
    return (
      <div className="btn-toolbar float-right">
        {row.status.toUpperCase()!=="DRAFT"?
        <a
        onClick={() => this.dispatchActivity(row)}
        >
          <Fa icon={linkIcon} fixed size="lg" title={I18n.t("ceaEvaluation.view")} />
        </a>:""}
      </div>
    );
  };

  dispatchActivity = (row) => {
    this.props.dispatch(
      push({
        pathname:"/kpiActuals",
        query: { selectedActivity:JSON.stringify(row), searchCriteria: this.state.activityDto }
      })
    );
  }

  pageChanged(page, sizePerPage) {
    const activityDto = { ...this.state.activityDto };
    activityDto["page"] = page;
    activityDto["rows"] = sizePerPage;
    activityDto["sizePerPage"] = sizePerPage;
    this.setState({ activityDto }, () => this.props.dispatch(launchTargetActivity(this.state.activityDto)))
  }


  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    const activityDto = { ...this.state.activityDto };
    activityDto["page"] = page;
    activityDto["rows"] = sizePerPage;
    activityDto["sizePerPage"] = sizePerPage;
    if (sortField) {
      if (activityDto.sortOrder === "desc" && sortOrder === "desc") {
        activityDto["sortOrder"] = "asc";
      } else {
        activityDto["sortOrder"] = sortOrder;
      }
      activityDto["sortCol"] = this.indexOfSortField(sortField);
    }

    this.setState({ activityDto }, () => this.props.dispatch(launchTargetActivity(this.state.activityDto)))
  };

  launchEvaluation =()=>{
  if(this.props.activityEvalutionsList && this.props.activityEvalutionsList.statusCode !== 400){
    this.props.dispatch(launchEvaluationsUpdate(this.props.activityEvalutionsList.draftActivitiesList,this.state.activityDto,this.state.actionDTO,this.state.activityStatusList,this.search,this.props.user.userMdl.activeProgram))
  }else if(this.props.activityEvalutionsList && this.props.activityEvalutionsList.statusCode === 400){
    console.log("this.props.activityEvalutionsList",this.props.activityEvalutionsList)
    let desc = this.props.activityEvalutionsList.desc
    if(desc === "some_business_function_not_launched" && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
      desc = "some_business_function_not_launched_net";
    }
    let previousYear = Number(this.state.actionDTO.ceaYear)-1 
    switch(desc){
        case "no_business_function_with_points" : return toastr.error(I18n.t("ceaEvaluation."+desc))
                                                  
        case "some_business_function_not_launched" : return toastr.error(I18n.t("ceaEvaluation."+desc)+this.state.activityDto.activeProgramName)

        case "some_business_function_not_launched_net" : return toastr.error(I18n.t("ceaEvaluation."+desc)+this.state.activityDto.activeProgramName)
                                        
        case "kpi_not_target_accepted" : return toastr.error(I18n.t("ceaEvaluation."+desc)+this.state.activityDto.activeProgramName)
                                      
        case "previous_year_evaluation_not_completed" : return toastr.error(I18n.t("ceaEvaluation."+desc) + previousYear)
                                                 
        default : return toastr.error(I18n.t("ceaEvaluation."+desc))
    
    }
  }
  }

  closeEvaluationPendingParamCheck =()=>{
    this.props.dispatch(checkForPendingParameters(this.state.ongoingActivitiesList,this.closePopUP))
  }


  closeEvaluation = ()=>{
    this.props.dispatch(closeEvaluation(this.state.ongoingActivitiesList,this.state.activityDto,this.state.actionDTO,this.search,this.state.activityStatusList))
  }

  indexOfSortField(sortField) {
    switch (sortField) {
      case "nmscCode":
        return "NMSC";
      case "toolbox":
        return "TOOLBOX";
      case "status":
        return "STATUS";
      case "ceaYear":
        return "CEA_YEAR";
      default:
        return "NMSC";
    }
  }

  componentWillReceiveProps(nextProps) {
    if(nextProps.activityCEAList !== this.props.activityCEAList && nextProps.activityCEAList!==null && nextProps.activityCEAList!==undefined ){

      if(nextProps.activityCEAList.records >0){
        this.setState({data : nextProps.activityCEAList.activityDTOList});
        this.setState({ records: nextProps.activityCEAList.records });
        this.setState({ total: nextProps.activityCEAList.total});
        let activityDTO = {...this.state.activityDto}
        activityDTO["page"] = nextProps.activityCEAList.page
        this.setState({activityDto : activityDTO })
      }
      else{
        this.setState({data : []})
        this.setState({ records: 0 });
        this.setState({ total: 0 });
      }
  }
  if(nextProps.closeEvaluation !== this.props.closeEvaluation && nextProps.closeEvaluation !== null && nextProps.closeEvaluation !== undefined  ){
    this.setState({
      closeEvaluation: nextProps.closeEvaluation.closeEvaluation,
      launchEvaluation : nextProps.closeEvaluation.launchEvaluation,
      ongoingActivitiesList : nextProps.closeEvaluation.ongoingActivitiesList
    })
  }

  if(nextProps.evaluationFilter.activityStatusList !== this.props.evaluationFilter.activityStatusList && nextProps.evaluationFilter.activityStatusList !== undefined ){
    this.setState({
      activityStatusList : nextProps.evaluationFilter.activityStatusList
    })
  }
}

businessFunctionFormatter=(cell,row)=>{
  return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.toolbox}</div>
  );
}

  render() {

    const columns = [
      {
        dataField: "nmscCode",
        text: I18n.t("ceaEvaluation.nmsc"),
        sort: true,
        style: { width: "20%", textAlign: "left" },
        headerStyle: { width: "20%", textAlign: "left" },
        // title: true
      },
      {
        dataField: "toolbox",
        text: I18n.t("kpiEntry.businessFunc"),
        sort: true,
        style: { width: "30%", textAlign: "left", paddingRight:"0rem" },
        headerStyle: { width: "30%", textAlign: "left",paddingRight:"0rem" },
        formatter: this.businessFunctionFormatter,
        title: true
      },
      {
        dataField: "status",
        text: I18n.t("kpiEntry.status"),
        sort: true,
        style: { width: "15%", textAlign: "left",paddingLeft:"1rem"},
        headerStyle: { width: "15%", textAlign: "left",paddingLeft:"0.5rem"},
        title: true
      },
      {
        dataField: "ceaYear",
        //text: I18n.t("Table.toolbox"),
        text: I18n.t("ceaEvaluation.evaluationYear"),
        sort: true,
        style: { width: "15%", textAlign: "center" },
        headerStyle: { width: "15%", textAlign: "center" },
        title: true
      },
      {
        dataField: "",
        text: "",
        formatter: this.buttonFormatter,
        style: { width: "15%" },
        headerStyle: { width: "15%" }
      }
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
      }
    };

    const {data} = this.state;
    const totalSize = this.state.records
    const {sizePerPage, page}  = this.state.activityDto

    let ceaNetAdmin = this.props.user.userMdl.roles.includes("KET-CEA Admin") || this.props.user.userMdl.roles.includes("KET-NET Admin")|| this.props.user.userMdl.roles.includes("KET-LEXUS Admin")

    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize
    }) => (
      <div className={'activityTable parent-' + this.props.theme.name}>
        <BootstrapTable
          remote
          keyField="activityId"
          noDataIndication={I18n.t('ceaEvaluation.noEvaluationsFound')}
          data={data}
          columns={columns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
        />
      </div>
    );

    return (
      <Container className="mt-5 b-1 activityContainer">
        <Col md="12" className="activityTableContainer">
          <div className="activity-content">
            {/* <form className="form-horizontal"> */}
            <div className="col-md-12 row control-label noPadding">
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                {I18n.t("ceaEvaluation.search")}
              </label>
              <label
                md="0.5"
                className="control-label boldFont"
                style={{ marginTop: "0.2rem" }}
              >
                <a onClick={this.toggleSearch}>
                  {this.state.showSearchFlag ? (
                    <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                  ) : (
                    <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                  )}
                </a>
              </label>
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'} style={{ width: "20%" }}>
                {I18n.t("ceaEvaluation.evaluations")} ({totalSize})
              </label>
              <label className="control-label" style={{ width: "56%" }} />
              <div className="col-md-2">
              {(ceaNetAdmin && this.props.user.userMdl.activeProgram !== AppConst.activeProgramUc && this.props.user.userMdl.activeProgram !== AppConst.activeProgramLN)? this.state.launchEvaluation?
                <button className={"launchSettingButton button-" + this.props.theme.name}
                onClick={this.launchEvaluation}
                style = {{float : 'right' }}
                > {I18n.t('ceaEvaluation.launchEvaluation')} </button>
                :
                this.state.closeEvaluation?
                <button className={"launchSettingButton button-" + this.props.theme.name}
                onClick={this.closeEvaluationPendingParamCheck}
                title={I18n.t("ceaEvaluation.closeEvaluationMsg")}
                style = {{float : 'right' }}
                > {I18n.t('ceaEvaluation.closeEvaluation')} </button>
               :""
                : ""}
                </div>
              <EvaluationSerach
                flag={this.state.showSearchFlag}
                activityDto={this.state.activityDto}
                search ={this.search}
                // update = {this.updateSearchDto}
              />
              <div className="col-md-12 searchActivityTable">
                <RemotePagination
                  data={data}
                  page={page}
                  sizePerPage={sizePerPage}
                  totalSize={totalSize}
                  onTableChange={this.handleTableChange}
                />
              </div>
            </div>
          </div>
        </Col>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    activityCEAList: state.activityReducer.activityCEAList,
    activityEvalutionsList: state.activityReducer.activityEvalutionsList,
    closeEvaluation : state.activityReducer.closeEvaluation,
    evaluationFilter : state.activityReducer.evaluationFilter
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(MainUserContainer)));
