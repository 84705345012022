import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { withTheme } from '../../themes/theming';
import './index.css';


//==========================Components Import
import MainUserContainer from './components/mainUserContainer.jsx';


class LaunchTargetScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    componentWillUpdate(nextProps) {

    }

    render() {
        return (
            <div>
                <MainUserContainer
                    userMdl={this.props.user.userMdl} />
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(LaunchTargetScreen)));