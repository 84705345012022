import React from "react";
import { Row } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { fetchFileDtls, fetchFileStatus, deleteFile, getDownloadPreSignedUrl, fetchNmscFileDtls, fetchNmscFileStatus, fetchNmscPublishedFileDtls, deleteNmscFile } from '../../../actions/libraryAction';
import BootstrapTable from 'react-bootstrap-table-next';
import { confirmAlert } from 'react-confirm-alert'; // Import
var I18n = require("react-redux-i18n").I18n;
class LibraryDetails extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            fileList: [],
            fileStatus: '',
            selected: [],
            selectedRow: [],
            filesForPublish: [],
            isSelectAll: false,
            folderId: '',
            fileListFlag: false
        }
        this.fileNameFormatter = this.fileNameFormatter.bind(this);
        this.actionFormatter = this.actionFormatter.bind(this);
        this.changeFiles = this.changeFiles.bind(this);
        this.retireFile = this.retireFile.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    componentWillMount() {
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        var isDealer = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isNMSCAdminUser = true;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" ||
                    roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" ||
                    roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER INTERNAL") {
                    isTMEAdminUser = true;
                    if (roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" ||
                        roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER INTERNAL") {
                        isDealer = true;
                    }
                    break;
                }
            }
        }

        this.setState({ isNMSCAdminUser: isNMSCAdminUser });
        this.setState({ isTMEAdminUser: isTMEAdminUser });
        this.setState({ isNotAdmin: isNotAdmin });
        this.setState({ isDealer: isDealer });
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.selectedNodeId !== nextProps.selectedNodeId && nextProps.selectedNodeId && nextProps.selectedNodeId !== '') {
            console.log("sub folder changed" + nextProps.selectedNodeId);
            // fetch file details for selected folder.
            this.setState({ folderId: nextProps.selectedNodeId });
            if (this.props.isNmsc) {
                this.props.dispatch(fetchNmscFileDtls(nextProps.selectedNodeId, this.props.user.userMdl.nmsc));
                this.props.dispatch(fetchNmscFileStatus(nextProps.selectedNodeId, this.props.user.userMdl.nmsc));
            }
            else if (this.props.isNotAdmin) {
                this.props.dispatch(fetchNmscPublishedFileDtls(nextProps.selectedNodeId, this.props.user.userMdl.nmsc));
                this.props.dispatch(fetchNmscFileStatus(nextProps.selectedNodeId, this.props.user.userMdl.nmsc));
            }
            else {
                this.props.dispatch(fetchFileDtls(nextProps.selectedNodeId));
                this.props.dispatch(fetchFileStatus(nextProps.selectedNodeId));
            }
            let data = [];
            nextProps.filesForPublish.map((file) => {
                if (file.folderId === nextProps.selectedNodeId) {
                    file.files.map((id) => {
                        data.push(id.fileId);
                    })
                }
            })
            console.log(data);
            this.setState({ selectedRow: data });
        }

        this.state.fileListFlag = false;
        console.log("file status" + nextProps.libraryReducer.fileStatus)
        if (nextProps.libraryReducer.fileStatus > 0) {
            this.state.fileListFlag = true;
        }
        else {
            this.state.fileListFlag = false;
        }
        this.props.fileListFlag(this.state.fileListFlag);

        if (this.props.isNmsc) {
            if (nextProps.libraryReducer.nmscFileStatus > 0) {
                this.state.fileListFlag = true;
            }
            else {
                this.state.fileListFlag = false;
            }
            this.props.fileListFlag(this.state.fileListFlag);
        }

        if (nextProps.libraryReducer.fileList) {
            const fileList = [...nextProps.libraryReducer.fileList];
            var nonSelectableFile = [];
            for (var i = 0, size = fileList.length; i < size; i++) {
                var item = fileList[i];
                if ((item.status === "Published") || item.status === "Retired") {
                    nonSelectableFile.push(item.fileId);
                }
            }
            this.setState({ nonSelected: nonSelectableFile });
            this.setState({ fileList: nextProps.libraryReducer.fileList });
        }
        if (this.props.filesForPublish !== nextProps.filesForPublish) {
            console.log(nextProps.filesForPublish);
            // fetch file details for selected folder.
            let data = [];
            nextProps.filesForPublish.map((file) => {
                if (file.folderId === nextProps.selectedNodeId) {
                    file.files.map((id) => {
                        data.push(id.fileId);
                    })
                }
            })
            console.log(data);
            this.setState({ selectedRow: data });
            this.setState({ filesForPublish: nextProps.filesForPublish, fileList: nextProps.libraryReducer.fileList });
        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filesForPublish !== this.props.filesForPublish) {
            this.setState({ filesForPublish: this.props.filesForPublish });
        }
    }
    fileNameHeaderFormatter(col, colIndex) {
        return (
            <div>
                {/* <i className="fa fa-folder" style={{marginRight: '5px', color: 'rgb(255, 187, 51)'}}/> */}
                {col.text}
            </div>
        );
    }
    fileNameFormatter(cell, row) {
        let icon = 'images/folder.png';
        if (row.fileType === 'doc' || row.fileType === 'docx') {
            icon = 'images/word.png';
        }
        if (row.fileType === 'xls' || row.fileType === 'xlsx') {
            icon = 'images/excel.png';
        }
        if (row.fileType === 'pdf') {
            icon = 'images/pdf.png';
        }
        if (row.fileType === 'jpg' || row.fileType === 'jpeg' || row.fileType === 'gif' || row.fileType === 'bmp'
            || row.fileType === 'png' || row.fileType === 'tif') {
            icon = 'images/image-icon.png';
        }
        if (row.fileType === 'txt') {
            icon = 'images/text.png';
        }

        if (row.fileType === 'ppt' || row.fileType === 'pptx') {
            icon = 'images/ppt-icon.png';
        }

        if (row.fileType === 'avi' || row.fileType === 'flv' || row.fileType === 'wmv' || row.fileType === 'mov' || row.fileType === 'mp4' || row.fileType === 'mpg') {
            icon = 'images/video-icon.png';
        }
        return (

            <div style={{ cursor: 'pointer' }} onClick={() => this.downloadFile(row.fileId, row.fileType)}>
                <img src={icon} height="20" width="20" style={{ marginRight: '4px' }} alt=""/>
                {cell}
            </div>
        );
    }
    actionFormatter(cell, row) {
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'LIBRARY')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'LIBRARY')[0].accessType : "";
        }
        return (
            <div className="btn-toolbar float-right">
                {/* <MDBPopover component="div" placement="left" className="lib-info-icon" style={{ cursor: 'pointer', marginTop: '5px' }}>
                    <MDBPopoverHeader>{row.fileName}</MDBPopoverHeader>
                    <MDBPopoverBody>
                        {row.desc}
                    </MDBPopoverBody>
                </MDBPopover> */}
                {row.desc !== undefined && row.desc.length > 0 ?
                    <a><div style={{ whiteSpace: 'pre', cursor: 'pointer', marginTop: '5px' }} title={row.desc} className="additionalInfo" /></a> : ""}
                {row.fileType !== 'DIR' && row.status !== "Retired" && accessType === "R/W" && this.props.activeTabNmscLang != "TME" ?
                    <div>
                        <div title={I18n.t("tmeAdminMaster.delete")} onClick={() => this.deleteLibFile(row)} className="delete-icon" style={{ cursor: 'pointer', marginTop: '4.9px' }} />
                        <div title={I18n.t('tmeAdminMaster.edit')} onClick={() => this.props.toggle('Edit', row)} className="edit-icon" style={{ cursor: 'pointer', marginTop: '4.9px' }} />
                    </div>
                    : ""}
            </div>
        );
    }
    selectAll = (selectedRows) => {
        let filesList = [];
        selectedRows.map((selectedFile) => {
            this.state.fileList.map((file) => {
                if (file.fileId === selectedFile) {
                    filesList.push(file);
                }
            });
        })
        let selectedFiles = {
            folderId: this.state.folderId,
            files: filesList
        }
        this.props.selectedFiles(selectedFiles);
    }
    downloadFile = (fileId, fileType) => {

        if (fileType !== 'DIR') {
            // alert("Download File with Id: " + fileId + " and file type: " + fileType);
            if (this.state.isNMSCAdminUser || this.state.isNotAdmin || this.state.isDealer) {
                this.props.dispatch(getDownloadPreSignedUrl(fileId, false));
            }
            else {
                this.props.dispatch(getDownloadPreSignedUrl(fileId, true));
            }

        }
    };
    changeFiles = () => {
        console.log(this.state.selectedRow);
        let filesList = [];
        this.state.selectedRow.map((selectedFile) => {
            this.state.fileList.map((file) => {
                if (file.fileId === selectedFile) {
                    filesList.push(file);
                }
            });
        })
        let selectedFiles = {
            folderId: this.state.folderId,
            files: filesList
        }
        this.props.selectedFiles(selectedFiles);
    }

    retireFile(row) {
        var index = -1;
        this.state.selectedRow.map((file) => {
            if (index === -1) {
                if (file === row.fileId) {
                    index = this.state.selectedRow.indexOf(file);
                }
            }
        })
        if (index > -1) {
            this.state.selectedRow.splice(index, 1);
        }
        this.changeFiles(this.state.selectedRow);

    }
    statusFormatter(cell, row) {
        return (
            <div >
                {I18n.t('status.' + row.status.toLowerCase())}
            </div>
        );
    }

    deleteLibFile = (row) => {
        // console.log("delete file "+this.state.isNmsc)
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
                    isNMSCAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;
            }
        }
        if (isNMSCAdminUser) {
            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                        <div className="deleteConfirmBox">
                            <div className={"titleClass-" + this.props.theme.name}>
                                {row.publishedDate === "" ?
                                    I18n.t("library.library_files_deleted")
                                    :
                                    I18n.t("library.library_files_linked_deleted")}
                            </div>
                            <div className="modal-footer" style={{ borderTop: "0px" }}>
                                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                    {I18n.t("standardMessages.no")}
                                </button>
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => {
                                        this.retireFile(row);
                                        // if(isNMSCAdminUser){
                                        //     this.props.dispatch(deleteNmscFile(row, this.state.folderId))
                                        // }
                                        // else{
                                        // this.props.dispatch(deleteFile(row, this.state.folderId))
                                        // }
                                        this.props.dispatch(deleteNmscFile(row, this.state.folderId, this.props.user.userMdl.nmsc))
                                        onClose();
                                    }}>
                                    {I18n.t("standardMessages.yes")}
                                </button>
                            </div>
                        </div>
                    );
                }
            });

        }
        else {


            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                        <div className="deleteConfirmBox">
                            <div className={"titleClass-" + this.props.theme.name}>
                                {row.publishedDate === "" ? I18n.t("library.library_files_deleted")
                                    :
                                    I18n.t("library.library_files_linked_deleted")}
                            </div>
                            <div className="modal-footer" style={{ borderTop: "0px" }}>
                                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                    {I18n.t("standardMessages.no")}
                                </button>
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => {
                                        this.retireFile(row);
                                        // if(isNMSCAdminUser){
                                        //     this.props.dispatch(deleteNmscFile(row, this.state.folderId))
                                        // }
                                        // else{
                                        // this.props.dispatch(deleteFile(row, this.state.folderId))
                                        // }
                                        this.props.dispatch(deleteFile(row, this.state.folderId))
                                        onClose();
                                    }}>
                                    {I18n.t("standardMessages.yes")}
                                </button>
                            </div>
                        </div>
                    );
                }
            });
        }


    }
    render() {
        //console.log(this.props.isMainFolderFlag)
        //console.log(this.state.isTMEAdminUser)
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'LIBRARY')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'LIBRARY')[0].accessType : "";
        }
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
                    isNMSCAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;
            }
        }

        const columns = [
            {
                dataField: "fileName",
                text: I18n.t("library.fileName"),
                sort: true,
                style: this.state.isTMEAdminUser ? { width: "46%", textAlign: "left", paddingRight: "0px", paddingLeft: "0px" } : { width: "36%", textAlign: "left", paddingRight: "0px", paddingLeft: "0px" },
                headerStyle: this.state.isTMEAdminUser ? { width: "46%", textAlign: "left", paddingRight: "0px", paddingLeft: "0px" } : { width: "37%", textAlign: "left", paddingRight: "0px", paddingLeft: "0px" },
                headerFormatter: this.fileNameHeaderFormatter,
                formatter: this.fileNameFormatter,
                title: true
            },
            {
                dataField: "status",
                text: I18n.t("Table.status"),
                sort: true,
                formatter: this.statusFormatter,
                classes: (cell, row, rowIndex, colIndex) => {
                    if (cell === "Published") { return 'greenColor'; }
                    else if (cell === "Saved") { return 'orangeColor'; }
                    else { return 'greyColor'; }
                },
                style: { width: "11.5%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                headerStyle: { width: "10.8%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                title: true
            },
            {
                dataField: "publishedDate",
                text: I18n.t("questionnaire.subQuestionTable.publishedDate"),
                sort: true,
                style: { width: "20%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                headerStyle: { width: "19%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                title: true
            },
            {
                dataField: "reference",
                text: I18n.t("Table.reference"),
                hidden: this.state.isTMEAdminUser,
                sort: true,
                // formatter: this.notApplicableFormatter,
                style: { fontSize: "12px", width: "8.95%", textAlign: "center" },
                headerStyle: { width: "7.51%", textAlign: "center" }
            },
            {
                dataField: "questionReference",
                text: I18n.t("Table.questionReference"),
                sort: true,
                style: {
                    maxWidth: "15px",
                    width: "12%", textAlign: "center", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingRight: "0px", paddingLeft: "0px"
                },
                headerStyle: { width: "12.1%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                title: true
            },
            {
                dataField: "",
                text: "",
                formatter: this.actionFormatter,
                style: { width: "14%", textAlign: "center", paddingRight: "0px", paddingLeft: "0px" },
                headerStyle: { width: "14%" }
            }];

        const mainFoldercolumns = [
            {
                dataField: "fileName",
                text: "",
                sort: true,
                style: { width: "75%", textAlign: "left" },
                //headerStyle: { width: "30%", textAlign: "left" },
                // headerFormatter: this.fileNameHeaderFormatter,
                formatter: this.fileNameFormatter,
                title: true
            },
            {
                dataField: "status",
                text: "",
                sort: true,
                formatter: this.statusFormatter,
                classes: (cell, row, rowIndex, colIndex) => {
                    if (cell === "Published") {
                        return "greenColor";
                    } else if (cell === "Saved") {
                        return "orangeColor";
                    } else {
                        return "greyColor";
                    }
                },
                style: { width: "12%", textAlign: "center" },
                //headerStyle: { width: "12%", textAlign: "center" },
                title: true
            },
            {
                dataField: "publishedDate",
                text: "",
                sort: true,
                style: { width: "18%", textAlign: "center" },
                //headerStyle: { width: "20%", textAlign: "center" },
                title: true
            },
            {
                dataField: "questionReference",
                text: "",
                sort: true,
                style: { width: "1%", textAlign: "center", paddingLeft: "40px" },
                // headerStyle: { width: "24%", textAlign: "center" },
                title: true
            },
            {
                dataField: "",
                text: "",
                //formatter: this.actionFormatter,
                style: { width: "1%", textAlign: "center" },
                // headerStyle: { width: "1%" }
            }
        ];


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox") {
                    e.target.checked = true;
                    var index = -1;
                    this.state.selectedRow.map((file) => {
                        if (index === -1) {
                            if (file === row.fileId) {
                                index = this.state.selectedRow.indexOf(file);
                            }
                        }
                    })
                    console.log("index matched:" + index);
                    if (index > -1) {
                        this.state.selectedRow.splice(index, 1);
                    } else {
                        this.state.selectedRow.push(row.fileId);
                    }
                }
                console.log(this.state.selectedRow);
                this.changeFiles(this.state.selectedRow);
                // this.props.selectedFiles(this.state.selected);
            }
        };
        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            nonSelectable: this.state.nonSelected,
            selected: this.state.selectedRow,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                if (isSelect) {
                    rows.forEach((row) => {
                        if (row.status === 'Saved') {
                            selectedRows.push(row.fileId);
                        }
                    });
                }
                this.setState({ selectedRow: selectedRows });
                this.selectAll(selectedRows);
                return selectedRows;
            }
        };

        const mainFolderSelectRow = {
            mode: 'radio',
            hideSelectColumn: true,
            clickToSelect: true
        };

        return (

            <Row className="ml-1 mt-2">
                {this.props.selectedNodeId ? <BootstrapTable
                    ref="libFileTbl"
                    keyField="fileId"
                    classes="libFileTbl"
                    data={this.state.fileList}
                    columns={this.props.isMainFolderFlag ? mainFoldercolumns : columns}
                    selectRow={this.props.isMainFolderFlag ? mainFolderSelectRow : selectRow}
                    hover
                    condensed
                    rowEvents={rowEvents}
                /> : (
                        <div />
                    )}
            </Row>

        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(LibraryDetails)));
