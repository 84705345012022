import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import $ from "jquery";
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import "./../index.css";
import Autosuggest from 'react-autosuggest';
import * as AppConst from '../../../AppConstant';
import { saveSearchFilters } from '../../../actions/dealerManagementAction.jsx'
import {MDBIcon } from "mdbreact";
import { fetchActivityFilters } from '../../../actions/activityAction';
import { fetchLinkDealerList, fetchLinkFilters, fetchLinkCityList } from '../../../actions/userManagementAction.jsx'
var I18n = require("react-redux-i18n").I18n;

class SearchDealer extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            nmscFlag: false,
            isAdmin: this.props.isAdmin,
            suggestionsDealer: [],
            suggestionsCity: [],
            dealerList: [],
            nmscCodeList: [],
            franchiseList: [],
            countryList: [],
            l3List: [],
            l4List: [],
            l5List: [],
            l6List: [],
            roleList: this.props.userDto.role,
            valueDealer: "",
            valueDealerCity: "",
            britReady: "",
            britLive: "",
            britJapan: "",
            britReadyList: [{ label: "Y", value: "Y" }, { label: "N", value: "N" }],
            britLiveList: [{ label: "Y", value: "Y" }, { label: "N", value: "N" }],
            britJapanList: [{ label: "Y", value: "Y" }, { label: "N", value: "N" }],
            eesActionList: [{ label: "Y", value: "Y" }, { label: "N", value: "N" }],
            dealerCode: '',
            a2dUuid: '',
            city: '',
            cityList: [],
            userProgram: this.props.user.userMdl.activeProgram,
            selectedNmscCode: [],
            selectedFranchise: [],
            selectedCountry: [],
            selectedL3: [],
            selectedL4: [],
            selectedL5: [],
            selectedL6: [],
            selectedBritJapan: '',
            selectedBritLive: '',
            selectedBritReady: '',
            selectedEESAction: '',
            dealerDto: {
                tarsId: this.props.userDto.tarsId,
                networkUserId: this.props.userDto.networkUserId,
                nmscCode: null,
                dealer: '',
                l1: null,
                l2: null,
                dealer: null,
                l3List: null,
                l4List: null,
                l5List: null,
                l6List: null,
                britReady: null,
                britLive: null,
                britJapan: null,
                eesAction: null,
                userNmscCode: this.props.userDto.nmscId,
                networkLevelDealerId: 0,
                businessArea:''
            },

            defaultAllOption: { "value": "All", "label": I18n.t('standardMessages.all'), "selected": "selected" },
            defaultAllObj: { "value": "All", "label": I18n.t('standardMessages.all') },
            isRefresh: false,
            businessFunc: [{ label: I18n.t('standardMessages.all'), value: "All" },{ label: I18n.t('userManagement.valueChainOnly'), value: "VALUE_CHAIN" },{ label: I18n.t('userManagement.britOnly'), value: "BRiT" }, { label: I18n.t('userManagement.valueChainAndBRiT'), value: "VALUE_CHAIN,BRiT" }, { label: I18n.t('userManagement.eaOnly'), value: "EA" }, { label: I18n.t('userManagement.valueChainAndEA'), value: "VALUE_CHAIN,EA" }, { label: I18n.t('userManagement.bRiTAndEA'), value: "BRiT,EA" }, { label: I18n.t('userManagement.BRiTAndValueChainAndEA'), value: "BRiT,VALUE_CHAIN,EA" }],
        }
    }

    componentWillMount() {

        let dealerDTO = { ...this.props.dealerDto }

        dealerDTO["page"] = this.props.searchDto.page
        dealerDTO["sizePerPage"] = this.props.searchDto.sizePerPage;
        dealerDTO["rows"] = this.props.searchDto.rows
        this.setState({ dealerDto: dealerDTO })
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
                    isNMSCAdminUser = true;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN") {
                    isTMEAdminUser = true;
                    break;
                }
                isNotAdmin = true;

            }
        }

        if (this.state.dealerDto !== undefined) {
            // console.log("got saved criteria" + JSON.stringify(this.state.downloadDto));
            let selectedNmscList = this.getSelectedList(this.state.dealerDto.nmscCodeList);
            let selectedDealerList = this.getSelectedList(this.state.dealerDto.dealerList);
            let selectedCountryList = this.getSelectedList(this.state.dealerDto.countryList);
            this.setState({
                dealerDto: this.state.dealerDto,
                selectedNmscCode: selectedNmscList,
                selectedDealer: selectedDealerList,
                selectedCountry: selectedCountryList,
                valueDealer: this.state.dealerDto.dealer !== undefined ? this.state.dealerDto.dealer : '',
                valueDealerCity: this.state.dealerDto.city !== undefined ? this.state.dealerDto.city : '',
            });
            this.props.dispatch(fetchActivityFilters(this.state.dealerDto, "nmscCode"));
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l1"))
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l2"))
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l3"))
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l4"))
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l5"))
            this.props.dispatch(fetchLinkFilters(this.state.dealerDto, "l6"))
            this.props.dispatch(fetchLinkDealerList(this.state.dealerDto))



        }
    }
    /**
         * Method to create selected option list for multiselect element.
         */
    getSelectedList(selectedList) {
        let filteredList = [];
        let obj;
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (selectedList !== undefined) {
            selectedList.map(value => {
                if (Number.test(value)) {
                    obj = {
                        label: value,
                        value: value,
                        selected: 'selected'
                    };
                }
                else {
                    obj = {
                        label: I18n.t("status." + value.toLowerCase()),
                        value: value,
                        selected: 'selected'
                    };
                }
                filteredList.push(obj);
            });
        }
        return filteredList;
    }
    componentDidMount() {
        if (this.state.isAdmin) {
            this.refreshFilters();
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["page"] = nextProps.searchDto.page;
        dealerDto["rows"] = nextProps.searchDto.rows;
        dealerDto["sizePerPage"] = nextProps.searchDto.sizePerPage;
        let roles = [];
        roles = nextProps.user.userMdl.roles;
        this.setState({ dealerDto: dealerDto })

        console.log(nextProps)
        //If user role is NMSC or DEALER then set flag as true
        // This will set the search activity toolbox drop down for nmsc user
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1 || roles[i].toUpperCase().indexOf("DEALER") !== -1) {
                    this.setState({ nmscFlag: true });
                    break;
                }
            }
        }
        // if (nextProps.dealerList.nmscList) {
        //     this.setState({ nmscCodeList: nextProps.dealerList.nmscList });
        //     var nmscValue = [];
        //     nextProps.dealerList.nmscList.map(function (item) {
        //         nmscValue.push(item.value);
        //     })
        //     const dealerDto = { ...this.state.dealerDto };
        //     dealerDto["nmscCodeList"] = nmscValue;
        //     this.setState({ dealerDto }); 
        // }
        console.log(nextProps.dealerManagement)


    }

    componentDidUpdate(prevProps, prevState) {

        let dealerDto = { ...this.state.dealerDto }



        if (prevProps.linkFilter.isRefresh !== this.props.linkFilter.isRefresh && this.props.linkFilter.isRefresh !== undefined) {
            this.refreshFilters()
        }


        if (prevProps.linkFilter.nmscCodeList !== this.props.linkFilter.nmscCodeList && this.props.linkFilter.nmscCodeList !== undefined) {

            if (this.props.searchDto.isNMSCAdminUser) {
                let nmsclist = [...this.state.nmscCodeList];
                nmsclist.push(this.props.user.userMdl.nmsc)
                this.setState({ nmscCodeList: nmsclist })
                this.props.dispatch(fetchLinkFilters(dealerDto, "l1"))
                this.props.dispatch(fetchLinkFilters(dealerDto, "l2"))
                this.props.dispatch(fetchLinkDealerList(dealerDto))
                this.props.dispatch(fetchLinkCityList(dealerDto))
            }
            else {
                let nmscCodeList = this.props.linkFilter.nmscCodeList.length > 1 ? [this.state.defaultAllObj].concat(this.props.linkFilter.nmscCodeList) : this.props.linkFilter.nmscCodeList
                let defaultSelectedNmscCode = this.props.linkFilter.nmscCodeList.length > 1 ? this.state.defaultAllOption : []
                dealerDto["nmscCode"] = AppConst.DEFAULT_ALL
                this.setState({ dealerDto, nmscCodeList: nmscCodeList, selectedNmscCode: [defaultSelectedNmscCode] })
                this.props.dispatch(fetchLinkFilters(dealerDto, "l1"))
                this.props.dispatch(fetchLinkFilters(dealerDto, "l2"))
                this.props.dispatch(fetchLinkDealerList(dealerDto))
                this.props.dispatch(fetchLinkCityList(dealerDto))
            }

        }
        if (prevProps.linkFilter.franchiseList !== this.props.linkFilter.franchiseList && this.props.linkFilter.franchiseList !== undefined) {


            if (this.props.linkFilter.franchiseList.length == 1) {
                dealerDto["l1"] = this.props.linkFilter.franchiseList[0].value
                let selectedFranchise = this.getSelectedDropDownList(this.props.linkFilter.franchiseList)
                this.setState({ dealerDto, selectedFranchise: selectedFranchise, franchiseList: this.props.linkFilter.franchiseList })
                this.props.dispatch(fetchLinkFilters(dealerDto, "l2"))
                this.props.dispatch(fetchLinkDealerList(dealerDto))
                this.props.dispatch(fetchLinkCityList(dealerDto))

            }
            else {
                let frachiseList = this.props.linkFilter.franchiseList.length > 1 ? [this.state.defaultAllObj].concat(this.props.linkFilter.franchiseList) : this.props.linkFilter.franchiseList
                let defaultSelectedFranchise = this.props.linkFilter.franchiseList.length > 1 ? this.state.defaultAllOption : []
                dealerDto["l1"] = AppConst.DEFAULT_ALL
                this.setState({ dealerDto, franchiseList: frachiseList, selectedFranchise: [defaultSelectedFranchise] })
            }
        }
        if (prevProps.linkFilter.countryList !== this.props.linkFilter.countryList && this.props.linkFilter.countryList !== undefined) {


            if (this.props.linkFilter.countryList.length == 1) {
                dealerDto["l2"] = this.props.linkFilter.countryList[0].value
                let selectedCountry = this.getSelectedDropDownList(this.props.linkFilter.countryList)
                this.setState({ dealerDto, selectedCountry: selectedCountry, countryList: this.props.linkFilter.countryList })
                this.props.dispatch(fetchLinkFilters(dealerDto, "l3"))
                this.props.dispatch(fetchLinkFilters(dealerDto, "l4"))
                this.props.dispatch(fetchLinkFilters(dealerDto, "l5"))
                this.props.dispatch(fetchLinkFilters(dealerDto, "l6"))
                this.props.dispatch(fetchLinkDealerList(dealerDto))
                this.props.dispatch(fetchLinkCityList(dealerDto))


            }
            else {
                let countryList = this.props.linkFilter.countryList.length > 1 ? [this.state.defaultAllObj].concat(this.props.linkFilter.countryList) : this.props.linkFilter.countryList
                let defaultSelectedCountry = this.props.linkFilter.countryList.length > 1 ? this.state.defaultAllOption : []
                dealerDto["l2"] = AppConst.DEFAULT_ALL
                this.setState({ dealerDto, countryList: countryList, selectedCountry: [defaultSelectedCountry] })
            }

        }
        if (prevProps.linkFilter.l3List !== this.props.linkFilter.l3List && this.props.linkFilter.l3List !== undefined) {


            if (this.props.linkFilter.l3List.length == 1) {
                dealerDto["l3List"] = [this.props.linkFilter.l3List[0].value]
                let selectedL3 = this.getSelectedDropDownList(this.props.linkFilter.l3List)
                this.setState({ dealerDto, selectedL3: selectedL3, l3List: this.props.linkFilter.l3List })
                //  this.props.dispatch(fetchLinkFilters(dealerDto,"l4"))
                //  this.props.dispatch(fetchLinkDealerList(dealerDto))
            }
            else {
                this.setState({ l3List: this.props.linkFilter.l3List })
            }
        }
        if (prevProps.linkFilter.l4List !== this.props.linkFilter.l4List && this.props.linkFilter.l4List !== undefined) {


            if (this.props.linkFilter.l4List.length == 1) {
                dealerDto["l4List"] = [this.props.linkFilter.l4List[0].value]
                let selectedL4 = this.getSelectedDropDownList(this.props.linkFilter.l4List)
                this.setState({ dealerDto, selectedL4: selectedL4, l4List: this.props.linkFilter.l4List })
                //  this.props.dispatch(fetchLinkFilters(dealerDto,"l5"))
                //  this.props.dispatch(fetchLinkDealerList(dealerDto))
            }
            else {
                this.setState({ l4List: this.props.linkFilter.l4List })
            }
        }
        if (prevProps.linkFilter.l5List !== this.props.linkFilter.l5List && this.props.linkFilter.l5List !== undefined) {


            if (this.props.linkFilter.l5List.length == 1) {
                dealerDto["l5List"] = [this.props.linkFilter.l5List[0].value]
                let selectedL5 = this.getSelectedDropDownList(this.props.linkFilter.l5List)
                this.setState({ dealerDto, selectedL5: selectedL5, l5List: this.props.linkFilter.l5List })
                //  this.props.dispatch(fetchLinkFilters(dealerDto,"l6"))
                //  this.props.dispatch(fetchLinkDealerList(dealerDto))
            }
            else {
                this.setState({ l5List: this.props.linkFilter.l5List })
            }
            // this.props.dispatch(fetchLinkDealerList(dealerDto))
        }
        if (prevProps.linkFilter.l6List !== this.props.linkFilter.l6List && this.props.linkFilter.l6List !== undefined) {


            if (this.props.linkFilter.l6List.length == 1) {
                dealerDto["l6List"] = [this.props.linkFilter.l6List[0].value]
                let selectedL6 = this.getSelectedDropDownList(this.props.linkFilter.l6List)
                this.setState({ dealerDto, selectedL6: selectedL6, l6List: this.props.linkFilter.l6List })
                //  this.props.dispatch(fetchLinkFilters(dealerDto,"l6"))
                // this.props.dispatch(fetchLinkDealerList(dealerDto))
            }
            else {
                this.setState({ l6List: this.props.linkFilter.l6List })
            }

        }
        if (prevProps.linkFilter.dealerList !== this.props.linkFilter.dealerList && this.props.linkFilter.dealerList !== undefined) {

            var valueOptions = [];
            // var valueCity = [];
            // var valueCity1= [];
            this.props.linkFilter.dealerList.map(function (item) {
                valueOptions.push(item.label);
            })
            // valueOptions.map(function (item) {
            //     valueCity = item.split("(")
            // var newOb = valueCity[1]
            // // if(newOb!=undefined){
            // valueCity = newOb.split(")");
            // valueCity1.push(valueCity[0])
            // // }
            // })
            // console.log("trim city",valueCity1)
            this.setState({ dealerList: this.props.linkFilter.dealerList, dealerDto })

        }
        if (prevProps.linkFilter.cityList !== this.props.linkFilter.cityList && this.props.linkFilter.cityList !== undefined) {
            var list = [];
            //var list1 = [];

            this.props.linkFilter.cityList.map(function (item) {
                //list1 = item.filter((items) => items.label !== item.label);         
                if (!list.includes(item.label)) {
                    list.push(item.label)
                }
            })
            console.log("list", list)
            // var valueOptions = [];

            // this.props.linkFilter.cityList.map(function (item) {
            //     valueOptions.push(item.label);
            // })
            let filterList = [];
            let obj;
            if (list !== undefined) {
                list.map(value => {
                    obj = {
                        label: value,
                        value: value
                    };
                    filterList.push(obj);
                });
            }
            this.setState({ cityList: filterList, dealerDto })

        }

    }

    isNotEmpty = (value) => {
        if (undefined === value || null === value) {
            return false;
        }
        else if ((value + "").trim() === "") {
            return false;
        }
        return true;
    }
    onDealerChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        console.log("dealer on change", newValue)
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = Number(newValue.substr(newValue.indexOf("_") + 1));
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const dealerDto = { ...this.state.dealerDto };
        if (modifiedValue.trim().length > 0) {
            dealerDto["dealer"] = modifiedValue;
            dealerDto["networkLevelDealerId"] = modifiedId
            this.setState({ dealerDto });
        }
        else {
            dealerDto["dealer"] = null;
            dealerDto["networkLevelDealerId"] = 0;
            this.setState({ dealerDto })
        }
        this.setState({
            valueDealer: modifiedValue,
        });
    };

    onCityChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        console.log("city on change", newValue)
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            console.log("city on change fullname", modifiedValue)

        } else {
            modifiedValue = newValue;
        }
        const dealerDto = { ...this.state.dealerDto };
        if (modifiedValue.trim().length > 0) {
            dealerDto["city"] = modifiedValue;
            console.log("city on change fullname after trim", modifiedValue)

            this.setState({ dealerDto });
        }
        else {
            dealerDto["city"] = null;
            this.setState({ dealerDto })
        }
        // var valueOptions = []
        // let deal = [];
        // deal=this.state.dealerList
        // deal.map(function (item) {
        //     // if(item.label.indexOf(newValue)!==-1){
        //     // valueOptions.push(item.label);
        //     // }
        //     console.log("dealer on change",modifiedValue)
        // })
        this.setState({
            valueDealerCity: modifiedValue,
            // dealerList: valueOptions
        });
    };

    onNmscChange = (selectedOption) => {

        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {
            dealerDto["nmscCode"] = selectedOption.value;
            dealerDto["l1"] = null
            dealerDto["l2"] = null
            dealerDto["l3List"] = null
            dealerDto["l4List"] = null
            dealerDto["l5List"] = null
            dealerDto["l6List"] = null
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({ dealerDto });
            this.setState({
                selectedNmscCode: [selectedOption], selectedCountry: [],
                selectedFranchise: [], selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
            this.props.dispatch(fetchLinkFilters(dealerDto, "l1"))
            this.props.dispatch(fetchLinkFilters(dealerDto, "l2"))

        } else {
            dealerDto["nmscCode"] = null
            dealerDto["l1"] = null
            dealerDto["l2"] = null
            dealerDto["l3List"] = null
            dealerDto["l4List"] = null
            dealerDto["l5List"] = null
            dealerDto["l6List"] = null
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({ dealerDto });
            this.setState({
                selectedNmscCode: [], selectedCountry: [],
                selectedFranchise: [], selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
        }
        this.props.dispatch(fetchLinkDealerList(dealerDto))
        this.props.dispatch(fetchLinkCityList(dealerDto))


    }

    onFranchiseChange = (selectedOption) => {

        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {
            dealerDto["l1"] = selectedOption.value;
            dealerDto["l2"] = null
            dealerDto["l3List"] = null
            dealerDto["l4List"] = null
            dealerDto["l5List"] = null
            dealerDto["l6List"] = null
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({
                dealerDto, selectedFranchise: [selectedOption], selectedCountry: [],
                selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
            this.props.dispatch(fetchLinkFilters(dealerDto, "l2"))
            //dealer Call
        } else {
            dealerDto["l1"] = null;
            dealerDto["l2"] = null;
            dealerDto["l3List"] = null;
            dealerDto["l4List"] = null;
            dealerDto["l5List"] = null;
            dealerDto["l6List"] = null;
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({
                dealerDto, selectedFranchise: [], selectedCountry: [],
                selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
        }
        this.props.dispatch(fetchLinkDealerList(dealerDto))
        this.props.dispatch(fetchLinkCityList(dealerDto))

    }
    onCountryChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {
            dealerDto["l2"] = selectedOption.value;
            dealerDto["l3List"] = null
            dealerDto["l4List"] = null
            dealerDto["l5List"] = null
            dealerDto["l6List"] = null
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({
                dealerDto, selectedCountry: [selectedOption], selectedL3: [],
                selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
            this.props.dispatch(fetchLinkFilters(dealerDto, "l3"))
            this.props.dispatch(fetchLinkFilters(dealerDto, "l4"))
            this.props.dispatch(fetchLinkFilters(dealerDto, "l5"))
            this.props.dispatch(fetchLinkFilters(dealerDto, "l6"))
            //dealer code
        } else {
            dealerDto["l2"] = null;
            dealerDto["l3List"] = null;
            dealerDto["l4List"] = null;
            dealerDto["l5List"] = null;
            dealerDto["l6List"] = null;
            dealerDto["dealer"] = null
            dealerDto["networkLevelDealerId"] = 0
            this.setState({
                dealerDto, selectedCountry: [], selectedL3: [],
                selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })

        }
        this.props.dispatch(fetchLinkDealerList(dealerDto))
        this.props.dispatch(fetchLinkCityList(dealerDto))

    }

    onL3Change = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {

            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
            })
            dealerDto["l3List"] = valueOptions;
            this.setState({ dealerDto, selectedL3: selectedOption, })
            //dealer call
        } else {
            dealerDto["l3List"] = null;
            this.setState({ dealerDto, selectedL3: [] })
        }
    }

    onL4Change = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {
            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
            })
            dealerDto["l4List"] = valueOptions;
            this.setState({ dealerDto, selectedL4: selectedOption })
        } else {
            dealerDto["l4List"] = null;
            this.setState({ dealerDto, selectedL4: [] })

        }
    }

    onL5Change = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {

            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
            })

            dealerDto["l5List"] = valueOptions
            this.setState({ dealerDto, selectedL5: selectedOption })
            //dealer call
        } else {
            dealerDto["l5List"] = null;
            this.setState({ dealerDto, selectedL5: [] })

        }
    }

    onL6Change = (selectedOption) => {

        const dealerDto = { ...this.state.dealerDto };
        if (this.isNotEmpty(selectedOption)) {
            var valueOptions = [];
            selectedOption.map(function (item) {
                valueOptions.push(item.value);
            })
            dealerDto["l6List"] = valueOptions
            this.setState({ dealerDto, selectedL6: selectedOption })
            //dealer call
        } else {
            dealerDto["l6List"] = null;
            this.setState({ dealerDto, selectedL6: [] })

        }
    }

    getSuggestionDealer = value => {
        console.log("value in suggestions", value)
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().indexOf(inputValue) !== -1)
        ) : [];
    };

    onSuggestionsDealerFetchRequested = ({ value }) => {
        console.log("value in suggestions", value)
        this.setState({
            suggestionsDealer: this.getSuggestionDealer(value)
        });
    };
    onSuggestionsDealerClearRequested = () => {
        this.setState({
            suggestionsDealer: []
        });
    };
    getSuggestionDealerValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionDealer = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    getSuggestionCity = value => {
        console.log("value in suggestions", value)
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.cityList !== undefined ? (this.state.cityList.filter(lang =>
            lang.label.toLowerCase().startsWith(inputValue))
        ) : [];
    };

    onSuggestionsCityFetchRequested = ({ value }) => {
        console.log("value in suggestions", value)
        this.setState({
            suggestionsCity: this.getSuggestionCity(value)
        });
    };
    onSuggestionsCityClearRequested = () => {
        this.setState({
            suggestionsCity: []
        });
    };
    getSuggestionCityValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionCity = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    // getSelectedList(selectedList) {
    //     let filteredList = null;
    //     if (selectedList !== undefined || selectedList !== null) {
    //         if(selectedList.length >0){
    //             filteredList = [];
    //             selectedList.map(obj => {
    //             filteredList.push(obj.value);
    //         })
    //     }
    //          }

    //     return filteredList;   
    //     }

    getSelectedString(selectedString) {
        let filteredString = null;
        if (selectedString !== undefined || selectedString !== null) {
            if (selectedString.length > 0) {
                filteredString = selectedString.value;

            }
        }
        return filteredString;
    }

    getSelectedDropDownList(selectedList) {
        let finalList = [];

        selectedList.map(objArr => {
            objArr["selected"] = "selected"
            finalList.push(objArr)
        })

        return finalList;
    }
    refreshFilters = () => {
        let dealerDto = { ...this.state.dealerDto }
        dealerDto["userNmscCode"] = this.props.user.userMdl.nmsc;
        dealerDto["tarsId"] = this.props.userDto.tarsId;
        dealerDto["networkUserId"] = this.props.userDto.networkUserId;
        dealerDto["l1"] = null
        dealerDto["l2"] = null
        dealerDto["nmscCode"] = null
        dealerDto["l3List"] = null
        dealerDto["l4List"] = null
        dealerDto["l5List"] = null
        dealerDto["l6List"] = null
        dealerDto["dealer"] = null
        dealerDto["networkLevelDealerId"] = 0

        let selectedNmscCode = { ...this.state.selectedNmscCode }

        if (this.props.searchDto.isNMSCAdminUser) {
            dealerDto["nmscCode"] = this.props.user.userMdl.nmsc

            let obj = {
                "label": this.props.user.userMdl.nmsc,
                "value": this.props.user.userMdl.nmsc,
                "selected": "selected"
            }
            selectedNmscCode = [obj];
            this.setState({
                dealerDto, selectedNmscCode, selectedCountry: [],
                selectedFranchise: [], selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: ""
            })
            this.props.dispatch(fetchLinkFilters(dealerDto, "nmscCode"))
            this.props.dispatch(fetchLinkDealerList(dealerDto))
        }
        else {

            this.setState({
                dealerDto, selectedNmscCode: [], selectedCountry: [],
                selectedFranchise: [], selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: "", valueDealerCity: ""
            })
            this.props.dispatch(fetchLinkFilters(dealerDto, "nmscCode"))
            this.props.dispatch(fetchLinkDealerList(dealerDto))
            this.props.dispatch(fetchLinkCityList(dealerDto))

        }

    }
    onBritReadyChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["britReady"] = null;
        if(selectedOption){
            if (selectedOption.value == 'Y') {
                dealerDto["britReady"] = true;
            }
            else {
                dealerDto["britReady"] = false;
            }
        }
        this.setState({ dealerDto });
        this.setState({ selectedBritReady: selectedOption })
    }

    onBritLiveChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["britLive"] = null;
        if(selectedOption){
            if (selectedOption.value == 'Y') {
                dealerDto["britLive"] = true;
            }
            else {
                dealerDto["britLive"] = false;
            }
        }
        this.setState({ dealerDto });
        this.setState({ selectedBritLive: selectedOption })
    }

    onBritJapanChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["britJapan"] = null;
        if(selectedOption){
            if (selectedOption.value == 'Y') {
                dealerDto["britJapan"] = true;
            }
            else {
                dealerDto["britJapan"] = false;
            }
        }
        this.setState({ dealerDto });
        this.setState({ selectedBritJapan: selectedOption })
    }
    onBusinessChange = (selectedOption) => {
        let dealerDto = { ...this.state.dealerDto }
        dealerDto["businessArea"] = selectedOption.value;
        if (selectedOption.value == 'All') {
            dealerDto["businessArea"] = '';
        }
        this.setState({ selectedBusinessFunc: selectedOption,dealerDto })
    }
    onEesActionChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["eesAction"] = null;
        if(selectedOption){
            if (selectedOption.value == 'Y') {
                dealerDto["eesAction"] = true;
            }
            else {
                dealerDto["eesAction"] = false;
            }
        }
        this.setState({ dealerDto });
        this.setState({ selectedEESAction: selectedOption })
    }

    dealerCodeChange = e => {
        this.setState({ dealerCode: e.target.value });
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["dealerCode"] = e.target.value;
        this.setState({ dealerDto });
    }

    a2dChange = e => {
        this.setState({ a2dUuid: e.target.value });
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["a2dUuid"] = e.target.value;
        this.setState({ dealerDto });
    }

    search = (e) => {
        let dealerDto = { ...this.state.dealerDto }
        e.preventDefault();
        // if((this.state.valueDealer!=="") &&  dealerDto.networkLevelDealerId === 0){
        //     toastr.error((I18n.t("ServerMessage.proper_dealer_selected")))
        //     this.refreshFilters()}
        //     else{
        //this.props.dispatch(fetchDealers(this.state.dealerDto))
        this.props.dispatch(saveSearchFilters(dealerDto))
        this.props.search(dealerDto)
        // }
    }


    render() {

        console.log("user nmsc in dealer management", this.state.nmscCodeList)
        console.log("user role in state", this.props.searchDto.isNMSCAdminUser)

        const { suggestionsDealer, valueDealer, isAdmin, suggestionsCity, valueDealerCity } = this.state;
        // Autosuggest will pass through all these props to the input.
        // console.log(this.state)
        const inputDealerProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueDealer,
            onChange: this.onDealerChange
        };

        const inputCityProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueDealerCity,
            onChange: this.onCityChange
        };

        let isNmscCodeDisabled = this.state.nmscFlag ? true : false
        let isL1Disabled = isAdmin && this.state.selectedNmscCode.length > 0 ? this.state.franchiseList ? this.state.franchiseList.length > 1 ? false : true : true : true
        let isL2Disabled = isAdmin && this.state.selectedNmscCode.length > 0 ? this.state.selectedNmscCode[0].value !== AppConst.DEFAULT_ALL ? this.state.countryList ? this.state.countryList.length > 1 ? false : true : true : true : true
        let isL3Disabled = this.state.selectedCountry.length > 0 ? this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL ? this.state.l3List ? this.state.l3List.length > 1 ? false : true : true : true : true
        let isL4Disabled = this.state.selectedCountry.length > 0 ? this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL ? this.state.l4List ? this.state.l4List.length > 1 ? false : true : true : true : true
        let isL5Disabled = this.state.selectedCountry.length > 0 ? this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL ? this.state.l5List ? this.state.l5List.length > 1 ? false : true : true : true : true
        let isL6Disabled = this.state.selectedCountry.length > 0 ? this.state.selectedCountry[0].value !== AppConst.DEFAULT_ALL ? this.state.l6List ? this.state.l6List.length > 1 ? false : true : true : true : true
        let isAutoSuggestionDisable = this.state.dealerList.length > 0 ? false : true
        console.log(isAutoSuggestionDisable)
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{ borderColor: this.props.theme.primaryColor, backgroundColor: "rgba(247, 247, 247, 1)" }} className={"col-md-12 dealerFilter"}>
                    <div className="col-md-12 row control-label">

                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"12px" }}>{I18n.t('Table.nmsc')}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"10px" }}>{I18n.t("Table.franchise")}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"10px" }}>{I18n.t("Table.country")}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"10px" }}>{I18n.t("activity.Dealer")}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"0px" }}>{I18n.t("Table.city")}</label>
                        {/* <label className="col-md-2 control-label dealerFilterLabel"  style={{  paddingLeft: '0rem'}}>{I18n.t('UserManagement.L5')}</label> */}
                        <label className="col-md-1 control-label " style={{ paddingLeft: "0rem", margin:"5px 0px 5px 0px" }}>{I18n.t('UserManagement.dealerCode')}</label>

                    </div>
                    <div className={"col-md-12 input-group control-label dealerFilter-" + this.props.theme.name} style={{ paddingLeft: '13px' }}>
                        <div className={"col-md-2 noPadding smallDD-" + this.props.theme.name}>

                            <Select
                                className={isNmscCodeDisabled ? "disabledField" : "activeField-" + this.props.theme.name}
                                //className = {"activeField-" + this.props.theme.name}
                                value={this.state.selectedNmscCode}
                                onChange={(e) => { this.onNmscChange(e) }}
                                options={this.state.nmscCodeList}
                                isDisabled={isNmscCodeDisabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />
                        </div>
                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <Select
                                //className = {isL1Disabled?"disabledField":"activeField-" + this.props.theme.name}
                                className={"activeField-" + this.props.theme.name}
                                value={this.state.selectedFranchise}
                                onChange={(e) => { this.onFranchiseChange(e) }}
                                options={this.state.franchiseList}
                                // isDisabled={isL1Disabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />

                        </div>
                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <Select
                                // className = {isL2Disabled?"disabledField":"activeField-" + this.props.theme.name}
                                className={"activeField-" + this.props.theme.name}
                                value={this.state.selectedCountry}
                                onChange={(e) => { this.onCountryChange(e) }}
                                options={this.state.countryList}
                                // isDisabled={isL2Disabled}
                                placeholder={I18n.t('standardMessages.all')}
                                isSearchable
                            />

                        </div>

                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isAutoSuggestionDisable ? <input
                                type="text"
                                className="form-control linkFormControl"
                                maxLength="100"
                                placeholder={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                disabled={true}
                            /> :
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsDealer}
                                    onSuggestionsFetchRequested={this.onSuggestionsDealerFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsDealerClearRequested}
                                    getSuggestionValue={this.getSuggestionDealerValue}
                                    renderSuggestion={this.renderSuggestionDealer}
                                    inputProps={inputDealerProps}
                                />
                            }
                        </div>
                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isAutoSuggestionDisable ? <input
                                type="text"
                                className="form-control linkFormControl"
                                maxLength="100"
                                placeholder={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                disabled={true}
                            /> :
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsCity}
                                    onSuggestionsFetchRequested={this.onSuggestionsCityFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsCityClearRequested}
                                    getSuggestionValue={this.getSuggestionCityValue}
                                    renderSuggestion={this.renderSuggestionCity}
                                    inputProps={inputCityProps}
                                />
                            }
                        </div>
                        <div />
                        <div className={"col-md-1 noPadding bigDD-" + this.props.theme.name}>
                            <input type="text"
                                className="form-control"
                                maxLength="50"
                                //placeholder ={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                style={{width:'10.2rem',height:'calc(1.5em + .75rem + 5px)'}}
                                value={this.state.dealerCode} onChange={this.dealerCodeChange} />

                        </div>

                    </div>

                    <div className="col-md-12 row control-label">

                        {/* <label className="col-md-2 control-label dealerFilterLabel"style={{paddingLeft:"0rem"}}>{I18n.t('UserManagement.L5')}</label>*/}
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem",marginRight:"12px" }}>{I18n.t("UserManagement.L3")}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: '0rem',marginRight:"10px" }}>{I18n.t('UserManagement.L4')}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: '0rem',marginRight:"10px" }}>{I18n.t('UserManagement.L5')}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: '0rem',marginRight:"10px" }}>{I18n.t('UserManagement.L6')}</label>
                        <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: '0rem',marginRight:"10px" }}>{I18n.t("UserManagement.a2d")}</label>
                        <label className="col-md-1 control-label " style={{ paddingLeft: "0rem", margin:"5px -10px 5px -10px" }}>
                            {I18n.t("userManagement.businessArea")}
                        </label>
                    </div>
                    <div className={"col-md-12 input-group control-label dealerFilter-" + this.props.theme.name}>

                        {/* <div style = {{margin : '10px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL3Disabled ? <Select
                                className="disabledField"
                                value={this.state.selectedL3}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled={true}
                            /> :
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedL3}
                                    onChange={(e) => { this.onL3Change(e) }}
                                    options={this.state.l3List}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            }
                        </div>
                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL4Disabled ? <Select
                                className="disabledField"
                                value={this.state.selectedL4}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled={true}
                            /> :
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedL4}
                                    onChange={(e) => this.onL4Change(e)}
                                    options={this.state.l4List}

                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            }
                        </div>
                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL5Disabled ? <Select
                                className="disabledField"
                                value={this.state.selectedL5}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled={true}
                            /> :
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedL5}
                                    onChange={(e) => this.onL5Change(e)}
                                    options={this.state.l5List}

                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            }
                        </div>

                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {isL6Disabled ? <Select
                                className="disabledField"
                                value={this.state.selectedL6}
                                placeholder={I18n.t('standardMessages.all')}
                                isDisabled={true}
                            /> :
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedL6}
                                    onChange={(e) => { this.onL6Change(e) }}
                                    options={this.state.l6List}

                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            }

                        </div>

                        <div style={{ margin: '5px' }} />
                        <div className={"col-md-2 noPadding smallDD-" + this.props.theme.name}>
                            <input type="text"
                                className="form-control"
                                maxLength="50"
                                // placeholder ={I18n.t('toastr.multiSelectPlaceHolderUser')}
                                style={{width:'11.6rem',height:'calc(1.5em + .75rem + 5px)'}}
                                value={this.state.a2dUuid} onChange={this.a2dChange} />
                        </div>
                        <div style={{ margin: '0px -1px 0px -1px' }} />
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}  style={{ maxWidth: "13.5%" }}   >
                                <Select
                                    className = {"activeField"+ this.props.theme.name}
                                    value={this.state.selectedBusinessFunc}
                                    options={this.state.businessFunc}
                                    onChange={(e)=>{this.onBusinessChange(e)}}
                                    placeholder={I18n.t('standardMessages.all')}
                                />
                            </div>
                        <div style={{ margin: '5px' }} />
                    </div>
                    {this.state.userProgram !== 'BRiT' ?
                        <div>
                            <div className="col-md-12 row control-label">
                            <div className={"col-md searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',margin:'2px -22px -5px 22px' }}>                                
                                <a 
                                    onClick={(e) =>this.search(e)}>
                                    <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("tmeAdminMaster.search")} 
                                        style = {{float : 'right' }}
                                    /> 
                                </a>
                            </div>
                            </div>
                        </div>  
                        : ""}      
                    {this.state.userProgram === 'BRiT' ?
                        <div>
                            <div className="col-md-12 row control-label">

                                {/* <label className="col-md-2 control-label dealerFilterLabel" style={{paddingLeft:"0rem"}}>{I18n.t('dealer.a2d')}</label> */}
                                <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem" }}>{I18n.t("UserManagement.britReady")}</label>
                                <label className="col-md-2 control-label dealerFilterLabel" style={{ paddingLeft: "0rem", marginLeft:"-5px" }}>{I18n.t("UserManagement.britLive")}</label>
                                <label className="col-md-2 control-label " style={{ paddingLeft: "0rem", margin: '5px 0px 5px -10px',wordBreak: 'break-word' }}>
                                    {I18n.t("UserManagement.britEss")}
                                </label>
                                <label className="col-md-2 control-label " 
                                    style={{ paddingLeft: "1.5rem", margin: '5px 0px 5px -15px',wordBreak: 'break-word' }}>
                                    {I18n.t('UserManagement.britJapan')}
                                </label>
                            </div>
                            <div className={"col-md-12 input-group control-label dealerFilter-" + this.props.theme.name}>

                                {/* <div style = {{margin : '10px'}}/> */}
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    <Select
                                        value={this.state.selectedBritReady}
                                        options={this.state.britReadyList}
                                        onChange={this.onBritReadyChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}
                                        placeholder={I18n.t('standardMessages.all')}
                                        isClearable = {true}
                                    />

                                </div>
                                <div style={{ margin: '5px' }} />
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    <Select
                                        value={this.state.selectedBritLive}
                                        options={this.state.britLiveList}
                                        onChange={this.onBritLiveChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}
                                        placeholder={I18n.t('standardMessages.all')}
                                        isClearable = {true}
                                    />

                                </div>

                                <div style={{ margin: '5px' }} />
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    <Select
                                        value={this.state.selectedEESAction}
                                        options={this.state.eesActionList}
                                        onChange={this.onEesActionChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}
                                        placeholder={I18n.t('standardMessages.all')}
                                        isClearable = {true}
                                    />
                                </div>
                                <div style={{ margin: '5px' }} />
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    <Select
                                        value={this.state.selectedBritJapan}
                                        options={this.state.britJapanList}
                                        onChange={this.onBritJapanChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}
                                        placeholder={I18n.t('standardMessages.all')}
                                        isClearable = {true}
                                    />
                                </div>                                
                                <div style={{ margin: '10px' }} />
                                <div className={"col-md searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px', margin:'2px -22px -5px 22px' }}>                                    
                                    <a 
                                        onClick={(e) =>this.search(e)}>
                                        <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("tmeAdminMaster.search")} 
                                        style = {{float : 'right' }}
                                        /> 
                                    </a>
                                </div>
                            </div>
                        </div>
                        : ""}

                </div>
                {/* <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div> */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        // dealerList: state.activityReducer,
        activityStatus: state.activityReducer,
        activityToolBox: state.activityReducer,
        toolboxByType: state.leftNavReducer,
        bulkUpload: state.bulkUploadReducer,
        linkFilter: state.userManagementReducer.linkFilters,
        dealerManagement: state.dealerManagementReducer


    };
};

export default withRouter(connect(mapStateToProps)(withTheme(SearchDealer)));