import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow, Fa } from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import '../index.css';
import { withTheme } from "../../../themes/theming";
var I18n = require("react-redux-i18n").I18n;
class OverdueActivities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: {}
        };
    }
    componentWillMount() {
        let activeProgram = this.props.user.userMdl.activeProgram;
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        // Fetch OverdueActivities.
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            nmscFlag: true,
            userId: this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("overdueActivities", "WIDGET_OVERDUE_ACTIVITIES", queryDto));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.overdueActivities
            && this.state.data && this.state.data.value !== nextProps.dashboardReducer.overdueActivities.value) {
            this.setState({ data: nextProps.dashboardReducer.overdueActivities, isLoading: false });
        }
    }
    render() {
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.overDueAct')}>{I18n.t('dashboard.overDueAct')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{ minHeight: "3rem" }}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {this.state.data.value}
                                    {this.state.data.arrowDirection ?
                                        <Fa icon={this.state.data.arrowDirection} style={{ color: this.state.data.arrowColor }}
                                            className="widget_arrow_class" size="lg" />
                                        : <div></div>}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="widget_brand_count_section">
                            <MDBCol lg="6" md="6">
                                <img src="images/TMEToyotaEllipse.png" alt="logo" height="21px" className="theme_logo" />
                            </MDBCol>
                            <MDBCol lg="6" md="6">
                                <img src="images/Lexus2D.jpg" alt="logo" height="20px" className="theme_logo" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol lg="6" md="6" className="widget_brand_count">{this.state.data.toyotaValue}</MDBCol>
                            <MDBCol lg="6" md="6" className="widget_brand_count">{this.state.data.lexusValue}</MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        dashboardReducer: state.dashboardReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(OverdueActivities)));