export default function bulkUploadReducer(state = {
  bukUploadResponse: {isUploaded:false},uploadActivityFileList:[]
}, action) {
  var newState;
  switch (action.type) {
    case "BULK_UPLOAD_DATA":
      newState = Object.assign({}, state, {
        bukUploadResponse: action.payload
      });
      return newState;

      case "BULK_DOWNLOAD_DATA":
        newState = Object.assign({}, state, {
          bukUploadResponse: action.payload
        });
        return newState;  
      case "QUESTIONNAIRE_DOWNLOAD_DATA":
        newState = Object.assign({}, state, {
          bukUploadResponse: action.payload
        });
        return newState; 
      case "UPLOAD_QUESTIONNAIRE_FILE_LIST":
        newState = Object.assign({}, state, {
          uploadQuestionnaireFileList: action.payload
        });
        return newState;   
    case "UPLOAD_ACTIVITY_FILE_LIST":
      newState = Object.assign({}, state, {
        uploadActivityFileList: action.payload
      });
      return newState;

    case "DOWNLOAD_ACTIVITY_FILE_LIST":
      newState = Object.assign({}, state, {
        downloadActivityFileList: action.payload
      });
      return newState;
    
    case "KPI_UPLOAD_DATA":
      newState = Object.assign({}, state, {
        bukUploadResponse: action.payload
      });
      return newState;
    case "KPI_UPLOAD_FILE_LIST":
      newState = Object.assign({}, state, {
        kpiUploadFileList: action.payload
      });
      return newState;
    case "BULK_DOWNLOAD_ACTIVITY_DATA":
        newState = Object.assign({}, state, {
          bukUploadResponse: action.payload
        });
        return newState;
    case "DATA_UPLOAD_FILE_LIST":
        newState = Object.assign({}, state, {
          dataUploadFileList: action.payload
        });
        return newState;
    case "UPLOAD_LIST":
        newState = Object.assign({}, state, {
          uploadList: action.payload
        });
          return newState;
    case "UPLOAD_KPI_LIST":
        newState = Object.assign({}, state, {
          uploadKpiList: action.payload
        });
        return newState;
    case "UPLOAD_QUESTIONNAIRE_LIST":
      newState = Object.assign({}, state, {
        uploadQuestionnaireList: action.payload
      });
      return newState;
    case "QUESTIONNAIRE_UPLOAD_FILE_LIST":
      newState = Object.assign({}, state, {
        questionnaireUploadFileList: action.payload
      });
      return newState;
    case "KPI_FILE_LIST":
      newState = Object.assign({}, state, {
        kpiFileList: action.payload
      });
        return newState;
    case "KPI_UPLOADED_FILE_LIST":
      newState = Object.assign({}, state, {
          kpiUploadedFileList: action.payload
      });
        return newState;
        
    default:
      return state;


  }
}