import React from 'react';
import { connect } from 'react-redux';
import { withTheme, themes } from '../../../themes/theming';
import { NavbarNav, NavItem,Fa } from 'mdbreact';
import { withRouter, Link } from 'react-router-dom';
import './index.css';
var I18n = require('react-redux-i18n').I18n;
const $ = require('jquery')
window.jQuery = $;

class DealerInternalMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            theme: themes.default
        };
        this.onClick = this.onClick.bind(this);
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    componentWillMount() {
        // alert("inside header" + JSON.stringify(this.props.user.userMdl))
    }

    addCss(e) {
        let selectedTabName = e.target.innerText;
        $("a").filter(function () {
            if ($(this).text() === selectedTabName) {
                $(this).addClass("selectedMenu");
            } else {
                $(this).removeClass("selectedMenu");
            }
            return null;
        });
    }

    render() {
        const navLinkClass = this.props.theme.name + "-nav-link nav-link";
        return (
            <NavbarNav left>  <NavItem active>

                 <Link className={navLinkClass} to="/home" onClick={this.addCss.bind(this)}>
                    <Fa icon="home" fixed className="mr-1" />
                    {I18n.t('TopNav.Home')}
                </Link> 
            </NavItem>
                <NavItem>
                      {/* <a className="nav-link" href="/toolbox"> */}
                      <Link className={navLinkClass} to="/toolbox" onClick={this.addCss.bind(this)}>
                    <Fa icon="wrench" fixed className="mr-1" />
                        {I18n.t('TopNav.Toolbox')}
                    </Link>
                </NavItem>
                <NavItem>
                    <Link className={navLinkClass} to="/activity" onClick={this.addCss.bind(this)}>
                        <Fa icon="briefcase" fixed className="mr-1" />
                        {I18n.t('TopNav.ActivityLog')}
                    </Link>
                </NavItem>
				{/* <NavItem >
                    <Link className={navLinkClass} to="/download" onClick={this.addCss.bind(this)}>
                        <Fa icon="download" fixed className="mr-1" />
                        {I18n.t('TopNav.Download')}</Link>
                </NavItem> */}
                {this.props.user.userMdl.activeProgram === "BRiT" ? 
                    <NavItem >
                        <a target="_blank" rel="noopener noreferrer" className={navLinkClass} href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.powerbi.com%2FRedirect%3Faction%3DOpenApp%26appId%3D9b474e7c-2783-49c8-a688-17ddf81555b7%26ctid%3D52b742d1-3dc2-47ac-bf03-609c83d9df9f&data=04%7C01%7Cvrushali.k%40infosys.com%7C042c6add914f49659b2c08d98190fb5f%7C63ce7d592f3e42cda8ccbe764cff5eb6%7C0%7C0%7C637683281974747315%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=Ove%2B4f6fxJZBYBcoUlZc2937ZeHW1MX%2BbhaNbdB6NfY%3D&reserved=0" onClick={this.addCss.bind(this)}>
                           {'Power BI'}
                        </a>
                    </NavItem> 
                : null}
            </NavbarNav>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.router
    }
}

export default withRouter(connect(mapStateToProps)(withTheme(DealerInternalMenu)));