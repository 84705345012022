import React from 'react';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';
import { withTheme } from '../../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Style from '../../library/style'; // eslint-disable-line
import '../index.css';
import { getDownloadPreSignedUrl, fetchEvalNmscFileDtlsPublished } from '../../../actions/libraryAction';
var I18n = require('react-redux-i18n').I18n;

class ViewLibFolderModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalFor: "",
            fileList: []
        }
        this.downloadFile = this.downloadFile.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(fetchEvalNmscFileDtlsPublished(this.props.folderId, this.props.versionId));
    }

    componentDidUpdate(prevProps, nextProps) {
        if (prevProps.folderId !== this.props.folderId) {
            //this.props.dispatch(fetchFileDtls(this.props.folderId));
            this.props.dispatch(fetchEvalNmscFileDtlsPublished(this.props.folderId, this.props.versionId));

        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.libraryReducer.fileListPublished) {
            this.setState({ fileList: [...nextProps.libraryReducer.fileListPublished] });
        }

    }
    downloadFile = (fileId, fileType) => {
        if (fileType !== 'DIR') {
            // alert("Download File with Id: " + fileId + " and file type: " + fileType);  
            this.props.dispatch(getDownloadPreSignedUrl(fileId, false));
        }
    }
    fileNameFormatter(row) {
        let icon = 'images/folder.png';
        if (row.fileType === 'doc' || row.fileType === 'docx') {
            icon = 'images/word.png';
        }
        if (row.fileType === 'xls' || row.fileType === 'xlsx') {
            icon = 'images/excel.png';
        }
        if (row.fileType === 'pdf') {
            icon = 'images/pdf.png';
        }
        if (row.fileType === 'jpg' || row.fileType === 'jpeg' || row.fileType === 'gif' || row.fileType === 'bmp'
        || row.fileType === 'png' || row.fileType === 'tif') {
            icon = 'images/image-icon.png';
        }

        if (row.fileType === 'ppt' || row.fileType === 'pptx') {
            icon = 'images/ppt-icon.png';
        }

        if (row.fileType === 'avi' || row.fileType === 'flv' || row.fileType === 'wmv' || row.fileType === 'mov' || row.fileType === 'mp4') {
            icon = 'images/video-icon.png';
        }
        if (row.fileType === 'txt') {
            icon = 'images/text.png';
        }
        return (
            <div style={{ cursor: 'pointer', paddingRight: '3px' }}>
                <img src={icon} height="20" width="20" style={{ marginRight: '4px' }} alt=""/>
                <span onClick={() => this.downloadFile(row.fileId, row.fileType)}>{row.fileName}</span>
            </div>
        );
    }

    preventDefault() { }
    render() {
        const fileDetails = this.state.fileList;
        return (
            <Container>
                <Modal
                    className={"addEditModal addEditModal-" + this.props.theme.name}
                    id="addEditActivityModal"
                    isOpen="true"
                    addMainQuestion={() => this.preventDefault()}
                >
                    <ModalHeader toggle={() => this.props.addMainQuestion(this.props.modalFor)}>
                        {this.props.modalFor === "Add" ? (I18n.t('questionnaire.links.question_link_view_folder')) : (I18n.t('questionnaire.links.question_link_view_folder'))}
                    </ModalHeader>
                    <ModalBody>

                        <Container className="viewLibraries">
                            <Row className="">
                                <Col xs="12" sm="12" md="12" lg="12">
                                    <ul style={{ listStyleType: 'none' }}>
                                        {fileDetails.map((file) => {
                                            return <li style={{ marginBottom: '1.5rem' }}>{this.fileNameFormatter(file)}</li>
                                        })}
                                    </ul>
                                </Col>
                            </Row>
                        </Container>

                    </ModalBody>
                    <ModalFooter>

                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ViewLibFolderModal)));
