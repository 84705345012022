import React from "react";
import BootstrapTable2 from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
// import { toastr } from "react-redux-toastr";
import { withTheme } from "../../../themes/theming";
import "../index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as AppConst from "../../../AppConstant";
var I18n = require("react-redux-i18n").I18n;


class FormulaTable extends React.Component {
    constructor(props) {
        super(props);
        console.log(props.formulaDTO);
        console.log(this.props.status);
        this.state = {
            formulaDto: props.type === 'View' || props.type === 'Edit' ? props.formulaDTO.map((obj, i) => {
                    obj.id = i + 1;
                    return obj;
            }) : [{ id: 1, kpiKey: "", operatorValue: "",kpiName:"" }],
            formulaText:"",
            selected: props.type === 'View' || props.type === 'Edit' ? [] : [1],
            notSelectable: props.type === 'View' ? props.formulaDTO.map(obj => { return obj.paramId }) : [],
            kpiEnable: false,
            operatorDropdown:[{
                value: '',
                label: ''
            },{
                value: '+',
                label: '+'
            }, {
                value: '-',
                label: '-'
            }, {
                value: '*',
                label: '*'
            }, {
                value: '/',
                label: '/'
            }, {
                value: '(',
                label: '('
            }, {
                value: ')',
                label: ')'
            },
            
        ],
        };
        this.handleChange =this.handleChange.bind(this);
        this.handleSelectChange=this.handleSelectChange.bind(this);
        this.handleOperatorSelectChange= this.handleOperatorSelectChange.bind(this);
    }

    componentDidMount() {
        if (this.props.type === 'View' || this.props.type === 'Edit') {
            this.props.formulaTableData(this.state.formulaDto);
            // console.log("componentDidMount")

            let selected = [];
            let paramDtoUpdated = [];
            if (this.state.formulaDto && this.state.formulaDto.length > 0) {
               
             this.setState({kpiEnable:true});
                
                this.state.formulaDto.map(obj => {
                    if (obj.dataEntryKet) {
                        if (this.props.type === 'View') {
                            selected.push(obj.paramId)
                        } else {
                            selected.push(obj.id)
                        }
                    }
                    if(obj.kpiKey!==AppConst.kpiKey){
                        paramDtoUpdated.push(obj)
                    }
                    return null;
                })
            }
            this.setState({ selected, formulaDto : paramDtoUpdated  })
        }
    }
    handleChange(event) {
        if (event.target.checked) {
         console.log('✅ Checkbox is checked');
         this.setState({kpiEnable:true});
       } else {
        this.setState({kpiEnable:false});
         console.log('⛔️ Checkbox is NOT checked');
       }
     }
     handleSelectChange(selectedOption,rowIndex)
     {
        console.log(selectedOption.label);
        console.log(selectedOption.value);
        // console.log(rowIndex);
        // console.log(this.state.formulaDto[rowIndex]);
         let formulaDtos = [...this.state.formulaDto]
         let formulaDto = {
            ...this.state.formulaDto[rowIndex],
            kpiKey: selectedOption.label,
            kpiName:selectedOption.value
        };
        formulaDtos[rowIndex]=formulaDto;
        this.setState({
            formulaDto:formulaDtos
        });

     }
     handleOperatorSelectChange(e,rowIndex)
    {
               let formulaDtos = [...this.state.formulaDto]
         let formulaDto = {
            ...this.state.formulaDto[rowIndex],
            operatorValue:e.target.value
        };
        formulaDtos[rowIndex]=formulaDto;
        this.setState({
            formulaDto:formulaDtos
        });
    }
    componentDidUpdate(prevProps, prevState) {

        if (prevState.formulaDto !== this.state.formulaDto) {
            // console.log("componentDidUpdate 1")

            this.props.formulaTableData(this.state.formulaDto);
        }



        if (prevState.formulaDto !== this.state.formulaDto) {
            // console.log("componentDidUpdate 2")
            var formula = "";
        this.state.formulaDto.forEach((obj) => {
            if(obj.kpiName!=="")
            {
                formula+=obj.kpiName+" ";
            }
            if(obj.operatorValue!=="")
            {
                formula+=obj.operatorValue+" ";
            }
          // formula.push(obj.kpiName+ " " + obj.operatorValue);
           this.setState({formulaText:formula})
        //    var total = formula.reduce(function(a){ return a}); 
        //     console.log("total is : " + total );
 
        });
            let selected = [];
            if (this.state.formulaDto && this.state.formulaDto.length > 0) {
                this.state.formulaDto.map(obj => {
                    if (obj.dataEntryKet) {
                        if (this.props.type === 'View') {
                            selected.push(obj.paramId)
                        } else {
                            selected.push(obj.id)
                        }
                    }
                    return null;
                })
            }
            this.setState({ selected })
        }
    }
    
    headerAdd = (cell, row) => {
       //console.log(cell, row)
      //  console.log(this.state.selectedRow.status);
        //add parameter button
        if (this.props.type !== 'View' && this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet) {
            if (this.state.formulaDto !== undefined && this.state.formulaDto.length <= 9) {
                return (
                 
                    <button
                        className={"addParam-" + this.props.theme.name}
                        title={I18n.t("CeaKpi.AddRows")}
                        onClick={() => this.addRow(cell, row)} style={{marginLeft: "20px"}}
                    >
                        {I18n.t("CeaKpi.AddRows")}
                    </button>
                );
            } else {
                return (
                    // <div>
                    //     <a><div title={I18n.t("CeaKpi.maxFiveParam")} className="addDisabledIcon cea-kpiAddIcon" /></a>
                    // </div>
                    <button style={{ cursor: "not-allowed" }}
                        className={"addParam-" + this.props.theme.name}
                        title={I18n.t("CeaKpi.maxFiveParam")}
                    >
                        {I18n.t("CeaKpi.addParam")}
                    </button>
                );
            }
        }
    }

    deleteRow = (cell, row, rowIndex) => {
        // console.log(cell, row, rowIndex)
        // if (this.props.user.userMdl.activeProgram !== AppConst.activeProgramLN){
        return (
            <div className="btn-toolbar float-right">
                <a onClick={(e) => this.deleteParam(cell, row, rowIndex)}><div title={I18n.t('CeaKpi.delete')} className="delete-icon" /></a>
            </div>
        );
    // }


    }

    deleteParam = (cell, row, rowIndex) => {
        let formulaDto = [...this.state.formulaDto]
        // console.log(paramDto)
        let newParamDto = []
        let tempParamDto = formulaDto.filter(obj => obj.id !== cell);

        // this.setState(() => ({
        //     selected: this.state.selected.filter(x => x !== cell)
        // }));
        tempParamDto.map((obj, i) => {
            obj.id = i + 1;
            newParamDto.push(obj);
            return null;
        });

        // console.log(newParamDto)
        this.setState({ formulaDto: newParamDto });
    }
    addRow = (cell, row) => {
       // console.log(cell.data)
        let formulaDto = [...this.state.formulaDto]
        console.log(formulaDto)
        formulaDto.push({ id: formulaDto.length + 1, kpiKey: "", operatorValue: "",kpiName:"" })

        let selected = [...this.state.selected]
        selected.push(formulaDto.length + 1)
        // console.log(paramDto)
        this.setState({
            formulaDto: formulaDto
            , selected: selected
        });

    }

    onAfterSaveCell = (oldValue, newValue, row, cell) => {
        // console.log(oldValue, newValue, row, cell)
        // if (cell.dataField === "kpiKey") {
        //     if (this.validateInputs(row.kpiKey, cell.dataField)) {
        //         return;
        //     }
        //     row.kpiKey = newValue;
        // }
        // if (cell.dataField === "operatorValue") {
        //     if (this.validateInputs(row.operatorValue, cell.dataField)) {
        //         return;
        //     }
        //     row.operatorValue = newValue;
        // }
        console.log(this.state.formulaDto);
        var formula = [];
        if(this.state.kpiEnable)
        {
        this.state.formulaDto.map(item=>formula.push((item)));
        }
        console.log(formula);
        this.props.formulaTableData(this.state.formulaDto);
    }

    inputFormatter = (cell, row, rowIndex) => {
        if( this.props.type !== 'View' &&  (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet) && (this.props.status!=='PROCESSED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN) && (this.props.status!=='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)){
            
            return (
                <select className="parameter_select">
                    <option value={cell}>{cell}</option>
                </select>
            );

        }else{
            return (
                <input
                    type="text"
                    defaultValue={(cell && cell !== undefined)?cell : ""}
                    className="form-control"
                    disabled={
                    (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? true
                    : (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true: (this.props.status==="PUBLISHED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true: this.props.type !== 'View' ? false : true}
                />
                
            );
        }
       
    };

    textAreaFormatter = (cell, row, rowIndex) => {
        return (
            <input
                type="textarea"
                defaultValue={(cell && cell !== undefined) ? cell : ""}
                className="form-control"
                disabled={
                    (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? true
                    : (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true: (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?true: this.props.type !== 'View' ? false : true}
            />
        );
    };

    render() {

        // console.log(this.props.formulaDto)
        // console.log(this.props.status)
        console.log(this.state.formulaDto)
        // console.log(this.state.selected)
        // console.log(this.state.notSelectable)
        var publishkpi = this.props.publishkpi;
        console.log(publishkpi);
        if(publishkpi!==undefined){ 
            var optionData =[];
            optionData.push({value:"" ,label: "" });
        publishkpi.kpiList.map(kpi=>(optionData.push({ value: kpi.name ,label: kpi.localKpiId }) ));
        console.log(publishkpi);
        console.log(optionData);      
        }
        const selectRow = {
            // mode: 'checkbox',
            selectionRenderer:this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ?  ({ mode, checked, disabled }) => (
                disabled
              ) : false,
            clickToSelect: false,
            clickToEdit: false,
            selected: this.state.selected,
            nonSelectable: this.props.type === 'View' || this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ? this.state.notSelectable : [],

            onSelect: (row, isSelect, rowIndex, e) => {
                // console.log(row, isSelect, rowIndex, e)

                if (this.props.type !== 'View') {

                    if (isSelect) {
                        this.setState(() => ({
                            selected: [...this.state.selected, row.id]
                        }));
                    } else {
                        this.setState(() => ({
                            selected: this.state.selected.filter(x => x !== row.id)
                        }));
                    }
                    let formulaDto = [...this.state.formulaDto];

                    formulaDto = formulaDto.map((obj, i) => {
                        if (rowIndex === i) {
                            obj.dataEntryKet = isSelect;
                            return obj;
                        } else {
                            return obj;
                        }
                    })
                    // console.log(paramDto)

                    this.setState({ formulaDto });
                }
            },
            // hideSelectAll: true,
            // selectionHeaderRenderer: () => I18n.t("CeaKpi.dataEntry"),

        };



        var columns = [
            // {
            //     dataField: 'kpiKey',
            //     text: I18n.t('CeaKpi.Select Published Kpi'),

            //     style: {
            //         width: "30%", textAlign: "left", wordWrap: "break-word",
            //         overflow: "hidden",
            //         textOverflow: "ellisis"
            //     },
            //     editable:
            //     (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? false :
            //     (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?false:
            //     this.props.type === 'View' ? false : true,
            //     headerStyle: { width: "18%", textAlign: "left" },
            //     formatter: this.inputFormatter,
            //     editorRendererType:Type.SELECT,
            //     editorRendererOptions:{optionData},
            //     editorRenderer:(editorProps,value,row,column,rowIndex,colIndex,columnIndexInall,rowIndexInAll)=>
            //     (
            //             <select value={value}
            //             className='form-control'
            //             onChange={(e)=>{
            //                 {optionData.map((option,id)=>(
            //                     <option key={id} value={option.value}>
            //                         {option.label}
            //                     </option>
            //                 ))}
            //                 const selectedOption=optionData.find((option)=>option.value==e.target.value);
            //                 this.handleSelectChange(selectedOption,rowIndex)}}>
            //             </select>
            //     ),
            //     // editor: {
            //     //     type: Type.SELECT,
            //     //     options:optionData
            //     // },

            //     editCellStyle: (cell, row, rowIndex, colIndex) => {
            //         const width = "30%";
            //         return { width };
            //     },
            // },
            {
                dataField: 'kpiName',
                text: I18n.t('CeaKpi.Select Published Kpi'),

                style: {
                    width: "30%!important", textAlign: "left", wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                editable:
                (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? false :
                (this.props.status==='PUBLISHED' || this.props.status==='PROCESSED') && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN?false:
                this.props.type === 'View' ? false : true,
                headerStyle: { width: "27%", textAlign: "left" },
                formatter: this.inputFormatter,
                editorRendererType:Type.SELECT,
                editorRendererOptions:{optionData},
                editorRenderer:(editorProps,value,row,column,rowIndex,colIndex,columnIndexInall,rowIndexInAll)=>
                (
                        <select value={value}
                        className='form-control'
                        onChange={(e)=>{
                            console.log(e.target[e.target.selectedIndex].label);
                            const selectedOption=optionData.find((option)=>option.value===e.target.value);
                            this.handleSelectChange(selectedOption,rowIndex)
                            }}>
                            {
                                optionData.map((option,id)=>(
                                <option key={id} value={option.value}>
                                    {option.value}
                                </option>
                            ))}
                         
                        </select>
                ),
                // editor: {
                //     type: Type.SELECT,
                //     options:optionData
                // },

                editCellStyle: (cell, row, rowIndex, colIndex) => {
                    const width = "30%";
                    return { width };
                },
            },


            {
                dataField: 'operatorValue',
                text: I18n.t('CeaKpi.Operator'),
                formatter: this.inputFormatter,
                style: {
                    width: "45%", textAlign: "center", wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                headerStyle: { width: "23%", textAlign: "right" },
                editable: 
                (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? false :
                (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?false:
                this.props.type === 'View' ? false : true,

                editCellStyle: (cell, row, rowIndex, colIndex) => {
                    const width = "45%";
                    return { width };
                },
                editorRendererType:Type.SELECT,
                editorRendererOptions:[{
                        value: '',
                        label: ''
                    },{
                        value: '+',
                        label: '+'
                    }, {
                        value: '-',
                        label: '-'
                    }, {
                        value: '*',
                        label: '*'
                    }, {
                        value: '/',
                        label: '/'
                    }, {
                        value: '(',
                        label: '('
                    }, {
                        value: ')',
                        label: ')'
                    },
                    
                ],
                editorRenderer:(editorProps,value,row,column,rowIndex,colIndex,columnIndexInall,rowIndexInAll)=>
                (
                        <select value={value}
                        className='form-control'
                        onChange={(e)=>{
                            this.handleOperatorSelectChange(e,rowIndex)
                            }}>
                            {
                                this.state.operatorDropdown.map((option,id)=>(
                                <option key={id} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                         
                        </select>
                ),
                // editor: {
                //     type: Type.SELECT,
                //     options: [{
                //         value: '+',
                //         label: '+'
                //     }, {
                //         value: '-',
                //         label: '-'
                //     }, {
                //         value: '*',
                //         label: '*'
                //     }, {
                //         value: '/',
                //         label: '/'
                //     }, {
                //         value: '(',
                //         label: '('
                //     }, {
                //         value: ')',
                //         label: ')'
                //     },
                    
                // ]
                // },
                
            },
            
            {
              
                dataField: "id",
                text: I18n.t('Table.action'),
                headerFormatter: this.headerAdd,
                formatter: this.deleteRow,
                style: { width: "10%" },
                headerStyle: { width: "35%" },
                editable: false,
                hidden: this.props.type === 'View' || this.props.status === 'PUBLISHED'|| this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? true : false

            }];

        return (
            <div className="parameterTable">
                   <input type="Checkbox" value={this.props.type}  onChange={ this.handleChange }
                    checked={this.state.kpiEnable}
                 disabled={
                   (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?true: this.props.type !== 'View' ? false : true}
/> Use Formula
                 
                 {this.state.kpiEnable===true?
                <BootstrapTable2 
                    keyField={this.props.type !== 'View' ? 'id' : 'paramId'}
                    data={this.state.formulaDto}
                    noDataIndication={I18n.t('CeaKpi.noParamAvai')}
                    columns={columns}
                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        // afterSaveCell: this.onAfterSaveCell
                    })}
                    selectRow={selectRow}
                />
                :""}
                  <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.formula")}
                      <span className="redFont"> *</span>
                    </label>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="70"
                        value={this.state.formulaText}
                        disabled={true}
                      />
                    </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
return {
    user: state.loginReducer
};
};
export default withRouter(connect(mapStateToProps)(withTheme(FormulaTable)));
  