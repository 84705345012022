export default function loginReducer(state = {
  userMdl: {}, blocking: false
}, action) {
  switch (action.type) {
    case "SET_USER_DETAILS":
      return Object.assign({}, state, {
        userMdl: action.payload
      });
    case "SET_BLOCKING_STATE":
      return Object.assign({}, state, {
        blocking: action.payload
      });
    case "LOG_OUT":
      return null;
    default:
      return state;
  }
};
