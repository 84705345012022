import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
import {fetchLinkDealerList,fetchLinkCityList} from './userManagementAction.jsx'
var I18n = require("react-redux-i18n").I18n;

export function fetchDealers(dealerDto){

    return function (dispatch) {
     dispatch(setBlockingState(true));
      axios
        .post("/dealerManagement/fetchDealers" ,dealerDto, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          console.log(response.data)
  
          dispatch({
            type: "FETCH_DEALER_REFRESH",
            payload: response.data
          })
          console.log(response.data)

        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
    
  }

  export function searchEmptyDealerList() {

    return function (dispatch) {
      dispatch({
        type: "FETCH_DEALER_REFRESH",
        payload: null
      });
  
    };
  }


  export function saveSearchFilters(dealerDto) {
    return function (dispatch) {
      dispatch({
        type: "SAVE_SEARCH_FILTER",
        payload: dealerDto
      });

    };
  }

  export function refreshSearchFilter() {

    return function (dispatch) {
      dispatch({
        type: "SEARCH_FILTER_REFRESH",
        payload: undefined
      });
  
    };
  }



  export function deleteDealer(networkLevelDealerId,userDto) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .delete("/dealerManagement/delete/" + networkLevelDealerId, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          if (response.data.statusCode === "200") {
            toastr.success(I18n.t("tabDetails.dealerDeleted"));
          }
          if (response.data.statusCode === "400") {
            toastr.error(I18n.t("tabDetails.activeDealer"));
          }
          dispatch({
            type: "DELETE_DEALER_DATA",
            payload: response.data
          });
          dispatch(fetchDealers(userDto));
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  }

  export function fetchDealersNameList(nmsc,langCode){
    return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get("/dealerManagement/fetchDealerNameList/"+nmsc+"/"+langCode, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        console.log(response.data)

        dispatch({
          type: "FETCH_DEALERS_NAME_LIST",
          payload: response.data
        })
        console.log(response.data)

      })
      .catch(function (error) {
       dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  
    }  };


  export function fetchA2DDealerDetails(fetchBy,dealerDto,toggleUpdateUuid,onClose,nmscCode){
    return function (dispatch) {
    // dispatch(setBlockingState(false));
    axios
      .post("/dealerManagement/fetchA2DDealerDetails/"+fetchBy,dealerDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
      //dispatch(setBlockingState(false));
        let type =
          fetchBy === "dealerName"
            ? "A2D_DEALER_DETAILS_BY_NAME":"A2D_DEALER_DETAILS_BY_UUID"
        
      if(fetchBy==="dealerUuid"){ 


        if(response.data.duplicateDealer){
          toastr.error(I18n.t("ServerMessage.duplicate_dealer_uuid"));
        }
        else if(response.data.id === undefined || response.data.id === null){
          toastr.error(I18n.t("ServerMessage.dealer_not_present"))
        }else if(response.data.nmscCode !== nmscCode){
          toastr.error(I18n.t("ServerMessage.dealer_uuid_other_nmsc"))
        }else{
        dispatch({
          type: type,
          payload: response.data
        })
        // toastr.success("Uuid Updated Successfully")\
        onClose()
        toggleUpdateUuid()
        }
        
      }
      else{
        dispatch({
          type: type,
          payload: response.data
        })
      }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message")
        );
        if(fetchBy==="Edit"){
        onClose()
        toggleUpdateUuid()
        }
      });
    }
    };


  export function fetchNetworkLevelDropdown(dealerDto, searchFor) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .post("/userManagement/linkFilters/" + searchFor, dealerDto, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          var type =
                   searchFor === "l3"
                    ? "L3_DEALER_DATA"
                    : searchFor === "l4"
                      ? "L4_DEALER_DATA"
                      : searchFor === "l5"
                        ? "L5_DEALER_DATA"
                        : "L6_DEALER_DATA"
  
          //dispatch(setBlockingState(false));
          dispatch({
            type: type,
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }
  export function refreshNetworkLevelData() {
    return function (dispatch) {
      dispatch({
        type: "REFRESH_DATA",
        payload: {}
      });
  
    };
  }
  

  export function saveDealer(dealerDto, searchDto, isSearchClicked, this_) {
    
    return function (dispatch) {
      dispatch(setBlockingState(true));
      axios
        .post("/dealerManagement/saveDealer", dealerDto, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
        
          if (response.data.desc === 'network_level_not_found' || response.data.desc === 'duplicate_dealer_uuid'
            ) {
            toastr.error(I18n.t("ServerMessage." + response.data.desc));
  
          } else {
            this_.props.toggle(true);
            this_.refreshStoreData()
            toastr.success(I18n.t("ServerMessage." + response.data.desc));
            if (isSearchClicked === true) {
              dispatch(fetchDealers(searchDto));
            }
            dispatch(fetchLinkDealerList(searchDto));
            dispatch(fetchLinkCityList(searchDto));
          }
  
  
        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          this_.props.toggle(true);
          this_.refreshStoreData()
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }

  export function fecthDealerlanguage(queryDto) {
    return function (dispatch) {
      dispatch(setBlockingState(true));
      console.log(queryDto)
      axios.post("/userManagement/fecthDealerlanguage", queryDto, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          dispatch(setBlockingState(false));
          dispatch({
            type: "DEALER_LANG",
            payload: response.data.languageCode
          });
        })
        .catch(function (error) {
          dispatch(setBlockingState(false));
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }

  export function deleteDealerActivities(networkLevelDealerId,businessAreaKey) {
    return function (dispatch) {
      axios
        .delete("/dealerManagement/deleteDealerActivities/" + networkLevelDealerId+"/"+ businessAreaKey, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {          
          if (response.data.statusCode === "200") {
            // toastr.success(I18n.t("tabDetails.dealerDeleted"));
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    };
  }