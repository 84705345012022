import React from "react";
import { Row } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { fetchFileDtlsPublished, fetchFileStatus, deleteFile, fetchNmscPublishedFileDtls, fetchNmscFileStatus } from '../../../actions/libraryAction';
import BootstrapTable from 'react-bootstrap-table-next';
import { confirmAlert } from 'react-confirm-alert'; // Import
var I18n = require("react-redux-i18n").I18n;

class FolderDetails extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            alreadyLinked: [],
            alreadyLinkedFiles: [],
            fileList: [],
            fileStatus: '',
            selected: [],
            selectedRow: [],
            nonSelected: [],
            filesForPublish: [],
            isSelectAll: false,
            folderId: '',
            fileListFlag: false
        }
        this.libFileTblRef= React.createRef();
        this.fileNameFormatter = this.fileNameFormatter.bind(this);
        this.actionFormatter = this.actionFormatter.bind(this);
        this.changeFiles = this.changeFiles.bind(this);
        this.selectAll = this.selectAll.bind(this);
    }

    componentWillMount() {
        var isNMSCAdminUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isNMSCAdminUser = true;
                    break;
                }
            }
        }
        this.setState({ isNMSCAdminUser: isNMSCAdminUser });
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedNodeId !== nextProps.selectedNodeId && nextProps.selectedNodeId && nextProps.selectedNodeId !== '') {
            console.log("sub folder changed" + nextProps.selectedNodeId);
            // fetch file details for selected folder.
            this.setState({ folderId: nextProps.selectedNodeId });
            if (this.state.isNMSCAdminUser) {
                this.props.dispatch(fetchNmscPublishedFileDtls(nextProps.selectedNodeId, this.props.user.userMdl.nmsc));
                this.props.dispatch(fetchNmscFileStatus(nextProps.selectedNodeId));
            }
            else {
                this.props.dispatch(fetchFileDtlsPublished(nextProps.selectedNodeId));
                this.props.dispatch(fetchFileStatus(nextProps.selectedNodeId));
            }
            let data = [];
            nextProps.filesForPublish.map((file) => {
                if (file.folderId === nextProps.selectedNodeId) {
                    file.files.map((id) => {
                        data.push(id.fileId);
                    })
                }
            })
            console.log(data);
            this.setState({ selectedRow: data });
        }

        this.state.fileListFlag = false;
        console.log("file status" + nextProps.libraryReducer.fileStatus)
        if (nextProps.libraryReducer.fileStatus > 0) {
            this.state.fileListFlag = true;
        }
        else {
            this.state.fileListFlag = false;
        }
        this.props.fileListFlag(this.state.fileListFlag);

        // if (nextProps.libraryReducer.fileListPublished) {
        //     const fileListPublished = [...nextProps.libraryReducer.fileListPublished];
        //     this.setState({ fileList: fileListPublished });
        // }
        if (nextProps.libraryReducer.fileListPublished && nextProps.includedSubFolder) {
            let nonSelectableFile = [];
            const fileList = [...nextProps.libraryReducer.fileListPublished];
            for (var i = 0, size = fileList.length; i < size; i++) {
                var item = fileList[i];
                nonSelectableFile.push(item.fileId);
            }
            this.setState({ nonSelected: nonSelectableFile, fileList: fileList });
        }
        if (nextProps.libraryReducer.fileListPublished && !nextProps.includedSubFolder) {
            const fileList = [...nextProps.libraryReducer.fileListPublished];
            const alreadyLinked = [...this.props.alreadyLinked];
            let fileIds = [];
            alreadyLinked.map((file) => {
                if (file.fileId !== 0) {
                    fileIds.push(file.fileId);
                }
            });
            this.setState({ nonSelected: fileIds, fileList: fileList });
        }
        if (this.props.filesForPublish !== nextProps.filesForPublish) {
            console.log(nextProps.filesForPublish);
            // fetch file details for selected folder.
            let data = [];
            nextProps.filesForPublish.map((file) => {
                if (file.folderId === nextProps.selectedNodeId) {
                    file.files.map((id) => {
                        data.push(id.fileId);
                    })
                }
            })
            const alreadyLinked = [...this.props.alreadyLinked];
            let fileIds = this.state.nonSelected;
            alreadyLinked.map((file) => {
                if (file.fileId !== 0) {
                    fileIds.push(file.fileId);
                }
            });

            if (nextProps.includedSubFolder) {
                const fileList = [...nextProps.libraryReducer.fileListPublished];
                for (var i = 0, size = fileList.length; i < size; i++) {
                    var item = fileList[i];
                    fileIds.push(item.fileId);
                }
            }
            console.log(data);
            this.setState({ filesForPublish: nextProps.filesForPublish, fileList: nextProps.libraryReducer.fileListPublished });
            this.setState({ selectedRow: data, nonSelected: fileIds });
        }
        if (this.props.alreadyLinked !== nextProps.alreadyLinked) {
            let alreadyLinked = [];
            alreadyLinked = [...this.props.alreadyLinked];
            let fileIds = this.state.nonSelected;
            alreadyLinked.map((file) => {
                if (file.fileId !== 0) {
                    fileIds.push(file.fileId);
                }
            });
            if (alreadyLinked.length > 0) {
                this.setState({ alreadyLinked: alreadyLinked, nonSelected: fileIds });
            }

        }

    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.filesForPublish !== this.props.filesForPublish) {
            this.setState({ filesForPublish: this.props.filesForPublish, nonSelectable: [] });
        }
    }
    fileNameHeaderFormatter(col, colIndex) {
        return (
            <div>
                {col.text}
            </div>
        );
    }
    fileNameFormatter(cell, row) {
        let icon = 'images/folder.png';
        if (row.fileType === 'doc' || row.fileType === 'docx') {
            icon = 'images/word.png';
        }
        if (row.fileType === 'xls' || row.fileType === 'xlsx') {
            icon = 'images/excel.png';
        }
        if (row.fileType === 'pdf') {
            icon = 'images/pdf.png';
        }

        if (row.fileType === 'jpg' || row.fileType === 'jpeg' || row.fileType === 'gif' || row.fileType === 'bmp'
        || row.fileType === 'png' || row.fileType === 'tif') {
            icon = 'images/image-icon.png';
        }

        if (row.fileType === 'ppt' || row.fileType === 'pptx') {
            icon = 'images/ppt-icon.png';
        }

        if (row.fileType === 'avi' || row.fileType === 'flv' || row.fileType === 'wmv' || row.fileType === 'mov' || row.fileType === 'mp4') {
            icon = 'images/video-icon.png';
        }
        if (row.fileType === 'txt') {
            icon = 'images/text.png';
        }
        return (
            <div style={{ cursor: 'pointer' }}>
                <img src={icon} height="20" width="20" style={{ marginRight: '4px' }} alt=""/>
                {cell}
            </div>
        );
    }
    actionFormatter(cell, row) {
        return (
            <div className="btn-toolbar float-right">
                <a><div style={{ whiteSpace: 'pre', cursor: 'pointer', marginTop: '5px' }} title={row.desc} className="additionalInfo" /></a>
                {row.fileType !== 'DIR' && row.status !== "Retired" ?
                    <div>
                        <div title={I18n.t("tmeAdminMaster.delete")} onClick={() => this.deleteLibFile(row)} className="delete-icon" style={{ cursor: 'pointer', marginTop: '4.9px' }} />
                        <div title={I18n.t('tmeAdminMaster.edit')} onClick={() => this.props.toggle('Edit', row)} className="edit-icon" style={{ cursor: 'pointer', marginTop: '4.9px' }} />
                    </div>
                    : <div></div>}
            </div>
        );
    }
    selectAll = (selectedRows) => {
        let filesList = [];
        selectedRows.map((selectedFile) => {
            this.state.fileList.map((file) => {
                if (file.fileId === selectedFile) {
                    filesList.push(file);
                }
            });
        })
        let selectedFiles = {
            folderId: this.state.folderId,
            files: filesList
        }
        // if(selectedRows.length>0){
        this.props.selectedFiles(selectedFiles, this.state.nonSelected);
        // }
    }
    changeFiles = () => {
        console.log(this.state.selectedRow);
        let filesList = [];
        this.state.selectedRow.map((selectedFile) => {
            this.state.fileList.map((file) => {
                if (file.fileId === selectedFile) {
                    filesList.push(file);
                }
            });
        })
        let selectedFiles = {
            folderId: this.state.folderId,
            files: filesList
        }
        console.log("Length is " + selectedFiles.files.length);
        this.props.selectedFiles(selectedFiles, this.state.nonSelected);
    }


    deleteLibFile = (row) => {

        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {row.publishedDate === "" ? 
                                I18n.t("library.library_files_deleted")
                                :
                                I18n.t("library.library_files_linked_deleted")}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.retireFile(row);
                                    this.props.dispatch(deleteFile(row, this.state.folderId))
                                    onClose();
                                }}>
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });



    }
    render() {
        const columns = [
            {
                dataField: "fileName",
                text: "Files",
                sort: true,
                style: { width: "90%", textAlign: "left" },
                headerStyle: { width: "80%", textAlign: "left" },
                headerFormatter: this.fileNameHeaderFormatter,
                formatter: this.fileNameFormatter,
                title: true
            }];

        const mainFoldercolumns = [
            {
                dataField: "fileName",
                text: "",
                sort: true,
                style: { width: "95%", textAlign: "left" },
                //headerStyle: { width: "30%", textAlign: "left" },
                headerFormatter: this.fileNameHeaderFormatter,
                formatter: this.fileNameFormatter,
                title: true
            },
            {
                dataField: "questionReference",
                text: "",
                sort: true,
                style: { width: "1%", textAlign: "center", paddingLeft: "40px" },
                // headerStyle: { width: "24%", textAlign: "center" },
                title: true
            },
            {
                dataField: "",
                text: "",
                //formatter: this.actionFormatter,
                style: { width: "1%", textAlign: "center" },
                // headerStyle: { width: "1%" }
            }
        ];


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox") {
                    if (!this.state.nonSelected.includes(row.fileId)) {
                        e.target.checked = true;
                        var index = -1;
                        this.state.selectedRow.map((file) => {
                            if (index === -1) {
                                if (file === row.fileId) {
                                    index = this.state.selectedRow.indexOf(file);
                                }
                            }
                        })
                        console.log("index matched:" + index);
                        if (index > -1) {
                            this.state.selectedRow.splice(index, 1);
                        } else {
                            this.state.selectedRow.push(row.fileId);
                        }
                        console.log(this.state.selectedRow);
                        this.changeFiles();
                    }
                }

                //this.props.selectedFiles(this.state.selected);
            }
        }
        const RowSelection = {
            mode: 'checkbox',
            clickToSelect: false,
            nonSelectable: this.state.nonSelected,
            selected: this.state.selectedRow,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                if (isSelect) {
                    rows.forEach((row) => {
                        if (!this.state.nonSelected.includes(row.fileId)) {
                            selectedRows.push(row.fileId);
                        }
                    });
                }
                this.selectAll(selectedRows);
                this.setState({ selectedRow: selectedRows });
                return selectedRows;
            }
        };

        return (
            <Row className="ml-1 mt-2">
                {this.props.selectedNodeId ? <BootstrapTable
                    ref={this.libFileTblRef}
                    keyField="fileId"
                    classes="libFileTbl"
                    data={this.state.fileList}
                    columns={columns}
                    rowEvents={rowEvents}
                    selectRow={RowSelection}
                    hover
                    condensed
                /> : (
                        <div />
                    )}
            </Row>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(FolderDetails)));
