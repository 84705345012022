import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import {
  fetchCeaNetKpiList,
  fetchBusinessFunctionPoints,
  fetchBusinessFunctionStatus,
  publishCeaKPIs,
  publishNetUcKPIs,
  deleteCeaNetKpi
} from "../../actions/ceaNetKPIAction.jsx";
import { launchTargetSetting } from "../../actions/activityAction";
import { withRouter } from "react-router-dom";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { withTheme } from "../../themes/theming";
import "./index.css";
import { toastr } from "react-redux-toastr";
import CeaNetKpiModal from "../ceaNetKpiModal";
import * as AppConst from "../../AppConstant";
import { Fa } from "mdbreact";

var I18n = require("react-redux-i18n").I18n;

class CeaNetKpiTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      ImportModal: false,
      modalFor: "",
      user: [],
      ceaNetKpiList: [],
      isCeaAdmin: false,
      selectedMandatory: [],
      isBO: false,
      ceaNetKpiIdList: [],
      selectedKpi: [],
      businessFunctionPoints: 0,
      businessFunctionStatus: "",
      totalKpiPoints: 0,

      userDetail: {
        username: this.props.user.userMdl.username,
        nmsc: this.props.user.userMdl.nmsc,
        roles: this.props.user.userMdl.roles,
      },

      selectedRow: {
        brand: "",
        subPillar: "",
        name: "",
        definition: "",
        additionalInfo: "",
        targetType: "",
        target: "",
        unit: "",
        evaluationMethod: "",
        scoringMethod: "",
        mode: "",
        mandatory: true,
        status: "",
        dependsOn:"",
        points: null,
        evaluationFrequency: "",
        packageProgramMappingId: this.props.packageMapPrimaryKey,
        localKpiId: null,
        createdBy: this.props.user.userMdl.username,
        updatedBy: this.props.user.userMdl.username,
        nmscCode: this.props.user.userMdl.nmsc,
        userName: this.props.user.userMdl.username,
      },
    };
    this.tableRef= React.createRef();
    this.buttonFormatter = this.buttonFormatter.bind(this);
    this.headerAdd = this.headerAdd.bind(this);
  }

  componentWillMount() {
    // console.log(this.props.packageMapPrimaryKey)
    let userDetail = {
      username: this.props.user.userMdl.username,
      nmsc: this.props.user.userMdl.nmsc,
      roles: this.props.user.userMdl.roles,
    };

    this.props.dispatch(
      fetchBusinessFunctionPoints(
        this.props.packageMapPrimaryKey,
        this.props.user.userMdl.activeProgramID
      )
    );
    this.props.dispatch(
      fetchBusinessFunctionStatus(
        this.props.packageMapPrimaryKey,
        this.props.user.userMdl.activeProgramID
      )
    );
    this.props.dispatch(
      fetchCeaNetKpiList(
        this.props.packageMapPrimaryKey,
        this.props.user.userMdl.activeProgramID,
        userDetail
      )
    );
  }
  componentWillReceiveProps(nextProps) {
    if (this.props.packageMapPrimaryKey !== nextProps.packageMapPrimaryKey) {
      let userDetail = {
        username: this.props.user.userMdl.username,
        nmsc: this.props.user.userMdl.nmsc,
        roles: this.props.user.userMdl.roles,
      };

      this.setState({ ceaNetKpiIdList: [] });
      this.props.dispatch(
        fetchCeaNetKpiList(
          nextProps.packageMapPrimaryKey,
          this.props.user.userMdl.activeProgramID,
          userDetail
        )
      );
      this.props.dispatch(
        fetchBusinessFunctionPoints(
          nextProps.packageMapPrimaryKey,
          this.props.user.userMdl.activeProgramID
        )
      );
      this.props.dispatch(
        fetchBusinessFunctionStatus(
          nextProps.packageMapPrimaryKey,
          this.props.user.userMdl.activeProgramID
        )
      );
    }

    if (nextProps.ceaNetKpiList) {
      this.setState({
        ceaNetKpiList: nextProps.ceaNetKpiList.kpiList,
        totalKpiPoints: nextProps.ceaNetKpiList.totalKpiPoints,
      });
      const ceaNetKpiList = nextProps.ceaNetKpiList.kpiList;
      let selectedKpi = [];
      //var readOnlyKpiList = [];
      for (let i = 0, size = ceaNetKpiList.length; i < size; i++) {
        let item = ceaNetKpiList[i];
        if (item.status === "Published" || item.status === "Retired") {
          selectedKpi.push(item.localKpiId);
        }
        // if((item.status === "Published")){
        //     readOnlyKpiList.push(item);
        // }
      }
      this.setState({ selectedKpi: selectedKpi });
    }
    if (nextProps.ceaNetKpiList) {
      if (nextProps.ceaNetKpiList.isBO) {
        this.setState({ isBO: true });
      } else {
        this.setState({ isBO: false });
      }
    }

    if (
      nextProps.businessFunctionPoints !== undefined ||
      nextProps.businessFunctionPoints !== null
    ) {
      this.setState({
        businessFunctionPoints: nextProps.businessFunctionPoints,
      });
    }
    if (
      nextProps.businessFunctionStatus !== undefined ||
      nextProps.businessFunctionStatus !== null
    ) {
      this.setState({
        businessFunctionStatus: nextProps.businessFunctionStatus,
      });
    }
  }

  publishCeaNetKIPs = () => {
    let isPointsNotAllocated =
      this.state.totalKpiPoints === 0 ||
      this.state.businessFunctionPoints === 0;
    if (this.state.ceaNetKpiIdList.length === 0) {
      toastr.error(I18n.t("toastr.atleastOnePublish"));
    } else {
      if(this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer){
        this.props.dispatch(
          publishNetUcKPIs(
            this.state.ceaNetKpiIdList,
            this.props.packageMapPrimaryKey,
            this.state.userDetail,
            this.props.user.userMdl.activeProgramID,
            this.props.user.userMdl.ceaDefaultYears.value,
            this.props.user.userMdl.activeProgram
          )
        );
        this.setState({ ceaNetKpiIdList: [] });
      }else{
        if (
          (this.state.totalKpiPoints <= this.state.businessFunctionPoints &&
            !isPointsNotAllocated)) {
          this.props.dispatch(
            publishCeaKPIs(
              this.state.ceaNetKpiIdList,
              this.props.packageMapPrimaryKey,
              this.state.userDetail,
              this.props.user.userMdl.activeProgramID
            )
          );
          this.setState({ ceaNetKpiIdList: [] });
        } else {
          toastr.error(I18n.t("toastr.points_allocation_incorrect"));
        }
      }    
    }
  };
  launchTargetSettings = () => {
    let actionDTO = {
      businessOwner: this.props.user.userMdl.username,
      activeProgramID: this.props.user.userMdl.activeProgramID,
      ceaYear: this.props.user.userMdl.ceaDefaultYears.value,
      packageProgramMappingId: this.props.packageMapPrimaryKey,
    };
    // if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
    //   this.props.dispatch(moveToLaunchSettings(actionDTO));
    // }else{
      this.props.dispatch(launchTargetSetting(actionDTO));
    // }
  };
  componentDidUpdate() {
    let publishVisible = false;
    if (
      this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) ||
      this.state.isBO ||
      this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin)
    ) {
      publishVisible = true;
    }
    this.props.updateCeaNetKpiLabel(
      this.state.businessFunctionPoints,
      this.state.totalKpiPoints,
      publishVisible
    );
  }
  buttonFormatter(cell, row) {
    let editDisabled = true;
    if (
      this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) ||
      this.state.isBO ||
      this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin)
    ) {
      editDisabled = false;
    }

    return (
      <div className="btn-toolbar float-right cea-KPIicon ">
        <a>
          <div title={row.additionalInfo} className="info-icon" />
        </a>
        {!editDisabled && row.status.toUpperCase() === "SAVED" ? (
          <a onClick={() => this.toggle("Edit", row)}>
            <div title={I18n.t("CeaKpi.edit")} className="edit-icon" />
          </a>
        ) : (
          ""
        )} 
        {/* for edit button on published and processed */}
        {!editDisabled && (row.status.toUpperCase() === "PUBLISHED" || row.status.toUpperCase() === "PROCESSED")&& 
       ( this.props.user.userMdl.activeProgram === AppConst.activeProgramCea || this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)
        ? <a onClick={() => this.toggle("Edit", row)}>
        <div title={I18n.t("CeaKpi.edit")} className="edit-icon" />
      </a> : (
          ""
        )}
     {/* delete button */}
        {!editDisabled && (row.status.toUpperCase() === "SAVED" || row.status.toUpperCase() === "PROCESSED"|| row.status.toUpperCase() === "PUBLISHED") && ( this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? (
          <a onClick={() => this.onDeleteClick(row.localKpiId,row.dependsOn)}>
            <div title={I18n.t("CeaKpi.delete")} className="delete-icon" />
          </a>
        ) : (
          ""
        )}

        <a onClick={() => this.toggle("View", row)}>
          <Fa icon={"eye"} fixed size="lg" title={I18n.t("Table.view")} />
        </a>
      </div>
    );
  }

  getSubPillar(cell, row) {
    let subPillar = cell;
    if (cell === 0 || cell === "0") {
      subPillar = "";
    }
    return subPillar;
  }

  onDeleteClick = (kpiId,dependsOn) => {
    let userDetail = {
      username: this.props.user.userMdl.username,
      nmsc: this.props.user.userMdl.nmsc,
      roles: this.props.user.userMdl.roles,
    };
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
         
          <div className={"ConfirmBox-" + this.props.theme.name}>
            <div className="ConfirmBoxTitle">
              {console.log("dependsOn",dependsOn)}
              {I18n.t("standardMessages.warning")}
            </div>
            <div className="ConfirmBoxBody">
              {dependsOn==="Y" ? I18n.t("standardMessages.deleteDependentConfirm"):I18n.t("standardMessages.deleteConfirm")}
            </div>
            <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.props.dispatch(
                    deleteCeaNetKpi(
                      kpiId,
                      this.props.packageMapPrimaryKey,
                      this.props.user.userMdl.activeProgramID,
                      userDetail
                    )
                  );
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.no")}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  headerAdd(cell, row) {
    let addDisabled = true;
    let addKPIDisable = true;
    if (
      (this.state.businessFunctionPoints !== 0 &&
      this.state.businessFunctionPoints != null) || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer
    ) {
      addKPIDisable = false;
    }
    if (
      this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) ||
      this.state.isBO ||
      this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin)
    ) {
      addDisabled = false;
    }
    if (
      (this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ||
        this.state.businessFunctionStatus === "SUBMITTED") &&
      addDisabled !== true
    ) {
      if (addKPIDisable !== true) {
        return (
          <div>
            <a onClick={() => this.toggle("Create", row)}>
              <div
                title={I18n.t("CeaKpi.addKPI")}
                className="add-icon cea-kpiAddIcon"
              />
            </a>
          </div>
        );
      } else {
        return (
          <div>
            <a>
              <div
                title={I18n.t("CeaKpi.noPointsAllocated")}
                className="addDisabledIcon cea-kpiAddIcon"
              />
            </a>
          </div>
        );
      }
    } else {
      return <div></div>;
    }
  }

  toggle = (action, row) => {
    this.setState({
      modal: !this.state.modal,
      modalFor: action,
    });
    if (action === "Create") {
      const selectedRow = { ...this.state.selectedRow };

      selectedRow["packageProgramMappingId"] = this.props.packageMapPrimaryKey;
      selectedRow["userName"] = this.props.user.userMdl.username;
      selectedRow["localKpiId"] = 0;

      this.setState({ selectedRow: selectedRow });
    } else {
      const selectedRow = { ...this.state.selectedRow };
      selectedRow["localKpiId"] = row.localKpiId;

      selectedRow["packageProgramMappingId"] = this.props.packageMapPrimaryKey;
      selectedRow["userName"] = this.props.user.userMdl.username;
      this.setState({ selectedRow: selectedRow });
    }
  };

  popUpClose = (action) => {
    this.setState({
      modal: !this.state.modal,
      modalFor: action,
    });
  };

  render() {
    let publishVisible = false;
    let launchTargetVisible = false;
    if (
      this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) ||
      this.state.isBO ||
      this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin) ||
      this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin)
    ) {
      publishVisible = true;
    }

    if (this.state.isBO && this.props.user.userMdl.businessAreaKey !== AppConst.businessAreaKeyPer) {
      launchTargetVisible = this.state.businessFunctionPoints === 0 &&
        this.state.totalKpiPoints === 0
        ? false
        : true;
    }

    var columns = [
      {
        dataField: "name",
        text: I18n.t("CeaKpi.evaluationItem"),
        sort: true,
        style: { width: "20%", textAlign: "left", wordWrap: "break-word" },
        headerStyle: { width: "20.5%", textAlign: "left" },
      },
      {
        dataField: "definition",
        text: I18n.t("CeaKpi.kpiMetric"),
        style: { width: "29%", textAlign: "left" },
        headerStyle: { width: "28%", textAlign: "left" },
      },

      {
        dataField: "status",
        text: I18n.t("Table.status"),
        sort: true,
        classes: (cell, row, rowIndex, colIndex) => {
          if (cell === "Published") {
            return "greenColor";
          } else if (cell === "Saved") {
            return "orangeColor";
          } else if (cell === "Processed") {
            return "blueColor";
          }
          else {
            return "greyColor";
          }
        },
        style: { width: "8%" },
        headerStyle: { width: "9%" },
      },

      {
        dataField:  this.props.user.userMdl.activeProgram === AppConst.activeProgramCea ? "subPillar" : "inputFrequency",
        text: this.props.user.userMdl.activeProgram === AppConst.activeProgramCea ? I18n.t("CeaKpi.subPillar") : I18n.t("CeaKpi.inputFreq"),
        formatter: this.getSubPillar,

        sort: true,
        style: { width: "10%" },
        headerStyle: { width: "11%" },
      },

      {
        dataField: this.props.user.userMdl.activeProgram === AppConst.activeProgramCea ? "points" : "paramKey",
        text: this.props.user.userMdl.activeProgram === AppConst.activeProgramCea ? I18n.t("CeaKpi.points") : I18n.t("CeaKpi.paramater"),
        sort: true,
        style: { width: "11%" },
        headerStyle: { width: "8%" },
      },

      {
        dataField: "action",
        text: I18n.t("Table.action"),
        headerFormatter: this.headerAdd,
        formatter: this.buttonFormatter,
        style: { width: "13%" },
        headerStyle: { width: "6%" },
      },
    ];

    const rowEvents = {
      style: {},
      onClick: (e, row, rowIndex) => {
        // if (e.target.type === "checkbox") {
        //     e.target.checked = true;
        // } else {
        //     // console.log('row event else')
        // }
      },
    };

    const selectRow = {
      mode: "checkbox",
      clickToSelect: false,
      hideSelectColumn: !publishVisible,
      nonSelectable: this.state.selectedKpi,
      selected: this.state.ceaNetKpiIdList,
      onSelectAll: (isSelect, rows, e) => {
        let selectedRows = [];
        rows.forEach((row) => {
          if (row.status === "Saved") {
            selectedRows.push(row.localKpiId);
          }
        });
        if (isSelect && selectedRows.length > 0) {
          this.setState({ ceaNetKpiIdList: selectedRows });
          return selectedRows;
        } else {
          this.setState({ ceaNetKpiIdList: [] });
        }
      },
      onSelect: (row, isSelect, rowIndex, e) => {
        let ceaNetKpiIdList = this.state.ceaNetKpiIdList;
        let index = ceaNetKpiIdList.indexOf(row.localKpiId);
        if (isSelect && index === -1) {
          ceaNetKpiIdList.push(row.localKpiId);
          this.setState({ ceaNetKpiIdList });
          return ceaNetKpiIdList;
        } else {
          ceaNetKpiIdList.splice(index, 1);
          this.setState({ ceaNetKpiIdList });
        }
      },
    };

    return (
      <div>
        <div className="ceaTable" id="tmeTable">
          <BootstrapTable
            ref={this.tableRef}
            keyField="localKpiId"
            data={this.state.ceaNetKpiList}
            columns={columns}
            selectRow={selectRow}
            noDataIndication={I18n.t("tabsDetails.NoKPI")}
            hover
            condensed
            rowEvents={rowEvents}
          />
        </div>
        <hr className="p-0 m-0" />
        {launchTargetVisible ? (
          <div
            className="col-md-12"
            style={{ textAlign: "right", paddingBottom: "1rem" }}
          >
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.publishCeaNetKIPs}
            >
              {" "}
              {I18n.t("tabsDetails.publish")}{" "}
            </button>{" "}
            &nbsp;&nbsp;
            {(this.state.businessFunctionStatus === "SUBMITTED") ? (
              <button
                className={
                  "launchSettingButton buttonCancel-" + this.props.theme.name
                }
                onClick={this.launchTargetSettings}
              >
                {" "}{I18n.t("ceaEvaluation.launchTargetSetting")}{" "}
              </button>
            ) : (
              ""
            )}
          </div>
        ) : publishVisible ? (
          <div
            className="col-md-12"
            style={{ textAlign: "right", paddingBottom: "1rem" }}
          >
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.publishCeaNetKIPs}
            >
              {" "}
              {I18n.t("tabsDetails.publish")}{" "}
            </button>
          </div>
        ) : (
          ""
        )}

        {this.state.modal === true ? (
          <CeaNetKpiModal
            modal={this.state.modal}
            toggle={this.popUpClose}
            modalFor={this.state.modalFor}
            selectedRow={this.state.selectedRow}
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    ceaNetKpiList: state.ceaNetKPIReducer.ceaNetKpiList,
    businessFunctionPoints: state.ceaNetKPIReducer.businessFunctionPoints,
    businessFunctionStatus: state.ceaNetKPIReducer.businessFunctionStatus,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(CeaNetKpiTable)));
