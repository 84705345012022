export default function ceaKpiEntryReducer(state = {
    kpiEntryFilters: {},
    kpiEntryDeatils :[],
    kpiActualDeatils:[],
}, action) {
    switch (action.type) {
       
        case "NMSC_CODE_DATA":
            return Object.assign({}, state, {
                kpiEntryFilters: { nmscCodeList: action.payload }
            }); 
        case "KPI_BRAND_DATA":
            return Object.assign({}, state, {
                kpiEntryFilters: { brandList: action.payload }
            });
        case "BUSINESS_FUNCTION_DATA":
            return Object.assign({}, state, {
                kpiEntryFilters: { businessFunctionList: action.payload }
            });
        case "KPI_DATA":
            return Object.assign({}, state, {
                kpiEntryFilters: { masterKpiList: action.payload }
            }); 
        case "SUBPILLAR_DATA":
            return Object.assign({}, state, {
                kpiEntryFilters: { subPillarList: action.payload }
            });
        case "ACTIVITY_STATUS":
                return Object.assign({}, state, {
                    kpiEntryFilters: { activityStatusList: action.payload }
                });    
        case "BULK_KPI_ENTRY_DETAILS":
            return Object.assign({}, state, {
                kpiEntryDeatils: action.payload,
            }); 
        case "KPI_ACTUALS_DETAILS":
            return Object.assign({}, state, {
                kpiActualDeatils: action.payload,
            });    
        case "KPI_MONTHLY_COMMENT":
            return Object.assign({}, state, {
                kpiMonthlyComment: action.payload,
            });  
        case "KPI_CURRENT_MONTHLY_COMMENT":
            return Object.assign({}, state, {
                kpiCurrentMonthComment: action.payload,
            });                             
        default:
            return state;

    }
};