import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { fetchMasterKpiList, deleteKpi, publishKPIs } from '../../actions/tabAction.jsx';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import ActionModal from '../modal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withTheme } from '../../themes/theming';
import './index.css';
import { toastr } from 'react-redux-toastr';
var I18n = require('react-redux-i18n').I18n;


class TablePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalFor: '',
            selectedRow: {
                masterKpiId: 0,
                toolboxId: this.props.packageMapPrimaryKey,
                userName : this.props.user.userMdl.username,
                brand: '',
                name: '',
                definition: '',
                benchmark: '',
                unit: '',
                status: '',
                additionalInfo: '',
                mandatory: true,
                publishedOn: '',
                createdBy: 'ADMIN',
                createdTimestamp: new Date(),
                packageProgramMappingId : this.props.packageMapPrimaryKey
            },
            masterKpiList: [],
            masterKpiIdList: [],
            showFlag: true,
            readOnlyKpiList : []
        };
        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.headerAdd = this.headerAdd.bind(this);
        this.publishKIPs = this.publishKIPs.bind(this);
        this.benchmarkTitle = this.benchmarkTitle.bind(this);
    }

    componentWillMount() {
     
        this.props.dispatch(fetchMasterKpiList(this.props.packageMapPrimaryKey, true));
    }

    componentWillReceiveProps(nextProps) {
    
        if (this.props.packageMapPrimaryKey !== nextProps.packageMapPrimaryKey) {
            this.props.dispatch(fetchMasterKpiList(nextProps.packageMapPrimaryKey, true));
        }
        if (this.props.packageMapPrimaryKey !== nextProps.packageMapPrimaryKey) {
            this.refs.table.sortContext.state.sortColumn = undefined;
            this.refs.table.sortContext.state.sortOrder = undefined;
        }
        if (nextProps.masterKpiList) {
            this.setState({ masterKpiList: nextProps.masterKpiList })

            const kpiList = nextProps.masterKpiList;
            var selectedKpi = [];
            var readOnlyKpiList = [];
            for (var i = 0, size = kpiList.length; i < size; i++) {
                var item = kpiList[i];          
                if ((item.status === "Published") || item.status === "Retired") {
                    selectedKpi.push(item.masterKpiId);
                }
                if((item.status === "Published")){
                    readOnlyKpiList.push(item);
                }
            }
            this.setState({ selected: selectedKpi,readOnlyKpiList:readOnlyKpiList });
            if (nextProps.modalState) {
                this.setState({ modal: false });
            }

        }
    }


    toggle = (action, row) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
        if (action === "Create") {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow['masterKpiId'] = 0;
            selectedRow['brand'] = '';
            selectedRow['name'] = '';
            selectedRow['definition'] = '';
            selectedRow['benchmark'] = '';
            selectedRow['status'] = '';
            selectedRow['mandatory'] = false;
            selectedRow['unit'] = '';
            selectedRow['additionalInfo'] = '';
            selectedRow['toolboxId'] = this.props.packageMapPrimaryKey;
            selectedRow['packageProgramMappingId'] = this.props.packageMapPrimaryKey;
            selectedRow['userName'] = this.props.user.userMdl.username;
            this.setState({ row: selectedRow });
        }
        else {
            const selectedRow = row;
            //selectedRow['toolboxId'] = this.props.toolboxId;
            this.setState({ row: selectedRow });
        }
    }

    popUpClose = (action) => {

        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
    }

    onDeleteClick = (status, publishedOnDate) => {
        if (status === 'Saved' && (publishedOnDate === null || publishedOnDate === '')) {
            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                        <div className={"ConfirmBox-" + this.props.theme.name}>
                            <div className="ConfirmBoxTitle">
                                {I18n.t('standardMessages.warning')}
                            </div>
                            <div className="ConfirmBoxBody">
                                {I18n.t('standardMessages.deleteConfirm')}
                            </div>
                            <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => {
                                        this.props.dispatch(deleteKpi(this.props.packageMapPrimaryKey, this.state.selectedRow.masterKpiId))
                                        onClose();
                                    }}>
                                    {I18n.t("standardMessages.yes")}
                                </button>
                                <button className={"button-" + this.props.theme.name} onClick={() => onClose()}>
                                    {I18n.t("standardMessages.no")}
                                </button>
                            </div>
                        </div>
                    );
                }
            });

        } else
            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                        <div className={"ConfirmBox-" + this.props.theme.name}>
                            <div className="ConfirmBoxTitle">
                                {I18n.t('standardMessages.warning')}
                            </div>
                            <div className="ConfirmBoxBody">
                                {I18n.t('standardMessages.retireConfirm')}
                            </div>
                            <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => {
                                        this.props.dispatch(deleteKpi(this.props.packageMapPrimaryKey, this.state.selectedRow.masterKpiId))
                                        onClose();
                                    }}>
                                    {I18n.t("standardMessages.yes")}
                                </button>
                                <button className={"button-" + this.props.theme.name} onClick={() => onClose()}>
                                    {I18n.t("standardMessages.no")}
                                </button>
                            </div>
                        </div>
                    );
                }
            });

    };
    buttonFormatter(cell, row) {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'MASTER_KPI')[0].accessType;
        return (
            <div className="btn-toolbar float-right">
                {row.additionalInfo && row.additionalInfo.length > 0 ?
                    <a><div style={{ whiteSpace: 'pre' }} title={row.additionalInfo} className="info-icon" /></a> : ""}
                {row.status !== 'Retired' && accessType === 'R/W' ?
                    <a onClick={() => this.toggle('Edit', row)}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" /></a> : ""}
                {row.status !== 'Retired' && accessType === 'R/W' ?
                    <a onClick={() => this.onDeleteClick(row.status, row.publishedOnDate)}><div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" /></a> : ""}

            </div>
        );
    }
    headerAdd(cell, row) {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'MASTER_KPI')[0].accessType;
        return (
            <div>
                {accessType === 'R' ? null :
                    <a onClick={() => this.toggle('Create', row)}><div title={I18n.t('tmeAdminMaster.add')} className="add-icon" /></a>
                }
            </div>
        );
    }

    flagFormatter(cell, row, rowIndex) {
        return (
            <div>
                {cell === true ?
                    <div title={I18n.t('Table.mandatory')}  className="active-flag" /> :
                    <div title="Not Mandatory" className="inactive-flag" size="2x" />
                }
            </div>
        );

    }
    benchmarkTitle(columns){
        console.log("Program:"+this.props.user.userMdl.activeProgram);
        if(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA"){
            columns[2].text=I18n.t('Table.penEbenchmark')
            columns[2].headerStyle.width="11.8%"
            columns[1].headerStyle.width="22.6%"
            columns[2].style.width="12%"
            columns[1].style.width="23%"
            return columns;
        }else if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
        || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS"){
            columns[2].text=I18n.t('Table.benchmark')
            return columns;
        }else {
            //..
        }
    }

    benchMarkFormatter(cell, row) {
        return (
            <div >
                {row.benchmark + " " + row.unit}
            </div>
        );
    }

    statusFormatter(cell, row) {
        return (
            <div >
                {I18n.t('status.'+row.status.toLowerCase())}
            </div>
        );
    }

    

    dataFormatter(cell, row, rowIndex) {
        var definition = row.definition;
        return (
            <div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
                {definition}
            </div>
        );
    }


    publishKIPs() {
        if (this.state.masterKpiIdList.length === 0) {
            toastr.error(I18n.t('toastr.atleastOnePublish'));
        } else {
            // this.setState({ showFlag: false });
            this.props.dispatch(publishKPIs(this.state.masterKpiIdList, this.props.packageMapPrimaryKey, this.callback));
            this.setState({ masterKpiIdList: [] });
        }
    }

    callback = () => {
        this.setState({ showFlag: true });
    }


    render() {
        console.log("Renderr",this.props.user.userMdl.accessControl)
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'MASTER_KPI')[0].accessType;
        var columns = [{
            dataField: 'name',
            text: I18n.t('Table.kpi'),
            sort: true,
            style: { width: "21%", textAlign: "left", wordWrap: "break-word" },
            headerStyle: { width: "20.7%", textAlign: "left" },


        }, {
            dataField: 'definition',
            text: I18n.t('Table.definition'),
            formatter: this.dataFormatter,
            style: { width: "25%", textAlign: "left" },
            headerStyle: { width: "24.6%", textAlign: "left" }
        }, {
            dataField: 'benchmark',
            text: I18n.t('Table.benchmark'),
            style: { width: "10%" },
            formatter: this.benchMarkFormatter,
            headerStyle: { width: "9.8%" }
        }, {
            dataField: 'status',
            text: I18n.t('Table.status'),
            sort: true,
            formatter: this.statusFormatter,
            classes: (cell, row, rowIndex, colIndex) => {
                if (cell === "Published") { return 'greenColor'; }
                else if (cell === "Saved") { return 'orangeColor'; }
                else { return 'greyColor'; }
            },
            style: { width: "9.8%" },
            headerStyle: { width: "9.8%" }
        }, {
            dataField: 'mandatory',
            text: I18n.t('Table.mandatory'),
            sort: true,
            formatter: this.flagFormatter,
            style: { width: "10%" },
            headerStyle: { width: "9.8%" }
        }, {
            dataField: 'publishedOnDate',
            text: I18n.t('Table.publishedOn'),
            sort: true,
            style: { width: "9.8%" },
            headerStyle: { width: "9.8%" }
        }, {
            dataField: "action",
            text: I18n.t('Table.action'),
            headerFormatter: this.headerAdd,
            formatter: this.buttonFormatter,
            style: { width: "11%" },
            headerStyle: { width: "11%" }
        }];
        columns=this.benchmarkTitle(columns);

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
             if (e.target.type === "checkbox") {
                    e.target.checked = true;
                    var index = this.state.masterKpiIdList.indexOf(row.masterKpiId);
                    if (index > -1) {
                        this.state.masterKpiIdList.splice(index, 1);
                    } else {
                        this.state.masterKpiIdList.push(row.masterKpiId);
                    }
                } else {
                    const selectedRow = { ...this.state.selectedRow };
                    selectedRow['masterKpiId'] = row.masterKpiId;
                    selectedRow['brand'] = row.brand;
                    selectedRow['name'] = row.name;
                    selectedRow['definition'] = row.definition;
                    if (row.unit !== "") {
                        selectedRow['benchmark'] = (row.benchmark).substr(0, ((row.benchmark).indexOf(row.unit) - 1));
                    } else {
                        selectedRow['benchmark'] = row.benchmark;
                    }
                    selectedRow['status'] = row.status;
                    selectedRow['mandatory'] = row.mandatory;
                    selectedRow['unit'] = row.unit;
                    selectedRow['additionalInfo'] = row.additionalInfo;
                    this.setState({ selectedRow });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            nonSelectable: this.state.selected,
            selected: this.state.masterKpiIdList,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                rows.forEach((row) => {
                    if (row.status === 'Saved') {
                        selectedRows.push(row.masterKpiId);
                    }
                });
                if (isSelect && selectedRows.length > 0) {
                    this.setState({ masterKpiIdList: selectedRows });
                    return selectedRows;
                }
                else {
                    this.setState({ masterKpiIdList: [] });
                }
            }
        };

        return (
            <div>
                {this.state.showFlag === true ?
                    <div className="tmeTable" id="tmeTable">
                        <BootstrapTable ref="table"
                            keyField='masterKpiId'
                            data= {accessType === "R/W"? this.state.masterKpiList : this.state.readOnlyKpiList}
                            //data={this.state.masterKpiList}
                            columns={columns}
                            selectRow={selectRow}
                            noDataIndication={I18n.t('tabsDetails.NoKPI')}
                            hover
                            condensed
                            rowEvents={rowEvents}
                        />
                    </div>
                    : null}
                <hr className="p-0 m-0" />
                <div className="col-md-12" style={{ textAlign: 'right', paddingBottom: '1rem' }}>
                    {accessType === 'R/W' ?
                        <button className={"button-" + this.props.theme.name} onClick={this.publishKIPs}> {I18n.t('tabsDetails.publish')} </button>
                        : ''}
                </div>
                {this.state.modal === true ?
                    <ActionModal
                        modal={this.state.modal}
                        toggle={this.popUpClose}
                        modalFor={this.state.modalFor}
                        selectedRow={this.state.row} /> : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterKpiList: state.tabReducer.masterKpiList,
        modalState: state.tabReducer.closeModal,
        user: state.loginReducer
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(TablePage)));