import axios from 'axios';

import { toastr } from 'react-redux-toastr';
// import fileSaver from 'file-saver';
import FileDownload from "js-file-download";


export function uploadBulk(fileCriteria,fileData,nmscCode,lang,userName,url) {


  return function (dispatch) {
    //   dispatch(setBlockingState(true));
    axios
      .post("/bulkOperation/"+url+"/"+nmscCode+"/"+lang+"/"+userName, fileData, {
        headers:
        {
          'Content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        // toastr.success(I18n.t('toastr.uploadSuccess'));
        // toastr.success("Success", response.data);
        toastr.success(response.data);

        dispatch({
          type: "BULK_UPLOAD_DATA",
          payload: {isUploaded:true}
        })

        // dispatch(getBulkUploadList(fileCriteria));

      })
      .catch(function (err) {
        // toastr.error(I18n.t('toastr.upload_error'));
        console.log(err);
        dispatch(getBulkUploadList(fileCriteria));

      });
  };
}


export function getBulkUploadList(fileCriteria) {
  return function (dispatch) {
    // dispatch(setBlockingState(true));
    axios.post(
      '/bulkOperation/list', fileCriteria, {
      headers:
      {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }

    }).then(function (response) {

      // dispatch(setBlockingState(false));
      dispatch({
        type: "UPLOAD_ACTIVITY_FILE_LIST",
        payload: response.data
      })
    })
      .catch(function (err) {

        // dispatch(setBlockingState(false));

      });
  }
}


export function downloadFile(id, fileName, uploadFileReqDTO) {
  return function (dispatch) {

    axios.post(
      "/bulkOperation/download/" + id, uploadFileReqDTO, {
      headers:
      {
        'Content-type': 'application/json',
        'Pragma': 'no-cache'
      },
    })
      .then((response) => {
        dispatch(downloadS3File(response));
      })

      .catch(function (err) {


      });
  }
}

export function downloadS3File(obj) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    var preSignedURL = obj.data.desc;
    axios({
      method: 'get',
      contentType: "application/octet-stream",
      url: preSignedURL,
      responseType: 'arraybuffer',
      headers: { 'Pragma': 'no-cache' }
    }).then(function (response) {
      FileDownload(response.data, obj.data.body.replace(/%20/g, " "));
      //dispatch(setBlockingState(false));
    })
  };
}

export function downloadTemplate(downloadDto) {


  return function (dispatch) {
    //   dispatch(setBlockingState(true));
    axios
      .post("/bulkOperation/downloadBrit" , downloadDto, {
        headers:
        {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        // toastr.success(I18n.t('toastr.uploadSuccess'));
        // toastr.success("Success", response.data);
        toastr.success(response.data);

        dispatch({
          type: "BULK_DOWNLOAD_DATA",
          payload: {isUploaded:true}
        })

         //dispatch(getBulkDownloadList(downloadDto));

      })
      .catch(function (err) {
        // toastr.error(I18n.t('toastr.upload_error'));
        console.log(err);
        //dispatch(getBulkDownloadList(downloadDto));

      });
  };
}

export function downloadQuestionnaireTemplate(downloadDto) {
  return function (dispatch) {
    axios
      .post("/bulkOperation/downloadQuestionnaire" , downloadDto, {
        headers:
        {
          'Content-type': 'application/json',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        // toastr.success(I18n.t('toastr.uploadSuccess'));
        // toastr.success("Success", response.data);
        toastr.success(response.data);

        dispatch({
          type: "QUESTIONNAIRE_DOWNLOAD_DATA",
          payload: {isUploaded:true}
        })

         //dispatch(getBulkDownloadList(downloadDto));

      })
      .catch(function (err) {
        // toastr.error(I18n.t('toastr.upload_error'));
        console.log(err);
        //dispatch(getBulkDownloadList(downloadDto));

      });
  };
}
export function getBulkDownloadList( downloadDto) {
  return function (dispatch) {
    // dispatch(setBlockingState(true));
    axios.post(
      '/bulkOperation/download/list', downloadDto, {
      headers:
      {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }

    }).then(function (response) {

      // dispatch(setBlockingState(false));
      dispatch({
        type: "DOWNLOAD_ACTIVITY_FILE_LIST",
        payload: response.data
      })
    })
      .catch(function (err) {

        // dispatch(setBlockingState(false));

      });
  }
}

export function downloadTemplateFile(id, fileName, downloadFileDTO) {
  return function (dispatch) {

    axios.post(
      "/bulkOperation/downloadTemplate/" + id, downloadFileDTO, {
      headers:
      {
        'Content-type': 'application/json',
        'Pragma': 'no-cache'
      },
    })
      .then((response) => {
        dispatch(downloadS3File(response));
      })

      .catch(function (err) {


      });
  }
}

export function uploadMonthlyKpi(fileCriteria, fileData, nmscCode, lang, userName) {
  return function (dispatch) {
    axios
      .post("/bulkOperation/uploadMonthlyKpi/" + nmscCode +"/"+ lang +"/"+ userName, fileData, {
        headers:
        {
          'Content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        toastr.success(response.data);

        dispatch({
          type: "KPI_UPLOAD_DATA",
          payload: {isUploaded:true}
        })
      })
      .catch(function (err) {
        console.log(err);
        dispatch(getMonthlyKpiUploadList(fileCriteria));
      });
  };
}

// export function uploadValueChainQuestionnaire(fileCriteria, fileData, nmscCode, lang, userName) {
//   return function (dispatch) {
//     axios
//       .post("/bulkOperation/uploadValueChainQuestionnaire/" + nmscCode +"/"+ lang +"/"+ userName, fileData, {
//         headers:
//         {
//           'Content-type': 'multipart/form-data',
//           'Accept': 'application/json',
//           'Pragma': 'no-cache'
//         }
//       })
//       .then(function (response) {
//         toastr.success(response.data);

//         dispatch({
//           type: "KPI_UPLOAD_DATA",
//           payload: {isUploaded:true}
//         })
//       })
//       .catch(function (err) {
//         console.log(err);
//         dispatch(getMonthlyKpiUploadList(fileCriteria));
//       });
//   };
// }

export function uploadUsedCars(fileCriteria, fileData, nmscCode, lang, userName, activeProgramID, ceaDefaultYears, activeProgramUc) {
  return function (dispatch) {
    axios
      .post("/bulkOperation/uploadUsedCars/" + nmscCode +"/"+ lang +"/"+ userName +"/"+ activeProgramID +"/"+ ceaDefaultYears+"/"+activeProgramUc, fileData, {
        headers:
        {
          'Content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        toastr.success(response.data);

        dispatch({
          type: "KPI_UPLOAD_DATA",
          payload: {isUploaded:true}
        })
      })
      .catch(function (err) {
        console.log(err);
        dispatch(getMonthlyKpiUploadList(fileCriteria));
      });
  };
}

export function uploadNetworks(fileCriteria, fileData, nmscCode, lang, userName, activeProgramID, ceaDefaultYears, activeProgramNet) {
  return function (dispatch) {
    axios
      .post("/bulkOperation/uploadNetworks/" + nmscCode +"/"+ lang +"/"+ userName +"/"+ activeProgramID +"/"+ ceaDefaultYears+"/"+activeProgramNet, fileData, {
        headers:
        {
          'Content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        toastr.success(response.data);

        dispatch({
          type: "KPI_UPLOAD_DATA",
          payload: {isUploaded:true}
        })
      })
      .catch(function (err) {
        console.log(err);
        dispatch(getMonthlyKpiUploadList(fileCriteria));
      });
  };
}

export function uploadLexusNetworks(fileCriteria, fileData, nmscCode, lang, userName, activeProgramID, ceaDefaultYears, activeProgramLN) {
  return function (dispatch) {
    axios
      .post("/bulkOperation/uploadLexusNetworks/" + nmscCode +"/"+ lang +"/"+ userName +"/"+ activeProgramID +"/"+ ceaDefaultYears+"/"+activeProgramLN, fileData, {
        headers:
        {
          'Content-type': 'multipart/form-data',
          'Accept': 'application/json',
          'Pragma': 'no-cache'
        }
      })
      .then(function (response) {
        toastr.success(response.data);

        dispatch({
          type: "KPI_UPLOAD_DATA",
          payload: {isUploaded:true}
        })
      })
      .catch(function (err) {
        console.log(err);
        dispatch(getMonthlyKpiUploadList(fileCriteria));
      });
  };
}

export function getMonthlyKpiUploadList(fileCriteria) {
  return function (dispatch) {
    axios.post(
      '/bulkOperation/monthlyKpiList', fileCriteria, {
      headers:
      {
        'Content-type': 'application/json',
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }

    }).then(function (response) {

      dispatch({
        type: "KPI_UPLOAD_FILE_LIST",
        payload: response.data
      })
    })
      .catch(function (err) {

      });
  }
}


export function downloadData(fileCriteria) {

  return function (dispatch) {
    dispatch({
      type: "DATA_UPLOAD_FILE_LIST",
      payload: fileCriteria
    });

  };
}

export function uploadData(fileCriteria) {

  return function (dispatch) {
    dispatch({
      type: "UPLOAD_LIST",
      payload: fileCriteria
    });

  };
}

export function getkpiUploadList(fileCriteria) {

  return function (dispatch) {
    dispatch({
      type: "UPLOAD_KPI_LIST",
      payload: fileCriteria
    });

  };
}

export function getQuestionnaireUploadList(fileCriteria) {

  return function (dispatch) {
    dispatch({
      type: "UPLOAD_QUESTIONNAIRE_LIST",
      payload: fileCriteria
    });

  };
}