import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css';
import DatePicker from 'react-datepicker';
import {MDBIcon } from "mdbreact";
import moment from 'moment';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { fetchActivityFilters,fetchDealer, fetchDealerCode, fetchActivityStatus,fetchToolBoxForTme, searchEmptyActivityList } from '../../actions/activityAction.jsx';
import Autosuggest from 'react-autosuggest';
import { fetchNmscToolboxByType,fetchTmeToolboxForNmsc } from "../../actions/leftNavAction.jsx";
import { withTheme } from '../../themes/theming';
import $ from 'jquery';
import { toastr } from 'react-redux-toastr';
import * as AppConst from '../../AppConstant';
var I18n = require('react-redux-i18n').I18n;

class SearchActivity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            show: false,
            toolBoxFlag: false,
            toolboxId: 0,
            nmscFlag: false,
            search: {
                dealer: "",
                startFrom: ""
            },
            activityDto: {
                nmscCode: null,
                loggedInUser: this.props.user.userMdl.username,
                brand: null,
                country: null,
                region:null,
                dealer: null,
                toolbox: null,
                assignee: null,
                status: null,
                score: null,
                createdBy: null,
                updatedBy: null,
                activityId: 0,
                city: null,
                toolboxId: null,
                completeBy: null,
                rows: null,
                page: null,
                offset: null,
                sortOrder: null,
                sortCol: null,
                businessArea: this.props.user.userMdl.businessAreaKey ==="EA" ? "EA" : this.props.user.userMdl.businessAreaKey
            },
            value: '',
            suggestions: [],
            suggestionsCode: [],
            valueAssignee: '',
            valueDealer: '',
            valueDealerCode: '',
            valueActivityId:'',
            suggestionsAssignee: [],
            nmscList: [],
            brandList: [],
            countryList: [],
            regionList: [],
            activityStatus: [],
            activityIdList:[],
            activityToolBox: [],
            assigneeList: [],
            dealerCodeList: [],
            selectedNmsc: [],
            selectedBrand: [],
            selectedCountry: [],
            selectedRegion: [],
            selectedToolbox: [],
            selectedStatus: [],
            selectedActivityId:[],
            defaultAllObj: [{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }],
        };
    }
    componentWillMount() {
        // var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
                // isNMSCAdminUser = true;
                break;
              }
              else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN") {
                isTMEAdminUser = true;
                break;
            }
          }
        }
        if (this.props.searchDto !== undefined) {
            // console.log("got saved criteria" + JSON.stringify(this.props.searchDto));
            let selectedNmscList = this.getSelectedList(this.props.searchDto.nmscCodeList);
            let selectedBrandList = this.getSelectedList(this.props.searchDto.brandList);
            let selectedCountryList = this.getSelectedList(this.props.searchDto.countryList);
            let selectedToolboxList = this.getSelectedList(this.props.searchDto.toolboxList);
            let selectedStatusList = this.getSelectedList(this.props.searchDto.statusList);
            let selectedRegionList = this.getSelectedList(this.props.searchDto.regionList);
            this.setState({
                activityDto: this.props.searchDto,
                selectedNmsc: selectedNmscList,
                selectedBrand: selectedBrandList,
                selectedCountry: selectedCountryList,
                selectedRegion: selectedRegionList,
                selectedToolbox: selectedToolboxList,
                selectedStatus: selectedStatusList,
                valueAssignee: this.props.searchDto.assignee !== undefined ? this.props.searchDto.assignee : '',
                valueDealer: this.props.searchDto.dealer !== undefined ? this.props.searchDto.dealer : '',
                valueDealerCode: this.props.searchDto.dealerCode !== undefined ? this.props.searchDto.dealerCode : '',                
            });
            if(this.props.user.userMdl.flagBRiT && this.props.searchDto.activityId !== null && this.props.searchDto.activityId !==0){
                    this.setState({valueActivityId:this.props.searchDto.activityId})
            }
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "nmscCode"));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "l1"));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "l2"));
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "l3"));
            this.props.dispatch(fetchDealer(this.props.searchDto));
            this.props.dispatch(fetchDealerCode(this.props.searchDto));
            if(!this.props.user.userMdl.flagBRiT){
            this.props.dispatch(fetchActivityFilters(this.props.searchDto, "userId"));
            }
            this.props.dispatch(fetchActivityStatus());
         
            if(!isTMEAdminUser){
                console.log(this.props.user.userMdl)
                this.props.dispatch(fetchNmscToolboxByType(this.props.user.userMdl.activeProgramID,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
            }
            else{
            //this.props.dispatch(fetchToolBox(this.props.user.userMdl.activeProgram,"TME"));
            this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
            }
        }
    }

    onBlurActivityId =()=>{
        let pattern = /^\d+$/;
        let activityDto = {...this.state.activityDto}
        if (!pattern.test(this.state.valueActivityId)) {
            activityDto["activityId"] = 0
            this.setState({activityDto})
        }
    }
    

    /**
     * Method to create selected option list for multiselect element.
     */
    getSelectedList(selectedList) {
        let filteredList = [];
        let obj;
        
        var Number = /^[\d\/\(\)\- \.]+$/; // eslint-disable-line
        if (selectedList !== undefined) {
            selectedList.map(value => {
                if (Number.test(value)){
                     obj = { 
                        label: value,            
                        value: value,
                        selected: 'selected'
                    };
                }
                else{
                 obj = {             
                    label: I18n.t("status." + value.toLowerCase()),
                    value: value,
                    selected: 'selected'
                };
            }
                filteredList.push(obj);
                return null;
            });
        }
        return filteredList;
    } 
 
    componentWillReceiveProps(nextProps) {
		let roles = [];
        roles = nextProps.user.userMdl.roles;

        //If user role is NMSC or DEALER then set flag as true
        // This will set the search activity toolbox drop down for nmsc user
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1 || roles[i].toUpperCase().indexOf("DEALER") !== -1) {
                    this.setState({ nmscFlag: true });
                    break;
                }
            }
        }
        if (nextProps.nmscList.nmscList) {
            if(this.state.nmscFlag){
                this.setState({ nmscList: nextProps.nmscList.nmscList });
            }else{
                this.setState({ nmscList: this.state.defaultAllObj.concat(nextProps.nmscList.nmscList) });
            }            
        }
        if (nextProps.brandList.brandList) {
            this.setState({ brandList: nextProps.brandList.brandList });
        }
        if (nextProps.countryList.countryList) {
            this.setState({ countryList: this.state.defaultAllObj.concat(nextProps.countryList.countryList) });
        }
        if (nextProps.regionList.regionList) {
            this.setState({ regionList: this.state.defaultAllObj.concat(nextProps.regionList.regionList) });
        }
        if (nextProps.assigneeData) {

            this.setState({ assigneeList: nextProps.assigneeData.assigneeData });
        }
        if (nextProps.dealerList.dealerList) {
            this.setState({ dealerList: nextProps.dealerList.dealerList });
        }
        if (nextProps.dealerCodeData.dealerCodeData) {
            this.setState({ dealerCodeList: nextProps.dealerCodeData.dealerCodeData });
        }
        if (nextProps.activityStatus.activityStatus) {
           
            const options = []
            nextProps.activityStatus.activityStatus.map((d) => {
                if(this.props.user.userMdl.activeProgram === "BRiT" && d.value === AppConst.ACTIVITY_STATUS){
                    return false;
                }
                options.push({ value: d.value, label: I18n.t(d.label) }) 
                return null;                            
            });
            this.setState({ activityStatus: options });
        }
        if(!this.state.nmscFlag){
        if (nextProps.activityToolBox.activityToolBox) {
            const options =[]
            options.push({ label: I18n.t('standardMessages.selectAll'),value:"All"},{"value": "None", "label": I18n.t('standardMessages.unSelectAll') })
             nextProps.activityToolBox.activityToolBox.map((d) => {
               options.push({ value: d.value,
                label: d.label } ) 
                return null;          
            });
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var j = 0; j < this.state.selectedToolbox.length; j++) {
                    let value = this.state.selectedToolbox[j].value;
                    // let label = this.state.selectedToolbox[j].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            this.setState({ activityToolBox: options });
        }
    }

        else{
        if (nextProps.toolboxByType.toolboxByType) {
            const options =[]
            options.push({ label: I18n.t('standardMessages.selectAll'),value:"All"},{"value": "None", "label": I18n.t('standardMessages.unSelectAll') })
            nextProps.toolboxByType.toolboxByType.map((d) => {
                options.push({
                    value: d.packageMapPrimaryKey,
                    label: d.toolboxName
                })
                return null;
            });
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var k = 0; k < this.state.selectedToolbox.length; k++) {
                    let value = this.state.selectedToolbox[k].value;
                    // let label = this.state.selectedToolbox[k].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            this.setState({ activityToolBox: options });
        }
    }

        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

    }

    onNmscChange = (selectedOption,event) => {
        console.log("event.action",event.action)
        console.log("event.option",event.option)
        console.log("selectedOption",selectedOption)
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedNmsc: this.state.nmscList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
                return null;
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedNmsc: [] })
            }else{
                this.setState({  selectedNmsc: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.nmscList.length - 1) {
            this.setState({ selectedNmsc : this.state.nmscList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
                return null;
            })    
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedNmsc: selectedOpt})           
        } 
        const activityDto = { ...this.state.activityDto };
        activityDto["nmscCodeList"] = valueOptions;
        this.setState({ activityDto });
        // this.setState({ selectedNmsc: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(activityDto);        
            if(valueOptions.length===0){
            this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
            }
            else{
            this.props.dispatch(fetchTmeToolboxForNmsc(this.props.user.userMdl.activeProgramID,valueOptions));
            }
        }
    }

    reloadDropDowns = (activityDto) => {
        this.props.dispatch(fetchActivityFilters(activityDto, "nmscCode"));
        this.props.dispatch(fetchActivityFilters(activityDto, "l1"));
        this.props.dispatch(fetchActivityFilters(activityDto, "l2"));
        this.props.dispatch(fetchActivityFilters(activityDto, "l3"));
        this.props.dispatch(fetchDealer(activityDto));
        this.props.dispatch(fetchDealerCode(activityDto));
        if(!this.props.user.userMdl.flagBRiT){
        this.props.dispatch(fetchActivityFilters(activityDto, "userId"));
        }
    }
    onBrandChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        const activityDto = { ...this.state.activityDto };
        activityDto["brandList"] = valueOptions;
        this.setState({ activityDto });
        this.setState({ selectedBrand: selectedOption })
        this.reloadDropDowns(activityDto);
    }
    onCountryChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedCountry: this.state.countryList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
                return null;
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedCountry: [] })
            }else{
                this.setState({  selectedCountry: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.countryList.length - 1) {
            this.setState({ selectedCountry: this.state.countryList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
                return null;
            })     
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });
            this.setState({ selectedCountry: selectedOpt})    
        } 
        const activityDto = { ...this.state.activityDto };
        activityDto["countryList"] = valueOptions;
        this.setState({ activityDto });
        // this.setState({ selectedCountry: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(activityDto);
        }
    }
    onRegionChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedRegion: this.state.regionList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedRegion: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedRegion: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
                return null;
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedRegion: [] })
            }else{
                this.setState({  selectedRegion: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.regionList.length - 1) {
            this.setState({ selectedRegion : this.state.regionList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
                return null;
            })    
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedRegion: selectedOpt})           
        } 
        const activityDto = { ...this.state.activityDto };
        activityDto["regionList"] = valueOptions;
        this.setState({ activityDto });
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(activityDto);        
        }
    }
    

   

    onToolBoxChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedToolbox: this.state.activityToolBox})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedToolbox: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedToolbox: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
                return null;
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedToolbox: [] })
            }else{
                this.setState({  selectedToolbox: selectedOption.filter(o => o.value !== "All") })
            }            
        }
        else if (selectedOption.length === this.state.activityToolBox.length - 1) {
            this.setState({ selectedToolbox : this.state.activityToolBox}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
                return null;
            })     
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });
            this.setState({ selectedToolbox: selectedOpt})          
        } 
        const activityDto = { ...this.state.activityDto };
        activityDto["toolboxList"] = valueOptions;
        // this.setState({ selectedToolbox: selectedOption })
        this.setState({ activityDto });
    }
    onStatusChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        const activityDto = { ...this.state.activityDto };
        activityDto["statusList"] = valueOptions;
        this.setState({ selectedStatus: selectedOption })
        this.setState({ activityDto });
    }


    onDateChange = (ev, action) => {
        if (ev) {
            var dt = moment(ev);
            const activityDto = { ...this.state.activityDto };
            activityDto[action] = dt.format("YYYY-MM-DD");
            this.setState({ activityDto });
        } else {
            const activityDto = { ...this.state.activityDto };
            activityDto[action] = "";
            this.setState({ activityDto });
        }

    }


    search = (e) => {
        e.preventDefault();
        var fromDate = this.state.activityDto["activityStartDate"] ? moment(this.state.activityDto["activityStartDate"]).format('YYYY-MM-DD') : null;
        var toDate = this.state.activityDto["activityCompletionDate"] ? moment(this.state.activityDto["activityCompletionDate"]).format('YYYY-MM-DD') : null;

        if (fromDate !== null && !moment(this.state.activityDto["activityStartDate"], 'YYYY-MM-DD', true).isValid()) {
            toastr.error(I18n.t('toastr.DateformatValidation'));
            return false;
        }

        if (toDate !== null && !moment(this.state.activityDto["activityCompletionDate"], 'YYYY-MM-DD', true).isValid()) {
            toastr.error(I18n.t('toastr.DateformatValidation'));
            return false;
        }

        if (fromDate > toDate) {
            toastr.error(I18n.t('toastr.DateValidation'));
            return false;
        }
        const activityDto = { ...this.state.activityDto };
        // if(activityDto.dealer === undefined || (activityDto.dealer !== undefined && activityDto.dealer === "")){
        //     if(activityDto.dealerCodeId !== undefined && activityDto.dealerCodeId === 0){
        //         activityDto["dealerId"] = 0;
        //     }            
        // }
        // if(activityDto.dealerCode !== undefined && activityDto.dealerCode !== ""){
        //     if(activityDto.dealer !== undefined && activityDto.dealer!== "" && activityDto.dealerId !== activityDto.dealerCodeId){
        //         this.props.dispatch(searchEmptyActivityList());
        //         return false;          
        //     }else{
        //         activityDto["dealerId"] = activityDto.dealerCodeId;
        //     }
        // }
        if((activityDto.dealer !== undefined && activityDto.dealer!== "" && activityDto.dealerId === 0)){
            this.props.dispatch(searchEmptyActivityList());
            return false;   
        }  
        activityDto["page"] = 1;
        activityDto["rows"] = 10;
        this.setState({ activityDto });
        this.props.search(activityDto);
    }

    // Teach Autosuggest how to calculate suggestions for any given input value.
    getSuggestions = value => {  
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length; 
        return inputLength > 4 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().indexOf(inputValue) !== -1)
        ) : [];
    };
    getCodeSuggestions = valueDealerCode => {  
        const inputValue = valueDealerCode.trim().toLowerCase();
        const inputLength = inputValue.length;
        let suggestions = [];
        if(this.state.dealerCodeList !== undefined){
            if(inputLength > 0 && inputLength < 3){
                suggestions = this.state.dealerCodeList.filter(code => code.label.toLowerCase() === inputValue);
            } else if (inputLength > 2) {
                suggestions = this.state.dealerCodeList.filter(code => code.label.toLowerCase().indexOf(inputValue) !== -1);
            }
        } 
        return suggestions;
    };
    getAssigneeSuggestions = valueAssignee => {
        const inputValue = valueAssignee.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 4 && this.state.assigneeList !== undefined ? this.state.assigneeList.filter(lang =>
            lang.label.toLowerCase().indexOf(inputValue) !== -1
        ) : [];
    };
    // When suggestion is clicked, Autosuggest needs to populate the input
    // based on the clicked suggestion. Teach Autosuggest how to calculate the
    // input value for every given suggestion.
    getSuggestionValue = suggestion => suggestion.label + "_" + suggestion.value;
    // getSuggestionValueCode = suggestionsCode => suggestionsCode.label + "_" + suggestionsCode.value;
    getSuggestionValueAssignee = suggestionsAssignee => suggestionsAssignee.label + "_" + suggestionsAssignee.value;
    // Use your imagination to render suggestions.
    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );
    // renderSuggestionCode = suggestionsCode => (
    //     <div>
    //         {suggestionsCode.label}
    //     </div>
    // );
    renderSuggestionAssignee = suggestionsAssignee => (
        <div>
            {suggestionsAssignee.label}
        </div>
    );

    onDealerChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const activityDto = { ...this.state.activityDto };
        activityDto["dealer"] = modifiedValue;
        activityDto["dealerId"] = modifiedId;
        this.setState({ activityDto });
        this.setState({
            valueDealer: modifiedValue,
            id: modifiedId
        });
    };
    onDealerCodeChange = e => {
        this.setState({ valueDealerCode: e.target.value });
        const activityDto = { ...this.state.activityDto };
        activityDto["dealerCode"] = e.target.value;
        this.setState({ activityDto });
    }
    // onDealerCodeChange = (event, { newValue }) => {
    //     var modifiedValue, modifiedId;
    //     if (newValue.indexOf("_") > -1) {
    //         modifiedValue = newValue.substr(0, newValue.indexOf("_"));
    //         modifiedId = newValue.substr(newValue.indexOf("_") + 1);
    //     } else {
    //         modifiedValue = newValue;
    //         modifiedId = 0;
    //     }
    //     const activityDto = { ...this.state.activityDto };
    //     activityDto["dealerCode"] = modifiedValue;
    //     activityDto["dealerCodeId"] = modifiedId;
    //     this.setState({ activityDto });
    //     this.setState({
    //         valueDealerCode: modifiedValue,
    //         id: modifiedId
    //     });
    // };    

    onActivityIdChange = (event) => {
        let pattern = /^\d+$/;
        let activityDto = {...this.state.activityDto}
        let valueActivityId='';
        if (!pattern.test(event.target.value)) {
            activityDto["activityId"] = 0
            valueActivityId = event.target.value
        }else{
            activityDto["activityId"] = Number(event.target.value)
            valueActivityId = event.target.value
        }
        this.setState({valueActivityId:valueActivityId,activityDto})
    }   

    onAssigneeChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const activityDto = { ...this.state.activityDto };
        activityDto["assignee"] = modifiedValue;
        this.setState({ activityDto });
        this.setState({
            valueAssignee: modifiedValue,
            idAssignee: modifiedId
        });
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    // onSuggestionsFetchRequestedCode = ({ value }) => {
    //     this.setState({
    //         suggestionsCode: this.getCodeSuggestions(value)
    //     });
    // };
    onSuggestionsFetchRequestedAssignee = ({ value }) => {
        this.setState({
            suggestionsAssignee: this.getAssigneeSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    // onSuggestionsClearRequestedCode = () => {
    //     this.setState({
    //         suggestionsCode: []
    //     });
    // };
    onSuggestionsClearRequestedAssignee = () => {
        this.setState({
            suggestionsAssignee: []
        });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.nmscList !== this.state.nmscList) {
            if (this.state.nmscFlag && this.state.nmscList[0]) {
                const activityDto = { ...this.state.activityDto };
                activityDto["nmscCodeList"] = [this.state.nmscList[0].value];
                if(!this.props.user.userMdl.flagBRiT){
                this.props.dispatch(fetchActivityFilters(activityDto, "userId"));
                }
            }
        }
    }


    render() {
        const { suggestions, valueDealer } = this.state;
        const { valueAssignee, suggestionsAssignee } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
            value: valueDealer,
            onChange: this.onDealerChange
        };
        // const inputPropsCode = {
        //     placeholder: I18n.t('toastr.multiSelectPlaceHolderDealerCode'),
        //     value: valueDealerCode,
        //     onChange: this.onDealerCodeChange
        // };
        const inputPropsAssignee = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
            value: valueAssignee,
            onChange: this.onAssigneeChange
        };
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchActivity">
                    <div className="col-md-12 row control-label">
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem' }}>{I18n.t('Table.nmsc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', width: '6%', overflowWrap: 'normal' }}>{I18n.t('Table.franchise')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem' }}>{I18n.t('Table.country')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem' }}>{I18n.t('Table.dealer')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem' }}>{I18n.t('UserManagement.dealerCode')}</label>                 
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem' }}>{I18n.t('Table.toolbox')}</label>
                    </div>
                    <div className="col-md-12 input-group control-label">
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {this.state.nmscFlag ? <Select
                            placeholder= {I18n.t('others.select')}
                                value={this.state.nmscList[0]}
                                onChange={this.onNmscChange}
                                options={this.state.nmscList}
                                isDisabled={true}
                            /> : <ReactMultiSelectCheckboxes
                                    value={this.state.selectedNmsc}
                                    options={this.state.nmscList}
                                    onChange={this.onNmscChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                    isDisabled={this.state.nmscFlag ? false : false}
                                />}

                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                    <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedBrand}
                                options={this.state.brandList}
                                onChange={this.onBrandChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                    <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedCountry}
                                options={this.state.countryList}
                                onChange={this.onCountryChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}

                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <Autosuggest class="form-control"
                                suggestions={suggestions}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                getSuggestionValue={this.getSuggestionValue}
                                renderSuggestion={this.renderSuggestion}
                                inputProps={inputProps}
                            />

                        </div>
                       
                            {/* <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsCode}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCode}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedCode}
                                    getSuggestionValue={this.getSuggestionValueCode}
                                    renderSuggestion={this.renderSuggestionCode}
                                    inputProps={inputPropsCode}
                                />
                            </div> */}
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <input type="text"
                                    className="form-control"
                                    maxLength="50"
                                    style={{width:'10.2rem',height:'calc(1.5em + .75rem + 5px)'}}
                                    value={this.state.valueDealerCode} 
                                    onChange={this.onDealerCodeChange} />

                            </div>
                 
                        {/* &nbsp;&nbsp;&nbsp; */}

                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedToolbox}
                                options={this.state.activityToolBox}
                                onChange={this.onToolBoxChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                    </div>
                    <br />
                    <div className="col-md-12 row control-label">
                    {this.props.user.userMdl.activeProgram === "BRiT" ? 
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.status')}</label>           
                        :<label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.assignee')}</label>
                    } 
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.activityStartDateFrom')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.activityStartDateTo')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.activityCompletionDateFrom')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.activityCompletionDateTo')}</label>
                        {this.props.user.userMdl.activeProgram === "BRiT" ? 
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0px' }}>{I18n.t('Table.activityId')}</label>
                        : 
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0px'}}>{I18n.t('Table.status')}</label>
                         }
                    </div>
                    <div className="col-md-12 input-group control-label">                        
                        {this.props.user.userMdl.activeProgram === "BRiT" ? 
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedStatus}
                                    options={this.state.activityStatus}
                                    onChange={this.onStatusChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            </div>
                        : null}
                        {this.props.user.userMdl.activeProgram !== "BRiT" ?   
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsAssignee}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedAssignee}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequestedAssignee}
                                    getSuggestionValue={this.getSuggestionValueAssignee}
                                    renderSuggestion={this.renderSuggestionAssignee}
                                    inputProps={inputPropsAssignee}
                                />
                            </div>:null
                        }   
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <DatePicker className="form-control"
                                onChange={(e) => this.onDateChange(e, "activityStartDate")}
                                //placeholderText="Activity Start Date"
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                                selected={this.state.activityDto.activityStartDate ? this.state.activityDto.activityStartDate : null}
                                id="startDate"
                                name="startDate"
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <DatePicker className="form-control"
                                onChange={(e) => this.onDateChange(e, "activityStartDateTo")}
                                //placeholderText="Activity Completion Date"
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                                selected={this.state.activityDto.activityStartDateTo ? this.state.activityDto.activityStartDateTo : null}
                                id="startDateTo"
                                name="startDateTo"
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <DatePicker className="form-control"
                                onChange={(e) => this.onDateChange(e, "activityCompletionDate")}
                                //placeholderText="Activity Start Date"
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                                selected={this.state.activityDto.activityCompletionDate ? this.state.activityDto.activityCompletionDate : null}
                                id="completeDate"
                                name="completeDate"
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <DatePicker className="form-control"
                                onChange={(e) => this.onDateChange(e, "activityCompletionDateTo")}
                                //placeholderText="Activity Completion Date"
                                dateFormat="dd/MM/yyyy"
                                isClearable={true}
                                selected={this.state.activityDto.activityCompletionDateTo ? this.state.activityDto.activityCompletionDateTo : null}
                                id="completeDateTo"
                                name="completeDateTo"
                            />
                        </div>
                        {/* &nbsp;&nbsp;&nbsp; */}

                        {this.props.user.userMdl.activeProgram === "BRiT"?
                        <div className={"col-md-2 noPadding smallDD-" + this.props.theme.name} >       
                            <input
                                    type="number"
                                    placeholder={""}
                                    id ="activityID"
                                    onKeyDown = {(evt)=>["e","+","-","E","."].includes(evt.key) && evt.preventDefault()}
                                    value={this.state.valueActivityId}
                                    onBlur={this.onBlurActivityId}
                                    className="form-control font-ratio"
                                    onChange={this.onActivityIdChange}
                                    pattern="[0-9]+"
                                    inputMode="numeric"
                                    style={{width:"9.5rem"}}
                            />  
                        </div>
                        : <div className={"activitysStatus col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                            value={this.state.selectedStatus}
                            options={this.state.activityStatus}
                            onChange={this.onStatusChange}
                            placeholderButtonLabel={I18n.t('standardMessages.all')}
                        />
                    </div>}
                       
                        {/* <div className={"col-md searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',textAlign:"center" }}>
                        <a 
                         onClick={(e) =>this.search(e)}>
                         <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("Search")} /> 
                       </a>
                         </div> */}
                    </div>
                    <br />
                    <div className="col-md-12 row control-label">
                    {this.props.user.userMdl.activeProgram === "BRiT" ? 
                        <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('Table.status')}</label>           
                        :<label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>Region</label>
                    } 
                    </div>
                    <div className="col-md-12 input-group control-label">                        
                        
                        {this.props.user.userMdl.activeProgram !== "BRiT" ?   
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                {console.log(this.state.nmscFlag)}
                               { this.state.nmscFlag!==true?
                                this.state.selectedNmsc.length>0 ? 
                                <ReactMultiSelectCheckboxes 
                                value={this.state.selectedRegion}
                                isSearchable={true}
                                options={this.state.regionList}
                                onChange={this.onRegionChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                                isDisabled={this.state.nmscFlag===true ? false : false}
                            />:
                                <Select
                            placeholder= {I18n.t('standardMessages.all')}
                                value={this.state.selectedRegion[0]}
                                onChange={this.onRegionChange}
                                options={this.state.regionList}
                                isDisabled={true}
                            />
                                :  <ReactMultiSelectCheckboxes 
                                value={this.state.selectedRegion}
                                isSearchable={true}
                                options={this.state.regionList}
                                onChange={this.onRegionChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                                isDisabled={this.state.nmscFlag===true ? false : false}
                            />}
                            </div>:null
                        }   
                       
                        <div className={"col-md searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',textAlign:"center" }}>
                        <a 
                         onClick={(e) =>this.search(e)}>
                         <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("Search")} /> 
                       </a>
                         </div>
                    </div>
                </div>
                <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        nmscList: state.activityReducer,
        brandList: state.activityReducer,
        countryList: state.activityReducer,
        regionList: state.activityReducer,
        assigneeData: state.activityReducer,
        dealerList: state.activityReducer,
        dealerCodeData: state.activityReducer,
        activityStatus: state.activityReducer,
        activityToolBox: state.activityReducer,
        toolboxByType: state.leftNavReducer,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(SearchActivity)));
