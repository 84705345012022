import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from "mdbreact";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import '../index.css';
import { Form } from 'react-bootstrap';
import { getCommentsHistory } from "../../../actions/evaluationAction";
var I18n = require("react-redux-i18n").I18n;

class addComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            evidenceHistory: [],
            evaluationId: this.props.subQuestion.evaluationId
        }
    }
    componentDidMount() {

    }

    componentWillMount() {
        if (this.state.evaluationId) {
            this.props.dispatch(getCommentsHistory(this.props.subQuestion.evaluationId));
        }
       
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.evaluation.evidenceHistory) {
            this.setState({ evidenceHistory: nextProps.evaluation.evidenceHistory })
        }
        this.setState({ evaluationId: nextProps.evaluation.evaluationId });
    }

    onCommentsChange = (e) => {
        this.setState({ comment: e.target.value });
    }

    submitComment = () => {
        if (!this.state.comment || this.state.comment === "") {
            toastr.error(I18n.t("evaluation.commentMsg"));
            return;
        }

        var subQuestion = this.props.subQuestion;

        if (this.state.evaluationId) {
            subQuestion.evaluationId = this.state.evaluationId;
        }
        this.props.submitAnswer(this.props.answer, subQuestion,"N", this.state.comment, true);
        this.setState({ comment: '' });
        this.props.toggleComment();
    }

    answerFormatter(cell, row) {
        return (
            <div >
                {I18n.t('evaluation.' + row.answer.toLowerCase())}
            </div>
        );
    }


    preventDefault() { }
    render() {
        const columns = [{
            dataField: 'who',
            text: I18n.t('evaluation.who'),
            style: { width: "25%", textAlign: "left" },
            headerStyle: { width: "25%", textAlign: "left" },
        }, {
            dataField: 'answer',
            text: I18n.t('evaluation.answer'),
            style: { width: "12%", textAlign: "left" },
            formatter: this.answerFormatter,
            headerStyle: { width: "11%", textAlign: "left" }
        }, {
            dataField: 'comment',
            text: I18n.t('evaluation.comment'),
            style: { width: "50%", wordWrap: "break-word", textAlign: "left"},
            headerStyle: { width: "50%", textAlign: "left" }
        }, {
            dataField: 'createDate',
            text: I18n.t('evaluation.date'),
            style: { width: "11%" }, 
            headerStyle: { width: "16%" }
        }];

        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'ACTIVITY_EVALUATION')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'ACTIVITY_EVALUATION')[0].accessType : "";
        }
        return (
            <Modal centered
                className={"addComments uploadModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggleComment()}>
                    {I18n.t("evaluation.comments")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md="8">
                                <Form style={{ margin: "20px" }}>
                                    <label className={"questionLabel"}>
                                        {I18n.t("evaluation.comments")}
                                        <span className="redFont"> *</span>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="3" maxLength="200" value={this.state.comment} onChange={this.onCommentsChange}
                                    />

                                </Form>
                            </Col>
                            <Col md="3">
                                {accessType === "R/W" && this.props.activityStatus === "Ongoing" ?
                                    <button style={{ marginTop: '70px' }}
                                        className={"button-" + this.props.theme.name}
                                        onClick={this.submitComment}
                                    >
                                        {I18n.t("evaluation.submit")}
                                    </button> :
                                    <button style={{ marginTop: '70px' }}
                                        className={"button-" + this.props.theme.name}
                                        disabled
                                    >
                                        {I18n.t("evaluation.submit")}
                                    </button>}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <div className="commentHistory">
                        <span >
                            <div style={{ marginLeft: '10px' }} className={"buttonHeading-" + this.props.theme.name}>
                                {I18n.t("Table.history")}
                            </div>
                        </span>
                        <br />
                        <br />

                        <BootstrapTable
                            keyField='historyId'
                            data={this.state.evidenceHistory}
                            columns={columns}
                            hover
                            condensed
                            noDataIndication={I18n.t('evaluation.noCommentAvailable')}
                        />
                    </div>

                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        evaluation: state.evaluationReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(addComments)));


