import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming.js";
import {
  getDealerMainQuestion,
  getDealerSubQuestion,
  fetchEvaluationChapterSequence,
  submitEvaluation,
  completeFirstAudit,
  completeReAudit,
  getSubQuestionForReEvaluation,
  getEvaluationProgressBar,
} from "../../../actions/evaluationAction.jsx";
import SubQuestionnairModal from "../../questionnaireModal/subQuestionnairModal.js";
import {
  launchKaizen,
  saveVisibilityOnUpdate,
} from "../../../actions/activityAction.jsx";
import { confirmAlert } from "react-confirm-alert"; // Import
import { Collapse } from "react-bootstrap";
import LibraryLink from "./libraryLink.jsx";
import UploadEvidence from "./uploadEvidence.jsx";
import AddComments from "./addComments.jsx";
import "../../../components/activity/index.css";
const $ = require("jquery");

var I18n = require("react-redux-i18n").I18n;

class AuditQuestionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openQuestion: true,
      openLibrary: false,
      rotateQuestion: "rotate-true",
      rotateLibrary: "rotate-false",
      questionNo: "",
      mainQuestionDetails: {},
      subQuestionDetails: [],
      chapterSequence: "",
      selectedQuestionId: this.props.selectedNodeId,
      nmscQuestionId: "",
      firstAuditDetails: {},
      reAuditDetails: {},
      isButtonDisabled: false,
      isAttachment: false,
      modal: false,
      isAttachmentSub: false,
      filteredData: [],
    };
    this.toggleQuestion = this.toggleQuestion.bind(this);
    this.toggleLibrary = this.toggleLibrary.bind(this);
    this.getSubQuestion = this.getSubQuestion.bind(this);
  }

  componentDidMount() {
    this.props.dispatch({
      type: "EVALUATION_ID",
      payload: null,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { selectedCriticality, selectedStatus, isSearch } = nextProps; // will receive updated state
    // **Helper function to apply the dynamic filters */
    const applyFilters = () => {
      let filteredData = this.state.subQuestionDetails;
      // Handle filtering by Criticality (e.g., Critical, Non-Critical, etc.)
      if (
        Array.isArray(selectedCriticality) &&
        selectedCriticality?.length > 0
      ) {
        const criticalityValues = selectedCriticality.map((item) => item.value); // Extract selected criticality values
        // If "All" is not selected, filter based on criticality values
        if (!criticalityValues.includes("All")) {
          // Filter for Critical and Non-Critical (if selected)
          if (criticalityValues.includes("Critical")) {
            filteredData = filteredData.filter(
              (subQuestion) => subQuestion.isCritical
            );
          }
          if (criticalityValues.includes("Non-Critical")) {
            filteredData = filteredData.filter(
              (subQuestion) => !subQuestion.isCritical
            );
          }
        }
      }

        // Handle filtering by Status (e.g., Complaint, Incomplete, etc.)
        if (Array.isArray(selectedStatus) && selectedStatus?.length > 0) {
            const statusValues = selectedStatus.map(item => item?.value); // Extract selected status values
            
            if (!statusValues.includes("All")) {
                filteredData = filteredData.filter(subQuestion => {
                    // If "No Answer" is selected, check if the answer is missing
                    if (statusValues.includes("No Answer")) {
                        return !subQuestion.answer; // Only include if answer is missing (null or undefined)
                    }
            
                    // If answer is present and in statusValues, include it
                    if (subQuestion.answer && statusValues.includes(subQuestion.answer)) {
                        return true;
                    }
            
                    return false;
                });
            }
            
        }

      // Update the state with the filtered data
      this.setState({ filteredData });
    };

    // Apply filters based on selected values when new props are received
    applyFilters();
    /** */

    if (
      this.props.selectedNodeId !== nextProps.selectedNodeId &&
      nextProps.selectedNodeId &&
      nextProps.selectedNodeId !== ""
    ) {
      this.setState({
        selectedQuestionId: nextProps.selectedNodeId,
        openQuestion: true,
        openLibrary: false,
        rotateQuestion: "rotate-true",
        rotateLibrary: "rotate-false",
      });
      this.props.dispatch(
        fetchEvaluationChapterSequence(nextProps.selectedNodeId)
      );
      this.props.dispatch(getDealerMainQuestion(nextProps.selectedNodeId));
      if (this.props.isFromFirstAudit) {
        this.props.dispatch(
          getDealerSubQuestion(nextProps.selectedNodeId, this.props.activityId)
        );
      } else {
        this.props.dispatch(
          getSubQuestionForReEvaluation(
            nextProps.selectedNodeId,
            this.props.activityId
          )
        );
      }

      $("ul.evaluationList").scrollTop(0);
      $("div.subQuestionDetails").scrollTop(0);
    }
    if (nextProps.evaluation.evaluationMainQuestion) {
      const mainQuestion = nextProps.evaluation.evaluationMainQuestion[0];
      this.setState({ mainQuestionDetails: mainQuestion });
    }
    if (nextProps.evaluation.evaluationSubQuestion) {
      const subQuestions = [...nextProps.evaluation.evaluationSubQuestion];
      this.setState({ subQuestionDetails: subQuestions });
    }
    if (nextProps.evaluation.nmscChapterSequence) {
      this.setState({
        chapterSequence: nextProps.evaluation.nmscChapterSequence,
      });
    }
    if (nextProps.evaluation.evaluationVersion) {
      this.setState({
        evaluationVersion: nextProps.evaluation.evaluationVersion,
      });
    }
    if (nextProps.evaluation.nmscQuestionId) {
      this.setState({ nmscQuestionId: nextProps.evaluation.nmscQuestionId });
    }
  }

  /** Reset attachment function */
  resetAttachment = () => {
    this.setState({ isAttachment: false });
  };

  /** Reset attachment subQuestion function */
  resetAttachmentSub = () => {
    this.setState({ isAttachmentSub: false });
  };

  /** Toggle function to open-close the modal */
  toggle = (row) => {
    if (row) {
      this.setState({
        modal: !this.state.modal,
        selectedRow: row,
        modalFor: "Edit",
      });
    } else {
      this.setState({
        modal: !this.state.modal,
        selectedRow: null,
        modalFor: "Add",
      });
    }
  };

  toggleQuestion = (e) => {
    if (this.state.rotateQuestion === "rotate-false") {
      this.setState({ rotateQuestion: "rotate-true" });
    } else {
      this.setState({ rotateQuestion: "rotate-false" });
    }
  };
  toggleLibrary = (e) => {
    if (this.state.rotateLibrary === "rotate-false") {
      this.setState({ rotateLibrary: "rotate-true" });
    } else {
      this.setState({ rotateLibrary: "rotate-false" });
    }
  };

  submitAnswer = (
    answer,
    subQuestion,
    currentstatus,
    comment,
    refreshHistory
  ) => {
    console.log(this.props.user.userMdl);
    var evaluationDTO = {
      versionSubQuestionId: subQuestion.versionSubQuestionId,
      activityId: this.props.activityId,
      businessAreaKey: this.props.user.userMdl.businessAreaKey,
      answer: answer,
      loginId: this.props.user.userMdl.username,
      comment: comment,
      evaluationId: subQuestion.evaluationId,
      noToYesIND: currentstatus,
      section: this.props.isFromFirstAudit ? "FIRST_AUDIT" : "RE_EVALUATION",
    };

    this.setState({ subQuestion: subQuestion });
    this.setState({ answer: answer });
    var selectedChapterId = this.state.mainQuestionDetails.versionChapterId;
    this.setState({ selectedChapterId: selectedChapterId });
    this.props.dispatch(
      submitEvaluation(
        evaluationDTO,
        this.state.evaluationVersion,
        selectedChapterId,
        this.props.selectedNodeId,
        refreshHistory
      )
    );
    // this.props.dispatch(getEvaluationProgressBar(this.props.activityId));
    //check Dealer Champion Role
    var isDealerUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (
          roles[i].toUpperCase() === "KET DEALER CHAMPION" ||
          roles[i].toUpperCase() === "KET DEALER INTERNAL" ||
          roles[i].toUpperCase() === "KET NMSC External" ||
          this.props.user.userMdl.roles[i] === "KET NMSC User" ||
          this.props.user.userMdl.roles[i] === "KET NMSC Field"
        ) {
          isDealerUser = true;
          break;
        }
      }
    }
    console.log(isDealerUser);
    if (isDealerUser === true) {
      var viewStatusDTO = {
        activityId: this.props.activityId,
        loginId: this.props.user.userMdl.username,
        questionnaireUpdated: "True",
      };
      this.props.dispatch(saveVisibilityOnUpdate(viewStatusDTO));
    }
  };

  completeFirstAudit = () => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {I18n.t("standardMessages.firstAuditComplete")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.goBack")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.submitCompleteFirstAudit();
                  onClose();
                }}
              >
                {I18n.t("standardMessages.confirm")}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  submitCompleteFirstAudit = () => {
    var launchKaizenDto = {
      businessAreaKey: this.props.user.userMdl.businessAreaKey,
      activityId: this.props.activityId,
      questionId: this.state.selectedQuestionId,
      activityStatus: this.props.activityStatus,
    };
    let response = this.props.dispatch(completeFirstAudit(launchKaizenDto));

    // this.props.dispatch(launchKaizen(launchKaizenDto));
    console.log(response);
    //  this.setstate({isButtonDisabled:true})
  };

  completeReAudit = () => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {I18n.t("standardMessages.firstAuditComplete")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button
                className={"buttonCancel-" + this.props.theme.name}
                onClick={() => onClose()}
              >
                {I18n.t("standardMessages.goBack")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.submitCompleteReAudit();
                  onClose();
                }}
              >
                {I18n.t("standardMessages.confirm")}
              </button>
            </div>
          </div>
        );
      },
    });
  };
  submitCompleteReAudit = () => {
    var reAuditDTO = {
      businessAreaKey: this.props.user.userMdl.businessAreaKey,
      activityId: this.props.activityId,
      questionId: this.state.selectedQuestionId,
    };
    let response = this.props.dispatch(completeReAudit(reAuditDTO));
  };

  toggleEvidence = (subQuestion) => {
    this.setState({ subQuestion: subQuestion });
    this.setState({ toggleEvidence: !this.state.toggleEvidence });
  };

  toggleComment = (subQuestion, answer) => {
    if (subQuestion) {
      this.props.dispatch({
        type: "EVALUATION_ID",
        payload: subQuestion.evaluationId,
      });
      if (!answer) {
        this.setState({ answer: subQuestion.answer });
      }
    }

    this.setState({ subQuestion: subQuestion });
    this.setState({ toggleComment: !this.state.toggleComment });
  };

  submitAnswerNo = (subQuestion) => {
    this.setState({ answer: -1 });
    this.toggleComment(subQuestion, true);
  };

  submitAnswerIncomplete = (subQuestion) => {
    this.setState({ answer: 2 });
    this.toggleComment(subQuestion, true);
  };
  buttonFormatter = (row) => {
    return (
      <div className="btn-toolbar float-right">
        {row.additionalInfo && row.additionalInfo.length > 0 ? (
          <div
            style={{ whiteSpace: "pre" }}
            title={row.additionalInfo}
            className="addP"
          />
        ) : (
          ""
        )}
      </div>
    );
  };

  /** subQuestion attachment icon function */
  buttonFormatterAttachment = (row) => {
    return (
      <a
        onClick={() => {
          this.setState({ isAttachment: true });
          this.setState({ isAttachmentSub: true });
          this.toggle(row);
        }}
        className="d-flex"
        style={{ cursor: "pointer", marginTop: "8px" }}
      >
        <div title={"Add Attachments"} className="attachmentCustomCss" />
      </a>
    );
  };

  getSubQuestion(subQuestion) {
    let accessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      accessType = this.props.user.userMdl.accessControl.filter(
        (obj) => obj.function === "ACTIVITY_EVALUATION"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
            (obj) => obj.function === "ACTIVITY_EVALUATION"
          )[0].accessType
        : "";
    }

    // var hasAccess = true;
    console.log("ststuscheck" + this.props.activityStatus);
    var hasAccess =
      (accessType === "R/W" && this.props.activityStatus === "Ongoing") ||
      this.props.activityStatus === "Completed"
        ? true
        : false;

    // Condition to disable pointer events

    const disablePointerEventsForReAudit =
      this.props.activityDetailsData &&
      (this.props.activityDetailsData.reAudit === "PASSED" ||
        this.props.activityDetailsData.reAudit === "FAILED");
    const disablePointerEventsForFirstAudit =
      this.props.activityDetailsData &&
      (this.props.activityDetailsData.firstAudit === "PASSED" ||
        this.props.activityDetailsData.firstAudit === "FAILED");

    const disablePointerEvents = this.props.isFromFirstAudit
      ? disablePointerEventsForFirstAudit
      : disablePointerEventsForReAudit;

    return (
      <div
        key={subQuestion.nmsc_subquestion_id}
        className="subQuestionEvaluation"
      >
        <div style={{ fontWeight: "700" }} className="evalSubQuestionSeq">
          {(this.state.chapterSequence.type === "LOCAL"
            ? this.state.chapterSequence.sequence + "#"
            : this.state.chapterSequence.sequence) +
            "." +
            (this.state.mainQuestionDetails.type === "LOCAL"
              ? this.state.mainQuestionDetails.seqNum + "#"
              : this.state.mainQuestionDetails.seqNum) +
            "." +
            subQuestion.seqNum +
            " "}
        </div>
        <div
          className={`subQuestionEvaluationContent ${
            disablePointerEvents ? "disable-pointer-events" : ""
          }`}
        >
          {/* <div style={{ fontWeight: '700' }}>{subQuestion.name}</div> */}
          {/* //Required for 4665............................. */}

          <div
            style={{
              fontWeight: "700",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {subQuestion.name}
            {subQuestion.isCritical ? (
              <span title="Critical Items" className="critical-icon"></span>
            ) : (
              " "
            )}
          </div>

          <hr />
          {subQuestion.section === "FIRST_AUDIT" &&
          this.props.user.userMdl.roles.includes("KET-TSP Auditor") &&
          this.props.user.userMdl.activeProgram === "NAS" ? (
            <div className="evaluationAnswers">
              {hasAccess ? (
                <div className="evaluationAnswerButton">
                  {console.log(subQuestion.answer)}
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswer(
                            1,
                            subQuestion,
                            subQuestion.answer === -1 ? "Y" : "N"
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.compliant")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.compliant")}
                  </div>
                </div>
              )}
              {hasAccess ? (
                <div className="evaluationAnswerButton">
                  {console.log(subQuestion.answer)}
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswerIncomplete(
                            subQuestion,
                            subQuestion.answer ? subQuestion.answer : ""
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.incomplete")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.incomplete")}
                  </div>
                </div>
              )}

              {hasAccess ? (
                <div
                  className={
                    subQuestion.noToYesIND === "Y"
                      ? "evaluationAnswerButtonGrey"
                      : "evaluationAnswerButton"
                  }
                >
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswerNo(
                            subQuestion,
                            subQuestion.answer ? subQuestion.answer : ""
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.nonCompliant")}
                  </div>
                  {subQuestion.noToYesIND === "Y" ? (
                    <div className="noToYesmsg">
                      {I18n.t("evaluation.noToYesMsg")}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.nonCompliant")}
                  </div>
                </div>
              )}
              {subQuestion.isApplicable && hasAccess ? (
                <div className="evaluationAnswerButton">
                  <div
                    className="answerContent"
                    onClick={() => this.submitAnswer(0, subQuestion)}
                  >
                    {I18n.t("evaluation.na")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer isNotApplicable">
                  <div className="answerContent">{I18n.t("evaluation.na")}</div>
                </div>
              )}
            </div>
          ) : (
            <div className="evaluationAnswers">
              {subQuestion.answer === 1 ? (
                <div className="evaluationAnswerButton yesAns">
                  <div className="answerContent ">
                    {I18n.t("evaluation.compliant")}
                  </div>
                </div>
              ) : hasAccess ? (
                <div className="evaluationAnswerButton">
                  {console.log(subQuestion.answer)}
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswer(
                            1,
                            subQuestion,
                            subQuestion.answer === -1 ? "Y" : "N"
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.compliant")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.compliant")}
                  </div>
                </div>
              )}
              {subQuestion.answer === 2 ? (
                <div className="evaluationAnswerButton ansIncomplete">
                  <div className="answerContent ">
                    {I18n.t("evaluation.incomplete")}
                  </div>
                </div>
              ) : hasAccess ? (
                <div className="evaluationAnswerButton">
                  {console.log(subQuestion.answer)}
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswerIncomplete(
                            subQuestion,
                            subQuestion.answer ? subQuestion.answer : ""
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.incomplete")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.incomplete")}
                  </div>
                </div>
              )}

              {subQuestion.answer === -1 ? (
                <div className="evaluationAnswerButton noAns">
                  <div className="answerContent">
                    {I18n.t("evaluation.nonCompliant")}
                  </div>
                  {subQuestion.noToYesIND === "Y" ? (
                    <div className="noToYesmsg">
                      {I18n.t("evaluation.noToYesMsg")}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              ) : hasAccess ? (
                <div
                  className={
                    subQuestion.noToYesIND === "Y"
                      ? "evaluationAnswerButtonGrey"
                      : "evaluationAnswerButton"
                  }
                >
                  <div
                    className="answerContent"
                    onClick={() =>
                      this.props.user.userMdl.roles.includes("KET-TSP Auditor")
                        ? this.submitAnswerNo(
                            subQuestion,
                            subQuestion.answer ? subQuestion.answer : ""
                          )
                        : null
                    }
                  >
                    {I18n.t("evaluation.nonCompliant")}
                  </div>
                  {subQuestion.noToYesIND === "Y" ? (
                    <div className="noToYesmsg">
                      {I18n.t("evaluation.noToYesMsg")}
                    </div>
                  ) : (
                    " "
                  )}
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer">
                  <div className="answerContent">
                    {I18n.t("evaluation.nonCompliant")}
                  </div>
                </div>
              )}
              {subQuestion.answer === 0 ? (
                <div className="evaluationAnswerButton naAns">
                  <div className="answerContent">{I18n.t("evaluation.na")}</div>
                </div>
              ) : subQuestion.isApplicable && hasAccess ? (
                <div className="evaluationAnswerButton">
                  <div
                    className="answerContent"
                    onClick={() => this.submitAnswer(0, subQuestion)}
                  >
                    {I18n.t("evaluation.na")}
                  </div>
                </div>
              ) : (
                <div className="evaluationAnswerButton defaultPointer isNotApplicable">
                  <div className="answerContent">{I18n.t("evaluation.na")}</div>
                </div>
              )}
            </div>
          )}
          <div className={"evaluationComments-" + this.props.theme.name}>
            <div
              title={I18n.t("evaluation.comments")}
              className="comment-icon"
            />
            <span>
              {subQuestion.evaluationId ? (
                <a onClick={() => this.toggleComment(subQuestion)}>
                  {I18n.t("evaluation.comments")}
                </a>
              ) : (
                I18n.t("evaluation.comments")
              )}
            </span>
            {subQuestion.isCommentLogged ? (
              <div className="evidenceUploaded" />
            ) : (
              <div className="evidenceNotUploaded" />
            )}
          </div>
          <div className={"evaluationHistory-" + this.props.theme.name}>
            <div
              title={I18n.t("evaluation.evidence")}
              className="evidence-icon"
            />
            <span>
              {subQuestion.evaluationId ? (
                <a onClick={() => this.toggleEvidence(subQuestion)}>
                  {I18n.t("evaluation.evidence")}
                </a>
              ) : (
                I18n.t("evaluation.evidence")
              )}
            </span>
            {subQuestion.isEvidenceUploaded ? (
              <div className="evidenceUploaded" />
            ) : (
              <div className="evidenceNotUploaded" />
            )}
          </div>

          <div className={"evaluationHistory-" + this.props.theme.name}>
            <div title={I18n.t("questionnaire.additionalInfo")} />
            {this.buttonFormatter(subQuestion)}
          </div>
        </div>

        <div className={"evaluationHistory-" + this.props.theme.name}>
          <div title={I18n.t("questionnaire.additionalInfo")} />
          {this.buttonFormatterAttachment(subQuestion)}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.theme.name === "lexus" && $("img.toyota")) {
      $(".mainQuestionArrow").removeClass("toyota");
      $(".mainQuestionArrow").addClass("lexus");
      $(".mainQuestionArrow").attr("src", "/images/upArrow-lexus.png");
    } else if (this.props.theme.name === "toyota" && $("img.lexus")) {
      $(".mainQuestionArrow").removeClass("lexus");
      $(".mainQuestionArrow").addClass("toyota");
      $(".mainQuestionArrow").attr("src", "/images/upArrow-toyota.png");
    } else {
      //..
    }

    const { openQuestion } = this.state;
    const { openLibrary } = this.state;
    var libraryContainer = null;
    if (
      this.state.nmscQuestionId !== "" &&
      this.state.nmscQuestionId !== -9999 &&
      this.state.nmscQuestionId !== "-9999"
    ) {
      libraryContainer = (
        <LibraryLink
          questionId={this.state.nmscQuestionId}
          versionId={this.props.versionId}
        />
      );
    }

    //  Condition to disable pointer events

    const disablePointerEventsForReAudit =
      this.props.activityDetailsData &&
      (this.props.activityDetailsData.reAudit === "PASSED" ||
        this.props.activityDetailsData.reAudit === "FAILED");
    const disablePointerEventsForFirstAudit =
      this.props.activityDetailsData &&
      (this.props.activityDetailsData.firstAudit === "PASSED" ||
        this.props.activityDetailsData.firstAudit === "FAILED");
    const disablePointerEvents = this.props.isFromFirstAudit
      ? disablePointerEventsForFirstAudit
      : disablePointerEventsForReAudit;

    return (
      <div style={{ width: "100%", marginLeft: "1rem" }}>
        <div
          className={`mainQuestionDetails ${
            disablePointerEvents ? "disable-pointer-events" : ""
          }`}
        >
          <div className="col-md-12">
            <div className="questionTitle">
              {I18n.t("questionnaire.question")}
              {" " +
                this.state.chapterSequence.sequence +
                "." +
                this.state.mainQuestionDetails.seqNum +
                (this.state.chapterSequence.type === "LOCAL" ||
                this.state.mainQuestionDetails.type === "LOCAL"
                  ? "#"
                  : "") +
                "- "}
              {this.state.mainQuestionDetails.description}
            </div>
          </div>
          <div className="col-md-11 whySection">
            <div style={{ position: "relative" }}>
              <span
                onClick={() => {
                  this.setState({ openQuestion: !openQuestion });
                  this.toggleQuestion();
                }}
                aria-expanded={openQuestion}
              >
                <img
                  src={"/images/upArrow-" + this.props.theme.name + ".png"}
                  className={
                    this.state.rotateQuestion + " mainQuestionArrow toyota"
                  }
                  alt=""
                />
              </span>
              <span>
                <div className={"buttonHeading-" + this.props.theme.name}>
                  {I18n.t("questionnaire.why")}
                </div>
              </span>
            </div>
            <Collapse
              in={this.state.openQuestion}
              className="mainQuestionWhyEvaluation"
            >
              <div id="collapseText">
                <div className="collapseContainerEval">
                  {this.state.mainQuestionDetails.reason}
                </div>
              </div>
            </Collapse>
          </div>
          <div className="col-md-11 librarySection">
            <div style={{ position: "relative" }}>
              <span
                onClick={() => {
                  this.setState({ openLibrary: !openLibrary });
                  this.toggleLibrary();
                }}
                aria-expanded={openLibrary}
              >
                <img
                  src={"/images/upArrow-" + this.props.theme.name + ".png"}
                  className={
                    this.state.rotateLibrary + " mainQuestionArrow toyota"
                  }
                  alt=""
                />
              </span>
              <span>
                <div className={"buttonHeading-" + this.props.theme.name}>
                  {I18n.t("evaluation.attachments")}
                </div>
              </span>
              <span />
            </div>
            <Collapse
              in={this.state.openLibrary}
              className="mainQuestionLibraryEvaluation"
            >
              <div id="collapseText" className="libraryLinkContainer">
                <div className="collapseContainerLibrary">
                  {this.state.selectedQuestionId !== ""
                    ? libraryContainer
                    : null}
                </div>
              </div>
            </Collapse>
          </div>
        </div>
        <div className="subQuestionDetails">
          {/* <div className=""> */}
          <div className={"buttonHeadingWhat-" + this.props.theme.name}>
            {I18n.t("questionnaire.what")}{" "}
          </div>
          {/* </div> */}

          <ul className="evaluationList">
            {this.props.isSearch
              ? this.state.filteredData.map((subQuestion) => {
                  return (
                    <li key={subQuestion.nmsc_subquestion_id}>
                      {this.getSubQuestion(subQuestion)}
                    </li>
                  );
                })
              : this.state.subQuestionDetails.map((subQuestion) => {
                  return (
                    <li key={subQuestion.nmsc_subquestion_id}>
                      {this.getSubQuestion(subQuestion)}
                    </li>
                  );
                })}
          </ul>
        </div>
        {this.state.toggleEvidence ? (
          <UploadEvidence
            activityId={this.props.activityId}
            questionId={this.state.selectedQuestionId}
            toggleEvidence={this.toggleEvidence}
            subQuestion={this.state.subQuestion}
            activityStatus={this.props.activityStatus}
            chapterSequence={this.state.chapterSequence}
            mainQuestionDetails={this.state.mainQuestionDetails}
          />
        ) : (
          ""
        )}
        {this.state.toggleComment ? (
          <AddComments
            toggleComment={this.toggleComment}
            submitAnswer={this.submitAnswer}
            subQuestion={this.state.subQuestion}
            answer={this.state.answer}
            activityStatus={this.props.activityStatus}
          />
        ) : (
          ""
        )}

        <button
          className={"button-" + this.props.theme.name}
          //   disabled={ (!this.props.user.userMdl.roles.includes("KET-TSP Auditor")) || disablePointerEvents}

          disabled={
            this.props.totalAnswerCount !== this.props.totalQuestionCount ||
            !this.props.user.userMdl.roles.includes("KET-TSP Auditor") ||
            (this.props.isFromFirstAudit
              ? disablePointerEventsForFirstAudit
              : disablePointerEventsForReAudit)
          }
          onClick={(e) =>
            this.props.isFromFirstAudit
              ? this.completeFirstAudit(e)
              : this.completeReAudit(e)
          }
        >
          {I18n.t(
            this.props.isFromFirstAudit
              ? "Complete Audit"
              : "Complete Re Audit",
            {
              defaultValue: this.props.isFromFirstAudit
                ? "Complete Audit"
                : "Complete Re Audit",
            }
          )}
        </button>

        {this.state.modal === true ? (
          <SubQuestionnairModal
            modal={this.state.modal}
            toggle={this.toggle}
            modalFor={this.state.modalFor}
            selectedRow={this.state.selectedRow}
            questionId={this.props.selectedQuestionId}
            isNmsc={this.state.isNmsc}
            activeTabNmscLang={this.props.activeTabNmscLang}
            questionNo={this.state.currentQuestionNumber}
            toolboxId={this.props.toolboxId}
            chapterId={this.state.chapterId}
            mainModal={this.state.libraryModal}
            alreadyLinked={this.state.libraryLinks}
            isAttachment={this.state.isAttachment} // for add/edit attachment
            resetAttachment={this.resetAttachment}
            isAttachmentSub={this.state.isAttachmentSub}
            resetAttachmentSub={
              this.state.isAttachmentSub && this.resetAttachmentSub
            }
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    evaluation: state.evaluationReducer,
    tab: state.tabReducer,
    activityDetailsData: state.evaluationReducer.activityDetails,
  };
};
export default withRouter(
  connect(mapStateToProps)(withTheme(AuditQuestionDetails))
);
