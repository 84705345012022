import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import $ from 'jquery';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {MDBIcon } from "mdbreact";
import {filtersUserManagement} from '../../../actions/userManagementAction.jsx'
import Autosuggest from 'react-autosuggest';
import * as AppConst from "../../../AppConstant";
var I18n = require("react-redux-i18n").I18n;

class SearchUser extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            show: false,
            nmscFlag: false,
            suggestionsTarsId: [],
            suggestionsFirstName : [],
            suggestionsLastName : [],
            suggestionsEmail : [],
            dealerList :[],
            valueTarsId: '',
            valueFirstName :'',
            valueLastName :'',
            valueEmail:'',
            nmscIdList: [],
            tarsIdList:[],
            firstNameList :[],
            lastNameList: [],
            emailIdList : [],
            roleList :[],
            statusList: [{ label: I18n.t("UserManagement.ACTIVE"), value:I18n.t("UserManagement.ACTIVE") }, { label: I18n.t("UserManagement.INACTIVE"), value: I18n.t("UserManagement.INACTIVE") }],
            downloadedList: [{ label: I18n.t("reachItDashboard.Downloaded"), value:true }, { label: I18n.t("reachItDashboard.not") +" "+I18n.t("reachItDashboard.Downloaded"), value: false }],
            selectedNmscId: [],
            selectedTarsId: "",
            selectedFirstName: "",
            selectedLastName: "",
            selectedRole: [],
            selectedStatus: [],
            selectedDownload: [],
            selectedEmail:"",
            userDto: {
                nmscIdList: null,
                tarsId : null,
                roleList : null,
                firstName : null,
                lastName : null,
                email:null,
                adminUserId: this.props.user.userMdl.username,
                adminUserNmscCode : this.props.user.userMdl.nmsc,
                statusList: null,
                downloadedList: []
            },
            defaultAllObj:[{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }], 
        };
        //this.changeSearchDto = this.changeSearchDto.bind(this);
    }
    componentWillReceiveProps(nextProps) {
        const userDTO = {...this.state.userDto};
        userDTO["page"] = nextProps.searchDto.page;
        userDTO["rows"] = nextProps.searchDto.rows;
        userDTO["sizePerPage"] = nextProps.searchDto.sizePerPage;
        let roles = [];
        roles = nextProps.user.userMdl.roles;
        this.setState({userDto : userDTO})
        //If user role is NMSC or DEALER then set flag as true
        let nmscFlag = false;
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1) {

                    nmscFlag = true;
                    this.setState({ nmscFlag: true });
                    break;
                }
            }
        }
        if (nextProps.userFilters) {
            this.setState({ nmscIdList: this.state.nmscFlag ? nextProps.userFilters.nmscIdList : this.state.defaultAllObj.concat(nextProps.userFilters.nmscIdList),            
            tarsIdList: nextProps.userFilters.tarsIdList,
            firstNameList:nextProps.userFilters.firstNameList,
            lastNameList : nextProps.userFilters.lastNameList,
            roleList : this.state.defaultAllObj.concat(nextProps.userFilters.roleList)
            });
          
        }
        if(this.props.emailIdList !== nextProps.emailIdList && nextProps.emailIdList!== undefined && nextProps.emailIdList!== null){
            this.setState({
                emailIdList : nextProps.emailIdList
            })
        }

        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

    }

    componentWillMount() {
        
       
        let userDTO = {...this.props.userDto}
        
        userDTO["page"] = this.props.searchDto.page
        userDTO["sizePerPage"] = this.props.searchDto.sizePerPage;
        userDTO["rows"] = this.props.searchDto.rows
        userDTO["adminUserType"] = this.props.searchDto.adminUserType
        userDTO["showSortIcon"] = false
        userDTO["statusList"] = this.getSelectedList([this.state.statusList[0]])
        let selectedStatus = this.state.statusList[0]
        selectedStatus["selected"] = 'selected'
        // userDTO["downloadedList"] = this.getSelectedList([this.state.downloadedList[0]])
        // let selectedDownload = this.state.downloadedList[0]
        // selectedDownload["selected"] = 'selected'
        this.setState({selectedStatus :[selectedStatus] ,userDto : userDTO})

        if( this.props.userSelectedFilters !== null && this.props.userSelectedFilters !==undefined){            
            userDTO["nmscIdList"] = this.getSelectedList(this.props.userSelectedFilters.selectedNmscId)
            userDTO["tarsId"] =  this.props.userSelectedFilters.valueTarsId
            userDTO["firstName"] =  this.props.userSelectedFilters.valueFirstName
            userDTO["lastName"] =  this.props.userSelectedFilters.valueLastName
            userDTO["roleList"] = this.getSelectedList(this.props.userSelectedFilters.selectedRole)
            userDTO["statusList"] = this.getSelectedList(this.props.userSelectedFilters.selectedStatus)   
            userDTO["downloadedList"] = this.getSelectedList(this.props.userSelectedFilters.selectedDownload)   
            userDTO["email"] =  this.props.userSelectedFilters.valueEmail        
            this.setState({
                selectedNmscId:this.props.userSelectedFilters.selectedNmscId ,
                valueTarsId: this.props.userSelectedFilters.valueTarsId,
                valueFirstName :this.props.userSelectedFilters.valueFirstName,
                valueLastName :this.props.userSelectedFilters.valueLastName,
                valueEmail : this.props.userSelectedFilters.valueEmail,
                selectedRole:this.props.userSelectedFilters.selectedRole,
                selectedStatus:this.props.userSelectedFilters.selectedStatus,
                selectedDownload:this.props.userSelectedFilters.selectedDownload,
                userDto : userDTO
            });
        }     
        this.props.update(userDTO);               
    }

    getSelectedList(selectedList) {
        let filteredList = null;
        if (selectedList !== undefined && selectedList !== null) {
            if(selectedList.length >0){
                filteredList = [];
                selectedList.map(obj => {
                filteredList.push(obj.value);
            })
        }
             }

        return filteredList;   
        }

        getSelectedString(selectedString) {
            let filteredString = null;
            if (selectedString !== undefined || selectedString !== null) {
                if(selectedString.length >0){
                    filteredString = selectedString.value;
            
                }
             }
            return filteredString;   
            }    


   
    componentDidUpdate(prevProps, prevState) {
        if (prevState.nmscIdList !== this.state.nmscIdList) {
            if (this.state.nmscFlag && this.state.nmscList && this.state.nmscList[0] !== undefined ) {
                const userDto = { ...this.state.userDto };
                userDto["nmscId"] = [this.state.nmscIdList[0].value];
                this.setState({userDto,selectedNmscId : this.state.nmscIdList[0].value})
               
            }
        }
        
    }

   
    getSuggestionsTrasId = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.tarsIdList !== undefined ? (this.state.tarsIdList.filter(lang =>
            lang.label.toLowerCase().indexOf(inputValue) !== -1)
        ) : [];
    };
    getSuggestionsFirstName = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.firstNameList !== undefined ? (this.state.firstNameList.filter(lang =>
            lang.label.toLowerCase().startsWith(inputValue))
        ) : [];
    };

    getSuggestionsLastName = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.lastNameList !== undefined ? (this.state.lastNameList.filter(lang =>
            lang.label.toLowerCase().startsWith(inputValue))
        ) : [];
    };

    getSuggestionsEmail = value => {
        // const inputValue = value.trim().toLowerCase();
        const inputValue = typeof value !== undefined ? value : '' ;
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.emailIdList !== undefined ? (this.state.emailIdList.filter(email =>
            email.label.toLowerCase().startsWith(inputValue))
        ) : [];
    };

    onTarsIdChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const userDto = { ...this.state.userDto };
        if(modifiedValue.trim().length>0){
        userDto["tarsId"] = modifiedValue;
        this.setState({ userDto });}
        else{
            userDto["tarsId"] = null;
        this.setState({ userDto })
        }
        this.setState({
            valueTarsId: modifiedValue,
            idTars: modifiedId
        });
    };

    onFirstNameChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const userDto = { ...this.state.userDto };
        if(modifiedValue.trim().length>0){
        userDto["firstName"] = modifiedValue;
        this.setState({ userDto });
        }else{
            userDto["firstName"] = null;
        this.setState({ userDto });
        }
        this.setState({
            valueFirstName: modifiedValue,
            idFirstName: modifiedId
        });
    };

    onLastNameChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const userDto = { ...this.state.userDto };
        if(modifiedValue.trim().length>0){
            userDto["lastName"] = modifiedValue;
            this.setState({ userDto });
            }else{
                userDto["lastName"] = null;
            this.setState({ userDto });
            }
        this.setState({
            valueLastName: modifiedValue,
            idLastName: modifiedId
        });
    };

    onEmailChange = (event, { newValue }) => {
        const userDto = { ...this.state.userDto };
        const newVal = typeof newValue !== undefined ? newValue : '' ;
        if(newVal && newVal.trim().length>0){
            userDto["email"] = newVal;
            this.setState({ userDto });
            }else{
                userDto["email"] = null;
                this.setState({ userDto });
            }
        this.setState({
            valueEmail: newVal,
        });
    };


    onNmscChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedNmscId: this.state.nmscIdList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedNmscId: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedNmscId: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedNmscId: [] })
            }else{
                this.setState({  selectedNmscId: selectedOption.filter(o => o.value !== "All") })
            }  
        }
        else if (selectedOption.length === this.state.nmscIdList.length - 1) {
            this.setState({ selectedNmscId : this.state.nmscIdList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })      
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });
            this.setState({ selectedNmscId: selectedOpt})          
        }             
        const userDto = { ...this.state.userDto };
        userDto["nmscIdList"] = valueOptions;
        this.setState({ userDto });
    }

    onRoleChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedRole: this.state.roleList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedRole: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedRole: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedRole: [] })
            }else{
                this.setState({  selectedRole: selectedOption.filter(o => o.value !== "All") })
            }              
        }
        else if (selectedOption.length === this.state.roleList.length - 1) {
            this.setState({ selectedRole : this.state.roleList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });
            this.setState({ selectedRole: selectedOpt})           
        }
        const userDto = { ...this.state.userDto };
        userDto["roleList"] = valueOptions;
        this.setState({ userDto });
        //this.setState({ selectedRole: selectedOption })
        //this.reloadDropDowns(dealerDto);
    }
    onStatusChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        const userDto = { ...this.state.userDto };
        userDto["statusList"] = valueOptions;
        this.setState({ userDto });
        this.setState({ selectedStatus: selectedOption })
    }

    onDownloadChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        const userDto = { ...this.state.userDto };
        userDto["downloadedList"] = valueOptions;
        this.setState({ userDto });
        this.setState({ selectedDownload: selectedOption })
    }
    onSuggestionsTarsFetchRequested = ({ value }) => {
        this.setState({
            suggestionsTarsId: this.getSuggestionsTrasId(value)
        });
    };
    onSuggestionsTarsClearRequested = () => {
        this.setState({
            suggestionsTarsId: []
        });
    };
    getSuggestionTarsValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionTars = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onSuggestionsFirstNameFetchRequested = ({ value }) => {
        this.setState({
            suggestionsFirstName: this.getSuggestionsFirstName(value)
        });
    };
    onSuggestionsFirstNameClearRequested = () => {
        this.setState({
            suggestionsFirstName: []
        });
    };
    getSuggestionFirstNameValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionFirstName = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onSuggestionsLastNameFetchRequested = ({ value }) => {

        this.setState({
            suggestionsLastName: this.getSuggestionsLastName(value)
        });
    };
    onSuggestionsLastNameClearRequested = () => {
        this.setState({
            suggestionsLastName: []
        });
    };
    getSuggestionLastNameValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionLastName = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onSuggestionsEmailFetchRequested = ({ value }) => {

        this.setState({
            suggestionsEmail: this.getSuggestionsEmail(value)
        });
    };
    onSuggestionsEmailClearRequested = () => {
        this.setState({
            suggestionsEmail: []
        });
    };
    getSuggestionEmailValue = suggestion => suggestion.value;

    renderSuggestionEmail = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    search = (e) => {
        e.preventDefault();
        const userDTO = {...this.state.userDto}

        let { selectedNmscId } = this.state

        if(this.state.nmscFlag){
            userDTO["nmscIdList"] = [this.state.nmscIdList[0].value];
            selectedNmscId = this.state.nmscIdList
        }
        if(userDTO["downloadedList"] === undefined || userDTO["downloadedList"] === null){
            userDTO["downloadedList"] = []
        }
        this.setState({selectedNmscId,userDto : userDTO})

        
        this.props.dispatch(filtersUserManagement({selectedNmscId : selectedNmscId,selectedRole:this.state.selectedRole,
        selectedStatus : this.state.selectedStatus , valueFirstName : this.state.valueFirstName,valueLastName : this.state.valueLastName , valueTarsId : this.state.valueTarsId, valueEmail : this.state.valueEmail,
        selectedDownload : this.state.selectedDownload
        }))
        this.props.search(userDTO)
    }
    onExcelClick = (e) => {
        const userDTO = {...this.state.userDto}
        if(this.state.nmscFlag){
            userDTO["nmscIdList"] = [this.state.nmscIdList[0].value];
        }
        if(userDTO["downloadedList"] === null){
            userDTO["downloadedList"] = []
        }
        this.props.onExcelClick(userDTO)
    }
    
    render() {
        
        const { suggestionsTarsId, valueTarsId,suggestionsFirstName,valueFirstName,suggestionsLastName,valueLastName,suggestionsEmail,valueEmail } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputTarsProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueTarsId,
            onChange: this.onTarsIdChange
        };
        const inputFirstNameProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueFirstName,
            onChange: this.onFirstNameChange
        };

        const inputLastNameProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueLastName,
            onChange: this.onLastNameChange
        };

        const inputEmailProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueEmail !== undefined ? valueEmail : '',
            onChange: this.onEmailChange
        };

        let isBRiT = this.props.user.userMdl.flagBRiT

        let isReachIT = (this.props.location.pathname ==="/reachIthome" || this.props.location.pathname ==="/home") ?
        this.props.user.userMdl.businessAreaKey === "EA" &&
        (this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_Admin) ||
        this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin)) : false
        
        let isReachItNmsc= (this.props.location.pathname ==="/reachIthome" || this.props.location.pathname ==="/home") ? this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin)  : false
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchActivity">
                    <div className="col-md-12 row control-label">
                        {isReachIT
                        ? <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '5px 5px 5px 0px', marginRight: isReachItNmsc ?'-35px':'0px' }}>{I18n.t('UserManagement.nmscId')}</label>:
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '5px 5px 5px 0px' }}>{I18n.t('UserManagement.nmscId')}</label>}
                        {this.props.user.userMdl.businessAreaKey === "EA" &&  this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_Admin) ? 
                        <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'12rem', margin : '5px',marginLeft:'15px' }}>{I18n.t("UserManagement.tarsId")}</label>:
                        <label className="col-md-1.5 control-label" style={{ paddingLeft: '0rem', width:'12rem', margin : '5px'}}>{I18n.t("UserManagement.tarsId")}</label>}
                        <label className="col-md-1.5 control-label" style={{ padding: '0rem', width:'12rem', margin : '5px' }}>{I18n.t("UserManagement.firstName")}</label>
                        <label className="col-md-1.5 control-label" style={{ padding: '0rem', width:'12rem', margin : '5px 0px 5px 3px' }}>{I18n.t("UserManagement.lastName")}</label>
                        {isBRiT?<label className="col-md-2.5 control-label" style={{ paddingLeft: '0rem', width:'14rem', margin : '5px' }}>{I18n.t("UserManagement.emailId")}</label>:""}
                        {isReachIT? "":<label className={isBRiT?"col-md-1.5 control-label":"col-md-2.5 control-label"} style={{ paddingLeft: '0rem', width:'9.5rem', margin : '5px' }}>{I18n.t("UserManagement.role")}</label>}
                        {isReachIT?"":<label className={isBRiT?"col-md-1 control-label":"col-md-1.5 control-label"} style={{ paddingLeft: '0rem', width:'9rem', margin : '5px 5px 5px 4px' }}>{I18n.t('Table.status')}</label>}
                      {isReachIT? <label className={"col-md-1.5 control-label"} style={{ paddingLeft: '0rem', width:'9rem', margin : '5px 5px 5px 4px' }}>{I18n.t('Table.status')}</label> : ""}
                    </div>
                    <div className="col-md-12 input-group control-label">
                        <div 
                        className={isReachIT
                              ? "col-md-1.5 noPadding smallDD-" + this.props.theme.name
                              : "col-md-1 noPadding smallDD-" + this.props.theme.name
                          }
                            >
                            {this.state.nmscFlag ? <Select
                                className = "dsabledSelect"
                                value={this.state.nmscIdList[0]}
                                onChange={this.onNmscChange}
                                options={this.state.nmscIdList}
                                isDisabled={true}
                            /> : <ReactMultiSelectCheckboxes
                                    value={this.state.selectedNmscId}
                                    options={this.state.nmscIdList}
                                    onChange={this.onNmscChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                    isDisabled={this.state.nmscFlag ? false : false}
                                />}

                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-1.5 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsTarsId}
                                    onSuggestionsFetchRequested={this.onSuggestionsTarsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsTarsClearRequested}
                                    getSuggestionValue={this.getSuggestionTarsValue}
                                    renderSuggestion={this.renderSuggestionTars}
                                    inputProps={inputTarsProps}
                                />
                            
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-1.5 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsFirstName}
                                    onSuggestionsFetchRequested={this.onSuggestionsFirstNameFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsFirstNameClearRequested}
                                    getSuggestionValue={this.getSuggestionFirstNameValue}
                                    renderSuggestion={this.renderSuggestionFirstName}
                                    inputProps={inputFirstNameProps}
                                />
                            
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-1.5 noPadding bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsLastName}
                                    onSuggestionsFetchRequested={this.onSuggestionsLastNameFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsLastNameClearRequested}
                                    getSuggestionValue={this.getSuggestionLastNameValue}
                                    renderSuggestion={this.renderSuggestionLastName}
                                    inputProps={inputLastNameProps}
                                />
                            
                        </div>
                        { isBRiT?<div style = {{margin : '6px'}}/>:""}
                        {isBRiT?
                        <div className={"col-md-2.5 noPadding britEmail bigDD-" + this.props.theme.name}>
                                <Autosuggest class="form-control"
                                    suggestions={suggestionsEmail}
                                    onSuggestionsFetchRequested={this.onSuggestionsEmailFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsEmailClearRequested}
                                    getSuggestionValue={this.getSuggestionEmailValue}
                                    renderSuggestion={this.renderSuggestionEmail}
                                    inputProps={inputEmailProps}
                                />
                        </div>:""}
                        {isReachIT? "":
                        <div style = {{margin : '6px'}}/>
                        }
                        {/* &nbsp;&nbsp;&nbsp; */}
                        {isReachIT? "":
                        (<div className={isBRiT ? "col-md-1.5 noPadding smallDD-" + this.props.theme.name : "col-md-2.5 noPadding smallDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedRole}
                                    options={this.state.roleList}
                                    onChange={this.onRoleChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')} />
                            </div>)}
                        <div style = {{margin : '6px'}}/>
                        {/* &nbsp;&nbsp;&nbsp; */}
                        {isReachIT? "" :
                        <div className={isBRiT ?"col-md-1 noPadding userStatus smallDD-" + this.props.theme.name:"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedStatus}
                                options={this.state.statusList}
                                onChange={this.onStatusChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div>}
                        {isReachIT? 
                            <div className={"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedDownload}
                                options={this.state.downloadedList}
                                onChange={this.onDownloadChange}
                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                            />
                        </div> : ""                
                        }
                        {/* &nbsp;&nbsp;&nbsp; */}
                        {isBRiT?"":isReachIT ?
                        <div style = {{margin : '20px 25px 20px 0px'}}/> :
                        <div style = {{margin : '20px 75px 20px 20px'}}/>}
                        {isBRiT?"": <div className={isReachIT ? "col-md-1.5 searchIcon-"+ this.props.theme.name : "col-md-1 searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',  }}>
                            {/* <button className={"button-" + this.props.theme.name + " noMargin"} onClick={(e) => this.search(e)}>{I18n.t('tmeAdminMaster.search')}</button> */}
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("tmeAdminMaster.search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>
                        </div>
                        }    
                        {isReachIT ?
                            <div className={"col-md-2 searchIcon-"+ this.props.theme.name} 
                                style={{ borderTop: '0px', padding: '0px', paddingLeft:'15px',
                                    marginLeft: isReachItNmsc ? "50px" : "12px"
                                }}>
                            <button
                                className={"button-" + this.props.theme.name}
                                style={{ padding: "0.3rem 25px 0.3rem 25px", float:"right"                                    
                                }}
                                onClick={(e) =>this.onExcelClick(e)}
                                title={I18n.t("reachItDashboard.exportToExcel")} 
                                >
                                {I18n.t("reachItDashboard.exportToExcel")}
                            </button>
                        </div>:""
                        }                  
                    </div>
                    {isBRiT?<div className="col-md-12 row control-label">
                            <div className={"col-md-12 searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',paddingTop:"10px"  }}>
                            {/* <button className={"button-" + this.props.theme.name + " noMargin"} onClick={(e) => this.search(e)}>{I18n.t('tmeAdminMaster.search')}</button> */}
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("tmeAdminMaster.search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>
                        </div>
                        </div>:""}
                </div>
                <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        userFilters : state.userManagementReducer.userFilters,
        userSelectedFilters : state.userManagementReducer.userSelectedFilters,
        emailIdList : state.userManagementReducer.emailIdList
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(SearchUser)));