const translationsBG = {
  bg: {
    Policy: {
      HeaderMsg:
        "Уведомление за поверителност за използване на Вашите лични данни в приложението KET",
      Message1:
        "Уведомление за поверителност за използване на Вашите лични данни в приложението KET.Това винаги трябва да се прочита заедно с Политиката на Тойота за Защита на Лични данни,където са посочени  условията на използване на Вашите Лични Данни,които трябва да бъдат приети първо,за да можете да продължите напред.",
      Message2:
        "Тойота Мотор Европа с централен офис Avenue du Bourget/Bourgetlaan 60, 1140 Брюксел,Белгия,като Администратор на Вашите лични данни за целите описани долу.",
      Message3: "Каква лична информация ще бъде събирана?",
      Message4:
        "Ще бъдат обединени следните категория/ии от личната Ви информация свързани с  KET:",
      Table1: {
        tr1: "Категория/ии лични данни",
        tr2: "Потребителско ID",
        tr3: "Потребител(Пълно име)",
        tr4: "Марка(Toyota/Lexus)",
        tr5: "Предпочитан език",
        tr6: "Партньорска организация"
      },

      Message5: "Цел и законово основание за използване на Вашите лични данни",
      Message6: "Ние ще използваме Вашите лични данни за целите и законовите основания описани долу:",
      Table2: {
        tr1: {
          th1: "Цел:",
          th2: "Законово основание"
        },
        tr2: {
          td1: "KET – 1: Kaizen Дилърство",
          td2: "Законов интерес проследен от Администратор на  данни"
        },
        tr3: {
          td1: "KET – 2: ИТ Поддръжка",
          td2: "Законов интерес проследен от Администратор на  данни"
        }
      },
      Message7: "Продължителност на съхранение на Вашите лични данни",
      Message8: "Вашите лични данни ще бъдат съхранявани за период посочен долу:",
      Table3: {
        tr1: {
          th1: "Период на съхранение:",
          th2: "Категория/ии лични данни"
        },
        tr2: {
          td1: "Всички Лични данни като описаните горе,ще бъдат съхранени за максимален период от 5 години+ 1 месец от деня на затваряне на KET",
          td2: "Име на потребител/Фамилия/Избор език/Организация"
        }
      },
      Message9: "Опция:В случай на разкриване/трансфериране на Вашите лични данни към страни извън EEA",
      Message10: "Вашите лични данни ще бъдат достъпни до ползватели в държави извън EEA посочени долу:",
      Table4: {
        tr1: {
          td1: "Идентичност",
          td2: "Държава",
          td3: ""
        },
        tr2: {
          td1: "Получател извън EEA",
          td2: "Infosys",
          td3: "Индия"
        }
      },
      Message11: "Потвърждавам,че разбирам това съобщение.Прочетох и приемам условията на Тойота за използване и защита  на Лични данни."
    },
    ACCESSORIES: "Аксесоари",
    activity: {
      Assignee: "Пълномощник",
      CompletionDate: "Дата приключване",
      Dealer: "Дилър",
      FirstVisit: "Първо посещение",
      kpi: {
        kpi: "Ключовите показатели(KPI) трябва да бъдат цифри.(+ve или -ve)",
        kpiLength: "Максималната дължина на KPI е 10 символа",
        mandatory: "Моля, въведете валидни данни във всички задължителни полета",
        mandatoryTargets: "Моля, въведете всички ключови показатеи (КП).",
        target: "Цел трябва да бъде  в цифри",
        targetLength: "Максималната дължина за цел е 10 символа"
      },
      ReminderOn: "Включено напомняне",
      statusDD: "Статус",
      Toolbox: "Набор от инструменти",
      activityCreation: "Процесът на създаване на дейност е стартиран.",
      activityCreated: "Дейностите са създадени."
    },
    DPOK: "DPOK",
    EFFICIENCY: "Ефективност",
    FIX_IT_RIGHT: "Правилен ремонт",
    footer: {
      message: "Задължителни полета"
    },
    FOUNDATION: "Основа",
    MAINTENANCE_REMINDERS: "Напомняне за сервиз",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Основи",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Апр",
      aug: "Авг",
      dec: "Дек",
      feb: "Фев",
      jan: "Ян",
      jul: "Юли",
      jun: "Юни",
      mar: "Мар",
      may: "Май",
      nov: "Ное",
      oct: "Окт",
      sep: "Сеп"
    },
    others: {
      back: "Назад",
      closed: "Затворени",
      completed: "Завършени ",
      countryLanguageCode: "Код на държава / език ",
      createActivity: "Създаване на дейност",
      createdDate: "Дата на създаване",
      dataUneditableNote:
        "Забележка: Веднъж запаметени, данните не могат да се редактират. За да промените данните, се свържете с администратор на NMSC.",
      date: "Дата",
      downloadQuestionnaire: "Изтеглете въпросник",
      editActivity: "Редактиране на дейност",
      initialHelpText: "Първоначалното измерване на КПИ трябва да бъде средната стойност от предишните пълни шест месеца",
      inProgress: "Изпълнява се",
      name: "Име",
      next: "Следващ",
      noActivities: "Няма намерени дейности",
      noResults: "Няма резултати",
      of: "от",
      ongoing: "Текущи",
      open: "Отворен",
      page: "Страница",
      planned: "Планирани",
      problemDescription: "Описание на проблема",
      signature: "Подпис",
      select: "Изберете.",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Възникна грешка по време на изтегляне файл.",
      no_published_subquestion: "Моля публикувайте Въпросника за този инструмент , за да се стартира избраната  Дейност.",
      activity_upload_error_msg: "Грешка при зареждане на детайли Дейност",
      action_plan_created: "Успешно създаден план за действие",
      action_plan_deleted: "Успешно премахнат план за действие",
      action_plan_saved: "Успешно запазен план за действие",
      activity_kpi_saved: "KPI действия запазени успешно",
      master_kpi_confirm_delete:
        "Сигурни ли сте,че искате да изтриете избраният KPI",
      master_kpi_created: "Главният КПИ е създаден успешно",
      master_kpi_deleted: "Главният КПИ беше изтрит",
      master_kpi_not_found: "Главният КПИ не е намерен",
      master_kpi_published: "Избраните главни КПИ са публикувани успешно",
      master_kpi_retired: "Главният КПИ беше оттеглен",
      master_kpi_saved: "Главният КПИ е запазен успешно",
      subquestion_created: "Подвъпросът беше успешно запазен.",
      subquestion_updated: "Подвъпросът беше успешно обновен.",
      subquestionnaire_not_found: "Подвъпросът не беше открит.",
      subquestion_retired: "Подвъпросът беше премахнат.",
      subquestion_deleted: "Подвъпросът беше изтрит.",
      subquestion_published: "Подвъпросите бяха успешно публикувани.",
      mainquestion_created: "Главният въпросник беше създаден.",
      mainquestion_deleted: "Главният въпросник беше изтрит.",
      mainquestion_updated: "Главният въпрос беше обновен.",
      duplicateMsg: "Дублирани записи в Excel файла.",
      deleteErr: "Грешка при изтриване на файла",
      toolbox_deleted: "Инструментът е изтрит успешно.",
      toolbox_already_exists: "Този инструмент вече съществува.",
      toolbox_saved: "Инструментът е запазен.",
      toolbox_created: "Инструментът е създаден.",
      toolbox_not_found: "Инструментът не е намерен.",
      published_content_found: "Този инструмент не може да бъде изтрит, тъй като има публикувано съдържание",
      dealers_already_mapped: " Избраните дилъри вече са отразени за този потребител. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Дилърът е добавен. ",
      dealers_added: "  Дилърът е добавен. ",
      total_dealers_mapped_now: "Общият брой на дилърите, свързани с този потребител е…..... ",
      no_dealer_available: "Няма избрани дилъри за избраните критерии.",
      proper_dealer_selected: "Моля, изберете подходящ дилър.",
      user_referenced_ongoing_activity: "В момента потребителят е назначен за текущи дейности. Моля, заменете потребителя в тези дейности и опитайте отново. ",
      want_to_remove_mapping: "Наистина ли искате да премахнете картографирането на потребителя за тези дилъри?",
      user_mapping_removed: "Избраните дилъри са премахнати успешно от потребителя.",
      dealer_closed_in_A2D: "Този дилър е регистриран като „Затворен“ в A2D.",
      network_level_not_found: "Моля, изберете валидна комбинация от мрежови нива според вашата настройка на KET.",
      duplicate_dealer_uuid: "Дилърът вече присъства в базата данни KET.",
      dealer_created: "Дилърът е създаден.",
      dealer_updated: "Атрибутите на дилърството са актуализирани.",
      dealer_not_present: "Този дилър не е регистриран в A2D.",
      dealer_uuid_other_nmsc: "Избраният A2D uuid код не е наличен за вашата мрежа.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Сигурни ли сте ,че искате да изтриете този файл?",
      addActionPlanNotAllowed: "Плана за действие не може да бъде създаден или коригиран след Затваряне, Приключване или отказ на Действие.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Сигурни ли сте,че искате да откажете тази Дейност.Не са възможни промени след отказ.",
      rejectMsg: "Сигурни ли сте,че искате да откажете тази Дейност.Не са възможни промени след отказ.",
      selectNMSCTab: "Моля изберете език за да публикувате съдържанието.",
      all: "Всички",
      deleteConfirm: "Сигурни ли сте, че искате да изтриете избрания КПИ?",
      no: "Не",
      retireConfirm: "Сигурни ли сте, че искате да оттеглите този КПИ? След като бъде оттеглен, този КПИ не може да се активира отново",
      warning: "Предупреждение",
      yes: "Да",
      saveDataConfirmation: "Сигурни ли сте, че искате да запазите данните?",
      saveKPIWarning:
        "Забележка: Данните не могат да се редактират след като бъдат запаметени. За да промените данните, се свържете с администратор на NMSC KET",
      addKpiNotAllowed:
        "Въвеждането на KPI  за следващ месец може да се направи само след изтичане на текущия месец.",
      activityLaunch:
        "Сигурни ли сте,че искате да стартирате това действие?Веднъж стартирано действие не може да бъде премахнато.",
      deleteConfirmSubQuestionNaire:
        "Сигурни ли сте,че искате да изтриете избраният подвъпрос.",
      retireConfirmSubQuestionNaire:
        "Сигурни ли сте,че искате да премахнете избраният подвъпрос.",
      deleteConfirmMainQuestion:
        "Моля да потвърдите,че искате да изтриете Главен въпрос и свързаните подвъпроси.",
      deleteConfirmChapter: "Сигурни ли сте,че искате да изтриете този раздел?",
      deleteLibraryFolder: "Сигурни ли сте,че искате да изтриете тази папка?",
      deletePublishedFolder: "Някой от файловете в тази папка може да са свързани с Въпросника. Сигурни ли сте, че искате да премахнете завинаги подпапката и да премахнате връзката с Въпросника?",
      importQuestion: "Сигурни ли сте,че искате да импортирате този подвъпрос.",
      noQuestion: "Няма налични въпроси",
      MandatoryWarning: "Моля попълнете всички задължителни полета за всички локални езици.",
      certificationSubmission:
        "Инструментът е изпратен на TME за сертифициране на дилъра.",
      sameToolBox: "Не можете да изпратите един и същ инструмент повече от веднъж. Моля, изберете различен инструмент.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Всички дейности на дилърството от \"Верига на стойността\" ще бъдат премахнати",
      updateBritConfirmDealer: "Всички дейности на дилърството от \"БРИТ\" ще бъдат премахнати",
      completed_activity_delete:"Данните не могат да бъдат възстановени след като бъдат изтрити. Сигурни ли сте, че искате да продължите?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "Всички дейности на дилърството от \"EA\" ще бъдат премахнати"
    },
    status: {
      published: "Публикувано",
      retired: "Оттеглено",
      saved: "Запазено",
      rejected: "Отказано",
      open: "Отворен",
      closed: "Затворени",
      close: "Затворени",
      ongoing: "Текущи",
      completed: "Завършени",
      complete: "Завършени",
      planned: "Планирани",
      overdue: "Преминало",
      due: "Очаквано",
      pending: "Pending",
    },
    rejected: "Отказано",
    Open: "Отворен",
    Closed: "Затворени",
    Close: "Затворени",
    Ongoing: "Текущи",
    Completed: "Завършени",
    Complete: "Завършени",
    Planned: "Планирани",
    tabDetails: {
      noActionAvailable: "Няма налични действия",
      activeDealer: "Понастоящем този дилър е регистриран като Активен в A2D и не може да бъде изтрит.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Действия",
      activityCompletionDate: "Дата на завършване на дейност",
      activityCompletionDateFrom: "Дата приключване действия от",
      activityCompletionDateTo: "Дата приключване действия до",
      activityStartDate: "Дата на започване на дейност",
      activityStartDateFrom: "Дата стартиране действия от",
      activityStartDateTo: "Дата стартиране действия до",
      assignedTo: "Възложено на",
      assignee: "Пълномощник",
      benchmark: "Стандарт",
      city: "Град",
      completedBy: "Завършено от ",
      completionBy: "Приключване от",
      completionDate: "Дата приключване",
      counterMeasure: "Противoмярка",
      country: "Държава",
      createdBy: "Създадено от",
      dealer: "Дилър",
      dealerNameAD: "Име на дилъра (A2D)",
      dealerName: "Дилър",
      definition: "Определение",
      description: "Описание",
      dueDate: "Дата за приключване",
      endDate: "Дата на приключване",
      firstVisit: "Първо посещение",
      followup: "Проследяване",
      franchise: "Франчайз",
      history: "История",
      id: "ИД",
      initial: "Начално",
      kpi: "Ключови Показатели на Изпълнение (КПИ)",
      launch: "Стартиране",
      mandatory: "Задължително",
      nmsc: "NMSC",
      problem: "Проблем",
      publishedOn: "Публикувано на",
      questionReference: "Препратка към въпрос",
      reference: "Препратка",
      reminderOn: "Включено напомняне",
      score: "Резултат",
      startDate: "Дата на започване",
      status: "Статус",
      target: "Target",
      toolbox: "Набор от инструменти",
      view: "Преглеждане",
      kpiResult: "KPI резултати",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Местоположение",
      ActionItemReport: "Теми за действие в прогрес",
      certification: "Статус Сертифициране",
      noOfToolbox: "Брой завършени Инструменти",
      program: "Програма",
      certificationDate: "Дата Сертифициране",
      activityId : 'Номер на Дейност',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "План за действие",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "План за действие",
      confirmDeleteMessage: "Сигурни ли сте, че искате да изтриете избрания КПИ?",
      confirmRetireMessage: "Моля, потвърдете, ако искате да оттеглите избраните КПИ, тъй като са били публикувани преди",
      errorRetireMsg: "Този КПИ не може да се оттегли, защото е активен TME със задължителен КПИ",
      import: "Импортиране",
      importKpiMessage: "Моля, импортирайте Вашите КПИ от главния списък с КПИ на TME",
      kpi: "Ключови Показатели на Изпълнение",
      library: "Библиотека",
      noActionAvailable: "Няма налични действия",
      NoKPI: "Няма налични КПИ",
      publish: "Публикуване",
      questionnaire: "Въпросник",
      tmeKPIList: "Главен списък с КПИ на TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Наименование инструмент?",
      newToolboxName: "Ново наименование",
      deleteToolbox: "Премахване инструмент?",
      programName: "Програмно име",
      ToolboxName_EN: "Инструмент наименование(EN)",
      deleteTool: "Сигурни ли сте,че искате да изтриете този инструмент?",
      selectToolbox: "Моля изберете инструмент,който да премахнете",
      editToolBox: "Редакция инструмент",
      addToolbox: "Добавяне инструмент",
      toolbox: "Моля въведете име на този инструмент",
      addActionPlan: "Добавяне План за действие",
      actionPlanItem: "План за действие – Елемент №",
      add: "Добавяне",
      addActivity: "Добавяне на дейност",
      additionalInformation: "Допълнителна информация",
      benchmark: "Стандарт",
      cancel: "Отмени",
      createEditActivity: "Създаване / редактиране на дейност",
      createKPI: "Създаване КПИ",
      delete: "Изтриване",
      download: "Изтегляне",
      edit: "Редактиране",
      editKPI: "редактиране на КПИ",
      keyPerformanceIndicator: "Ключов Показател на Изпълнение",
      kpiDefinition: "Определяне на КПИ",
      mandatoryKpi: "Задължителни КПИ",
      save: "Запазване",
      search: "Търсене",
      unit: "Подразделение",
      update: "Актуализация",
      validData: "Моля, въведете валидни данни",
      hide: "Скрий",
      show: "Покажи",
      upload_dealer_network: "Обновяване на дилърска мрежа",
      upload_question: "Зареждане на въпросник",
      upload_user: "Зареждане на данни за потребителя",
      upload_activity: "Зареждане на дейности",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Моля отговорете на всички въпроси,за да може да се приключи тази Дейност.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Моля въведете пъроначални стойности на ключовите показатели и поставените цели за да продължите",
      upload_error: "Грешка при зареждане на Дилърската мрежа",
      action_item_create_warning: "Допуска се максимум до пет записа да бъдат присъединени за едно действие",
      actionPlanStartDateValidation: "Датата на приключване не може да е преди датата на започване",
      actionsNotClosed: "Има действия от плана за действие, които се изпълняват; моля, затворете всички действия преди да затворите дейност",
      activityDuplicate: "Друга дейност за същия дилър и набор от инструменти вече съществува",
      assigneeNotExist: "Избраният пълномощник не е регистриран в системата",
      assigneeValidation: "Невалиден пълномощник",
      atleastOnePublish: "Моля, изберете поне един КПИ за публикуване",
      cannotRetireActive: "Това е активен TME със задължителен КПИ, който не може да се оттегли ",
      completionDate: "Датата на завършване не може да бъде преди датата на първото посещение",
      confirDeletePlannedActivity: "Сигурни ли сте, че искате да изтриете тази планирана дейност?",
      DateformatValidation: "Датата трябва да бъде във формат ДД / ММ / ГГГГ",
      DateValidation: "Началната дата трябва да е по-ранна от крайната дата",
      dealerValidation: "Невалиден дилър",
      deleteSelectedConfirm: "Сигурни ли сте, че искате да изтриете избрания КПИ?",
      duplicate: "Вече съществува друг КПИ със същото име и определение",
      modalMandatory: "Моля, въведете валидни данни във всички задължителни полета",
      noPublishKpi: "Все още не са публикувани КПИ за избрания набор от инструменти",
      validSearchCriteria: "Моля, въведете валидни критерии за търсене",
      multiSelectPlaceHolder: "Въведете минимум 5 символа",
      multiSelectPlaceHolderUser: "Въведете минимум 3 символа",
      startDateValidation: "Началната дата не може да бъде по-късна от крайната дата",
      fileSelectionErr: "Моля изберете файл",
      atleastOneQuestion: "Моля изберете поне един подвъпрос за импортиране",
      atleastOneSubQues: "Моля, изберете поне един подвъпрос за публикуване.",
      uploadErr: "Брой на файловете неуспели да се качат:",
      uploadSuccess: "Успешно зареждане на Дилърската мрежа.",
      file_upload_success: "Успешно зареждане на файла(овете).",
      KPIinOngoingClosedActivity: "Този КП присъства в една или повече текущи / затворени дейности. Моля, попълнете тези дейности, преди да направите каквато и да е промяна в КП.",
      Role_Error: "Потребителските настройки са непълни, моля, свържете се с администратора на NMSC KET.",
      points_allocation_incorrect: "Неправилно разпределение на точки, моля, променете.",
      activity_deleted:"Дейността е изтрита успешно.",
      activity_certified_dealer:"Дилърството е сертифицирано за избраната дейност и не може да бъде изтрита.",
      activity_submited_for_certificate_dealer:"Дейността е включена в Заявката за сертифициране към ТМЕ и състоянието не може да бъде променено."
    },
    TopNav: {
      ActivityLog: "Дейности",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Начало",
      logout: "Изход",
      MasterDataUpload: "Качване на основни данни",
      Toolbox: "Инструмент",
      welcome: "Добре дошли",
      Admin: "Администратор"
    },
    questionnaire: {
      addSubQuestion: "Добавяне подвъпрос",
      editSubQuestion: "Редакция подвъпрос",
      subQuestionTitle: "Подвъпрос",
      additionalInfo: "Допълнителна информация",
      na: "Добавете 'Неприложимо' като възможен отговор за този въпрос",
      question: "Въпрос",
      questionTitle: "Заглавие на въпрос",
      why: "Защо?",
      addMainQuestion: "Добавяне главен въпрос",
      editMainQuestion: "Редакция главен въпрос",
      previousQuestion: "Предишен въпрос",
      nextQuestion: "Следващ въпрос",
      what: "Какво?",
      library: "Библиотека",
      subQuestionTable: {
        no: "Не",
        subQuestion: "Подвъпрос",
        status: "Статус",
        publishedDate: "Дата публикуване",
        notApplicable: "Неналично"
      },
      links: {
        question_link_button: "Връзка",
        question_link_include_folder: "Включително поддиректории",
        question_link_add_link: "Свързани файлове от Библиотеката",
        question_link_view_folder: "Виж библиотеката"
      },
      mainQuestion: "Нови/Обновени въпроси",
      subQuestion: "Нови/Обновени подвъпроси",
      retiredQuestion: "Премахнати подвъпроси",
      libraries: "Нови/Обновени файлове в Библиотека",
      noFiles: "Няма налични файлове."
    },
    library: {
      selectFile: "Моля изберете поне един файл",
      fileSelected: "Избрани файлове",
      publish_library_file: "Моля изберете поне един файл за публикуване",
      library_files_deleted: "Наистина ли искате да изтриете този файл?",
      library_files_linked_deleted: "Сигурни ли сте ,че искате да изтриете този файл и връзките създадени във Въпросника?",
      max_size_error: "Размера на файла надхвърля ограничението от 1 GB за зареждане..",
      empty_file_desc: "Моля въведете описние на файла",
      file_not_selected: "Моля изберете файл(ове) за качване (макс.1GB)",
      file_type_error: "Невалиден формат на файл",
      file_name_error: "Невалиден символ в име на файл",
      file_name_lenght_error: "Име на файл може да бъде максимум 50 символа",
      addFolder: "Добавяне папка",
      editFolder: "Редакция папка",
      currentFolder: "Име на папка",
      parentFolder: "Име на Главна Папка",
      emptyFolderName: "Име на папка не може да бъде празно",
      invalidFolderName: "Името на папката не може да съдържа „/“.",
      folder_created: "Папката беше успешно създадена",
      folder_updated: "Папката беше успешно заредена",
      folder_not_found: "Не е открита папка",
      lib_invalid_toolbox: "Избран невалиден Инструмент",
      lib_folder_already_exists: "Папката вече съществува",
      lib_folder_deleted: "Папката беше успешно премахната",
      library_files_published: "Файлът беше публикуван",
      addFile: "Добави файл",
      editFile: "Редакция файл",
      fileName: "Име файл",
      upload: "Прикачете",
      desc: "Описание",
      folderCreationErr: "Hierarchy limited to two levels.",
      subFolderWarning: "Моля, изберете подпапка за добавяне на файл.",
      deleteWarning: "Моля, изберете папка за изтриване.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Разделът съществува",
      chapter_saved: "Раздел запазен",
      chapter_cannot_delete: "Разделът не може да бъде премахнат,поради вече публикуван подвъпрос.",
      failed: "Неуспешно!",
      chapter_deleted: "Премахване раздел",
      chapter_nonEmpty: "Моля създайте име на раздел.",
      addchapter: "Add chapter",
      editChapter: "Редактиране на глава",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Прикачени файлове",
      comments: "Коментари",
      evidence: "Доказателство",
      evidences: "Доказателство(а)",
      yes: "Да",
      no: "Не",
      na: "N/A",
      uploadEvidence: "Зареждане на доказателство",
      fileUpload: "Моля изберете файл(ове) за качване (макс.1GB)",
      who: "Кой?",
      answer: "Отговор",
      comment: "Коментари",
      date: "Дата",
      submit: "Изпращане",
      commentMsg: "Моля въведете коментарите.",
      noCommentAvailable: "Няма налични коментари.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Брой на файловете неуспели да се качат:",
      fileWithSameName: "Вече съществува друг файл с това име.",
      noEvidence: "Няма налични доказателства",
      createAction: "Създаване на действие",
      chapterName: "Име на раздел",
      questionNo: "Въпрос No.",
      when: "Кога?",
      noRecords: "Няма налични записи.",
      kaizenPoints: "Kaizen точки",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Въпрос",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Обходен преглед",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Бояджийно- тенекеджийно",
      TSM_USED_CAR: "Употребявани автомобили",
      value_chain: "Верига на добавената стойност (Value Chain)",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Табло забележки",
      kpi_target_not_set: "Целите на КП не са определени",
      kpi_initial_not_set: "Първоначалното измерване на КП не е зададено",
      kpi_monthly_not_set: "Месечното измерване на КП не е зададено",
      kpi_below_target: "Целите на КП са налични по-долу",
      activation: "Активация.",
      users: "Потребители",
      nmscs: "NMSC's",
      activeRetailer: "Активни Дилъри",
      certificationRate: "Степен на Сертифициране",
      reCertificationRate: "Степен на Ре-сертифициране",
      activityDue: "Предстоящи действия",
      avgdays: "Средно дни/Инструменти",
      overDueAct: "Изтекли Активности",
      actionItem: "Теми за действие",
      bussResult: "Бизнес резултати",
      toolboxImpl: "Имплементиране Инструменти",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Последни актуализации",
      rolloutTopNmsc: "Изпълнение резултати - най - добри NMSC",
      bestPractice: "Табло Добри практики",
      availableKpi: "Налични KPI",
      selectedKpi: "Избрани KPI",
      dealerBestPractice: "Табло за добри практики на дилъра",
      kpiList: "Списък Ключови показатели(KPI)",
      go: "Старт",
      nodata: "Няма данни",
      businessResultTop: "Бизнес резултати - най-добри дилъри",
      businessResultNMSC: "Бизнес резултати - най-добри NMSC"


    },
    dealerCertification: {
      dealerCertification: "Сертифициране дилър.",
      readyForCertification: "Готов за Сертифициране",
      certification: "Сертифициране",
      submittedOn: "Изпратено на",
      certificateDate: "Дата Сертифициране",
      reCertificateDate: "Дата Ре-Сертифициране",
      toolboxDetails: "Детайли Инструмент",
      approve: "Одобрение",
      reject: "Отхвърлете",
      pending: "В очакване",
      region: "Регион"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Идентификационен номер на изпълнение",
      uploadedOn: "Качено на",
      lastUpdatedOn: "Последна актуализация на",
      noDataTable: "No data to view",
      kpiUpload: "Качване на КП",
      action: "Действие",
      processed: "Обработено",
      error: "Грешка",
      received: "Received",
      createActivity: "Създаване на дейности",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Управление на потребителите",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Първо име",
      lastName: "Фамилия",
      emailId: "Електронна поща",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Акаунт",
      invalidPbiAccount : "PBI Акаунт трябва да съдържа мин 3 символа",
      businessArea: "Бизнес област",
      valueChainAndBRiT: "Верига на стойността и БРИТ",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Само \"Верига на стойността\"",
      britOnly: "Само \"БРИТ\"",
      valueChainAndEA: "Верига на стойността и EA",
      britAndEA: "БРИТ и EA",
      eaOnly: "Само EA",
      valueChainAndBRiTAndEA: "Верига на стойността и БРИТ и EA"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Първо име",
      lastName: "Фамилия",
      emailId: "Електронна поща",
      languageCode: "Езиков код",
      ACTIVE: "Active",
      INACTIVE: "Неактивен",
      addUser: "Добавяне на потребител",
      Assign: "Възлагане",
      List: "Списък",
      user: "Потребител",
      error_message: "Възникна грешка. Моля, опитайте отново по-късно.",
      role: "Потребителска роля",
      standardMessage: "No User Found",
      dealerCode: "Код на дилъра",
      a2d: "A2D UUID",
      britReady: "Търговецът участва в Brit Ready.",
      britLive: "Търговецът участва в Brit Live.",
      britJapan: "Търговецът участва в Brit Discovery Japan.",
      britEss:"Дилърството актуализира EES план на действие",
      addDealer: "Добавяне на дилър (от A2D)",
      noDealer: "Не са намерени дилъри.",
      dealerUuid: "Uuid код на дилъра (A2D)",
      language: "Език",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"


    },
    dealerManagement: {
      invalidUuid: "Моля, въведете валиден A2D uuid.",
      invalidDealerName: "Моля, въведете валидно име на дилъра.",
      invalidCity: "Моля, въведете валидно местоположение.",
      invaliDealerCode: "Моля, въведете валиден код на дилъра.",
      existingUuid: "A2D uuid кодът вече присъства в базата данни KET.",
      updateUuid: "Актуализирайте A2D uuid код",
      newUuid: "Нов A2D uuid код",
    },
    networkManagement: {
      networkManagement: "Управление на мрежата",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsBG;