import React from 'react';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { withTheme } from '../../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import '../index.css';
import UpdateUuid from "./updateUuid";
import Select from "react-select";
import Autosuggest from 'react-autosuggest';
import { toastr } from 'react-redux-toastr';
import * as AppConst from '../../../AppConstant';
import { fetchDealersNameList, fetchNetworkLevelDropdown, fetchA2DDealerDetails, refreshNetworkLevelData, saveDealer, deleteDealerActivities } from '../../../actions/dealerManagementAction'

var I18n = require('react-redux-i18n').I18n;
// const $ = require('jquery')
// window.jQuery = $;




class AddEditViewDealer extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {

            participatedInBrit: [{ "value": true, "label": "Y" }, { "value": false, "label": "N" }],
            modalAction: false,
            valueDealer: "",
            suggestionsDealer: [],
            dealerNameList: [],
            l3List: [],
            l4List: [],
            l5List: [],
            l6List: [],
            franchiseList: [],

            selectedFranchise: [],
            selectedL3: [],
            selectedL4: [],
            selectedL5: [],
            selectedL6: [],
            selectedBritReady: this.props.modalFor === 'Create' ? [{ "value": false, "label": "N", "selected": "selected" }] : [],
            selectedBritLive: this.props.modalFor === 'Create' ? [{ "value": false, "label": "N", "selected": "selected" }] : [],
            selectedBritJapan: this.props.modalFor === 'Create' ? [{ "value": false, "label": "N", "selected": "selected" }] : [],
            selectedEESAction: this.props.modalFor === 'Create' ? [{ "value": false, "label": "N", "selected": "selected" }] : [],
            newUuid: "",
            dealerDto: {
                networkLevelDealerId: 0,
                dealerName: "",
                id: 0,
                city: "",
                country: "",
                nmscCode: "",
                dealerCode: "",
                dealerUuid: "",
                franchise: null,
                l1: null,
                l2: null,
                l3: null,
                l4: null,
                l5: null,
                l6: null,
                flagBRiT: this.props.user.userMdl.flagBRiT,
                language: "",
                britReady: false,
                britLive: false,
                britJapan: false,
                status: "",
                businessArea:""
            },
            busiAreaValueChain: false,
            busiAreaBrit: false,
            checkFlag: true,
            busiAreaEa: false
        }
    }
    onUuidChange = (e) => {
        this.setState({ newUuid: e.target.value })
    }

    newUuidUpdate = (e) => {
        this.setState({ newUuid: "" })
    }

    onSaveDealer = () => {

        let dealerDto = { ...this.state.dealerDto };
        if (dealerDto.status === "Closed") {
            toastr.error((I18n.t("ServerMessage.dealer_closed_in_A2D")))
            return
        }
        if (this.props.modalFor === "Create" && dealerDto.id === 0) {
            toastr.error((I18n.t("ServerMessage.proper_dealer_selected")))
            return
        }
        if (this.isNotEmpty(dealerDto.dealerName) && this.isNotEmpty(dealerDto.dealerCode) && this.isNotEmpty(dealerDto.city)
            && this.isNotEmpty(dealerDto.dealerUuid) && this.isNotEmpty(dealerDto.country) && this.isNotEmpty(dealerDto.nmscCode) 
            && (this.state.busiAreaValueChain || this.state.busiAreaBrit || this.state.busiAreaEa)
        ) {
            let businessArea = "";
            if (this.state.busiAreaValueChain && !this.state.busiAreaBrit && !this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_VALUE_CHAIN;
            }
            if (this.state.busiAreaBrit && !this.state.busiAreaValueChain && !this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_BRIT;
            }
            if (this.state.busiAreaEa && !this.state.busiAreaBrit && !this.state.busiAreaValueChain) {
                businessArea = AppConst.BUSINESS_AREA_EA;
            }
            if (this.state.busiAreaValueChain && this.state.busiAreaBrit && this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_VALUE_CHAIN + "," + AppConst.BUSINESS_AREA_BRIT + "," + AppConst.BUSINESS_AREA_EA;
            }
            if (this.state.busiAreaValueChain && this.state.busiAreaBrit && !this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_VALUE_CHAIN + "," + AppConst.BUSINESS_AREA_BRIT;
            }
            if (this.state.busiAreaValueChain && !this.state.busiAreaBrit && this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_VALUE_CHAIN + "," + AppConst.BUSINESS_AREA_EA;
            }
            if (this.state.busiAreaBrit && !this.state.busiAreaValueChain && this.state.busiAreaEa) {
                businessArea = AppConst.BUSINESS_AREA_BRIT + "," + AppConst.BUSINESS_AREA_EA;
            }
            dealerDto['businessArea'] = businessArea
            if(this.props.modalFor === "Edit" && businessArea !== this.props.selectedRow.businessArea &&
                (!this.state.busiAreaValueChain || !this.state.busiAreaBrit || !this.state.busiAreaEa)){ 
                    let businessAreaKey = this.state.busiAreaEa ? AppConst.BUSINESS_AREA_EA : !this.state.busiAreaValueChain ? AppConst.BUSINESS_AREA_VALUE_CHAIN : AppConst.BUSINESS_AREA_BRIT;

                confirmAlert({
                    customUI: ({ title, message, onClose }) => {
                        return (
                            <div className={"ConfirmBox-" + this.props.theme.name}>
                                <div className="ConfirmBoxTitle">
                                    {I18n.t('standardMessages.warning')}
                                </div>
                                <div className="ConfirmBoxBody">
                                    {!this.state.busiAreaBrit ? 
                                        I18n.t('standardMessages.updateBritConfirmDealer'):
                                        I18n.t('standardMessages.updateVcConfirmDealer')
                                    }
                                </div>
                                <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
                                    <button
                                        className={"button-" + this.props.theme.name}
                                        onClick={() => {
                                            this.props.dispatch(deleteDealerActivities(dealerDto.networkLevelDealerId,businessAreaKey))
                                            if (this.props.isSearchClicked) {
                                                this.props.dispatch(saveDealer(dealerDto, this.props.searchFilterDto, this.props.isSearchClicked, this));
                                            }
                                            else {
                                                let searchDto = { ...this.props.searchDto }
                                                searchDto["nmscCode"] = this.props.user.userMdl.nmsc
                                                    this.props.dispatch(saveDealer(dealerDto, searchDto, this.props.isSearchClicked, this));
                                            }
                                            onClose();
                                        }}>
                                        {I18n.t("standardMessages.yes")}
                                    </button>
                                    <button className={"button-" + this.props.theme.name} onClick={() => {
                                        onClose()
                                        }}>
                                        {I18n.t("standardMessages.no")}
                                    </button>
                                </div>
                            </div>
                        );
                    }
                });
            }else{
                if (this.props.isSearchClicked) {
                    this.props.dispatch(saveDealer(dealerDto, this.props.searchFilterDto, this.props.isSearchClicked, this));
                }
                else {
                    let searchDto = { ...this.props.searchDto }
                    searchDto["nmscCode"] = this.props.user.userMdl.nmsc
                        this.props.dispatch(saveDealer(dealerDto, searchDto, this.props.isSearchClicked, this));
                }
            }
        } else {
            toastr.error(I18n.t("toastr.modalMandatory"));
        }
    }

    toggleUpdateUuid = () => {
        this.setState({
            modalAction: !this.state.modalAction,
        });
    }

    updateUuid = (newUuid, onClose) => {
        let obj = {
            dealerUuid: newUuid,
            nmscCode: this.props.user.userMdl.nmsc,
            languageCode: this.props.user.userMdl.lang,
            // country:this.props.user.userMdl.countryCode
        }
        this.props.dispatch(fetchA2DDealerDetails("dealerUuid", obj, this.toggleUpdateUuid, onClose, this.state.dealerDto.nmscCode))
    }

    componentWillMount() {
        if (this.props.modalFor === "Create") {
            this.props.dispatch(fetchDealersNameList(this.props.user.userMdl.nmsc, this.props.user.userMdl.lang))
        } else {
            let selectedBritReady = []
            let selectedBritJapan = []
            let selectedBritLive = [];
            let selectedEESAction = [];
            let dealerDto = { ...this.state.dealerDto }
            dealerDto["networkLevelDealerId"] = this.props.selectedRow.networkLevelDealerId
            dealerDto["dealerName"] = this.props.selectedRow.dealerName
            dealerDto["city"] = this.props.selectedRow.city
            dealerDto["dealerCode"] = this.props.selectedRow.dealerCode
            dealerDto["dealerUuid"] = this.props.selectedRow.dealerUuid
            dealerDto["nmscCode"] = this.props.selectedRow.nmscCode
            dealerDto["franchise"] = this.props.selectedRow.l1
            dealerDto["l1"] = this.props.selectedRow.l1
            let selectedFranchise = [{ "value": this.props.selectedRow.l1, "label": this.props.selectedRow.l1, "selected": "selected" }]
            dealerDto["country"] = this.props.selectedRow.l2
            dealerDto["l2"] = this.props.selectedRow.l2
            dealerDto["l3"] = this.props.selectedRow.l3 === "" ? null : this.props.selectedRow.l3
            let selectedL3 = this.props.selectedRow.l3 === "" ? [] : [this.getSelctedLevelDropdownObj(this.props.selectedRow.l3)]
            dealerDto["l4"] = this.props.selectedRow.l4 === "" ? null : this.props.selectedRow.l4
            let selectedL4 = this.props.selectedRow.l4 === "" ? [] : [this.getSelctedLevelDropdownObj(this.props.selectedRow.l4)]
            dealerDto["l5"] = this.props.selectedRow.l5 === "" ? null : this.props.selectedRow.l5
            let selectedL5 = this.props.selectedRow.l5 === "" ? [] : [this.getSelctedLevelDropdownObj(this.props.selectedRow.l5)]
            dealerDto["l6"] = this.props.selectedRow.l6 === "" ? null : this.props.selectedRow.l6
            let selectedL6 = this.props.selectedRow.l6 === "" ? [] : [this.getSelctedLevelDropdownObj(this.props.selectedRow.l6)]
            if (this.state.dealerDto.flagBRiT) {
                dealerDto["britReady"] = this.props.selectedRow.britReady
                selectedBritReady = [this.getSelctedBritDropdownObj(this.props.selectedRow.britReady)]
                dealerDto["britLive"] = this.props.selectedRow.britLive
                selectedBritLive = [this.getSelctedBritDropdownObj(this.props.selectedRow.britLive)]
                dealerDto["britJapan"] = this.props.selectedRow.britJapan
                selectedBritJapan = [this.getSelctedBritDropdownObj(this.props.selectedRow.britJapan)]
                dealerDto["eesAction"] = this.props.selectedRow.eesAction
                selectedEESAction = [this.getSelctedBritDropdownObj(this.props.selectedRow.eesAction)]

            }
            dealerDto["status"] = ""
            this.setState({
                dealerDto, selectedL3: selectedL3, selectedL4: selectedL4, selectedL5: selectedL5, selectedL6: selectedL6, selectedFranchise: selectedFranchise,
                selectedBritJapan: selectedBritJapan, selectedBritReady: selectedBritReady, selectedBritLive: selectedBritLive, selectedEESAction: selectedEESAction
            })
            if (this.props.modalFor === "Edit") {
                this.getNetworkLevels(dealerDto)
            }

        }

    }
    preventDefault() { }
    getSelctedBritDropdownObj = (value) => {
        let obj = {}
        if (value) {
            obj["value"] = value
            obj["label"] = "Y"
            obj["selected"] = "selected"
        } else {
            obj["value"] = value
            obj["label"] = "N"
            obj["selected"] = "selected"
        }

        return obj
    }



    getSelctedLevelDropdownObj = (value) => {
        let obj = {}
        obj["value"] = value
        obj["label"] = value
        obj["selected"] = "selected"
        return obj
    }

    componentDidUpdate(prevProps, prevState) {
        let dealerDto = { ...this.state.dealerDto }
        let selectedBritReady = []
        let selectedBritJapan = []
        let selectedBritLive = []
        let selectedEESAction = []
        if (this.props.modalFor !== "Create") {
            if (prevProps.addModalDropDown.dealerLang !== this.props.addModalDropDown.dealerLang && this.props.addModalDropDown.dealerLang !== undefined) {
                dealerDto["language"] = this.props.addModalDropDown.dealerLang !== null ? this.props.addModalDropDown.dealerLang.toUpperCase() : null
                this.setState({ dealerDto })
            }
        }
        if (prevProps.addModalDropDown.dealerNameList !== this.props.addModalDropDown.dealerNameList && this.props.addModalDropDown.dealerNameList !== undefined) {
            this.setState({
                dealerNameList: this.props.addModalDropDown.dealerNameList && this.props.addModalDropDown.dealerNameList
            })
        }
        if (prevProps.a2dDealerDetails.dealerDetails !== this.props.a2dDealerDetails.dealerDetails && this.props.a2dDealerDetails.dealerDetails !== undefined) {
            if (this.props.modalFor === "Create") {
                dealerDto["networkLevelDealerId"] = 0
            }
            dealerDto["city"] = this.props.a2dDealerDetails.dealerDetails.city
            dealerDto["dealerCode"] = this.props.a2dDealerDetails.dealerDetails.dealerCode
            dealerDto["dealerUuid"] = this.props.a2dDealerDetails.dealerDetails.dealerUuid
            dealerDto["franchise"] = this.props.a2dDealerDetails.dealerDetails.brandList[0].value
            dealerDto["l1"] = this.props.a2dDealerDetails.dealerDetails.brandList[0].value
            let brandList = this.props.a2dDealerDetails.dealerDetails.brandList
            let selectedFranchise = this.getSelectedDropDownList([brandList[0]])
            dealerDto["language"] = this.props.a2dDealerDetails.dealerDetails.languageCode !== null || this.props.a2dDealerDetails.dealerDetails.languageCode !== undefined ? this.props.a2dDealerDetails.dealerDetails.languageCode.toUpperCase() : null
            dealerDto["nmscCode"] = this.props.a2dDealerDetails.dealerDetails.nmscCode
            dealerDto["country"] = this.props.a2dDealerDetails.dealerDetails.country
            dealerDto["l2"] = this.props.a2dDealerDetails.dealerDetails.country
            if (this.state.dealerDto.flagBRiT) {
                selectedBritReady = [this.state.participatedInBrit[1]]
                selectedBritLive = [this.state.participatedInBrit[1]]
                selectedBritJapan = [this.state.participatedInBrit[1]]
                selectedEESAction = [this.state.participatedInBrit[1]]
                dealerDto["britJapan"] = false
                dealerDto["britLive"] = false
                dealerDto["britReady"] = false
                dealerDto["eesAction"] = false
            }
            if (this.props.modalFor === "Edit") {
                dealerDto["dealerName"] = this.props.a2dDealerDetails.dealerDetails.dealerName
                dealerDto["l3"] = ""
                dealerDto["l4"] = ""
                dealerDto["l5"] = ""
                dealerDto["l6"] = ""
            }
            dealerDto["status"] = this.props.a2dDealerDetails.dealerDetails.status
            this.setState({
                dealerDto, selectedFranchise: selectedFranchise, selectedBritReady, selectedBritLive, selectedBritJapan, selectedEESAction,
                franchiseList: brandList, selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: []
            })
            this.getNetworkLevels(dealerDto)
        }
        if (prevProps.addModalDropDown.l3List !== this.props.addModalDropDown.l3List && this.props.addModalDropDown.l3List !== undefined) {
            this.setState({ l3List: this.props.addModalDropDown.l3List })
        }
        if (prevProps.addModalDropDown.l4List !== this.props.addModalDropDown.l4List && this.props.addModalDropDown.l4List !== undefined) {
            this.setState({ l4List: this.props.addModalDropDown.l4List })
        }
        if (prevProps.addModalDropDown.l5List !== this.props.addModalDropDown.l5List && this.props.addModalDropDown.l5List !== undefined) {
            this.setState({ l5List: this.props.addModalDropDown.l5List })
        }
        if (prevProps.addModalDropDown.l6List !== this.props.addModalDropDown.l6List && this.props.addModalDropDown.l6List !== undefined) {
            this.setState({ l6List: this.props.addModalDropDown.l6List })
        }
        if (this.props.modalFor !== "Create" && this.state.checkFlag) {           
            if(this.props.selectedRow.businessArea!==undefined && this.props.selectedRow.businessArea!==""){
                let busiAreaArr = this.props.selectedRow.businessArea.split(',');
                if(busiAreaArr.length>0){
                    this.setState({
                        busiAreaValueChain: busiAreaArr.includes(AppConst.BUSINESS_AREA_VALUE_CHAIN) ? true : false,
                        busiAreaBrit: busiAreaArr.includes(AppConst.BUSINESS_AREA_BRIT) ? true : false,
                        busiAreaEa: busiAreaArr.includes(AppConst.BUSINESS_AREA_EA) ? true : false
                    });
                }
            }
            this.setState({
                checkFlag : false
            });
        }
    }


    getNetworkLevels = (dealerDto) => {
        this.props.dispatch(fetchNetworkLevelDropdown(dealerDto, "l3"))
        this.props.dispatch(fetchNetworkLevelDropdown(dealerDto, "l4"))
        this.props.dispatch(fetchNetworkLevelDropdown(dealerDto, "l5"))
        this.props.dispatch(fetchNetworkLevelDropdown(dealerDto, "l6"))
    }

    onFeildChange = (event, type) => {
        let dealerDto = { ...this.state.dealerDto }
        if (type === "dealerName") {
            dealerDto["dealerName"] = event.target.value
        }
        if (type === "dealerCode") {
            dealerDto["dealerCode"] = event.target.value
        }
        if (type === "city") {
            dealerDto["city"] = event.target.value
        }

        this.setState({ dealerDto })
    }

    validateFeild = (value) => {  
        if (undefined === value || null === value) {
            return true;
        }
        if (value.trim() === "") {
            return true;
        } 
        return false;
    }
    onBlur = (event, type) => {
        if (this.validateFeild(event.target.value)) {
            if (type === "dealerName") {
                toastr.error(I18n.t("dealerManagement.invalidDealerName"));
            }
            if (type === "city") {
                toastr.error(I18n.t("dealerManagement.invalidCity"));
            }
            if (type === "dealerCode") {
                toastr.error(I18n.t("dealerManagement.invaliDealerCode"));

            }
        }
    }

    isNotEmpty = (value) => { 
        if (undefined === value || null === value) {
            return false;
        }
        else if ((value + "").trim() === "") {
            return false;
        } 
        return true;
    }

    getSelectedDropDownList(selectedList) {
        let finalList = [];

        selectedList.map(objArr => {
            objArr["selected"] = "selected"
            finalList.push(objArr)
        })

        return finalList;
    }

    refreshStoreData = () => {
        this.props.dispatch(refreshNetworkLevelData())
    }


    onDealerChange = (event, { newValue, method }) => {
        var modifiedValue, modifiedId;
        if (method === 'click' || method === 'type' || method === 'escape' || method === 'enter') {
            if (newValue.indexOf("_") > -1) {
                modifiedValue = newValue.substr(0, newValue.indexOf("_"));
                modifiedId = Number(newValue.substr(newValue.indexOf("_") + 1));
            } else {
                modifiedValue = newValue;
                modifiedId = 0;
            }
            const dealerDto = { ...this.state.dealerDto }; 
            if (modifiedValue.trim().length > 0) {
                dealerDto["dealerName"] = modifiedValue;
                dealerDto["id"] = modifiedId
                if (modifiedId !== 0) {
                    this.setState({ dealerDto })
                    this.props.dispatch(fetchA2DDealerDetails("dealerName", dealerDto))

                } else {
                    this.setState({ dealerDto })
                    this.refreshFilters()
                }

            }
            else {
                dealerDto["dealerName"] = "";
                dealerDto["id"] = 0
                this.setState({ dealerDto })
                this.refreshFilters()
            } 
            this.setState({
                valueDealer: modifiedValue,
            });
        }
    };



    onFranchiseChange = (selectedOption) => {
        const dealerDto = { ...this.state.dealerDto };
        dealerDto["l1"] = selectedOption.value;
        dealerDto["franchise"] = selectedOption.value;
        dealerDto["l3"] = null
        dealerDto["l4"] = null
        dealerDto["l5"] = null
        dealerDto["l6"] = null
        this.setState({
            dealerDto, selectedFranchise: [selectedOption],
            selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [], valueDealer: ""
        })
        this.getNetworkLevels(dealerDto)
    }

    refreshFilters = () => {
        let dealerDto = { ...this.state.dealerDto }
        let selectedBritReady = { ...this.state.selectedBritReady }
        let selectedBritLive = { ...this.state.selectedBritLive }
        let selectedBritJapan = { ...this.state.selectedBritJapan }
        let selectedEESAction = { ...this.state.selectedEESAction }

        dealerDto["id"] = 0
        dealerDto["city"] = ""
        dealerDto["dealerCode"] = "";
        dealerDto["dealerUuid"] = "";
        dealerDto["nmscCode"] = ""
        dealerDto["language"] = ""
        dealerDto["country"] = ""
        dealerDto["l2"] = ""
        dealerDto["franchise"] = ""
        dealerDto["l1"] = ""
        dealerDto["l3"] = ""
        dealerDto["l4"] = ""
        dealerDto["l5"] = ""
        dealerDto["l6"] = ""
        dealerDto["britJapan"] = false
        dealerDto["britReady"] = false
        dealerDto["britReady"] = false
        selectedBritReady = [this.state.participatedInBrit[1]]
        selectedBritLive = [this.state.participatedInBrit[1]]
        selectedBritJapan = [this.state.participatedInBrit[1]]
        selectedEESAction = [this.state.participatedInBrit[1]]
        this.setState({
            dealerDto,
            selectedFranchise: [], selectedL3: [], selectedL4: [], selectedL5: [], selectedL6: [],
            selectedBritJapan, selectedEESAction, selectedBritLive, selectedBritReady,
            l3List: [], l4List: [], l5List: [], l6List: [], franchiseList: []
        })
    }




    onL3Change = (e) => {
        const dealerDto = { ...this.state.dealerDto };
        console.log("On L3 change",e.target.value);
        this.setState({ dealerDto, selectedL3: [e.target.value] })
        dealerDto["l3"] = e.target.value;
        // if (this.isNotEmpty(selectedOption)) {
        //     var valueOption = selectedOption.value;
        //     dealerDto["l3"] = valueOption
        //     this.setState({ dealerDto, selectedL3: [selectedOption] })
        // } else {
        //     dealerDto["l3"] = null;
        //     this.setState({ dealerDto, selectedL3: [] })
        // }
    }

    onL4Change = (e) => {
        const dealerDto = { ...this.state.dealerDto };
        this.setState({ dealerDto, selectedL4: [e.target.value] })
        dealerDto["l4"] = e.target.value;
        // if (this.isNotEmpty(selectedOption)) {
        //     var valueOption = selectedOption.value;
        //     dealerDto["l4"] = valueOption
        //     this.setState({ dealerDto, selectedL4: [selectedOption] })
        // } else {
        //     dealerDto["l4"] = null;
        //     this.setState({ dealerDto, selectedL4: [] })
        // }
    }

    onL5Change = (e) => {
        const dealerDto = { ...this.state.dealerDto };
        this.setState({ dealerDto, selectedL5: [e.target.value] })
        dealerDto["l5"] = e.target.value;
        // if (this.isNotEmpty(selectedOption)) {

        //     var valueOption = selectedOption.value;


        //     dealerDto["l5"] = valueOption
        //     this.setState({ dealerDto, selectedL5: [selectedOption] })
                 //dealer call
        // } else {
        //     dealerDto["l5"] = null;
        //     this.setState({ dealerDto, selectedL5: [] })

        // }
    }

    onL6Change = (e) => {

        const dealerDto = { ...this.state.dealerDto };
        this.setState({ dealerDto, selectedL6: [e.target.value] })
        dealerDto["l6"] = e.target.value;
        // if (this.isNotEmpty(selectedOption)) {
        //     var valueOption = selectedOption.value;
        //     dealerDto["l6"] = valueOption
        //     this.setState({ dealerDto, selectedL6: [selectedOption] })
        // } else {
        //     dealerDto["l6"] = null;
        //     this.setState({ dealerDto, selectedL6: [] })
        // }
    }

    onChangeBusiArea = (event, action) => {        
        if (action === 'busiAreaValueChain') {
            this.setState({ busiAreaValueChain: !this.state.busiAreaValueChain });
        }else if(action === 'busiAreaEa'){
            this.setState({ busiAreaEa: !this.state.busiAreaEa }); 
        } else {
            this.setState({ busiAreaBrit: !this.state.busiAreaBrit });            
        }

    };

    onBritChange = (selectedOption, type) => {
        const dealerDto = { ...this.state.dealerDto };
        if (type === "ready") {
            var valueOption = selectedOption.value;
            dealerDto["britReady"] = valueOption
            this.setState({ dealerDto, selectedBritReady: [selectedOption] })
        } else if (type === "live") {
            var valueOption = selectedOption.value;
            dealerDto["britLive"] = valueOption
            this.setState({ dealerDto, selectedBritLive: [selectedOption] })
        } else if (type === "EESAction") {
            var valueOption = selectedOption.value;
            dealerDto["eesAction"] = valueOption
            this.setState({ dealerDto, selectedEESAction: [selectedOption] })
        }
        else {
            var valueOption = selectedOption.value;
            dealerDto["britJapan"] = valueOption
            this.setState({ dealerDto, selectedBritJapan: [selectedOption] })
        }

    }

    getSuggestionDealer = value => { 
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 2 && this.state.dealerNameList !== undefined ? (this.state.dealerNameList.filter(dealer =>
            dealer.label.toLowerCase().indexOf(inputValue) !== -1)
        ) : [];
    };

    onSuggestionsDealerFetchRequested = ({ value }) => {
        this.setState({
            suggestionsDealer: this.getSuggestionDealer(value)
        });
    };
    onSuggestionsDealerClearRequested = () => {
        this.setState({
            suggestionsDealer: []
        });
    };
    getSuggestionDealerValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestionDealer = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onSuggestionSelected = (event, { suggestion, suggestionValue, method }) => {

        if (method === 'enter') {
            event.preventDefault();
            this.input.blur();
        }
    }


    render() {
        const { suggestionsDealer, valueDealer } = this.state;
        const inputDealerProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolderUser'),
            value: valueDealer,
            onChange: this.onDealerChange,
        };

        let isL1Disabled = this.props.modalFor !== "View" ? this.state.franchiseList.length > 1 ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true : true
        let isL3Disabled = this.props.modalFor !== "View" ? this.state.l3List ? this.state.l3List.length > 0 ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true : true : true
        let isL4Disabled = this.props.modalFor !== "View" ? this.state.l4List ? this.state.l4List.length > 0 ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true : true : true
        let isL5Disabled = this.props.modalFor !== "View" ? this.state.l5List ? this.state.l5List.length > 0 ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true : true : true
        let isL6Disabled = this.props.modalFor !== "View" ? this.state.l6List ? this.state.l6List.length > 0 ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true : true : true
        let isBritDisabled = this.props.modalFor !== "View" ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true
        return (
            <Container>
                <Modal className={"addEditViewDealerModel addEditViewDealerModel-" + this.props.theme.name}
                    isOpen={true}
                    toggle={() => this.preventDefault()}
                >
                    <ModalHeader toggle={() => {
                        this.refreshStoreData()
                        this.props.toggle()
                    }}>
                        {this.props.modalFor === "Create" ? I18n.t("tmeAdminMaster.add") : this.props.modalFor === "Edit" ? I18n.t("tmeAdminMaster.edit") : I18n.t("Table.view")}
                        {" " + I18n.t("Table.dealer")}
                    </ModalHeader>
                    <ModalBody className="addEditViewClass">
                        <form className="form-horizontal">
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{this.props.modalFor === "Create" ? I18n.t("Table.dealerNameAD") : I18n.t("Table.dealerName")} <span className="redFont">*</span></label>
                                <label className="col-md-6 control-label">{I18n.t("Table.city")} <span className="redFont">*</span></label>
                            </div>
                            <div className="col-md-12 input-group">
                                {this.props.modalFor !== "Create" ?
                                    <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                        <input
                                            type="text"
                                            className="form-control black-font"
                                            value={this.state.dealerDto.dealerName}
                                            disabled={this.props.modalFor === "View" ? true : false}
                                            onChange={(e) => this.onFeildChange(e, "dealerName")}
                                            onBlur={(e) => this.onBlur(e, "dealerName")}
                                        />
                                    </div> :
                                    <div className="col-md-6 addDealer" style={{ paddingLeft: "5px" }}>
                                        <Autosuggest
                                            ref={autosuggest => {
                                                if (autosuggest !== null) {
                                                    this.input = autosuggest.input
                                                }
                                            }}
                                            suggestions={suggestionsDealer}
                                            onSuggestionsFetchRequested={this.onSuggestionsDealerFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsDealerClearRequested}
                                            getSuggestionValue={this.getSuggestionDealerValue}
                                            renderSuggestion={this.renderSuggestionDealer}
                                            onSuggestionSelected={
                                                this.onSuggestionSelected
                                            }
                                            focusInputOnSuggestionClick={false}
                                            inputProps={inputDealerProps}
                                        />
                                    </div>}
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        value={this.state.dealerDto.city}
                                        disabled={this.props.modalFor !== "View" ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true}
                                        onChange={(e) => this.onFeildChange(e, "city")}
                                        onBlur={(e) => this.onBlur(e, "city")}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t("UserManagement.dealerUuid")}<span className="redFont">*</span>
                                    {this.props.modalFor === "Edit" ?
                                        <a
                                            onClick={() => this.toggleUpdateUuid()}

                                        >
                                            <div title={I18n.t("tmeAdminMaster.edit")} className="edit-icon editUuid" />
                                        </a> : ""}
                                </label>
                                <label className="col-md-6 control-label">{I18n.t("UserManagement.dealerCode")} <span className="redFont">*</span></label>
                            </div>
                            <div className="col-md-12 input-group">
                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        style={{ paddingLeft: "3px", paddingRight: "3px" }}
                                        value={this.state.dealerDto.dealerUuid}
                                        disabled="disabled"
                                    /> </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        value={this.state.dealerDto.dealerCode}
                                        disabled={this.props.modalFor !== "View" ? this.props.modalFor === "Create" && this.state.dealerDto.id === 0 ? true : false : true}
                                        onChange={(e) => this.onFeildChange(e, "dealerCode")}
                                        onBlur={(e) => this.onBlur(e, "dealerCode")}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.language')}{this.state.dealerDto.language !== undefined && this.state.dealerDto.language !== null && this.state.dealerDto.language !== "" ? <span className="redFont">*</span> : <span></span>}</label>
                                <label className="col-md-6 control-label">{I18n.t("Table.franchise")} <span className="redFont">*</span></label>
                            </div>
                            <div className="col-md-12 input-group">
                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        value={this.state.dealerDto.language !== undefined && this.state.dealerDto.language !== null ? this.state.dealerDto.language.toUpperCase() : ""}
                                        disabled="disabled"
                                    />  </div>
                                <div className="col-md-6">
                                    <Select
                                        className={isL1Disabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                        value={this.state.selectedFranchise}
                                        onChange={(e) => { this.onFranchiseChange(e) }}
                                        options={this.state.franchiseList}
                                        isDisabled={isL1Disabled}
                                        placeholder={""}
                                        isSearchable
                                    /> </div>
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t("Table.nmsc")} <span className="redFont">*</span></label>
                                <label className="col-md-6 control-label">{I18n.t("Table.country")} <span className="redFont">*</span></label>
                            </div>
                            <div className="col-md-12 input-group">
                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        value={this.state.dealerDto.nmscCode}
                                        disabled="disabled"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control black-font"
                                        value={this.state.dealerDto.country}
                                        disabled="disabled"
                                    /></div>
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.L3')}</label>
                                <label className="col-md-6 control-label">{I18n.t('UserManagement.L4')}</label>
                            </div>
                            <div className="col-md-12 input-group">
                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="text"
                                        // style={{ height: '0.5rem' }}
                                        // className={isL3Disabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                        className="form-control black-font"
                                        value={this.state.dealerDto.l3}
                                        onChange={(e) => { this.onL3Change(e) }}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                        // options={this.state.l3List}
                                        // isDisabled={isL3Disabled}
                                        // placeholder={isL3Disabled ? "" : I18n.t('standardMessages.Select')}
                                        // isSearchable
                                        // isClearable
                                        // maxMenuHeight={170}
                                    /> </div>
                                    
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        // style={{ height: '0.5rem' }}
                                        // className={isL4Disabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                        className="form-control black-font"
                                        value={this.state.dealerDto.l4}
                                        onChange={(e) => { this.onL4Change(e) }}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                        // options={this.state.l4List}
                                        // isDisabled={isL4Disabled}
                                        // placeholder={isL4Disabled ? "" : I18n.t('standardMessages.Select')}
                                        // isSearchable
                                        // isClearable
                                        // maxMenuHeight={170}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="col-md-12 row control-label">
                                <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.L5')}</label>
                                <label className="col-md-6 control-label">{I18n.t('UserManagement.L6')}</label>
                            </div>
                            <div className="col-md-12 input-group">
                                <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="text"
                                        // style={{ height: '0.5rem' }}
                                        // className={isL5Disabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                        className="form-control black-font"
                                        value={this.state.dealerDto.l5}
                                        onChange={(e) => { this.onL5Change(e) }}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                        // options={this.state.l5List}
                                        // isDisabled={isL5Disabled}
                                        // placeholder={isL5Disabled ? "" : I18n.t('standardMessages.Select')}
                                        // isSearchable
                                        // isClearable
                                        // maxMenuHeight={170}
                                    /> </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        // style={{ height: '0.5rem' }}
                                        // className={isL6Disabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                        className="form-control black-font"
                                        value={this.state.dealerDto.l6}
                                        onChange={(e) => { this.onL6Change(e) }}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                        // options={this.state.l6List}
                                        // isDisabled={isL6Disabled}
                                        // placeholder={isL6Disabled ? "" : I18n.t('standardMessages.Select')}
                                        // isSearchable
                                        // isClearable
                                        // maxMenuHeight={170}
                                    />
                                </div>
                            </div>
                            <br />
                            {this.props.user.userMdl.activeProgram === "BRiT" ?
                                <div>
                                    <div className="col-md-12 row control-label">
                                        <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.britReady')}</label>
                                        <label className="col-md-6 control-label">{I18n.t('UserManagement.britLive')}</label>
                                    </div>
                                    <div className="col-md-12 input-group">
                                        <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                            <Select
                                                // style={{ height: '0.5rem' }}
                                                className={isBritDisabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                                value={this.state.selectedBritReady}
                                                onChange={(e) => this.onBritChange(e, "ready")}
                                                options={this.state.participatedInBrit}
                                                isDisabled={isBritDisabled}
                                                // placeholder={I18n.t('standardMessages.all')}
                                                isSearchable
                                            /> </div>
                                        <div className="col-md-6">
                                            <Select
                                                // style={{ height: '0.5rem' }}
                                                className={isBritDisabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                                value={this.state.selectedBritLive}
                                                onChange={(e) => this.onBritChange(e, "live")}
                                                options={this.state.participatedInBrit}
                                                isDisabled={isBritDisabled}
                                                //placeholder={I18n.t('standardMessages.all')}
                                                isSearchable
                                            />
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col-md-12 row control-label">
                                        <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('UserManagement.britJapan')}</label>
                                        <label className="col-md-6 control-label">Retailer has updated EES Action Plan</label>
                                    </div>
                                    <div className="col-md-12 input-group">
                                        <div className="col-md-6" style={{ paddingLeft: "5px" }}>
                                            <Select
                                                style={{ height: '0.5rem' }}
                                                className={isBritDisabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                                value={this.state.selectedBritJapan}
                                                onChange={(e) => this.onBritChange(e, "japan")}
                                                options={this.state.participatedInBrit}
                                                isDisabled={isBritDisabled}
                                                //placeholder={I18n.t('standardMessages.Select')}
                                                isSearchable
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <Select
                                                style={{ height: '0.5rem' }}
                                                className={isBritDisabled ? "disabledField blackfont" : "activeField-" + this.props.theme.name}
                                                value={this.state.selectedEESAction}
                                                onChange={(e) => this.onBritChange(e, "EESAction")}
                                                options={this.state.participatedInBrit}
                                                isDisabled={isBritDisabled}
                                                //placeholder={I18n.t('standardMessages.Select')}
                                                isSearchable
                                            />
                                        </div>
                                    </div>
                                    <br />
                                </div>
                                : null}
                                <div className="col-md-12 input-group">
                                    <label className="col-md-12 control-label" style={{ paddingLeft: "5px" }}>
                                    <span className="redFont">*</span> {I18n.t("userManagement.optionText")}
                                    </label>
                                </div>
                                <div className="col-md-12 input-group">
                                <label className="col-md-4 control-label" style={{ paddingLeft: "5px" }}>
                                    <input
                                        type="checkbox"
                                        id="busiAreaValueChain"
                                        checked={this.state.busiAreaValueChain}
                                        onChange={e => this.onChangeBusiArea(e, "busiAreaValueChain")}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                    /> {I18n.t("ketProgram.value_chain")}
                                </label>
                                <label className="col-md-4 control-label">
                                    <input
                                        type="checkbox"
                                        id="busiAreaBrit"
                                        checked={this.state.busiAreaBrit}
                                        onChange={e => this.onChangeBusiArea(e, "busiAreaBrit")}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                    /> {I18n.t("ketProgram.brit")}
                                </label>
                                <label className="col-md-4 control-label">
                                    <input
                                        type="checkbox"
                                        id="busiAreaEa"
                                        checked={this.state.busiAreaEa}
                                        onChange={e => this.onChangeBusiArea(e, "busiAreaEa")}
                                        disabled={this.props.modalFor === "View" ? true : false}
                                    /> {I18n.t("ketProgram.ea")}
                                </label>
                            </div>
                            <br />                                
                        </form>
                    </ModalBody>

                    {this.props.modalFor !== "View" ?
                        <ModalFooter>
                            <button
                                className={"buttonCancel-" + this.props.theme.name}
                                onClick={() => {
                                    this.refreshStoreData()
                                    this.props.toggle()
                                }}
                            >
                                {I18n.t("tmeAdminMaster.cancel")}
                            </button>
                            {this.props.modalFor === "Create" ?

                                (<button
                                    className={"button-" + this.props.theme.name}
                                    onClick={this.onSaveDealer}
                                >
                                    {I18n.t("tmeAdminMaster.save")}
                                </button>)
                                :
                                (<button
                                    className={"button-" + this.props.theme.name}
                                    onClick={this.onSaveDealer}
                                >
                                    {I18n.t("tmeAdminMaster.update")}
                                </button>)
                            }
                        </ModalFooter>
                        : null}
                </Modal>
                {this.state.modalAction === true ?
                    <UpdateUuid
                        toggle={this.toggleUpdateUuid}
                        existingUuid={this.state.dealerDto.dealerUuid}
                        updateUuid={this.updateUuid}
                    />
                    : null
                }
            </Container>
        );
    }

}


const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        addModalDropDown: state.dealerManagementReducer.addModalDropDown,
        a2dDealerDetails: state.dealerManagementReducer.a2dDealerDetails,
        searchFilterDto: state.dealerManagementReducer.dealerSearchFilter
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddEditViewDealer)));