import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { withRouter } from 'react-router-dom';
import './index.css';
import ToolboxSideNav from './toolboxSideNav.js';
import Table from '../table';
import Questionnaire from '../questionnaire';
import NmscTable from '../nmscTable';
import CeaNetKpiTable from '../ceaNetKpiTable';
import { getQuestionnaire } from '../../actions/tabAction.jsx';
import { fetchNmscToolbox, fetchNmscToolboxByLang } from "../../actions/leftNavAction.jsx";
import { getQuestionSummary, getNmscQuestionSummary } from '../../actions/questionSummaryAction.jsx';
import { getLibraryCount } from '../../actions/libraryAction.jsx';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { withTheme } from '../../themes/theming';
import Library from '../library';
import { getUserDetails } from "../../actions/loginAction";
import * as AppConst from '../../AppConstant';
const $ = require('jquery')
var I18n = require('react-redux-i18n').I18n;

class Toolbox extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            show: false,
            toolBoxFlag: false,
            toolboxId: 0,
            user: 'nmsc',
            type: '',
            tmeFileList: [],
            isNmscAdminUser: false,
            packageMapPrimaryKey: 0,
            points:0,
            ceaPointsVisible:false,
            toolboxDetails:[]
        };
    }


    changeToolBoxFlag = (toolId, toolFlag, toolboxDetails) => {
        // console.log("check here",toolId, toolFlag, toolboxDetails)
        var loggedInUserNmsc = this.props.user.userMdl.nmsc;
        this.setState({ toolboxId: toolId, packageMapPrimaryKey: toolboxDetails.packageMapPrimaryKey });
        this.setState({ toolBoxFlag: toolFlag });
        this.setState({ toolboxKey: toolboxDetails.toolboxKey });
        this.setState({ type: toolboxDetails.type });
        this.setState({toolboxDetails: toolboxDetails})
        // this.props.dispatch(getToolBoxFileList(toolBoxName, loggedInUserNmsc, isNmscAdminUser));
        if (this.state.isNmscAdminUser) {
            // fetching NMSC Chapter list based on loggedin user nmsc and language as empty, because No tab is selected while mounting component.
            this.props.dispatch(getNmscQuestionSummary(toolboxDetails.packageMapPrimaryKey, loggedInUserNmsc, this.state.activeTabNmscLang, null, null, true));
            this.props.dispatch(fetchNmscToolbox(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.nmsc));
            this.props.dispatch(fetchNmscToolboxByLang(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.nmsc));
        } else if (this.state.isTMEAdminUser) {
            this.props.dispatch(getQuestionSummary(toolboxDetails.packageMapPrimaryKey, null, null, true));
            this.props.dispatch(getLibraryCount(toolboxDetails.packageMapPrimaryKey));
        }
       

    }
    selectedTab = (index, label) => {
        this.setState({ selectedTab: index });
        let langCode = 'TME';
        this.props.user.userMdl.userLangList.map((obj, index) => {
            if (obj.language.toUpperCase() === this.props.user.userMdl.lang.toUpperCase()) {
                langCode = obj.language;
            }
        });

        this.setState({ activeTabNmscLang: langCode });
    }
    componentWillMount() {


        if (
            this.props.user.userMdl === undefined ||
            this.props.user.userMdl === null ||
            this.props.user.userMdl.expired === undefined ||
            this.props.user.userMdl.expired
        ) {
            // get user Details from token.
            this.props.dispatch(getUserDetails());
        }

        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        // var isCeaUser = false;
        //   let ceaAdminRole = AppConst.CEA_ROLES.KET_CEA_Admin;
        // if (this.props.user.userMdl.businessAreaKey === 'CEA') {
        //     this.setState({ isCeaUser: true })
        // }
        // console.log("Toolbox Index - ", this.props)
        if (this.props && this.props.user && this.props.user.userMdl && this.props.user.userMdl.roles && this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles && roles[i] && (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1)) {
                    isNMSCAdminUser = true;
                    break;
                }
                else if (roles && roles[i] && (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN" || roles[i] ==="KET-TSP Auditor")) {
                    isTMEAdminUser = true;
                    break;
                }
                isNotAdmin = true;

            }
        }
        this.setState({ isNmscAdminUser: isNMSCAdminUser });
        this.setState({ isNotAdmin: isNotAdmin });
        this.setState({ isTMEAdminUser: isTMEAdminUser });
        if (isNMSCAdminUser || isNotAdmin) {
            let langCode = 'TME';
            this.props.user.userMdl.userLangList.map((obj, index) => {
                if (obj.language.toUpperCase() === this.props.user.userMdl.lang.toUpperCase()) {
                    langCode = obj.language;
                }
            });

            this.setState({ activeTabNmscLang: langCode });

        }


    }
    componentWillReceiveProps(nextProps) {
        this.setState({ fileName: nextProps.fileName });
        this.setState({ tmeFileList: nextProps.tmeFileList });

    }
    getQuestionnaire(filePath) {
        this.props.dispatch(getQuestionnaire(filePath))
    }

    handleTabStyle() {
        if (this.props.theme.name === "toyota") {
            $(".kpiTableContainer .nav-link").css('border', '1px solid transparent');
            $(".kpiTableContainer .nav-link.active").css('border-bottom-color', '#e50000')

        } else {
            $(".kpiTableContainer .nav-link").css('border', '1px solid transparent');
            $(".kpiTableContainer .nav-link.active").css('border-bottom-color', '#252525')
        }
    }

    handleLeafClick = node => {
        // console.log(node);
        this.props.dispatch(getQuestionnaire(node.id));
    }

    getQuestionnaire(filePath) {
        this.props.dispatch(getQuestionnaire(filePath))
    }

    // This function will be called when tab inside Questionnaire will be clicked
    activeNMSCTabLink = (activeTabNmscLang) => {
        this.setState({ activeTabNmscLang: activeTabNmscLang })
    }

    updateCeaNetKpiLabel=(businessFunctionPoints,totalKPiPoints,pointsVisble)=>{
        this.setState({businessFunctionPoints:businessFunctionPoints,totalKPiPoints:totalKPiPoints,ceaPointsVisible:pointsVisble})
    }


    render() {
        this.handleTabStyle()
        let isAdminUser = false;
        let isNotNmscUser = true;

        //added for cea req
        let isCeaUser = false;

        let isQuestionnaireAccess = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.accessControl;
            // console.log("Roles");
            // console.log(this.props.user.userMdl);
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].function === "MASTER_KPI") {
                    isAdminUser = true;
                    break;
                }
                else if (roles[i].function === "LOCAL_KPI") {
                    isNotNmscUser = false;
                    break;

                }
                // added for cea req
                else if (roles[i].function === "KPI Set-Up") {
                    isCeaUser = true;
                    break;
                }
            }
        }
        if (this.props.user.userMdl.roles !== undefined) {
            var access = this.props.user.userMdl.accessControl;
            for (var i = 0; i < access.length; i++) {
                if (access[i].function === "QUESTIONNAIRE_CHAPTER" || access[i].function === "LIBRARY") {
                    isQuestionnaireAccess = true;
                    break;
                }
            }
        }

        // console.log(isCeaUser)
        let cealabel = this.props.user.userMdl.activeProgram === AppConst.activeProgramCea && this.state.businessFunctionPoints !== undefined && this.state.totalKPiPoints !== undefined && this.state.ceaPointsVisible ?I18n.t('tabsDetails.kpi')+" ("+this.state.totalKPiPoints+"/"+this.state.businessFunctionPoints+")":I18n.t('tabsDetails.kpi')
        return (

            <Container fluid className="mt-0 ml-0 mb-1 pl-0">
                <Row className="mr-0">
                    <Col md="1" className="pl-1">
                        <ToolboxSideNav changeToolBoxFlag={this.changeToolBoxFlag} />
                    </Col>
                    {this.state.toolBoxFlag ?
                        <Col md="11" className="paddingTab kpiTableContainer">
                            {this.state.toolboxKey === 1561631791 || this.props.user.userMdl.activeProgram === "NAS" ?
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                    <Tab label={I18n.t('tabsDetails.kpi')}>
                                            <div md="12">
                                                {isAdminUser ?
                                                    <Table toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />
                                                     : isNotNmscUser ? null : <NmscTable toolboxId={this.state.toolboxId} type={this.state.type} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />}
                                            </div>
                                        </Tab>
                                        <Tab label={I18n.t('tabsDetails.questionnaire')}>
                                        <div className="downloadTreeContainer">

                                            <Questionnaire activeTabNmscLang={this.state.activeTabNmscLang} activeNMSCTabLink={this.activeNMSCTabLink} packageMapPrimaryKey={this.state.packageMapPrimaryKey} toolboxId={this.state.toolboxId} type={this.state.type} toolboxDetails={this.state.toolboxDetails}/>


                                        </div>

                                    </Tab>
                                    {/* <Tab label={I18n.t('tabsDetails.library')}>{I18n.t('others.inProgress')}</Tab> */}
                                    <Tab label={I18n.t('tabsDetails.library')}>
                                        <Library packageMapPrimaryKey={this.state.packageMapPrimaryKey} toolboxId={this.state.toolboxId} />
                                    </Tab>
                                </Tabs>
                                :
                                isQuestionnaireAccess && (this.props.user.userMdl.activeProgram === "TSM"
                                    || this.props.user.userMdl.activeProgram === "TSM_BNP"
                                    || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS") ?
                                    <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                        <Tab label={I18n.t('tabsDetails.kpi')}>
                                            <div md="12">
                                                {isAdminUser ?
                                                    <Table toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />
                                                    : isNotNmscUser ? null : <NmscTable toolboxId={this.state.toolboxId} type={this.state.type} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />}
                                            </div>
                                        </Tab>
                                        <Tab label={I18n.t('tabsDetails.questionnaire')}>
                                            <div className="downloadTreeContainer">
                                                <Questionnaire activeTabNmscLang={this.state.activeTabNmscLang} activeNMSCTabLink={this.activeNMSCTabLink} toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} type={this.state.type} />
                                            </div>
                                        </Tab>
                                        {/* new tab */}
                                        {/* <Tab label={I18n.t('tabsDetails.Questionnaire 2')}>
                                            <div className="downloadTreeContainer">
                                                <Questionnaire activeTabNmscLang={this.state.activeTabNmscLang} activeNMSCTabLink={this.activeNMSCTabLink} toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} type={this.state.type}  ques="q2"/>
                                            </div>
                                        </Tab> */}
                                        <Tab label={I18n.t('tabsDetails.library')}>
                                            <Library packageMapPrimaryKey={this.state.packageMapPrimaryKey} toolboxId={this.state.toolboxId} />
                                        </Tab>
                                    </Tabs>
                                    :

                                    !isCeaUser ?

                                        <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                            <Tab label={I18n.t('tabsDetails.kpi')}>
                                                <div md="12">
                                                    {isAdminUser ?
                                                        <Table toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />
                                                        : isNotNmscUser ? null : <NmscTable toolboxId={this.state.toolboxId} type={this.state.type} packageMapPrimaryKey={this.state.packageMapPrimaryKey} />}
                                                </div>
                                            </Tab>
                                        </Tabs>

                                        :
                                        //added for cea req

                                        <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                                            <Tab label={cealabel}>
                                                <div md="12">
                                                    <CeaNetKpiTable packageMapPrimaryKey={this.state.packageMapPrimaryKey} updateCeaNetKpiLabel={this.updateCeaNetKpiLabel}/>
                                                </div>
                                            </Tab>
                                            {/* <Tab label={I18n.t('CeaKpi.survey')}> */}
                                                {/* <div className="downloadTreeContainer"> */}
                                                {/* <Questionnaire activeTabNmscLang={this.state.activeTabNmscLang} activeNMSCTabLink={this.activeNMSCTabLink} toolboxId={this.state.toolboxId} packageMapPrimaryKey={this.state.packageMapPrimaryKey} type={this.state.type} /> */}
                                                {/* </div> */}
                                            {/* </Tab> */}
                                            {/* <Tab label={I18n.t('tabsDetails.library')}> */}
                                                {/* <Library packageMapPrimaryKey={this.state.packageMapPrimaryKey} toolboxId={this.state.toolboxId} /> */}
                                            {/* </Tab> */}
                                        </Tabs>

                            }

                        </Col> : null}
                </Row>
            </Container>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        fileName: state.tabReducer.fileName,
        tmeFileList: state.tabReducer.tmeFileList
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(Toolbox)));

