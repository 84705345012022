import React from 'react';
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'mdbreact';
import { withTheme } from '../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import './index.css';
import { Form } from 'react-bootstrap';
import { saveChapter, saveNmscChapter } from '../../actions/questionSummaryAction.jsx';
var I18n = require('react-redux-i18n').I18n;

class LeftPaneModel extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      chapterName: ""
    }


  }

  onHandleChange = (e) => {
    // const selectedRow = { ...this.state.selectedRow };
    //   selectedRow["description"] = e.target.value;
    //   this.setState({ selectedRow });
    this.setState({ chapterName: e.target.value });
  }
  onChange = (e) => {
    // const selectedRow = { ...this.state.selectedRow };
    //   selectedRow["reason"] = e.target.value;
    //   this.setState({ selectedRow });
    this.setState({ reason: e.target.value });
  }
  saveChapter = (e,chapterId) => {
    var chapterName = this.state.chapterName;
    e.preventDefault();
    if(chapterName===""){
      toastr.error(I18n.t("chapter.chapter_nonEmpty"));
      return;
    }
    if(this.props.isNMSCAdminUser){
      let chapterObj = {
        chapterId: chapterId,
	      toolboxId: this.props.toolBoxId,
	      chapterName: chapterName,
        nmscCode: this.props.user.userMdl.nmsc,
        //Nmsc lang of currrent selected tab
        nmscLang: this.props.activeTabNmscLang,	      
	      userId: this.props.user.userMdl.username
      };
      this.props.dispatch(saveNmscChapter(chapterObj));
    }else{
      this.props.dispatch(saveChapter(chapterId,chapterName, this.props.toolBoxId));
    }
    this.props.leftPaneAddToggle(this.props.modalFor);
  }

  componentWillMount() {
    if(this.props.modalFor === "editChapter"){
      this.setState({chapterName:this.props.selectedChapterRow.name})
    }
    
  }

  componentWillReceiveProps(nextProps) {
  }
  preventDefault() { }
  render() {
    return (
      <Container>
        <Modal
          className={"addEditModal-" + this.props.theme.name}
          // id="leftPaneModal"
          isOpen="true"
          leftPaneAddToggleToggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={() => this.props.leftPaneAddToggle(this.props.modalFor)}>
          {this.props.modalFor === "addChapter"? I18n.t("chapter.addchapter") : I18n.t("chapter.editChapter")}
            </ModalHeader>
          <ModalBody>
            <Form style={{ margin: "10px" }}>
              <label>
              {I18n.t("evaluation.chapterName")}
            </label>
              <input
                className="form-control"
                rows="1"
                maxLength="40"
                //  onChange={e => this.onHandleChange(e, "description")}
                onChange={this.onHandleChange}
                value={this.state.chapterName}
 />
              <br />
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => this.props.leftPaneAddToggle(this.props.modalFor)}
            >
              {I18n.t("tmeAdminMaster.cancel")}
            </button>
            {this.props.modalFor === "addChapter" ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={(e)=> this.saveChapter(e,0)}
              >
                {I18n.t("tmeAdminMaster.save")}
              </button>
            ) : (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={(e)=> this.saveChapter(e,this.props.selectedChapterRow.chapterId)}
                >
                  {I18n.t("tmeAdminMaster.update")}
                </button>
              )}
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(LeftPaneModel)));
