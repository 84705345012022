import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { getDealerSubQuestion } from "./evaluationAction.jsx";
import { toastr } from "react-redux-toastr";
import FileDownload from "js-file-download";
var I18n = require("react-redux-i18n").I18n;
/* eslint-disable */
export function saveFolderToDB(folderDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/library/folder/save", folderDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.data.statusCode === "200") {
          //toastr.success(I18n.t("library." + response.data.desc));
          dispatch({
            type: "FOLDER_SAVED",
            payload: response.data
          });
        } else {
          toastr.error(I18n.t("library." + response.data.desc));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchTmeFolders(toolBoxId, folderId, parentFolderId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/folder/" + toolBoxId, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        var nodeList = response.data;
        if (folderId && parentFolderId && folderId !== parentFolderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id === parentFolderId) {
              var children = nodeList[i].children;
              for (var j = 0; j < children.length; j++) {
                var child = children[j];
                if (child.id === folderId) {
                  child.active = true;
                }
                else {
                  child.active = false;
                }
              }
              node.toggled = true;
            }
            else {
              node.toggled = false;
            }
          }
        }
        else if (folderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id == folderId) {
              node.active = true;
            }
            else {
              node.active = false;
            }
          }
        }

        dispatch({
          type: "FOLDER_LIST",
          payload: nodeList
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteFolder(folderId, toolBoxId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/folder/" + toolBoxId + "/delete/" + folderId, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          //toastr.success(I18n.t("library." + response.data));
          dispatch({
            type: "FOLDER_SAVED",
            payload: response.data
          });
        } else {
          toastr.error(response.data);
        }
        dispatch(fetchTmeFolders(toolBoxId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchFileDtls(folderId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/" + folderId + "/fileDetails", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FILE_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function getDownloadPreSignedUrlForBritVideo(fileDetail) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/library/getDownloadPreSignedUrl", fileDetail, {
      headers: {

        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(true))
        //toastr.success(response.data.desc);
        dispatch(downloadLibraryFile(response));
      })
      .catch(err => {
        toastr.error(I18n.t('ServerMessage.error_message'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}


export function getDownloadPreSignedUrl(fileId, tmeType) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));

    axios.get("/library/" + fileId + "/" + tmeType + "/getDownloadPreSignedUrl", {
      headers: {

        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(true))
        //toastr.success(response.data.desc);
        dispatch(downloadLibraryFile(response));
      })
      .catch(err => {
        toastr.error(I18n.t('ServerMessage.error_message'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function downloadLibraryFile(obj) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    var preSignedURL = obj.data.desc;
    axios({
      method: 'get',
      contentType: "application/octet-stream",
      url: preSignedURL,
      responseType: 'arraybuffer',
      headers: { 'Pragma': 'no-cache' }
    }).then(function (response) {
      FileDownload(response.data, obj.data.body.replace(/%20/g, " "));
      //dispatch(setBlockingState(false));
    })
  };
}

export function downloadBritVideo(fileDetail) {
  return function (dispatch) {
    console.log("File Details---", fileDetail)
    //dispatch(setBlockingState(true));
    // var url = "/videos/PPSC_KT_Session2.mp4";
    // FileDownload(url,'PPSC_KT_Session2.mp4');
    FileDownload(fileDetail.filePath, fileDetail.fileName);


    // axios({
    //   method: 'get',
    //   contentType: "application/octet-stream",
    //   url: url,
    //   responseType: 'arraybuffer',
    //   headers: { 'Pragma': 'no-cache' }
    // }).then(function (response) {
    //   // obj.data.body.replace(/%20/g," ")
    //   FileDownload(response.data);
    //   //dispatch(setBlockingState(false));
    // })
  };
}




export function publishLibraryFiles(folderId, filesId) {
  if (filesId.length === 0) {
    toastr.error(I18n.t('library.selectFile'));
  } else {

    return function (dispatch) {
      //dispatch(setBlockingState(true));
      let url = "/library/publish/";
      axios
        .put(url, filesId, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          //toastr.success(I18n.t("library.files_are_published"));
          dispatch(fetchFileDtls(folderId));
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.log(error);
          toastr.error("" + error);
        });
    };
  }
}

export function fetchFileStatus(folderId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/" + folderId + "/fileStatus", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //toastr.success(I18n.t("ServerMessage." + response.data));
        dispatch({
          type: "FILE_STATUS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function deleteFile(row, folderId) {

  var fileId = row.fileId;
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/file/" + fileId + "/delete", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          //toastr.success(I18n.t("library." + response.data));

          // De-linking Files from questionnaire
          dispatch(deleteLibLinkRetire(fileId));
          dispatch(fetchFileDtls(folderId));
        } else {
          toastr.error(response.data);
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

// Three Steps of file upload
// 1. Insert into database (Check if duplicate and abort further steps)
// 2. Get Presigned URL
// 3. Upload file using presigned URL


// 1. -----------------------------------------------------
export function insertToLibraryFileTbl(s3Object, fileContentList) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/library/insertToLibraryFileTbl", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        // dispatch(setBlockingState(false))
        if (!response.status === 200) {
          toastr.error("File could not be saved");
          return;
        }

        if (response.data === "file_duplicate") {
          toastr.error(I18n.t('evaluation.fileWithSameName'));
          return;
        }
        dispatch(getUploadPreSignedUrl(s3Object, fileContentList))
        // dispatch(setBlockingState(false))

      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

// 2 -----------------------------------------------------
export function getUploadPreSignedUrl(s3Object, fileContentList) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/upload/getUploadPreSignedUrl/", s3Object, {
      headers: {

        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        //dispatch(setBlockingState(false))    
        //toastr.success(response.data);
        response.data.map((item, key) => {
          dispatch(uploadFileToS3(item, fileContentList[key], s3Object));
        })
      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

// 3. -----------------------------------------------------
export function uploadFileToS3(url, file, s3Object) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put(url, file, {
        headers: {
          "Content-type": "multipart/form-data",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          dispatch({
            type: "UPLOAD_FILES",
            payload: true
          });

          dispatch(fetchFileDtls(s3Object.id));

          // dispatch(insertToLibraryFileTbl(s3Object));

        } else {
          //dispatch(setBlockingState(false));
          toastr.error(I18n.t("Error while uploading File Details"));
        }
      })
      .catch(err => {
        toastr.error(I18n.t("Error while uploading File Details"));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function updateLibFile(fileObject) {

  return function (dispatch) {
    axios.post("/library/updateLibFile", fileObject, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Pragma: "no-cache"
      }
    })
      .then(response => {
        dispatch(fetchFileDtls(fileObject.id));
      })
      .catch(function (error) {
        setBlockingState(false);
        console.error(error);
      });
  }
}

export function saveQueLibraryLink(userId, questionLibLinkDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/QuestionLibLink/" + userId;
    axios
      .post(url, questionLibLinkDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(fetchQueLibraryLink(questionLibLinkDTO[0].questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchQueLibraryLink(questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/fetch/QuestionLibLink/" + questionId;
    axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "QUE_LIB_LINK",
          payload: response.data
        });
        const links = [...response.data];
        let fileIds = [];
        let folderIds = [];
        links.map((link) => {
          if (link.fileId !== 0) {
            fileIds.push(link.fileId);
          } else {
            folderIds.push(link.folderId);
          }
        });
        if (fileIds.length > 0) {
          dispatch(fetchQueLibraryFiles(fileIds));
        }
        if (folderIds.length > 0) {
          dispatch(fetchQueLibraryFolders(folderIds));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchQueLibraryFiles(files) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/fetch/QueLibFiles";
    axios
      .post(url, files, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "QUE_LIB_FILES",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchQueLibraryFolders(folders) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/fetch/QueLibFolders";
    axios
      .post(url, folders, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "QUE_LIB_FOLDERS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteLibLink(files, questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/deleteLibLink";
    axios
      .post(url, files, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(fetchQueLibraryLink(questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteLibLinkRetire(fileId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/deleteLibraryLinkRetire/" + fileId;
    axios
      .post(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getLibraryCount(toolBoxId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/getLibraryCount/" + toolBoxId, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "LIBRARY_LIST_COUNT",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchFileDtlsPublished(folderId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/" + folderId + "/fileList", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FILE_LIST_PUBLISHED",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getLibraryFolders(toolboxId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/Libraryfolders/" + toolboxId, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FOLDER_LIST_QUE",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function linkQuestionReference(questionNo, list) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/updateQuestionReference/" + questionNo;
    axios
      .post(url, list, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // dispatch(fetchQueLibraryLink(questionLibLinkDTO[0].questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function deleteQuestionReference(questionId, questionNo, links) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/deleteQuestionReference/" + questionNo + "/" + questionId;
    axios
      .post(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // dispatch(fetchQueLibraryLink(questionLibLinkDTO[0].questionId));
        dispatch(deleteLibLink(links, questionId));

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscFolders(toolBoxId, folderId, parentFolderId, nmsc, lang) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/nmscFolder/" + toolBoxId + "/" + nmsc + "/" + lang, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        var nodeList = response.data;
        if (folderId && parentFolderId && folderId != parentFolderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id == parentFolderId) {
              var children = nodeList[i].children;
              for (var j = 0; j < children.length; j++) {
                var child = children[j];
                if (child.id == folderId) {
                  child.active = true;
                }
                else {
                  child.active = false;
                }
              }
              node.toggled = true;
            }
            else {
              node.toggled = false;
            }
          }
        }
        else if (folderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id == folderId) {
              node.active = true;
            }
            else {
              node.active = false;
            }
          }
        }

        dispatch({
          type: "NMSC_FOLDER_LIST",
          payload: nodeList
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function fetchNmscFileDtls(folderId, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios

      .get("/library/" + folderId + "/" + nmsc + "/nmscFileDetails", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "NMSC_FILE_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function saveNmscFolderToDB(nmscFolderDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/library/nmscFolder/save", nmscFolderDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.data.statusCode === "200") {
          //toastr.success(I18n.t("library." + response.data.desc));
          dispatch({
            type: "NMSC_FOLDER_SAVED",
            payload: response.data
          });
          let values = [];
          if (response.data.desc !== undefined) {
            values = response.data.desc.split('|');
          }
          let id = values[0] !== undefined ? values[0] : 0;
          this.props.dispatch(fetchNmscFolders(nmscFolderDTO.toolboxId, id, nmscFolderDTO.parentFolderId, nmscFolderDTO.nmscCode, nmscFolderDTO.langCode));
        } else {
          toastr.error(I18n.t("library." + response.data.desc));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteNmscFolder(folderId, toolBoxId, nmscLang, nmscCode) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/nmscFolder/" + toolBoxId + "/delete/" + folderId, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          //toastr.success(I18n.t("library." + response.data));
          dispatch({
            type: "NMSC_FOLDER_SAVED",
            payload: response.data
          });
        } else {
          toastr.error(response.data);
        }
        dispatch(fetchNmscFolders(toolBoxId, null, null, nmscCode, nmscLang));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscQueLibraryLink(questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/fetch/nmscQuestionLibLink/" + questionId;
    axios
      .get(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "QUE_LIB_LINK",
          payload: response.data.questionLibLinkDTOList
        });
        dispatch({
          type: "QUE_LIB_FILES",
          payload: response.data.fileDetails
        });
        dispatch({
          type: "QUE_LIB_FOLDERS",
          payload: response.data.folderDetails
        });

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function fetchNmscFileStatus(folderId, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/" + folderId + "/" + nmsc + "/nmscFileStatus", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //toastr.success(I18n.t("ServerMessage." + response.data));
        dispatch({
          type: "NMSC_FILE_STATUS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function fetchEvalNmscFileDtlsPublished(folderId, versionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios

      .get("/library/" + folderId + "/" + versionId + "/nmscEvalFileList", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FILE_LIST_PUBLISHED",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchNmscFileDtlsPublished(folderId, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios

      .get("/library/" + folderId + "/" + nmsc + "/nmscFileList", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FILE_LIST_PUBLISHED",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscPublishedFolders(toolBoxId, folderId, parentFolderId, nmsc, lang) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/nmscPublishedFolder/" + toolBoxId + "/" + nmsc + "/" + lang, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        var nodeList = response.data;
        if (folderId && parentFolderId && folderId != parentFolderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id == parentFolderId) {
              var children = nodeList[i].children;
              for (var j = 0; j < children.length; j++) {
                var child = children[j];
                if (child.id == folderId) {
                  child.active = true;
                }
                else {
                  child.active = false;
                }
              }
              node.toggled = true;
            }
            else {
              node.toggled = false;
            }
          }
        }
        else if (folderId) {
          for (var i = 0; i < nodeList.length; i++) {
            var node = nodeList[i];
            if (node.id == folderId) {
              node.active = true;
            }
            else {
              node.active = false;
            }
          }
        }

        dispatch({
          type: "NMSC_FOLDER_LIST",
          payload: nodeList
        });

        dispatch({
          type: "NMSC_FOLDER_LIST_QUE",
          payload: nodeList
        });

        dispatch({
          type: "NMSC_LIBRARY_LIST_COUNT",
          payload: nodeList.length
        });

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscPublishedFileDtls(folderId, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios

      .get("/library/" + folderId + "/" + nmsc + "/nmscPublishedFileDetails", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "NMSC_FILE_LIST",
          payload: response.data
        });

        let finalList = [];
        const list = [...response.data];
        list.map((data) => {
          if (data.fileType !== 'DIR') {
            finalList.push(data);
          }
        });
        dispatch({
          type: "FILE_LIST_PUBLISHED",
          payload: finalList
        });

        /*   dispatch({
            type: "FILE_LIST_PUBLISHED",
            payload: response.data
          }); */


      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function publishNmscLibraryFiles(folderId, filesId, nmsc) {
  if (filesId.length === 0) {
    toastr.error(I18n.t('library.selectFile'));
  } else {

    return function (dispatch) {
      //dispatch(setBlockingState(true));
      let url = "/library/nmscPublish/";
      axios
        .put(url, filesId, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
          //dispatch(setBlockingState(false));
          //toastr.success(I18n.t("library.files_are_published"));
          dispatch(fetchNmscFileDtls(folderId, nmsc));
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.log(error);
          toastr.error("" + error);
        });
    };
  }
}

export function saveNmscQueLibraryLink(questionLibLinkDTO, nmsc, lang) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/nmscQuestionLibLink/" + nmsc + "/" + lang;
    axios
      .post(url, questionLibLinkDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(fetchNmscQueLibraryLink(questionLibLinkDTO[0].questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteNmscQuestionLibraryLink(questionId, links) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/library/deleteNmscQuestLibLink/" + questionId;
    axios
      .post(url, links, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(fetchNmscQueLibraryLink(questionId));
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

export function updateNmscLibFile(fileObject) {

  return function (dispatch) {
    axios.post("/library/updateNmscLibFile", fileObject, {
      headers: {
        "Content-type": "application/json",
        Accept: "application/json",
        Pragma: "no-cache"
      }
    })
      .then(response => {
        dispatch(fetchNmscFileDtls(fileObject.nmscFolderId, fileObject.nmscCode));
      })
      .catch(function (error) {
        setBlockingState(false);
        console.error(error);
      });
  }
}

export function insertToNmscLibraryFileTbl(s3Object, file) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/library/insertToNmscLibraryFileTbl", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false))
        if (!response.status === 200) {
          toastr.error("File could not be saved");
          return;
        }

        if (response.data === "file_duplicate") {
          toastr.error(I18n.t('evaluation.fileWithSameName'));
          return;
        }
        dispatch(getNmscUploadPreSignedUrl(s3Object, file))
        dispatch(setBlockingState(false))

      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function getNmscUploadPreSignedUrl(s3Object, fileContentList) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/upload/getNmscUploadPreSignedUrl/", s3Object, {
      headers: {

        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        //dispatch(setBlockingState(false))    
        //toastr.success(response.data);
        response.data.map((item, key) => {
          dispatch(uploadNmscFileToS3(item, fileContentList[key], s3Object));
        })
      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function deleteNmscFile(row, folderId, nmsc) {

  var nmscFileId = row.fileId;
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/file/" + nmscFileId + "/nmscDelete", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          //toastr.success(I18n.t("library." + response.data));

          // De-linking Files from questionnaire
          dispatch(deleteLibLinkRetire(nmscFileId));
          dispatch(fetchNmscFileDtls(folderId, nmsc));
        } else {
          toastr.error(response.data);
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function uploadNmscFileToS3(url, file, s3Object) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put(url, file, {
        headers: {
          "Content-type": "multipart/form-data",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          dispatch({
            type: "UPLOAD_FILES",
            payload: true
          });
          dispatch(fetchNmscFileDtls(s3Object.nmscFolderId, s3Object.nmscCode));
        } else {
          //dispatch(setBlockingState(false));
          toastr.error(I18n.t("Error while uploading File Details"));
        }
      })
      .catch(err => {
        toastr.error(I18n.t("Error while uploading File Details"));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function fetchFileTMEPublished(folderId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/library/" + folderId + "/tmefileList", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FILE_LIST_PUBLISHED",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function saveEvidences(s3Object, file, activityId, questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios.post("/evaluation/saveEvidences", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false))

        if (response.data === "file_duplicate") {
          toastr.error(I18n.t("evaluation.fileWithSameName"));
          return;
        }

        dispatch({
          type: "TOTAL_FILE_COUNT",
          payload: response.data.length
        });

        response.data.map((item, key) => {

          dispatch(uploadEvidencesToS3(item.preSignedUrl, file[key], s3Object, item, activityId, questionId));

          //TODO: Add validation for type of images....
          // dispatch(generateThumbnail(file[key], s3Object, activityId));

        })


      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        console.error("in saveEvidences catch");
        //dispatch(setBlockingState(false));
      });
  };
}

// export function generateThumbnail(file, s3Object, activityId) {

//   var fileName = file.name;
//   const fileExtension = fileName.split('.').pop();

//   var fileDTO = {
//     fileType: fileExtension,
//     fileName: fileName
//   };
//   const postData = new FormData();
//   postData.append(
//     "fileDTO",
//     new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
//   );
//   postData.append("file", file);

//   return function (dispatch) {
//     axios.post("/evaluation/generateThumbnailEvidences/" + s3Object.evaluationId + "/" + activityId, postData, {
//       headers:
//       {
//         'Content-type': 'multipart/form-data',
//         'Accept': 'application/json',
//         'Pragma': 'no-cache'
//       }
//     })
//       .then((response) => {

//         dispatch(setBlockingState(false))
//         if (response.data === "file_duplicate") {
//           toastr.error(I18n.t("evaluation.fileWithSameName"));
//           return;
//         }

//         dispatch({
//           type: "TOTAL_FILE_COUNT",
//           payload: response.data.length
//         });
//         console.log(response);
//       })
//       .catch(err => {
//         toastr.error(I18n.t('toastr.uploadErr'));
//         console.error(err);
//         console.error("in saveEvidences catch");
//         //dispatch(setBlockingState(false));
//       });
//   };
// }

// export function generateThumbnailAttachements(file, activityId) {

//   var fileName = file.name;
//   const fileExtension = fileName.split('.').pop();

//   var fileDTO = {
//     fileType: fileExtension,
//     fileName: fileName
//   };
//   const postData = new FormData();
//   postData.append(
//     "fileDTO",
//     new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
//   );
//   postData.append("file", file);

//   return function (dispatch) {
//     axios.post("/evaluation/generateThumbnailAttachements/" + activityId , postData, {
//       headers:
//       {
//         'Content-type': 'multipart/form-data',
//         'Accept': 'application/json',
//         'Pragma': 'no-cache'
//       }
//     })
//       .then((response) => {

//         dispatch(setBlockingState(false))
//         if (response.data === "file_duplicate") {
//           toastr.error(I18n.t("evaluation.fileWithSameName"));
//           return;
//         }

//         dispatch({
//           type: "TOTAL_FILE_COUNT",
//           payload: response.data.length
//         });
//         console.log(response);
//       })
//       .catch(err => {
//         toastr.error(I18n.t('toastr.uploadErr'));
//         console.error(err);
//         console.error("in saveEvidences catch");
//         //dispatch(setBlockingState(false));
//       });
//   };
// }

export function swapEvidences(s3Object, evaluationId, activityId, questionId, flag) {
  return function (dispatch) {
    axios.post("/evaluation/swapEvidences", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false))
        console.log(response)
        if (response.data === "OK") {
          toastr.success(I18n.t("evaluation.moveMsg"));
          if(flag){
            dispatch(fetchEvidenceFiles(evaluationId));
            dispatch(getDealerSubQuestion(questionId, activityId))
          }else{
            dispatch(fetchQuestionEvidenceFiles(questionId, activityId));
          }
          
          return;
        }
      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        console.error("in swapEvidences catch");
        //dispatch(setBlockingState(false));
      });
  };
}
export function swapAttachments(s3Object, actionPlanId, activityId, questionReference, flag) {
  return function (dispatch) {
    axios.post("/evaluation/swapAttachments", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false))
        console.log(response)
        if (response.data==="OK") {
          toastr.success(I18n.t("evaluation.moveMsg"));
          if(flag){
            dispatch(fetchAttachmentFiles(actionPlanId,activityId,questionReference));
          }else{
            dispatch(fetchAttachFilesActionPlan(activityId,questionReference));
          }          
          return;
        }
      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        console.error("in swapAttachments catch");
        //dispatch(setBlockingState(false));
      });
  };
}
export function uploadEvidencesToS3(url, file, s3Object, item, activityId, questionId) {

  return function (dispatch) {

    console.log(url);
    console.log(file);

    //dispatch(setBlockingState(true));
    axios
      .put(url, file, {
        headers: {
          "Content-type": "multipart/form-data",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

        //dispatch(setBlockingState(false));
        if (response.status === 200) {
          dispatch({
            type: "UPLOAD_FILES",
            payload: true
          });

          dispatch({
            type: "SUCCESS_FILE_COUNT",
            payload: true
          });

          dispatch(fetchEvidenceFiles(s3Object.evaluationId));
          dispatch(getDealerSubQuestion(questionId, activityId))
        } else {
          dispatch(deleteEvidences(item));
          dispatch({
            type: "FAILURE_FILE_COUNT",
            payload: true
          });
        }
      })
      .catch(err => {
        dispatch(deleteEvidences(item));

        dispatch({
          type: "FAILURE_FILE_COUNT",
          payload: true
        });
        //dispatch(setBlockingState(false));
      });
  };
}

export function fetchEvidenceFiles(evaluationId) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get("/evaluation/" + evaluationId + "/evidenceFiles", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
         dispatch(setBlockingState(false));
        dispatch({
          type: "EVIDENCE_FILE",
          payload: response.data
        });
        //Evidence file thumbnail code end
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


function arrayBufferToBase64(buffer) {
  var binary = '';
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getDownloadEvidencePreSignedUrl(fileId, tableRef) {
  return function (dispatch) {
    axios.get("/evaluation/" + fileId + "/" + tableRef + "/getDownloadEvidencePreSignedUrl", {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(downloadLibraryFile(response));
      })
      .catch(err => {
        toastr.error(I18n.t('ServerMessage.error_message'));
        console.error(err);
        //dispatch(setBlockingState(false));
      });
  };
}

export function deleteEvidences(dto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/evaluation/evidence";
    axios
      .delete(url, dto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function saveAttachments(s3Object, file, activityId) {
  return function (dispatch) {
    axios.post("/evaluation/saveAttachments", s3Object, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false))

        if (response.data === "file_duplicate") {
          toastr.error(I18n.t("evaluation.fileWithSameName"));
          return;
        }

        dispatch({
          type: "TOTAL_FILE_COUNT",
          payload: response.data.length
        });


        response.data.map((item, key) => {
          dispatch(uploadAttachmentsToS3(item.preSignedUrl, file[key], s3Object, item));
          //TODO: Add validation for type of images....
          // dispatch(generateThumbnailAttachements(file[key], activityId));

        })

      })
      .catch(err => {
        toastr.error(I18n.t('toastr.uploadErr'));
        console.error(err);
        console.error("in saveAttachments catch");
      });
  };
}

export function uploadAttachmentsToS3(url, file, s3Object, item) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .put(url, file, {
        headers: {
          "Content-type": "multipart/form-data",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if (response.status === 200) {
          dispatch({
            type: "UPLOAD_FILES",
            payload: true
          });

          dispatch({
            type: "SUCCESS_FILE_COUNT",
            payload: true
          });

          dispatch(fetchAttachmentFiles(s3Object.actionPlanId, s3Object.activityId, s3Object.questionReference));
        } else {
          dispatch(deleteAttachments(item));
          dispatch({
            type: "FAILURE_FILE_COUNT",
            payload: true
          });
        }
      })
      .catch(err => {
        dispatch(deleteAttachments(item));
        console.error(err);
        dispatch({
          type: "FAILURE_FILE_COUNT",
          payload: true
        });
      });
  };
}

export function fetchAttachmentFiles(actionPlanId, activityId, questionReference) {
  return function (dispatch) {
    if (questionReference === '' || questionReference === ' ' || questionReference === undefined) {
      questionReference = '0.0.0'
    }
    dispatch(setBlockingState(true));
    axios

      .get("/evaluation/" + actionPlanId + "/" + activityId + "/" + encodeURIComponent(questionReference) + "/attachmentFiles", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //Attachment file thumbnail code end
        dispatch({
          type: "ATTACHMENT_FILE",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteAttachments(dto) {
  return function (dispatch) {
    let url = "/evaluation/attachment";
    axios
      .delete(url, dto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function fetchQuestionEvidenceFiles(subQuestionIds, activityId) {
  return function (dispatch) {
    axios
      .post("/evaluation/" + activityId + "/questionEvidenceFiles", subQuestionIds, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
         dispatch(setBlockingState(false));
         dispatch({
           type: "EVIDENCE_FILE",
           payload: response.data
         });
         //Evidence file thumbnail code end
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

export function fetchAttachFilesActionPlan(activityId, questionReference) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get("/evaluation/" + activityId + "/" + encodeURIComponent(questionReference) + "/actionPlanAttachFiles", {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        dispatch({
          type: "ATTACHMENT_FILE",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteEvidence(evidenceDTO,questionId) {
  console.log("deleteEvidence", evidenceDTO)
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/evaluation/deleteEvidence/" + evidenceDTO.fileId + "/" + evidenceDTO.tableRef;
    axios
      .delete(url, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if (response.status === 200) {
          toastr.success(I18n.t("evaluation.deleteSuccessMsg"));
          if (evidenceDTO.tableRef === "SubQuestion") {
            dispatch(fetchEvidenceFiles(evidenceDTO.evaluationId));
          } else {
            dispatch(fetchAttachmentFiles(evidenceDTO.actionPlanId, evidenceDTO.activityId, evidenceDTO.questionReference));
          }       
          if(questionId){
            dispatch(getDealerSubQuestion(questionId, evidenceDTO.activityId))
          }   
        } else {
          toastr.error(response.data);
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
        console.error("Deleting file is falied", error);
      });
  };
}

export function renameEvidence(evidenceDTO,subQuestionIds) {
  return function (dispatch) {
    axios.post("/evaluation/renameEvidence", evidenceDTO, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    }).then((response) => {
      dispatch(setBlockingState(false))

      if (response.data === "file_duplicate") {
        toastr.error(I18n.t("evaluation.fileWithSameName"));
        return;
      }
      if (response.status === 200) {
        toastr.success(I18n.t("evaluation.renameSuccessMsg"));        
        if(subQuestionIds.length > 0 && subQuestionIds[0] !== ''){
          dispatch(fetchQuestionEvidenceFiles(subQuestionIds,evidenceDTO.activityId));
        }else{
          dispatch(fetchEvidenceFiles(evidenceDTO.evaluationId));
        }
      } else {
        toastr.error(response.data);
      }
    })
      .catch(err => {
        toastr.error("" + err);
        console.error("Renaming file is falied", err);
      });
  };
}

export function renameAttachment(evidenceDTO,flag) {
  return function (dispatch) {
    axios.post("/evaluation/renameAttachment", evidenceDTO, {
      headers: {
        'Accept': 'application/json',
        'Pragma': 'no-cache'
      }
    }).then((response) => {
      dispatch(setBlockingState(false))

      if (response.data === "file_duplicate") {
        toastr.error(I18n.t("evaluation.fileWithSameName"));
        return;
      }
      if (response.status === 200) {
        toastr.success(I18n.t("evaluation.renameSuccessMsg"));
        if(flag){
          dispatch(fetchAttachFilesActionPlan(evidenceDTO.activityId, evidenceDTO.questionReference))
        }else{
          dispatch(fetchAttachmentFiles(evidenceDTO.actionPlanId, evidenceDTO.activityId, evidenceDTO.questionReference));
        }        
      } else {
        toastr.error(response.data);
      }
    })
      .catch(err => {
        toastr.error("" + err);
        console.error("Renaming file is falied", err);
      });
  };
}

export function emptyEvidanceData() {
  return function (dispatch) {
    dispatch({
      type: "EVIDENCE_FILE",
      payload: null
    });
    dispatch({
      type: "ATTACHMENT_FILE",
      payload: null
    });
  };
}

export function deleteAllLibraries(programId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete(
        "/library/" + programId + "/" + packageProgramMappingId + "/deleteAllLibrary",
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        if (response.data === "DELETE_SUCC") {
          toastr.success(I18n.t("chapter.DELETE_SUCC_LIB"));
          dispatch(fetchTmeFolders(packageProgramMappingId));
          dispatch(fetchFileDtls(0));
        } else {
          toastr.error(I18n.t("chapter.DELETE_ERR"));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("Chapter.failed"));
      });
  };
}