// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.block-ui-container{
    z-index: 9999 !important;
}

.backColor{
    line-height: 2rem;
    height: 3.375rem !important;
}

#react-confirm-alert{
    position: relative;
    z-index: 9999;
}

@media screen and (max-width: 1150px) {  
    html, body {
        width: 1366px !important;
        overscroll-behavior-y: contain !important;
        
        }

        /* .navbar-expand-sm .navbar-toggler {
            width: 1366px !important;
        } */
    }

@media (min-width: 931px) and (max-width: 1010px){
.collapse.navbar-collapse ul{
  font-size:14px;
}
.navbar .navbar-brand{
    font-size:16px;
}
}

@media (max-width: 930px){
    .collapse.navbar-collapse ul{
      font-size:12px;
    }

    .navbar .navbar-brand{
        font-size:15px;
    }
    }
    .AdminHide{
        display:none;
    }
 `, "",{"version":3,"sources":["webpack://./src/components/container/index.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;IACjB,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI;QACI,wBAAwB;QACxB,yCAAyC;;QAEzC;;QAEA;;WAEG;IACP;;AAEJ;AACA;EACE,cAAc;AAChB;AACA;IACI,cAAc;AAClB;AACA;;AAEA;IACI;MACE,cAAc;IAChB;;IAEA;QACI,cAAc;IAClB;IACA;IACA;QACI,YAAY;IAChB","sourcesContent":[".block-ui-container{\n    z-index: 9999 !important;\n}\n\n.backColor{\n    line-height: 2rem;\n    height: 3.375rem !important;\n}\n\n#react-confirm-alert{\n    position: relative;\n    z-index: 9999;\n}\n\n@media screen and (max-width: 1150px) {  \n    html, body {\n        width: 1366px !important;\n        overscroll-behavior-y: contain !important;\n        \n        }\n\n        /* .navbar-expand-sm .navbar-toggler {\n            width: 1366px !important;\n        } */\n    }\n\n@media (min-width: 931px) and (max-width: 1010px){\n.collapse.navbar-collapse ul{\n  font-size:14px;\n}\n.navbar .navbar-brand{\n    font-size:16px;\n}\n}\n\n@media (max-width: 930px){\n    .collapse.navbar-collapse ul{\n      font-size:12px;\n    }\n\n    .navbar .navbar-brand{\n        font-size:15px;\n    }\n    }\n    .AdminHide{\n        display:none;\n    }\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
