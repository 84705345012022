const translationsRO = {
  ro: {
    Policy: {
      HeaderMsg:
        "Notificare privitoare la politica de confidențialitate în prelucrarea datelor dvs. personale în cadrul Kaizen Expert Tool.",
      Message1:
        "Această notificare de confidențialitate se referă la procesarea datelor dvs. personale în cadrul Kaizen Expert Tool. Această notificare trebuie întotdeauna citită împreună cu Politica generală de confidențialitate a Toyota și Politica de protecție a datelor personale în care modalitățile de prelucrare a datelor dvs. personale sunt descrise și care trebuie acceptate de dvs. pentru a putea utiliza în continuare această aplicatie.",
      Message2:
        "Toyota Motor Europe NV/SA având sediul în Avenue du Bourget 60, 1140 Bruxelles, Belgia, în calitate de Controlor de date, colectează următoarele date personale în scopurile descrise mai jos.",
      Message3: "Ce date personale vor fi colectate?",
      Message4:
        "Următoarele categorii de date personale vor fi colectate de către Kaizen Expert Tool:",
      Table1: {
        tr1: "Categoriile de date personale:",
        tr2: "ID utilizator",
        tr3: "Nume, Prenume utilizator",
        tr4: "Franșiză (Toyota, Lexus)",
        tr5: "Limba preferată",
        tr6: "Organizația afiliată"
      },

      Message5:
        "Scopul și bazele legale pentru procesarea datele dvs. personale",
      Message6:
        "Datele dvs. personale vor fi procesate pentru scopurile și în baza legală descrise mai jos:",
      Table2: {
        tr1: {
          th1: "Scop:",
          th2: "Baza legală:"
        },
        tr2: {
          td1: "KET-1: Retailer Performance Kaizen",
          td2: "Interesul legitim urmărit de Controlorul de date"
        },
        tr3: {
          td1: "KET-2: Suport IT",
          td2: "Interesul legitim urmărit de Controlorul de date"
        }
      },
      Message7: "Durata de păstrare a datelor dvs. personale",
      Message8:
        "Datele dvs. personale vor fi păstrate pentru perioada menționată mai jos:",
      Table3: {
        tr1: {
          th1: "Perioada de păstrare:",
          th2: "Categoriile de date personale:"
        },
        tr2: {
          td1:
            "Toate datele personale descrise anterior vor fi păstrate pentru o perioadă de maxim cinci ani, plus o lună adițională, calculată începând cu data închiderii finale a Kaizen Expert Tool.",
          td2:
            "Prenume utilizator, Nume utilizator, limba preferată, organizație"
        }
      },
      Message9:
        "Opțional: În cazul dezvăluirii/transferului datelor dvs. personale către o țară din afara Spațiului Economic European (European Economic Area -EEA)",
      Message10:
        "Datele dvs. personale vor fi accesibile de către recipienți din următoarele țări din afara Spațiului Economic European (European Economic Area-EEA):",
      Table4: {
        tr1: {
          td1: "Identitate",
          td2: "Țară",
          td3: ""
        },
        tr2: {
          td1: "Recipient non-EEA",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11:
        "Prin prezenta confirm că am înțeles această notificare. Am citit și accept Condițiile Generale de Confidențialitate ale Toyota și Politica de protecție a datelor personale."
    },
    ACCESSORIES: "Accesorii",
    activity: {
      Assignee: "Responsabil",
      CompletionDate: "Data finalizării",
      Dealer: "Dealer",
      FirstVisit: "Prima vizită",
      kpi: {
        kpi: "KPI poate fi format numai din numere pozitive sau negative.",
        kpiLength: "Lungimea maximă pentru KPI este de 10 caractere.",
        mandatory: "Vă rugăm să introduceți date valide în toate câmpurile obligatorii",
        mandatoryTargets: "Vă rugăm să introduceți toți indicatorii KPI.",
        target:
          "Target-ul poate fi format numai din numere pozitive sau negative.",
        targetLength: "Lungimea maximă pentru target este de 10 caractere."
      },
      ReminderOn: "Rapel în data de",
      statusDD: "Stare",
      Toolbox: "Modul Kaizen",
      activityCreation: "Procesul de creare a Activității a fost demarat.",
      activityCreated: "Activitatea a fost creată."
    },
    DPOK: "Optimizare stoc piese",
    EFFICIENCY: "Eficiență",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Câmpuri obligatorii"
    },
    FOUNDATION: "Principii de bază",
    MAINTENANCE_REMINDERS: "Notificare revizii MRS",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamente",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr.",
      aug: "Aug.",
      dec: "Dec.",
      feb: "Feb.",
      jan: "Ian.",
      jul: "Iul.",
      jun: "Iun.",
      mar: "Mar.",
      may: "Mai",
      nov: "Nov.",
      oct: "Oct.",
      sep: "Sept."
    },
    others: {
      back: "Înapoi",
      closed: "Închis",
      completed: "Finalizat ",
      countryLanguageCode: "Țară / Cod limbă ",
      createActivity: "Creare activitate",
      createdDate: "Data creării",
      dataUneditableNote:
        "Notă: Datele salvate nu mai pot fi modificate ulterior Pentru a modifica datele, vă rugăm să luați legătura cu Toyota România (KET Admin)",
      date: "Dată",
      downloadQuestionnaire: "Descărcare chestionar",
      editActivity: "Editare activitate",
      initialHelpText:
        "Măsurătoarea inițială a indicatorilor KPI trebuie considerată ca valoare medie a ultimelor șase luni întregi",
      inProgress: "În desfășurare",
      name: "Nume",
      next: "Următorul",
      noActivities: "Nu a fost găsită nici o activitate",
      noResults: "Nu există rezultate",
      of: "din",
      ongoing: "În desfăşurare",
      open: "Deschis",
      page: "Pagină",
      planned: "Planificat",
      problemDescription: "Descrierea problemei",
      signature: "Semnătură",
      select: "Selectați",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    Planned: "Planificat",
    ServerMessage: {
      error_message: "S-a întâmpinat o eroare în timpul descărcării.",
      no_published_subquestion: "Vă rugăm să publicați chestionarul acestui modul de kaizen  pentru a putea începe această Activitate.",
      activity_upload_error_msg:
        "A apărut o eroare în timpul încarcării detaliilor Activității.",
      action_plan_created: "Planul de acțiune a fost creat cu succes.",
      action_plan_deleted: "Planul de acțiune a fost șters cu succes.",
      action_plan_saved: "Planul de acțiune a fost salvat cu succes.",
      activity_kpi_saved:
        "Indicatorii de performanță pentru această activitate au fost salvați cu succes.",
      master_kpi_confirm_delete:
        "Are you sure you want to delete the selected KPI's?",
      master_kpi_created: "KPI-ul de referință fost creat cu succes",
      master_kpi_deleted: "KPI-ul de referință a fost șters",
      master_kpi_not_found: "Nu s-a găsit KPI-ul de referință",
      master_kpi_published:
        "Indicatorii de performanță selectați au fost publicați cu succes",
      master_kpi_retired: "KPI-ul de referință a fost retras",
      master_kpi_saved: "KPI-ul de referință a fost salvat cu succes",
      subquestion_created: "Întrebarea a fost salvată cu succes.",
      subquestion_updated: "Întrebarea a fost actualizată cu succes.",
      subquestionnaire_not_found: "Întrebarea nu a fost găsită.",
      subquestion_retired: "Întrebarea a fost retrasă.",
      subquestion_deleted: "Întrebarea a fost ștearsă.",
      subquestion_published: "Întrebările au fost publicate cu succes.",
      mainquestion_created: "Întrebarea principală a fost creată.",
      mainquestion_deleted: "Întrebarea principală a fost ștearsă.",
      mainquestion_updated: "Întrebarea principală a fost actualizată.",
      duplicateMsg: "Au fost identificate duplicate in fișierul Excel.",
      deleteErr: "Eroare apărută în timpul ștergerii fișierului",
      toolbox_deleted: "Modulul a fost șters cu succes",
      toolbox_already_exists: "Acest modul există deja",
      toolbox_saved: "Modul salvat",
      toolbox_created: "Modul creat",
      toolbox_not_found: "Modulul nu a fost găsit",
      published_content_found: "Acest modul nu poate fi șters deoarece are conținut publicat.",
      dealers_already_mapped: " Dealerii selectați sunt deja alocați pentru acest utilizator. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer-ul a fost adăugat. ",
      dealers_added: "  Dealer-ul a fost adăugat. ",
      total_dealers_mapped_now: "Numărul total de dealeri alocați acum acestui utilizator este . ",
      no_dealer_available: "Dealeri indisponibili pentru criteriile selectate.",
      proper_dealer_selected: "Vă rugăm să selectați un dealer corespunzător.",
      user_referenced_ongoing_activity: "Utilizatorul este momentan selectat ca Responsabil pentru Activități în desfășurare. Vă rugăm să înlocuiți Responsabilul acestor Activități și să încercați din nou. ",
      want_to_remove_mapping: "Sunteți sigur(ă) că doriți să înlăturați alocarea dealer-ilor pentru acest utilizator?",
      user_mapping_removed: "Dealerii selectați au fost înlăturați din alocarea acestui utilizator.",
      dealer_closed_in_A2D: "Acest dealer este inregistrat ca 'Închis' în baza de date A2D.",
      network_level_not_found: "Vă rugăm să selectați o combinație validă a nivelurilor de rețea în conformitate cu configurarea existentă în KET.",
      duplicate_dealer_uuid: "Acest dealer este deja prezent în baza de date.",
      dealer_created: "Dealer creat.",
      dealer_updated: "Caracteristicile acestui dealer au fost actualizate.",
      dealer_not_present: "Acest dealer nu este înregistrat în baza de data A2D.",
      dealer_uuid_other_nmsc: "Codul A2D uuid selectat nu este disponibil pentru rețeaua dvs de dealeri.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Sunteți sigur(ă) că doriți să ștergeți?",
      addActionPlanNotAllowed: "Planul de Acțiune nu poate fi creat sau modificat după ce Activitatea a fost Închisă, Finalizată sau Respinsă.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Sunteți sigur(ă) că doriți să respingeți această Activitate? După respingere nu se mai pot efectua nici un fel de modificări.",
      rejectMsg: "Sunteți sigur(ă) că doriți să respingeți această Activitate? După respingere nu se mai pot efectua nici un fel de modificări.",
      selectNMSCTab: "Vă rugăm să selectați limba pentru publicare",
      all: "Tot",
      deleteConfirm:
        "Sunteți sigur că doriți să ștergeți indicatorul KPI selectat?",
      no: "Nu",
      retireConfirm:
        "Sunteți sigur că doriți să retrageți indicatorul KPI selectat? Odată retras, acest indicator KPI nu poate fi reactivat",
      warning: "Avertisment",
      yes: "Da",
      saveDataConfirmation: "Sunteți sigur(ă) că doriți să salvați datele?",
      saveKPIWarning:
        "Notă: Datele nu pot fi editate după ce au fost salvate. Pentru a modifica datele, vă rugăm să luați legătura cu administratorul KET al Toyota Romania",
      addKpiNotAllowed: "Planul de Acțiune nu poate fi creat sau modificat după ce Activitatea a fost Închisă, Finalizată sau Respinsă. ",
      activityLaunch:
        "Sunteți sigur(ă) că doriți să lansați această Activitate? După lansare, Activitatea nu mai poate fi ștearsă.",
      deleteConfirmSubQuestionNaire:
        "Sunteți sigur(ă) că doriți să ștergeți această întrebare?",
      retireConfirmSubQuestionNaire:
        "Sunteți sigur(ă) că doriți să retrageți această întrebare?",
      deleteConfirmMainQuestion:
        "Vă rugăm să confirmați că doriți să ștergeți întrebarea principală și întrebările atașate.",
      deleteConfirmChapter: "Sunteți sigur(ă) că doriți să ștergeți capitolul?",
      deleteLibraryFolder:
        "Sunteți sigur(ă) că doriți să ștergeți acest dosar?",
      deletePublishedFolder:
        "Anumite fișiere din acest dosar pot fi atașate unor întrebări din chestionar. Sunteți sigur(ă) că doriți să ștergeți permanent acest dosar și să eliminați atașamentele din chestionar?",
      importQuestion:
        "Sunteți sigur(ă) că doriți să importați această întrebare? Întrebarea curentă va fi înlocuită.",
      noQuestion: "Nu există întrebări disponibile.",
      MandatoryWarning: "Vă rugăm să completați toate câmpurile obligatorii.",
      certificationSubmission:
        "Modulul a fost trimis către TME pentru certificare",
      sameToolBox: "Nu este posibil să trimiteți același modul din nou. Vă rugăm să selectați un alt modul.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Toate Activitățile create pentru acest dealer în Value chain vor fi șterse.Sunteți sigur(ă) că doriți să continuați?",
      updateBritConfirmDealer: "Toate Activitățile create pentru acest dealer în programul BRiT vor fi șterse.Sunteți sigur(ă) că doriți să continuați?",
      completed_activity_delete:"Datele nu pot fi recuperate după ștergere.Sunteți sigur(ă) că doriți să continuați?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Selectează tot",
      unSelectAll: "Deselectează tot",
      select:"Selectează..",
      warningMsg:"Anumite activități incluse in Planul de Acțiune sunt incă în desfășurare. Sunteți sigur că doriți să finalizați această Activitate?",
      updateEaConfirmDealer: "Toate Activitățile create pentru acest dealer în programul EA vor fi șterse.Sunteți sigur(ă) că doriți să continuați?"
    },
    status: {
      published: "Publicat",
      retired: "Retras",
      saved: "Salvat",
      rejected: "Respinsă",
      open: "Deschis",
      closed: "Închis",
      close: "Închis",
      ongoing: "În desfăşurare",
      completed: "Finalizat",
      complete: "Finalizat",
      planned: "Planificat",
      overdue: "Restante",
      due: "Scadente",
      pending: "Pending",
    },
    Rejected: "Respinsă",
    Open: "Deschis",
    Closed: "Închis",
    Close: "Închis",
    Ongoing: "În desfăşurare",
    Completed: "Finalizat",
    Complete: "Finalizat",
    tabDetails: {
      noActionAvailable: "Nu există acțiuni disponibile",
      activeDealer: "Acest dealer este înregistrat ca operațional în baza de date A2D și nu poate fi șters.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Activitate",
      activityCompletionDate: "Data finalizării activității",
      activityCompletionDateFrom: "Data de finalizare a Activității de la",
      activityCompletionDateTo: "Data de finalizare a Activității până la",
      activityStartDate: "Data începerii activității",
      activityStartDateFrom: "Data de început a Activității de la",
      activityStartDateTo: "Data de început a Activității până la",
      assignedTo: "Atribuit către",
      assignee: "Responsabil",
      benchmark: "Etalon",
      city: "Oraș",
      completedBy: "Finalizat de ",
      completionBy: "Finalizat de ",
      completionDate: "Data finalizării",
      counterMeasure: "Acțiune corectivă",
      country: "Țară",
      createdBy: "Creat de",
      dealer: "Dealer",
      dealerNameAD: "Nume dealer (A2D)",
      dealerName: "Dealer",
      definition: "Definiție",
      description: "Descriere",
      dueDate: "Data scadentă",
      endDate: "Data finalizării",
      firstVisit: "Prima vizită",
      followup: "Monitorizare ulterioară",
      franchise: "Marcă",
      history: "Istoric",
      id: "ID",
      initial: "Inițial",
      kpi: "KPI",
      launch: "Lansare",
      mandatory: "Obligatoriu",
      nmsc: "NMSC",
      problem: "Problemă",
      publishedOn: "Publicat la",
      questionReference: "Referință întrebare",
      reference: "Referință",
      reminderOn: "Rapel în data de",
      score: "Scor",
      startDate: "Data începerii",
      status: "Stare",
      target: "Target",
      toolbox: "Modul kaizen",
      view: "Vizualizare",
      kpiResult: "Rezultate KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Locație",
      ActionItemReport: "Acțiuni kaizen în desfășurare",
      certification: "Stare certificare",
      noOfToolbox: "Număr module kaizen finalizate",
      program: "Program",
      certificationDate: "Certificat în",
      activityId : 'Cod Activitate',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan de acțiune",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Plan de acțiune",
      confirmDeleteMessage:
        "Sunteți sigur că doriți să ștergeți indicatorul KPI selectat?",
      confirmRetireMessage:
        "Vă rugăm să confirmați dacă doriți să retrageți KPI-ul selectat, întrucât acesta este publicat",
      errorRetireMsg:
        "Acest indicator KPI nu poate fi retras întrucât est un indicator KPI obligatoriu al TME",
      import: "Import",
      importKpiMessage:
        "Vă rugăm să importați indicatorii de performanță din Lista de referință TME",
      kpi: "Indicatori de performanță ",
      library: "Bibliotecă",
      noActionAvailable: "Nu există acțiuni disponibile",
      NoKPI: "Indicatori de performanță indisponibili",
      publish: "Publicare",
      questionnaire: "Chestionar",
      tmeKPIList: "Lista de referință TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nume modul",
      newToolboxName: "Noul nume",
      deleteToolbox: "Ștergeți acest modul?",
      programName: "Nume program",
      ToolboxName_EN: "Nume modul în engleză (EN)",
      deleteTool: "Sunteți sigur(ă) că doriți să ștergeți acest modul?",
      selectToolbox: "Vă rugăm să stabiliți un nume pentru acest modul.",
      editToolBox: "Editați modulul",
      addToolbox: "Adăugați un nou modul.",
      toolbox: "Introduceți un nume pentru acest modul.",
      addActionPlan: "Adăugați Plan de acțiune",
      actionPlanItem: "Plan de acțiune - Articol nr.",
      add: "Adăugați",
      addActivity: "Adăugare activitate",
      additionalInformation: "Informații suplimentare",
      benchmark: "Etalon",
      cancel: "Anulare",
      createEditActivity: "Creare/Editare activitate",
      createKPI: "Creare KPI",
      delete: "Ștergere",
      download: "Descărcare",
      edit: "Editare",
      editKPI: "Editare KPI",
      keyPerformanceIndicator: "Indicator de performanță",
      kpiDefinition: "Definiție KPI",
      mandatoryKpi: "KPI obligatoriu",
      save: "Salvare",
      search: "Căutare",
      unit: "Unitate",
      update: "Actualizare",
      validData: "Vă rugăm să introduceți date valide",
      hide: "Ascunde",
      show: "Arată",
      upload_dealer_network: "Încarcă rețeaua de dealeri",
      upload_question: "Încarcă chestionarul",
      upload_user: "Încarcă detaliile utilizatorilor",
      upload_activity: "Încarcă Activități",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Data limită depașită.",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Vă rugăm să răspundeți la toate întrebările din chestionar pentru a putea închide această activitate.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Vă rugăm să adăugați valorile KPI pentru situația inițială, target-uri și cel puțin pentru o lună suplimentară.",
      initialTarget: "Vă rugăm să introduceți valorile KPI pentru evaluarea inițială și target-uri pentru a continua.",
      upload_error: "A apărut o eroare în timpul încărcării rețelei de dealeri.",
      action_item_create_warning: "Numărul maxim de înregistrări care pot fi grupate intr-o acțiune de kaizen este de cinci.",
      actionPlanStartDateValidation:
        "Data finalizării nu poate fi anterioară Datei de începere",
      actionsNotClosed:
        "Există acțiuni în desfășurare în Planul de acțiune. Vă rugăm să închideți toate acțiunile înainte de a închide o activitate",
      activityDuplicate:
        "Există deja o Activitate pentru același Dealer și același Modul Kaizen",
      assigneeNotExist:
        "Responsabilul selectat nu a fost înregistrat în sistem",
      assigneeValidation: "Responsabil incorect",
      atleastOnePublish:
        "Vă rugăm să selectați cel puțin un indicator KPI pentru publicare",
      cannotRetireActive:
        "Acesta este un indicator KPI obligatoriu al TME care nu poate fi retras ",
      completionDate: "Data finalizării nu poate fi anterioară primei vizite",
      confirDeletePlannedActivity:
        "Sunteți sigur(ă) că doriți să ștergeți această activitate planificată?",
      DateformatValidation: "Formatul datei trebuie să fie ZZ/LL/AAAA",
      DateValidation: "Data de start trebuie să fie anterioară datei finale.",
      dealerValidation: "Dealer incorect",
      deleteSelectedConfirm:
        "Sunteți sigur că doriți să ștergeți indicatorul KPI selectat?",
      duplicate:
        "Există deja un indicator KPI cu același nume și aceeași definiție",
      modalMandatory:
        "Vă rugăm să introduceți date valide în toate câmpurile obligatorii",
      noPublishKpi:
        "Indicatorii KPI nu au fost încă publicați pentru Modulul Kaizen selectat",
      validSearchCriteria: "Vă rugăm să introduceți criterii de căutare valide",
      multiSelectPlaceHolder: "Minim 5 caractere",
      multiSelectPlaceHolderUser: "Minim 3 caractere",
      startDateValidation: "Data de începere nu poate fi după data de sfârșit",
      fileSelectionErr: "Vă rugăm să selectați mai întâi fișierul.",
      atleastOneQuestion:
        "Vă rugăm să selectați cel puțin o întrebare pentru import.",
      atleastOneSubQues: "Vă rugăm să selectați cel puțin o întrebare pentru publicare",
      uploadErr: "Numărul de fișiere eșuate la încărcare:",
      uploadSuccess: "Rețeaua de dealeri a fost încărcată cu succes.",
      file_upload_success: "Încărcare cu succes.",
      KPIinOngoingClosedActivity: "Acest KPI este prezent în una sau mai multe Activități în desfășurare sau închise, dar nefinalizate. Vă rugăm să finalizați aceste Activități pentru a putea modifica acest KPI.",
      Role_Error: "Configurarea acestui utilizator este incompletă, vă rugăm să contactați admnistratorul KET de la Toyota România.",
      points_allocation_incorrect: "Alocarea punctelor este incorectă, vă rugăm să modificați.",
      activity_deleted:"Activitate ștearsă.",
      activity_certified_dealer:"Această Activitate a fost inclusă în certificarea dealer-ului și nu poate fi ștearsă.",
      activity_submited_for_certificate_dealer:"Statutul acestei Activități nu poate fi modificat întrucât este inclusă în cererea de certificare trimisă către TME."
    },
    TopNav: {
      ActivityLog: "Activități",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Acasă",
      logout: "Deconectare",
      MasterDataUpload: "Încărcare Date primare",
      Toolbox: "Module Kaizen",
      welcome: "Bine ați venit!",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Adăugați o întrebare",
      editSubQuestion: "Editați întrebarea",
      subQuestionTitle: "Întrebare",
      additionalInfo: "Informații suplimentare",
      na: "Adăugați 'Nu se aplică' ca posibil răspuns pentru această întrebare",
      question: "Întrebare",
      questionTitle: "Titlul întrebării",
      why: "De ce?",
      addMainQuestion: "Adăugați o întrebare principală",
      editMainQuestion: "Editați întrebarea principală",
      previousQuestion: "Întrebarea precedentă",
      nextQuestion: "Întrebarea următoare",
      what: "Ce anume?",
      library: "Bibliotecă",
      subQuestionTable: {
        no: "Nr.",
        subQuestion: "Întrebare",
        status: "Stare",
        publishedDate: "Data publicării",
        notApplicable: "Nu se aplică"
      },
      links: {
        question_link_button: "Atașați",
        question_link_include_folder: "Includeți un dosar",
        question_link_add_link: "Atașați fișiere din bibliotecă",
        question_link_view_folder: "Vizualizați biblioteca"
      },
      mainQuestion: "Întrebări principale Noi/Actualizate:",
      subQuestion: "Întrebări Noi/Actualizate:",
      retiredQuestion: "Întrebări retrase:",
      libraries: "Fișiere în bibliotecă Noi/Actualizate:",
      noFiles: "Nu există fișiere disponibile."
    },
    library: {
      selectFile: "Selectați cel puțin un fișier.",
      fileSelected: "Fișiere selectate",
      publish_library_file:
        "Vă rugăm să selectați cel puțin un fișier pentru publicare.",
      library_files_deleted: "Sunteți sigur(ă) că doriți să ștergeți acest fișier?",
      library_files_linked_deleted:
        "Sunteți sigur(ă) că doriți să ștergeți acest fișier și legăturile aferente create in chestionar?",
      max_size_error:
        "Mărimea totală a fișierului sau a fișierelor depășește limita maximă de 1 GB per încărcare.",
      empty_file_desc: "Please enter a file description",
      file_not_selected:
        "Selectați fișierul sau fișierele pe care doriți să le încărcați (maxim 1 GB)",
      file_type_error: "Acest tip de fișier nu este permis.",
      file_name_error: "Caracter invalid in numele fișierului.",
      file_name_lenght_error:
        "Numele fișierului poate avea maxim 50 caractere.",
      addFolder: "Adăugați dosar",
      editFolder: "Editați dosar",
      currentFolder: "Nume dosar",
      parentFolder: "Nume dosar rădăcină",
      emptyFolderName: "Numele dosarului nu poate fi un spațiu gol.",
      invalidFolderName: "Numele acestui folder nu poate conține acest simbol '/'.",
      folder_created: "Dosarul a fost salvat cu succes.",
      folder_updated: "Dosarul a fost actualizat cu succes.",
      folder_not_found: "Dosarul nu a fost găsit.",
      lib_invalid_toolbox: "Modulul Kaizen selectat nu este valid.",
      lib_folder_already_exists: "Acest dosar există deja.",
      lib_folder_deleted: "Dosarul a fost șters cu succes.",
      library_files_published: "Fișierele au fost publicate.",
      addFile: "Adăugați un fișier",
      editFile: "Editați fișierul",
      fileName: "Nume fișier",
      upload: "Încărcați",
      desc: "Descriere",
      folderCreationErr: "Ierarhia permite maximum două nivele.",
      subFolderWarning: "Selectați un dosar pentru a adăuga fișiere.",
      deleteWarning: "Selectați dosarul pentru a fi șters.",
      beforeAfterNotSelected: "Vă rugăm selectați dacă documentul reflectă situația inainte sau după finalizarea activitaților de îmbunătățire.",
    },
    chapter: {
      chapter_exist: "Acest capitol există deja.",
      chapter_saved: "Capitol salvat",
      chapter_cannot_delete:
        "Acest capitol nu poate fi șters deoarece întrebările au fost deja publicate.",
      failed: "Eșec!",
      chapter_deleted: "Capitol șters",
      chapter_nonEmpty: "Creați un nume pentru acest capitol.",
      addchapter: "Adăugați un capitol",
      editChapter: "Editare capitol",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Atașamente",
      comments: "Comentarii",
      evidence: "Dovezi",
      evidences: "Dovezi",
      yes: "Da",
      no: "Nu",
      na: "N/A",
      uploadEvidence: "Încarcă dovadă",
      fileUpload: "Selectați fișierul sau fișierele pe care doriți să le încărcați (maxim 1 GB)",
      who: "Cine?",
      answer: "Răspuns",
      comment: "Comentarii",
      date: "Dată",
      submit: "Trimite",
      commentMsg: "Vă rugăm să introduceți comentariile.",
      noCommentAvailable: "Nu există comentarii disponibile.",
      uploadSuccessMsg: "Încărcare cu succes.",
      uploadErrorMsg: "Numărul de fișiere eșuate la încărcare:",
      fileWithSameName: "Există deja un fișier cu un nume identic.",
      noEvidence: "Nu există documente suport disponibile",
      createAction: "Creați acțiune de kaizen",
      chapterName: "Nume capitol",
      questionNo: "Nr. Întrebare",
      when: "Când?",
      noRecords: "Nu există înregistrări disponibile.",
      kaizenPoints: "Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Întrebare",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Este documentul referitor la situația înainte sau după finalizarea activităților de îmbunătățire.",
      move:"Transferă",
      moveMsg: "Fișierele au fost mutate cu succes.",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Inspecția vizuală RVS",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Tinichigerie/Vopsitorie",
      TSM_USED_CAR: "Used Car",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Avizier KPI",
      kpi_target_not_set: "Valorile țintă lipsă pentru indicatorii KPI.",
      kpi_initial_not_set: "Valorile inițiale lipsă pentru indicatorii KPI.",
      kpi_monthly_not_set: "Valorile lunare lipsă pentru indicatorii KPI.",
      kpi_below_target: "Valori ale indicatorilor KPI sub valorile țintă.",
      activation: "Activare",
      users: "Utilizatori",
      nmscs: "NMSC's",
      activeRetailer: "Dealeri activi",
      certificationRate: "Rată certificare",
      reCertificationRate: "Rată re-certificare",
      activityDue: "Activități scadente",
      avgdays: "Medie zile/Modul",
      overDueAct: "Activități restante",
      actionItem: "Acțiuni kaizen",
      bussResult: "Rezultate comerciale",
      toolboxImpl: "Implementare module kaizen",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Actualizări recente",
      rolloutTopNmsc: "Rezultate implementare - Top NMSC",
      bestPractice: "Panou rezultate remarcabile în rețea",
      availableKpi: "KPI disponibili",
      selectedKpi: "KPI selectați",
      dealerBestPractice: "Panou de bune practici",
      kpiList: "Listă KPI",
      go: "Start",
      nodata: "Nici o informație disponibilă",
      businessResultTop: "Rezultate comerciale - Top dealeri",
      businessResultNMSC: "Rezultate comerciale - Top NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certificare dealer",
      readyForCertification: "Disponibil pentru certificare",
      certification: "Certificare",
      submittedOn: "Trimis în",
      certificateDate: "Dată primă certificaree",
      reCertificateDate: "Dată re-certificare",
      toolboxDetails: "Detalii modul kaizen",
      approve: "Aprobare",
      reject: "Respinge",
      pending: "În așteptare",
      region: "Regiune"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "ID execuție",
      uploadedOn: "Încărcat în",
      lastUpdatedOn: "Ultima actualizare",
      noDataTable: "No data to view",
      kpiUpload: "Încărcare indicatori KPI",
      action: "Acțiune",
      processed: "Prelucrat",
      error: "Eroare",
      received: "Received",
      createActivity: "Crează Activități",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Administrare utilizatori",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Prenume",
      lastName: "Nume",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Cont PBI",
      invalidPbiAccount : "Contul Power BI trebuie să conțină minim 3 caractere.",
      businessArea: "Program",
      valueChainAndBRiT: "Value chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain",
      britOnly: "BRiT",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Prenume",
      lastName: "Nume",
      emailId: "E-mail",
      languageCode: "Cod limbă",
      ACTIVE: "Active",
      INACTIVE: "Inactiv",
      addUser: "Adăugare utilizator",
      Assign: "Alocare",
      List: "Listă",
      user: "Utilizator",
      error_message: "A apărut o eroare, vă rugăm să reîncercați.",
      role: "Rol utilizator",
      standardMessage: "No User Found",
      dealerCode: "Cod dealer",
      a2d: "A2D UUID",
      britReady: "Dealer-ul a participat în programul 'Brit Ready'.",
      britLive: "Dealer-ul a participat în programul 'Brit Live'.",
      britJapan: "Dealer-ul a participat în programul 'Brit Discovery Japan'.",
      britEss:"Dealer-ul a actualizat Planul de acțiune EES.",
      addDealer: "Adăugare dealer (din A2D)",
      noDealer: "Nici un dealer nu a fost găsit.",
      dealerUuid: "Cod uuid dealer (A2D)",
      language: "Limbă",
      L3: "N3",
      L4: "N4",
      L5: "N5",
      L6: "N6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Vă rugăm să introduceți un cod A2D uuid valid.",
      invalidDealerName: "Vă rugăm să introduceți un nume de dealer valid.",
      invalidCity: "Vă rugăm să introduceți o locație validă.",
      invaliDealerCode: "Vă rugăm să introduceți un cod dealer valid.",
      existingUuid: "Codul A2D uuid este deja prezent in baza de date.",
      updateUuid: "Actualizare cod A2D uuid",
      newUuid: "Cod A2D uuid nou",
    },
    networkManagement: {
      networkManagement: "Administrare dealeri",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Descarcari",
      historyFiles:"Istoricul fisierelor anterioare",
      reachText1:"Bine ati venit pe pagina de pornire REACH IT. Echipa TME Environmental Management va folosi aceasta pagina pentru a partaja informatii relevante legate de SCIP-Substances of Concern In articles as such or in complex objects (Products)/Substanțe care prezintă motive de îngrijorare in articole ca atare sau in obiecte complexe (Produse) stabilite in conformitate cu Directiva Cadru privind Deșeurile (WFD-Waste Framework Directive) pentru ca dumneavoastră sa va indepliniti obligatia de a procesa cu notificarea dumneavoastra simplificata SCIP.",
      reachText2:"In cazul in care aveti intrebari, va rugam sa contactati echipa de management de mediu TME prin linkul de mai jos.",
      reachText3:"In cazul in care aveti intrebari, va rugam sa contactati TROM.",
      envMngt:"Management de Mediu",
      fileStatistics: "Statistici fisier descarcat",
      Downloaded: "Descarcat",
      not: "Nu",
      exportToExcel:"Export in Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsRO;
