import React from "react";
import { connect } from "react-redux";
import { withTheme, themes } from "../../../themes/theming";
import {
  Navbar,
  NavbarBrand,
  NavbarNav,
  NavbarToggler,
  Collapse,
  NavItem,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Fa
} from "mdbreact";
import { withRouter } from "react-router-dom";
import ThemeChanger from "../../../themes/ThemeChanger";
import AuditMenu from "./AuditMenu";
import AuditDealerMenu from "./AuditDealerMenu";
import TmeAdminMenu from "./TmeAdminMenu";
import TmeInternalMenu from "./TmeInternalMenu";
import AllocateWarning from "../../../components/annualSetup/allocateWarning"
import NmscAdminMenu from "./NmscAdminMenu";
import NmscInternalMenu from "./NmscInternalMenu";
import NmscFieldMenu from "./NmscFieldMenu";
import NmscExternalMenu from "./NmscExternalMenu";
import DealerKaizenChampionMenu from "./DealerKaizenChampionMenu";
import DealerInternalMenu from "./DealerInternalMenu";
import CeaNetUcAdminMenu from "./CeaNetUcAdminMenu";
import ReachItMenu from "./ReachItMenu";
import * as AppConst from '../../../AppConstant';
import { getAccessControlList, updateUserMdl, toggleNotifications,toggleTheme } from "../../../actions/loginAction";
import "./index.css";
import { push } from "connected-react-router";
import GDPR from "../../../components/gdpr";
import NotificationsSwitch from "./NotificationsSwitch";
import Select from "react-select";
const $ = require('jquery')


var I18n = require("react-redux-i18n").I18n;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      theme: themes.default,
      modal: true,
      showModal: false,
      isOldUser: true,
      activeProgram: '',
      activeProgramID: 0,
      activeProgramOBJ: {},
      activeBusinessAreaID: 0,
      activeProgramName: '',
      businessAreaNameEn: '',
      businessAreaKey: '',
      allocateFlag: false,
      ceaCurrentYears:''
    };
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      collapse: !this.state.collapse
    });
  }

  componentWillMount() {
    console.log("inside header" + JSON.stringify(this.props.user.userMdl));
    // alert("inside header" + JSON.stringify(this.props.user.userMdl))
  }

  componentWillReceiveProps(nextProps) {
    if (Object.keys(this.state.activeProgramOBJ).length === 0 && nextProps.user.userMdl.businessAreasList) {
      this.setState({
        activeProgramOBJ: nextProps.user.userMdl.businessAreasList[0].programsDTOList[0],
        activeProgram: nextProps.user.userMdl.businessAreasList[0].programsDTOList[0].programKey,
        activeProgramName: nextProps.user.userMdl.businessAreasList[0].programsDTOList[0].programName,
        activeProgramID: nextProps.user.userMdl.businessAreasList[0].programsDTOList[0].id,
        activeBusinessAreaID: nextProps.user.userMdl.businessAreasList[0].id,
        businessAreaNameEn: nextProps.user.userMdl.businessAreasList[0].businessAreaNameEn,
        businessAreaKey: nextProps.user.userMdl.businessAreasList[0].businessAreaKey,
      })
    }

    if(nextProps.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer){
        this.setState({
          getCEAYears :  nextProps.user.userMdl.getCEAYears.map(_=>{
        let obj = {value:_,label:_}
        return obj
          })
        })
      }
    }




  logOut(e) {
    e.preventDefault();
    // Clear All cookies.
    // var res = document.cookie;
    // var cookies = res.split(";");
    // for (var i = 0; i < cookies.length; i++) {
    //     var key = cookies[i].split("=");
    //     document.cookie = key[0] + " =; expires = -1";
    // }
    // this.props.history.push('/logout');
    this.props.dispatch(push("/logout"));
    // window.location.href="https://" + window.location.host + "/ket/auth/deleteCookie";
  }

  popUpClose = event => {
    event.preventDefault();
    this.setState({ modal: !this.state.modal });
  };

  toggle = event => {
    this.setState({ modal: !this.state.modal });
  };
  TextTrackCue;
  gdpr(e) {
    e.preventDefault();
    this.setState({ showModal: true, modal: true });
  }

  // changeProgram = (e, element) => {
  //   e.preventDefault();
  //   var userMdl = this.props.user.userMdl;
  //   userMdl["activeProgram"] = element;
  //   this.setState({ activeProgram: element })
  //   this.props.dispatch(getAccessControlList(userMdl));
  //   let index=this.props.user.userMdl.programList.indexOf(element);
  //   $("ul.navbar-nav a").removeClass("selectedMenu");
  //   $("ul.navbar-nav a[href='/home'").addClass("selectedMenu");
  //   this.props.dispatch(push({ pathname: "/redirect" }));
  // }

  changeProgramOBJ = (e, programOBJ, baOBJ) => {
    e.preventDefault();
    var userMdl = this.props.user.userMdl;
    console.log(programOBJ)
    console.log(baOBJ)
    userMdl["activeProgramOBJ"] = programOBJ;
    userMdl['activeProgram'] = programOBJ.programKey
    userMdl['activeProgramID'] = programOBJ.id
    userMdl['activeBusinessAreaID'] = baOBJ.id
    userMdl['activeProgramName'] = programOBJ.programName;
    userMdl['businessAreaNameEn'] = baOBJ.businessAreaNameEn;
    userMdl['businessAreaKey'] = baOBJ.businessAreaKey;
    this.setState({
      activeProgramOBJ: programOBJ, activeProgram: programOBJ.programKey,
      activeBusinessAreaID: baOBJ.id,
      activeProgramName: programOBJ.programName,
      activeProgramID: programOBJ.id,
      businessAreaNameEn: baOBJ.businessAreaNameEn,
      businessAreaKey: baOBJ.businessAreaKey
    })
    this.props.dispatch(getAccessControlList(userMdl));
    $("ul.navbar-nav a").removeClass("selectedMenu");
    $("ul.navbar-nav a[href='/home'").addClass("selectedMenu");
    this.props.dispatch(push({ pathname: "/redirect" }));
  }



  createRoleDiv = () => {
    let roleDiv = [];
    let roleList = this.props.user.userMdl.roles;
    if (roleList) {
      if (roleList !== undefined) {
        for (var i = 0; i < roleList.length; i++) {
          roleDiv.push(<div key={i}>{roleList[i]}</div>);
        }
      }
      return roleDiv;
    }
  };

  onClickAllocate = () => {
    this.setState({ allocateFlag: !this.state.allocateFlag });
  }

  onClickAllocateSave = () => {
      let ceaCurrentYears = this.state.ceaCurrentYears
      this.setState({ allocateFlag: false,});
      let userMDL = {...this.props.user.userMdl}
      userMDL['ceaDefaultYears'] = {label : ceaCurrentYears.value, value : ceaCurrentYears.value};
      this.props.dispatch(updateUserMdl(userMDL, true));
      $("ul.navbar-nav a").removeClass("selectedMenu");
      $("ul.navbar-nav a[href='/home'").addClass("selectedMenu");
  }

  onCEAYearChange = (e)=>{
    if(e.value < new Date().getFullYear() && this.props.user.userMdl.activeProgram !== AppConst.activeProgramCea){
      this.setState({allocateFlag:true,ceaCurrentYears:e})
    }else{
      let userMDL = {...this.props.user.userMdl}
      userMDL['ceaDefaultYears'] = {label : e.value, value : e.value};
      this.props.dispatch(updateUserMdl(userMDL, true));
      $("ul.navbar-nav a").removeClass("selectedMenu");
      $("ul.navbar-nav a[href='/home'").addClass("selectedMenu");
    }
  }

  onNotificationsToggle = (checked)=>{
    console.log(checked);
    const userMdl = this.props.user.userMdl;
    userMdl["notificationsFlag"] = checked ? 1 : 0;
    this.props.dispatch(updateUserMdl(userMdl, false));
    this.props.dispatch(toggleNotifications(userMdl));
  }

  onThemeChange = (checked)=>{
    this.props.handler(checked === false ? 'dark' : 'default');
    const userMdl = this.props.user.userMdl;    
    userMdl["themeFlag"] = checked ? 1 : 0;
    this.props.dispatch(updateUserMdl(userMdl, false));
    this.props.dispatch(toggleTheme(userMdl));
  }

  render() {
    const navLinkClass = this.props.theme.name + "-nav-link nav-link";
    let accessControlList = this.props.user.userMdl.accessControl;
    var menuList = "";
    if (
      this.props.user.userMdl.activeProgram === AppConst.activeProgramEa &&
      (this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_Admin) ||
        this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin) ||
        this.props.user.userMdl.roles.includes(AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion)) &&
        (!this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_Admin) 
        && !this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_NMSC_Admin) &&
        !this.props.user.userMdl.roles.includes(AppConst.EA_ROLES.KET_EA_Dealer_Champion))
    ) {
      menuList = <ReachItMenu />;
    } 

    else if (
      accessControlList && accessControlList.filter(obj => obj.function.trim() === 'THE_AUDIT_DEALER_MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function.trim() === 'THE_AUDIT_DEALER_MENU')[0].accessType === "R/W" && this.props.user.userMdl.activeProgram === 'NAS'
    ) {
      menuList = <AuditDealerMenu />;
    }
    else if (
      accessControlList && accessControlList.filter(obj => obj.function.trim() === 'THE_AUDIT_MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function.trim() === 'THE_AUDIT_MENU')[0].accessType === "R/W" && this.props.user.userMdl.activeProgram === 'NAS'
    ) {
      menuList = <AuditMenu />;
    } 
    
    else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'TME ADMIN MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'TME ADMIN MENU')[0].accessType === "R/W"
    ) {
      menuList = <TmeAdminMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'TME INTERNAL MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'TME INTERNAL MENU')[0].accessType === "R/W"
    ) {
      menuList = <TmeInternalMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'NMSC ADMIN MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'NMSC ADMIN MENU')[0].accessType === "R/W"
    ) {
      menuList = <NmscAdminMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'NMSC INTERNAL MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'NMSC INTERNAL MENU')[0].accessType === "R/W"
    ) {
      menuList = <NmscInternalMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'NMSC FIELD MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'NMSC FIELD MENU')[0].accessType === "R/W"
    ) {
      menuList = <NmscFieldMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'NMSC EXTERNAL MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'NMSC EXTERNAL MENU')[0].accessType === "R/W"
    ) {
      menuList = <NmscExternalMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'DEALER KAIZEN CHAMPION MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DEALER KAIZEN CHAMPION MENU')[0].accessType === "R/W"
    ) {
      menuList = <DealerKaizenChampionMenu />;
    } else if (
      accessControlList && accessControlList.filter(obj => obj.function === 'DEALER INTERNAL MENU')[0] && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DEALER INTERNAL MENU')[0].accessType === "R/W"
    ) {
      menuList = <DealerInternalMenu />;
    }
    
    else {
      menuList = <CeaNetUcAdminMenu />;
    }


    var dynamicProgram = "";

    if (this.props.user.userMdl.businessAreasList) {

      let businessAreasDTOList = this.props.user.userMdl.businessAreasList;

      dynamicProgram = businessAreasDTOList.map(

        (baOBJ, index) => {
          return (<div key={index} className="programHeader">
            
            <b>{baOBJ.businessAreaKey === AppConst.businessAreaKeyPer ? I18n.t("ketProgram.per"):
             baOBJ.businessAreaName}</b>{
              baOBJ.programsDTOList.map((programOBJ, index) => {

                let font = "blackFont";
                if (this.state.activeProgramName === programOBJ.programName) {
                  font = "redFont";
                }
                
                //Performance buisness area have only Used car or network program condition
                let programName = true;
                if(programOBJ.programName === AppConst.programNameNet){
                  programName = this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) ||
                  this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_NMSC_User)
                  ? true : false 
                }
                if(programOBJ.programName === AppConst.programNameUc){
                  programName = this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin) ||
                  this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_NMSC_User) ||
                  this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_User) 
                  ? true : false 
                }
                if(programOBJ.programName === AppConst.programNameLN){
                  programName = this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin) ||
                  this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_NMSC_User) ||
                  this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_User)
                  ? true : false
                }

                if(programOBJ.programName === AppConst.programNameTSP){
                  programName = this.props.user.userMdl.roles.includes(AppConst.KET_TSP_AUDITOR) ||
                  this.props.user.userMdl.roles.includes(AppConst.KET_TME_ADMIN) ||
                  this.props.user.userMdl.roles.includes(AppConst.KET_NMSC_ADMIN) ||
                  this.props.user.userMdl.roles.includes(AppConst.KET_Dealer_Champion)
                  ? true : false
                }

                return ( 
                  programName &&
                  <DropdownItem
                    key={index}
                    href="/"
                    className={
                      "dd_item " +
                      "ket_program " +
                      "program_" +
                      index +
                      " " +
                      font
                    }
                    onClick={(e) => this.changeProgramOBJ(e, programOBJ, baOBJ)}
                  >
                    {programOBJ.programName === AppConst.programNameNet
                      ? I18n.t("ketProgram.nettrans")
                      : baOBJ.businessAreaKey === AppConst.businessAreaKeyEa
                      ?I18n.t("reachItDashboard.envMngt")
                      : programOBJ.programName}
                  </DropdownItem>
                );
              })
            }
          </div>)
        }
      )
    }
    
    return (
      <div className="container-fluid">
        <Navbar
          color={"white"}
          expand="sm"
          scrolling
          className="p-0"
          fixed="top"
        >
          <NavbarBrand className="p-0 mr-5 tp-nav-brname">
            <span style={{ width: "5em" }}>
              <img
                src={this.props.theme.logo}
                alt="logo"
                className="mr-3 tp-nav-divider"
              />
            </span>
            <strong>{I18n.t("TopNav.ApplicationName")}</strong>
          </NavbarBrand>
          {!this.state.isWideEnough && (
            <NavbarToggler className="fa fa-bars" onClick={this.onClick} />
          )}

          <Collapse isOpen={this.state.collapse} navbar>
            {menuList}
            <NavbarNav right>
              {this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? (
                <NavItem className="mr-2 program_container">
                  <div
                    className="col-md-12"
                    style={{ width: "150px", padding: "5px" }}
                  >
                    <Select
                      placeholder={I18n.t("others.select")}
                      value={this.props.user.userMdl.ceaDefaultYears}
                      onChange={this.onCEAYearChange}
                      options={this.state.getCEAYears}
                      // isClearable
                    />
                  </div>
                </NavItem>
              ) : null}

              <NavItem className="mr-2 program_container">
                <Dropdown>
                  <DropdownToggle nav className={""}>
                    <span
                      style={{ backgroundColor: this.props.theme.primaryColor }}
                      className={"program_text"}
                    >
                      {this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyEa
                        ? I18n.t("reachItDashboard.envMngt") :
                        this.state.activeProgramName === AppConst.programNameNet
                        ? I18n.t("ketProgram.nettrans")
                        : I18n.t("ketProgram." + this.state.activeProgramName)}
                    </span>
                    <span className="program_dots"></span>
                  </DropdownToggle>

                  <DropdownMenu right>{dynamicProgram}</DropdownMenu>
                </Dropdown>
              </NavItem>

              <NavItem className="mr-2">
                <Dropdown>
                  <DropdownToggle nav className={navLinkClass}>
                    <Fa icon="user-circle" fixed size="lg" />
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem className="dd_item">
                      <span className="user_name">
                        {this.props.user.userMdl.fullName}
                      </span>
                      <br />
                      <span className="user_role">{this.createRoleDiv()}</span>
                    </DropdownItem>
                    <DropdownItem divider className="divider" />
                    <div className="dropdown-item dd_item">
                      <img
                        src="images/Lexus2D.jpg"
                        alt="logo"
                        height="28px"
                        className="theme_logo"
                      />
                      <ThemeChanger
                        themeFlag={this.props.user.userMdl.themeFlag}
                        onThemeChange={this.onThemeChange} 
                      />
                      <img
                        src="images/TMEToyotaEllipse.png"
                        alt="logo"
                        height="28px"
                        className="theme_logo"
                      />

                    </div>
                    <div className="dropdown-item dd_item">
                      <img
                        src="images/no_bell.png"
                        alt="logo"
                        height="28px"
                        className="theme_logo"
                        style={{
                          paddingLeft: "inherit",
                          paddingRight: "2.5px",
                        }}
                      />
                      <NotificationsSwitch
                        notificationsFlag={
                          this.props.user.userMdl.notificationsFlag
                        }
                        onToggle={this.onNotificationsToggle}
                      />
                      <img
                        src="images/bell.png"
                        alt="logo"
                        height="28px"
                        className="theme_logo"
                        style={{ paddingLeft: "inherit" }}
                      />
                    </div>
                    {/*   <DropdownItem divider className="divider" />
                                        <DropdownItem href="/preferences" className="dd_item">
                                            <Fa icon="gear" fixed className={this.props.theme.iconColor} />
                                            User Preferences
                                        </DropdownItem> */}
                    <DropdownItem divider className="divider" />

                    {/* </Link> */}
                    <DropdownItem
                      href="/"
                      className="dd_item"
                      onClick={this.gdpr.bind(this)}
                    >
                      <Fa
                        icon="eye"
                        className={this.props.theme.iconColor}
                        fixed
                        size="lg"
                      />
                      GDPR
                    </DropdownItem>
                    {/* <Link to="/logout"> */}
                    <DropdownItem
                      href="/"
                      className="dd_item"
                      onClick={this.logOut.bind(this)}
                    >
                      <Fa
                        icon="sign-out"
                        className={this.props.theme.iconColor}
                        fixed
                        size="lg"
                      />
                      {I18n.t("TopNav.logout")}
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </NavItem>
            </NavbarNav>
          </Collapse>
        </Navbar>
        {this.state.showModal === true ? (
          <GDPR
            modal={this.state.modal}
            popUpClose={this.popUpClose}
            toggle={this.toggle}
            isOldUser={this.state.isOldUser}
            isLexus={this.props.isLexus}
          />
        ) : (
          <div />
        )}
         {this.state.allocateFlag ? 
            <AllocateWarning
                warningMsg = {AppConst.yearWarningMsg}
                allocateModalNo = {this.onClickAllocate}
                allocateModalYes= {this.onClickAllocateSave }
                confirmtxt = {AppConst.confirmtxt}
                cancelTxt = {AppConst.cancelTxt}
            />
        : ""}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.router
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(Header)));
