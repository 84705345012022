import { MDBContainer } from 'mdbreact';
import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { withTheme } from '../../themes/theming';
import './index.css';
import SearchFilter from './searchFilter';
import WidgetContainer from './widgetContainer';
import DealerWidgetContainer from './dealerWidgetContainer';
import BritDashboard from './britDashboard';
import { Fa } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import { getDownloadPreSignedUrlForBritVideo } from '../../actions/libraryAction';
var I18n = require("react-redux-i18n").I18n;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchFlag: false,
            selectedNmsc: [],
            selectedBrand: [],
            selectedCountry: [],
            selectedRegion: [],

            britVideoList: [
                { fileId: 1, fileName: "Localize KPI.mp4", filePath: "videos/Localize_KPI.mp4" },
                { fileId: 2, fileName: "KPI Download V2.mp4", filePath: "videos/KPI_Download_V2.mp4" },
                { fileId: 3, fileName: "Upload Rules.mp4", filePath: "videos/Upload_Rules.mp4" },
                { fileId: 4, fileName: "KPI Upload.mp4", filePath: "videos/KPI_Upload.mp4" },
                { fileId: 5, fileName: "Verify uploaded Details V2.mp4", filePath: "videos/Verify_Uploaded_Details_V2.mp4" },
                { fileId: 6, fileName: "User Management.mp4", filePath: "videos/User_Management.mp4" },
                { fileId: 7, fileName: "Search View Add Dealer.mp4", filePath: "videos/Search_View_Add_Dealer.mp4" },
                { fileId: 8, fileName: "Update Dealer.mp4", filePath: "videos/Update_Dealer.mp4" },
                { fileId: 9, fileName: "Delete Dealer.mp4", filePath: "videos/Delete_Dealer.mp4" },
                { fileId: 10, fileName: "Rounding Off & Others.mp4", filePath: "videos/Rounding_Off_Others.mp4" }




            ],
            video: [],
            playBritVideo: false,
        };
    }

    refresh(data) {
        // refreshWidgets(data) {

        console.log("Refresh Widgets!!!");
        // let filterData = {
        //     selectedNmsc: data.selectedNmsc,
        //     selectedBrand: data.selectedBrand,
        //     selectedCountry: data.selectedCountry,
        //     selectedRegion: data.selectedRegion
        // };
        this.props.history.push('/redirect', { 'filterData': this.props.filtersDashboard });
    }

    componentWillUpdate(nextProps) {
        /* if(nextProps.user.userMdl && nextProps.user.userMdl.activeProgram){
            this.setState({userMdl: nextProps.user.userMdl});
        } */
        // alert("Refresh Widgets");
    }
    toggleSearch = toggle => {
        this.setState({
            showSearchFlag: !this.state.showSearchFlag
        });
    };

    playVideo = (row) => {
        console.log("inside playVideo function")
        console.log("row details--", row.fileId, row.fileName, row.filePath)
        if (row.fileId) {
            console.log(row.fileId)
            this.setState({ video: row }, this.onCallback)

        }

    }
    downloadVideo = (row) => {
        console.log("Inside download video function");
        console.log("row details--", row.fileId, row.fileName, row.filePath)
        let fileDetail = {
            fileName: row.fileName,
            filePath: row.filePath,
        }
        this.props.dispatch(getDownloadPreSignedUrlForBritVideo(fileDetail));
    }

    onCallback = () => {
        console.log("Inside onCallback")
        this.setState({
            playBritVideo: !this.state.playBritVideo
        })
    }
    infoFormatter = (cell, row) => {
        // let linkIcon = 'eye';
        // const pack = <span>&nbsp;&nbsp;</span>
        return (
            <div className="btn-toolbar float-right">
                <Fa icon="play" fixed size="lg" title={I18n.t("britDashboard.playVideo")} onClick={() => this.playVideo(row)} >
                </Fa>
                {/* <Fa icon="download" fixed size="lg" title={I18n.t("britDashboard.download")} onClick={() => this.downloadVideo(row)}></Fa> */}


            </div >
        );
    }
    britVideo = () => {
        // console.log("In britVideo");
        const columns = [{
            dataField: "fileId",
            text: "id",
            title: true,
            hidden: true,
            style: {
                width: "10%", textAlign: "left", wordWrap: "break-word", paddingLeft: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellisis"
            },
            headerStyle: { width: "12%", textAlign: "left", paddingTop: "1rem" },
        }, {
            dataField: "fileName",
            text: "Video",
            title: true,
            style: {
                width: "12%", textAlign: "left", wordWrap: "break-word", paddingLeft: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellisis"
            },
            headerStyle: { width: "12%", textAlign: "left", paddingTop: "1rem" },
        },
        {
            dataField: "",
            text: "",
            title: true,
            style: { width: "5%", textAlign: "center", wordWrap: "break-word", },
            formatter: this.infoFormatter,
            headerStyle: {
                width: "5%",
                textAlign: "center",
                wordWrap: "break-word", paddingTop: "1rem"
            }
        }
        ]
        return (
            <React.Fragment>
                <div className="britVideoTable">
                    <div className="col-md-12 row control-label noPadding britVideoLabel">
                        <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                            {I18n.t("britDashboard.trainingVideos")}
                        </label>

                    </div>
                    <BootstrapTable

                        keyField='fileId'
                        data={this.state.britVideoList}
                        columns={columns}
                        hover
                        condensed
                        noDataIndication={I18n.t('britDashboard.noVideo')}
                    />
                </div>
            </React.Fragment>
        )
    }
    render() {
        let nmscList = [];
        let filtersDashboard = []
        if (this.props.user.userMdl.activeProgram === 'TSM') {
            filtersDashboard = this.props.Tsmfilters
        }
        else if (this.props.user.userMdl.activeProgram === 'TSM_BNP') {
            filtersDashboard = this.props.Bnpfilters

        } else if (this.props.user.userMdl.activeProgram === 'TSM_USED_CAR') {
            filtersDashboard = this.props.Usedcarfilters

        } else if (this.props.user.userMdl.activeProgram === 'TSM_SURVEYS') {
            filtersDashboard = this.props.tmeSurveysfilters

        }

        if (filtersDashboard.selectedNmsc && filtersDashboard.selectedNmsc.length > 0) {
            filtersDashboard.selectedNmsc.map((item) => {
                nmscList.push(item.value);
                return null;
            });
        }
        let brandList = [];
        if (filtersDashboard.selectedBrand && filtersDashboard.selectedBrand.length > 0) {
            filtersDashboard.selectedBrand.map((item) => {
                brandList.push(item.value);
                return null;
            });
        }
        let countryList = [];
        if (filtersDashboard.selectedCountry && filtersDashboard.selectedCountry.length > 0) {
            filtersDashboard.selectedCountry.map((item) => {
                countryList.push(item.value);
                return null;
            });
        }
        let regionList = [];
        if (filtersDashboard.selectedRegion && filtersDashboard.selectedRegion.length > 0) {
            filtersDashboard.selectedRegion.map((item) => {
                regionList.push(item.value);
                return null;
            });
        }

        var flagNmsc = false;

        //Check for length undefined
        var length;
        if (this.props.user.userMdl && this.props.user.userMdl.roles && this.props.user.userMdl.roles.length) {
            length = this.props.user.userMdl.roles.length;
        }
        for (let i = 0; i < length; i++) {
            if (this.props.user.userMdl.roles[i] === "KET NMSC Field" || this.props.user.userMdl.roles[i] === "KET NMSC External" ||
                this.props.user.userMdl.roles[i] === "KET NMSC User" || this.props.user.userMdl.roles[i] === "KET NMSC Admin") {
                flagNmsc = true;
            }
        }
        const nmscFlag = length > 0 && flagNmsc;

        let ifCEA = false;
        let ifNET = false;
        let ifUC = false;
        if (this.props && this.props.user && this.props.user.userMdl && this.props.user.userMdl.activeProgram) {
            let regex = new RegExp(/^CEA/)
            let regexNet = new RegExp(/^NET/)
            let regexUc = new RegExp(/^UC/)
            ifCEA = regex.test(this.props.user.userMdl.activeProgram.toUpperCase())
            ifNET = regexNet.test(this.props.user.userMdl.activeProgram.toUpperCase())
            ifUC = regexUc.test(this.props.user.userMdl.activeProgram.toUpperCase())
        }
        if (length > 0
            && ((this.props.user.userMdl.roles.filter(r => r === "KET TME Admin" || r === "KET TME User" || r === "TSM KET User").length > 0 ||
                nmscFlag) && this.props.user.userMdl.activeProgram.toUpperCase() !== 'BRIT' && !ifCEA && !ifNET && !ifUC)) {
            return (
                <MDBContainer className="mt-5 b-1" style={{ maxWidth: '100%', marginBottom: '3rem' }}>
                    {/* <SearchFilter filterProps={this.props.filterData} refreshWidgets={() => this.refreshWidgets()} /> */}
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                            {I18n.t("tmeAdminMaster.search")}
                        </label>
                        <label
                            md="0.5"
                            className="control-label boldFont"
                            style={{ marginTop: "0.2rem" }}
                        >
                            <a onClick={this.toggleSearch}>
                                {this.state.showSearchFlag ? (
                                    <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                                ) : (
                                    <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                                )}
                            </a>
                        </label>
                        {/* <label className="control-label" style={{ width: "92%" }} /> */}
                        <SearchFilter flag={this.state.showSearchFlag} filterProps={filtersDashboard} nmscFlag={nmscFlag} refreshWidgets={() => this.refreshWidgets()} userMdl={this.props.user.userMdl} />
                    </div>

                    <WidgetContainer
                        userMdl={this.props.user.userMdl}
                        nmscFilter={nmscList}
                        brandFilter={brandList}
                        countryFilter={countryList}
                        regionFilter={regionList}
                        nmscFlag={nmscFlag} />
                </MDBContainer >

            );
        } else if (length > 0
            && ((this.props.user.userMdl.roles.filter(r => r === "KET Dealer Champion" || r === "KET Dealer Internal").length > 0
            ) && !nmscFlag && this.props.user.userMdl.activeProgram.toUpperCase() !== 'BRIT')) {

            return (
                <MDBContainer className="mt-5 b-1" style={{ maxWidth: '100%', marginBottom: '3rem' }}>
                    <div className="col-md-12  control-label noPadding dealerDashboard">
                        <DealerWidgetContainer
                            userMdl={this.props.user.userMdl}
                            nmscFilter={nmscList}
                            brandFilter={brandList}
                            countryFilter={countryList}
                            regionFilter={regionList} />
                    </div>
                </MDBContainer >

            );
        }
        else if (length > 0 && this.props.user.userMdl.activeProgram.toUpperCase() === 'BRIT' && this.props.user.userMdl.flagBRiT) {
            // console.log("Brit program")
            // console.log(this.state)
            return (
                <div className="britDashboardTable">
                    {this.state.playBritVideo ? <BritDashboard className="britVideoPlayer" onToggle={this.onCallback}
                        video={this.state.video}
                        width="720"
                        height="420"
                    /> : this.britVideo()}
                </div>
            )
        }
        else {
            return (
                <div>

                </div>
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        Tsmfilters: state.dashboardReducer.Tsmfilters,
        Bnpfilters: state.dashboardReducer.Bnpfilters,
        Usedcarfilters: state.dashboardReducer.Usedcarfilters,
        tmeSurveysfilters: state.dashboardReducer.tmeSurveysfilters
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Dashboard)));
