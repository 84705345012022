export default function userManagementReducer(state = {
    userManagementDetails: null,
    dealerManagementDetails: null,
    searchTargetDetails: null,
    userFilters: {
        nmscIdList: [],
        tarsIdList: [],
        firstNameList: [],
        lastNameList: [],
        roleList: []
    },
    userSelectedFilters: {
        selectedNmscId: [],
        valueTarsId: "",
        valueFirstName: "",
        valueEmail:"",
        valueLastName: "",
        selectedRole: [],
        selectedStatus: [],
        selectedDownload: []
    },
    linkFilters: {},
    targetFilters: {},
    linkFilterSearch: {},
    userInfo: {},
    nmscList: [],
    countryList: []
}, action) {
    switch (action.type) {
        case "USER_SEARCH_FIELDS":
            return Object.assign({}, state, {
                userFilters: action.payload
            });
        case "USER_DETAILS":
            return Object.assign({}, state, {
                userManagementDetails: action.payload
            });
        case "USER_SELECTED_FILTERS":
            return Object.assign({}, state, {
                userSelectedFilters: action.payload
            });
        case "USER_INFO":
            return Object.assign({}, state, {
                userInfo: action.payload
            });

        case "NMSC_LIST":
            return Object.assign({}, state, {
                nmscList: action.payload
            });

        case "COUNTRY_LIST":
            return Object.assign({}, state, {
                countryList: action.payload
            });
        case "NMSC_DATA":
            return Object.assign({}, state, {
                linkFilters: { nmscCodeList: action.payload }
            });

        case "FRANCHISE_DATA":
            return Object.assign({}, state, {
                linkFilters: { franchiseList: action.payload }
            });
        case "COUNTRY_DATA":
            return Object.assign({}, state, {
                linkFilters: { countryList: action.payload }
            });
        case "L3_DATA":
            return Object.assign({}, state, {
                linkFilters: { l3List: action.payload }
            });
        case "L4_DATA":
            return Object.assign({}, state, {
                linkFilters: { l4List: action.payload }
            });
        case "L5_DATA":
            return Object.assign({}, state, {
                linkFilters: { l5List: action.payload }
            });
        case "L6_DATA":
            return Object.assign({}, state, {
                linkFilters: { l6List: action.payload }
            });
        case "DEALER_LIST":
            return Object.assign({}, state, {
                linkFilters: { dealerList: action.payload }
            });
            case "CITY_LIST":
                return Object.assign({}, state, {
                    linkFilters :{cityList :action.payload}
                    });
        case "FETCH_DEALER_REFRESH":
                return Object.assign({}, state, {
                    dealerManagementDetails: action.payload
                    }); 
        case "ASSIGN_DEALER_REFRESH":
            return Object.assign({}, state, {
                linkFilters: { isRefresh: action.payload }
            });
        case "DEALER_OBJ_LIST":
            return Object.assign({}, state, {
                dealerDetailsList: action.payload
            });
        case "SEARCH_FILTER_DATA":
            return Object.assign({}, state, {
                linkDealerFilters: action.payload
            });
        case "ONGOING_ACTIVITIES":
            return Object.assign({}, state, {
                isOngoingActivities: action.payload.ongoingActivities
            });

        case "NMSC_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { nmscCodeList: action.payload }
            });

        case "FRANCHISE_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { franchiseList: action.payload }
            });
        case "COUNTRY_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { countryList: action.payload }
            });
        case "L3_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { l3List: action.payload }
            });
        case "L4_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { l4List: action.payload }
            });
        case "L5_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { l5List: action.payload }
            });
        case "L6_DATA_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { l6List: action.payload }
            });
        case "DEALER_LIST_SEARCH":
            return Object.assign({}, state, {
                linkFilterSearch: { dealerList: action.payload }
            });
        case "DEALER_LANG":
            return Object.assign({}, state, {
                dealerLang:  action.payload 
            });
        case "NMSC_CODE_DATA":
            return Object.assign({}, state, {
                targetFilters: { nmscCodeList: action.payload }
            }); 
        case "KPI_BRAND_DATA":
            return Object.assign({}, state, {
                targetFilters: { brandList: action.payload }
            });
        case "KPI_STATUS_DATA":
            return Object.assign({}, state, {
                targetFilters: { statusList: action.payload }
            }); 
        case "TOOLBOX_DATA":
            return Object.assign({}, state, {
                targetFilters: { businessFunctionList: action.payload }
            });
        case "KPI_DATA":
            return Object.assign({}, state, {
                targetFilters: { kpiList: action.payload }
            }); 
        case "SUBPILLAR_DATA":
            return Object.assign({}, state, {
                targetFilters: { subPillarList: action.payload }
            });
        case "FETCH_TARGET_REFRESH":
            return Object.assign({}, state, {
                searchTargetDetails: action.payload
            });     
        case "KPI_EVALUATION_COMMENT":
            return Object.assign({}, state, {
                kpiEvaluationComment: action.payload
            });    
        case  "USER_EMAILS_LIST":
            return  Object.assign({}, state, {
                emailIdList: action.payload
            });                     
        default:
            return state;

    }
};