export default function libraryReducer(state = {
    userMdl: {}, blocking: false, refreshRequired: false, closeModal: false, fileList: []
}, action) {
    switch (action.type) {
        case "FOLDER_SAVED":
            return Object.assign({}, state, {
                refreshRequired: true,
                closeModal: true,
                folderId: action.payload.desc
            });
        case "REFRESH_DONE":
            return Object.assign({}, state, {
                refreshRequired: false
            });
        case "FOLDER_LIST":
            return Object.assign({}, state, {
                data: action.payload
            });
        case "NMSC_FOLDER_LIST":
            return Object.assign({}, state, {
                data: action.payload
            });
        case "FILE_LIST":
            return Object.assign({}, state, {
                fileList: action.payload
            });
        case "NMSC_FILE_LIST":
            return Object.assign({}, state, {
                fileList: action.payload
            });
        case "FILE_STATUS":
            return Object.assign({}, state, {
                fileStatus: action.payload
            });
        case "QUE_LIB_LINK":
            return Object.assign({}, state, {
                queLibLinks: action.payload
            });
        case "QUE_LIB_FILES":
            return Object.assign({}, state, {
                queLibFiles: action.payload
            });
        case "QUE_LIB_FOLDERS":
            return Object.assign({}, state, {
                queLibFolders: action.payload
            });
        case "LIBRARY_LIST_COUNT":
            return Object.assign({}, state, {
                libraryCount: action.payload
            });
        case "NMSC_LIBRARY_LIST_COUNT":
            return Object.assign({}, state, {
                libraryCount: action.payload
            });
        case "FILE_LIST_PUBLISHED":
            return Object.assign({}, state, {
                fileListPublished: action.payload
            });
        case "FOLDER_LIST_QUE":
            return Object.assign({}, state, {
                folderListLink: action.payload
            }); 
        case "NMSC_FOLDER_LIST_QUE":
            return Object.assign({}, state, {
                folderListLink: action.payload
            });  
        case "NMSC_FOLDER_SAVED":
            return Object.assign({}, state, {
                refreshRequired: true,
                closeModal: true,
                folderId: action.payload.desc
            });
        case "NMSC_FILE_STATUS":
            return Object.assign({}, state, {
                nmscFileStatus: action.payload
            });		    
        default:
            return state;
    }
};