const translationsES = {
  es: {
    Policy: {
      HeaderMsg: "Aviso de privacidad para el procesamiento de sus Datos Personales en el marco del Kaizen Expert Tool",
      Message1: "Este Aviso de privacidad se relaciona con el procesamiento de sus datos personales en el marco del Kaizen Expert Tool. Siempre se debe leer junto con la Política General de Privacidad y Protección de Datos Personales de Toyota, en la que se describen las modalidades generales del procesamiento de sus Datos Personales y que debe aceptar para poder continuar.",
      Message2: "Toyota Motor Europe NV / SA, con sede central en Avenue du Bourget / Bourgetlaan 60, 1140 Bruselas, Bélgica, como Controlador de Datos, recopila sus Datos Personales (como se indica a continuación) para los fines que se describen a continuación.",
      Message3: "¿Cuál de sus Datos Personales será recogido?",
      Message4: "Recopilaremos las siguientes categorías de sus Datos Personales con respecto a Kaizen Expert Tool:",
      Table1: {
        tr1: "Categorías de Datos Personales:",
        tr2: "ID del Usuario",
        tr3: "Nombre del Usuario (nombre completo)",
        tr4: "Marca (Toyota, Lexus)",
        tr5: "Idioma preferido",
        tr6: "Organización afiliada"
      },

      Message5: "Finalidad y bases legales del tratamiento de sus Datos Personales.",
      Message6: "Procesaremos sus Datos Personales para los fines y en las bases legales que se describen a continuación:",
      Table2: {
        tr1: {
          th1: "Propósito:",
          th2: "Bases legales:"
        },
        tr2: {
          td1: "KET- 1: Cumplimiento Kaizen del concesionario",
          td2: "Interés legítimo perseguido por el Controlador de Datos"
        },
        tr3: {
          td1: "KET- 2: Soporte IT",
          td2: "Interés legítimo perseguido por el Controlador de Datos"
        }
      },
      Message7: "Duración de la retención de sus datos personales.",
      Message8: "Your Personal Data will be kept for the duration indicated below:",
      Table3: {
        tr1: {
          th1: "Periodo de retención:",
          th2: "Categorías de Datos Personales:"
        },
        tr2: {
          td1: "Toda la información personal descrita anteriormente se conservará durante un máximo de 5 años + 1 mes a partir del día de cierre final del Kaizen Expert Tool.",
          td2: "User first name, Last name, Language preference, organisation"
        }
      },
      Message9: "Opcional: en caso de divulgación / transferencia de sus datos personales a países fuera del Espacio Económico Europeo (EEE)",
      Message10: "Sus datos personales se harán accesibles a los destinatarios en países fuera del EEE como se indica a continuación:",
      Table4: {
        tr1: {
          td1: "Identidad",
          td2: "País",
          td3: ""
        },
        tr2: {
          td1: "Destinatarios no pertenecientes al EEE",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Por la presente confirmo haber entendido este aviso; He leído y acepto la Política General de Privacidad y Protección de Datos Personales de Toyota."
    },
    ACCESSORIES: "Accesorios",
    activity: {
      Assignee: "Receptor",
      CompletionDate: "Fecha de finalización",
      Dealer: "Concesionario",
      FirstVisit: "Primera visita",
      kpi: {
        kpi: "Los KPI deberían ser números",
        kpiLength: "La longitud máxima para el KPI es de 10 caracteres",
        mandatory: "Introduzca una fecha válida en todos los campos obligatorios",
        mandatoryTargets: "Por favor inserte todos los KPI's",
        target: "El objetivo debería ser un número",
        targetLength: "La longitud máxima para el objetivo es de 10 caracteres"
      },
      ReminderOn: "Recordatorio sobre",
      statusDD: "Estado",
      Toolbox: "Toolbox",
      activityCreation: "Se ha iniciado el proceso de creación de una actividad",
      activityCreated: "Se ha creado la(s) actividad(es)"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Eficiencia",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Campos obligatorios"
    },
    FOUNDATION: "Fundamentos",
    MAINTENANCE_REMINDERS: "MRS",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fundamentos",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Abr.",
      aug: "Ago.",
      dec: "Dic.",
      feb: "Feb.",
      jan: "Ene.",
      jul: "Jul.",
      jun: "Jun.",
      mar: "Mar.",
      may: "Mayo",
      nov: "Nov.",
      oct: "Oct.",
      sep: "Sep."
    },
    others: {
      back: "Atrás",
      closed: "Cerrada",
      completed: "Finalizada ",
      countryLanguageCode: "Código de país/idioma ",
      createActivity: "Crear actividad",
      createdDate: "Fecha de creación",
      dataUneditableNote:
        "Nota: Una vez guardados, los datos no pueden editarse. Para modificar los datos, póngase en contacto con el administrador de NMSC.",
      date: "Fecha",
      downloadQuestionnaire: "Descargar cuestionario",
      editActivity: "Editar actividad",
      initialHelpText:
        "La medición del KPI inicial debería ser el valor promedio de los seis meses previos completos",
      inProgress: "En progreso",
      name: "Nombre",
      next: "Siguiente",
      noActivities: "No se han encontrado actividades",
      noResults: "No hay Resultados",
      of: "de",
      ongoing: "En curso",
      open: "Abrir",
      page: "Página",
      planned: "Planificada",
      problemDescription: "Descripción del problema",
      signature: "Firma",
      select: "Seleccione",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Ha ocurrido un error durante la descarga",
      no_published_subquestion: "Por favor, publique el Cuestionairo para este toolbox para comenzar la Actividad.",
      activity_upload_error_msg: "Error al cargar los detalles de la actividad.",
      action_plan_created: "Plan de acción creado satisfactoriamente",
      action_plan_deleted: "Plan de acción borrado satisfactoriamente",
      action_plan_saved: "Plan de acción grabado satisfactoriamente",
      activity_kpi_saved: "Actividad KPI's grabada satisfactoriamente",
      master_kpi_confirm_delete: "¿Está Vd. seguro que quiere borrar el KPI seleccionado",
      master_kpi_created: "El KPI maestro se ha creado correctamente",
      master_kpi_deleted: "El KPI maestro se ha eliminado",
      master_kpi_not_found: "No se ha encontrado el KPI maestro",
      master_kpi_published: "Los KPI maestros seleccionados se han publicado correctamente",
      master_kpi_retired: "El KPI maestro se ha retirado",
      master_kpi_saved: "El KPI maestro se ha guardado correctamente",
      subquestion_created: "La subpregunta se ha guardado correctamente.",
      subquestion_updated: "La subpregunta se ha actualizado correctamente.",
      subquestionnaire_not_found: "La subpregunta no ha sido encontrada.",
      subquestion_retired: "La subpregunta ha ha sido retirada.",
      subquestion_deleted: "La subpregunta ha ha sido eliminada.",
      subquestion_published: "The sub-questions have been successfully published.",
      mainquestion_created: "The Main Questionnaire has been created.",
      mainquestion_deleted: "The Main Questionnaire has been deleted.",
      mainquestion_updated: "The Main question has been updated.",
      duplicateMsg: "Duplicar registros en el archivo excel.",
      deleteErr: "Error al borrar el archivo",
      toolbox_deleted: "El toolbox ha sido borrado con éxito",
      toolbox_already_exists: "Este toolbox ya existe",
      toolbox_saved: "Toolbox guardado",
      toolbox_created: "Toolbox creado",
      toolbox_not_found: "Toolbox no encontrado",
      published_content_found: "Este toolbox no puede ser borrado porque tiene contenido publicado",
      dealers_already_mapped: " Los concesionarios seleccionados han sido asignados a este usuario ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Añadir concesionario. ",
      dealers_added: "  Añadir concesionario. ",
      total_dealers_mapped_now: "El número total de concesionarios asignados actulamente a este usuario es. ",
      no_dealer_available: "No hay conceionario(s) disponibles para los criterios seleccionados",
      proper_dealer_selected: "Seleccione un concesionario adecuado.",
      user_referenced_ongoing_activity: "El usuario esta asignado actualmente a actividades en curso. Por favor reemplace a al asignado en estas actividades e inténtelo de nuevo. ",
      want_to_remove_mapping: "¿Está seguro que quiere eliminar la asignación de este usuario a estos concesionarios?",
      user_mapping_removed: "Los concesionarios seleccionados se han eliminado correctamente de la asignación del usuario",
      dealer_closed_in_A2D: "El concesionario está registrado como 'cerrado' en A2D",
      network_level_not_found: "Seleccione una combinación válida de niveles de red de acuerdo con su configuración de red en KET.",
      duplicate_dealer_uuid: "Este concesionario ya existe en la base de datos de KET",
      dealer_created: "Concesionario creado",
      dealer_updated: "Los atributos de este concesionario se han actualizado",
      dealer_not_present: "El concesionario no está registrado en A2D",
      dealer_uuid_other_nmsc: "El código A2D uuid seleccionado no está disponible en su red",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "¿Está seguro que quiere borrar?",
      addActionPlanNotAllowed: "El Plan de Acción no puede crearse o editarse después de que la Actividad haya sido cerrada, completada o rechazada.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "¿Está seguro que quiere rechazar esta actividad? No se podrán hacer cambios después de rechazarla ",
      rejectMsg: "¿Está seguro que quiere rechazar esta actividad? No se podrán hacer cambios después de rechazarla ",
      selectNMSCTab: "Por favor elija el idioma del NMSC para publicar",
      all: "Todo",
      deleteConfirm: "¿Seguro que desea eliminar el KPI seleccionado?",
      no: "No",
      retireConfirm:
        "¿Seguro que desea retirar este KPI? Una vez retirado, este KPI no se podrá reactivar",
      warning: "Advertencia",
      yes: "Sí",
      saveDataConfirmation: "¿Está seguro de que quiere guardar los datos?",
      saveKPIWarning: "Nota: Los datos no se podrán editar una vez que se hayan guardado. Para modificar los datos, póngase en contacto con el administrador de KET de la NMSC",
      addKpiNotAllowed: "Los KPI's para el próximo mes podrán ser añadidos cuando acabe el mes actual.",
      activityLaunch: "¿Está Vd. seguro que quiere lanzar esta Actividad? Después del lanzamiento la Actividad no podrá volver al estado Planificado o Eliminada.",
      deleteConfirmSubQuestionNaire: "Are you sure you want to delete the selected sub-question?",
      retireConfirmSubQuestionNaire: "Are you sure you want to retire the selected sub-question?",
      deleteConfirmMainQuestion: "Please confirm if you want to delete the Main question and its related sub-questions.",
      deleteConfirmChapter: "Are you sure you want to delete the chapter ?",
      deleteLibraryFolder: "Are you sure you want to delete this folder ?",
      deletePublishedFolder: "Algunos archivos dentro de esta carpeta pueden estar vinculados al cuestionario. ¿Está seguro de que desea eliminar permanentemente esta subcarpeta y eliminar los enlaces del Cuestionario?",
      importQuestion: "Are you sure you want to import this sub-question? The current sub-question will be replaced.",
      noQuestion: "No Questions available.",
      MandatoryWarning: "Por favor, complete todos los campos obligatorios para todos los idiomas locales.",
      certificationSubmission:
        "El tooblbox ha sido enviado al TME para la certificación del concesionario",
      sameToolBox: "No puede enviar el mismo toolbox más de una vez . Por favor elija un toolbox distinto.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Todas las actividades creadas para este concesionario en \"Value chain\" van a ser eliminadas. ¿Está seguro de que quiere continuar?",
      updateBritConfirmDealer: "Todas las actividades creadas para este concesionario en \"BRiT\" van a ser eliminadas. ¿Está seguro de que quiere continuar?",
      completed_activity_delete:"Los datos no se podrán recuperar si elimina la actividad. ¿Está seguro de que quiere eliminar esta actividad?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Seleccionar todo",
      unSelectAll: "Deseleccionar todo",
      select:"Seleccionar...",
      warningMsg:"Hay acciones en curso en este plan de acción. ¿Seguro que quiere completar la actividad?",
      updateEaConfirmDealer: "Todas las actividades creadas para este concesionario en \"EA\" van a ser eliminadas. ¿Está seguro de que quiere continuar?"

    },
    status: {
      published: "Publicado",
      retired: "Retirado",
      saved: "Guardado",
      rejected: "Rechazado",
      open: "Abrir",
      closed: "Cerrada",
      close: "Cerrada",
      ongoing: "En curso",
      completed: "Finalizada",
      complete: "Finalizada",
      planned: "Planificada",
      overdue: "Atrasado",
      due: "Pendiente",
      pending: "Pending",
    },
    Rejected: "Rechazado",
    Open: "Abrir",
    Closed: "Cerrada",
    Close: "Cerrada",
    Ongoing: "En curso",
    Completed: "Finalizada",
    Complete: "Finalizada",
    Planned: "Planificada",
    tabDetails: {
      noActionAvailable: "No hay acciones disponibles",
      activeDealer: "Este concesionario está registrado actualmente como activo en A2D y no se puede eliminar.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Actividad  ",
      activityCompletionDate: "Fecha de finalización de la actividad",
      activityCompletionDateFrom: "Fecha de finalización de la actividad desde",
      activityCompletionDateTo: "Fecha de finalización de la actividad hasta",
      activityStartDate: "Fecha de inicio de la actividad",
      activityStartDateFrom: "Fecha de inicio de la actividad desde",
      activityStartDateTo: "Fecha de inicio de la actividad hasta",
      assignedTo: "Asignado a",
      assignee: "Receptor",
      benchmark: "Indicador",
      city: "Ciudad",
      completedBy: "Completado por ",
      completionBy: "Finalizado por",
      completionDate: "Fecha de finalización",
      counterMeasure: "Counter Measure",
      country: "País",
      createdBy: "Creado por",
      dealer: "Concesionario",
      dealerNameAD: "Nombre del concesionario (A2D)",
      dealerName: "Nombre del concesionario",
      definition: "Definición",
      description: "Descripción",
      dueDate: "Fecha límite",
      endDate: "Fecha de finalización",
      firstVisit: "Primera visita",
      followup: "Seguimiento",
      franchise: "Franquicia",
      history: "Historia",
      id: "ID",
      initial: "Inicial",
      kpi: "KPI",
      launch: "Iniciar",
      mandatory: "Obligatorio",
      nmsc: "NMSC",
      problem: "Problema",
      publishedOn: "Fecha de publicación",
      questionReference: "Pregunta de referencia",
      reference: "Referencia",
      reminderOn: "Recordatorio sobre",
      score: "Puntuación",
      startDate: "Fecha de inicio",
      status: "Estado",
      target: "Target",
      toolbox: "Toolbox",
      view: "Ver",
      kpiResult: "Resultados KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Ubicación",
      ActionItemReport: "Acciones en progreso",
      certification: "Estado de certificación",
      noOfToolbox: "Número de toolboxes completados",
      program: "Programa",
      certificationDate: "Fecha certificada",
      activityId : "Identificación de la actividad",
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan de acción",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
    tabsDetails: {
      actionPlan: "Plan de acción",
      confirmDeleteMessage: "¿Seguro que desea eliminar el KPI seleccionado?",
      confirmRetireMessage: "Are you sure you want to retire (remove) the selected published KPI?",
      errorRetireMsg: "This KPI cannot be retired as it's an active TME mandatory KPI",
      import: "Importar",
      importKpiMessage: "Importe su KPI desde la lista de KPI maestros de TME",
      kpi: "Indicadores de rendimiento clave",
      library: "Biblioteca",
      noActionAvailable: "No hay acciones disponibles",
      NoKPI: "No hay KPI disponibles",
      publish: "Publicar",
      questionnaire: "Cuestionario",
      tmeKPIList: "Lista de KPI maestros de TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nombre del toolbox",
      newToolboxName: "Nuevo nombre",
      deleteToolbox: "¿Borrar toolbox?",
      programName: "Nombre del programa",
      ToolboxName_EN: "Nombre del toolbox (EN)",
      deleteTool: "¿Está seguro que quiere borrar este toolbox?",
      selectToolbox: "Por favor, seleccione un toolbox para borrar",
      editToolBox: "Editar toolbox",
      addToolbox: "Añadir toolbox",
      toolbox: "Por favor, introduzca un nombre para este toolbox",
      addActionPlan: "Add Plan de acción",
      actionPlanItem: "Plan de acción - Núm. de elemento",
      add: "Añadir",
      addActivity: "Añadir actividad",
      additionalInformation: "Información adicional",
      benchmark: "Indicador",
      cancel: "Cancelar",
      createEditActivity: "Crear/editar actividad",
      createKPI: "Crear KPI",
      delete: "Eliminar",
      download: "Descargar",
      edit: "Editar",
      editKPI: "Editar KPI",
      keyPerformanceIndicator: "Indicador de rendimiento clave",
      kpiDefinition: "Definición del KPI",
      mandatoryKpi: "KPI obligatorio",
      save: "Guardar",
      search: "Buscar",
      unit: "Unidad",
      update: "Actualizar",
      validData: "Introduzca datos válidos",
      hide: "Esconder",
      show: "Mostrar",
      upload_dealer_network: "Subir Red de Concesionarios",
      upload_question: "Subir Cuestionario",
      upload_user: "Subir Detalles del Usuario",
      upload_activity: "Subir Actividades",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"La fecha de límite ha pasado",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Por favor, responda todas las preguntas para poder cerrar esta Actividad",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Por favor complete el valor inicial de todos los KPIs, los objectivos y añada al menos un mes de KPIs para continuar.",
      initialTarget: "Por favor, introduzca el KPI inicial y el KPI objetivo para continuar",
      upload_error: "Error al cargar la red de concesionarios.",
      action_item_create_warning: "Como máximo se permiten 5 grabaciones para fusionarse en un único elemento de acción.",
      actionPlanStartDateValidation: "La fecha de finalización no puede ser anterior a la fecha de inicio",
      actionsNotClosed: "Hay acciones en curso en el plan de acción. Cierre todas las acciones antes de cerrar cualquier actividad",
      activityDuplicate: "AYa existe otra actividad para el mismo concesionario y la misma Toolbox",
      assigneeNotExist: "El cesionario seleccionado no se ha registrado en el sistema",
      assigneeValidation: "Beneficiario no válido",
      atleastOnePublish: "Seleccione al menos un KPI para su publicación",
      cannotRetireActive: "Este es un KPI obligatorio de TME activo que no se puede retirar",
      completionDate: "La fecha de término no puede ser anterior a la primera fecha de visita",
      confirDeletePlannedActivity: "¿Seguro que desea eliminar esta actividad planificada?",
      DateformatValidation: "La fecha tiene que estar en el formato DD/MM/AAAA",
      DateValidation: "La fecha de inicio tiene que ser anterior a la de fin",
      dealerValidation: "Concesionario no válido",
      deleteSelectedConfirm: "¿Seguro que desea eliminar el KPI seleccionado?",
      duplicate: "Ya existe otro KPI con el mismo nombre y la misma definición",
      modalMandatory: "Introduzca una fecha válida en todos los campos obligatorios",
      noPublishKpi: "Los KPI todavía no se han publicado para la Toolbox seleccionada",
      validSearchCriteria: "Introduzca un criterio de búsqueda válido",
      multiSelectPlaceHolder: "Introduzca los últimos 5 caracteres",
      multiSelectPlaceHolderUser: "Introduzca los últimos 3 caracteres",
      startDateValidation:
        "La fecha de inicio ",
      fileSelectionErr: "Por favor, selecciones el archivo primero",
      atleastOneQuestion: "Por favor seleccione al menos una sub-pregunta para importar.",
      atleastOneSubQues: "Por favor elija al menos una sub-pregunta para publicar",
      uploadErr: "Número de archivos que no se cargan:",
      uploadSuccess: "Red de concesionarios cargada correctamente.",
      file_upload_success: "Los archivos se han subido con éxito.",
      KPIinOngoingClosedActivity: "Este KPI está presente en una o más actividades cerradas o en curso. Por favor complete estas actividades anted fe hacer cambios en el KPI.",
      Role_Error: "La configuración del usuario está incompleta, comuníquese con el administrador de KET del NMSC.",
      points_allocation_incorrect: "Asignación de puntos incorrecta, modifíquela por favor.",
      activity_deleted:"La actividad ha sido eliminada",
      activity_certified_dealer:"El concesionario ha sido certificado para la actividad seleccionada, nose puede eliminar",
      activity_submited_for_certificate_dealer:"Esta actividad ha sido incluída en la demanda de certificación envíada a TME,  por lo tanto no se puede cambiar su estado"
    },
    TopNav: {
      ActivityLog: "Actividades",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Inicio",
      logout: "Desconectar",
      MasterDataUpload: "Cargar datos maestros",
      Toolbox: "Toolbox",
      welcome: "Bienvenida",
      Admin: "Administración"
    },
    questionnaire: {
      addSubQuestion: "Añadir subpregunta",
      editSubQuestion: "Editar subpregunta",
      subQuestionTitle: "Subpregunta",
      additionalInfo: "Información adicional",
      na: "Indique 'No aplicable' como una posible respuesta para esta pregunta",
      question: "Pregunta",
      questionTitle: "Título de la pregunta",
      why: "¿Por qué?",
      addMainQuestion: "Añadir pregunta principal",
      editMainQuestion: "Editar pregunta principal",
      previousQuestion: "Pregunta previa",
      nextQuestion: "Próxima pregunta",
      what: "¿Qué?",
      library: "Biblioteca",
      subQuestionTable: {
        no: "No.",
        subQuestion: "Subpregunta",
        status: "Estado",
        publishedDate: "Fecha de publicación",
        notApplicable: "No aplicable"
      },
      links: {
        question_link_button: "Link",
        question_link_include_folder: "Incluir una subcarpeta",
        question_link_add_link: "Enlace de archivo(s) de la biblioteca",
        question_link_view_folder: "Ver Librería"
      },
      mainQuestion: "Preguntas principales nuevas / actualizadas:",
      subQuestion: "Subreguntas principales nuevas / actualizadas:",
      retiredQuestion: "Subpreguntas retiradas",
      libraries: "Archivos nuevos / actualizados en las bibliotecas:",
      noFiles: "No hay archivos disponibles"
    },
    library: {
      selectFile: "Por favor seleccione al menos un archivo.",
      fileSelected: "Archivos seleccionados",
      publish_library_file: "Por favor, selleciones al menos un archivo para publicar",
      library_files_deleted: "Está seguro de que quiere borrar este archivo?",
      library_files_linked_deleted: "¿Está seguro que quiere borrar este archivo y los enlaces creados en el Cuestionario",
      max_size_error: "El tamaño de los archivos excede el límite de 1GB por subida.",
      empty_file_desc: "Por favor, indroduzca un archivo de descripción.",
      file_not_selected: "Por favor, seleccione el archivo para subir (max. 1GB)",
      file_type_error: "This file type is not allowed.",
      file_name_error: "Invalid character in the file name.",
      file_name_lenght_error: "The file name can have maximum 50 characters.",
      addFolder: "Add Folder",
      editFolder: "Edit Folder",
      currentFolder: "Folder Name",
      parentFolder: "Parent Folder Name",
      emptyFolderName: "El nombre de la carpeta no puede estar vacío.",
      invalidFolderName: "El nombre de la carpeta no puede contener " / "",
      folder_created: "The Folder has been succesfully created.",
      folder_updated: "The Folder has been successfully updated.",
      folder_not_found: "Folder not found",
      lib_invalid_toolbox: "Invalid Toolbox selected",
      lib_folder_already_exists: "This Folder already exists.",
      lib_folder_deleted: "The Folder has been successfully deleted.",
      library_files_published: "The files have been Published.",
      addFile: "Add File",
      editFile: "Edit File",
      fileName: "File name",
      upload: "Upload",
      desc: "Description",
      folderCreationErr: "Jerarquía limitada a dos niveles.",
      subFolderWarning: "Por favor, seleccione una subcarpeta para agregar archivos.",
      deleteWarning: "Por favor, seleccione una carpeta para eliminar.",
      beforeAfterNotSelected: "Por favor seleccione si esta evidencia es de antes o después del kaizen",
    },
    chapter: {
      chapter_exist: "Este capítulo ya existe",
      chapter_saved: "Capítulo guardado",
      chapter_cannot_delete: "This Chapter cannot be deleted as sub-questions have already been published.",
      failed: "Failed !",
      chapter_deleted: "Chapter Deleted",
      chapter_nonEmpty: "Por favor, cree un nombre de capítulo.",
      addchapter: "Agregar capitulo",
      editChapter: "Editar capítulo",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Archivos adjuntos",
      comments: "Comentarios",
      evidence: "Evidencias",
      evidences: "Evidencia(s)",
      yes: "Sí",
      no: "No",
      na: "N/A",
      uploadEvidence: "Subir evidencia",
      fileUpload: "Por favor, seleccione el archivo para subir (max. 1GB)",
      who: "¿Quién?",
      answer: "Respuesta",
      comment: "Comment",
      date: "Fecha",
      submit: "Enviar",
      commentMsg: "Por favor, introduzca comentarios",
      noCommentAvailable: "No hay comentarios disponibles",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload: ",
      fileWithSameName: "Ya existe un archivo con el mismo nombre",
      noEvidence: "No hay pruebas disponibles",
      createAction: "Crear elemento de acción",
      chapterName: "Nombre del capítulo",
      questionNo: "Pregunta Nº",
      when: "¿Cuándo?",
      noRecords: "No hay registros disponibles",
      kaizenPoints: "Puntos Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Pregunta",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Esta evidencia es de antes o después del kaizen.",
      move:"Mover",
      moveMsg: "El archivo se ha movido con éxito",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Recepción interactiva",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Used Cars",
      value_chain: "Value chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Tablero de Noticias",
      kpi_target_not_set: "Objetivos del KPI no establecidos",
      kpi_initial_not_set: "Medidas iniciales del KPI no establecidas",
      kpi_monthly_not_set: "Medidas mensuales del KPI no establecidas",
      kpi_below_target: "KPI por debajo del objetivo",
      activation: "Activación",
      users: "Usuarios",
      nmscs: "NMSC's",
      activeRetailer: "Concesionarios activos",
      certificationRate: "Ratio de certificación",
      reCertificationRate: "Ratio de re certificación",
      activityDue: "Actividades pendientes",
      avgdays: "Promedio de días por toolbox",
      overDueAct: "Actividades atrasadas",
      actionItem: "Acciones",
      bussResult: "Resultados de negocio",
      toolboxImpl: "Implementación de toolbox",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Actualizaciones recientes",
      rolloutTopNmsc: "Resultados de implementación - Top NMSC's",
      bestPractice: "Tablero de Buenas Prácticas",
      availableKpi: "KPI's disponibles",
      selectedKpi: "KPI's seleccionados",
      dealerBestPractice: "Tablero de Best practices",
      kpiList: "Lista de KPI's",
      go: "Ir",
      nodata: "No hay datos disponibles",
      businessResultTop: "Resultados de negocio - Top Concesionarios",
      businessResultNMSC: "Resultados de negocio - Top NMSC's"


    },
    dealerCertification: {
      dealerCertification: "Certificación del Concesionario",
      readyForCertification: "Listo para certificación",
      certification: "Certificación del Concesionario",
      submittedOn: "Enviado el ",
      certificateDate: "Fecha de Certificado",
      reCertificateDate: "Fecha de Re Certificado",
      toolboxDetails: "Detalles del toolbox",
      approve: "Aprobar",
      reject: "Rechazar",
      pending: "Pendiente",
      region: "Región"



    },
    uploadBulk: {
      browse: "Browse_es",
      executionId: "Execution Id_es",
      uploadedOn: "Uploaded On_es",
      lastUpdatedOn: "Last updated on_es",
      noDataTable: "No data to view_es",
      kpiUpload: "KPI Upload_es",
      action: "Action_es",
      processed: "Processed_es",
      error: "Error_es",
      received: "Received_es",
      createActivity: "Create Activities_es",
      fileUpload: "File Upload_es",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Administración de usuarios",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Nombre",
      lastName: "Apellido",
      emailId: "e-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Cuenta de PowerBI",
      invalidPbiAccount : "Lacuenta de PowerBI tiene que tener al menos 3 caracteres",
      businessArea: "Area de negocio",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Solo Value Chain",
      britOnly: "Solo BRiT",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Solo",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Nombre",
      lastName: "Apellido",
      emailId: "e-mail",
      languageCode: "Código del idioma",
      ACTIVE: "Active",
      INACTIVE: "Inactivo",
      addUser: "Añadir usuario",
      Assign: "Asignar",
      List: "Lista",
      user: "Usuario",
      error_message: "Ha ocurrido un error, por favor inténtelo más tarde",
      role: "Rol del usuario",
      standardMessage: "No User Found",
      dealerCode: "Código del concesionario",
      a2d: "A2D UUID",
      britReady: "El concesionario ya participó en Brit Ready.",
      britLive: "El concesionario ya participó en Brit live",
      britJapan: "El concesionario ya participó en Brit Discvery Japan.",
      britEss:"El concesionario ha actualizado el plan de acción de la EES (encuesta de compromiso de empleados)",
      addDealer: "Añadir concesionario (desde A2D)",
      noDealer: "Ningún concesionario ha sido encontrado",
      dealerUuid: "Código uuid del concesionario (A2D)",
      language: "Idioma",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Por favor introduzca un A2D uuid válido",
      invalidDealerName: "Por favor introduzca un nombre de concesionario válido",
      invalidCity: "Por favor introduzca una localización válida",
      invaliDealerCode: "Por favor introduzca un código de concesionario válido",
      existingUuid: "El código A2D uuid ya existe en la base de datos de KET",
      updateUuid: "Actualizar el código A2D uuid",
      newUuid: "Nuevo código A2D uuid ",
    },
    networkManagement: {
      networkManagement: "Administración de la red",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Descargas",
      historyFiles:"Histórico de archivos anteriores",
      reachText1:"Bienvenido a la página de REACH IT. El equio de Gestion Medioambiental de TME utilizará esta página para compartir información relevante relacionada con SCIP (Substances of Concern in Products): Sustancias preocupantes contenidas en artículos o en objetivos complejos (productos) establecidos en virtud de la Directiva Marco sobre Residuos, para que pueda cumplir con el deber de tramitar con su notificación simplificada SCIP.",
      reachText2:"En caso de consulta, comuníquese con el equipo de Gestión Medioambiental de TME a través del siguiente enlace.",
      reachText3:"En caso de consulta, por favor contacte con su NMSC.",
      envMngt:"Gestión Ambiental",
      fileStatistics: "Estadísticas descargadas del archivo",
      Downloaded: "Descargado",
      not: "No",
      exportToExcel:"Exportar a Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsES;
