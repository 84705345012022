import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./index.css";
var I18n = require("react-redux-i18n").I18n;

class GDPR extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      btnDisabled: true,
      isChecked: false
    };
  }
  componentWillMount() { }

  onChangeCheckBox = () => {
    this.state.isChecked = !this.state.isChecked;
    this.setState({ btnDisabled: !this.state.btnDisabled });
  };

  render() {
    return (
      <span className="gdpr-modal-container">
        <Modal className="modal-dialog-gdpr"
          isOpen={this.props.modal}
          toggle={() => this.props.preventDefault()}
        >
          <ModalHeader
            className="modal-header-gdpr"
            toggle={
              this.props.isOldUser ? event => this.props.toggle(event) : null
            }
          >
            <div className="modal-title-gdpr">
              <img
                src={
                  this.props.isLexus
                    ? "images/Lexus2D.jpg"
                    : "images/TMEToyotaEllipse.png"
                }
                alt="logo"
                height="40px"
                className="theme_log"
              />
              {I18n.t("Policy.HeaderMsg")}
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="modal-body-gdpr">
              <div>{I18n.t("Policy.Message1")}</div>
              <br />
              <div>{I18n.t("Policy.Message2")}</div>
              <br />
              <div>
                <b>{I18n.t("Policy.Message3")}</b>
              </div>
              <div>{I18n.t("Policy.Message4")}</div>
              <br />
              <div>
                <table>
                  <tbody>
                    <b>
                      <tr>{I18n.t("Policy.Table1.tr1")}</tr>
                    </b>
                    <tr>{I18n.t("Policy.Table1.tr2")}</tr>
                    <tr>{I18n.t("Policy.Table1.tr3")}</tr>
                    <tr>{I18n.t("Policy.Table1.tr4")}</tr>
                    <tr>{I18n.t("Policy.Table1.tr5")}</tr>
                    <tr>{I18n.t("Policy.Table1.tr6")}</tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <b> {I18n.t("Policy.Message5")}</b>
              </div>
              <div>{I18n.t("Policy.Message6")}</div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>{I18n.t("Policy.Table2.tr1.th1")}</th>
                      <th>{I18n.t("Policy.Table2.tr1.th2")}</th>
                    </tr>
                    <tr>
                      <td>{I18n.t("Policy.Table2.tr2.td1")}&nbsp;&nbsp;&nbsp;</td>
                      <td>{I18n.t("Policy.Table2.tr2.td2")}</td>
                    </tr>
                    <tr>
                      <td>{I18n.t("Policy.Table2.tr3.td1")}</td>
                      <td>{I18n.t("Policy.Table2.tr3.td2")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <b>{I18n.t("Policy.Message7")}</b>
              </div>
              <div>{I18n.t("Policy.Message8")}</div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>{I18n.t("Policy.Table3.tr1.th1")}</th>
                      <th>{I18n.t("Policy.Table3.tr1.th2")}</th>
                    </tr>
                    <tr>
                      <td>{I18n.t("Policy.Table3.tr2.td1")}</td>
                      <td>{I18n.t("Policy.Table3.tr2.td2")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div>
                <b>{I18n.t("Policy.Message9")}</b>
              </div>
              <div>{I18n.t("Policy.Message10")}</div>
              <br />
              <div>
                <table>
                  <tbody>
                    <tr>
                      <th>{I18n.t("Policy.Table4.tr1.td1")}</th>
                      <th>{I18n.t("Policy.Table4.tr1.td2")}</th>
                    </tr>
                    <tr>
                      <td>{I18n.t("Policy.Table4.tr2.td1")}</td>
                      <td>{I18n.t("Policy.Table4.tr2.td2")}</td>
                      <td>{I18n.t("Policy.Table4.tr2.td3")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div className="checkbox">
                <label>
                  <input
                    type="checkbox"
                    checked={this.props.isOldUser ? true : this.state.isChecked}
                    onChange={this.onChangeCheckBox}
                    id="mandatory"
                  />{" "}
                  <b className="stylePadding">{I18n.t("Policy.Message11")}</b>
                </label>
                <button
                  className="gdpr-disableAutoSuggest"
                  onClick={event => this.props.popUpClose(event)}
                  disabled={
                    this.props.isOldUser ? true : this.state.btnDisabled
                  }
                >
                  {"OK"}
                </button>
              </div>
              <br /> <br />
            </div>
          </ModalBody>
          <ModalFooter />
        </Modal>
      </span>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing
  };
};
export default withRouter(connect(mapStateToProps)(GDPR));

//export default GDPR;
