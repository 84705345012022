import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../../themes/theming';
import { MDBCol, MDBRow} from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';

import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class Activation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            filters: [{ value: "", label: "NMSC's" }, { value: "USERS", label: "Users" }],
            data: {},
            leftArrow: "widget_filter_arrow_disable",
            rightArrow: "widget_filter_arrow"
        };
    }
    componentWillMount() {
        this.fetchData("");
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dealerKaizenPoints) {
            // alert("updating activeusers")
            this.setState({ data: nextProps.dealerKaizenPoints, isLoading: false });
        }
    }
    fetchData = (filter) => {
        this.setState({ isLoading: true });
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            activeProgram:activeProgram,
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            userId: this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("kaizenPoints", "WIDGET_KAIZEN_POINTS_REPORT", queryDto));
    }
    render() {
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('evaluation.kaizenPoints')}>{I18n.t('evaluation.kaizenPoints')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">

                        <MDBRow style={{ minHeight: "3.2rem" }}>
                            <MDBCol lg="12" md="12" className="widget_count_class">
                                {this.state.data.value}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget-header_sub">
                            {I18n.t("Table.status")}
                           </MDBCol>
                        </MDBRow>
                        <MDBRow style={{ minHeight: "1.7rem" }}>
                            <MDBCol lg="12" md="12" className="widget_slider_class">
                                <div style={{textAlign: 'center'}} >
                                    <span className="widget_filter">{I18n.t('status.pending')}</span>
                                </div>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        dealerKaizenPoints: state.dashboardReducer.dealerKaizenPoints
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Activation)));