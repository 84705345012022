import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from "mdbreact";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import '../index.css';
import { Form } from 'react-bootstrap';
import { getKpiEvaluationCommentHistory } from "../../../actions/finalizeTargetAction";
var I18n = require("react-redux-i18n").I18n;

class addComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            evidenceHistory: []
        }
    }
    componentDidMount() {

    }

    componentWillMount() {
        if (this.props.kpiEvaluationId) {
            this.props.dispatch(getKpiEvaluationCommentHistory(this.props.kpiEvaluationId));
        }       
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.kpiEvaluationComment) {
            this.setState({ evidenceHistory: nextProps.kpiEvaluationComment })
        }
    }

    onCommentsChange = (e) => {
        this.setState({ comment: e.target.value });
    }

    submitComment = () => {
        if (!this.state.comment || this.state.comment === "") {
            toastr.error(I18n.t("evaluation.commentMsg"));
            return;
        }
        this.props.submitComment(this.state.comment, true);
        this.setState({ comment: '' });
    }    
    
    CommentFormatter = (cell, row) => {
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden"}}>{row.comment}</div>
        );
    }
    preventDefault() { }
    render() {
        const columns = [{
            dataField: 'who',
            text: I18n.t('evaluation.who'),
            style: { width: "22%", textAlign: "left" },
            headerStyle: { width: "21%", textAlign: "left" },
        }, {
            dataField: 'target',
            text: I18n.t('Table.target'),
            style: { width: "10%", textAlign: "left" },
            headerStyle: { width: "10%", textAlign: "left" }
        },{
            dataField: 'nmscStatus',
            text: I18n.t('Table.status'),
            style: { width: "20%", textAlign: "left" },
            headerStyle: { width: "20%", textAlign: "left" }
        },
         {
            dataField: 'comment',
            text: I18n.t('evaluation.comment'),
            style: { width: "40%", wordWrap: "break-word", textAlign: "left"},
            headerStyle: { width: "39%", textAlign: "left" },
            formatter: this.CommentFormatter,
            title: true
        }, {
            dataField: 'createDate',
            text: I18n.t('evaluation.date'),
            style: { width: "10%" }, 
            headerStyle: { width: "16%" }
        }];

        return (
            <Modal centered
                className={"addComments uploadModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggleCommentsCancel()}>
                    {I18n.t("evaluation.comments")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md="8">
                                <Form>
                                    <label className={"questionLabel"}>
                                        {I18n.t("evaluation.comments")}
                                        <span className="redFont"> *</span>
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="3" maxLength="200" value={this.state.comment} onChange={this.onCommentsChange}
                                    />

                                </Form>
                            </Col>
                            <Col md="3">
                            {this.props.isCommentSave ?
                                    <button style={{ marginTop: '50px' }}
                                        className={"button-" + this.props.theme.name}
                                        onClick={this.submitComment}
                                    >
                                        {I18n.t("evaluation.submit")}
                                    </button>
                            : ""}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <div className="commentHistory">
                        <span >
                            <div style={{ marginLeft: '10px' }} className={"buttonHeading-" + this.props.theme.name}>
                                {I18n.t("Table.history")}
                            </div>
                        </span>
                        <br />
                        <br />

                        <BootstrapTable
                            keyField='historyId'
                            data={this.state.evidenceHistory}
                            columns={columns}
                            hover
                            condensed
                            noDataIndication={I18n.t('evaluation.noCommentAvailable')}
                        />
                    </div>

                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        kpiEvaluationComment: state.userManagementReducer.kpiEvaluationComment,  
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(addComments)));


