import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { withTheme } from '../../themes/theming';
import { withRouter } from 'react-router-dom';
import ActionPlanModal from '../actionPlanModal';
import { fetchActionPlans, downloadActionPlanDtls, deleteActionPlan, downloadActionPlanSummary } from '../../actions/activityAction.jsx';
import './index.css';
import { confirmAlert } from "react-confirm-alert"; // Import
import ClampLines from 'react-clamp-lines';
import $ from 'jquery';
import * as AppConst from '../../AppConstant';
var I18n = require('react-redux-i18n').I18n;

class ActionPlanTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPlanList: [],
            actionPlanDto: {
                actionPlanId: '',
                description: '',
                activityId: ''
            },
            type: "",
        };
    }

    componentWillMount() {
        if (this.props.activity !== undefined && this.props.activity.activityId !== undefined && this.props.activity.activityId !== 0) {
            this.props.dispatch(fetchActionPlans(this.props.activity.activityId));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.actionPlanList && nextProps.actionPlanRefreshRequired && nextProps.actionPlanList.constructor === Array) {
            this.setState({ actionPlanList: nextProps.actionPlanList });
            this.props.dispatch({
                type: "ACTION_PLAN_REFRESH_DONE",
                payload: false
            })
        }
    }

    componentDidUpdate() {
        if (this.props.activity.status !== undefined
            && (this.props.activity.status === 'Closed' || this.props.activity.status === 'Rejected' || this.props.activity.status === 'Completed')) {
            $('.controlledIcon').hide();
        } else {
            // let accessControlList = this.props.user.userMdl.accessControl;
            let roles = this.props.user.userMdl.roles;
            $('.controlledIcon').each(function () {
                if (!this.classList.contains('hideIcon')) {
                    $(this).show();
                } else {
                    if ($(this).children().length > 0) {
                        // Validate if icon need to be shown.
                        let ceaBoRole = false
                        let ceaNmscAdminRole = false
                        let ceaAdminRole = false
                        let accessType = "R"
                        if (roles.includes(AppConst.CEA_ROLES.KET_CEA_BO) || roles.includes(AppConst.NET_ROLES.KET_NET_BO)) {
                            ceaBoRole = true
                        }
                        if (roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) || roles.includes(AppConst.NET_ROLES.KET_NET_NMSC_Admin) || roles.includes(AppConst.UC_ROLES.KET_UC_NMSC_User)) {
                            ceaNmscAdminRole = true
                        }
                        if (roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) || roles.includes(AppConst.NET_ROLES.KET_NET_Admin) || roles.includes(AppConst.UC_ROLES.KET_UC_Admin)) {
                            ceaAdminRole = true
                        }
                        if(ceaBoRole || ceaNmscAdminRole || ceaAdminRole){
                            accessType = "R/W"
                        }
                        // let accessType = accessControlList.filter(obj => obj.function === 'ACTIVITY')[0] ?
                        //     accessControlList.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
                        let status = $(this).parent().parent().parent().siblings()[3].innerText;
                        let startDate = $(this).parent().parent().parent().siblings()[4].innerText;
                        let dateObj = startDate.split("/");
                        dateObj = new Date(dateObj[2], dateObj[1] - 1, dateObj[0]);
                        let element = 'delete';
                        if ($(this).children()[0].classList.contains('edit-icon')) {
                            element = 'edit';
                        }
                        // check for Edit Icon.
                        if (element === 'edit' && accessType === "R/W" && (status === 'Open' || status === 'Closed')) {
                            $(this).show();
                        }
                        // check for Delete Icon.
                        if (element === 'delete' && accessType === "R/W" && status === 'Open' && dateObj > new Date()) {
                            $(this).show();
                        }
                    }
                }
            });
        }
    }

    counterMeasureFormatter = (cell, row) => {
        return (
            <ClampLines
                text={cell}
                lines={2}
                ellipsis="..."
                buttons={false}
                className="custom-class"
            />
        );
    }

    buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
        let ceaBoRole = false
        let ceaNmscAdminRole = false
        let ceaAdminRole = false
        let accessType = "R"
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_BO) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_BO)) {
            ceaBoRole = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_NMSC_Admin) || this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_NMSC_User)) {
            ceaNmscAdminRole = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) || this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin) || this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin)) {
            ceaAdminRole = true
        }
        if(ceaBoRole || ceaNmscAdminRole || ceaAdminRole){
            accessType = "R/W"
        }
        // let accessType = formatExtraData.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0] ? formatExtraData.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;        
        var startDate = row.startDate.split("/");
        startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);
        const editIconClass = (accessType === "R/W" && (row.status === 'Open' || row.status === 'Closed')
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Completed"
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Rejected"
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Closed") ? "controlledIcon" : "controlledIcon hideIcon";

        const deleteIconClass = (accessType === "R/W" && row.status === 'Open' && startDate > new Date()
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Completed"
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Rejected"
            && (formatExtraData.activity.status || formatExtraData.selectedActivity.status) !== "Closed") ? "controlledIcon" : "controlledIcon hideIcon";
        return (
            <div>
                <div className="btn-toolbar float-right">
                    <a onClick={() => this.downloadAPDtls(row.actionPlanId)}>
                        <div title={I18n.t('ceaActionPlan.download')} className="download-icon" />
                    </a>
                    <a className={editIconClass} onClick={() => this.toggleDownload('Edit')}>
                        <div title={I18n.t('ceaActionPlan.edit')} className="edit-icon" />
                    </a>
                    <a className={deleteIconClass} onClick={() => this.deleteActionPlan(row)}>
                        <div title={I18n.t('ceaActionPlan.delete')} className="delete-icon" />
                    </a>
                </div>
            </div>
        );
    }

    statusFormatter(cell, row) {
        return (
            <div >
                {I18n.t('others.' + row.status.toLowerCase())}
            </div>
        );
    }

    deleteActionPlan = (row) => {        
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                        {I18n.t("ceaActionPlan.deleteMsg")}
                  </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("ceaActionPlan.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.props.dispatch(deleteActionPlan(row.actionPlanId, row.activityId));
                                    onClose();
                                }}>
                                {I18n.t("ceaActionPlan.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });
    }

    downloadAPDtls = (id) => {
        this.props.dispatch(downloadActionPlanDtls(id, "en" , this.props.activity.nmscCode, this.props.theme.name, this.props.user.userMdl.activeProgram, this.props.activity.activityId));
    }

    downloadAPSummary = (id) => {
        this.props.dispatch(downloadActionPlanSummary(id, "en", this.props.activity.nmscCode, this.props.theme.name, this.props.user.userMdl.activeProgram));
    }

    headerAdd = (cell, row) => {
        // let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;        
        let ceaBoRole = false
        let ceaNmscAdminRole = false
        let ceaAdminRole = false
        let accessType = "R"
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_BO) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_BO)) {
            ceaBoRole = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_NMSC_Admin) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_NMSC_Admin) || this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_NMSC_User)) {
            ceaNmscAdminRole = true
        }
        if (this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_Admin) || this.props.user.userMdl.roles.includes(AppConst.NET_ROLES.KET_NET_Admin) || this.props.user.userMdl.roles.includes(AppConst.UC_ROLES.KET_UC_Admin)|| this.props.user.userMdl.roles.includes(AppConst.LN_ROLES.KET_LEXUS_Admin)) {
            ceaAdminRole = true
        }
        if(ceaBoRole || ceaNmscAdminRole || ceaAdminRole){
            accessType = "R/W"
        }
        return (
            accessType === "R/W" ?
            <div>
                {(this.props.activity.status !== "Completed") && (this.props.activity.status) !== "Closed" ?
                    <a onClick={() => this.toggleDownload('Create')}><div title={I18n.t('ceaActionPlan.add')} className="add-icon" /></a>
                    : <a><div title={I18n.t('ceaActionPlan.addActionPlanNotAllowed')} className="add-icon-disabled" /></a>}
            </div> :
            <div></div>
        );
    }    

    toggleDownload = action => {
        this.setState({
            modalAction: !this.state.modalAction,
            modalFor: action
        });
        if (action === "Create") {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow["activityId"] = this.props.activity.activityId;
            selectedRow["actionPlanId"] = 0;
            selectedRow["actionPlanDisplayId"] = 0;
            selectedRow["description"] = "";
            selectedRow["counterMeasure"] = "";
            selectedRow["questionReference"] = "";
            selectedRow["userId"] = "";
            selectedRow["status"] = "Open";
            selectedRow["followUp"] = "";
            selectedRow["assignee"] = "";
            selectedRow["endDate"] = "";
            selectedRow["startDate"] = "";
            selectedRow["userMdl"] = this.props.user.userMdl;
            selectedRow["nmscCode"] = this.props.activity.nmscCode;
            selectedRow["langCode"] = this.props.user.userMdl.lang;
            selectedRow["userId"] = this.props.user.userMdl.username;
            this.setState({ selectedRow });
        }

    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }

    quesRefFormatter = (cell, row) => {
        return (
            <div >
                {cell}
            </div>
        );
    }
    isCriticalFormatter = (cell, row) => {
        return row.isCritical ? "Yes" : "No";
      };
    render() {
        const columns = [{
            dataField: 'actionPlanDisplayId',
            text: I18n.t('ceaActionPlan.id'),
            sort: true,
            style: { width: "5%", textAlign: "center" },
            headerStyle: { width: "5%", textAlign: "center", paddingLeft: "0px" }
        }, {
            dataField: 'counterMeasure',
            text: I18n.t('ceaActionPlan.counterMeasure'),
            title: I18n.t('ceaActionPlan.counterMeasure'),
            formatter: this.counterMeasureFormatter,
            style: { width: "28.5%", textAlign: "left", wordWrap: "break-word" },
            headerStyle: { width: "28%", textAlign: "left" }
        }, 
        {
            dataField: "isCritical",
            text: I18n.t('questionnaire.subQuestionTable.isCritical'),
            sort: true,
            formatter: this.isCriticalFormatter,
            style: { fontSize: "11.8px", width: "15%", textAlign: "center" },
            headerStyle: { width: "15%", textAlign: "center" },
            hidden:this.props.user.userMdl.activeProgram != AppConst.activeProgramNas
            ? true : false,
            classes:'kaizenPtClsColumnBootStrap',
            headerClasses:'kaizenPtHeaderClsColumnBootStrap',
            bodyClasses:'kaizenPtBodyClsColumnBootStrap'
          },
        {
            dataField: 'questionReference',
            text: I18n.t('ceaActionPlan.questionReference'),
            title: I18n.t('ceaActionPlan.questionReference'),
            formatter: this.quesRefFormatter,
            style: { width: "13.5%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "13.3%", textAlign: "left" }
        }, {
            dataField: 'status',
            text: I18n.t('ceaActionPlan.status'),
            sort: true,
            style: { width: "11%", textAlign: "center" },
            headerStyle: { width: "11%", textAlign: "center" },
            formatter: this.statusFormatter,
        }, {
            dataField: 'startDate',
            text: I18n.t('ceaActionPlan.startDate'),
            sort: true,
            style: { width: "8.5%", textAlign: "center" },
            headerStyle: { width: "8.5%", textAlign: "center" }
        }, {
            dataField: 'endDate',
            text: I18n.t('ceaActionPlan.dueDate'),
            sort: true,
            style: { width: "13%", textAlign: "center" },
            headerStyle: { width: "13%", textAlign: "center" }
        }, {
            dataField: 'assignee',
            text: I18n.t('ceaActionPlan.assignedTo'),
            title: I18n.t('ceaActionPlan.assignedTo'),
            sort: true,
            style: { width: "9.8%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "10%", textAlign: "left" }
        }, {
            dataField: "",
            text: "",
            isDummyField: 'formatter',
            headerFormatter: this.headerAdd,
            formatter: this.buttonFormatter,
            formatExtraData: this.props,
            style: { width: "10%" },
            headerStyle: { width: "11%" }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                const selectedRow = { ...this.state.selectedRow };
                selectedRow["activityId"] = row.activityId;
                selectedRow["actionPlanId"] = row.actionPlanId;
                selectedRow["actionPlanDisplayId"] = row.actionPlanDisplayId;
                selectedRow["description"] = row.description;
                selectedRow["counterMeasure"] = row.counterMeasure;
                selectedRow["questionReference"] = row.questionReference;
                selectedRow["userId"] = row.userId;
                selectedRow["status"] = row.status;
                selectedRow["followUp"] = row.followUp;
                selectedRow["assignee"] = row.assignee;
                selectedRow["endDate"] = row.endDate;
                selectedRow["startDate"] = row.startDate;
                selectedRow["userMdl"] = this.props.user.userMdl;
                selectedRow["nmscCode"] = this.props.activity.nmscCode;
                selectedRow["langCode"] = this.props.user.userMdl.lang;
                selectedRow["userId"] = this.props.user.userMdl.username;
                selectedRow["flagCEA"] = this.props.user.userMdl.flagCEA;
                selectedRow["flagNET"] = this.props.user.userMdl.flagNET;
                selectedRow["flagUC"] = this.props.user.userMdl.flagUC;
                selectedRow["flagLN"] = this.props.user.userMdl.flagLN;
                this.setState({ selectedRow });
            }
        };
        return (            
            <div>
                <div className="col-md-12 row">
                    <div className="col-md-12 actionPlanTable">
                        <BootstrapTable
                            keyField='actionPlanId'
                            noDataIndication={I18n.t('ceaActionPlan.noActionAvailable')}
                            data={this.state.actionPlanList}
                            columns={columns}
                            hover
                            condensed
                            rowEvents={rowEvents}
                        />
                    </div>
                </div>
               <br></br>
                {this.state.actionPlanList.length > 0 && this.props.user.userMdl.activeProgram !== AppConst.activeProgramLN?
                    <div className="col-md-12 modal-footer" style={{ borderTop: '0px', paddingTop: '0px', paddingBottom:'0px'}}>
                        <div className="col-md-6" style={{ textAlign: 'right' , margin: 'unset' }}>
                            <button onClick={() => this.downloadAPSummary(this.props.activity.activityId)} className={"button-" + this.props.theme.name} >{I18n.t('ceaActionPlan.download')}</button>
                        </div>
                    </div>
                    : 
                   null
                }
                {this.state.modalAction === true ?
                    <ActionPlanModal
                        modal={this.state.modalAction}
                        toggle={this.toggleDownload}
                        modalFor={this.state.modalFor}
                        selectedRow={this.state.selectedRow}
                        activity={this.props.activity}
                    /> : null}
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        actionPlanList: state.activityReducer.actionPlanList,
        actionPlanRefreshRequired: state.activityReducer.actionPlanRefreshRequired,
        subQuesInfo: state.activityReducer.subQuesInfo
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ActionPlanTable)));