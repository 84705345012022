import React from "react";
import Login from "../components/login";
import Home from "../components/home";
import Toolbox from "../components/toolbox";
import UserPreferences from "../components/userPreferences";
import Activity from "../components/activity";
import ActionPlan from "../components/actionPlan";
import AppHandler from "../AppHandler";
import Container from "../components/container";
import MasterDataUpload from "../components/masterDataUpload";
import Download from "../components/download";
import Redirect from "../components/home/redirect";
import Certification from "../components/certification";
import BulkUpload from "../components/bulkUpload";
import UserManagement from "../components/userManagement";
import DealerManagement from "../components/dealerManagement";
import BulkActions from "../components/bulkUpload";
import KpiActuals from "../components/kpiActuals";
import { Route } from "react-router-dom";
import LinkDealer from "../components/linkDealer";
import AnnualSetup from "../components/annualSetup";
import ReachItHome from "../components/reachItDashboard";

const routes = (
  <div>
    <Route exact path="/" component={AppHandler} />
    <Container>
      <Route path="/home" component={Home} />
      <Route path="/toolbox" component={Toolbox} />
      <Route path="/preferences" component={UserPreferences} />
      <Route path="/activity" component={Activity} />
      <Route path="/masterDataUpload" component={MasterDataUpload} />
      <Route path="/actionPlan" component={ActionPlan} />
      <Route path="/download" component={Download} />
      <Route path="/certification" component={Certification} />
      <Route path="/kpiUpload" component={BulkUpload} />
      <Route path="/activityUpload" component={BulkUpload} />
      <Route path="/userManagement" component={UserManagement} />
      <Route path="/dealerManagement" component={DealerManagement} />
      <Route path="/linkDealer" component={LinkDealer} />
      <Route path="/redirect" component={Redirect} />
      <Route path="/bulkAction" component={BulkActions} />
      <Route path="/kpiActuals" component={KpiActuals} />
      <Route path="/annualSetup" component={AnnualSetup} />
      <Route path="/reachIthome" component={ReachItHome} />
    </Container>
    {/* <Route path='/login' component={Login} />  */}
    <Route path="/logout" render={deleteAllCookies} />
    <Route
      path="/login"
      component={() => (window.location = "/ket/v1/auth/login")}
    />
  </div>
);

function deleteAllCookies() {
  window.location = "/ket/auth/deleteCookie";
  var cookies = document.cookie.split(";");
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    console.log(cookie);
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
  console.log("completed");
  return null;
}
export default routes;
