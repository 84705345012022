const translationsRU = {
  ru: {
    Policy: {
      HeaderMsg:
        "Уведомление о конфиденциальности для обработки ваших персональных данных в рамках Kaizen Expert Tool",
      Message1:
        "Настоящее конфиденциальное уведомление относится к обработке ваших персональных данных в рамках Kaizen Expert Tool. Его всегда следует читать вместе с Общей политикой конфиденциальности и защиты персональных данных Тойота, в которой описаны общие условия обработки ваших персональных данных и которую вы должны принять, чтобы иметь возможность продолжить работу с политикой конфиденциальности и защиты личных данных Тойота.",
      Message2:
        "Toyota Motor Europe NV/SA с головным офисом по адресу Avenue du Bourget / Bourgetlaan 60, 1140, Брюссель, Бельгия, в качестве контроллера данных собирает ваши персональные данные (как указано ниже) для целей, описанных ниже.",
      Message3: "Какие ваши личные данные будут собраны?",
      Message4:
        "Мы собираем следующие категории ваших персональных данных для Kaizen Expert Tool:",
      Table1: {
        tr1: "Категория/и персональных данных:",
        tr2: "Идентификатор пользователя",
        tr3: "Имя пользователя (полное имя)",
        tr4: "Бренд (Тойота, Лексус)",
        tr5: "Предпочтительный язык",
        tr6: "Дочерняя организация"
      },

      Message5:
        "Цель и правовые основы обработки ваших персональных данных.",
      Message6:
        "Мы будем обрабатывать ваши личные данные для целей и на правовых основаниях, описанных ниже:",
      Table2: {
        tr1: {
          th1: "Цель:",
          th2: "Правовые основы:"
        },
        tr2: {
          td1: "КЕТ -- 1: Результаты деятельности Kaizen дилера",
          td2: "Законный интерес, преследуемый контролером данных"
        },
        tr3: {
          td1: "КЕТ - 2: IT-поддержка",
          td2: "Законный интерес, преследуемый контролером данных"
        }
      },
      Message7: "Срок хранения ваших личных данных",
      Message8: "Ваши персональные данные будут храниться в течение срока, указанного ниже:",
      Table3: {
        tr1: {
          th1: "Срок хранения:",
          th2: "Категория/и персональных данных:"
        },
        tr2: {
          td1: "Любая личная информация, как описано выше, будет храниться не более 5 лет + 1 месяц со дня окончательного закрытия Kaizen Expert Tool.",
          td2: "Имя пользователя, Фамилия, Предпочтительный язык, организация"
        }
      },
      Message9: "ДОПОЛНИТЕЛЬНО: В СЛУЧАЕ РАЗГЛАШЕНИЯ / ПЕРЕДАЧИ ВАШИХ ЛИЧНЫХ ДАННЫХ В СТРАНЫ ЗА ПРЕДЕЛАМИ ЕВРОПЕЙСКОЙ ЭКОНОМИЧЕСКОЙ ЗОНЫ (ЕЭЗ)",
      Message10: "Ваши Личные данные будут доступны получателю(ям) в странах за пределами ЕЭЗ, как указано ниже:",
      Table4: {
        tr1: {
          td1: "Идентичность",
          td2: "Страна",
          td3: ""
        },
        tr2: {
          td1: "Получатель(и) не ЕЭЗ",
          td2: "Infosys",
          td3: "Индия"
        }
      },
      Message11: "Настоящим подтверждаю, что понял это уведомление; Я прочитал и согласился с общей политикой конфиденциальности и политикой защиты персональных данных Тойота."
    },
    ACCESSORIES: "Продажа аксессуаров",
    activity: {
      Assignee: "Ответственный",
      CompletionDate: "Дата завершения",
      Dealer: "Дилер",
      FirstVisit: "Первый визит",
      kpi: {
        kpi: " KPI должны быть только цифры",
        kpiLength: "Максимальное значение KPI составляет 10 символов",
        mandatory: "Введите правильные данные во все обязательные поля",
        mandatoryTargets: "Пожалуйста, введите все KPI",
        target: "Целевые показатели должны быть только цифры",
        targetLength: "Максимальное значение целевых показателей составляет 10 символов."
      },
      ReminderOn: "Напоминание о",
      statusDD: "Статус",
      Toolbox: "Кайдзен Модуль",
      activityCreation: "Начат процесс создания Мероприятия",
      activityCreated: "Мероприятие создано"
    },
    DPOK: "Управление складом",
    EFFICIENCY: "Эффективность Произ-ва",
    FIX_IT_RIGHT: "Базовый Уровень (FIR)",
    footer: {
      message: "Обязательные поля"
    },
    FOUNDATION: "Стандарты ДЦ",
    MAINTENANCE_REMINDERS: "Напоминание о ТО",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Основы",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Апр.",
      aug: "Авг.",
      dec: "Дек.",
      feb: "Февр.",
      jan: "Янв.",
      jul: "Июль",
      jun: "Июнь",
      mar: "Март",
      may: "Май",
      nov: "Нояб.",
      oct: "Окт.",
      sep: "Сент."
    },
    others: {
      back: "Назад",
      closed: "Закрыто",
      completed: "Завершено ",
      countryLanguageCode: "Код страны/языка ",
      createActivity: "Создать мероприятие",
      createdDate: "Дата создания",
      dataUneditableNote: "Примечание: Сохраненные данные нельзя редактировать. Для изменения данных обратитесь к администратору ТМР.",
      date: "Дата",
      downloadQuestionnaire: "Загрузить опросник",
      editActivity: "Редактировать мероприятие",
      initialHelpText: "Исходное значение KPI должно быть средним значением за предыдущие шесть месяцев",
      inProgress: "В процессе",
      name: "Ф. И. О.",
      next: "Далее",
      noActivities: "Мероприятий не найдено",
      noResults: "Результаты отсутствуют",
      of: "из",
      ongoing: "В процессе",
      open: "Открыть",
      page: "Стр.",
      planned: "Запланировано",
      problemDescription: "Описание проблемы",
      signature: "Подпись",
      select: "Выбрать",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Во время загрузки произошла ошибка.",
      no_published_subquestion: "Для того, чтобы начать это Мероприятие, опубликуйте, пожалуйста, Опросник для этого модуля.",
      activity_upload_error_msg: "Ошибка при загрузке сведений о действиях",
      action_plan_created: " План действий создан успешно",
      action_plan_deleted: " План действий успешно удален",
      action_plan_saved: " План действий успешно сохранен",
      activity_kpi_saved: " KPI мероприятия успешно сохранены",
      master_kpi_confirm_delete: "Подтвердите, если вы хотите удалить выбранный КПД.",
      master_kpi_created: "Основной KPI успешно создан",
      master_kpi_deleted: "Основной KPI успешно удален",
      master_kpi_not_found: "Основной KPI не найден",
      master_kpi_published: "Выбранные основные KPI успешно опубликованы",
      master_kpi_retired: "Основной KPI успешно убран",
      master_kpi_saved: "Основной KPI успешно сохранен",
      subquestion_created: "Подвопрос успешно сохранен.",
      subquestion_updated: "Подвопрос успешно обновлен.",
      subquestionnaire_not_found: "Подвопрос не найден.",
      subquestion_retired: "",
      subquestion_deleted: "Подвопрос удален.",
      subquestion_published: "Подвопросы успешно опубликованы.",
      mainquestion_created: "Основной вопросник создан.",
      mainquestion_deleted: "Основной вопросник удален.",
      mainquestion_updated: "Главный вопрос обновлен.",
      duplicateMsg: "Повторяющиеся записи в файле Excel.",
      deleteErr: "Ошибка при удалении файла",
      toolbox_deleted: "Кайзен модуль удален",
      toolbox_already_exists: "Данный Кайзен модуль уже существует",
      toolbox_saved: "Кайзен модуль сохранен",
      toolbox_created: "Кайзен модуль создан",
      toolbox_not_found: "Кайзен модуль не найден",
      published_content_found: "Этот Кайзен модуль не может быть удален, поскольку имеет опубликованный контент",
      dealers_already_mapped: " Выбранные Дилеры уже отображаются для этого Пользователя ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Дилер добавлен. ",
      dealers_added: "  Дилер добавлен. ",
      total_dealers_mapped_now: "Общее количество Дилеров, отображаемых для данного Пользователя . ",
      no_dealer_available: "Нет Дилера(ов) по данным критериям",
      proper_dealer_selected: "Пожалуйста, выберите подходящего Дилера.",
      user_referenced_ongoing_activity: "Пользователь уже назначен для Мероприятия \"В процессе\". Пожалуйста, замените отвествтенного в этих Мероприятиях и повторите снова. ",
      want_to_remove_mapping: "Вы действительно хотите удалить отображение Пользователя для этих Дилеров?",
      user_mapping_removed: "Выбранные Дилеры успешно исключены из отображения Пользователя",
      dealer_closed_in_A2D: "Этот Дилер зарегистрирован как \"Закрытый\"в A2D",
      network_level_not_found: "Выберите допустимую комбинацию сетевых уровней в соответствии с настройками вашей сети KET",
      duplicate_dealer_uuid: "Дилер уже существует в базе данных KET",
      dealer_created: "Дилер создан",
      dealer_updated: "Информация по данному Дилеру обновлена",
      dealer_not_present: "Этот Дилер не зарегистрирован в A2D",
      dealer_uuid_other_nmsc: "Выбранный A2D uuid код не доступен для вашей сети",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Вы уверены, что Вы хотите удалить это?",
      addActionPlanNotAllowed: "План действий не может быть создан или отредактирован после того, как действие было закрыто, завершено или отклонено.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Вы уверены, что хотите отклонить мероприятие? Изменения будут невозможны после отклонения.",
      rejectMsg: "Вы уверены, что хотите отклонить мероприятие? Изменения будут невозможны после отклонения.",
      selectNMSCTab: "Пожалуйста, выберите язык для публикации",
      all: " Все",
      deleteConfirm: "Вы уверены, что хотите удалить выбранный KPI?",
      no: "Нет",
      retireConfirm: "Вы уверены, что хотите убрать выбранный KPI? Убрав KPI, его нельзя активировать повторно",
      warning: "Предупреждение",
      yes: "Да",
      saveDataConfirmation: "Вы уверены, что хотите сохранить данные?",
      saveKPIWarning: "Вы уверены, что хотите сохранить данные? Примечание: после сохранения данные нельзя редактировать. Для изменения данных обратитесь к администратору KET дистрибьютора.",
      addKpiNotAllowed: "Следующий месяц для ввода KPI можно добавить по окончании текущего месяца.",
      activityLaunch: "Вы уверены, что хотите начать это мероприятие? После того, как мероприятие было запущено, его нельзя отменить.",
      deleteConfirmSubQuestionNaire: "Вы уверены, что хотите удалить выбранный подвопрос?",
      retireConfirmSubQuestionNaire: "Вы уверены, что хотите изъять выбранный подвопрос?",
      deleteConfirmMainQuestion: "Пожалуйста, подтвердите, если вы хотите удалить Основной вопрос и связанные с ним подвопросы.",
      deleteConfirmChapter: "Вы уверены, что хотите удалить главу?",
      deleteLibraryFolder: "Вы уверены, что хотите удалить эту папку?",
      deletePublishedFolder: "Some files within the sub folders are linked to Questionnaires which will be de-linked . Please confirm if you want to proceed.",
      importQuestion: "Вы уверены, что хотите импортировать этот подвопрос? Текущий подвопрос будет заменён.",
      noQuestion: "Нет доступных вопросов.",
      MandatoryWarning: "Пожалуйста, заполните все обязательные поля для всех местных языков.",
      certificationSubmission:
        "Кайзен модуль отправлен в ТМЕ для сертификации",
      sameToolBox: "Вы не можете отправить один и тот же Кайзен модуль дважды. Пожалуйста, выберите другой Кайзен модуль",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "All Activities created for this dealer in 'EA' will be removed. Are you sure you want to continue?"

    },
    status: {
      published: "Опубликовано",
      retired: "Убрано",
      saved: "Сохранено",
      rejected: "Отклонено",
      open: "Открыть",
      closed: "Закрыто",
      close: "Закрыто",
      ongoing: "В процессе",
      completed: "Завершено",
      complete: "Завершено",
      planned: "Запланировано",
      overdue: "Просрочено",
      due: "В работе",
      pending: "Pending",
    },
    Rejected: "Отклонено",
    Open: "Открыть",
    Closed: "Закрыто",
    Close: "Закрыто",
    Ongoing: "В процессе",
    Completed: "Завершено",
    Complete: "Завершено",
    Planned: "Запланировано",
    tabDetails: {
      noActionAvailable: "Нет доступных действий",
      activeDealer: "Данный Дилер уже зарегистрирован, как активный в A2D и не может быть удален",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Мероприятие",
      activityCompletionDate: "Дата завершения мероприятия",
      activityCompletionDateFrom: "Дата завершения мероприятия с",
      activityCompletionDateTo: "Дата завершения мероприятия до",
      activityStartDate: "Дата начала мероприятия",
      activityStartDateFrom: "Дата начала мероприятия с",
      activityStartDateTo: "Дата начала мероприятия до",
      assignedTo: "Ответственный",
      assignee: "Ответственный",
      benchmark: "Сравнительное значение ",
      city: "Город",
      completedBy: "Завершил ",
      completionBy: "Завершение",
      completionDate: "Дата завершения",
      counterMeasure: "Контрмера",
      country: "Страна",
      createdBy: "Создал",
      dealer: "Дилер",
      dealerNameAD: "Назнавание Дилера (A2D)",
      dealerName: "Название дилера",
      definition: "Определение",
      description: "Описание",
      dueDate: "Дата завершения",
      endDate: "Дата окончания",
      firstVisit: "Первый визит",
      followup: "Последующий контакт",
      franchise: "Франшиза",
      history: "История",
      id: "ID",
      initial: "Первоначальный",
      kpi: "Ключевой Показатель (KPI)",
      launch: "Запуск",
      mandatory: "Обязательно",
      nmsc: "Дистрибьютор",
      problem: "Проблема",
      publishedOn: "Дата публикации",
      questionReference: "Ссылка на вопрос",
      reference: "Ссылка",
      reminderOn: "Напоминание о",
      score: "Оценка",
      startDate: "Дата начала",
      status: "Статус",
      target: "Цель",
      toolbox: "Кайдзен Модуль",
      view: "Просмотр",
      kpiResult: "Результаты KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Локация",
      ActionItemReport: "Действия в процессе",
      certification: "Статус сертификации",
      noOfToolbox: "Кол-во завершенных кайзен модулей",
      program: "Программа",
      certificationDate: "Дата сертификации",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      
     },
     tabsDetails: {
      actionPlan: "План действий",
      confirmDeleteMessage: "Вы уверены, что хотите удалить выбранный KPI?",
      confirmRetireMessage: "Подтвердите, что хотите убрать выбранный KPI, поскольку он уже публиковался ранее",
      errorRetireMsg: "Этот KPI нельзя убрать, т. к. он является активным обязательным KPI TME",
      import: "Импортировать",
      importKpiMessage: "Пожалуйста загрузите несите ваш KPI из Основного листа KPI TME",
      kpi: "Ключевые показатели (KPIs)",
      library: "Библиотека",
      noActionAvailable: "Нет доступных действий",
      NoKPI: "Нет доступных Ключевых показателей",
      publish: "Опубликовать",
      questionnaire: "Опросник",
      tmeKPIList: "Список основных KPI TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Название модуля",
      newToolboxName: "Новое название",
      deleteToolbox: "Удалить модуль?",
      programName: "Название программы",
      ToolboxName_EN: "Название модуля (Англ.)",
      deleteTool: "Вы уверены, что Вы хотите удалить этот модуль?",
      selectToolbox: "Выберите, пожалуйста, модуль для удаления.",
      editToolBox: "Редактировать модуль",
      addToolbox: "Добавить модуль",
      toolbox: "Кайзен модуль",
      addActionPlan: "Добавить План действий",
      actionPlanItem: "План действий — пункт №",
      add: "Добавить",
      addActivity: "Добавить мероприятие",
      additionalInformation: "Дополнительная информация",
      benchmark: "Сравнительное значение",
      cancel: "Отмена",
      createEditActivity: "Создать/редактировать мероприятие",
      createKPI: "Создать KPI",
      delete: "Удалить",
      download: "Загрузка",
      edit: "Редактировать",
      editKPI: "редактировать KPI",
      keyPerformanceIndicator: "Ключевой показатель",
      kpiDefinition: "Определение Ключевого Показателя",
      mandatoryKpi: "Обязательный KPI",
      save: "Сохранить",
      search: "Поиск",
      unit: "Ед. изм.",
      update: "Обновить",
      validData: "Пожалуйста, введите достоверные значения",
      hide: "Спрятать",
      show: "Показать",
      upload_dealer_network: "Загрузить дилерскую сеть",
      upload_question: "Загрузить вопросник",
      upload_user: "Загрузить данные пользователя",
      upload_activity: "Загрузить действия",
      remove: "Remove",
      toyota: "Тойота",
      lexus: "Лексус",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Пожалуйста, ответьте на все вопросы в Опроснике, для того, чтобы завершить это Мероприятие.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Для того, чтобы продолжить, введите, пожалуйста, первоначальные значения KPI и целевые значения KPI.",
      upload_error: "Ошибка при загрузке дилерской сети.",
      action_item_create_warning: "Максимум пять записей разрешено объединять в одном пункте действия.",
      actionPlanStartDateValidation: "Дата окончания не может быть раньше даты начала",
      actionsNotClosed: "В плане действий есть выполняемые действия; перед закрытием мероприятия закройте все действия",
      activityDuplicate: "Другое мероприятие для того же дилера панели инструментов уже существует",
      assigneeNotExist: "Выбранное ответственное лицо не зарегистрировано в системе",
      assigneeValidation: "неверное ответственное лицо",
      atleastOnePublish: "Выберите для публикации по меньшей мере один KPI",
      cannotRetireActive: "Это активный обязательный KPI TME, который нельзя убрать ",
      completionDate: "Дата завершения не может быть раньше даты первого посещения",
      confirDeletePlannedActivity: "Вы уверены, что хотите удалить это запланированное мероприятие?",
      DateformatValidation: "Дата должна быть в формате ДД/ММ/ГГГГ",
      DateValidation: "Дата начала должна быть раньше даты завершения",
      dealerValidation: "Неверный дилер",
      deleteSelectedConfirm: "Подтвердите, что хотите удалить выбранный KPI",
      duplicate: "Другой KPI с таким же названием и определением уже существует",
      modalMandatory: "Введите правильные данные во все обязательные поля",
      noPublishKpi: "Для выбранной панели инструментов нет опубликованных KPI",
      validSearchCriteria: "Введите действительный критерии поиска",
      multiSelectPlaceHolder: " Введите не менее 5 символов",
      multiSelectPlaceHolderUser: " Введите не менее 3 символов",
      startDateValidation: "Дата начала не может быть позже даты завершения",
      fileSelectionErr: " Пожалуйста, выберите сначала файл",
      atleastOneQuestion: "Пожалуйста, выберите хотя бы один подвопрос для импорта.",
      atleastOneSubQues: "Выберите хотя бы один подвопрос для публикации.",
      uploadErr: "Количество файлов, которые не удалось загрузить:",
      uploadSuccess: "Дилерская сеть успешно загружена.",
      file_upload_success: "Файл (ы) успешно загружены.",
      KPIinOngoingClosedActivity: "Этот KPI присутствует в одном или нескольких Мероприятиях в процессе/закрытых. Завершите эти Мероприятия перед внесением изменений в KPI.",
      Role_Error: "Настройка пользователя не завершена, пожалуйста, обратитесь к Администратору ТМР",
      points_allocation_incorrect: "Неправильное распределение баллов, пожалуйста, измените.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Мероприятия",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Главная",
      logout: "Выйти",
      MasterDataUpload: "Загрузить исходные данные",
      Toolbox: "Кайзен модуль",
      welcome: "Добро пожаловать",
      Admin: "Администратор"
    },
    questionnaire: {
      addSubQuestion: "Добавить подвопрос",
      editSubQuestion: "Редактировать подвопрос",
      subQuestionTitle: "Подвопрос",
      additionalInfo: "Дополнительная информация",
      na: "Добавление «Не применимо» в качестве возможного ответа на этот вопрос.",
      question: "Вопрос",
      questionTitle: "Название вопроса",
      why: "Почему?",
      addMainQuestion: "Добавить Основной вопрос",
      editMainQuestion: "Редактировать Основной вопрос",
      previousQuestion: "Предыдущий вопрос",
      nextQuestion: "Следующий вопрос",
      what: "Что?",
      library: "Библиотека",
      subQuestionTable: {
        no: "Нет",
        subQuestion: "Подвопросы",
        status: "Статус",
        publishedDate: "Дата публикации",
        notApplicable: "Неприменимо"
      },
      links: {
        question_link_button: "Ссылка на сайт",
        question_link_include_folder: "Включить подпапку",
        question_link_add_link: "Ссылочные файлы из библиотеки",
        question_link_view_folder: "Просмотр библиотеки"
      },
      mainQuestion: "Новые / обновлённые основные вопросы:",
      subQuestion: "Новые / обновлённые подвопросы:",
      retiredQuestion: "Изъять подвопросы:",
      libraries: "Новые / обновлённые файлы в библиотеках:",
      noFiles: "Файл не доступен."
    },
    library: {
      selectFile: "Пожалуйста, выберите хотя бы один файл.",
      fileSelected: "Выбранные файлы",
      publish_library_file: "Пожалуйста, выберите хотя бы один файл для публикации",
      library_files_deleted: "Вы уверены, что хотите удалить этот файл?",
      library_files_linked_deleted: "Вы уверены, что Вы хотите удалить этот файл и все ссылки, созданные в Опроснике?",
      max_size_error: "Размер файлов превышает ограничение в 1 ГБ на загрузку.",
      empty_file_desc: "Пожалуйста, введите описание файла.",
      file_not_selected: "Пожалуйста, выберите файл (ы) для загрузки (макс. 1 ГБ)",
      file_type_error: "Этот тип файла не разрешен.",
      file_name_error: "Неверный символ в имени файла.",
      file_name_lenght_error: "Имя файла может содержать не более 50 символов.",
      addFolder: "Добавить папку",
      editFolder: "Редактировать папку",
      currentFolder: "Имя папки",
      parentFolder: "Имя родительской папки",
      emptyFolderName: "Имя папки не может быть пустым.",
      invalidFolderName: "Имя папки не может содержать '/'.",
      folder_created: "Папка успешно создана.",
      folder_updated: "Папка успешно обновлена.",
      folder_not_found: "Папка не найдена",
      lib_invalid_toolbox: "Выбран неверный тулбокс",
      lib_folder_already_exists: "Эта папка уже существует.",
      lib_folder_deleted: "Папка успешно удалена.",
      library_files_published: "Файлы были опубликованы.",
      addFile: "Добавить файл",
      editFile: "Редактировать файл",
      fileName: "Имя файла",
      upload: "Загрузить",
      desc: "Описание",
      folderCreationErr: "Иерархия ограничена двумя уровнями.",
      subFolderWarning: "Пожалуйста, выберите подпапку, чтобы добавить файл (ы).",
      deleteWarning: "Пожалуйста, выберите папку для удаления.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Эта глава уже существует.",
      chapter_saved: "Глава сохранена",
      chapter_cannot_delete: "Эта глава не может быть удалена, так как подвопросы уже были опубликованы.",
      failed: "Не удалось !",
      chapter_deleted: "Глава удалена",
      chapter_nonEmpty: "Пожалуйста, создайте название главы.",
      addchapter: "Добавить главу",
      editChapter: "Редактирование главы",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Вложения",
      comments: "Комментарии",
      evidence: "Доказательства",
      evidences: "Доказательство (а)",
      yes: "Да",
      no: "Нет",
      na: "N/A",
      uploadEvidence: "Загрузить доказательства",
      fileUpload: "Пожалуйста, выберите файл (ы) для загрузки (макс. 1 ГБ)",
      who: "Кто?",
      answer: "Ответ",
      comment: "Комментарии",
      date: "Дата",
      submit: "Отправить",
      commentMsg: "Введите, пожалуйста, комментарии.",
      noCommentAvailable: "Комментариев нет.",
      uploadSuccessMsg: "Файл (ы) успешно загружены.",
      uploadErrorMsg: "Количество файлов, которые не удалось загрузить:",
      fileWithSameName: "Уже существует другой файл с таким же названием.",
      noEvidence: "Доказательства отсутствуют",
      createAction: "Создать пункт действия",
      chapterName: "Название главы",
      questionNo: "Номер вопроса.",
      when: "Когда?",
      noRecords: "Записей нет.",
      kaizenPoints: "Кайзен пункты",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Вопрос",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Круговой осмотр",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Кузовной ремонт",
      TSM_USED_CAR: "Автомобили с пробегом",
      value_chain: "Область",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Доска объявлений",
      kpi_target_not_set: "Цели KPI не установлены",
      kpi_initial_not_set: "Начальные значения KPI не установлены",
      kpi_monthly_not_set: "Месячные значения KPI не установлены",
      kpi_below_target: "Присутствуют KPI ниже целевых показателей",
      activation: "Активация",
      users: "Пользователи",
      nmscs: "NMSC's",
      activeRetailer: "Активные Дилеры",
      certificationRate: "Доля сертификации",
      reCertificationRate: "Доля ре-сертификации",
      activityDue: "Мероприятия в работе",
      avgdays: "Среднее количество дней/Кайзен модуль",
      overDueAct: "Просроченные мероприятия",
      actionItem: "Действия",
      bussResult: "Бизнес показатели",
      toolboxImpl: "Внедрение кайзен модуля",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Последние обновления",
      rolloutTopNmsc: "Результаты внедрения - лучшие NMSC",
      bestPractice: "Лучшие практики",
      availableKpi: "Доступные KPI",
      selectedKpi: "Выбранные KPI",
      dealerBestPractice: "Доска Лучших практик Дилера",
      kpiList: "Список KPI",
      go: "Вперед",
      nodata: "Нет данных ",
      businessResultTop: "Бизнес показатели - лучшие Дилеры",
      businessResultNMSC: "Бизнес показатели - лучшие NMSC"


    },
    dealerCertification: {
      dealerCertification: "Сертификация Дилера",
      readyForCertification: "Готов к сертификации",
      certification: "Сертификация",
      submittedOn: "Отправлено",
      certificateDate: "Дата сертификации",
      reCertificateDate: "Дата ре-сертификации",
      toolboxDetails: "Подробности кайзен модуля",
      approve: "Одобрить",
      reject: "Отклонено",
      pending: "В ожидании",
      region: "Регион"


    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Идентификатор выполнения",
      uploadedOn: "Загружено на",
      lastUpdatedOn: "Последнее обновление",
      noDataTable: "No data to view",
      kpiUpload: "загрузка KPI",
      action: "Действие",
      processed: "Обработано",
      error: "Ошибка",
      received: "Received",
      createActivity: "Создать Мероприятия",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Управление пользователями",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Имя",
      lastName: "Фамилия",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Имя",
      lastName: "Фамилия",
      emailId: "E-mail",
      languageCode: "Код языка",
      ACTIVE: "Active",
      INACTIVE: "Неактивный",
      addUser: "Добавить Пользователя",
      Assign: "Назначить",
      List: "Список",
      user: "Пользователь",
      error_message: "Произошла ошибка, пожалуйста попробуйте позже",
      role: "Роль пользователя",
      standardMessage: "No User Found",
      dealerCode: "Код Дилера",
      a2d: "A2D UUID",
      britReady: "Дилер участвовал в Brit Ready",
      britLive: "Дилер участвовал в Brit Live",
      britJapan: "Дилер участвовал в Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Добавить Дилера (из A2D)",
      noDealer: "Дилер(ы) не найдены",
      dealerUuid: "uuid код Дилера (A2D)",
      language: "Язык",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Пожалуйста, введите действующий A2D uuid код ",
      invalidDealerName: "Пожалуйста, введите действующее имя Дилера",
      invalidCity: "Пожалуйста, введите действующую локацию",
      invaliDealerCode: "Пожалуйста, введите действующий код Дилера",
      existingUuid: "A2D uuid код уже существует в базе данных KET",
      updateUuid: "Обновить A2D uuid код",
      newUuid: "Новый A2D uuid код",
    },
    networkManagement: {
      networkManagement: "Управление сетью",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsRU;
