import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import Select from "react-select";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import { saveKpi, getUnitsList, getEaUnitsList } from "../../actions/tabAction.jsx";
import "./index.css";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;

class ActionModal extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      selectedUnit: null,
      brandToyota: false,
      brandLexus: false,
      selectedRow: this.props.selectedRow,
      unitsListKPI: []
    };
    this.benchmarkTitle=this.benchmarkTitle.bind(this);
  }

  saveKpi = e => {
    e.preventDefault();
    if((this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") && this.state.selectedRow.unit.length === 0){
      toastr.error(I18n.t("toastr.modalMandatory"));
      return;
    }
    if (
      this.state.selectedRow.name.length === 0 ||
      this.state.selectedRow.definition.length === 0 ||
      this.state.selectedRow.benchmark.length === 0 ||
      ((this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") && this.state.brandToyota === false && this.state.brandLexus === false)
    ) {
      toastr.error(I18n.t("toastr.modalMandatory"));
      return;
    }

    // if (this.state.selectedRow.definition.length === 0) {
    //     toastr.error('Please enter kpi definition.');
    //     return;
    // }

    // if (this.state.selectedRow.benchmark.length === 0) {
    //      toastr.error('Please enter benchmark.');
    //     return;
    // }
    if(this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA"){
      var brand = [];
      if(this.state.brandToyota){
        brand.push(AppConst.BRAND_TOYOTA)
      }
      if(this.state.brandLexus){
        brand.push(AppConst.BRAND_LEXUS)
      }
      this.state.selectedRow.brand = brand.length> 0 ? brand.toString() : ""
      this.setState({ selectedRow: this.state.selectedRow });
    }
    this.props.dispatch(saveKpi(this.state.selectedRow));
    // this.props.toggle(false);
  };
  onStatusChange = selectedOption => {
    const selectedRow = { ...this.state.selectedRow };
    if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
      || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS"){
      selectedRow["unit"] = selectedOption !== null ? selectedOption.value : "";
    }else if (this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA"){
      selectedRow["unit"] = selectedOption !== null ? selectedOption.value : "";
    }else{
      //..
    }
    this.setState({
      selectedRow: selectedRow,
      selectedUnit: selectedOption
    });
    // this.state.selectedUnit = selectedOption;
    // this.state.selectedRow.unit = selectedOption.value;
    // this.setState({ selectedRow: this.state.selectedRow });
  };
  onHandleChange = (event, action) => {
    const selectedRow = { ...this.state.selectedRow };
    selectedRow[action] = event.target.value;
    this.setState({ selectedRow });
  };
  onHandleChangeNumber = event => {
    var Number = /^[\d\/\(\)\- \.]+$/;
    //  const selectedRow = { ...this.state.selectedRow };
    var numText = event.target.value;
    if (Number.test(numText) || numText === "") {
      this.state.selectedRow.benchmark = numText;
    } else {
      //this.state.selectedRow.benchmark = numText;
      //toastr.error('The benchmark field should contain numbers only.')
      return;
    }
    this.setState({ selectedRow: this.state.selectedRow });
  };
  onChangeMandatory = () => {
    this.state.selectedRow.mandatory = !this.state.selectedRow.mandatory;
    this.setState({ selectedRow: this.state.selectedRow });
  };
  onChangeBrand = (event, action) => {
    if(action === 'brandToyota'){
      this.setState({ brandToyota : !this.state.brandToyota});
    }else{
      this.setState({ brandLexus : !this.state.brandLexus });
    }    
  };
  componentWillMount() {
    this.props.dispatch(getUnitsList());
    this.props.dispatch(getEaUnitsList());
  }
  componentDidMount() {    
    if ((this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") && this.props.selectedRow.brand !== undefined && this.props.selectedRow.brand !== '') {
      let brandArr = this.props.selectedRow.brand.split(',');
      if(brandArr.length>0){
        this.setState({
          brandToyota: brandArr.includes(AppConst.BRAND_TOYOTA) ? true : false,
          brandLexus: brandArr.includes(AppConst.BRAND_LEXUS) ? true : false
        });
      }      
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.unitsList || nextProps.eaUnitsList) {
      // this.setState({ unitsListKPI: nextProps.unitsList });
      const options1 = nextProps.unitsList!==undefined?
       nextProps.unitsList.map(d => ({
        value: d,
        label: d
      }))
      :''
      const options2 = nextProps.eaUnitsList !==undefined ?
      nextProps.eaUnitsList.map(d => ({
        value: d==="mï¿½"?"m³" :d,
        label: d==="mï¿½"?"m³" :d
      }))
      : ''            
      
      this.setState({
        unitsListKPI: this.props.user.userMdl.activeProgram==="EA"? 
        nextProps.eaUnitsList!==undefined ? nextProps.eaUnitsList: 
        nextProps.unitsList!==undefined ? nextProps.unitsList : ''
        : nextProps.unitsList!==undefined ? nextProps.unitsList : '',
        unitListKPILIST: this.props.user.userMdl.activeProgram==="EA"? 
        nextProps.eaUnitsList!==undefined ?options2:options1:options1,
        selectedUnit: {
          value: this.state.selectedRow.unit,
          label: this.state.selectedRow.unit
        }
      });
      // if (this.state.selectedRow.unit !== "") {
      //     const defaultSelectedUnit = { value: this.state.selectedRow.unit, label: this.state.selectedRow.unit };
      //     defaultSelectedUnit.push({value:'',label:''});
      //     this.setState({ selectedUnit: defaultSelectedUnit });
      // }
    }
  }
  benchmarkTitle=()=>{
    if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
    || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS"){
      return I18n.t("tmeAdminMaster.benchmark");
    }else if(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA"){
      return I18n.t('Table.penEbenchmark');
    }else{
      //..
    }
  }

  preventDefault() {}
  render() {
    var benchmarkTitle=I18n.t("tmeAdminMaster.benchmark");
    benchmarkTitle=this.benchmarkTitle();
    return (
      <Modal
        className={"addEditModal addEditModal-" + this.props.theme.name}
        isOpen="true"
        toggle={() => this.preventDefault()}
      >
        <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>
          {this.props.modalFor === "Create" ? I18n.t("tmeAdminMaster.createKPI") : I18n.t("tmeAdminMaster.editKPI")}
        </ModalHeader>
        <ModalBody>
        <form className="form-horizontal">
            {(this.props.user.userMdl.activeProgram ===  "BRiT" || this.props.user.userMdl.activeProgram ===  "EA") ? 
                <div>
                  <label className="col-md-6 control-label">
                    <input
                        type="checkbox"
                        id="brandToyota"
                        checked={this.state.brandToyota}
                        onChange={e => this.onChangeBrand(e, "brandToyota")}
                    /> {I18n.t("tmeAdminMaster.toyota")}
                  </label>
                  <label className="col-md-6 control-label">
                    <input
                        type="checkbox"
                        id="brandLexus"
                        checked={this.state.brandLexus}
                        onChange={e => this.onChangeBrand(e, "brandLexus")}
                    /> {I18n.t("tmeAdminMaster.lexus")}
                  </label>
                </div> : null           
              }                     
            {(this.props.user.userMdl.activeProgram  ===  "BRiT" || this.props.user.userMdl.activeProgram  ===  "EA") ?  <br /> : null }
            <label className="col-md-12 control-label">
              {I18n.t("tmeAdminMaster.keyPerformanceIndicator")}
              <span className="redFont"> *</span>
            </label>
            <div className="col-md-12">
              <input
                type="text"
                className="form-control"
                maxLength="50"
                autoFocus
                value={this.state.selectedRow["name"]}
                onChange={e => this.onHandleChange(e, "name")}
              />
            </div>
            {/* <Input label="Key Performance Indicator" /> */}
            <br />
            <label className="col-md-12 control-label">
              {I18n.t("tmeAdminMaster.kpiDefinition")}
              <span className="redFont"> *</span>
            </label>
            <div className="col-md-12">
              <textarea
                className="form-control"
                maxLength="100"
                rows="3"
                onChange={e => this.onHandleChange(e, "definition")}
                value={this.state.selectedRow.definition}
              />
            </div>
            <br />
            <div className="col-md-12 row control-label">
              <label
                className="col-md-5 control-label"
                style={{ paddingLeft: "0.3rem" }}
              >
                {benchmarkTitle}{" "}
                <span className="redFont"> *</span>
              </label>
              {(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
                || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS")?
              <label
                className="col-md-6 control-label"
                style={{ paddingLeft: "0.5rem" }}
              >
                {I18n.t("tmeAdminMaster.unit")}
              </label>
              :(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA")?
              <label
                className="col-md-6 control-label"
                style={{ paddingLeft: "0.5rem" }}
              >
                {I18n.t("tmeAdminMaster.unit")}
                <span className="redFont"> *</span>
              </label>:null}
            </div>
            <div className="col-md-12 input-group control-label">
              <input
                type="text"
                maxLength="10"
                className="form-control col-md-5"
                autoFocus
                value={this.state.selectedRow.benchmark}
                onChange={e => this.onHandleChangeNumber(e, "benchmark")}
              />
              &nbsp;
              <Select
                placeholder= {I18n.t('others.select')}
                value={this.state.selectedUnit}
                onChange={this.onStatusChange}
                options={this.state.unitListKPILIST}
                isClearable={true}
                className="addTmekpi_modal_unit"
              />
            </div>
            <br />
            <label className="col-md-12 control-label">
              {I18n.t("tmeAdminMaster.additionalInformation")}
            </label>
            <div className="col-md-12">
              <textarea
                className="form-control"
                maxLength="500"
                rows="5"
                onChange={e => this.onHandleChange(e, "additionalInfo")}
                value={this.state.selectedRow.additionalInfo}
              />
            </div>
            <br />
            <div className="col-md-12 checkbox">
              <label>
                <input
                  type="checkbox"
                  id="mandatory"
                  checked={this.state.selectedRow.mandatory}
                  onChange={this.onChangeMandatory}
                />{" "}
                {I18n.t("tmeAdminMaster.mandatoryKpi")}
              </label>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <button
            className={"buttonCancel-" + this.props.theme.name}
            onClick={() => this.props.toggle(this.props.modalFor)}
          >
            {I18n.t("tmeAdminMaster.cancel")}
          </button>
          {this.props.modalFor === "Create" ? (
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.saveKpi}
            >
              {I18n.t("tmeAdminMaster.save")}
            </button>
          ) : (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.saveKpi}
              >
                {I18n.t("tmeAdminMaster.update")}
              </button>
            )}
        </ModalFooter>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    unitsList: state.tabReducer.unitsList,
    eaUnitsList: state.tabReducer.eaUnitsList
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(ActionModal)));
