import React from "react";
import { withTheme } from "../../../themes/theming";
import { Container, Col, MDBIcon } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import '../index.css';
import moment from 'moment';
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { fetchNmscCertification, check, emptyNmscCertification } from '../../../actions/certificationAction';
import SearchFilter from "./filter.jsx";
import ToolBoxDetails from './toolBoxDetails';
import { getUserDetails } from "../../../actions/loginAction";
import ToolBoxDetailsForTME from './toolBoxDetailsForTME';
var I18n = require("react-redux-i18n").I18n;


class MainContainer extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      showPopUp: false,
      records: [],
      data: [],
      selectedProgram : null,
      dealerDto: {
        nmscCode: null,
        userId: this.props.user.userMdl.username,
        userLangCode: null,
        // userNmscCode: this.props.user.userMdl.nmsc,
        userNmscCode: null,

        type: null,
        loggedInUser: this.props.user.userMdl.username
      },
      searchDto: {
        nmscCode: null,
        userId: null,
        type: null,
        langCode: null,
        loggedInUser: this.props.user.userMdl.username,
        businessArea: this.props.user.userMdl.businessAreaKey
      },
      show: false
    }
  }
  
  sortArray(array) {
    array.sort((a, b) => {
      let aDate = a.submittedOn;
      let bDate = b.submittedOn;
      if (!aDate) return -1;
      if (!bDate) return 1;
      if (aDate > bDate) return 1;
      else if (bDate > aDate) return -1;
      else if (a.readyForCertification < b.readyForCertification) return 1;
      else if (b.readyForCertification < a.readyForCertification) return -1;
      else return 0;
    });
    return array;
  }

  componentWillMount() {
    if (this.props.user) {
      this.props.dispatch(fetchNmscCertification(this.getQueryDTO()));
    }
    if (
      this.props.user.userMdl === undefined ||
      this.props.user.userMdl === null ||
      this.props.user.userMdl.expired === undefined ||
      this.props.user.userMdl.expired
    ) {
      // get user Details from token.
      // console.log("component will mount")
      this.props.dispatch(getUserDetails());
    }
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1) {
          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN") {
          isTMEAdminUser = true;
          break;
        }
        isNotAdmin = true;

      }
    }
    // const searchDto = { ...this.props.certiFilters };
    const searchDto = { ...this.state.searchDto };
    if (isTMEAdminUser) {
      searchDto["type"] = "TME";
    }
    else {
      searchDto["type"] = "NMSC";
      searchDto["langCode"] = this.props.user.userMdl.lang;
      searchDto["nmscCode"] = this.props.user.userMdl.nmsc;
      //searchDto["userNmscCode"] = this.props.user.userMdl.nmsc;
    }
    searchDto["loggedInUser"] = this.props.user.userMdl.username
    this.setState({ searchDto: searchDto });
  }
  getQueryDTO = () => {
    let nmscCode = this.props.user.userMdl.nmsc;
    let langCode = this.props.user.userMdl.lang;
    let userId = this.props.user.userMdl.username;
    //let activeProgram = this.props.user.userMdl.activeProgram;

    return {
      userNmscCode: this.props.certificationReducer.CertiFlag ? null : nmscCode,
      userLangCode: this.props.certificationReducer.CertiFlag ? null : langCode,
      nmscCode: this.props.nmscFilter,
      brand: this.props.brandFilter,
      country: this.props.countryFilter,
      userId: userId,
      numberOfCompletedToolBox: null,
      dealerId:this.props.certiFilters.dealerList !== undefined && this.props.certiFilters.dealerList[0] ? this.props.certiFilters.dealerList[0] : null,
      programList: this.props.certiFilters.programList,
      dealerList:this.props.certiFilters.dealerList,
      statusList: this.props.certiFilters.statusList,
      nmscCodeList:this.props.certiFilters.nmscCodeList,
      brandList:this.props.certiFilters.brandList,
      countryList:this.props.certiFilters.countryList,
      noOfCompletedToolBox:this.props.certiFilters.noOfCompletedToolBox,
      businessArea: this.props.user.userMdl.businessAreaKey
    };
  }
  componentWillReceiveProps(nextProps) {

    if (nextProps.certificationReducer && nextProps.certificationReducer.nmscCertification !== null && nextProps.certificationReducer.nmscCertification.length > 0) {
      if(((this.props.user.userMdl.nmsc.toUpperCase() ==='TME' || this.props.user.userMdl.nmsc.toUpperCase() ==='TMME')
     || this.props.user.userMdl.roles[0].value === "KET TME Admin" || this.props.user.userMdl.roles[0].value === "KET TME User" || this.props.user.userMdl.roles[0].value === "TSM KET User")){
        let data = nextProps.certificationReducer.nmscCertification
       // let finalArray = this.sortArray(data);
        this.setState({ data });
     }else{
      this.setState({ data: nextProps.certificationReducer.nmscCertification });
     }
    } else {
      this.setState({ data: [] });
    }

  }

  selectedProgram = (programList) =>{
    // console.log("0",programList);
    this.setState({
      selectedProgram : programList
    })
  }

  toggelPopUp = (dealerId) => {
    this.setState({
      showPopUp: !this.state.showPopUp
    })
    if (dealerId)
      this.setState({ selectedDealerId: dealerId });
  }

  toggel = (dealerId) => {
    this.setState({
      show: !this.state.show
    })
    if (dealerId)
      this.setState({ selectedDealerId: dealerId });
      this.props.dispatch(check())
  }

  toggleSearch = toggle => {
    this.setState({
      showSearchFlag: !this.state.showSearchFlag
    });
  };
  dealerFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealer}</div>
    );
  }

  CertificationFormatter = (cell, row) => {
    if (cell === true) {
      return (
        <MDBIcon fa icon="check" />
      );
    } else {
      return (
        <MDBIcon fa icon="times" />
      );
    }
  }



  countFormatter = (cell, row) => {
    if(((this.props.user.userMdl.nmsc.toUpperCase() ==='TME' || this.props.user.userMdl.nmsc.toUpperCase() ==='TMME')
     || this.props.user.userMdl.roles[0].value === "KET TME Admin" || this.props.user.userMdl.roles[0].value === "KET TME User" || this.props.user.userMdl.roles[0].value === "TSM KET User")){
      return (
        <div style={{ textDecoration: 'underline', textAlign: "center" }}>
          <a className="queslink" onClick={() => this.toggel(row.dealerId)}> {row.readyForCertification}</a>
        </div>
      );
    }
    return (
      <div style={{ textDecoration: 'underline', textAlign: "center" }}>
          <a className="queslink" onClick={() => this.toggelPopUp(row.dealerId)}> {row.readyForCertification}</a>
      </div>
    );
  }
  dateFormatter = (cell, row) => {
    var date = new Date(cell);
    if (cell !== null) {
      return (
        moment(date).format('DD/MM/YYYY')
      );
    } else {
      return (
        null
      );
    }
  }

  nmscFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.nmscCode}</div>
    );
  }

  componentWillUnmount() {
    this.setState({ data: [] });    
    let certiFilters = {dealerList : [],
      programList : null,
      statusList : null,
      nmscCodeList : null,
      brandList : null,
      countryList : null,
      noOfCompletedToolBox : null}
    this.props.dispatch(emptyNmscCertification(certiFilters));
  }

  render() {
    // console.log( this.props.certificationReducer.CertiFlag)
    const sizePerPageOptionRenderer = ({
      text,
      page,
      onSizePerPageChange
    }) => (
        <li
          key={text}
          role="presentation"
          className="dropdown-item"
        >
          <a
            href="#/"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              e.preventDefault();
              onSizePerPageChange(page);
            }}
            style={{ color: 'red' }}
          >
            {text}
          </a>
        </li>
      );
    const options = {
      sizePerPageOptionRenderer
    };

    const columns = [
      {
        dataField: "dealerId",
        text: "",
        hidden: true

      },
      {
        dataField: "dealer",
        text: I18n.t("Table.dealerName"),
        sort: true,
        style: { width: "19%", textAlign: "left" },
        headerStyle: { width: "17.8%", textAlign: "left" },
        formatter: this.dealerFormatter,
        title: true
      },
      {
        dataField: "nmscId",
        text :"NMSC Code",
        sort: true,
        style: { width: "7.2%", textAlign: "left" },
        headerStyle: { width: "8%", textAlign: "left" },
        formatter: this.nmscFormatter,
      },
      {
        dataField: "city",
        text: I18n.t("Table.city"),
        sort: true,
        style: { width: "13%", textAlign: "left" },
        headerStyle: { width: "12.9%", textAlign: "left" },
        title: true
      },
      {
        dataField: "readyForCertification",
        text: I18n.t("dealerCertification.readyForCertification"),
        sort: true,
        style: { width: "14%", textAlign: "center" },
        formatter: this.countFormatter,
        headerStyle: { width: "12.8%", textAlign: "center" }
      },
      {
        dataField: "certification",
        text: I18n.t("dealerCertification.certification"),
        sort: true,
        style: { width: "7%" },
        headerStyle: { width: "8.9%" },
        formatter: this.CertificationFormatter
      },
      {
        dataField: "submittedOn",
        text:  I18n.t("dealerCertification.submittedOn"),
        sort: true,
        formatter: this.dateFormatter,
        style: { width: "12%" },
        headerStyle: { width: "10.9%" },
        // title: true
      },
      {
        dataField: "certificateDate",
        text: I18n.t("dealerCertification.certificateDate"),
        sort: true,
        formatter: this.dateFormatter,
        style: { width: "12%" },
        headerStyle: { width: "11.9%" },
        // title: true
      },
      {
        dataField: "reCertificateDate",
        text: I18n.t("dealerCertification.reCertificateDate"),
        sort: true,
        formatter: this.dateFormatter,
        style: { width: "12%" },
        headerStyle: {
          width: "10.9%",
        },
        // title: true

      }
    ];

    // const defaultSorted = [{
    //   dataField: 'certification',
    //   order: 'asc'
    // }];

    const { data, sizePerPage, page } = this.state;
    return (
      <Container className="mt-5 b-1 certificationContainer">
        <Col md="12" className="certificationTableContainer">
          <div className="certification-content">
            <div className="col-md-12 row control-label noPadding">
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                {I18n.t("tmeAdminMaster.search")}
              </label>
              <label
                md="0.5"
                className="control-label boldFont"
                style={{ marginTop: "0.2rem" }}
              >
                <a onClick={this.toggleSearch}>
                  {this.state.showSearchFlag ? (
                    <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                  ) : (
                      <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                    )}
                </a>
              </label>
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'} style={{ width: "20%" }}>
                {I18n.t('activity.Dealer')} ({this.state.data.length})
              </label>
              <label className="control-label" style={{ width: "92%" }} />
              <SearchFilter
                flag={this.state.showSearchFlag}
                searchDto={this.state.searchDto}
                selectedProgram = {this.selectedProgram}
              />

              <div className="col-md-12 searchCertificationTable">

                <div className={'certificateTable parent-' + this.props.theme.name}>
                  <BootstrapTable
                    keyField="dealerId"
                    data={data}
                    noDataIndication="No records to view"
                    columns={columns}
                    // defaultSorted={defaultSorted}
                    pagination={paginationFactory(options)}
                  />
                </div>
              </div>
            </div>
          </div>
        </Col>
        <Col>
          <div>
            {this.state.showPopUp && <ToolBoxDetails
              flag={this.state.showPopUp} selectedDealerId={this.state.selectedDealerId}
              toggelPopUp={this.toggelPopUp}
            />}
          </div>
        </Col>
        <Col>
          <div>
            {this.state.show && <ToolBoxDetailsForTME
              selectedDealerId={this.state.selectedDealerId}
              selectedProgram = {this.state.selectedProgram}
              toggel={this.toggel} />}
          </div>
        </Col>        
      </Container>
    );
  }
}


const mapStateToProps = state => {
  return {
    certificationReducer: state.certificationReducer,
    certiFilters: state.certificationReducer.certiFilters,
    user: state.loginReducer,
  };
};


export default withRouter(connect(mapStateToProps)(withTheme(MainContainer)));