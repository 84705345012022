import { MDBCol, MDBRow, MDBCard } from 'mdbreact';
import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { withRouter } from "react-router-dom";
import { withTheme } from '../../themes/theming';
import KaizenPoint from './widgets/kaizenPoint';
import ActionItems from './widgets/actionItemsForDealers';
import DealerNoticeBoard from './widgets/dealerNoticeBoard';
import BestPracticeBoard from './widgets/dealerPracticeBoard';
import Activities from './widgets/activities';
import ActionItemReport from './widgets/ActionItemReport';

class DealerWidgetContainer extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    render() {


        return (
            <div style={{paddingBottom:"1rem",paddingTop:"1rem"}}>
                <MDBRow className="mt-3 mb-5 mr-2">
                    <MDBCol lg="2" md="3">
                        <MDBCard className="dealer-scrollable-widgets">
                        <Activities userMdl={this.props.userMdl} />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="2" md="3">
                        <MDBCard className="dealer-scrollable-widgets">
                            <ActionItems userMdl={this.props.userMdl}/>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="2" md="3">
                        <MDBCard className="dealer-scrollable-widgets">
                            <KaizenPoint userMdl={this.props.userMdl} />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="3" md="4">
                        <MDBCard className="dealer-dashboard-Notice-board">
                        <DealerNoticeBoard userMdl={this.props.userMdl} />
                        </MDBCard>
                    </MDBCol>
                    <MDBCol lg="3" md="5">
                        <MDBCard className="dealer-dashboard-Practice">
                        <BestPracticeBoard userMdl={this.props.userMdl}/>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>


                <MDBRow className="mt-3 mb-5 mr-2">
                    <MDBCol>
                        <MDBCard className="dealer-dashboard-table">
                        <ActionItemReport userMdl={this.props.userMdl}/>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
export default withRouter((withTheme(DealerWidgetContainer)));