import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Col, Fa } from "mdbreact";
import { withRouter } from "react-router-dom";
import { fetchDealerListDetails,removeMappings,checkForOngoingActivities,assignDealersFilterData, fecthDealerlanguage} from '../../../actions/userManagementAction.jsx';
import LinkListDealerFilter from './ListLinkDealerFilter';
import ViewListDealerModal from './viewListDealerModal';
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

var I18n = require("react-redux-i18n").I18n;
class ListLinkDealer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchDto: {
        tarsId: this.props.userDto.tarsId,
        networkUserId: this.props.userDto.networkUserId,
        nmscCode: null,
        l1: null,
        l2: null,
        dealer: null,
        l3List: null,
        l4List: null,
        l5List: null,
        l6List: null,
        userNmscCode: this.props.userDto.nmscId,
        networkLevelDealerId: 0,
        businessFunc: '',
      },
      data: [],
      records: 0,
      linkDealerIdList: [],
      mappingIdList:[]
    };
  }


  componentDidMount() {
    let searchDto = { ...this.state.searchDto }
    if (this.props.userDto.nmscId !== null){
      if(this.props.userDto.nmscId.toUpperCase() !== "TME" && this.props.userDto.nmscId.toUpperCase() !== "TMME") {
      searchDto["nmscCode"] = this.props.userDto.nmscId
      this.setState({ searchDto })
    }
    }
    this.props.dispatch(fetchDealerListDetails(searchDto))
    this.props.dispatch(assignDealersFilterData(searchDto))
  }

  componentDidUpdate(prevProps, prevState) {


    if (prevProps.dealerDetailsList !== this.props.dealerDetailsList && this.props.dealerDetailsList !== undefined) {
      if (this.props.dealerDetailsList.records > 0) {
     
        this.setState({ data: this.props.dealerDetailsList.dealerDetailsDTOList });
        this.setState({ records: this.props.dealerDetailsList.records });
        this.setState({linkDealerIdList:[],mappingIdList:[]})
      
      }
      else {
        this.setState({ data: [] })
        this.setState({ records: 0 });
        this.setState({linkDealerIdList:[],mappingIdList:[]})
     //   this.setState({ total: 0 });
      }
    }
  }

  searchDtoUpdate = (searchDto)=>{
    this.setState({searchDto,linkDealerIdList:[],mappingIdList:[]})
  }

  nmscFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.nmscCode}</div>
    );
  }

  franchiseFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.franchise}</div>
    );
  }

  countryFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.country}</div>
    );
  }

  dealerNameFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealerName}</div>
    );
  }

  cityFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.city}</div>
    );
  }

  dealerCodeFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealerCode}</div>
    );
  }

  buttonFormatter = (cell, row) => {
    return (
      <div className="btn-toolbar float-right">
        <a onClick={() => this.toggleView(row)}>
          <Fa icon={"eye"} fixed size="lg" title={I18n.t('Table.view')} />
        </a>
      </div>
    );
  }

  toggleView = (row) => {
    let queryDto={
      dealerUuid : row.dealerUuid
    }
    this.props.dispatch(fecthDealerlanguage(queryDto))
    this.setState({
      modalAction: true,
      selectedRow: row
    });
  }

  toggle = () => {
    this.setState({
      modalAction: false
    });
  }
  removeDispatch =(e)=>{
    let queryDto={
      listDealerId : this.state.linkDealerIdList,
      networkUserId : this.props.userDto.networkUserId
    }
    this.props.dispatch(checkForOngoingActivities(queryDto,this.removePopUP))
  }

  removePopUP =(result)=>{
   confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
            {result?
          I18n.t("ServerMessage.user_referenced_ongoing_activity")+I18n.t("ServerMessage.want_to_remove_mapping"):I18n.t("ServerMessage.want_to_remove_mapping")
            } 
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button className={"buttonCancel-" + this.props.theme.name} onClick={() => {
                onClose()}}>
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.props.dispatch(this.remove)
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    })

  }




  remove=()=>{
    let searchDto = {...this.state.searchDto}
    let queryDto={
      mappingIdList : this.state.mappingIdList,
      networkUserId : this.props.userDto.networkUserId
    }

    this.props.dispatch(removeMappings(queryDto,searchDto))
  }


  componentWillReceiveProps(nextProps) {
  }


  render() {
 
    const { data } = this.state;

    const sizePerPageOptionRenderer = ({
      text,
      page,
      onSizePerPageChange
    }) =>{
        return (
        <li
          key={text}
          role="presentation"
          className="dropdown-item"
        >
          <a
            href="#/"
            tabIndex="-1"
            role="menuitem"
            data-page={page}
            onMouseDown={(e) => {
              e.preventDefault();
              onSizePerPageChange(page);
            }}
            style={{ color: 'red' }}
          >
            {text}
          </a>
        </li>
      )};
    const options = {
      sizePerPageOptionRenderer
    };

    const columns = [
      {
        dataField: "nmscCode",
        text: I18n.t("Table.nmsc"),
        sort: true,
        style: { width: "14%", textAlign: "center" },
        headerStyle: { width: "14%", textAlign: "center" },
        formatter: this.nmscFormatter,
        title: true
      },
      {
        dataField: "franchise",
        text: I18n.t("Table.franchise"),
        sort: true,
        style: { width: "15%", textAlign: "center" },
        headerStyle: { width: "15%", textAlign: "center" },
        formatter: this.franchiseFormatter,
        title: true
      },
      {
        dataField: "country",
        text: I18n.t("Table.country"),
        sort: true,
        style: { width: "13%", textAlign: "center" },
        headerStyle: { width: "13%", textAlign: "center" },
        formatter: this.countryFormatter,
        title: true
      },
      {
        dataField: "dealerName",
        text: I18n.t("Table.dealerName"),
        sort: true,
        style: { width: "15%", textAlign: "left" },
        headerStyle: { width: "15%", textAlign: "left" },
        formatter: this.dealerNameFormatter,
        title: true
      },
      {
        dataField: "city",
        text: I18n.t("Table.city"),
        sort: true,
        style: { width: "13%", textAlign: "left" },
        headerStyle: { width: "13%", textAlign: "left" },
        formatter: this.cityFormatter,
        title: true
      },
      {
        dataField: "dealerCode",
        text: I18n.t("UserManagement.dealerCode"),
        sort: true,
        style: { width: "15%", textAlign: "center" },
        headerStyle: { width: "15%", textAlign: "center" },
        formatter: this.dealerCodeFormatter,
        title: true
      },
      {
        dataField: "",
        text: "",
        style: { width: "10%", textAlign: "center" },
        headerStyle: { width: "10%", textAlign: "center" },
        formatter: this.buttonFormatter,
        title: true
      }
    ];
    const selectRow = {
      mode: 'checkbox',
      clickToSelect: false,
      nonSelectable: this.state.selected,
      selected: this.state.linkDealerIdList,
      onSelectAll: (isSelect, rows, e) => {
       let linkDealerIdList = []
       let mappingIdList = []
        if(isSelect){
          this.state.data.forEach((row) => {
            linkDealerIdList.push(row.networkLevelDealerId);
            mappingIdList.push(row.mappigId);
            
          });
          this.setState({linkDealerIdList})
          this.setState({mappingIdList})
          // console.log(linkDealerIdList)
          // console.log(mappingIdList)
          return linkDealerIdList}
          else{
            this.setState({linkDealerIdList})
            this.setState({mappingIdList})
          }
      },
      onSelect: (row,isSelect, rowIndex,e) => {
        let linkDealerIdList = this.state.linkDealerIdList
        let mappingIdList = this.state.mappingIdList
        let index = linkDealerIdList.indexOf(row.networkLevelDealerId);
        if (isSelect && index === -1) {
          linkDealerIdList.push(row.networkLevelDealerId);
          mappingIdList.push(row.mappigId)
          this.setState({linkDealerIdList})
          this.setState({mappingIdList})
          this.setState({mappingIdList})
          return linkDealerIdList
          } else {
            linkDealerIdList.splice(index, 1);
            mappingIdList.splice(index,1)
            this.setState({linkDealerIdList})
            this.setState({mappingIdList})
          }

    }
  
}
    const rowEvents = {
      
    };
    
    return (
      <div className = "col-md-12 listDealerContainer">
      <Col md="12" className="activityTableContainer">
		  {/* <div className="col-md-12 row control-label noPadding"> */}
      <div className="activity-content">
        <div className="col-md-12 row control-label noPadding">
          <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
            {I18n.t("tmeAdminMaster.search")}
          </label>
          <label
            md="0.5"
            className="control-label boldFont"
            style={{ marginTop: "0.2rem" }}
          >
            <a onClick={() => this.setState((state, props) => { return { showfilter: !state.showfilter } })} >
              {this.state.showfilter ? (
                <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
              ) : (
                  <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                )}
            </a>
          </label>
          <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'} style={{ width: "20%" }}>
            {I18n.t('activity.Dealer')} ({this.state.records})
              </label>
          <label className="control-label" style={{ width: "46%" }} />
          {this.state.showfilter && <LinkListDealerFilter
              isAdmin={this.props.isAdmin}
              userDto={this.props.userDto}
              search = {this.searchDtoUpdate}
            />}
            <div className="col-md-12 searchActivityTable">
            <div className={'linkDealerTable parent-' + this.props.theme.name}>
              <BootstrapTable
                keyField="networkLevelDealerId"
                noDataIndication={I18n.t('UserManagement.No Dealer Found')}
                data={data}
                columns={columns}
                selectRow={selectRow}
                pagination={paginationFactory({options})}
                //onTableChange={onTableChange}
                rowEvents={rowEvents}
              />
              </div>
             </div>
          </div>
        </div>
		{/* </div> */}
      </Col>
       {this.props.isAdmin?
        <div className="col-md-1.5" style={{ borderTop: '0px', padding: '5px 15px 6px 0px', float:'right' }}>
          <button className={"button-" + this.props.theme.name + " noMargin"} disabled={this.state.linkDealerIdList.length>0?false:true} onClick={(e) =>this.removeDispatch(e)} >{I18n.t('tmeAdminMaster.remove')}</button>
        </div>:null
        }
        {this.state.modalAction === true ?
          <ViewListDealerModal
              toggle={this.toggle}
              selectedRow={this.state.selectedRow}
          /> : null
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    // listlinkFilters : state.userManagementReducer.listlinkFilters,
    dealerDetailsList: state.userManagementReducer.dealerDetailsList,
    isOngoingActivities : state.userManagementReducer.isOngoingActivities,
    linkFilter : state.userManagementReducer.linkFilters
  }
}
export default withRouter(connect(mapStateToProps)(withTheme(ListLinkDealer)));
