const translationsSR = {
  sr: {
    Policy: {
      HeaderMsg: "Napomena o privatnosti obrade Vaših ličnih podataka u okviru KET alata.",
      Message1: "Ovo obaveštenje se odnosi na obradu vaših ličnih podataka u okviru KET alata. Molimo da ga pročitate zajedno sa opštom Toyotinom politikom privatnosti i zaštite ličnih podataka u kojoj su opisani načini obrade Vaših ličnih podataka a koju morate da prihvatite da biste nastavili dalje.",
      Message2: "Toyota Motor Europe NV/SA, sa sedištem u 'Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium', kao kontrolor podataka, sakuplja Vaše lične podatke (kako je naznačeno ispod) za svrhe koje su opisane u nastavku.",
      Message3: "Koje ćemo Vaše lične podatke sakupiti?",
      Message4: "Sakupićemo sledeće kategorije Vaših ličnih podataka u vezi sa KET alatom:",
      Table1: {
        tr1: "Kategorije ličnih podataka:",
        tr2: "Identifikacioni broj korisnika",
        tr3: "Ime korisnika (puno ime i prezime)",
        tr4: "Marka vozila (Toyota, Lexus)",
        tr5: "Odabrani jezik",
        tr6: "Povezana organizacija"
      },

      Message5: "Svrha i zakonska osnova za obradu Vaših ličnih podataka",
      Message6: "Mi ćemo obraditi Vaše lične podatke sa svrhom i prema zakonskom osnovu koji koji je opisan ispod:",
      Table2: {
        tr1: {
          th1: "Svrha:",
          th2: "Zakonski osnov:"
        },
        tr2: {
          td1: "KET-1: Performanse dilerstva Kaizen",
          td2: "Legitimni interes kontrolora podataka"
        },
        tr3: {
          td1: "KET-2: IT podrška",
          td2: "Legitimni interes kontrolora podataka"
        }
      },
      Message7: "Period zadržavanja Vaših ličnih podataka",
      Message8: "Vaši lični podaci će biti zadržani u periodu koji je naznačen ispod:",
      Table3: {
        tr1: {
          th1: "Period zadržavanja:",
          th2: "Kategorije ličnih podataka:"
        },
        tr2: {
          td1: "Bilo koja lična informacija, koja je opisana ispod, biće zadržana najviše 5 godina +1 mesec od dana konačnog zatvaranja KET alata. ",
          td2: "Ime, prezime, odabrani jezik, organizacija"
        }
      },
      Message9: "Opcija: U slučaju otkrivanja/prenosa Vaših ličnih podataka u zemlje van Evropske Ekonomske Asocijacije (EEA)",
      Message10: "Vaši lični podaci će biti dostupni korisnicima u zemljama izvan Evropske Ekonomske Asocijacije, kako je naznačeno ispod:",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Zemlja",
          td3: ""
        },
        tr2: {
          td1: "Ne-EEA korisnik",
          td2: "Informacioni sistem",
          td3: "Indija"
        }
      },
      Message11: "Ovim potvrđujem da sam razumeo ovu informaciju; pročitao i prihvatio opšte Toyotine uslove privatnosti i politiku zaštite ličnih podataka."
    },
    ACCESSORIES: "Dodatna oprema",
    activity: {
      Assignee: "Dodeljeno",
      CompletionDate: "Datum završetka",
      Dealer: "Diler",
      FirstVisit: "Prva poseta",
      kpi: {
        kpi: "KPI treba da budu brojevi (+ve ili -ve)",
        kpiLength: "KPI može da ima najviše 10 znakova",
        mandatory: "Unesite važeće podatke u obavezna polja",
        mandatoryTargets: "Unesite sve KPI vrednosti",
        target: "Cilj treba da bude samo broj",
        targetLength: "Cilj može da ima najviše 10 znakova"
      },
      ReminderOn: "Potsetnik uključen",
      statusDD: "Status",
      Toolbox: "Komplet alata",
      activityCreation: "Pokrenut je proces stvaranja aktivnosti",
      activityCreated: "Stvorena je aktivnost"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efikasnost",
    FIX_IT_RIGHT: "Ispravna popravka-FIR",
    footer: {
      message: "Obavezna polja"
    },
    FOUNDATION: "Osnovni upitnik",
    MAINTENANCE_REMINDERS: "Servisni potsetnik",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Osnove",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Aug",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "Maj",
      nov: "Nov",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Nazad",
      closed: "Zatvoreno",
      completed: "Završeno ",
      countryLanguageCode: "Zemlja / Jezični kod ",
      createActivity: "Kreiraj aktivnost",
      createdDate: "Datum kreiranja",
      dataUneditableNote:
        "Napomena: Podaci se ne mogu uređivati nakon čuvanja. Da biste izmenili podatke, obratite se administratoru NMSC.",
      date: "Datum",
      downloadQuestionnaire: "Preuzmi upitnik",
      editActivity: "Uredi aktivnost",
      initialHelpText: "Početno KPI merenje treba da bude prosečna vrednost za poslednjih šest punih meseci",
      inProgress: "U toku",
      name: "Ime i prezime",
      next: "Sledeće",
      noActivities: "Nisu pronađene aktivnosti",
      noResults: "Nema rezultata",
      of: "od",
      ongoing: "Aktuelno",
      open: "Otvoreno",
      page: "Stranica",
      planned: "Planirano",
      problemDescription: "Opis problema",
      signature: "Potpis",
      select: "Odaberi",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Dogodila se greška prilikom preuzimanja podataka.",
      no_published_subquestion: "Objavite upitnik za ovaj komplet alata kako bi započeli ovu aktivnost.",
      activity_upload_error_msg: "Došlo je do greške tokom učitavanja podataka o aktivnostima.",
      action_plan_created: "Plan akcija je uspešno kreiran",
      action_plan_deleted: "Plan akcija je uspešno obrisan",
      action_plan_saved: "Plan akcija je uspešno sačuvan",
      activity_kpi_saved: "KPI akcija su uspešno sačuvani",
      master_kpi_confirm_delete:
        "Da li sigurno želite da obrišete odabrani KPI?",
      master_kpi_created: "Glavni KPI uspešno kreiran",
      master_kpi_deleted: "Glavni KPI je obrisan",
      master_kpi_not_found: "Glavni KPI nije pronađen",
      master_kpi_published: "Izabrani glavni KPI su uspešno objavljeni",
      master_kpi_retired: "Glavni KPI je arhiviran",
      master_kpi_saved: "Glavni KPI uspešno sačuvan",
      subquestion_created: "Potpitanja su uspešno sačuvana",
      subquestion_updated: "Potpitanja su uspešno izmenjena",
      subquestionnaire_not_found: "Potpitanje nije pronađeno",
      subquestion_retired: "Potpitanje je povučeno",
      subquestion_deleted: "Potpitanje je obrisano",
      subquestion_published: "Potpitanje je uspešno objavljeno",
      mainquestion_created: "Glavno pitanje je kreirano",
      mainquestion_deleted: "Glavno pitanje je obrisano",
      mainquestion_updated: "Glavno pitanje je ažurirano",
      duplicateMsg: "Postoje dupli zapisi u Excell datoteci.",
      deleteErr: "Dogodila se greška prilikom brisanja datoteke",
      toolbox_deleted: "Komplet alata je uspešno obrisan",
      toolbox_already_exists: "Ovaj Komplet alata već postoji",
      toolbox_saved: "Komplet alata je sačuvan",
      toolbox_created: "Komplet alata je stvoren",
      toolbox_not_found: "Komplet alata nije pronađen",
      published_content_found: "Ovaj Komplet alata ne može da se obriše zato što poseduje objavljeni sadržaj",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Da li sigurno želite da obrišete?",
      addActionPlanNotAllowed: "Akcioni plan je napravljen ili izmenjen nakon što je aktivnost bila zatvorena, završena ili odbijena.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Da li zaista želite da odbijete ovu aktivnost?Nakon odbijanja izmene nisu moguće.",
      rejectMsg: "Da li zaista želite da odbijete ovu aktivnost?Nakon odbijanja izmene nisu moguće.",
      selectNMSCTab: "Odaberite NMSC jezik za objavljivanje",
      all: "Sve",
      deleteConfirm: "Da li ste sigurni da želite obrisati izabrani KPI?",
      no: "Ne",
      retireConfirm: "Da li ste sigurni da želite arhivirati ovaj KPI? KPI se ne može ponovo aktivirati nakon arhiviranja",
      warning: "Upozorenje",
      yes: "Da",
      saveDataConfirmation: "Jeste li sigurni da želite sačuvati podatke?",
      saveKPIWarning: "Napomena: Podaci se ne mogu uređivati nakon čuvanja. Da biste izmenili podatke, obratite se administratoru NMSC KET",
      addKpiNotAllowed: "KPI za sledeći mesec može da se doda nakon završetka tekućeg meseca.",
      activityLaunch: "Da li ste sigurni da želite da pokrenete ovu aktivnost? Nakon pokretanja, akcija ne može da se opozove i vrati nazad u status planirane ili obrisane.",
      deleteConfirmSubQuestionNaire: "Da li sigurno želite da obrišete odabrano  potpitanje?",
      retireConfirmSubQuestionNaire: "Da li sigurno želite da povučete odabrano potpitanje?",
      deleteConfirmMainQuestion: "Molimo da potvrdite da želite da obrišete glavno pitanje i sa njim povezana potpitanja.",
      deleteConfirmChapter: "Da li sigurno želite da obrišete poglavlje?",
      deleteLibraryFolder: "Da li sigurno želite da obrišete ovu Fasciklu?",
      deletePublishedFolder: "Neke datoteke u ovoj fascikli mogu da budu u vezi sa upitnikom. Da li sigurno želite da za stalno obrišete ovu podfasciklu i uklonite vezu da upitnikom?",
      importQuestion: "Da li ste sigurni da želite da uvezete potpitanja? Postojeća potpitanja će biti zamenjena.",
      noQuestion: "Nema dostupnih pitanja.",
      MandatoryWarning: "Popunite sva obavezna polja za sve lokalne jezike",
      certificationSubmission:
        "Komplet alata je predan u TME na sertifikaciju partnera",
      sameToolBox: "Ne možete da pošaljete isti Komplet alata više od jednom. Odaberite drugi Komplet alata",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Označiti sve",
      unSelectAll: "Poništi sve",
      select:"Označi...",
      warningMsg:"U ovoj aktivnosti postoje otvoreni akcioni planovi. Da li želite da završite aktivnost?",
      updateEaConfirmDealer: "All Activities created for this dealer in 'EA' will be removed. Are you sure you want to continue?"

    },
    status: {
      published: "Objavljeno",
      retired: "Arhivirano",
      saved: "Sačuvano",
      rejected: "Odbijeno",
      open: "Otvoreno",
      closed: "Zatvoreno",
      close: "Zatvoreno",
      ongoing: "Aktuelno",
      completed: "Završeno",
      complete: "Završeno",
      planned: "Planirano",
      overdue: "Prekoračenje roka",
      due: "Rok",
      pending: "Pending",
    },
    Rejected: "Odbijeno",
    Open: "Otvoreno",
    Closed: "Zatvoreno",
    Close: "Zatvoreno",
    Ongoing: "Aktuelno",
    Completed: "Završeno",
    Complete: "Završeno",
    Planned: "Planirano",
    tabDetails: {
      noActionAvailable: "Nema dostupnih radnji",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Akcija",
      activityCompletionDate: "Datum završetka aktivnosti",
      activityCompletionDateFrom: "Datum kraja akcije od",
      activityCompletionDateTo: "Datum kraja akcije do",
      activityStartDate: "Datum početka aktivnosti",
      activityStartDateFrom: "Datum početka akcije od",
      activityStartDateTo: "Datum početka akcije do",
      assignedTo: "Dodeljeno",
      assignee: "Dodeljeno",
      benchmark: "Odrednica",
      city: "Grad",
      completedBy: "Završio ",
      completionBy: "Završetak do",
      completionDate: "Datum završetka",
      counterMeasure: "Protivmera",
      country: "Zemlja",
      createdBy: "Kreirao",
      dealer: "Diler",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Naziv dilera",
      definition: "Definicija",
      description: "Opis",
      dueDate: "Rok",
      endDate: "Datum završetka",
      firstVisit: "Prva poseta",
      followup: "Provera (Follow-up)",
      franchise: "Franšiza",
      history: "Istorija",
      id: "ID",
      initial: "Početno",
      kpi: "KPI",
      launch: "Pokreni",
      mandatory: "Obavezno",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Objavljeno",
      questionReference: "Referenca za pitanje",
      reference: "Referenca",
      reminderOn: "Potsetnik uključen",
      score: "Ocena",
      startDate: "Datum početka",
      status: "Status",
      target: "Target",
      toolbox: "Komplet alata",
      view: "Prikaži",
      kpiResult: "KPI rezultat",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokacija",
      ActionItemReport: "Stavke aktivnosti koje napreduju",
      certification: "Status sertifikacije",
      noOfToolbox: "Broj završenih kompleta alata",
      program: "Program",
      certificationDate: "Datum sertifikacije",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan akcija",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
      },
      tabsDetails: {
      actionPlan: "Plan akcija",
      confirmDeleteMessage: "Da li ste sigurni da želite obrisati izabrani KPI?",
      confirmRetireMessage: "Potvrdite da li želite arhivirati izabrani KPI jer je ovo već objavljeno",
      errorRetireMsg: "Ovaj KPI se ne može arhivirati jer je aktivan obavezni KPI TME",
      import: "Uvezi",
      importKpiMessage: "Uvezite vaš KPI iz liste glavnih KPI TME",
      kpi: "Ključni indikatori učinka (KPI)",
      library: "Biblioteka",
      noActionAvailable: "Nema dostupnih radnji",
      NoKPI: "Nema dostupnih KPI",
      publish: "Objavi",
      questionnaire: "Upitnik",
      tmeKPIList: "Lista glavnih KPI TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Naziv kompleta alata",
      newToolboxName: "Novi naziv",
      deleteToolbox: "Brisanje kompleta alata?",
      programName: "Naziv programa",
      ToolboxName_EN: "Naziv kompleta alata (EN)",
      deleteTool: "Da li sigurno želite da obrišete ovaj komplet alata?",
      selectToolbox: "Odaberite komplet alata za brisanje.",
      editToolBox: "Izmeni komplet alata",
      addToolbox: "Dodaj komplet alata",
      toolbox: "Unesite naziv za ovaj komplet alata.",
      addActionPlan: "Dodaj Plan akcija",
      actionPlanItem: "Plan akcije - Stavka #",
      add: "Dodaj",
      addActivity: "Dodaj aktivnost",
      additionalInformation: "Dodatna informacija",
      benchmark: "Odrednica",
      cancel: "Otkaži",
      createEditActivity: "Kreiraj/uredi aktivnost",
      createKPI: "Kreiraj KPI",
      delete: "Obriši",
      download: "Preuzmi",
      edit: "Uredi",
      editKPI: "Uredi KPI",
      keyPerformanceIndicator: "Ključni indikator učinka",
      kpiDefinition: "Definicija KPI",
      mandatoryKpi: "Obavezni KPI",
      save: "Sačuvaj",
      search: "Pretraga",
      unit: "Jedinica",
      update: "Ažuriraj",
      validData: "Unesite važeće podatke",
      hide: "Sakrij",
      show: "Prikaži",
      upload_dealer_network: "Učitaj dilersku mrežu",
      upload_question: "Učitaj pitanja",
      upload_user: "Učitaj podatke o korisniku",
      upload_activity: "Učitaj aktivnosti",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Rok je prekoračen.",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Please answer all questions in the in order to be able to close this Activity.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Unesite početno merenje KPI parametara, ciljeve KPI parametara i najmanje mesec dana merenja kako biste nastavili.",
      initialTarget: "Unesite početne i ciljane vrednosti KPI kako bi ste nastavili dalje.",
      upload_error: "Došlo je do greške tokom učitavanja dilerske mreže.",
      action_item_create_warning: "Dozvoljeno je spojiti najviše pet zapisa u jednu stavku aktivnosti.",
      actionPlanStartDateValidation: "Datum završetka ne može biti pre datuma početka",
      actionsNotClosed: "U planu akcija postoje radnje koje su u toku; zatvorite sve radnje pre zatvaranja aktivnosti",
      activityDuplicate: "Već postoji druga aktivnost za istog dilera i kompletu alatki",
      assigneeNotExist: "Izabrana osoba nije registrovana u sistemu",
      assigneeValidation: "Nevažeća osoba",
      atleastOnePublish: "Izaberite najmanje jedan KPI za objavljivanje",
      cannotRetireActive: "Ovo je obavezni aktivni TME KPI koji se ne može arhivirati ",
      completionDate: "Datum završetka ne može biti pre datuma prve posete",
      confirDeletePlannedActivity: "Da li ste sigurni da želite obrisati ovu planiranu aktivnost?",
      DateformatValidation: "Datum treba da bude u formatu DD/MM/GGGG",
      DateValidation: "Datum početka treba da bude pre datuma završetka",
      dealerValidation: "Nevažeći diler",
      deleteSelectedConfirm: "Da li ste sigurni da želite obrisati izabrani KPI?",
      duplicate: "Već postoji drugi KPI sa istim nazivom i definicijom",
      modalMandatory: "Unesite važeće podatke u obavezna polja",
      noPublishKpi: "KPI još nisu objavljeni za izabrani komplet alatki",
      validSearchCriteria: "Unesite važeće kriterijume za pretraživanje",
      multiSelectPlaceHolder: "Unesi najmanje 5 znakova",
      multiSelectPlaceHolderUser: "Unesi najmanje 3 znakova",
      startDateValidation: "Datum početka ne može da bude veći od datuma završetka",
      fileSelectionErr: "Najpre odaberite datoteku",
      atleastOneQuestion: "Molimo da odaberete najmanje jedno potpitanje za uvoz.",
      atleastOneSubQues: "Odaberite najmanje jedno podpitanje za objavljivanje",
      uploadErr: "Broj datoteka koje se nisu učitale:",
      uploadSuccess: "Dilerska mreža je uspešno učitana.",
      file_upload_success: "Datoteke du uspešno učitane.",
      KPIinOngoingClosedActivity: "Ovaj KPI se nalazi u jednoj ili više tekućih/zatvorenih aktivnosti. Završite ove aktivnosti pre bilo kakve promene KPI",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Aktivnosti",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Početak",
      logout: "Odjava",
      MasterDataUpload: "Otpremi glavne podatke",
      Toolbox: "Komplet alata",
      welcome: "Dobro došli",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Dodaj potpitanje",
      editSubQuestion: "Izmeni potpitanje",
      subQuestionTitle: "Potpitanje",
      additionalInfo: "Dodatna informacija",
      na: "Dodaj 'Nije primenjivo' kao mogući odgovor na ovo pitanje",
      question: "Pitanje",
      questionTitle: "Naslov pitanja",
      why: "Zašto?",
      addMainQuestion: "Dodaj glavno pitanje",
      editMainQuestion: "Izmeni glavno pitanje",
      previousQuestion: "Prethodno pitanje",
      nextQuestion: "Sledeće pitanje",
      what: "Šta?",
      library: "Biblioteka",
      subQuestionTable: {
        no: "Ne",
        subQuestion: "Potpitanje",
        status: "Status",
        publishedDate: "Datum objavljivanja",
        notApplicable: "Nije primenjivo"
      },
      links: {
        question_link_button: "Veza",
        question_link_include_folder: "Uključite podfasciklu",
        question_link_add_link: "Poveži datoteku iz biblioteke",
        question_link_view_folder: "Prikaži biblioteku"
      },
      mainQuestion: "Novo/izmenjeno glavno pitanje:",
      subQuestion: "Novo/izmenjeno potpitanje:",
      retiredQuestion: "Povučeno potpitanje:",
      libraries: "Novi/izmenjeni fajl u biblioteci:",
      noFiles: "Nema raspoloživih datoteka."
    },
    library: {
      selectFile: "Odaberite najmanje jednu datoteku.",
      fileSelected: "Odabrane datoteke",
      publish_library_file: "Odaberite najmanje jednu datoteku za objavljivanje",
      library_files_deleted: "Da li sigurno želite da obrišete ovu datoteku?",
      library_files_linked_deleted: "Da li sigurno želite da obrišete ovu datoteku i sve veze koje postoje u upitniku?",
      max_size_error: "Veličina datoteke premašuje ograničenje od 1GB.",
      empty_file_desc: "Unesite opis datoteke",
      file_not_selected: "Odaberite datoteku koju želite da učitate (max.1GB)",
      file_type_error: "Ovaj tip fajla nije dozvoljen.",
      file_name_error: "Pogrešan karakter u nazivu fajla.",
      file_name_lenght_error: "Naziv fajla može da ima najviše 50 karaktera.",
      addFolder: "Dodaj Fasciklu",
      editFolder: "Izmeni Fasciklu",
      currentFolder: "Naziv Fascikle",
      parentFolder: "Naziv matične Fascikle",
      emptyFolderName: "Naziv Fascikle ne može da bude prazan",
      invalidFolderName: "Ime datoteke ne može da sadrži '/'.",
      folder_created: "Naziv Fascikle je uspešno kreiran",
      folder_updated: "Naziv Fascikle je uspešno izmenjen",
      folder_not_found: "Fascikla nije pronađena",
      lib_invalid_toolbox: "Odabran je pogrešan 'Toolbox'",
      lib_folder_already_exists: "Fascikla već postoji.",
      lib_folder_deleted: "Fascikla je uspešno obrisana.",
      library_files_published: "Fajl je objavljen",
      addFile: "Dodaj fajl",
      editFile: "Izmeni fajl",
      fileName: "Naziv fajla",
      upload: "Uvezi",
      desc: "Opis",
      folderCreationErr: "Hijerarhija je ograničena na dva nivoa.",
      subFolderWarning: "Odaberite podfasciklu u koju ćete dodati datoteke",
      deleteWarning: "Odaberite fasciklu za brisanje",
      beforeAfterNotSelected: "Molimo odaberite da li je dokaz pre ili posle Kaizen aktivnosti.",
    },
    chapter: {
      chapter_exist: "Ovo poglavlje već postoji.",
      chapter_saved: "Poglavlje je sačuvano",
      chapter_cannot_delete: "Ovo poglavlje ne može da bude obrisano zato što su potpitanja već objavljena.",
      failed: "Neuspešno!",
      chapter_deleted: "Poglavlje je obrisano",
      chapter_nonEmpty: "Napravite naziv poglavlja.",
      addchapter: "Dodaj poglavlje",
      editChapter: "Uredi poglavlje",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Prilozi",
      comments: "Komentari",
      evidence: "Dokaz",
      evidences: "Dokazi",
      yes: "Da",
      no: "Ne",
      na: "N/A",
      uploadEvidence: "Učitaj dokaze",
      fileUpload: "Odaberite datoteku koju želite da učitate (max.1GB)",
      who: "Ko?",
      answer: "Odgovori",
      comment: "Comment",
      date: "Datum",
      submit: "Pošalji",
      commentMsg: "Unesite komentar.",
      noCommentAvailable: "Nema raspoloživih komentara.",
      uploadSuccessMsg: "Datoteke du uspešno učitane.",
      uploadErrorMsg: "Broj datoteka koje se nisu učitale:",
      fileWithSameName: "Postoji još jedna datoteka sa istim nazivom.",
      noEvidence: "Nema dostupnih dokaza",
      createAction: "Napravi stavku aktivnosti",
      chapterName: "Naziv poglavlja",
      questionNo: "Pitanje br.",
      when: "Kada?",
      noRecords: "Nema raspoloživih zapisa.",
      kaizenPoints: "Kaizen bodovi",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Pitanje",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Da li je ovo dokaz pre ili posle Kaizen aktivnosti?",
      move:"Premesti",
      moveMsg: "Datoteke su uspešno premeštene.",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Pregled vozila – WAC",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Limarija i lakiranje",
      TSM_USED_CAR: "Polovni automobili",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Tabla sa napomenama",
      kpi_target_not_set: "KPI ciljevi nisu postavljeni",
      kpi_initial_not_set: "Početne vrednosti KPI nisu postavljene",
      kpi_monthly_not_set: "Mesečni KPI nije postavljen",
      kpi_below_target: "KPI je ispod trenutnog cilja",
      activation: "Aktivacija",
      users: "Korisnici",
      nmscs: "NMSC's",
      activeRetailer: "Aktivni dileri",
      certificationRate: "Stopa sertifikacije",
      reCertificationRate: "Stopa re-sertifikacije",
      activityDue: "Rok za aktivnost",
      avgdays: "Prosečan broj dana/Toolbox",
      overDueAct: "Aktivnosti koje sa isteklim rokom",
      actionItem: "Stavke aktivnosti",
      bussResult: "Poslovni rezultati",
      toolboxImpl: "Implementacija kompleta alata",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Nedavne izmene",
      rolloutTopNmsc: "Rollout rezultati - Najbolji NMSC",
      bestPractice: "Tabla sa najboljim praksama",
      availableKpi: "Raspoloživi KPI",
      selectedKpi: "Odaberi KPI",
      dealerBestPractice: "Pregled dobre prakse kod Dilera",
      kpiList: "Lista KPI",
      go: "KRENI",
      nodata: "Podaci nisu dostupni",
      businessResultTop: "Poslovni reziltati - Najbolji Dileri",
      businessResultNMSC: "Poslovni rezultati - Najbolji NMSC"


    },
    dealerCertification: {
      dealerCertification: "Sertifikacija dilera.",
      readyForCertification: "Spremno za sertifikaciju",
      certification: "Sertifikacija dilera.",
      submittedOn: "Podneto na",
      certificateDate: "Datum sertifikata",
      reCertificateDate: "Datum obnovljenog sertifikata",
      toolboxDetails: "Detalji kompleta alata",
      approve: "Odobri",
      reject: "Odbaci",
      pending: "Na čekanju",
      region: "Region"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      Assign: "Assign",
      List: "List",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsSR;
