import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow } from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
var I18n = require("react-redux-i18n").I18n;
class BusinessResultsTopNmsc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            nmscFlag: false,
            countryValue: null
        };
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.businessResults && nextProps.businessResults.BUSINESS_RESULT && nextProps.businessResults.BUSINESS_RESULT.length > 0) {
            this.setState({ data: nextProps.businessResults.BUSINESS_RESULT, isLoading: false });
        }
            // if (nextProps.businessResults.DEALER_KPI && nextProps.businessResults.DEALER_KPI.length > 0) {
            //     this.setState({ dealerKpi: nextProps.businessResults.DEALER_KPI });
            // }
    
        else{
            this.setState({ data: [], isLoading: false });
        }

        if (this.state.nmscFlag && (nextProps.businessResults.COUNTRY !== null || !nextProps.businessResults.COUNTRY)) {
            var countryValue = {
                value: nextProps.businessResults.COUNTRY, label: nextProps.businessResults.COUNTRY
            }
            this.setState({ countryValue });
        }
        
        if (nextProps.filters.nmscCode) {
            this.setState({ countryList: nextProps.filters.l2 });
        }
    }

    componentWillMount() {
    
        let roles = [];
        roles = this.props.user.userMdl.roles;
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET NMSC USER" || roles[i].toUpperCase() === "KET NMSC EXTERNAL" || roles[i].toUpperCase() === "KET NMSC FIELD") {
                this.setState({ nmscFlag: true });
                break;
            }
        }
        for (var j = 0; j < roles.length; j++) {
            if (roles[j].toUpperCase() === "KET NMSC ADMIN") {
                this.setState({ nmscAdminFlag: true });
                break;
            }
        }

    }

    getPercentageRow = (percentage) => {
        let textclass = "";
        let value = percentage;
        if (percentage < 0) {
            textclass = 'red-font';
            value = "-  "+ Math.abs(Math.round(percentage)) + ' %';
        } else if (percentage >= 0) {
            textclass = "green-font";
            value = "+  "+ Math.round(percentage) + ' %';
        }else if(percentage === '-'){
            textclass = "no-font";
            value = percentage;
        } 
        return <MDBCol lg="3" md="3"  style={{textAlign:"right", marginTop:"5px"}} className={textclass}>{value}</MDBCol>;
    }
    render() {
        return (
            <div style={{ width: "100%" }}>
                <div className={"widget-header left-align-header widget-header-" + this.props.theme.name} title={this.state.nmscFlag ? I18n.t('dashboard.businessResultTop'):I18n.t('dashboard.businessResultNMSC')}>
                {this.state.nmscFlag ? I18n.t('dashboard.businessResultTop'):I18n.t('dashboard.businessResultNMSC')} 
                    {this.state.nmscFlag ? <label style={{ float:"right",marginRight:"0.5rem",marginBottom:"0rem" }}>{this.state.countryValue ? this.state.countryValue.label : ""}</label> : ""}
                    {/* <Fa icon="filter" className="widget-filter-icon" /> */}
                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="medium-widget-content">
                      {/*   <MDBRow className="pl-1 ml-1 mr-1">
                            <MDBCol lg="8" md="8" className="br-table-header">KPI</MDBCol>
                            <MDBCol lg="2" md="2" className="br-table-header">NMSC</MDBCol>
                            <MDBCol lg="2" md="2" className="br-table-header">+/-</MDBCol>
                        </MDBRow> */}
                        {this.state.data.length > 0 ?
                            this.state.data.map((row, index) => {
                                return (
                                    (this.state.nmscFlag ?
                                    <MDBRow key={index} className="br-table-row pl-1 report-bottom-border" style={{height:"25px"}}>
                                        <MDBCol lg="6" md="6"  title={row.topRetailer ? row.topRetailer + "-" + row.dealerCity:""} style={{marginTop:"3px"}} className="text-ellipsis">{row.kpiName}</MDBCol>
                                        {/* <MDBCol lg="2" md="2">{row.topNmsc}</MDBCol> */}
                                        <MDBCol lg="3" md="3" className="text-alignment" style={(row.targetUnit && row.targetUnit !== "")? {paddingLeft: "1.5rem",marginTop:"3px"} : {paddingLeft: "1.5rem",marginTop:"3px"}}>{(row.targetUnit && row.targetUnit !== "") ? row.targetUnit : "-"}</MDBCol>
                                        {this.getPercentageRow(row.retailerPercentage)}
                                    </MDBRow>:
                                    <MDBRow key={index} className="br-table-row pl-1 report-bottom-border" style={{height:"25px"}}>
                                        <MDBCol lg="6" md="6" title={row.toolboxName + "-" + row.kpiName} style={{marginTop:"3px"}} className="text-ellipsis">{row.kpiName}</MDBCol>
                                        <MDBCol lg="3" md="3" style={{marginTop:"5px"}}>{row.topNmsc}</MDBCol>
                                        {this.getPercentageRow(row.nmscPercentage)}
                                    </MDBRow>)
                                )
                            })
                            : <MDBRow className="pl-4">{I18n.t('evaluation.noRecords')}</MDBRow>}
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        businessResults: state.dashboardReducer.businessResults,
        user: state.loginReducer,
        filters: state.dashboardReducer.filters
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(BusinessResultsTopNmsc)));
