import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { toastr } from "react-redux-toastr";
import * as AppConst from "../../../AppConstant";
import "../index.css";
import {
  fetchSubQueInfo,
  fetchQueInfo,
} from "../../../actions/activityAction.jsx";
import {
  fetchKaizenPoints,
  downloadKaizenPoints,
} from "../../../actions/kaizenAction";
import QuestionInfo from "../../questionInfo";
var I18n = require("react-redux-i18n").I18n;

class kaizenPoints extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      kaizenPoints: [],
      selectedToDownload: [],
      selectedToCreateAction: [],
      activityDetails: {},
      modalAction: true,
      isInternalUser: false,
      quesModal: false,
    };
    this.createActionItem = this.createActionItem.bind(this);
    this.toggleAction = this.toggleAction.bind(this);
  }
  componentDidMount() {}

  componentWillMount() {
    // console.log(this.props.activityStatus);
    if (
      this.props.activityStatus !== "Closed" &&
      this.props.activityStatus !== "Completed" &&
      this.props.activityStatus !== "Rejected"
    ) {
      this.setState({ actionItemToBeCreated: true });
    } else {
      this.setState({ actionItemToBeCreated: false });
    }
    this.props.dispatch(fetchKaizenPoints(this.props.activityId));

    if (this.props.user.userMdl.activeProgram !== AppConst.activeProgramNas) {
      this.props.dispatch(fetchSubQueInfo(this.props.activityId));
    } else {
      this.props.dispatch(fetchQueInfo(this.props.activityId));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.kaizenPoints.kaizenPointsData) {
      this.setState({ kaizenPoints: nextProps.kaizenPoints.kaizenPointsData });
    }
    if (nextProps.evaluation.evaluationSubQuestion) {
      this.setState({
        evaluationSubQuestion: nextProps.evaluation.evaluationSubQuestion,
      });
    }
  }

  downloadPdf = () => {
    this.props.dispatch(
      downloadKaizenPoints(
        this.props.activityId,
        this.props.user.userMdl.lang,
        this.props.theme.name,
        this.state.selectedToDownload,
        this.props.user.userMdl.nmsc
      )
    );
  };
  toggleAction = () => {
    this.setState({
      modalAction: !this.state.modalAction,
      modalFor: "Create",
    });
  };
  // createActionItem=()=>{
  //     if(this.state.selectedToCreateAction.length >0 && this.state.selectedToCreateAction.length <=5 ){
  //         this.props.createActionItem(this.state.selectedToCreateAction)
  //     }else{
  //         if(this.state.selectedToCreateAction.length <=0){
  //             //..
  //         }else{
  //             toastr.error(I18n.t("toastr.action_item_create_warning"));
  //         }
  //     }

  // }

  createActionItem = () => {
    if (this.state.selectedToCreateAction.length === 1) {
      this.props.createActionItem(this.state.selectedToCreateAction);
    } else if (this.state.selectedToCreateAction.length > 1) {
      toastr.error(I18n.t("toastr.action_item_create_warning_oneItem"));
    }
  };

  // isCriticalFormatter = (cell, row) => {
  //     if (row.isCritical === true) {
  //       return <input type="checkbox" checked disabled />;
  //     } else {
  //       return <input type="checkbox" disabled />;
  //     }
  //   };

  isCriticalFormatter = (cell, row) => {
    return row.isCritical ? "Yes" : "No";
  };

  subQuesDescriptionFormatter = (cell, row) => {
    return row.subQuestionName;
  };

  // quesRefFormatterNew=(cell, row)=>{
  //     let questionRef=cell.split(',')
  //     let QuesList=this.props.subQuesInfo
  //     // console.log("QuesList",QuesList)
  //     let quesInfo=[]
  //     let quesInfoDesc=''
  //      quesInfo=questionRef.map(function(x,i){
  //         if(QuesList!==undefined && QuesList[x]){
  //             return QuesList[x].subQuesDescription
  //         }
  //     })
  //     // console.log("quesInfo",quesInfo)
  //     if(quesInfo.length>0){
  //         quesInfoDesc = quesInfo.toString()
  //     }
  //     return (
  //         <div >
  //             {quesInfoDesc}
  //         </div>
  //     );
  // }
  // quesRefFormatter=(cell, row)=>{
  //     let questionRef=cell.split(",")
  //     return (
  //         <div className="btn-toolbar  queslink" style={{ display:'flex',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} onClick={() => this.toggleQuesInfo(questionRef)}>
  //          <u> {cell}</u>
  //         </div>
  //     );

  // }
  // toggleQuesInfo=action=>{

  //     let QuesList=this.props.subQuesInfo
  //     let quesInfo=[]
  //      quesInfo=action.map(function(x,i){
  //         if(QuesList[x]){
  //             return {ques:x,info:QuesList[x].subQuesDescription}
  //         }else{
  //             return {ques:x,info:""}

  //         }
  //     })

  //     this.setState({
  //         quesModal: !this.state.quesModal,
  //         modalFor: quesInfo

  //     });
  // }

  preventDefault() {}
  render() {
    const combinedData = this.state.kaizenPoints.map((point) => {
      // Find the matching subQuestion based on a unique identifier( evaluationId)
      const matchingSubQuestion = this.state.evaluationSubQuestion.find(
        (subQuestion) => subQuestion.evaluationId === point.evaluationId
      );
      return {
        ...point,
        subQuestionName: matchingSubQuestion?.name || [{}],
        isCritical: matchingSubQuestion?.isCritical || [{}],
      };
    });

    var isInternalUser = false;
    // console.log(this.props.user.userMdl.accessControl);
    if (
      !this.props.user.userMdl.accessControlList.filter(
        (obj) => obj.function === "ACTIVITY_EVALUATION_ACTION"
      )[0]
    ) {
      isInternalUser = true;
    }
    const columns = [
      {
        dataField: "chapterName",
        text: I18n.t("evaluation.chapterName"),
        title: true,
        style: {
          width: this.props.user.userMdl.activeProgram == "NAS" ? "15%" : "26%",
          textAlign: "left",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
          maxWidth: "0",
          textTransform: "uppercase",
        },
        headerStyle: { width: this.props.user.userMdl.activeProgram == "NAS" ? "12%" : "23%", textAlign: "left" },
        sort: true,
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
      {
        dataField: "questionNo",
        text: I18n.t("evaluation.no"),
        title: true,
        style: { width: "7.5%", textAlign: "left" },
        headerStyle: { width: "7.5%", textAlign: "center" },
        sort: true,
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
      {
        dataField: "subQuestionName",
        text: I18n.t("questionnaire.subQuestionTable.subQuestion"),
        sort: true,
        formatter: this.subQuesDescriptionFormatter,
        style: { fontSize: "11.8px", width: "19%", textAlign: "center" },
        headerStyle: { width: "19%", textAlign: "center" },
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
      {
        dataField: "isCritical",
        text: I18n.t("questionnaire.subQuestionTable.isCritical"),
        sort: true,
        formatter: this.isCriticalFormatter,
        style: { fontSize: "11.8px", width: this.props.user.userMdl.activeProgram == "NAS" ? "16%" : "15%", textAlign: "center" },
        headerStyle: { width: "14%", textAlign: "center" },
        hidden:
          this.props.user.userMdl.activeProgram != AppConst.activeProgramNas
            ? true
            : false,
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },

      {
        dataField: "who",
        text: I18n.t("evaluation.who"),
        style: {
          width: "15%",
          textAlign: "left",
          wordWrap: "break-word",
          wordBreak: "break-all",
        },
        headerStyle: { width: "14%", textAlign: "left" },
        sort: true,
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
      {
        dataField: "when",
        text: I18n.t("evaluation.when"),
        style: {
          width: "15%",
          textAlign: "left",
          wordWrap: "break-word",
          wordBreak: "break-all",
        },
        headerStyle: { width: "13%", textAlign: "left" },
        sort: true,
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
      {
        dataField: "comments",
        text: I18n.t("evaluation.comments"),
        style: {
          width: "15%",
          textAlign: "left",
          wordWrap: "break-word",
          wordBreak: "break-all",
        },
        headerStyle: { width: "15%", textAlign: "left" },
        classes: "kaizenPtClsColumnBootStrap",
        headerClasses: "kaizenPtHeaderClsColumnBootStrap",
        bodyClasses: "kaizenPtBodyClsColumnBootStrap",
      },
    ];
    <BootstrapTable
      keyField="chapterName"
      data={kaizenPoints}
      columns={columns}
    />;

    const defaultSorted = [
      {
        dataField: "chapterName",
        order: "desc",
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        if (e.target.type === "checkbox" || e.target.cellIndex === 0) {
          e.target.checked = true;
          var index = this.state.selectedToDownload.indexOf(row.evaluationId);
          if (index > -1) {
            this.state.selectedToDownload.splice(index, 1);
            this.state.selectedToCreateAction.splice(index, 1);
          } else {
            this.state.selectedToDownload.push(row.evaluationId);
            this.state.selectedToCreateAction.push(row);
          }

          this.setState({ selectedToDownload: this.state.selectedToDownload });
        }
      },
    };

    const selectRow = {
      mode: "checkbox",
      selected: this.state.selectedToDownload,
      onSelectAll: (isSelect, rows, e) => {
        let selectedRows = [];
        let selectedToCreateAction = [];
        rows.forEach((row) => {
          selectedRows.push(row.evaluationId);
          selectedToCreateAction.push(row);
        });
        if (isSelect && selectedRows.length > 0) {
          this.setState({
            selectedToDownload: selectedRows,
            selectedToCreateAction: selectedToCreateAction,
          });
          return selectedRows;
        } else {
          this.setState({ selectedToDownload: [], selectedToCreateAction: [] });
        }
      },
    };

    return (
      <div className="kaizenModelMain">
        <Modal
          centered
          className={
            "kaizenPointsModal kaizenPointsModal-" + this.props.theme.name
          }
          isOpen={true}
          style={{ maxWidth: "30%!important" }}
          toggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={() => this.props.toggleKaizen()}>
            {I18n.t("evaluation.kaizenPoints")}
          </ModalHeader>
          <ModalBody>
            <BootstrapTable
              keyField="evaluationId"
              data={this.state.kaizenPoints}
             //data={combinedData}
              columns={columns}
              selectRow={selectRow}
              hover
              condensed
              rowEvents={rowEvents}
              noDataIndication={I18n.t("evaluation.noRecords")}
            />
          </ModalBody>
          <ModalFooter>
            {this.state.selectedToDownload.length > 0 &&
            !isInternalUser &&
            this.state.actionItemToBeCreated ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.createActionItem();
                }}
                disabled={this.state.selectedToCreateAction.length === 0}
              >
                {I18n.t("evaluation.createAction")}
              </button>
            ) : (
              <button className={"button-" + this.props.theme.name} disabled>
                {I18n.t("evaluation.createAction")}
              </button>
            )}
            {this.state.selectedToDownload.length > 0 ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.downloadPdf}
              >
                {I18n.t("tmeAdminMaster.download")}
              </button>
            ) : (
              <button className={"button-" + this.props.theme.name} disabled>
                {I18n.t("tmeAdminMaster.download")}
              </button>
            )}

            {/* {this.state.selectedToDownload.length === 0 ? (
                <button className={"button-" + this.props.theme.name} disabled>
                  {I18n.t("evaluation.createAction")}
                </button>
              ) : this.state.selectedToDownload.length === 1 ? (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={() => {
                    this.createActionItem();
                  }}
                >
                  {console.log(
                    "selectedDownload",
                    this.state.selectedToDownload.length
                  )}
                  {I18n.t("evaluation.createAction")}
                </button>
              ) : (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={() => {
                    alert(
                      "User cannot create a single action plan for multiple Kaizen points"
                    );
                  }}
                >
                  {I18n.t("evaluation.createAction")}
                </button>
              )}
              {this.state.selectedToDownload.length > 0 ? (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={this.downloadPdf}
                >
                  {I18n.t("tmeAdminMaster.download")}
                </button>
              ) : (
                <button className={"button-" + this.props.theme.name} disabled>
                  {I18n.t("tmeAdminMaster.download")}
                </button>
              )}
 */}
          </ModalFooter>
        </Modal>

        {this.state.quesModal === true ? (
          <QuestionInfo
            modal={this.state.quesModal}
            toggle={this.toggleQuesInfo}
            modalFor={this.state.modalFor}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    kaizenPoints: state.kaizenReducer,
    evaluation: state.evaluationReducer,
    subQuesInfo: state.activityReducer.subQuesInfo,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(kaizenPoints)));
