import React from "react";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from "reactstrap";
import Select from "react-select";
import { withTheme } from "../../themes/theming";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePicker from "react-datepicker";
import { confirmAlert } from "react-confirm-alert"; // Import
import { saveActivity } from "../../actions/tabAction.jsx";
import { getEvaluationProgressBar } from "../../actions/evaluationAction.jsx";
import { fetchNmscToolboxByType } from "../../actions/leftNavAction.jsx";
import {
  fetchDealer,
  fetchAssignee,
  fetchActivityStatus,
  fetchDealerActivityDetails,
  fetchToolBoxForTme,
  fetchActionPlans
} from "../../actions/activityAction.jsx";

import "react-datepicker/dist/react-datepicker.css";
import "./index.css";
import Autosuggest from "react-autosuggest";
import moment from "moment";
import "moment-timezone";
var I18n = require("react-redux-i18n").I18n;

class ActivityModal extends React.Component {	
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      selectedRow: this.props.selectedRow,
      value: "",
      suggestions: [],
      leftNavNamesList: [],
      valueAssignee: "",
      suggestionsAssignee: [],
      rowStatus: "",
      statusList: [],
      retailerActualList: [],
      ansSubquestionList: [],
      activityDto: {
        nmscCode: null,
        loggedInUser: this.props.user.userMdl.username,
        toolboxId: null,
        userId: null,
        status: null,
        score: null,
        activityId: null,
        loginId: null,
        dealerId: null,
        langCode: null,
        businessAreaKey: null,
        businessArea: 
        this.props.user.userMdl.businessAreaKey ==="EA" ? "VALUE_CHAIN,BRiT" : this.props.user.userMdl.businessAreaKey
      },
      statusRefreshRequired: true,
      statusChangeFlag: false
    };
  }

  onDealerChange = (event, { newValue }) => {
    var modifiedValue, modifiedId;
    if (newValue.indexOf("_") > -1) {
      modifiedValue = newValue.substr(0, newValue.indexOf("_"));
      modifiedId = newValue.substr(newValue.indexOf("_") + 1);
    } else {
      modifiedValue = newValue;
      modifiedId = "";
    }
    this.setState({
      value: modifiedValue
      //   dealerId: modifiedId
    });
    this.state.selectedRow["dealerId"] = modifiedId;
    this.setState({ valueAssignee: "" });
    if (modifiedId !== "") {
      this.props.dispatch(fetchAssignee(this.state.selectedRow.dealerId, this.props.user.userMdl.activeProgram));
    }
  };
  onAssigneeChange = (event, { newValue }) => {
    var modifiedValue, modifiedId;
    if (newValue.indexOf("_") > -1) {
      modifiedValue = newValue.substr(0, newValue.indexOf("_"));
      modifiedId = newValue.substr(newValue.indexOf("_") + 1);
    } else {
      modifiedValue = newValue;
      modifiedId = "";
    }
    this.setState({
      valueAssignee: modifiedValue,
      idAssignee: modifiedId
    });
    this.state.selectedRow["userId"] = modifiedId;
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value)
    });
  };
  onSuggestionsFetchRequestedAssignee = ({ value }) => {
    this.setState({
      suggestionsAssignee: this.getAssigneeSuggestions(value)
    });
  };
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionsClearRequestedAssignee = () => {
    this.setState({
      suggestionsAssignee: []
    });
  };

  saveActivity = (e, valueAssignee) => {
    e.preventDefault();

    if (
      this.state.selectedRow &&
      this.state.selectedRow.firstVisit !== null &&
      this.state.selectedRow.firstVisit !== ""
    ) {
      var firstVisitDt = moment(this.state.selectedRow.firstVisit);
      this.state.selectedRow.firstVisit = firstVisitDt.format("YYYY-MM-DD");
    }
    if (
      this.state.selectedRow &&
      this.state.selectedRow.completionOn !== null &&
      this.state.selectedRow.completionOn !== ""
    ) {
      var completionOnDt = moment(this.state.selectedRow.completionOn);
      this.state.selectedRow.completionOn = completionOnDt.format("YYYY-MM-DD");
    }
    if (
      this.state.selectedRow &&
      this.state.selectedRow.reminderOn !== null &&
      this.state.selectedRow.reminderOn !== ""
    ) {
      var reminderOnDt = moment(this.state.selectedRow.reminderOn);
      this.state.selectedRow.reminderOn = reminderOnDt.format("YYYY-MM-DD");
    }

    if (this.props.user.userMdl.activeProgram === "BRiT") {
      var isEmpty = false;

      for (var i = 0; i < this.state.retailerActualList.length; i++) {
        if (this.state.retailerActualList[i].initial === null ||
          this.state.retailerActualList[i].initial === '' || !this.state.retailerActualList[i].initial) {
          isEmpty = true;
          break;
        }
      }

      if (this.state.retailerActualList.length === 0) {
        isEmpty = true;
      }

      if ((this.state.selectedRow.status === "COMPLETED" || this.state.selectedRow.status === "CLOSED") && isEmpty && this.state.selectedRow.toolboxId !== 1) {
        toastr.error(I18n.t("toastr.retailerActual"));
        return;
      }
    }

    if (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS") {
      isEmpty = false;

      for (var i = 0; i < this.state.retailerActualList.length; i++) {
        if (!this.state.retailerActualList[i].initial ||
          !this.state.retailerActualList[i].target ||
          this.state.retailerActualList[i].initial === null ||
          this.state.retailerActualList[i].initial === '' ||
          this.state.retailerActualList[i].target === null ||
          this.state.retailerActualList[i].target === '' || 
          ((!this.state.retailerActualList[i].monthlyTarget ||
          this.state.retailerActualList[i].monthlyTarget.length===0) && this.props.user.userMdl.activeProgram !== "TSM_SURVEYS")) {
          isEmpty = true;
          break;
        }
      }

      if (this.state.retailerActualList.length === 0) {
        isEmpty = true;
      }

      if ((this.state.selectedRow.status === "COMPLETED" || this.state.selectedRow.status === "CLOSED") && isEmpty && this.state.selectedRow.toolboxId !== 1) {
        this.props.user.userMdl.activeProgram !== "TSM_SURVEYS" ?
        toastr.error(I18n.t("toastr.initialTargetMonthly")) : toastr.error(I18n.t("toastr.initialTarget"))
        return;
      }
    }

    if (this.props.user.userMdl.activeProgram === "EA") {
      isEmpty = false;

      for (var i = 0; i < this.state.retailerActualList.length; i++) {
        if (!this.state.retailerActualList[i].initial ||
          !this.state.retailerActualList[i].target ||
          this.state.retailerActualList[i].initial === null ||
          this.state.retailerActualList[i].initial === '' ||
          this.state.retailerActualList[i].target === null ||
          this.state.retailerActualList[i].target === '') {
          isEmpty = true;
          break;
        }
      }

      if (this.state.retailerActualList.length === 0) {
        isEmpty = true;
      }

      if ((this.state.selectedRow.status === "COMPLETED" || this.state.selectedRow.status === "CLOSED") && isEmpty && this.state.selectedRow.toolboxId !== 1) {
        toastr.error(I18n.t("toastr.initialTarget"));
        return;
      }
    }



    var flag = true;
    // console.log(this.state)
    if (
      this.state.value.length === 0 ||
      this.state.selectedRow.firstVisit === null || this.state.selectedRow.firstVisit.length === 0 ||
      this.state.selectedRow.completionOn === null ||
      this.state.selectedRow.completionOn.length === 0 || this.state.valueAssignee.length === 0
    ) {
      toastr.error(I18n.t("toastr.modalMandatory"));
      flag = false;
      return;
    }

    var notAnswered = 0;
    for (var i = 0; i < this.state.ansSubquestionList.length; i++) {
      var evaluation = this.state.ansSubquestionList.split('|');
      var questions = evaluation[4] !== undefined ? parseInt(evaluation[4]) : 0;
      var answered = evaluation[5] !== undefined ? parseInt(evaluation[5]) : 0;
      notAnswered = questions - answered;
      if (notAnswered > 0) {
        break;
      }
    }

    if ((this.state.selectedRow.status === "COMPLETED" || this.state.selectedRow.status === "CLOSED") && notAnswered > 0) {
      toastr.error(I18n.t('toastr.activityStatus_condition'));
      return;
    }
    if (this.props.user.userMdl.activeProgram === "NAS") {
      this.state.selectedRow.businessAreaKey ="TSP";
    }
    if (flag) {
      this.toChangeStatusFlag(false);
      let statusFound = false;
      if (this.state.actionPlanList && this.state.actionPlanList.length > 0) {
        statusFound = this.state.actionPlanList.find(element => element.status === "OPEN" || element.status === "PLANNED")
      }
      console.log("actionPlanList",this.state.actionPlanList)
      console.log("statusFound",statusFound)
      if (this.state.selectedRow.status === "REJECTED" || (this.state.selectedRow.status === "COMPLETED" && statusFound)) {
        this.confirmAlertCall();                
      }else{
        this.props.dispatch(
          saveActivity(this.state.selectedRow, this.props.activityDto, this)
        );
      }              
    }
  };
  // if status COMPLETED or REJECTED show waring message
  confirmAlertCall = () => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {this.state.selectedRow.status === "REJECTED" ? (this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") ?
              I18n.t("standardMessages.britRejectMsg") : I18n.t("standardMessages.rejectMsg") : I18n.t("standardMessages.warningMsg")}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.props.dispatch(
                    saveActivity(this.state.selectedRow, this.props.activityDto, this)
                  );
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  // Teach Autosuggest how to calculate suggestions for any given input value.
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    return inputLength > 4 && this.state.dealerList !== undefined
      ? this.state.dealerList.filter(
        lang => lang.label.toLowerCase().slice(0, inputLength) === inputValue
      )
      : [];
  };

  getAssigneeSuggestions = valueAssignee => {
    const inputValue = valueAssignee.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength > 4 && this.state.assigneeList !== undefined
      ? this.state.assigneeList.filter(
        lang => lang.userName.toLowerCase().slice(0, inputLength) === inputValue
      )
      : [];
  };
  // When suggestion is clicked, Autosuggest needs to populate the input
  // based on the clicked suggestion. Teach Autosuggest how to calculate the
  // input value for every given suggestion.
  getSuggestionValue = suggestion => suggestion.label + "_" + suggestion.value;

  getSuggestionValueAssignee = suggestionsAssignee =>
    suggestionsAssignee.userName + "_" + suggestionsAssignee.networkUserId;
  // Use your imagination to render suggestions.
  renderSuggestion = suggestion => <div>{suggestion.label}</div>;

  renderSuggestionAssignee = suggestionsAssignee => (
    <div>{suggestionsAssignee.userName}</div>
  );
  onToolboxChange = toolbox => {
    // console.log(toolbox)
    this.state.toolbox = toolbox;
    const selectedRow = { ...this.state.selectedRow };
    // selectedRow["toolboxId"] = toolbox.value;
    if (toolbox == null) {
      selectedRow["toolboxId"] = "";
    } else {
      selectedRow["toolboxId"] = toolbox.value;
    }
    this.setState({ selectedRow });
  };
  onStatusChange = statusOption => {
    this.state.selectedStatus = statusOption;
    this.setState({ oldStatus: this.state.selectedRow.status })
    const selectedRow = { ...this.state.selectedRow };
    selectedRow["status"] = statusOption.value;
    selectedRow["statusChangedFlag"] = true;
    this.setState({ selectedRow });
    this.setState({ statusChangeFlag: true })
    this.props.dispatch(
      fetchDealerActivityDetails(selectedRow.activityId, selectedRow.nmscCode,selectedRow.langCode,)
    );
    this.props.dispatch(getEvaluationProgressBar(selectedRow.activityId));
    this.props.dispatch(fetchActionPlans(selectedRow.activityId));
  };

  onHandleChange = (event, action) => {
    const selectedRow = { ...this.state.selectedRow };
    selectedRow[action] = event.target.value;
    this.setState({ selectedRow });
  };
  componentWillMount() {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    var isDealerKaizenChamp = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN") {
          isTMEAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET-EA DEALER CHAMPION") {
          isNotAdmin = true;
          isDealerKaizenChamp = true;
          break;
        }
        isNotAdmin = true;

      }
    }
    this.setState({ isDealerKaizenChamp });
    if (!isTMEAdminUser) {
      // nmscCode:this.props.userMdl.nmscCode,
      // langCode:this.props.userMdl.langCode
      this.props.dispatch(fetchNmscToolboxByType(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang));

    }
    else {
      this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
    }
    this.props.dispatch(fetchDealer(this.state.activityDto));
    this.props.dispatch(fetchActivityStatus());
    // this.setState({ value: "alexander" });
    // this.props.dispatch(fetchAssignee(this.state.activityDto));
    if (this.state.selectedRow.firstVisit.length > 0) {
      var firstVisitDate = this.state.selectedRow.firstVisit.split("/");
      this.state.selectedRow.firstVisit = new Date(
        firstVisitDate[2],
        firstVisitDate[1] - 1,
        firstVisitDate[0]
      );
    }
    if (
      this.state.selectedRow.completionOn !== undefined &&
      this.state.selectedRow.completionOn !== null &&
      this.state.selectedRow.completionOn.length > 0
    ) {
      var completeOnDate = this.state.selectedRow.completionOn.split("/");
      this.state.selectedRow.completionOn = new Date(
        completeOnDate[2],
        completeOnDate[1] - 1,
        completeOnDate[0]
      );
    }
    if (
      this.state.selectedRow.reminderOn !== undefined &&
      this.state.selectedRow.reminderOn !== null &&
      this.state.selectedRow.reminderOn.length > 0
    ) {
      var reminderOnDate = this.state.selectedRow.reminderOn.split("/");
      this.state.selectedRow.reminderOn = new Date(
        reminderOnDate[2],
        reminderOnDate[1] - 1,
        reminderOnDate[0]
      );
    }
    this.props.dispatch(fetchAssignee(this.state.selectedRow.dealerId, this.props.user.userMdl.activeProgram));
  }


  toChangeStatusFlag = (flag) => {
    this.setState({ statusRefreshRequired: flag });
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.toolboxByType.toolboxByType) {
      // this.setState({ unitsListKPI: nextProps.unitsList });
      var options = nextProps.toolboxByType.toolboxByType.map((item) => ({
        value: item.packageMapPrimaryKey,
        label: item.toolboxName
      }));

      if (
        this.state.selectedRow.toolbox &&
        this.state.selectedRow.toolbox.length > 0
      ) {
        options.map((obj, index) => {
          if (obj.value === this.state.selectedRow.toolboxId) {
            this.state.toolbox = obj;
          }
        });
      }
      // console.log(nextProps.toolboxByType)
      this.setState({
        leftNavNamesList: nextProps.toolboxByType.toolboxByType.data,
        leftNavNamesLIST: options
      });
    }

    if (nextProps.activityToolBox) {
      // this.setState({ unitsListKPI: nextProps.unitsList });
      var options = nextProps.activityToolBox;

      if (
        this.state.selectedRow.toolbox &&
        this.state.selectedRow.toolbox.length > 0
      ) {
        options.map((obj, index) => {
          if (parseInt(obj.value) === this.state.selectedRow.toolboxId) {
            this.state.toolbox = obj;
          }
        });
      }
      // console.log(nextProps.activityToolBox)

      this.setState({
        leftNavNamesList: nextProps.activityToolBox.data,
        leftNavNamesLIST: options
      });
    }

    if (nextProps.activityStatus && this.state.statusRefreshRequired) {
      if (this.state.selectedRow.status !== "") {       
        var statusList = [];
        let roles = [];
        roles = this.state.selectedRow.userMdl.roles;

        nextProps.activityStatus.map((obj, index) => {
          if(this.props.user.userMdl.activeProgram === "BRiT" && obj.label === "Closed"){
            return false;
          }
          if (
            this.state.selectedRow.status === "ONGOING" || this.state.selectedRow.status === "CLOSED"
          ) {
            if (obj.label !== "PLANNED") {
              if (obj.label === "COMPLETED") {
                for (var i = 0; i < roles.length; i++) {
                  if (((roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET NMSC FIELD" || roles[i].toUpperCase() === "KET NMSC EXTERNAL")
                    && (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"))
                    || ((roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION")
                      && this.props.user.userMdl.activeProgram === "BRiT")
                      ||((roles[i].toUpperCase() === "KET-EA NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN")
                      && this.props.user.userMdl.activeProgram === "EA")) {
                    // if (obj.label !== "Rejected") {
                    statusList.push(obj);
                    //}
                  }
                }
              }
              else if (obj.label === "Rejected") {
                for (var i = 0; i < roles.length; i++) {
                  if (((roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN")
                    && (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"))
                    || ((roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN")
                      && this.props.user.userMdl.activeProgram === "BRiT")
                      ||((roles[i].toUpperCase() === "KET-EA NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN")
                      && this.props.user.userMdl.activeProgram === "EA")) {
                    // if (obj.label !== "Rejected") {
                    statusList.push(obj);
                    //}
                  }
                }
              } else {
                statusList.push(obj);
              }
            }
          }
          else if( this.props.selectedRow.status === "COMPLETED"){
            // console.log(obj)
            if (obj.value === "COMPLETED" || obj.value === "ONGOING") {
              for (var i = 0; i < roles.length; i++) {
                if (((roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET NMSC FIELD" || roles[i].toUpperCase() === "KET NMSC EXTERNAL")
                  && (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"))
                  || ((roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN")
                  && this.props.user.userMdl.activeProgram === "BRiT")
                  ||((roles[i].toUpperCase() === "KET-EA NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN")                  
                  && this.props.user.userMdl.activeProgram === "EA")) {
                  statusList.push(obj);
                  // console.log(obj)
                }
              }
            }
          }
          else {
            if (obj.label === "COMPLETED" || obj.label === "Rejected") {
              for (var i = 0; i < roles.length; i++) {
                if (((roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET NMSC FIELD" || roles[i].toUpperCase() === "KET NMSC EXTERNAL")
                  && (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS"))
                  || ((roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN")
                    && this.props.user.userMdl.activeProgram === "BRiT")
                  || ((roles[i].toUpperCase() === "KET-EA NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN")
                    && this.props.user.userMdl.activeProgram === "EA")) {
                  statusList.push(obj);
                }
              }
            } else {
              if (obj.label !== "PLANNED") {
                statusList.push(obj);
              }
            }
          }
          if (obj.label === this.state.selectedRow.status) {
            obj.label = I18n.t(obj.label);
            this.setState({ selectedStatus: obj });
            this.setState({ rowStatus: obj.value });
          }
        });
        // console.log(options)
        const options = statusList.map((d) => ({
          value: d.value,
          label: I18n.t(d.label)
        }));
        this.setState({ activityStatus: options });
      }
    }

    if (nextProps.assigneeList && nextProps.assigneeList.ASSIGNEE_LIST) {
      this.setState({ assigneeList: nextProps.assigneeList.ASSIGNEE_LIST });
      // console.log(nextProps.assigneeList);
      // console.log(this.state.selectedRow.assigneeId);
      if (this.state.selectedRow.userId > 0) {
        nextProps.assigneeList.ASSIGNEE_LIST.map((obj, index) => {
          if (obj.networkUserId === parseInt(this.state.selectedRow.userId)){
            this.setState({ valueAssignee: obj.userName });
          }
        });
      }
      /*  else if (nextProps.assigneeList.KAIZEN_CHAMP) {
         nextProps.assigneeList.ASSIGNEE_LIST.map((obj, index) => {
           if (obj.value == nextProps.assigneeList.KAIZEN_CHAMP) {
             this.setState({
               valueAssignee: obj.label,
               idAssignee: obj.value
             });
             this.state.selectedRow["userId"] = obj.value;
           }
         });
 
       } */
      else if (this.state.isDealerKaizenChamp) {
        var loggeInUsername = this.props.user.userMdl.username.toUpperCase();
        nextProps.assigneeList.ASSIGNEE_LIST.map((obj, index) => {
          if (obj.userId.toUpperCase() === loggeInUsername) {
            this.setState({
              valueAssignee: obj.userName,
              idAssignee: obj.networkUserId
            });
            this.state.selectedRow["userId"] = obj.networkUserId;
          }
        });
      }
    }

    if (nextProps.retailerActual) {
      this.setState({ retailerActualList: nextProps.retailerActual });
    }

    /*   if (nextProps.evaluationActivityCompleted) {
        this.setState({ ansSubquestionList: nextProps.evaluationActivityCompleted });
      } */
    if (nextProps.evalautionProgressBar) {
      this.setState({ ansSubquestionList: nextProps.evalautionProgressBar });
    }
    if (nextProps.actionPlanList) {
      this.setState({ actionPlanList: nextProps.actionPlanList });
    }
    if (nextProps.dealerList) {
      //   const dealerList = nextProps.dealerList.dealerList;
      this.setState({ dealerList: nextProps.dealerList });
      // console.log("dealerId");
      // console.log(this.state.selectedRow.dealerId);
      if (this.state.selectedRow.dealerId > 0) {
        nextProps.dealerList.map((obj, index) => {
          if (obj.value === this.state.selectedRow.dealerId) {
            this.setState({ value: obj.label });
          }
        });
      }
      else if (this.state.isDealerKaizenChamp && nextProps.dealerList[0]) {
        this.setState({ value: nextProps.dealerList[0].label});
        this.state.selectedRow["dealerId"] = nextProps.dealerList[0].value;
        //  if (nextProps.dealerList[0].value != this.state.selectedRow.dealerId) {
        this.props.dispatch(fetchAssignee(nextProps.dealerList[0].value, this.props.user.userMdl.activeProgram));
        // }
      }
    }
  }
  // componentDidMount() {
  //   // $('#addEditActivityModal').modal({ backdrop: 'static', keyboard: false });
  // }
  onFirstVisitDateChange = (ev, action) => {
    // console.log(ev.utc());
    var date = moment(ev);
    date = date.format("YYYY-MM-DD");
    var completeOnDate = moment(this.state.selectedRow.completionOn);
    completeOnDate = completeOnDate.format("YYYY-MM-DD");
    if (ev == null) {
      this.state.selectedRow[action] = ev;
      this.setState({ selectedRow: this.state.selectedRow });
      return;
    }
    if (
      this.state.selectedRow.completionOn === null ||
      this.state.selectedRow.completionOn === ""
    ) {
      this.state.selectedRow[action] = ev;
      this.setState({ selectedRow: this.state.selectedRow });
    } else if (
      this.state.selectedRow.completionOn !== null &&
      this.state.selectedRow.completionOn !== "" &&
      completeOnDate > date
    ) {
      this.state.selectedRow[action] = ev;
      this.setState({ selectedRow: this.state.selectedRow });
    } else {
      toastr.error(I18n.t("toastr.completionDate"));
      return;
    }
  };
  onCompletionDateChange = (ev, action) => {
    var date = moment(ev);
    date = date.format("YYYY-MM-DD");
    var firstVisit = moment(this.state.selectedRow.firstVisit);
    firstVisit = firstVisit.format("YYYY-MM-DD");
    if (ev == null) {
      this.state.selectedRow[action] = ev;
      this.setState({ selectedRow: this.state.selectedRow });
      return;
    }
    if (
      this.state.selectedRow.firstVisit !== null &&
      this.state.selectedRow.firstVisit !== "" &&
      firstVisit < date
    ) {
      this.state.selectedRow[action] = ev;
      this.setState({ selectedRow: this.state.selectedRow });
    } else {
      toastr.error(I18n.t("toastr.completionDate"));
      return;
    }
  };
  onDateChange = (ev, action) => {
    this.state.selectedRow[action] = ev;
    this.setState({ selectedRow: this.state.selectedRow });
  };
  preventDefault() { }

  componentWillUnmount() {
    this.props.dispatch({
      type: "ASSIGNEE_CODE",
      payload: []
    });
  }

  render() {
    // console.log(this.state.oldStatus)
    const { value, suggestions } = this.state;
    const { valueAssignee, suggestionsAssignee } = this.state;

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
      value,
      onChange: this.onDealerChange,
      className:
        this.state.rowStatus === "PLANNED"
          ? "react-autosuggest__input"
          : "react-autosuggest__input disableAutoSuggest",
      disabled: this.state.rowStatus === "PLANNED" ? false : true
    };
    const inputPropsAssignee = {
      placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
      value: valueAssignee,
      onChange: this.onAssigneeChange,
      className:
        this.state.rowStatus === "PLANNED" || this.state.rowStatus === "ONGOING"
          ? "react-autosuggest__input"
          : "react-autosuggest__input disableAutoSuggest",
      disabled:
        this.state.rowStatus === "PLANNED" || this.state.rowStatus === "ONGOING"
          ? false
          : true
    };
    return (
      <Container>
        <Modal
          className={
            "addEditActivityModal addEditActivityModal-" + this.props.theme.name
          }
          id="addEditActivityModal"
          isOpen="true"
          toggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>
            {this.props.modalFor === "Create" ? I18n.t("others.createActivity") : I18n.t("others.editActivity")}
          </ModalHeader>
          <ModalBody>
            <form className="form-horizontal">
              <label className="col-md-12 control-label">
                {I18n.t("Table.dealer")}
                <span className="redFont"> *</span>
              </label>
              {/* <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="50"
                                    autoFocus
                                    value={this.state.selectedRow['name']}
                                    onChange={(e) => this.onHandleChange(e, 'name')}
                                />
                            </div>
                            <br /> */}
              <div className="col-md-12">
                <Autosuggest
                  focusInputOnSuggestionClick={false}
                  class="form-control"
                  maxLength="50"
                  suggestions={suggestions}
                  onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                  onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                  getSuggestionValue={this.getSuggestionValue}
                  renderSuggestion={this.renderSuggestion}
                  inputProps={inputProps}
                />
              </div>
              <br />
              <label className="col-md-12 control-label">
              {this.props.user.userMdl.activeProgram === "TSP"? I18n.t("Table.audit") : I18n.t("Table.toolbox") } 
              </label>
              <div className="col-md-12">
                <Select                
                  placeholder= {I18n.t('others.select')}
                  value={this.state.toolbox}
                  onChange={this.onToolboxChange}
                  options={this.state.leftNavNamesLIST}
                  isClearable
                  isDisabled={this.state.rowStatus === "PLANNED" ? false : true}
                />
              </div>
              <br />
              <label className="col-md-12 control-label">
                {I18n.t("Table.assignee")}
                <span className="redFont"> *</span>
              </label>
              <div className="col-md-12">
                <Autosuggest
                  class="form-control"
                  focusInputOnSuggestionClick={false}
                  suggestions={suggestionsAssignee}
                  onSuggestionsFetchRequested={
                    this.onSuggestionsFetchRequestedAssignee
                  }
                  onSuggestionsClearRequested={
                    this.onSuggestionsClearRequestedAssignee
                  }
                  getSuggestionValue={this.getSuggestionValueAssignee}
                  renderSuggestion={this.renderSuggestionAssignee}
                  inputProps={inputPropsAssignee}
                />
              </div>
              <br />
              <div className="col-md-12 row control-label">
                <label
                  className="col-md-6 control-label"
                  style={{ paddingLeft: "0.3rem" }}
                >
                  {I18n.t("Table.startDate")}
                  <span className="redFont"> *</span>
                </label>
                <label
                  className="col-md-6 control-label"
                  style={{ paddingLeft: "0.5rem" }}
                >
                  {I18n.t("Table.completionDate")}
                  <span className="redFont"> *</span>
                </label>
              </div>
              <div className="col-md-12 input-group control-label">
                <DatePicker
                  className="form-control"
                  onChange={e => this.onFirstVisitDateChange(e, "firstVisit")}
                  //placeholderText="First Visit"
                  dateFormat="dd/MM/yyyy"
                  isClearable={
                    this.state.rowStatus === "PLANNED" ? true : false
                  }
                  //minDate={new Date()}
                  disabled={this.state.rowStatus === "PLANNED" ? false : true}
                  selected={
                    this.state.selectedRow.firstVisit
                      ? this.state.selectedRow.firstVisit
                      : null
                  }
                  id="firstVisit"
                  name="firstVisit"
                />
                &nbsp;
                <DatePicker
                  className="form-control"
                  onChange={e => this.onCompletionDateChange(e, "completionOn")}
                  //placeholderText="Completion Date"
                  dateFormat="dd/MM/yyyy"
                  isClearable={
                    this.state.rowStatus === "PLANNED" ||
                      this.state.rowStatus === "ONGOING"
                      ? true
                      : false
                  }
                  disabled={
                    this.state.rowStatus === "PLANNED" ||
                      this.state.rowStatus === "ONGOING"
                      ? false
                      : true
                  }
                  selected={
                    this.state.selectedRow.completionOn
                      ? this.state.selectedRow.completionOn
                      : null
                  }
                  id="completionOn"
                  name="completionOn"
                />
              </div>
              <br />
              <div className="col-md-12 row control-label">
                <label
                  className="col-md-6 control-label"
                  style={{ paddingLeft: "0.3rem" }}
                >
                  {" "}
                  {I18n.t("Table.reminderOn")}
                  <span className="redFont" />
                </label>
                <label
                  className="col-md-6 control-label"
                  style={{ paddingLeft: "0.5rem" }}
                >
                  {I18n.t("Table.status")}
                </label>
              </div>
              <div className="col-md-12 input-group control-label">
                <DatePicker
                  className="form-control"
                  onChange={e => this.onDateChange(e, "reminderOn")}
                  //placeholderText="First Visit"
                  dateFormat="dd/MM/yyyy"
                  isClearable={
                    this.state.rowStatus === "PLANNED" ||
                      this.state.rowStatus === "ONGOING"
                      ? true
                      : false
                  }
                  disabled={
                    this.state.rowStatus === "PLANNED" ||
                      this.state.rowStatus === "ONGOING"
                      ? false
                      : true
                  }
                  selected={
                    this.state.selectedRow.reminderOn
                      ? this.state.selectedRow.reminderOn
                      : null
                  }
                  id="reminderOn"
                  name="reminderOn"
                />
                &nbsp;
                <Select                
                placeholder= {I18n.t('others.select')}
                  className="statusDD"
                  isDisabled={
                    this.state.rowStatus === "ONGOING" ||
                      this.state.rowStatus === "CLOSED" || this.state.rowStatus === "COMPLETED"
                      ? false
                      : true
                  }
                  value={this.state.selectedStatus}
                  onChange={this.onStatusChange}
                  options={this.state.activityStatus}
                  isClearable
                />
              </div>
            </form>
          </ModalBody>
          <ModalFooter>
            <button
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => this.props.toggle(this.props.modalFor)}
            >
              {I18n.t("tmeAdminMaster.cancel")}
            </button>
            {this.props.modalFor === "Create" ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={e => {
                  this.saveActivity(e, this.state.valueAssignee);
                }}
              >
                {I18n.t("tmeAdminMaster.save")}
              </button>
            ) : (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={e => {
                    this.saveActivity(e, this.state.valueAssignee);
                  }}
                >
                  {I18n.t("tmeAdminMaster.update")}
                </button>
              )}
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    toolboxByType: state.leftNavReducer,
    assigneeList: state.activityReducer.assigneeList,
    dealerList: state.activityReducer.dealerList,
    activityStatus: state.activityReducer.activityStatus,
    retailerActual: state.activityReducer.retailerActual,
    evalautionProgressBar: state.evaluationReducer.evalautionProgressBar,
    activityToolBox: state.activityReducer.activityToolBox,
    actionPlanList: state.activityReducer.actionPlanList
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(ActivityModal)));
