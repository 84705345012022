import React from "react";
//import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from "mdbreact";
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { submitComments,fetchNmscCertification} from '../../../actions/certificationAction';
import '../index.css';
import { Form } from 'react-bootstrap';
var I18n = require("react-redux-i18n").I18n;

class SubmitComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            userId : this.props.user.userMdl.username
            //certificateIds: [5,7,8,4]
        }
    }
    componentDidMount() {

    }

    componentWillMount() {
       
    }

    componentWillReceiveProps(nextProps) {
        
    }

    onCommentsChange = (e) => {
        this.setState({ comment: e.target.value });
    }

    submitComment = () => {

        this.props.dispatch(submitComments(this.state.userId,this.getTableQueryDTO()));
        this.props.toggleComments();
        this.props.toggel();
        // this.props.dispatch(fetchNmscCertification(this.getQueryDTO()));
    } 
    closeComment = () => {
        this.props.toggleComments()
        this.props.toggel();
        this.props.dispatch(submitComments(this.state.userId,this.getTableQueryDTO()));
        this.props.dispatch(fetchNmscCertification(this.getTableQueryDTO()));
    } 

    getQueryDTO = () => {
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let userId = this.props.user.userMdl.username;
        const userComment =  this.state.comment

        return {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: this.props.programFilter,
            nmscCode: this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            userId: userId,
            dealerList: [this.props.selectedDealerId],
            statusList: [],
            numberOfCompletedToolBox: null,
            comment: userComment,
            activityIds : []
          };
    }

    getTableQueryDTO = () => {
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let userId = this.props.user.userMdl.username;
        const userComment =  this.state.comment
        //let activeProgram = this.props.user.userMdl.activeProgram;
    
        return {
          userNmscCode: this.props.certificationReducer.CertiFlag ? null : nmscCode,
          userLangCode: this.props.certificationReducer.CertiFlag ? null : langCode,
          nmscCode: this.props.nmscFilter,
          brand: this.props.brandFilter,
          country: this.props.countryFilter,
          userId: userId,
          numberOfCompletedToolBox: null,
          dealerId: this.props.certiFilters.dealerList !== undefined && this.props.certiFilters.dealerList[0] ? this.props.certiFilters.dealerList[0] : null,
          programList: this.props.certiFilters.programList,
          dealerList:this.props.certiFilters.dealerList,
          statusList: this.props.certiFilters.statusList,
          nmscCodeList:this.props.certiFilters.nmscCodeList,
          brandList:this.props.certiFilters.brandList,
          countryList:this.props.certiFilters.countryList,
          noOfCompletedToolBox:this.props.certiFilters.noOfCompletedToolBox,
          comment: userComment,
          activityIds: this.props.activityIds
        };
      }
    preventDefault() { }
    render() {
        
        return (
            <Modal centered
                className={"addComments uploadModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important',border: '0px solid !important'}}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={this.closeComment}>
                    {I18n.t("evaluation.comments")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md="9">
                                <Form style={{ margin: "20px" }}>
                                    <label className={"questionLabel"}>
                                        {I18n.t("evaluation.comments")}
                                        {/* <span className="redFont"> *</span> */}
                                    </label>
                                    <textarea
                                        className="form-control"
                                        rows="3" maxLength="200" value={this.state.comment} onChange={this.onCommentsChange}
                                    />

                                </Form>
                            </Col>
                            <Col md="3">
                                
                                    <button style={{ marginTop: '70px' }}
                                        className={"button-" + this.props.theme.name}
                                        onClick={this.submitComment}
                                    >
                                        {I18n.t("evaluation.submit")}
                                    </button> 
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
                <ModalFooter>
                   

                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        certificationReducer: state.certificationReducer,
        certiFilters: state.certificationReducer.certiFilters,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(SubmitComments)));


