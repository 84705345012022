import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../../themes/theming';
import { MDBCol, MDBRow , Fa} from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import Slider from "react-slick";
import Arrow from '../arrow';
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';

import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class Activation extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            isLoading: true,
            filters:[{value: "", label: I18n.t('dashboard.nmscs')},{value: "USERS", label: I18n.t('dashboard.users')}],
            data: {},
            leftArrow:"widget_filter_arrow_disable",
            rightArrow:"widget_filter_arrow"
        };
    }
    componentWillMount(){
        if(this.props.nmscFlag){
            this.fetchData("USERS");
        } else 
        this.fetchData("");
    }
    componentWillReceiveProps(nextProps){
        if(nextProps.activeUsers){
            // alert("updating activeusers")
            this.setState({data: nextProps.activeUsers, isLoading: false});
        }
    }
    fetchData = (filter) => {
        this.setState({isLoading: true});
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let queryDto={
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            widgetFilter: filter,
            userId:this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
            
        };
        this.props.dispatch(fetchScrollableWidgetData("activeUsers", "ACTIVE_USERS", queryDto));
    }
    render() {
        let filters = [...this.state.filters]; 
        if(this.props.nmscFlag)
            filters= filters.slice(1)
        const settings = {
             dots: false,
             infinite: false,
             speed: 500,
             slidesToShow: 1,
             slidesToScroll: 1,
            beforeChange: (current, next) => {
            

                let filter = this.state.filters[next];
                if(filter){
                    this.fetchData(filter.value);
                }
                if(next===0){
                    this.setState({leftArrow:"widget_filter_arrow_disable",rightArrow:"widget_filter_arrow"})
                }
                else if(next===(this.state.filters.length-1)){
                    this.setState({rightArrow:"widget_filter_arrow_disable",leftArrow:"widget_filter_arrow"})
                }else{
                    this.setState({rightArrow:"widget_filter_arrow",leftArrow:"widget_filter_arrow"})
 
                }
            },
            //widget_filter_arrow_disable
            //  prevArrow: <Arrow icon="arrow-circle-left" />,
            //  nextArrow: <Arrow icon="arrow-circle-right" parentClass="nextBtnClass"/>
            prevArrow: <Arrow icon="chevron-left" parentClass="previousBtnClass" BtnClass={this.state.leftArrow}/>,
            nextArrow: <Arrow icon="chevron-right" parentClass="nextBtnClass" BtnClass={this.state.rightArrow}/>
        };
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.activation')}>{I18n.t('dashboard.activation')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                      
                         <MDBRow style={{minHeight: "4.9rem"}}>
                            <MDBCol  lg="12" md="12" className="widget_count_class">
                                {this.state.data.value}
                           
                                {this.state.data.arrowDirection ?
                                <Fa icon={this.state.data.arrowDirection} style={{color: this.state.data.arrowColor}} className="widget_arrow_class" size="lg" />
                                : <div></div> }
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                        <MDBCol  lg="12" md="12" className="widget_slider_class">

                        <Slider {...settings}>
                            {filters.map((t, i) => {
                               return (<div key={i}>
                                    <span className="widget_filter" title={I18n.t(t.label)}>{I18n.t(t.label)}</span>
                                </div>);
                            })}
                        </Slider>
                        </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        activeUsers: state.dashboardReducer.activeUsers
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Activation)));