const translationsHU = {
  hu: {
    Policy: {
      HeaderMsg: "Adatvédelmi nyilatkozat a személyes adatok feldolgozására a Kaizen szakértői eszköz keretében",
      Message1: "Ez az adatvédelmi nyilatkozat a személyes adatok feldolgozására vonatkozik a Kaizen Expert Tool keretében. Mindig együtt kell olvasni az Általános Toyota Adatvédelmi és Személyes Adatvédelmi Szabályzattal, amelyben az Ön személyes adatai feldolgozásának általános szabályait ismertetik, és amelyeket Önnek el kell fogadnia ahhoz, hogy tovább menjen.",
      Message2: "A Toyota Motor Europe NV / SA, amelynek székhelye az Avenue du Bourget / Bourgetlaan 60, 1140 Brüsszel, Belgium, adatkezelőként gyűjti össze személyes adatait (az alábbiak szerint) az alább leírt célokra.",
      Message3: "Milyen személyes adatokat gyűjtsünk össze?",
      Message4: "A Kaizen Expertre vonatkozan a következő kategóriákban gyűjtöttük össze a személyes adatokat:ű",
      Table1: {
        tr1: "Kategóriák / Személyes adatok",
        tr2: "Felhasználó azonosító",
        tr3: "Teljes felhasználó név",
        tr4: "Márka (Toyota, Lexus)",
        tr5: "Preferált nyelv",
        tr6: "Kapcsolt szervezet"
      },

      Message5: "A személyes adatok feldolgozásának célja és jogalapja",
      Message6: "A személyes adatokat az alábbiakban ismertetett célokra és jogalapkra fogjuk feldolgozni",
      Table2: {
        tr1: {
          th1: "Cél:",
          th2: "Jogalapok"
        },
        tr2: {
          td1: "KET - 1: Márkaszerviz Kazein telejesítménye",
          td2: "Adatkezelők jogszerű érdeke"
        },
        tr3: {
          td1: "KET- 2 IT támogatás",
          td2: "Adatkezelők jogszerű érdeke"
        }
      },
      Message7: "A személyes adatok megőrzésének időtartama",
      Message8: "Az ön személyes adatait az alábbi időtartamig tároljuk:",
      Table3: {
        tr1: {
          th1: "Megőrzési idő:",
          th2: "Kategóriák / Személyes adatok"
        },
        tr2: {
          td1: "A fentiekben leírt személyes adatokat legfeljebb 5 évig + 1 hónapig tartják a Kaizen Expert Tool végleges bezárásának napjától.",
          td2: "Felhasználó Keresztnév, Vezetéknév, Preferált nyelv, Szervezet"
        }
      },
      Message9: "Választható: személyes adatainak az Európai Gazdasági Térségen kívüli országokra történő közzététele / átadása esetén",
      Message10: "Az Ön személyes adatai az EGT-n kívüli országokban lévő címzettek számára hozzáférhetővé válnak az alábbiak szerint:",
      Table4: {
        tr1: {
          td1: "Személyazonosság",
          td2: "Ország",
          td3: ""
        },
        tr2: {
          td1: "EGT-n kívüli címzett (ek)",
          td2: "Infosys",
          td3: "India"
        }
      },
      Message11: "Megerősítem, hogy megértettem ezt az értesítést; Elolvastam és elfogadtam a Toyota adatvédelmi és személyes adatvédelmi nyilatkozatát."
    },
    ACCESSORIES: "Tartozékok",
    activity: {
      Assignee: "Kijelölt személy",
      CompletionDate: "Befejezés dátuma",
      Dealer: "Kereskedő",
      FirstVisit: "Első audit",
      kpi: {
        kpi: "A KPI-k pozitív vagy negatív számok lehetnek",
        kpiLength: "A KPI-k maximalis hossza 10 karakter lehet",
        mandatory: "Kérjük, érvényes adatokat adjon meg valamennyi kötelező mezőben",
        mandatoryTargets: "Kérjük adja meg az összes KPI-t.",
        target: "A célok csak számok lehetnek",
        targetLength: "A célszámok maximális hossza 10 karakter lehet"
      },
      ReminderOn: "Emlékeztető be",
      statusDD: "Státusz",
      Toolbox: "Toolbox",
      activityCreation: "Elindult a tevékenység létrehozása",
      activityCreated: "Tevékenység létrehozva"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Hatékonyság",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Kötelezően kitöltendő mezők"
    },
    FOUNDATION: "Alapok",
    MAINTENANCE_REMINDERS: "Maintenance Reminder",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Alapok",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Ápr.",
      aug: "Aug.",
      dec: "Dec.",
      feb: "Febr.",
      jan: "Jan.",
      jul: "Júl.",
      jun: "Jún.",
      mar: "Márc.",
      may: "Máj.",
      nov: "Nov.",
      oct: "Okt.",
      sep: "Szept."
    },
    others: {
      back: "Vissza",
      closed: "Lezárva",
      completed: "Befejezve ",
      countryLanguageCode: "Ország/nyelvkód ",
      createActivity: "Tevékenység létrehozása",
      createdDate: "Létrehozva",
      dataUneditableNote:
        "Megjegyzés: A mentett adatokat már nem tudja szerkeszteni. Az adatok módosításához lépjen kapcsolatba az NMSC rendszergazdával.",
      date: "Dátum",
      downloadQuestionnaire: "Kérdőív letöltése",
      editActivity: "Tevékenység szerkesztése",
      initialHelpText: "A kezdeti mért KPI-értéknek az előző hat hónap átlagának kell lennie",
      inProgress: "Folyamatban",
      name: "Név",
      next: "Tovább",
      noActivities: "Nem található tevékenység",
      noResults: "Nincsenek eredmények",
      of: "/",
      ongoing: "Folyamatban",
      open: "Megnyitás",
      page: "Oldal",
      planned: "Tervezett",
      problemDescription: "Probléma leírása",
      signature: "Aláírás",
      select: "Kiválasztott KPI-k",
      bulkAction: "Bulk Action",
      bulkActions: "Öszetett műveletek"
    },
    ServerMessage: {
      error_message: "Hiba történt a letöltés során.",
      no_published_subquestion: "Kérjük, hogy tegye elérhetővé a Toolbox kérdőívét annak  érdekében, hogy el tudja indítani a tevékenységet.",
      activity_upload_error_msg: "Hiba történt a tevékenység feltöltésekor",
      action_plan_created: "Akcióterv elkészült",
      action_plan_deleted: "Akcióterv törölve",
      action_plan_saved: "Akcióterv mentve",
      activity_kpi_saved: "KPI-k mentve",
      master_kpi_confirm_delete: "Are you sure you want to delete the selected KPI's?",
      master_kpi_created: "Master KPI sikeresen létrehozva",
      master_kpi_deleted: "Master KPI törölve",
      master_kpi_not_found: "Master KPI nem található",
      master_kpi_published: "A kijelölt Master KPI-k közzététele sikeres",
      master_kpi_retired: "Master KPI visszavonva",
      master_kpi_saved: "Master KPI sikeresen mentve",
      subquestion_created: "Az al-kérdés sikeresen mentve.",
      subquestion_updated: "Az al-kérdés sikeresen frissítve.",
      subquestionnaire_not_found: "Az al-kérdés nem található.",
      subquestion_retired: "Az al-kérdés visszavonva.",
      subquestion_deleted: "Az al-kérdés sikeresen törölve.",
      subquestion_published: "Az al-kérdés sikeresen mentve.",
      mainquestion_created: "A fő kérdés létrehozva.",
      mainquestion_deleted: "A fő kérdés törölve.",
      mainquestion_updated: "A fő kérdés frissítve.",
      duplicateMsg: "Duplikált fájlok az excelben",
      deleteErr: "Hiba történt a fájl törlése közben",
      toolbox_deleted: "A Toolbox sikeresen törlődött",
      toolbox_already_exists: "Ez a Toolbox már létezik",
      toolbox_saved: "Toolbox mentve",
      toolbox_created: "Toolbox létrehozva",
      toolbox_not_found: "Nem található Toolbox",
      published_content_found: "Ezt a Toolboxot nem lehet törölni, mivel tartalmaz néhány közzétett tartalmat",
      dealers_already_mapped : " A kiválasztott kereskedők már társították a felhasználót. ",
      dealer_already_mapped : " Dealer already mapped ",
      dealer_added : " Márkakereskedő hozzáadva. ",
      dealers_added : "  Dealer added. ",
      total_dealers_mapped_now :"A felhasználóhoz hozzárendelt kereskedők száma.",
      no_dealer_available : "Nem található kereskedő a beállított kritériumok alapján.",
      proper_dealer_selected : "Válasszon egy kereskedőt.",
      user_referenced_ongoing_activity : "Ez a felhasználó jelenleg egy folyamatban levő tevékenységhez van rendelve. Kérem cserélje ki a felhasználót ezekben a tevékenységeben és próbálja újra. ",
      want_to_remove_mapping : "Biztos benne, hogy elakarja távolítani a felhasználót ezektől a dealerektől?",
      user_mapping_removed : "A kiválasztott kereskedőket sikeresen eltávolítottuk a felhasználótól.",
      dealer_closed_in_A2D : "Ez a dealer az A2D-ben regisztrálva van.",
      network_level_not_found :"Kérem válassza ki a megfelelő kombinációt a KET hálózat beállítása szerint.",
      duplicate_dealer_uuid : "A kereskedő már megtalálható a KET adatbázisban",
      dealer_created:"Kereskedő létrehozva",
      dealer_updated:"A márkakereskedő tulajdonságai frissítve",
      dealer_not_present: "Ez a dealer nincs regisztrálva az A2D-ben",
      dealer_uuid_other_nmsc:"A kiválasztott A2D uuid kód nem érhertő el a hálózatán."
    },
    standardMessages: {
      delete: "Biztosan törölni kívánja?",
      addActionPlanNotAllowed: "Az akcióterv nem hozható létre vagy szerkeszthető a tevékenység bezárása, befejezése vagy elutasítása után.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Biztosan el akarja utasítani ezt a tevékenységet?Az elutasítás után már nem lehetséges a módosítás..",
      rejectMsg: "Biztosan el akarja utasítani ezt a tevékenységet?Az elutasítás után már nem lehetséges a módosítás..",
      selectNMSCTab: "Kérjük válassza ki a közzétételhez szükséges NMSC nyelvet",
      all: "Minden",
      deleteConfirm: "Biztosan törli a kiválasztott KPI-t?",
      no: "Nem",
      retireConfirm: "Biztosan visszavonja a kiválasztott KPI-t? Visszavonását követően a KPI nem aktiválható újra",
      warning: "Figyelmeztetés",
      yes: "Igen",
      saveDataConfirmation: "Biztosan menteni kívánja az adatokat?",
      saveKPIWarning: "Note:Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed: "A jelenlegi / jövőbeli hónapokra vonatkozó KPI bevitel nem engedélyezett",
      activityLaunch: "Biztosan el akarja indítani ezt a tevékenységet? Az indítás után a tevékenységet nem lehet visszaállítani a tervezett állapotra vagy törölni",
      deleteConfirmSubQuestionNaire: "Biztosan törli a kiválasztott al-kérdést?",
      retireConfirmSubQuestionNaire: "Biztosan visszavonja a kiválasztott al-kérdést?",
      deleteConfirmMainQuestion: "Kérjük, erősítse meg, hogy törölni szeretné a fő kérdést és a kapcsolódó al-kérdéseket.",
      deleteConfirmChapter: "Biztosan törli a fejezetet?",
      deleteLibraryFolder: "Biztosan törli a mappát?",
      deletePublishedFolder: "A mappa egyes fájljai a kérdőívhez kapcsolódhatnak. Biztosan véglegesen törli ezt az almappát, és eltávolítja a linket a kérdőívből?",
      importQuestion: "Biztosan be kívánja importálni ezt az al-kérdést? A jelenlegi al-kérdés helyébe lép.",
      noQuestion: "Nincs több kérdés",
      MandatoryWarning: "Kérjük töltse ki az összes helyi nyelv kötelező mezőjét ",
      certificationSubmission:
        "Kereskedői tanusítás céljából a toolbox-ot benyújtották a TME-rendszerébe",
      sameToolBox: "Nem lehet egyszerre benyújtani ugyanazt a toolboxot. Kérjük válasszon ki másik Toolboxot.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "A Value Chain területen létrehozott összes tevékenység el lesz távolítva. Biztosan folytatni akarja a műveletet?",
      updateBritConfirmDealer: "A BRIT területen létrehozott összes tevékenység el lesz távolítva. Biztosan folytatni akarja a műveletet?",
      completed_activity_delete:"Az adatok törlés után nem lesznek elérhetőek. Biztosan folytatni akarja a törlést?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "A EA területen létrehozott összes tevékenység el lesz távolítva. Biztosan folytatni akarja a műveletet?"

    },
    status: {
      published: "Közzétett",
      retired: "Visszavont",
      saved: "Mentve",
      rejected: "Elutasítva",
      open: "Megnyitás",
      closed: "Lezárva",
      close: "Lezárva",
      ongoing: "Folyamatban",
      completed: "Befejezve",
      complete: "Befejezve",
      planned: "Tervezett",
      overdue: "Lejárt",
      due: "Esedékes",
      pending: "Függőben levő",
    },
    Rejected: "Elutasítva",
    Open: "Megnyitás",
    Closed: "Lezárva",
    Close: "Lezárva",
    Ongoing: "Folyamatban",
    Completed: "Befejezve",
    Complete: "Befejezve",
    Planned: "Tervezett",
    tabDetails: {
      noActionAvailable: "Nincsenek műveletek",
      activeDealer:"Ez a kereskedő jelenleg aktívként van az A2D-ben és nem törölhető.",
      dealerDeleted:"Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivitás",
      activityCompletionDate: "Tevékenység befejeződátuma",
      activityCompletionDateFrom: "Aktivitás befejezésének dátumától",
      activityCompletionDateTo: "Aktivitás befejezésének dátumig",
      activityStartDate: "Tevékenység kezdődátuma",
      activityStartDateFrom: "Aktivitás kezdésének dátuma",
      activityStartDateTo: "Aktivitás befejezésének dátuma",
      assignedTo: "Felelős",
      assignee: "Kijelölt személy",
      benchmark: "Referenciaérték",
      city: "Település",
      completedBy: "Befejezés időpontja ",
      completionBy: "Befejezve eddig",
      completionDate: "Befejezés dátuma",
      counterMeasure: "Intézkedés",
      country: "Ország",
      createdBy: "Létrehozta",
      dealer: "Kereskedő",
      dealerNameAD:"Márkakereskedő név (A2D)",
      dealerName: "Márkakereskedő neve",
      definition: "Meghatározás",
      description: "Leírás",
      dueDate: "Határidő",
      endDate: "Záródátum",
      firstVisit: "Első audit",
      followup: "Utánkövetés",
      franchise: "Franchise",
      history: "Előzmények",
      id: "ID",
      initial: "Kezdőérték",
      kpi: "KPI",
      launch: "Indítás",
      mandatory: "Kötelező",
      nmsc: "NMSC",
      problem: "Probléma",
      publishedOn: "Közzététel napja",
      questionReference: "Kérdés hivatkozási száma",
      reference: "Hivatkozás",
      reminderOn: "Emlékeztető be",
      score: "Pontszám",
      startDate: "Kezdődátum",
      status: "Státusz",
      target: "Target",
      toolbox: "Toolbox",
      view: "Nézet",
      kpiResult: "KPI adatok",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Hely",
      ActionItemReport: "Akció pontok folyamatban",
      certification: "Minősítés státusza",
      noOfToolbox: "Teljesített Toolboxok száma",
      program: "Program",
      certificationDate: "Minősítés dátum",
      activityId : 'Tevékenység azonosító',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Akcióterv",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
    },
    tabsDetails: {
      actionPlan: "Akcióterv",
      confirmDeleteMessage: "Biztosan törli a kiválasztott KPI-t?",
      confirmRetireMessage: "Erősítse meg, hogy vissza kívánja vonni a kijelölt KPI-t, mivel korábban már közzétették.",
      errorRetireMsg: "Ez a KPI nem vonható vissza, mivel aktív TME-vel rendelkező, kötelező KPI-ről van szó",
      import: "Import",
      importKpiMessage: "Importálja a KPI-t a TME Master KPI listából",
      kpi: "Alapvető teljesítménymutatók",
      library: "Könyvtár",
      noActionAvailable: "Nincsenek műveletek",
      NoKPI: "Nincs elérhető KPI",
      publish: "Közzététel",
      questionnaire: "Kérdőív",
      tmeKPIList: "TME Master KPI lista",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Toolbox neve",
      newToolboxName: "Új név",
      deleteToolbox: "Toolbox törlése?",
      programName: "Program neve",
      ToolboxName_EN: "Toolbox név (EN)",
      deleteTool: "Biztosan törölni kívánja ezt a Toolboxot?",
      selectToolbox: "Kérem válassza ki a törlendő Toolboxot.",
      editToolBox: "Toolbox szerkesztése",
      addToolbox: "Toolbox hozzáadása",
      toolbox: "Kérem adja meg a Toolbox nevét.",
      addActionPlan: "Hozáad Akcióterv",
      actionPlanItem: "Akcióterv – #. pont",
      add: "Hozáad",
      addActivity: "Tevékenység hozzáadása",
      additionalInformation: "Plusz információ",
      benchmark: "Referenciaérték",
      cancel: "Mégse",
      createEditActivity: "Tevékenység létrehozása/szerkesztése",
      createKPI: "KPI létrehozása",
      delete: "Törlés",
      download: "Letöltés",
      edit: "Szerkesztés",
      editKPI: "KPI szerkesztése",
      keyPerformanceIndicator: "Alapvető teljesítménymutató",
      kpiDefinition: "KPI Leírás",
      mandatoryKpi: "Kötelező KPI",
      save: "Mentés",
      search: "Keresés",
      unit: "Mértékegység",
      update: "Frissítés",
      validData: "Adjon meg érvényes adatokat",
      hide: "Elrejt",
      show: "Megmutat",
      upload_dealer_network: "Kereskedői hálózat feltöltése",
      upload_question: "Kérdőívek feltöltése",
      upload_user: "Felhasználó részletes adatainak feltöltése",
      upload_activity: "Tevékenységek feltöltése",
      remove:"Remove",
      toyota:"Toyota",
      lexus:"Lexus",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Kérjük válaszoljon az összes kérdésre azért, hogy letudja zárni a tevékenységet.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Állítsa be a KPI adatokat és a KPI célokat a folytatáshoz.",
      upload_error: "Hiba történt a kereskedő hálózat feltöltésekor",
      action_item_create_warning: "Maximum 5 pont engedélyezett egy akcióterven belül",
      actionPlanStartDateValidation: "A záró dátum nem lehet korábbi a kezdő dátumnál",
      actionsNotClosed: "Műveletek vannak folyamatban az akciótervben; kérjük, hogy tevékenység bezárása előtt zárjon be valamennyi műveletet",
      activityDuplicate: "Már létezik másik tevékenység ugyanehhez a márkakereskedőhöz és Toolboxhoz",
      assigneeNotExist: "A kiválasztott kijelölt személy nincs regisztrálva a rendszerben",
      assigneeValidation: "Érvénytelen kijelölt személy",
      atleastOnePublish: "Válasszon ki legalább egy KPI-t, amelyet közzétesz",
      cannotRetireActive: "Ez egy aktív TME-vel rendelkező, kötelező KPI, amely nem vonható vissza ",
      completionDate: "A teljesítés dátuma nem lehet korábbi, mint a kezdés dátuma",
      confirDeletePlannedActivity: "Biztosan törli ezt a tervezett tevékenységet?",
      DateformatValidation: "A dátumoknak ilyen formátumúnak kell lenni: Nap/Hónap/Év",
      DateValidation: "A kezdés dátumának korábbinak kell lennie, mint a lezárás dátuma",
      dealerValidation: "Érvénytelen márkakereskedő",
      deleteSelectedConfirm: "Biztosan törli a kiválasztott KPI-t?",
      duplicate: "Már létezik KPI ezzel a névvel és leírással",
      modalMandatory: "Kérjük, érvényes adatokat adjon meg valamennyi kötelező mezőben",
      noPublishKpi: "Még nem történt meg a KPI-k közzététele a kiválasztott Toolboxra vonatkozóan",
      validSearchCriteria: "Adjon meg érvényes keresési feltételeket",
      multiSelectPlaceHolder: "Adjon meg legalább 5 karaktert",
      multiSelectPlaceHolderUser: "Adjon meg legalább 3 karaktert",
      startDateValidation: "A kezdés dátuma nem lehet nagyobb, mint a lezárás dátuma",
      fileSelectionErr: "Válassza ki először a fájlt",
      atleastOneQuestion: "Kérjük, válasszon legalább egy al-kérdést az importáláshoz.",
      atleastOneSubQues: "Kérjük, válasszon ki legalább egy alkérdést a közzétételhez.",
      uploadErr: "Feltöltetlen fájlok száma",
      uploadSuccess: "Kereskedő hálózat sikeresen feltöltve",
      file_upload_success: "A fájl sikeresen feltöltve",
      KPIinOngoingClosedActivity:"Ez a KPI egy folyamatban/lezárt tevékenységben van. Kérjük állítsa át teljesítettre ezt a tevékenységet mielőtt változtat a KPI-on.",
      Role_Error: "A felhasználó beállítása nem teljes, kérjük vegye fel a kapcsolatot az NMSC KET Adminnal.",
      points_allocation_incorrect : "Helytelen pontkiosztás, kérem módosítson",
      activity_deleted:"A tevékenység törlése sikeresen megtörtént",
      activity_certified_dealer:"A márkakereskedőt a kiválasztott tevékenység tekintetében már tanusították, ezért nem lehet törölni",
      activity_submited_for_certificate_dealer:"Ezt a tevékenységet már felvették a TME-nek küldött tanusítási kérelembe, ezért a státusz nem változtatható meg"
    },
    TopNav: {
      ActivityLog: "Tevékenységek",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Kezdőlap",
      logout: "Kijelentkezés",
      MasterDataUpload: "Törzsadatok feltöltése",
      Toolbox: "Toolboxok",
      welcome: "Üdvözöljük",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Al-kérdés hozzáadása",
      editSubQuestion: "Al-kérdés szerkesztése",
      subQuestionTitle: "Al-kérdés",
      additionalInfo: "Plusz információ",
      na: "Ehhez a kérdéshez válaszként adjon meg „Nem alkalmazható” lehetőséget",
      question: "Kérdés",
      questionTitle: "Kérdés címek",
      why: "Miét?",
      addMainQuestion: "Fő kérdés hozzáadása",
      editMainQuestion: "Fő kérdés szerkesztése",
      previousQuestion: "Előző kérdés",
      nextQuestion: "Következő kérdés",
      what: "Mit?",
      library: "Könyvtár",
      subQuestionTable: {
        no: "Nem.",
        subQuestion: "Al-kérdések",
        status: "Státusz",
        publishedDate: "Megjelenés dátuma",
        notApplicable: "Nem alkalmazható"
      },
      links: {
        question_link_button: "Hivatkozás",
        question_link_include_folder: "Tartalmazzon egy almappát",
        question_link_add_link: "Könyvtárból való hivatkozás fájlok",
        question_link_view_folder: "Könyvtár nézet"
      },
      mainQuestion: "Új/Frissített Fő kérdések",
      subQuestion: "Új/Frissített al-kérdések",
      retiredQuestion: "Visszavont al-kérdések",
      libraries: "Új/Frissített könyvtárak",
      noFiles: "A fájl nem elérhető."
    },
    library: {
      selectFile: "Kérem válasszon ki legalább egy fájlt!",
      fileSelected: "File kiválasztva",
      publish_library_file: "Kérjük válasszon legalább egy fájlt amit közzétesz!",
      library_files_deleted: "Biztosan törölni szeretné ezt a fájlt?",
      library_files_linked_deleted: "Biztosan törli ezt a fájlt és a kérdőívben létrehozott linkeket?",
      max_size_error: "A fájl mérete meghaladja az 1GB-ot",
      empty_file_desc: "Kérjük adja meg a fájl leírását!",
      file_not_selected: "Kérjük válassza ki a feltöltendő fájlt (max 1 GB-ig)",
      file_type_error: "Ez a fájl típus nem engedélyezett.",
      file_name_error: "Érvénytelen karakter a fájlnévben.",
      file_name_lenght_error: "A fájl neve maximum 50 karakter lehet.",
      addFolder: "Mappa hozzáadása",
      editFolder: "Mappa szerkesztése",
      currentFolder: "Mappa név",
      parentFolder: "Fő mappa neve",
      emptyFolderName: "A mappa nem lehet üres.",
      invalidFolderName: "A mappa neve nem tartalmazhat '/' karaktert",
      folder_created: "A mappa sikeresen szerkesztve.",
      folder_updated: "A mappa sikeresen frissítve.",
      folder_not_found: "Mappa nem található",
      lib_invalid_toolbox: "Érvénytelen eszközkészlet",
      lib_folder_already_exists: "Ez a mappa már létezik.",
      lib_folder_deleted: "Ez a mappa sikeresen törölve.",
      library_files_published: "A fájlok megjelentek.",
      addFile: "Fájl hozzáadása",
      editFile: "Fájl szerkesztés",
      fileName: "Fájl név",
      upload: "Feltöltés",
      desc: "Leírás",
      folderCreationErr: "A hierahia 2 szintre korlátozódik",
      subFolderWarning: "Kérjük válasszon egy almappát a fájlok hozáadásához",
      deleteWarning: "Kérjük válassza ki a törölni kívánt mappát!",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Ez a fejezet már létezik.",
      chapter_saved: "Fejezet mentve",
      chapter_cannot_delete: "Ezt a fejezetet már nem lehet törölni, mivel az al-kérdések már megjelentek.",
      failed: "Hiba!",
      chapter_deleted: "Fejezet törölve",
      chapter_nonEmpty: "Kérjük hozzon létre egy fejezet nevet",
      addchapter: "Fejezet hozzáadása",
      editChapter: "Fejezet szerkesztése",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Csatolmányok",
      comments: "Megjegyzések",
      evidence: "Tényadat",
      evidences: "Tényadatok ",
      yes: "Igen",
      no: "Nem",
      na: "N/A",
      uploadEvidence: "Tényadatok feltöltése",
      fileUpload: "Kérjük válassza ki a feltöltendő fájlt (max 1 GB-ig)",
      who: "Ki?",
      answer: "Válasz",
      comment: "Comment",
      date: "Dátum",
      submit: "Elfogadva",
      commentMsg: "Adja meg a megjegyzést.",
      noCommentAvailable: "Nincs elérhető megjegyzés.",
      uploadSuccessMsg: "A fájl sikeresen feltöltve",
      uploadErrorMsg: "Feltöltetlen fájlok száma",
      fileWithSameName: "Ilyen nevű fájl már létezik.",
      noEvidence: "Nem állnak rendelkezésre tényadatok",
      createAction: "Akcióterv készítése",
      chapterName: "Fejezet neve",
      questionNo: "Kérdés.",
      when: "Mikor",
      noRecords: "Nincs elérhető record.",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Kérdés",
      kaizenPoints: "Kaizen pontok",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Walk Around Check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Lakatos & Fényező",
      TSM_USED_CAR: "Használt autó",
      value_chain: "Érték Lánc",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Hirdetőtábla",
      kpi_target_not_set: "KPI célok nincsennek beállítva",
      kpi_initial_not_set: "Kezdő KPI nincs beállítva",
      kpi_monthly_not_set: "Havi KPI-ok mérése nincs beállítva",
      kpi_below_target: "KPI-ok a célok alatt vannak",
      activation: "Aktiválás",
      users: "Felhasználók",
      nmscs: "NMSC's",
      activeRetailer: "Aktív Márkakereskedés",
      certificationRate: "Minősítés arány",
      reCertificationRate: "Újra minősítés arány",
      activityDue: "Esedékes tevékenységek",
      avgdays: "Átlag napok / Toolbox",
      overDueAct: "Lejárt tevékenységek",
      actionItem: "Akció pontok",
      bussResult: "Üzleti eredmények",
      toolboxImpl: "Toolbox bevezetés",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Legutóbbi frissítések",
      rolloutTopNmsc: "Bevezetési eredmények - Top NMSC-k",
      bestPractice: "Best Practise Tábla",
      availableKpi: "Elérhető KPI-k",
      selectedKpi: "Kiválasztott KPI-k",
      dealerBestPractice: "Legjobb kereskedői gyakorlatok mutatói",
      kpiList: "KPI-k lista",
      go: "MEGY",
      nodata: "Nem elérhető adat",
      businessResultTop: "Üzleti eredmények - Top Márkakereskedések",
      businessResultNMSC: "Üzleti eredmények - Top NMSC-k"


    },
    dealerCertification: {
      dealerCertification: "Kereskedői minősítés",
      readyForCertification: "Kész a minősítésre",
      certification: "Minősítés",
      submittedOn: "Benyújtva",
      certificateDate: "Minősítési dátuma",
      reCertificateDate: "Újra minősítési dátuma", 
      toolboxDetails: "Toolbox részletek", 
      approve: "Elfogadva", 
      reject: "Elutasítva", 
      pending: "Függőben levő",
      egion: "Régió"



    },
    uploadBulk:{
      browse:"Browse",
      executionId:"Végrehajtási azonosító (id)",
      uploadedOn:"Feltöltve",
      lastUpdatedOn:"Utolsó frissítés",
      noDataTable:"No data to view",
      kpiUpload:"KPI-ok feltöltése",
      action:"Tevékenység",
      processed:"Feldolgozott",
      error:"Hiba történt kérem később próblja újra.",
      received:"Received",
      createActivity:"Tevékenységek létrehozása",
      fileUpload:"File Upload"
    },

    userManagement:{
      userManagement: "Felhasználók kezelése",
      createUser:"Create User",
      editUser:"Edit User",
      viewUser:"View User",
      nmscId:"NMSC ID",
      tarsId:"TARS ID",
      firstName:"Keresztnév",
      lastName:"Vezetéknév",
      emailId:"E-mail",
      britDealerChamp:"Brit Dealer Champion",
      invalidEmail:"Please enter valid E-Mail ID",
      invalidTars:"Please enter valid TARS ID",
      invalidFirstName:"Please enter valid First Name",
      invalidLastName:"Please enter valid Last Name",
      user_created:"User Created",
      user_updated:"User updated",
      duplicate_tarsuser:"This TARS id is already registered in the KET database.",
      user_define_in_tool:"User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on:"User is referenced in Ongoing activity",
      pbiAccount : "PBI számla",
      invalidPbiAccount :"A PBI számlának legalább 3 karaktert kell tartalmazni",
      businessArea: "Üzleti terület",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Csak Value Chain",
      britOnly: "Csak BRIT",
      britEss: "A márkaszerviz frissítette az akció tervét",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Csak",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"

    },
    UserManagement :{
      nmscId : "NMSC ID",
      tarsId : "TARS ID",
      firstName: "Keresztnév",
      lastName : "Vezetéknév",
      emailId : "E-Mail ID",
      languageCode : "Nyelv kód",
      ACTIVE : "Active",
      INACTIVE : "Inaktív",
      addUser : "felhasználó hozzáadása",
      Assign: "Kijelöl",
      List: "Lista",
      user : "Felhasználó",
      error_message : "Hiba történt kérem később próblja újra.",
      role : "Felhasználói szerepkör",
      standardMessage : "Felhasználó nem található.",
      dealerCode:"Dealer kód",
      a2d: "A2D UUID",
      britReady: "A márkakereskedő részt vett a BRIT Ready-ben.",
      britLive: "A márkakereskedő részt vett a BRIT live-on.",
      britJapan: "A márkakereskedő részt vett a BRIT Discovery Japan-ban.",
      britEss:"A márkaszerviz frissítette az akció tervét",
      addDealer: "Kereskedés hozzáadása (A2D)",
      noDealer: "Márkakereskedés nem található.",
      dealerUuid: "Dealer uuid kód (A2D)",      
      language: "Nyelv",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi :{
      survey:"Survey",
      evaluationItem:"Evaluation Item",
      kpiMetric:"KPI Metric",
      stratagicPillar:"Strategic Pillar",
      addKPI:"Add KPI",
      edit:"Edit",
      delete:"Delete",
    },
    dealerManagement: {
      invalidUuid:"Kérem adja meg az érvényes A2D kódot.",
      invalidDealerName:"Kérem adja meg az érvényes dealer nevet.",
      invalidCity:"Kérem adja meg az érvényes helyet.",
      invaliDealerCode:"Kérem adja meg az érvényes dealer kódot.",
      existingUuid:"Aktuális A2D uuid kód",
      updateUuid:"Frissítse az A2D uuid kódot",
      newUuid:"Új A2D uuid kód",
      },
      networkManagement: {
        networkManagement: "Hálózat kezelés"
      },
      kpiEntry:{
        status : 'Státusz',
        evaluationYear: 'Evaluation Year',
        frequency:'Frequency',
        typeOfTarget : 'Type of Target',
        target : 'Target',
        unit : 'Unit',
        standardMessage : 'No Kpi Data Found',
        srNo: 'S.No.',
        kpi:'KPI',
        actuals1 : 'Actuals should not be more than allotted points. Please re-enter',
        actuals : 'Actuals',
        subPillar:'Sub Pillar',
        businessFunc:'Business Function',
        brand:'Brand',
        inputTxt:"Inputs"
        },
        reachItDashboard:{
          topNavReachIT:"ReachIT",
          downloads:"Letöltések",
          historyFiles:"Fájl előzmények",
          reachText1:"Köszöntjük a ReachIT oldalán. A TME Environmental Management csapata  ezen az oldalon keresztül oszt meg releváns információkat az SCIP-vel kapcsolatban:  árucikkekben vagy összetett tárgyakban (termékekben) található, aggodalomra okot adó anyagok, amelyek a hulladékokról szóló keretirányelv (WFD) hatálya alá tartoznak, hogy segítse az Önök SCIP-bejelentési kötelezettség teljesítését.",
          reachText2:"Kérdés esetén kérjük, keresse a TME Environmental Management csapatát az alábbi linken",
          reachText3:"Kérdés esetén kérjük, keresse az NMSC-t.",
          envMngt:"Environmental Management",
          fileStatistics: "Letöltött fájl statisztika",
          Downloaded: "Letöltve",
          not: "Nem",
          exportToExcel:"Exportálás Excelbe."
        },
        usedCarComment:{
          ongoing:"Ongoing",
          completed:"Completed",
          commentHistory:"Monthly Comments History",
          addComment:"Add Monthly Comment",
          month:"Month",
          comment:"Comment",
          username:"Username",
          email:"Email",
          createdDate:"Created Date"
        },
  }
};

export default translationsHU;
