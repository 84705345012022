export default function leftNavReducer(state = {
    userMdl: {}, blocking: false
}, action) {
    switch (action.type) {
        case "LEFT_NAV":
            return Object.assign({}, state, {
                leftNavList: action.payload
            });
        case "TOOLBOX_BY_TYPE":
            return Object.assign({}, state, {
                toolboxByType: action.payload
            });
        case "PILLAR_TOOLBOX_DATA":
            return Object.assign({}, state, {
                pillarToolboxData: action.payload
            });
        case "PILLAR_TO_YEAR_TOTAL_POINT":
            return Object.assign({}, state, {
                pillarToYrTotalPt: action.payload
            });    
        case "TOOLBOX_BY_TYPE_LIST":
            return Object.assign({}, state, {
                nmscToolIdList: action.payload
        });
        case "TOOLBOX_BY_TYPE_LANG":
        return Object.assign({}, state, {
            nmscDropdownToolList: action.payload
    });
        case "TOOLBOX_SAVED":
        return Object.assign({}, state, {
            refreshRequired: true,
            closeModal: true,
            message: action.payload.desc
        });
        case "NMSC_TOOLBOX_SAVED":
        return Object.assign({}, state, {
            refreshRequired: true,
            closeModal: true
        });
        case "DELETE_TOOLBOX":
        return Object.assign({}, state, {
            message: action.payload.desc,
            closeModal: true
        });
        case "CEA_BUSINESS_OWNER":
        return Object.assign({}, state, {
            ceaBusinessOwnerList: action.payload
        });
        default:
            return state;
    }
};