import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row, Col } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { updateLibFile,updateNmscLibFile } from '../../../actions/libraryAction';
import { insertToLibraryFileTbl,insertToNmscLibraryFileTbl } from '../../../actions/libraryAction';
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery');

class AddEditFile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fileName: '',
            fileDesc: '',
            selectedFile: '',
            fileId: '',
            description:'',
            nmscFileId:''
        }
        this.fileUploadBxRef= React.createRef();
        this.libFileUploadRef= React.createRef();
    }
    componentDidMount() {
        // $('#libFileUploadTrigger').click(function () { $('#libFileUpload').trigger('click'); });
        $('#libFileUploadTrigger').on("click",function () { $('#libFileUpload').trigger('click'); });
        
        if (this.props.row !== undefined) {
            if(this.props.isNmsc){
                this.setState({
                    fileName: this.props.row.fileName,
                    description: this.props.row.desc,
                    selectedFile: this.props.row.fileName,
                    nmscFileId: this.props.row.fileId
    
                });
            }
            this.setState({
                fileName: this.props.row.fileName,
                fileDesc: this.props.row.desc,
                selectedFile: this.props.row.fileName,
                fileId: this.props.row.fileId

            });
            // $("#fileUploadBx").attr("placeholder", this.props.row.fileName);
        }
    }
    changeFileName = e => {
        this.setState({ fileName: e.target.value });
    }
    changeFileDesc = e => {
        this.setState({ fileDesc: e.target.value });
    }

    changeDescription = e => {
        this.setState({ description: e.target.value });
    }

    updateFile = () => {
     /*    if(this.props.isNmsc){
            if (this.state.description.length === 0) {
                toastr.error(I18n.t("library.empty_file_desc"));
                return;
            }
    }else{
        if (this.state.fileDesc.length === 0) {
            toastr.error(I18n.t("library.empty_file_desc"));
            return;
        }
    }   */      
        if (this.state.selectedFile.length === 0) {
            toastr.error(I18n.t("library.file_not_selected"));
            return;
        }

        if(this.props.isNmsc){
            let fileObj = {
                nmscFolderId: this.props.parentFolder.id,
                nmscFileId: this.state.fileId,
                description: this.state.description,
                nmscCode: this.props.user.userMdl.nmsc
            };
            this.props.dispatch(updateNmscLibFile(fileObj));
        }
        else{
            let fileObj = {
                id: this.props.parentFolder.id,
                fileId: this.state.fileId,
                desc: this.state.fileDesc
            };

        this.props.dispatch(updateLibFile(fileObj));
    }
        this.props.toggle(this.props.modalFor);
    }

    saveFile = () => {
        // if(this.state.fileName.length === 0){
        //     toastr.error(I18n.t("library.empty_file_name"));
        //     return;
        // }
 /*        if(this.props.isNmsc){
            if (this.state.description.length === 0) {
                toastr.error(I18n.t("library.empty_file_desc"));
                return;
            }
    }else{
        if (this.state.fileDesc.length === 0) {
            toastr.error(I18n.t("library.empty_file_desc"));
            return;
        }
    }    */     
        if (this.state.selectedFile.length === 0) {
            toastr.error(I18n.t("library.file_not_selected"));
            return;
        }

        

        //To check invalid character in file name
        // if(!(/^[\sA-Za-z0-9_!.*'()-]*$/.test(this.state.selectedFile))){
        //     toastr.error(I18n.t("library.file_name_error"));
        //     return;
        // }

        //To check if file is a script file.        
        if (disallowedFileList.indexOf(this.state.selectedFile.split('.').pop().toUpperCase()) !== -1) {
            toastr.error(I18n.t("library.file_type_error"));
            return;
        }

        if (this.libFileUploadRef.current && this.libFileUploadRef.current.files[0]) {

            // Actual file with contents
            var fileContentList = this.libFileUploadRef.current.files;

            var fileObjList = [];
            var fileSizeInMB = 0;
            var fileSize = 0;
            for (var i = 0; i < fileContentList.length; i++) {

                //convert size to MB
                fileSizeInMB = (fileContentList[i].size / 1024) / 1024;
                fileSize = fileSize + fileSizeInMB;

                //Restrict If total size of file upload is greater than 1 GB
                if (fileSize > 2500) {
                    toastr.error(I18n.t("library.max_size_error"));
                    return
                }

                //Restrict file name length to 50 chars
                if (fileContentList[i].name.length > 50) {
                    toastr.error(I18n.t("library.file_name_lenght_error"));
                    return;
                }

                //To check if file is a script file.   
                if (disallowedFileList.indexOf(fileContentList[i].name.split('.').pop().toUpperCase()) !== -1) {
                    toastr.error(I18n.t("library.file_type_error"));
                    return;
                }

                //Creating object from actual file object, so that can transfer to backend 
                var file = {};
                file['name'] = fileContentList[i].name
                file['size'] = fileContentList[i].size;
                fileObjList.push(file);
            }

            //TODO: 1. Get presigned Url.
            //TODO: 2. Upload file to bucket using presigned url.
            //TODO: 3. Save File details in DB.

            if(this.props.isNmsc){
                let s3Object = {
                    nmscFileId:this.props.nmscFileId ? this.props.nmscFileId : 0,
                    directory: this.props.parentFolder.name,
                    fileName: this.state.selectedFile,
                    fileList: fileObjList,
                    //bucketName:"ket-library-dev",
                    nmscFolderId: this.props.parentFolder.id,
                    description: this.state.description,
                    uploadFile: true,
                    nmscCode: this.props.user.userMdl.nmsc,
                    nmscLang: this.props.activeTabNmscLang,
                    type: this.props.type ? this.props.type : "Local",
                    userId: this.props.user.userMdl.username
                };
                s3Object.toolBoxID = this.props.toolboxId
                this.props.dispatch(insertToNmscLibraryFileTbl(s3Object, fileContentList));
            }
            else{           
            let s3Object = {
                directory: this.props.parentFolder.name,
                fileName: this.state.selectedFile,
                fileList: fileObjList,
                //bucketName:"ket-library-dev",
                id: this.props.parentFolder.id,
                fileDesc: this.state.fileDesc,
                uploadFile: true,
                userId: this.props.user.userMdl.username
            };
            //TODO Set UI toolbox ID
            s3Object.toolBoxId = this.props.toolboxId
            this.props.dispatch(insertToLibraryFileTbl(s3Object, fileContentList));
        }

            //This is to hide model popup of click of save button
            this.props.toggle(this.props.modalFor);
        }
    }
    handleLibFileChange(file) {
        if (
            this.libFileUploadRef.current &&
            this.libFileUploadRef.current.files[0] &&
            this.libFileUploadRef.current.files[0].name
        ) {
            var fileCount = this.libFileUploadRef.current.files.length + " files";
            //var fileName = this.libFileUploadRef.current.files[0].name;
            $("#fileUploadBx").attr("placeholder", fileCount);
            this.setState({ selectedFile: fileCount });
        }
    }
    preventDefault() { }
    render() {
        let isAddModal = this.props.modalFor && this.props.modalFor === "Add" ? true : false;
        return (
            <Modal centered
                className={"addEditModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '20%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader>
                    {isAddModal ? I18n.t("library.addFile") :
                        I18n.t("library.editFile")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <label className={"questionLabel"}>
                                {I18n.t("library.parentFolder")}
                            </label>
                            <input type="text"
                                className="form-control black-font"
                                defaultValue={this.props.parentFolder.name}
                                disabled="disabled" />
                        </Row>
                        <br />
                        {/* <Row>
                            <label className={"questionLabel"}>
                                {I18n.t("library.fileName")}
                                <span className="redFont"> *</span>
                            </label>
                            <input type="text"
                                className="form-control"
                                maxLength="100"
                                value={this.state.fileName} onChange={this.changeFileName} />
                        </Row> */}
                        <br />
                        <Row>
                            <label className={"questionLabel"}>
                                {I18n.t("library.upload")}
                                <span className="redFont"> *</span>
                            </label>
                            <Col md="11" className="pl-0">
                                <input
                                    id="fileUploadBx"
                                    ref={this.fileUploadBxRef}
                                    type="text"
                                    className="form-control"
                                    placeholder={I18n.t("evaluation.fileUpload")}
                                    readOnly
                                />
                            </Col>{console.log(isAddModal)}
                            {isAddModal ?
                                <Col md="1" className="ml-0 pl-0">
                                    <img id="libFileUploadTrigger" src="images/folder.png" style={{ cursor: 'pointer' }} alt=""
                                     onClick={() => {
                                        document.getElementById("libFileUpload").click();
                                      }}
                                    />

                                    <input id="libFileUpload"
                                        type="file"
                                        onChange={e => {
                                            this.handleLibFileChange(e.target);
                                        }}
                                        ref={this.libFileUploadRef}
                                        accept={".xlsx,.xls,.doc,.docx,.pdf,.jpg,.jpeg,.png,.txt"}
                                        style={{
                                            display: "none"
                                        }}
                                        multiple
                                    />
                                </Col> : null}
                        </Row>
                        <br />
                        {this.props.isNmsc?
                        <Row>
                        <label className={"questionLabel"}>
                            {I18n.t("library.desc")}
                           {/*  <span className="redFont"> *</span> */}
                        </label>
                        <textarea className="form-control"
                            rows="2" maxLength="500"
                            value={this.state.description} onChange={this.changeDescription} />
                    </Row>
                        :
                        <Row>
                            <label className={"questionLabel"}>
                                {I18n.t("library.desc")}
                             {/*    <span className="redFont"> *</span> */}
                            </label>
                            <textarea className="form-control"
                                rows="2" maxLength="500"
                                value={this.state.fileDesc} onChange={this.changeFileDesc} />
                        </Row>
                        }
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle(this.props.modalFor)}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button>
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={isAddModal ? this.saveFile : this.updateFile}
                    >
                        {isAddModal ?
                            I18n.t("tmeAdminMaster.save") :
                            I18n.t("tmeAdminMaster.update")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddEditFile)));


let disallowedFileList = "0XE,73K,89K,8CK,A6P,A7R,AC,ACC,ACR,ACTC,ACTION,ACTM,AHK,AIR,APK,APP,APP,APP,APPLESCRIPT,ARSCRIPT,ASB,AZW2,BA_,BAT,BEAM,BIN,BIN,BTM,CACTION,CEL,CELX,CGI,CMD,COF,COFFEE,COM,COMMAND,CSH,CYW,DEK,DLD,DMC,DS,DXL,E_E,EAR,EBM,EBS,EBS2,ECF,EHAM,ELF,EPK,ES,ESH,EX4,EX5,EXE,EXE,EXE1,EXOPC,EZS,EZT,FAS,FAS,FKY,FPI,FRS,FXP,GADGET,GPE,GPU,GS,HAM,HMS,HPF,HTA,ICD,IIM,IPA,IPF,ISU,ITA,JAR,JS,JSE,JSF,JSX,KIX,KSH,KX,LO,LS,M3G,MAC,MAM,MCR,MCR,MEL,MEM,MIO,MLX,MM,MPX,MRC,MRP,MS,MS,MSL,MXE,N,NCL,NEXE,ORE,OSX,OTM,OUT,PAF,PAFEXE,PEX,PHAR,PIF,PLSC,PLX,PRC,PRG,PRG,PS1,PVD,PWC,PYC,PYO,QIT,QPX,RBF,RBX,RFU,RGS,ROX,RPJ,RUN,RXE,S2A,SBS,SCA,SCAR,SCB,SCPT,SCPTD,SCR,SCRIPT,SCT,SEED,SERVER,SHB,SMM,SPR,TCP,THM,TIAPP,TMS,U3P,UDF,UPX,VBE,VBS,VBSCRIPT,VDO,VEXE,VLX,VPM,VXP,WCM,WIDGET,WIDGET,WIZ,WORKFLOW,WPK,WPM,WS,WSF,WSH,X86,XAP,XBAP,XLM,XQT,XYS,ZL9"
