import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyMiddleware, compose, createStore } from 'redux'
import { createBrowserHistory } from 'history';
import { loadTranslations, setLocale, syncTranslationWithStore } from 'react-redux-i18n';
import ReduxToastr from 'react-redux-toastr';
import { routerMiddleware } from 'connected-react-router'
import App from './App'
import createRootReducer from './reducers';
import thunk from 'redux-thunk'
import './index.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
//import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import axios from 'axios';
import { setBlockingState } from "./actions/loginAction";
import translationsEN from './components/i18n/ket.i18n_EN.jsx';
import translationsFR from './components/i18n/ket.i18n_FR.jsx';
import translationsDE from './components/i18n/ket.i18n_DE.jsx';
import translationsNL from './components/i18n/ket.i18n_NL.jsx';
import translationsPL from './components/i18n/ket.i18n_PL.jsx';
import translationsPT from './components/i18n/ket.i18n_PT.jsx';
import translationsRO from './components/i18n/ket.i18n_RO.jsx';
import translationsRU from './components/i18n/ket.i18n_RU.jsx';
import translationsTR from './components/i18n/ket.i18n_TR.jsx';
import translationsSL from './components/i18n/ket.i18n_SL.jsx';
import translationsBG from './components/i18n/ket.i18n_BG.jsx';
import translationsET from './components/i18n/ket.i18n_ET.jsx';
import translationsLT from './components/i18n/ket.i18n_LT.jsx';
import translationsLV from './components/i18n/ket.i18n_LV.jsx';
import translationsSV from './components/i18n/ket.i18n_SV.jsx';
import translationsBS from './components/i18n/ket.i18n_BS.jsx';
import translationsCS from './components/i18n/ket.i18n_CS.jsx';
import translationsDA from './components/i18n/ket.i18n_DA.jsx';
import translationsEL from './components/i18n/ket.i18n_EL.jsx';
import translationsES from './components/i18n/ket.i18n_ES.jsx';
import translationsHR from './components/i18n/ket.i18n_HR.jsx';
import translationsHU from './components/i18n/ket.i18n_HU.jsx';
import translationsIS from './components/i18n/ket.i18n_IS.jsx';
import translationsNO from './components/i18n/ket.i18n_NO.jsx';
import translationsSK from './components/i18n/ket.i18n_SK.jsx';
import translationsSR from './components/i18n/ket.i18n_SR.jsx';
import translationsUK from './components/i18n/ket.i18n_UK.jsx';
import translationsHB from './components/i18n/ket.i18n_HB.jsx';
import translationsFI from './components/i18n/ket.i18n_FI.jsx';
import translationsIT from './components/i18n/ket.i18n_IT.jsx';
import translationsCI from './components/i18n/ket.i18n_CI.jsx';

//------BELOW TRANSLATION CONTENT WILL BE IN RUSSIAN LANGUAGE
import translationsAZ from './components/i18n/ket.i18n_AZ.jsx';
import translationsHY from './components/i18n/ket.i18n_HY.jsx';
import translationsKA from './components/i18n/ket.i18n_KA.jsx';
import translationsBE from './components/i18n/ket.i18n_BE.jsx';
//-------------------------------------------------------------




const history = createBrowserHistory()

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(
  createRootReducer(history),
  composeEnhancer(
    applyMiddleware(
      thunk,
      routerMiddleware(history),
    ),
  ),
);

syncTranslationWithStore(store);
store.dispatch(loadTranslations({translationsAZ, translationsHY,translationsKA,translationsBE,translationsDE,translationsEN,translationsET,translationsFR,translationsLT,translationsLV,translationsNL,translationsPL,translationsPT,translationsRO,translationsRU,translationsSL,translationsSV,translationsTR,translationsBG,translationsBS,translationsCS,translationsDA,translationsEL,translationsES,translationsCI,translationsHR,translationsHU,translationsIS,translationsNO,translationsSK,translationsSR,translationsUK,translationsHB,translationsIT,translationsFI}));
store.dispatch(setLocale('translationsEN.en'));

// --------- HTTP INTERCEPTOR -----------------------
let oldXHROpen = window.XMLHttpRequest.prototype.open;
window.XMLHttpRequest.prototype.open = function (method, url, async, x, y) {
  // Performance.navigation.type will be 1 for reload event
  // On reload route page to '/'
  if (window.performance) {
    if (performance.navigation.type === 1) {
      window.location = "/login";
      //alert( "Page reloaded");
    } else {
      // alert( "Page not reloaded");
    }
  }

  // Call back Method
  this.addEventListener('load', function () {
    // Callback for response
    //alert('load: ' + this.responseText);
  });

  return oldXHROpen.apply(this, arguments);
}
// --------- HTTP INTERCEPTOR -----------------------

var reqCount=0;
var resCount=0;
axios.interceptors.request.use(function (request) {
  reqCount =reqCount+1;
  // Do something before request is sent
  //console.log("Request Received");
  if(!request.headers.unblockUI){
    store.dispatch(setBlockingState(true));
  }
  return request;
}, function (error) {
  // Do something with request error
  store.dispatch(setBlockingState(false));
  resCount=0;
  reqCount=0;
  return Promise.reject(error);
});

// Add a response interceptor

axios.interceptors.response.use(function (response) {
  // Do something with response data
  resCount=resCount+1
  // Do something with response data
  //console.log("response received");
 
 if(resCount===reqCount){
   store.dispatch(setBlockingState(false));
   resCount=0;
   reqCount=0;
 }

  return response;
}, function (error) {
  // Do something with response error
  store.dispatch(setBlockingState(false));
  resCount=0;
  reqCount=0;
  return Promise.reject(error);
});



const render = () => {
  ReactDOM.render(
    <Provider store={store}>
      <div>
        <App history={history} />
        <ReduxToastr
          timeOut={0}
          newestOnTop
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
        />
      </div>
    </Provider>,
    document.getElementById('root')
  )
}

render()
