import React from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { getActionItemReport, downloadActionItem } from "../../../actions/dashboardAction";
import LoadingOverlay from 'react-loading-overlay';
import { Fa } from 'mdbreact';
import ClampLines from 'react-clamp-lines';
import moment from "moment";
var I18n = require("react-redux-i18n").I18n;


class ActionItemReport extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            actionItemReport: [],
            selectedToDownload: []
        }
    }

    componentWillMount() {
        this.fetchData("");
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.actionItemReport) {
            this.setState({ actionItemReport: nextProps.actionItemReport, isLoading: false });
        }
    }

    downloadPdf = () => {
        this.state.queryDto.actionPlanIdList = this.state.selectedToDownload;
        this.state.queryDto.selectedBrand = this.props.theme.name;
        this.props.dispatch(downloadActionItem(this.state.selectedToDownload, this.state.queryDto));

    }
    fetchData = (filter) => {
        this.setState({ isLoading: true });
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let brand = this.props.theme.name;
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            userId: this.props.userMdl.username,
            brand: [brand],
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.setState({ queryDto });
        this.props.dispatch(getActionItemReport(queryDto));
    }

    statusFormatter = (cell, row) => {
        return (
            <div>
                <button
                    variant="contained"
                    style={{
                        backgroundColor:
                        row.status === "Open"
                            ? "#cf142b"
                            : row.status === "Closed"
                            ? "#3CB043"
                            : row.status === "Completed"
                            ? "#3CB043"
                            : "primary",
                        lineHeight: 1,
                        color: "white",
                        textAlign: "center",
                        fontSize: "0.675rem"
                    }}
                disabled
                >
                {I18n.t(row.status)}
                </button>
            </div>
        );
    }

    dueDateFormatter(cell, row, rowIndex, formatExtraData) {
        var dueDate = row.dueDate.split("/");
        dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);            
        let today = moment(new Date()).format('DD/MM/YYYY');
        var todayDate = today.split("/");
        todayDate = new Date(todayDate[2], todayDate[1] - 1, todayDate[0]);
        const color =
            row.status === "Open" && todayDate > dueDate 
            // && formatExtraData.activity.status && 
            // (formatExtraData.activity.status === "Ongoing" || formatExtraData.activity.status === "Closed")
            ? "red": "";
        return (
          <span style={{color: color}}>
              {row.dueDate}
          </span>
        );
    }

    buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
            // warning sign to highlight the Action items which are overdue. 
            var dueDate = row.dueDate.split("/");
            dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);            
            let today = moment(new Date()).format('DD/MM/YYYY');
            var todayDate = today.split("/");
            todayDate = new Date(todayDate[2], todayDate[1] - 1, todayDate[0]);
            const warningIconClass =
              row.status === "Open" &&
              todayDate > dueDate 
            //   && this.props.activity.status !== undefined &&
            //   (this.props.activity.status === "Ongoing" ||
            //     this.props.activity.status === "Closed")
                ? "controlledIcon1"
                : "hideIcon1";
        return (
            <div>
                <div className="btn-toolbar float-right">                                     
                    <a className={warningIconClass}>
                        <div title={I18n.t('tmeAdminMaster.dueDateExceed')} className="warning-icon" />
                    </a>
                </div>
            </div>
        );
    }

    counterMeasureFormatter = (cell, row) => {
        return (
            <ClampLines
                text={cell}
                lines={2}
                ellipsis="..."
                buttons={false}
                className="custom-class"
            />
        );
    }

    render() {
        const columns = [{
            dataField: 'counterMeasure',
            text: I18n.t('Table.counterMeasure'),
            formatter: this.counterMeasureFormatter,
            style: { width: "18%", textAlign: "left", wordWrap: "break-word" },
            headerStyle: { width: "18%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            title: true,
            headerTitle: true
        }, {
            dataField: 'location',
            text: I18n.t('Table.location'),
            sort: true,
            style: { width: "15.5%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "15%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            title: true,
            headerTitle: true
        }, {
            dataField: 'toolBoxName',
            text: I18n.t('Table.toolbox'),
            sort: true,
            style: { width: "8%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "8%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            title: true,
            headerTitle: true
        }, {
            dataField: 'actionPlanDisplayId',
            text: I18n.t('Table.id'),
            style: { width: "6%" },
            headerStyle: { width: "6%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerTitle: true

        }, {
            dataField: 'status',
            formatter: this.statusFormatter,
            text: I18n.t('Table.status'),
            sort: true,
            style: { width: "8%" },
            headerStyle: {  width: "8%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerTitle: true
        }, {
            dataField: 'startDate',
            text: I18n.t('Table.startDate'),
            sort: true,
            style: { width: "8%" },
            headerStyle: {  width: "7.5%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerTitle: true
        }, {
            dataField: 'dueDate',
            text: I18n.t('Table.dueDate'),
            sort: true,
            style: { width: "8%" },
            formatter: this.dueDateFormatter,
            formatExtraData: this.props,
            headerStyle: {  width: "8%", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerTitle: true
        }, {
            dataField: 'assignee',
            text: I18n.t('Table.assignee'),
            style: { width: "9%",textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            sort: true,
            headerStyle: { width: "10%",textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerTitle: true,
            title: true,
        },
        {            
            dataField: "",
            text: "",
            isDummyField: 'formatter',
            formatter: this.buttonFormatter,
            formatExtraData: this.props,
            style: { width: "10%" },
            headerStyle: { width: "10%" }
        }
    ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox" || e.target.cellIndex === 0) {
                    e.target.checked = true;
                    var index = this.state.selectedToDownload.indexOf(row.actionPlanId);
                    if (index > -1) {
                        this.state.selectedToDownload.splice(index, 1);
                    } else {
                        this.state.selectedToDownload.push(row.actionPlanId);
                    }

                    this.setState({ selectedToDownload: this.state.selectedToDownload });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedToDownload,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                rows.forEach((row) => {
                    selectedRows.push(row.actionPlanId);
                });
                if (isSelect && selectedRows.length > 0) {
                    this.setState({ selectedToDownload: selectedRows });
                    return selectedRows;
                }
                else {
                    this.setState({ selectedToDownload: [] });
                }
            }
        };

        return (
            <div className="actionItem">
                <div className={"widget-header-report  widget-header-" + this.props.theme.name} title={I18n.t('Table.ActionItemReport')}>
                    {I18n.t('Table.ActionItemReport')}
                    {this.state.selectedToDownload.length <= 0 ? <span style={{ float: 'right', fontSize: '1.2rem' }} ><Fa icon="download" className="mr-3" /></span>
                        : <span style={{ float: 'right', cursor: 'pointer', fontSize: '1.2rem' }} onClick={this.downloadPdf}><Fa icon="download" className="mr-3" /></span>
                    }

                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="actionItemTable">
                        <div className="actionItemReport">
                            <BootstrapTable
                                keyField='actionPlanId'
                                data={this.state.actionItemReport}
                                columns={columns}
                                selectRow={selectRow}
                                hover
                                condensed
                                rowEvents={rowEvents}
                                noDataIndication={I18n.t('evaluation.noRecords')}
                            />
                        </div>
                    </div>

                </LoadingOverlay>
            </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        actionItemReport: state.dashboardReducer.actionItemReport
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ActionItemReport)));

