import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from '../../../themes/theming';
import { Footer } from 'mdbreact';

class AppFooter extends React.Component {
    render() {
        return (
            <Footer className="font-small fixed-bottom footer-font" style={{ textAlign: "center", backgroundColor: "white" }}>
               <span className=""> &copy;{(new Date().getFullYear())} Toyota Motor Europe </span>
            </Footer>
        );
    }
}

export default connect()(withTheme(AppFooter));