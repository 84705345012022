export default function ceaNetKPIReducer(state = {
    userMdl: {}, blocking: false, closeModal: false,
    ceaSubpillar: [], ceaDropdowns: {},
}, action) {
    switch (action.type) {

        case "CEA_NET_KPI_DATA":
            // console.log("reducer---CEA_NET_KPI_DATA")
            return Object.assign({}, state, {
                ceaNetKpiList: action.payload,
                closeModal: false
            })
        case "BUSINESS_POINTS":
            return Object.assign({}, state, {
                businessFunctionPoints: action.payload,
            })
        case "BUSINESS_STATUS":
            return Object.assign({}, state, {
                businessFunctionStatus: action.payload,
            })
        case "CEA_NET_SUBPILLAR":
            return Object.assign({}, state, {
                ceaSubpillar: action.payload,
            })

        case "CEA_NET_DROPDOWNS":
            return Object.assign({}, state, {
                ceaDropdowns: action.payload,
            })

        case "CEA_NET_KPI_DEATAILS":
            return Object.assign({}, state, {
                getKpiDetails: action.payload,
            })
        case "COPY_KPI_HISTORY_DATA":
            return Object.assign({}, state, {
                copyKPIHistoryList: action.payload
            })
        case"LEXUS_PUBLISHED_KPI":
            return Object.assign({}, state, {
                getLexusPublishedKpiList: action.payload
            })

        default:
            return state;
    }

}
