import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../themes/theming";
import { withRouter } from "react-router-dom";
import { Fa} from 'mdbreact';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory  from 'react-bootstrap-table2-editor';
import CeaNetKpiModal from '../ceaNetKpiModal';
import HistoryMonthlyComments from './historyMonthlyComments';
import AddMonthlyComments from './addMonthlyComments';
import { toastr } from "react-redux-toastr";
import {saveBulkKpiActuals,searchBulkKpiDetails,saveMonthlyComment} from '../../actions/bulkCeaKpiEntryAction';
import * as AppConst from '../../AppConstant';
import "./index.css";
var I18n = require("react-redux-i18n").I18n;

class KpiActualsTable extends React.Component{
    /* eslint-disable */
    constructor(props){
        super(props);
        this.state ={ 
            nmscWiseKpiData:[],   
            isActive:false,
            isEditable:false,
            activeIndex:null,
            modal: false,
            selectedRow:{},
            monthTargetDTOList : [],
            valid: true,
            paramCheck: true,
            toggleComments: false,
            toggleAddComments: false
        }
    }
    componentDidUpdate() {
        let nmscWiseKpiData = this.props.kpiData
        let paramflag = true
        if(nmscWiseKpiData.length!==0 && nmscWiseKpiData[0].ceaKpiEvaluationList.length!==0 && this.state.paramCheck){            
            nmscWiseKpiData[0].ceaKpiEvaluationList.map(obj=>{
                if(obj.kpiParamDTOList.length!==0 && paramflag){
                    obj.kpiParamDTOList.map(obj1=>{
                        if(obj1.monthTargetDTOList.length===0 && obj1.paramKey !== AppConst.paramKey){
                            toastr.error(I18n.t("kpiEntry.standardMsgKpiEntry"))
                            paramflag = false
                            return;
                        }
                    })
                }
            })     
            this.setState({paramCheck :false});       
        }
    }      

    handleOnClick = (index)=>{
        this.setState({activeIndex :index});
    }   

    actualsFormatter =(cell,row)=>{
        return (
            <div></div>
        );
    }

    onChangeTextBox = (e, row, cell) => {
        // This is intentional
     };

    commaSeparator =(value)=>{
        var numarray = value.split(".");
        var value = numarray[0];    
        var decimal = numarray[1];
        var firstSeparatedValue = Intl.NumberFormat('en-US').format(value);
        if(decimal !==" " && decimal !==undefined){
            var decimalSeparatedValue = !isNaN(decimal)?decimal:" ";
            var final = decimalSeparatedValue?firstSeparatedValue + "." + decimalSeparatedValue:firstSeparatedValue;
        }else{
            var final = firstSeparatedValue;
        }
        return final;
    }
    
     onBlurTextBox = (e, row, cell) => {    
        let val = e.target.value.trim();
        console.log("val",val);
        val=val.replace(/\,/g,'');
        var Number =/^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            Number = /^[+-]?((\d+(\.\d*)?)|(\.\d+))$/;
        }
        if (Number.test(val) || val === "" ) {    
            e.target.value = val;
            console.log("val",cell.target,val);
            if(cell.target===val){
                return
            }
            cell.target = val;                    
            let selectedObj = {
                dealerMonthlyTargetId: cell.dealerMonthlyTargetId,
                target: val,
                monthName: cell.monthName,
                kpiEvaluationId: row.kpiEvaluationId,
                paramKey: row.paramKey
            } 
            let monthList = this.state.monthTargetDTOList.filter((item) => item.dealerMonthlyTargetId === cell.dealerMonthlyTargetId);  
            if(monthList.length === 0){
                this.state.monthTargetDTOList.push(selectedObj);
            }else{
                this.state.monthTargetDTOList.map(obj=>{
                    if(obj.dealerMonthlyTargetId === cell.dealerMonthlyTargetId){
                        obj.target = val
                    }
                })
            }
            this.props.updateActualsData(this.state.monthTargetDTOList)
        }else{
            toastr.error(I18n.t("kpiEntry.notNumber"))
            e.target.value = cell.target;
        }                          
    };
    
// text box for manual input entry
    inputFormatter = (cell, row, rowIndex) => {
        return (
            <input
            type="text"
            defaultValue={(cell && cell !== undefined && cell.target !== "") ? this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ? this.commaSeparator(cell.target):cell.target : ""}
            onChange={e => this.onChangeTextBox(e, row, cell)}
            onBlur={e => this.onBlurTextBox(e, row, cell)}
            className="form-control"
            disabled={(cell && cell !== undefined && cell.editable) ? false :true}           
            />
        );
    };
      
    seqFormatter =(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.seqNum}</div>
        );
    }
    seqFormatterParam =(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>
                {row.paramKey !== AppConst.paramKey ? row.seqNum : ''}
            </div>
        );
    }

    kpiFormatter =(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>{row.name}</div>
        );
    }

    definitionFormatter =(cell,row)=>{
        console.log("Definition start");
        if (row && row.definition !== null && row.definition !== undefined) {
        console.log("Definition if");    
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>{row.definition}</div>
        );
        }
        else {
            console.log("Definition else");
            return (
                <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>{''}</div>
            );
        }
    }

    frequencyFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.evaluationFrequency}</div>
        );
    }

    typeTargetFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.typeOfTarget}</div>
        );
    }

    targetFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.target}</div>
        );
    }

    unitFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.unit}</div>
        );
    }

    toggleView=(row)=>{
        this.setState({
            modal: !this.state.modal,
            modalFor: 'View',
            selectedRow: {localKpiId:row.kpiId}
        });
    }

    //for kpi view
    popUpClose = (action) => {

        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
    }

    buttonFormatter = (cell, row) => {
        return (
          <div className="btn-toolbar float-left">
            <a onClick={() => this.toggleView(row)}>
              <Fa icon={"eye"} fixed size="lg" title={I18n.t('ceaEvaluation.view')} />
            </a>
          </div>
        );
      }

    createTable =(data,nmscName)=>{
        const columns = [
            {
                dataField: "seqNumber",
                text:I18n.t("kpiEntry.srNo"),
                editable: false,
                style: { width: "70px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "70px", textAlign: "center" },
                formatter: this.seqFormatter,
                title: true
            },
            {
                dataField: "name",
                text: I18n.t('kpiEntry.kpi'),
                editable: false,
                // sort: true,
                style: { width: "250px", textAlign: "left",
                // minWidth:"18rem" 
                },
                headerStyle: { width: "260px", textAlign: "left" },
                 formatter: this.kpiFormatter,
                title: true
            },
            {
                dataField: "",
                text: "",
                editable: false,
                // sort: true,
                style: { width: "39px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "39px", textAlign: "center" },
                formatter: this.buttonFormatter,
                title: true
            },
            {
                dataField: "evaluationFrequency",
                text: I18n.t("kpiEntry.frequency"),
                // editable: true,             
                // sort: true,
                style: { width: "95px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "85px", textAlign: "center" },
                formatter: this.frequencyFormatter,
                title: true
            },
            {
                dataField: "typeOfTarget",
                text: I18n.t("kpiEntry.typeOfTarget"),
                editable: false,
                // sort: true,
                style: { width: "115px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "115px", textAlign: "center" },
                formatter: this.typeTargetFormatter,
                title: true,
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            }, 
            {
                dataField: "target",
                text: I18n.t("kpiEntry.target"),
                editable: false,
                // sort: true,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center" },
                 formatter: this.targetFormatter,
                title: true,
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            },
            {
                dataField: "unit",
                text: I18n.t("kpiEntry.unit"),
                editable: false,
                // sort: true,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center" },
                formatter: this.unitFormatter,
                title: true
            },
            {   
                dataField: "actuals",
                text:I18n.t("kpiEntry.actuals"),
                editable: false,
                // sort: true,
                style: { width: "500px", textAlign: "left" },
                headerStyle: { width: "485px", textAlign: "left" },
                formatter: this.actualsFormatter,
                title: true
            },
            {
                dataField: "actuals1",
                text:"",
                editable: false,            
                title: true
            }          
        ];        
        const expandRow = {
            renderer: (row, rowIndex) => (
                this.createParamTable(row)
            ),            
            showExpandColumn:true,
            // expandByColumnOnly : true,
            expanded: this.props.kpiData[0].kpiEvaluationIdsList
        }
        return(
            <div key={nmscName}
                className={this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? "parentParamTableKpi col-md-12 row control-label noPadding" : "kpiParamHeight parentParamTableKpi col-md-12 row control-label noPadding"}>
                <BootstrapTable
                    keyField="kpiEvaluationId"
                    noDataIndication={I18n.t('dashboard.nodata')}
                    data={data}
                    columns={columns}
                    rowStyle={{ overflow: 'auto' }}
                    expandRow={expandRow}
                />
            </div>
        );
    }

    pramaKeyValueFormatter =(cell,row)=>{
        let activeProgram = "";
        activeProgram = this.props.user.userMdl.activeProgram
        if(activeProgram != "UC") {
        return row.paramKey !== AppConst.paramKey ?
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>
                {/* {row.paramKey + " = " + row.paramValue} */}
                {row.definition}
            </div> :
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal", fontWeight: "bold" }}>
                {I18n.t('ceaActionPlan.total')}
            </div>
        }
        else {
            return row.paramKey !== AppConst.paramKey ?
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>
                {row.paramKey + " = " + row.paramValue}
            </div> :
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal", fontWeight: "bold" }}>
                {I18n.t('ceaActionPlan.total')}
            </div>
        }
    }

    totalFormatter =(cell,row)=>{
        let total = 0;
        row.monthTargetDTOList.filter((item) => {
            if(item.target!=='' && item.target!==""){
                total = parseInt(item.target) + total
            }
        });  
        return (
            <input
            type="text"
            defaultValue={total}
            className="form-control"
            disabled={true}           
            />
        );
    }

    createParamTable = (data)=>{
        const columns = [            
            {
                dataField: "seqNum",
                text:"",
                editable: false,
                style: { width: "70px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "70px", textAlign: "center",minWidth:"3rem" },
                formatter: this.seqFormatterParam,
            },
            {
                dataField: "paramKey",
                text:I18n.t("kpiEntry.inputTxt"),
                style: { width: "250px", textAlign: "left"},
                headerStyle: { width: "250px", textAlign: "left"},
                editable: false,
                formatter: this.pramaKeyValueFormatter,
            },
            {
                dataField: "empty1",
                text: "",
                editable: false,
                style: { width: "39px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "39px", textAlign: "center",minWidth:"3rem" },
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            },
            {
                dataField: "empty2",
                text: "",
                editable: false,
                style: { width: "95px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "95px", textAlign: "center",minWidth:"5rem" },
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            },
            {
                dataField: "empty3",
                text:"",
                editable: false,
                style: { width: "115px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "115px", textAlign: "center",minWidth:"5rem" }
            },
            {
                dataField: "empty4",
                text:"",
                editable: false,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center",minWidth:"5rem" }
            },
            {
                dataField: "",
                text:"",
                editable: false,
                style: { width: this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ?"25px":"71px", textAlign: "center",
                // minWidth:"5rem" 
                },
                headerStyle: { width: this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ?"35px":"75px", textAlign: "center",
                // minWidth:"5rem"
                },                
            }                       
        ];
        let obj = { };
        let length = 1;
        let actualsList = ['CY']
        if(data.evaluationFrequency === "Monthly"){
            length = 12;            
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)
        }
        if(data.evaluationFrequency === "Quarterly"){
            length = 4;  
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)
        }        
        if(data.evaluationFrequency === "Half Yearly"){          
            length = 2; 
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)          
        }
        for (var i = 0; i < length; i++) {
            obj = {
                dataField: "monthTargetDTOList["+i+"]",
                text: actualsList[i],                
                editable:true,
                style: { 
                    width: "190px", 
                    textAlign: "left" },
                formatter: this.inputFormatter,
                headerStyle: { width: "190px", textAlign: "left" }
            };               
            columns.push(obj)
        }                
        let col6 = {
            text:"",
            dataField: "empty6",
            editable: false,
            style: { 
                width: "120px", 
                textAlign: "left" },
                headerStyle: { width: "120px", textAlign: "left" }
            } 
        let col7 = {
                text:"",
                dataField: "empty7",
                editable: false,
                style: { 
                    width: "120px", 
                    textAlign: "left" },
                    headerStyle: { width: "120px", textAlign: "left" }
                }     
        let col8 = {
            dataField: "empty8",
            text: I18n.t('ceaActionPlan.cytotal'),
            editable: false,
            style: {
                width: "120px",
                textAlign: "left"
            },
            formatter: this.totalFormatter,
            headerStyle: { width: "120px", textAlign: "left" }
        }
        if(data.evaluationFrequency === "Monthly" && this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            columns.push(col8)
        }            
        if(data.evaluationFrequency !== "Quarterly"){
            columns.push(col6)
            columns.push(col7)
            if(data.evaluationFrequency === "Yearly"){
                columns.push(col6)
            }
        }
        const expandRow = {
            showExpandColumn:false          
        }
        return(
            <div key={data.kpiEvaluationId} className="childParamTableKpi col-md-12 row control-label noPadding">                                 
                <BootstrapTable
                    keyField="paramId"
                    noDataIndication={I18n.t('dashboard.nodata')}
                    data={data.kpiParamDTOList}
                    columns={columns}
                    cellEdit={cellEditFactory({
                        beforeSaveCell(oldValue, newValue, row, column, done) {                                                
                            return { async: true };
                        }
                    })}
                    expandRow={ expandRow }
                />
            </div>
        );        
    }

    monthFormatterBasedOnFrequency = (frequency)=>{
        switch(frequency){
            case 'Monthly':
                let allMonthList = [I18n.t('kpiEntry.Jan'), I18n.t('kpiEntry.Feb'), I18n.t('kpiEntry.Mar'), I18n.t('kpiEntry.Apr'), I18n.t('kpiEntry.May'), I18n.t('kpiEntry.Jun'), I18n.t('kpiEntry.Jul'), I18n.t('kpiEntry.Aug'), I18n.t('kpiEntry.Sep'), I18n.t('kpiEntry.Oct'), I18n.t('kpiEntry.Nov'), I18n.t('kpiEntry.Dec')];
                return allMonthList;
            case 'Quarterly' :
                let quaterList = [I18n.t('kpiEntry.q1'),I18n.t('kpiEntry.q2'),I18n.t('kpiEntry.q3'),I18n.t('kpiEntry.q4')]
                return quaterList
            case 'Half Yearly' :
                let halfYearList = [I18n.t('kpiEntry.h1'),I18n.t('kpiEntry.h2')]
                return halfYearList
            case 'Yearly' :
                let yearList = [I18n.t('kpiEntry.cy')]
                return  yearList
            default:
                return []
        }
    }
    
    onclickCancel(){        
        this.props.dispatch(searchBulkKpiDetails(this.props.actualSearchDto,"KpiActuals"))        
        this.setState({monthTargetDTOList:[]})
        this.props.updateActualsData([])  
    }
    onclickCommentHistory(){  
        this.setState({toggleComments:true})
    }
    onclickSave(){
        console.log('decimal check',this.state.monthTargetDTOList.length);
        if(this.state.monthTargetDTOList.length===0){
            toastr.error(I18n.t("kpiEntry.enterActualsData"))
            return
        }else if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            this.setState({toggleAddComments:true})
        }else{
            this.onclickSaveActuals()
        }
    }
    onclickSaveActuals(){
        const d = new Date();
        let month = d.getMonth();
        let monthWiseStatusUpdate = false;
        let monthlyTargetList = this.state.monthTargetDTOList;
        if (monthlyTargetList.length && this.props.user.userMdl.activeProgram === AppConst.activeProgramUc) {
            let targetTotal = 0;
            let total = 0;
            let count = 0;
            let newObj = {}
            monthlyTargetList.map((obj) => {
                this.props.kpiData[0].ceaKpiEvaluationList.filter((item) => {
                    targetTotal = 0;
                    total = 0;
                    count = 0;
                    if (obj.kpiEvaluationId === item.kpiEvaluationId) {
                        item.kpiParamDTOList.map((key) => {
                            if (key.paramKey === AppConst.paramKey) {                                
                                /* update monthwise total */
                                total = targetTotal;
                                if(targetTotal===0 && key.monthTargetDTOList[obj.monthName - 1].target!== "" && key.monthTargetDTOList[obj.monthName - 1].target!== 0){
                                    total = key.monthTargetDTOList[obj.monthName - 1].target;
                                }
                                if((count===(item.kpiParamDTOList.length-1)) && key.monthTargetDTOList[obj.monthName - 1].target===""){
                                    total = ""
                                }
                                key.monthTargetDTOList[obj.monthName - 1].target = total;
                                /* Create new object for total */
                                newObj = {
                                    dealerMonthlyTargetId: key.monthTargetDTOList[obj.monthName - 1].dealerMonthlyTargetId,
                                    target: total,
                                    monthName: key.monthTargetDTOList[obj.monthName - 1].monthName,
                                    kpiEvaluationId: key.kpiEvaluationId,
                                    paramKey: key.paramKey
                                }
                                monthlyTargetList.push(newObj)
                            } else {
                                /* Addition of monthly params*/
                                if(key.monthTargetDTOList[obj.monthName - 1].target !== ""){
                                    targetTotal = parseInt(targetTotal) + parseInt(key.monthTargetDTOList[obj.monthName - 1].target)
                                }else{
                                    count = count+1
                                }
                            }
                            /* Start - Set flag for update kpi actual status ongoing to completed for Used cars*/
                            if(this.props.kpiData[0].kpiEvaluationIdsList[0] === item.kpiEvaluationId){
                                if(obj.monthName === month+1){
                                    monthWiseStatusUpdate = total!=="" ? true : false;               
                                }
                            }
                            /* End - Set flag for update kpi actual status ongoing to completed for Used cars */
                        })
                    }
                });
            })
        }

        let saveKpiBulkActualsDTO = {
            userId :  this.props.user.userMdl.username,
            activityId : this.props.activityId,
            monthTargetDTOList : monthlyTargetList
        }

        this.props.dispatch(saveBulkKpiActuals(saveKpiBulkActualsDTO,this.props.actualSearchDto,'KpiActuals',monthWiseStatusUpdate))
        this.setState({monthTargetDTOList:[]})
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            this.props.statusUpdate(monthWiseStatusUpdate ? I18n.t("usedCarComment.completed") : I18n.t("usedCarComment.ongoing"))
        }
        this.props.updateActualsData([])
    }
    toggleCommentsCancel = ()=>{
        this.setState({ toggleComments: false, toggleAddComments:false});
    }
    submitComment = (comment) => {
        const d = new Date();
        this.setState({toggleAddComments:false});
        let currentYear = new Date();
        currentYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : currentYear.getfullYear();
        let saveCommentDTO = {
            userId :  this.props.user.userMdl.username,
            kpiMonth : d.getMonth(),
            comment :comment,
            activityId : this.props.activityId,
            kpiYear : currentYear
        }
        this.props.dispatch(saveMonthlyComment(saveCommentDTO));
        this.onclickSaveActuals();
    }
    render(){
        let nmscWiseKpiData = this.props.kpiData
        if(nmscWiseKpiData.length!==0){
        return (
            <div >
                <div
                    className={this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? "col-md-12 kpiActualsAccordian" : "col-md-12 kpiActualsAccordian kpiAccordianHeight"}
                    style={{ padding: "0px", marginTop: "8px" }}>
                    {this.createTable(nmscWiseKpiData[0].ceaKpiEvaluationList, nmscWiseKpiData[0].name)}
                </div>
                <div className="col-md-12 input-group control-label">
                    <div className="col-md-12" style={{ padding: '0px',marginTop:'5px' }}>
                        {this.props.isEditable ?
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={(e)=>{this.onclickSave()}}
                                style = {{float : 'right' }} 
                                >
                                {I18n.t("kpiEntry.save")}
                            </button>
                            :""}
                        <button
                            className={"buttonCancel-" + this.props.theme.name}
                            onClick={(e)=>{this.onclickCancel()}}
                            style = {{float : 'right' }} 
                            >
                            {I18n.t("kpiEntry.cancel")}
                        </button>
                        {this.props.user.userMdl.activeProgram === AppConst.activeProgramUc ?
                        <button
                            className={"button-" + this.props.theme.name}
                            onClick={(e)=>{this.onclickCommentHistory()}}
                            style = {{float : 'left' }} 
                            >
                            {I18n.t("usedCarComment.commentHistory")}
                        </button>:''}
                    </div>
                </div>
            {/* kpi view--start */}
                {this.state.modal === true ?
                    <CeaNetKpiModal
                        modal={this.state.modal}
                        toggle={this.popUpClose}
                        modalFor={this.state.modalFor}
                        selectedRow={this.state.selectedRow}
                    /> : null}
            {/* kpi view--end */}
            {this.state.toggleComments === true ?
            <HistoryMonthlyComments
                activityId={this.props.activityId}
                toggleCommentsCancel={this.toggleCommentsCancel} 
            /> : null}
            {this.state.toggleAddComments === true ?
            <AddMonthlyComments 
                submitComment={this.submitComment}
                activityId={this.props.activityId}
                toggleCommentsCancel={this.toggleCommentsCancel} 
            /> : null}
            </div>   
        );
        }
        else{
            return (
                <div>
                </div>
            )
        }
  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        kpiEntryDeatils: state.ceaKpiEntryReducer.kpiEntryDeatils
    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(KpiActualsTable)));