import { Fa, MDBCol, MDBRow } from 'mdbreact';
import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchActivityStatus, fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import { withTheme } from "../../../themes/theming";
import Slider from "react-slick";
import Arrow from '../arrow';
import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class Activities extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: [{value: "ONGOING", label: "ongoing"},
            {value: "PLANNED", label: "planned"},
            {value: "CLOSED", label: "closed"},
            {value: "COMPLETED", label: "completed"},
            {value: "REJECTED", label: "rejected"}],
            data: {},
            leftArrow:"widget_filter_arrow_disable",
            rightArrow:"widget_filter_arrow"
        };
    }
    componentWillMount() {
        // Fetch all available toolboxes.
        this.props.dispatch(fetchActivityStatus());
        // Fetch AverageDaysPerToolbox.
        this.fetchData("ONGOING");
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.activityStatus
            && this.state.status.length !== nextProps.dashboardReducer.activityStatus.length) {
                // console.log(nextProps.dashboardReducer.activityStatus)
            //this.setState({ status: nextProps.dashboardReducer.activityStatus });
        }
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.activities) {
            // console.log(nextProps.dashboardReducer.activities)
            this.setState({ data: nextProps.dashboardReducer.activities, isLoading: false });
        }
    }
    fetchData = (status) => {
        this.setState({ isLoading: true });
        let activeProgram = this.props.user.userMdl.activeProgram;
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            widgetFilter: status,
            userId:this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("activities", "WIDGET_ACTIVITIES", queryDto));
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                // alert(JSON.stringify(this.state.availableToolboxes[next]));
               
                let status = this.state.status[next];
                if (status) {
                    this.fetchData(status.value);
                }

                if(next===0){
                    this.setState({leftArrow:"widget_filter_arrow_disable",rightArrow:"widget_filter_arrow"})
                }
                else if(next===(this.state.status.length-1)){
                    this.setState({rightArrow:"widget_filter_arrow_disable",leftArrow:"widget_filter_arrow"})
                }else{
                    this.setState({rightArrow:"widget_filter_arrow",leftArrow:"widget_filter_arrow"})
 
                }
            },
            // prevArrow: <Arrow icon="arrow-circle-left" />,
            // nextArrow: <Arrow icon="arrow-circle-right" parentClass="nextBtnClass" />
            prevArrow: <Arrow icon="chevron-left" parentClass="previousBtnClass" BtnClass={this.state.leftArrow}/>,
            nextArrow: <Arrow icon="chevron-right" parentClass="nextBtnClass" BtnClass={this.state.rightArrow}/>
        };
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('TopNav.ActivityLog')}>{I18n.t('TopNav.ActivityLog')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{ minHeight: "3.2rem" , paddingLeft:"3px"}}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {this.state.data.value}
                                    {this.state.data.arrowDirection ?
                                        <Fa icon={this.state.data.arrowDirection} style={{ color: this.state.data.arrowColor }}
                                            className="widget_arrow_class" size="lg" />
                                        : <div></div>}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget-header_sub " style={{paddingLeft:"8px"}}>
                            {I18n.t("Table.status")}
                           </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget_slider_class">
                                <Slider {...settings}>
                                    {this.state.status.map((t, i) => {
                                        return (<div key={i}>
                                            <span className="widget_filter" title={I18n.t('status.'+t.label.toLowerCase())} style={{paddingRight:"3px"}}>{I18n.t('status.'+t.label.toLowerCase())}</span>
                                        </div>);
                                    })}
                                </Slider>
                           </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        dashboardReducer: state.dashboardReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Activities)));