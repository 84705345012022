import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from "react-select";
import { MDBIcon, MDBCard, MDBCardHeader, MDBCardBody } from "mdbreact";
import { Accordion } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import ParamTable from "./components/paramTable";
import FormulaTable from "./components/formulaTable";
import {
  fetchSubpillar,
  fetchCeaDropdowns,
  saveCeaNetKPI,
  fetchCeaNetKPI,
  fetchPublishedLexusKpi,
} from "../../actions/ceaNetKPIAction.jsx";
import "./index.css";
import * as AppConst from "../../AppConstant";
var I18n = require("react-redux-i18n").I18n;

class CeaNetKpiModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      brandToyota:
        this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer
          ? false
          : true,
      brandLexus:
        this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer
          ? false
          : true,
      selectedRow: props.selectedRow,
      paramDto: [],
      formulaDto: [],
      selectedItems: {
        evaluationFrequency: { value: "M", label: "Monthly" },
      },
    };
  }

  saveKpi = (e) => {
    e.preventDefault();

    let kpiDTO = { ...this.state.selectedRow };
    if (this.props.user.userMdl.activeProgram === AppConst.activeProgramUc) {
      kpiDTO["evaluationFrequency"] = "M";
    }
    //check all mandatory fields
    let isfilled = true;
    if (!this.state.brandToyota && !this.state.brandLexus) {
      isfilled = false;
    }

    if (
      this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer
    ) {
      if (
        !this.isNotEmpty(this.state.selectedRow.name) ||
        (this.props.user.userMdl.activeProgram === AppConst.activeProgramNet &&
          !this.isNotEmpty(this.state.selectedRow.definition)) ||
        !this.isNotEmpty(kpiDTO.evaluationFrequency)
      ) {
        isfilled = false;
      }
    } else {
      if (
        (!this.isNotEmpty(this.state.selectedRow.subPillar) &&
          this.props.ceaSubpillar.length !== 0) ||
        !this.isNotEmpty(this.state.selectedRow.name) ||
        !this.isNotEmpty(this.state.selectedRow.definition) ||
        !this.isNotEmpty(this.state.selectedRow.targetType) ||
        !this.isNotEmpty(this.state.selectedRow.unit) ||
        !this.isNotEmpty(this.state.selectedRow.scoringMethod) ||
        !this.isNotEmpty(this.state.selectedRow.mode) ||
        !this.isNotEmpty(kpiDTO.evaluationFrequency) ||
        (this.state.selectedRow.targetType === "STANDARD" &&
          !this.isNotEmpty(this.state.selectedRow.target)) ||
        !this.isNotEmpty(this.state.selectedRow.points)
        // && !this.isNotEmpty(this.state.selectedRow.mode) && !this.isNotEmpty(this.state.selectedRow.evaluationFrequency)
      ) {
        isfilled = false;
      }
    }

    if (kpiDTO.evaluationFrequency !== "NN") {
      if (this.state.paramDto && this.state.paramDto.length > 0) {
        this.state.paramDto.forEach((row) => {
          if (
            !this.isNotEmpty(row.paramKey) ||
            !this.isNotEmpty(row.paramValue)
          ) {
            isfilled = false;
          }
        });
      } else {
        isfilled = false;
      }
    }
    //formula
    // if (kpiDTO.evaluationFrequency !== "NN") {
    //   if (this.state.formulaDto && this.state.formulaDto.length > 0) {
    //     this.state.formulaDto.forEach((row) => {
    //       if (
    //         !this.isNotEmpty(row.kpiKey) ||
    //         !this.isNotEmpty(row.operatorValue)
    //       ) {
    //         isfilled = false;
    //       }
    //     });
    //   } else {
    //     isfilled = false;
    //   }
    // }
    if (isfilled) {
      if (kpiDTO.evaluationFrequency !== "NN") {
        let paramDtoList = [...this.state.paramDto];
        kpiDTO["paramDtoList"] = paramDtoList;
      }
      // formula parameter
      if (this.props.user.userMdl.activeProgram === AppConst.activeProgramLN) {
        if (kpiDTO.evaluationFrequency !== "NN") {
          let formulaDtoList = [...this.state.formulaDto];
          console.log(formulaDtoList);
          kpiDTO["formulaDtoList"] = formulaDtoList;
        }
      }

      let brand = "";
      if (this.state.brandToyota && !this.state.brandLexus) {
        brand = AppConst.BRAND_TOYOTA;
      }
      if (this.state.brandLexus && !this.state.brandToyota) {
        brand = AppConst.BRAND_LEXUS;
      }

      if (this.state.brandLexus && this.state.brandToyota) {
        brand = AppConst.BRAND_TOYOTA + "," + AppConst.BRAND_LEXUS;
      }

      kpiDTO["brand"] = brand;
      kpiDTO["createdBy"] = this.props.user.userMdl.username;
      kpiDTO["updatedBy"] = this.props.user.userMdl.username;
      kpiDTO["isLexus"] =
        this.props.user.userMdl.activeProgram === AppConst.activeProgramLN
          ? true
          : false;

      let userDetail = {
        username: this.props.user.userMdl.username,
        nmsc: this.props.user.userMdl.nmsc,
        roles: this.props.user.userMdl.roles,
      };
      /* Start - add total parameter for actual update screen*/
      let lastObj = kpiDTO["paramDtoList"].filter(
        (item) => item.paramKey === AppConst.paramKey
      );
      if (lastObj.length) {
        kpiDTO["paramDtoList"].pop(lastObj);
      }
      if (
        kpiDTO["paramDtoList"].length &&
        this.props.user.userMdl.activeProgram === AppConst.activeProgramUc
      ) {
        let totalObj = {
          id: kpiDTO["paramDtoList"].length + 1,
          paramId: 0,
          dataEntryKet: true,
          paramKey: "total",
          paramValue: "Total",
        };
        kpiDTO["paramDtoList"].push(totalObj);
      }
      /* End - add total parameter for actual update screen*/

      /* formula Start - add total parameter for actual update screen*/
      if( this.props.user.userMdl.activeProgram ===
        AppConst.activeProgramLN){
      let formulaObj = kpiDTO["formulaDtoList"].filter(
        (item) => item.kpiKey === AppConst.kpiKey
      );
      console.log(formulaObj);
      if (formulaObj.length) {
        kpiDTO["formulaDtoList"].pop(formulaObj);
      }
      if (
        kpiDTO["formulaDtoList"].length &&
        this.props.user.userMdl.activeProgram === AppConst.activeProgramLN
      ) {
      }
    }
      /* End - formula add total parameter for actual update screen*/
      this.props.dispatch(
        saveCeaNetKPI(
          kpiDTO,
          this.props.user.userMdl.businessAreaKey,
          userDetail,
          this
        )
      );
    } else {
      toastr.error(I18n.t("toastr.modalMandatory"));
    }
  };

  isNotEmpty = (value) => {
    if (undefined === value || null === value) {
      return false;
    } else if ((value + "").trim() === "") {
      return false;
    }
    return true;
  };

  onfieldChange = (event, action) => {
    // console.log(event, action)
    let selectedRow = { ...this.state.selectedRow };
    let selectedItems = { ...this.state.selectedItems };

    if (this.isNotEmpty(event)) {
      if (action === "subPillar") {
        selectedRow["subPillar"] = event.value;
        selectedItems["subPillar"] = event;
      }
      if (action === "name") {
        selectedRow["name"] = event.target.value;
      }
      if (action === "definition") {
        selectedRow["definition"] = event.target.value;
      }
      if (action === "additionalInfo") {
        selectedRow["additionalInfo"] = event.target.value;
      }

      if (action === "targetType") {
        selectedRow["targetType"] = event.value;
        selectedItems["targetType"] = event;

        if (event.value !== "STANDARD") {
          selectedRow["target"] = "";
        }
      }
      if (action === "target") {
        var Number = /^[\d\/\(\)\- \.]+$/; // eslint-disable-line
        var numText = event.target.value;
        if (Number.test(numText) || numText === "") {
          if (!isNaN(numText) && Math.sign(numText) !== -1) {
            selectedRow["target"] = numText;
          } else {
            toastr.error(I18n.t("CeaKpi.targetIncorrect"));
          }
        } else {
          return;
        }

        // selectedRow['target'] = event.target.value
      }
      if (action === "unit") {
        selectedRow["unit"] = event.value;
        selectedItems["unit"] = event;
      }

      if (action === "evaluationMethod") {
        selectedRow["evaluationMethod"] = event.target.value;
      }
      if (action === "scoringMethod") {
        selectedRow["scoringMethod"] = event.target.value;
      }
      if (action === "mode") {
        selectedRow["mode"] = event.value;
        selectedItems["mode"] = event;
        if (event.value === "PTS_ALLOC" || event.value === "SURVEY") {
          selectedRow["evaluationFrequency"] = "Y";
          selectedItems["evaluationFrequency"] = {
            value: "Y",
            label: "Yearly",
          };
        }
      }
      if (action === "points") {
        // let regexp = /^[0-9\b]+$/
        // if (regexp.test(event.target.value) || event.target.value == "") {
        selectedRow["points"] = event.target.value;
        // }else{
        //     return;
        // }
      }
      if (action === "evaluationFrequency") {
        // console.log("evalution value data",event.value,event);
        selectedRow["evaluationFrequency"] = event.value;
        selectedItems["evaluationFrequency"] = event;
        if( this.props.user.userMdl.activeProgram ===
          AppConst.activeProgramLN){
            this.props.dispatch(
              fetchPublishedLexusKpi(
                event.value,
                this.props.user.userMdl.ceaDefaultYears.value
              )
            );
          }
        
      }
    } else {
      selectedRow[action] = "";
    }

    this.setState({ selectedRow, selectedItems });
  };

  onChangeBrand = (event, action) => {
    if (action === "brandToyota") {
      this.setState({ brandToyota: !this.state.brandToyota });
    } else {
      this.setState({ brandLexus: !this.state.brandLexus });
    }
  };
  componentWillMount() {
    this.props.dispatch(
      fetchCeaDropdowns(this.props.user.userMdl.activeProgram)
    );
    this.props.dispatch(
      fetchSubpillar(this.props.user.userMdl.activeProgramID)
    );
   
  
  }
  componentDidMount() {
    if (this.props.modalFor === "View" || this.props.modalFor === "Edit") {
      this.props.dispatch(fetchCeaNetKPI(this.props.selectedRow.localKpiId));
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.getKpiDetails !== this.props.getKpiDetails) {
      let selectedItems = { ...this.state.selectedItems };

      if (this.props.getKpiDetails.brand === AppConst.BRAND_TOYOTA) {
        this.setState({ brandToyota: true, brandLexus: false });
      }
      if (this.props.getKpiDetails.brand === AppConst.BRAND_LEXUS) {
        this.setState({ brandToyota: false, brandLexus: true });
      }
      if (
        this.props.getKpiDetails.brand ===
        AppConst.BRAND_TOYOTA + "," + AppConst.BRAND_LEXUS
      ) {
        this.setState({ brandToyota: true, brandLexus: true });
      }

      if (this.props.ceaSubpillar && this.props.ceaSubpillar.length > 0) {
        let option = this.props.ceaSubpillar.filter(
          (obj) => obj.value === this.props.getKpiDetails.subPillar
        );

        selectedItems["subPillar"] = option[0];
      }
      if (this.props.ceaDropdowns) {
        if (
          this.props.ceaDropdowns.ceaKpiTargetTypeList &&
          this.props.ceaDropdowns.ceaKpiTargetTypeList.length > 0
        ) {
          let option1 = this.props.ceaDropdowns.ceaKpiTargetTypeList.filter(
            (obj) => obj.value === this.props.getKpiDetails.targetType
          );
          selectedItems["targetType"] = option1[0];
        }

        if (
          this.props.ceaDropdowns.ceaKpiModeList &&
          this.props.ceaDropdowns.ceaKpiModeList.length > 0
        ) {
          let option2 = this.props.ceaDropdowns.ceaKpiModeList.filter(
            (obj) => obj.value === this.props.getKpiDetails.mode
          );
          selectedItems["mode"] = option2[0];
        }

        if (
          this.props.ceaDropdowns.ceaKpiUnitList &&
          this.props.ceaDropdowns.ceaKpiUnitList.length > 0
        ) {
          let option3 = this.props.ceaDropdowns.ceaKpiUnitList.filter(
            (obj) => obj.value === this.props.getKpiDetails.unit
          );
          selectedItems["unit"] = option3[0];
        }

        if (
          this.props.ceaDropdowns.inputFrequencyList &&
          this.props.ceaDropdowns.inputFrequencyList.length > 0
        ) {
          let option4 = this.props.ceaDropdowns.inputFrequencyList.filter(
            (obj) => obj.value === this.props.getKpiDetails.evaluationFrequency
          );
          selectedItems["evaluationFrequency"] = option4[0];
          if ((this.props.modalFor === "View" || this.props.modalFor === "Edit") && this.props.user.userMdl.activeProgram ===
          AppConst.activeProgramLN) {
            console.log(this.props.getKpiDetails.evaluationFrequency);
            this.props.dispatch(fetchPublishedLexusKpi(this.props.getKpiDetails.evaluationFrequency, this.props.user.userMdl.ceaDefaultYears.value
              )
            );
          }
        }
      }

      this.setState({ selectedRow: this.props.getKpiDetails, selectedItems });
    }
  }

  paramTableData = (data) => {
    // console.log(data)
    // console.log(this.props.modalFor);

    this.setState({ paramDto: data });
    //this.setState({ formulaDto: data });
  };

  formulaTableData = (data) => {
    // console.log(data)
    this.setState({ formulaDto: data });
  };
  preventDefault() {}
  render() {
    // console.log(this.props.selectedRow)
    // console.log(this.state.selectedRow)
    // console.log(this.props.getKpiDetails)
    // console.log(this.state.selectedItems)
    console.log(this.state.selectedRow.evaluationFrequency);
    return (
      <Modal
        className={"ceaKpiModal ceaKpiModal-" + this.props.theme.name}
        isOpen="true"
        toggle={() => this.preventDefault()}
      >
        <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>
          {this.props.modalFor === "Create"
            ? I18n.t("tmeAdminMaster.createKPI")
            : this.props.modalFor === "View"
            ? I18n.t("CeaKpi.viewKpi")
            : I18n.t("tmeAdminMaster.editKPI")}
        </ModalHeader>
        <ModalBody>
          <Accordion>
            <MDBCard className="assignCard">
              <Accordion.Toggle
                as={MDBCardHeader}
                eventKey="0"
                //onClick={() => this.handleOnClick}
              >
                <label
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                >
                  <MDBIcon fa="true" icon="chevron-down" />
                </label>
                <label
                  md="0.2"
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                  style={{ fontSize: "15px", textTransform: "capitalize" }}
                >
                  {I18n.t("CeaKpi.general")}
                </label>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <MDBCardBody className="cardPadding">
                  <div>
                    <div>
                      <label className="col-md-6 control-label">
                        <input
                          type="checkbox"
                          id="brandToyota"
                          checked={this.state.brandToyota}
                          onChange={(e) => this.onChangeBrand(e, "brandToyota")}
                          disabled={
                            this.props.modalFor === "View" ||
                            this.props.user.userMdl.roles.includes(
                              "KET-LEXUS Admin"
                            )
                              ? true
                              : false
                          }
                        />{" "}
                        {I18n.t("tmeAdminMaster.toyota")}
                      </label>
                      <label className="col-md-6 control-label">
                        <input
                          type="checkbox"
                          id="brandLexus"
                          checked={
                            this.props.user.userMdl.activeProgram ===
                            AppConst.activeProgramNet
                              ? false
                              : this.state.brandLexus
                          }
                          onChange={(e) => this.onChangeBrand(e, "brandLexus")}
                          disabled={
                            this.props.user.userMdl.activeProgram ===
                            AppConst.activeProgramNet
                              ? true
                              : this.props.modalFor === "View"
                              ? true
                              : false
                          }
                        />{" "}
                        {I18n.t("tmeAdminMaster.lexus")}
                      </label>
                    </div>

                    <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.subPillar")}
                      {this.props.ceaSubpillar.length !== 0 ? (
                        <span className="redFont"> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <div className="col-md-12">
                      <Select
                        placeholder={I18n.t("others.select")}
                        onChange={(e) => this.onfieldChange(e, "subPillar")}
                        options={this.props.ceaSubpillar}
                        value={this.state.selectedItems.subPillar}
                        isClearable={true}
                        className="ceakpi_modal_unit"
                        isDisabled={
                          this.props.user.userMdl.businessAreaKey ===
                          AppConst.businessAreaKeyPer
                            ? true
                            : this.props.ceaSubpillar.length === 0 ||
                              this.props.modalFor === "View"
                            ? true
                            : false
                        }
                      />
                    </div>
                    <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.evaluationItem")}
                      <span className="redFont"> *</span>
                    </label>
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="form-control"
                        maxLength="70"
                        autoFocus
                        value={this.state.selectedRow.name}
                        onChange={(e) => this.onfieldChange(e, "name")}
                        disabled={this.props.modalFor === "View" ? true : false}
                      />
                    </div>

                    <br />
                    <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.kpiMetric")}
                      {this.props.user.userMdl.activeProgram ===
                      AppConst.activeProgramNet ? (
                        <span className="redFont"> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        maxLength="800"
                        rows="3"
                        onChange={(e) =>
                          this.props.modalFor !== "View"
                            ? this.onfieldChange(e, "definition")
                            : ""
                        }
                        value={this.state.selectedRow.definition}
                        readOnly={this.props.modalFor === "View" ? true : false}
                      />
                    </div>

                    <label className="col-md-12 control-label">
                      {this.props.user.userMdl.businessAreaKey ===
                      AppConst.businessAreaKeyPer
                        ? I18n.t("CeaKpi.kaizenImp_impact_def1")
                        : I18n.t("CeaKpi.kaizenImp_impact_def")}
                    </label>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        maxLength="500"
                        rows="3"
                        onChange={(e) =>
                          this.props.modalFor !== "View"
                            ? this.onfieldChange(e, "additionalInfo")
                            : ""
                        }
                        value={this.state.selectedRow.additionalInfo}
                        readOnly={this.props.modalFor === "View" ? true : false}
                      />
                    </div>
                  </div>
                </MDBCardBody>
              </Accordion.Collapse>
            </MDBCard>
            <MDBCard className="assignCard">
              <Accordion.Toggle
                as={MDBCardHeader}
                eventKey="1"
                //onClick={() => this.handleOnClick}
              >
                <label
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                >
                  <MDBIcon fa="true" icon="chevron-down" />
                </label>
                <label
                  md="0.2"
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                  style={{ fontSize: "15px", textTransform: "capitalize" }}
                >
                  {I18n.t("CeaKpi.targetScoring")}
                </label>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <MDBCardBody className="cardPadding">
                  <div>
                    {this.props.user.userMdl.businessAreaKey !==
                    AppConst.businessAreaKeyPer ? (
                      <div className="col-md-12 row control-label">
                        <label
                          className="col-md-4 control-label"
                          style={{ paddingLeft: "0.3rem" }}
                        >
                          {I18n.t("CeaKpi.typeOftarget")}
                          {this.props.user.userMdl.businessAreaKey !==
                          AppConst.businessAreaKeyPer ? (
                            <span className="redFont"> *</span>
                          ) : (
                            ""
                          )}
                        </label>

                        {this.state.selectedRow.targetType !== "STANDARD" ? (
                          <div col-md-4></div>
                        ) : (
                          <label
                            className="col-md-4 control-label"
                            style={{ paddingLeft: "0.3rem" }}
                          >
                            {I18n.t("CeaKpi.target")}
                            {this.state.selectedRow.targetType ===
                            "STANDARD" ? (
                              <span className="redFont"> *</span>
                            ) : (
                              ""
                            )}
                          </label>
                        )}
                        <label
                          className="col-md-4 control-label"
                          style={{ paddingLeft: "0.5rem", marginLeft: "1rem" }}
                        >
                          {I18n.t("tmeAdminMaster.unit")}
                          <span className="redFont"> *</span>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.props.user.userMdl.businessAreaKey !==
                    AppConst.businessAreaKeyPer ? (
                      <div className="col-md-12 input-group control-label">
                        <Select
                          placeholder={I18n.t("others.select")}
                          // value={this.state.selectedUnit}
                          onChange={(e) => this.onfieldChange(e, "targetType")}
                          options={this.props.ceaDropdowns.ceaKpiTargetTypeList}
                          isClearable={true}
                          className="ceakpi_modal_unit"
                          value={this.state.selectedItems.targetType}
                          isDisabled={
                            this.props.user.userMdl.businessAreaKey ===
                            AppConst.businessAreaKeyPer
                              ? true
                              : this.props.modalFor === "View"
                              ? true
                              : false
                          }
                        />
                        {this.state.selectedRow.targetType !== "STANDARD" ? (
                          <div col-md-5></div>
                        ) : (
                          <input
                            type="text"
                            maxLength="10"
                            className="form-control col-md-5"
                            autoFocus
                            value={this.state.selectedRow.target}
                            onChange={(e) => this.onfieldChange(e, "target")}
                            disabled={
                              this.state.selectedRow.targetType !==
                                "STANDARD" || this.props.modalFor === "View"
                                ? true
                                : false
                            }
                          />
                        )}
                        &nbsp; &nbsp; &nbsp;
                        <Select
                          placeholder={I18n.t("others.select")}
                          // value={this.state.selectedUnit}
                          onChange={(e) => this.onfieldChange(e, "unit")}
                          options={this.props.ceaDropdowns.ceaKpiUnitList}
                          isClearable={true}
                          className="ceakpi_modal_unit"
                          value={this.state.selectedItems.unit}
                          isDisabled={
                            this.props.modalFor === "View" ? true : false
                          }
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.evalMethod")}
                    </label>
                    <div className="col-md-12">
                      <textarea
                        className="form-control"
                        maxLength="800"
                        rows="3"
                        onChange={(e) =>
                          this.props.modalFor !== "View"
                            ? this.onfieldChange(e, "evaluationMethod")
                            : ""
                        }
                        value={this.state.selectedRow.evaluationMethod}
                        readOnly={this.props.modalFor === "View" ? true : false}
                      />
                    </div>
                    <br />
                    <label className="col-md-12 control-label">
                      {I18n.t("CeaKpi.scoreMethod")}
                      {this.props.user.userMdl.businessAreaKey !==
                      AppConst.businessAreaKeyPer ? (
                        <span className="redFont"> *</span>
                      ) : (
                        ""
                      )}
                    </label>
                    <div className="col-md-12">
                      <textarea
                        placeholder={
                          this.props.user.userMdl.businessAreaKey ===
                          AppConst.businessAreaKeyPer
                            ? "-"
                            : ""
                        }
                        className="form-control"
                        maxLength="800"
                        rows="3"
                        onChange={(e) =>
                          this.props.modalFor !== "View"
                            ? this.onfieldChange(e, "scoringMethod")
                            : ""
                        }
                        value={this.state.selectedRow.scoringMethod}
                        readOnly={this.props.modalFor === "View" ? true : false}
                      />
                    </div>

                    <div className="col-md-12 row control-label">
                      <label
                        className="col-md-4 control-label"
                        style={{ paddingLeft: "0.3rem" }}
                      >
                        {I18n.t("CeaKpi.mode")}
                        {this.props.user.userMdl.businessAreaKey !==
                        AppConst.businessAreaKeyPer ? (
                          <span className="redFont"> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                      <label
                        className="col-md-4 control-label"
                        style={{ paddingLeft: "0.3rem", marginLeft: "1rem" }}
                      >
                        {I18n.t("CeaKpi.points")}
                        {this.props.user.userMdl.businessAreaKey !==
                        AppConst.businessAreaKeyPer ? (
                          <span className="redFont"> *</span>
                        ) : (
                          ""
                        )}
                      </label>
                    </div>
                    <div className="col-md-12 input-group control-label">
                      <Select
                        placeholder={I18n.t("others.select")}
                        // value={this.state.selectedUnit}
                        onChange={(e) => this.onfieldChange(e, "mode")}
                        options={this.props.ceaDropdowns.ceaKpiModeList}
                        isClearable={true}
                        className="ceakpi_modal_unit"
                        value={this.state.selectedItems.mode}
                        isDisabled={
                          this.props.user.userMdl.businessAreaKey ===
                          AppConst.businessAreaKeyPer
                            ? true
                            : this.props.modalFor === "View"
                            ? true
                            : false
                        }
                      />
                      &nbsp; &nbsp; &nbsp;
                      <input
                        type="number"
                        maxLength="10"
                        min="0"
                        // max="100"
                        className="form-control col-md-4"
                        step="1"
                        autoFocus
                        value={
                          this.props.user.userMdl.businessAreaKey ===
                          AppConst.businessAreaKeyPer
                            ? "0"
                            : this.state.selectedRow.points
                        }
                        onChange={(e) => this.onfieldChange(e, "points")}
                        disabled={
                          this.props.user.userMdl.businessAreaKey ===
                          AppConst.businessAreaKeyPer
                            ? true
                            : this.props.modalFor === "View"
                            ? true
                            : false
                        }
                      />
                    </div>
                  </div>
                </MDBCardBody>
              </Accordion.Collapse>
            </MDBCard>
            <MDBCard className="assignCard">
              <Accordion.Toggle
                as={MDBCardHeader}
                eventKey="2"
                //onClick={() => this.handleOnClick}
              >
                <label
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                >
                  <MDBIcon fa="true" icon="chevron-down" />
                </label>
                <label
                  md="0.2"
                  className={
                    "control-label " +
                    this.props.theme.name +
                    "-Assign-FontWithBold"
                  }
                  style={{ fontSize: "15px", textTransform: "capitalize" }}
                >
                  {I18n.t("CeaKpi.inputs")}
                </label>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <MDBCardBody className="cardPadding">
                  <div>
                    <div className="col-md-12 row control-label">
                      <label
                        className="col-md-4 control-label"
                        style={{ paddingLeft: "0.3rem" }}
                      >
                        {I18n.t("CeaKpi.inputFreq")}
                        <span className="redFont"> *</span>
                      </label>
                    </div>
                    {/* input frequency dropdown */}

                    <div className="col-md-12 input-group control-label">
                      <Select
                        placeholder={I18n.t("others.select")}
                        value={
                          this.props.user.userMdl.activeProgram ===
                          AppConst.activeProgramUc
                            ? this.state.selectedItems.evaluationFrequency
                            : this.isNotEmpty(
                                this.state.selectedRow.evaluationFrequency
                              )
                            ? this.state.selectedItems.evaluationFrequency
                            : null
                        }
                        onChange={(e) => {
                          this.onfieldChange(e, "evaluationFrequency");
                        }}
                        options={this.props.ceaDropdowns.inputFrequencyList}
                        isClearable={true}
                        className="ceakpi_modal_unit"
                        isDisabled={
                          this.props.modalFor === "Edit" &&
                          this.props.user.userMdl.activeProgram ===
                            AppConst.activeProgramNet
                            ? true
                            : this.props.modalFor === "View" ||
                              this.props.user.userMdl.activeProgram ===
                                AppConst.activeProgramUc
                            ? true
                            : this.state.selectedRow.status === "PUBLISHED" &&
                              this.props.user.userMdl.activeProgram ===
                                AppConst.activeProgramLN
                            ? true
                            : this.state.selectedRow.status === "PROCESSED" &&
                              this.props.user.userMdl.activeProgram ===
                                AppConst.activeProgramLN
                            ? true
                            : false
                        }
                      />
                    </div>

                    {this.state.selectedRow.evaluationFrequency !== "NN" &&
                    ((this.state.selectedRow.paramDtoList !== undefined &&
                      this.props.modalFor === "View") ||
                      this.props.modalFor === "Create" ||
                      (this.state.selectedRow.paramDtoList !== undefined &&
                        this.props.modalFor === "Edit")) ? (
                      <ParamTable
                        paramTableData={this.paramTableData}
                        type={this.props.modalFor}
                        status={this.state.selectedRow.status}
                        paramDTO={this.state.selectedRow.paramDtoList}
                      />
                    ) : (
                      ""
                    )}
                    {this.state.selectedRow.evaluationFrequency !== "NN"&& this.isNotEmpty(this.state.selectedRow.evaluationFrequency) &&
                    ((this.state.selectedRow.formulaDtoList !== undefined &&
                      this.props.modalFor === "View") ||
                      this.props.modalFor === "Create" ||
                      (this.state.selectedRow.formulaDtoList !== undefined &&
                        this.props.modalFor === "Edit")) &&
                    this.props.user.userMdl.activeProgram ===
                      AppConst.activeProgramLN ? (
                      <FormulaTable
                        formulaTableData={this.formulaTableData}
                        type={this.props.modalFor}
                        status={this.state.selectedRow.status}
                        formulaDTO={this.state.selectedRow.formulaDtoList}
                        publishkpi={this.props.getPublishedLexusKpi}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                </MDBCardBody>
              </Accordion.Collapse>
            </MDBCard>
          </Accordion>
          <form className="form-horizontal"></form>
        </ModalBody>
        {this.props.modalFor !== "View" ? (
          <ModalFooter>
            <button
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => this.props.toggle(this.props.modalFor)}
            >
              {I18n.t("tmeAdminMaster.cancel")}
            </button>
            {this.props.modalFor === "Create" ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.saveKpi}
              >
                {I18n.t("tmeAdminMaster.save")}
              </button>
            ) : (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.saveKpi}
              >
                {I18n.t("tmeAdminMaster.update")}
              </button>
            )}
          </ModalFooter>
        ) : (
          ""
        )}
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    ceaSubpillar: state.ceaNetKPIReducer.ceaSubpillar,
    ceaDropdowns: state.ceaNetKPIReducer.ceaDropdowns,
    getKpiDetails: state.ceaNetKPIReducer.getKpiDetails,
    getPublishedLexusKpi: state.ceaNetKPIReducer.getLexusPublishedKpiList,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(CeaNetKpiModal)));
