import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../../themes/theming";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import "./../index.css";
import {fetchTargetFilters,saveCEASearchFilters} from '../../../actions/finalizeTargetAction.jsx';
import {MDBIcon } from "mdbreact";
var I18n = require("react-redux-i18n").I18n;

class SearchTargetSetting extends React.Component{
    /* eslint-disable */
    constructor(props){
        super(props);
        this.state ={ 
            isNmscOwner: false,
            nmscCodeList : [],
            brandList : [],
            subPillarList : [],
            statusList : [],
            kpiList : [],
            businessFunctionList : [],
            selectedNmscCode : [],
            selectedBrandList : [],
            selectedSubPillarList : [],
            selectedStatusList : [],
            selectedKpiList : [],
            selectedBusinessFunctionList : [],
            isNotTMEAdminUser: false,           
            searchDto: {
                nmscCodeList : [] ,
                valueNmsc : '' ,
                suggestionsNmsc : [],
                activeProgramId : this.props.user.userMdl.activeProgramID,
                ceaYear : this.props.user.userMdl.ceaDefaultYears.value,
                userId : this.props.user.userMdl.username                            
            }
        }
    }

    componentWillMount(){
        var isNMSCAdminUser = false;
        var isNotAdmin = false;
        var isNotTMEAdminUser = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf('KET-CEA Business Owner') !== -1 ) {
                isNMSCAdminUser = true;
                this.setState({isNmscOwner:true})
                break;
              }
              else if (roles[i].toUpperCase() === "KET-CEA NMSC ADMIN"  ||  roles[i].toUpperCase() === "KET-CEA NMSC USER" ){
                isNotTMEAdminUser = true;
                this.setState({isNotTMEAdminUser:true})
                break;
            }
            isNotAdmin = true;
    
          }
        }
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"nmscCode"))
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"brand"))
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"status"))
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"subpillar"))
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"toolbox"))
        this.props.dispatch(fetchTargetFilters(this.state.searchDto,"KPI"))



    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }
    } 

    componentDidUpdate(prevProps, prevState) {

        let searchDto = {...this.state.searchDto}
        console.log(this.props.targetFilters)
        if(prevProps.targetFilters.nmscCodeList!== this.props.targetFilters.nmscCodeList && this.props.targetFilters.nmscCodeList!== undefined ){
            if(this.state.isNotTMEAdminUser){
                let filteredList = [];
                let obj;
                let nmsclist = [...this.state.nmscCodeList];
                nmsclist.push(this.props.user.userMdl.nmsc)
                //this.setState({nmscCodeList:nmsclist})
                var nmsc = this.props.user.userMdl.nmsc
                var valueOptions = [];
            this.props.targetFilters.nmscCodeList.map(function (item) {
                if(item.nmscCode.toLowerCase().indexOf(nmsc.toLowerCase())!==-1){
                console.log("item",item.nmscCode)
                valueOptions.push(item.nmscCode);
                }
            })
            valueOptions.map(value => {               
                    obj = {             
                    label: value,
                    value: value
                };            
                filteredList.push(obj);
            });
            searchDto["nmscCodeList"] = nmsclist;
            this.setState({nmscCodeList: filteredList,searchDto})
            this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
            this.props.dispatch(fetchTargetFilters(searchDto,"status"))
            this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
            this.props.dispatch(fetchTargetFilters(searchDto,"toolbox"))
            this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))

            }
            else{
            var valueOptions = [];
            this.props.targetFilters.nmscCodeList.map(function (item) {
                if(item.nmscCode){
                console.log("item",item.nmscCode)
                valueOptions.push(item.nmscCode);
                }
            })
            let filteredList = [];
            let obj;
            var valueNmsc = [];
            var valueOptions1 = [];
            if (valueOptions !== undefined) {
                valueOptions.map(value => {               
                        obj = {             
                        label: value,
                        value: value
                    };            
                    filteredList.push(obj);
                });
                valueOptions.map(function (item) {
                    valueNmsc = item.split("-")
                valueOptions1.push(valueNmsc[1])
                })
            }
//return filteredList;
console.log("list",valueOptions1)
            searchDto["nmscCodeList"] = valueOptions1;
            this.setState({nmscCodeList: filteredList,searchDto})
            }
        }

        if(prevProps.targetFilters.brandList!== this.props.targetFilters.brandList && this.props.targetFilters.brandList!== undefined ){

            var brandOptions = [];
            this.props.targetFilters.brandList.map(function (item) {
                if(item.brand){
                //console.log("item",item.nmscCode)
                brandOptions.push(item.brand);
                }
            })
            let list = [];
            let obj;
            if (brandOptions !== undefined) {
                brandOptions.map(value => {               
                        obj = {             
                        label: value,
                        value: value
                    };            
                    list.push(obj);
                });
            }

            searchDto["brandList"] = brandOptions;
            this.setState({brandList: list,searchDto})
        
        }

        if(prevProps.targetFilters.statusList!== this.props.targetFilters.statusList && this.props.targetFilters.statusList!== undefined ){

            var valueOptions = [];
            this.props.targetFilters.statusList.map(function (item) {
                if(item.status){
                //console.log("item",item.nmscCode)
                valueOptions.push(item.status);
                }
            })
            let filterList = [];
            let obj;
            if (valueOptions !== undefined) {
                valueOptions.map(value => {               
                        obj = {             
                        label: value,
                        value: value
                    };            
                    filterList.push(obj);
                });
            }
            searchDto["statusList"] = valueOptions;
            this.setState({statusList: filterList,searchDto})
        
        }

        if(prevProps.targetFilters.businessFunctionList!== this.props.targetFilters.businessFunctionList && this.props.targetFilters.businessFunctionList!== undefined ){
            var businessOptions = [];
            var businessValue = [];
            let obj;
            this.props.targetFilters.businessFunctionList.map(function (item) {
                if(item.businessFunction){
                //console.log("item",item.nmscCode)
                obj = {             
                    label: item.businessFunction,
                    value: item.bussinessFunctionId
                };    
                businessOptions.push(obj);
                }
                businessValue.push(item.bussinessFunctionId);

            })
            // let filterList = [];
            // let obj;
            // if (businessOptions !== undefined) {
            //     businessOptions.map(value => {               
            //             obj = {             
            //             label: value,
            //             value: value
            //         };            
            //         filterList.push(obj);
            //     });
            // }
            
            searchDto["businessFunctionList"] = businessValue;
            this.setState({businessFunctionList: businessOptions,searchDto})
        
        }

        if(prevProps.targetFilters.subPillarList!== this.props.targetFilters.subPillarList && this.props.targetFilters.subPillarList!== undefined ){
            var pillarOptions = [];
            var pillarValue = [];
            let obj;
            this.props.targetFilters.subPillarList.map(function (item) {
                if(item.subPillar){
                //console.log("item",item.nmscCode)
                obj = {             
                    label: item.subPillar,
                    value: item.subPillarId
                };    
                pillarOptions.push(obj);//label
                //pillarOptions.push(item.subPillarId);//value
                }
                pillarValue.push(item.subPillarId)
            })
            // let filterList = [];
            // let obj;
            console.log("pillar",pillarOptions)
            // if (pillarOptions !== undefined) {
            //     pillarOptions.map((item, key) => {    
            //         console.log("item",item)
            //         console.log("key",key)
         
                    //     obj = {             
                    //     label: value,
                    //     value: value
                    // };            
                    // filterList.push(obj);
            //     });
            // }
            
            searchDto["subPillarList"] = pillarValue;
            this.setState({subPillarList: pillarOptions,searchDto})
        
        }

        if(prevProps.targetFilters.kpiList!== this.props.targetFilters.kpiList && this.props.targetFilters.kpiList!== undefined ){
            var kpiOptions = [];
            var kpiValue = [];

            let obj;
            
            this.props.targetFilters.kpiList.map(function (item) {
                if(item.kpi){
                    obj = {             
                        label: item.kpi,
                        value: item.kpiEvaluationId
                    };   
                //console.log("item",item.nmscCode)
                kpiOptions.push(obj);
                }
                kpiValue.push(item.kpi)

            })
            // let filterList = [];
            // if (kpiOptions !== undefined) {
            //     kpiOptions.map(value => {               
            //             obj = {             
            //             label: value,
            //             value: value
            //         };            
            //         filterList.push(obj);
            //     });
            // }
            
            searchDto["kpiList"] = kpiValue;
            this.setState({kpiList: kpiOptions,searchDto})
        
        }
    }


    onNmscCodeChange = (selectedOption) => {
        
         const searchDto = { ...this.state.searchDto };
         var valueOptions = [];
         var valueNmsc = [];
         var valueOptions1 = [];

         selectedOption.map(function (item) {
             valueOptions.push(item.value);
         })
         valueOptions.map(function (item) {
            valueNmsc = item.split("-")
        valueOptions1.push(valueNmsc[1])
        })
         searchDto["nmscCodeList"] = valueOptions1;
         this.setState({ searchDto });
         this.setState({ selectedNmscCode: selectedOption })
         this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
        this.props.dispatch(fetchTargetFilters(searchDto,"status"))
        this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
        this.props.dispatch(fetchTargetFilters(searchDto,"toolbox"))
        this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))
   
    }

    onBrandChange = (selectedOption) => {
        
        const searchDto = { ...this.state.searchDto };
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        searchDto["brandList"] = valueOptions;
        this.setState({ searchDto });
        this.setState({ selectedBrandList: selectedOption })
        // if(!this.state.isNotTMEAdminUser){
        //     this.props.dispatch(fetchTargetFilters(searchDto,"nmscCode"))
        //     }
       this.props.dispatch(fetchTargetFilters(searchDto,"status"))
       this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
       this.props.dispatch(fetchTargetFilters(searchDto,"toolbox"))
       this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))
  
   }

   onStatusChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
    })
    searchDto["statusList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedStatusList: selectedOption })
    // if(!this.state.isNotTMEAdminUser){
    //     this.props.dispatch(fetchTargetFilters(searchDto,"nmscCode"))
    //     }
   this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
   this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
   this.props.dispatch(fetchTargetFilters(searchDto,"toolbox"))
   this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))

}

onBusinessFunctionChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
    })
    searchDto["businessFunctionList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedBusinessFunctionList: selectedOption })
    // if(!this.state.isNotTMEAdminUser){
    //     this.props.dispatch(fetchTargetFilters(searchDto,"nmscCode"))
    //     }
   this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
   this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
   this.props.dispatch(fetchTargetFilters(searchDto,"status"))
   this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))

}

onKpiChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.label);
    })
    searchDto["kpiList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedKpiList: selectedOption })
    // if(!this.state.isNotTMEAdminUser){
    //     this.props.dispatch(fetchTargetFilters(searchDto,"nmscCode"))
    //     }
   this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
   this.props.dispatch(fetchTargetFilters(searchDto,"subpillar"))
   this.props.dispatch(fetchTargetFilters(searchDto,"status"))
   this.props.dispatch(fetchTargetFilters(searchDto,"tolbox"))

}

onSubPillarChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
    })
    searchDto["subPillarList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedSubPillarList: selectedOption })
    // if(!this.state.isNotTMEAdminUser){
    //     this.props.dispatch(fetchTargetFilters(searchDto,"nmscCode"))
    //     }
   this.props.dispatch(fetchTargetFilters(searchDto,"brand"))
   this.props.dispatch(fetchTargetFilters(searchDto,"KPI"))
   this.props.dispatch(fetchTargetFilters(searchDto,"status"))
   this.props.dispatch(fetchTargetFilters(searchDto,"tolbox"))

}
    
    search = (e) => {
        let searchDto = {...this.state.searchDto}
        e.preventDefault(); 
        this.props.dispatch(saveCEASearchFilters(searchDto))
       // this.props.search(searchDto)
            // }
    }

    render(){  
        

        console.log("nmsc in state",this.state.nmscCodeList)
        console.log("nmsc in props",this.props.user.userMdl.roles)
        let isNmscCodeDisabled = this.state.isNotTMEAdminUser?true:false
        
        return (
        <div className="col-md-12" style={{ padding: "0px" }}>
            <div id="searchId" style={{ borderColor: this.props.theme.primaryColor }} className="col-md-12 searchDash">
                <div>
                    <div className="col-md-12 row control-label">                    
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400',marginLeft:'-8px',marginRight: '3px'}}>{I18n.t('Table.nmsc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.subPillar')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.businessFunc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400', marginRight: '4px' }}>{I18n.t('kpiEntry.kpi')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.brand')}</label>
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.status')}</label>
                    </div>
                </div>
                <div className={"col-md-12 input-group control-label dealerFilter-"+this.props.theme.name} style={{paddingLeft: '5px'}}>
                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                {!this.state.isNotTMEAdminUser ?
                <ReactMultiSelectCheckboxes
                                value={this.state.selectedNmscCode}
                                onChange={(e)=>this.onNmscCodeChange(e)}
                                options={this.state.nmscCodeList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            /> :
                            <Select
                                className = {"disabledField" + this.props.theme.name}
                                //className = {"activeField-" + this.props.theme.name}
                                value={this.state.nmscCodeList[0]}
                                onChange={(e)=>{this.onNmscCodeChange(e)}}
                                options={this.state.nmscCodeList}
                                isDisabled={isNmscCodeDisabled}
                                placeholder={I18n.t('ceaEvaluation.all')}
                                isSearchable
                        />}
                        </div>
                        {/* <div style = {{margin : '5px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedSubPillarList}
                                onChange={(e)=>this.onSubPillarChange(e)}
                                options={this.state.subPillarList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        </div>
                        {/* <div style = {{margin : '5px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedBusinessFunctionList}
                                onChange={(e)=>this.onBusinessFunctionChange(e)}
                                options={this.state.businessFunctionList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        </div>
                        {/* <div style = {{margin : '5px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedKpiList}
                                onChange={(e)=>this.onKpiChange(e)}
                                options={this.state.kpiList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        </div>
                        {/* <div style = {{margin : '5px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedBrandList}
                                onChange={(e)=>this.onBrandChange(e)}
                                options={this.state.brandList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        </div>
                        {/* <div style = {{margin : '5px'}}/> */}
                        <div className={"col-md-1 noPadding bigDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedStatusList}
                                onChange={(e)=>this.onStatusChange(e)}
                                options={this.state.statusList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        </div>
                        <div className={"col-md-1 searchIcon-"+ this.props.theme.name} style={{ padding: '0px' }}>
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("ceaEvaluation.search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>
                        </div>
                </div>
                {/* <div className="col-md-12 input-group control-label">
                    <div className={"col-md-12 searchIcon-"+ this.props.theme.name} style={{ padding: '20px 0px 0px 0px' }}> */}
                        {/* <button className={"button-" + this.props.theme.name + " noMargin"}
                            style = {{float : 'right' }} onClick={(e) =>this.search(e)}>
                            {I18n.t("ceaEvaluation.search")}
                        </button> */}
                        {/* <a 
                            onClick={(e) =>this.search(e)}>
                            <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("ceaEvaluation.search")} 
                            style = {{float : 'right' }}
                            /> 
                        </a>
                    </div>
                </div>  */}
            </div>                    
            <div id="hrId" className="col-md-12 hrClass">
                <hr style={{ margin: '0px', borderTop: "1px solid" }} />
            </div>
        </div>
    );
  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        targetFilters : state.userManagementReducer.targetFilters,
        router: state.routing
    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(SearchTargetSetting)));