export default function productReducer(state = {
    uploadResponse: {}
  }, action) {
    var newState;
    switch (action.type) {   
      case "UPLOAD_NETWORK_DATA":
         newState = Object.assign({}, state, {
          uploadResponse: action.payload
        });
        return newState;
      default:
        return state;
  
  
    }
  }