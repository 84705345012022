import React from 'react';
import { connect } from 'react-redux';

class UserPreferences extends React.Component {
    
    render() {
        return (
           <h1>Welcome to User Preferences</h1>
        );
    }
}
export default connect()(UserPreferences);