import { combineReducers } from 'redux';
import loginReducer from './loginReducer.jsx';
import tabReducer from './tabReducer.jsx';
import leftNavReducer from './leftNavReducer.jsx';
import activityReducer from './activityReducer.jsx';
import uploadReducer from './uploadReducer.jsx';
import questionSummaryReducer from './questionSummaryReducer.jsx';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { i18nReducer } from 'react-redux-i18n';
import { connectRouter } from 'connected-react-router'
import libraryReducer from './libraryReducer.jsx';
import evaluationReducer from './evaluationReducer.jsx';
import kaizenReducer from './kaizenReducer.jsx'
import dashboardReducer from './dashboardReducer.jsx';
import certificationReducer from './certificationReducer.jsx';
import bulkUploadReducer from './bulkUploadReducer.jsx';
import userManagementReducer from './userManagementReducer.jsx';
import dealerManagementReducer from './dealerManagementReducer.jsx';
import ceaKpiEntryReducer from './ceaKpiEntryReducer';
import ceaNetKPIReducer from './ceaNetKPIReducer.jsx';

export default (history) => combineReducers({
    loginReducer,
    tabReducer,
    toastr: toastrReducer,
    router: connectRouter(history),
    i18n: i18nReducer,
    leftNavReducer,
    uploadReducer,
    activityReducer,
    questionSummaryReducer,
    libraryReducer,
    evaluationReducer,
    kaizenReducer,
    dashboardReducer,
    certificationReducer,
    bulkUploadReducer,
    userManagementReducer,
    dealerManagementReducer,
    ceaNetKPIReducer,
    ceaKpiEntryReducer
})
