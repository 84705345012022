import React from "react";
import {
    Container,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "mdbreact";
import Select from "react-select";
import { withTheme } from "../../../themes/theming";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import "react-datepicker/dist/react-datepicker.css";
import "../index.css";
import "moment-timezone";

import { fetchUser, saveUser, fetchNmscLang } from '../../../actions/userManagementAction'

var I18n = require("react-redux-i18n").I18n;

class UserManageModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            britDealerChampList: [{ "value": "Y", "label": "Y" }, { "value": "", "label": "" }],
            statusList: [{ "value": "ACTIVE", "label": I18n.t("userManagement.ACTIVE") }, { "value": "INACTIVE", "label": I18n.t("userManagement.INACTIVE") }],
           

            userDto: {
                networkUserId: null,
                nmscId: null,
                tarsId: null,
                firstName: null,
                lastName: null,
                emailId: null,
                languageCode: null,
                britDealerChamp: "",
                pbiAccount : null,
                status: this.props.modalFor === 'Create' ? "ACTIVE" : "",
                role: [],
                adminUserLoginId: this.props.user.userMdl.username,
                adminUserLangCode: this.props.user.userMdl.lang,
                adminUserNmscCode: this.props.user.userMdl.nmsc,
                programID: this.props.user.userMdl.activeProgramID,
                program: this.props.user.userMdl.activeProgram,
                flagBRiT:this.props.user.userMdl.flagBRiT

            },

            selectedItem: {
                nmscId: [],
                firstName: null,
                lastName: null,
                emailId: null,
                languageCode: [],
                pbiAccount:null,
                britDealerChamp: [{ "value": "", "label": "" }],
                status: this.props.modalFor === 'Create' ? [{ "value": "ACTIVE", "label": I18n.t("userManagement.ACTIVE") }] : [],
                role: [],

            }

        }
    }

    componentWillMount() {
        if (this.props.modalFor !== "Create") {
            this.props.dispatch(fetchUser(this.props.selectedRow.networkUserId));
        }

        if (this.props.modalFor === "Create" && (this.props.user.userMdl.nmsc.toUpperCase() !== 'TME' && this.props.user.userMdl.nmsc.toUpperCase() !== 'TMME')) {
            let userDto = { ...this.state.userDto };
            let selectedItem = { ...this.state.selectedItem };

            userDto['nmscId'] = this.props.user.userMdl.nmsc
            selectedItem['nmscId'] = { label: this.props.user.userMdl.nmsc, value: this.props.user.userMdl.nmsc }

            this.setState({ userDto, selectedItem });

            this.props.dispatch(fetchNmscLang(this.props.user.userMdl.nmsc));

        } else {
            this.props.dispatch(fetchNmscLang("nmsc"));


        }

    }

    componentWillReceiveProps(nextProps) {

        // if (nextProps.userInfo) {
        //     console.log(nextProps.userInfo);
        // }


    }
    componentDidUpdate(prevProps, prevState) {


        if (this.props.modalFor === 'Create' && (prevProps.countryList !== this.props.countryList) && this.props.countryList.length === 1) {
            let userDto = { ...this.state.userDto };
            let selectedItem = { ...this.state.selectedItem };

            userDto['languageCode'] = this.props.countryList[0].value
            selectedItem['languageCode'] = this.props.countryList[0]

            this.setState({ userDto, selectedItem });
        }


        if (prevProps.userInfo !== this.props.userInfo) {
            // console.log(prevProps.userInfo)
            // console.log(this.props.userInfo)

            let userDto = { ...this.state.userDto };
            let selectedItem = { ...this.state.selectedItem };


            userDto['networkUserId'] = this.props.userInfo.networkUserId
            userDto['nmscId'] = this.props.userInfo.nmscId
            userDto['tarsId'] = this.props.userInfo.tarsId

            userDto['firstName'] = this.props.userInfo.firstName
            userDto['lastName'] = this.props.userInfo.lastName
            userDto['emailId'] = this.props.userInfo.emailId
            userDto['languageCode'] = this.props.userInfo.languageCode
            userDto['britDealerChamp'] = this.props.userInfo.britDealerChamp
            userDto['status'] = this.props.userInfo.status
            userDto['pbiAccount'] = this.props.userInfo.pbiAccount

            if (this.props.userInfo.nmscId !== "" && this.props.userInfo.nmscId !== null) {
                this.props.dispatch(fetchNmscLang(this.props.userInfo.nmscId));
            }

            if (this.props.nmscList && this.props.nmscList.length > 0 && this.props.userInfo.nmscId !== "" && this.props.userInfo.nmscId !== null) {
                /* eslint-disable */
                let nmsc = this.props.nmscList.map(obj => {
                    if (obj.value === this.props.userInfo.nmscId) {
                        return obj;
                    }
                })

                // selectedItem['nmscId'] = nmsc
                selectedItem['nmscId'] = { value: this.props.userInfo.nmscId, label: this.props.userInfo.nmscId }

            }

            if (this.props.countryList && this.props.countryList.length > 0 && this.props.userInfo.languageCode !== "" && this.props.userInfo.languageCode !== null) {
                // console.log(this.props.userInfo.languageCode);
                let country = this.props.countryList.map(obj => {
                    if (obj.value === this.props.userInfo.languageCode) {
                        return obj;
                    }
                })

                // selectedItem['languageCode'] = country
                selectedItem['languageCode'] = { value: this.props.userInfo.languageCode, label: this.props.userInfo.languageCode }

            } else {
                selectedItem['languageCode'] = { value: this.props.userInfo.languageCode, label: this.props.userInfo.languageCode }

            }


            if (this.state.britDealerChampList && this.state.britDealerChampList.length > 0) {
                let brit = this.state.britDealerChampList.map(obj => {
                    if (obj.value === this.props.userInfo.britDealerChamp) {
                        return obj;
                    }
                })

                selectedItem['britDealerChamp'] = brit
            }

            if (this.state.statusList && this.state.statusList.length > 0 && this.props.userInfo.status !== "" && this.props.userInfo.status !== null) {
                let status = this.state.statusList.map(obj => {
                    if (obj.value === this.props.userInfo.status) {
                        return obj;
                    }
                })

                selectedItem['status'] = status

            }



            // console.log(userDto)
            // console.log(selectedItem)

            this.setState({ userDto: userDto, selectedItem: selectedItem });

        }

    }

    preventDefault() { }

    componentWillUnmount() {

    }

    onFeildChange = (event, type) => {
        // console.log(type)
        let userDto = { ...this.state.userDto };
        let selectedItem = { ...this.state.selectedItem };
        if (type === 'firstName') {

            // console.log(event.target.value)
            userDto['firstName'] = event.target.value;

        } else if (type === 'lastName') {

            // console.log(event.target.value)
            userDto['lastName'] = event.target.value;

        } else if (type === 'tarsId') {

            // console.log(event.target.value)
            userDto['tarsId'] = event.target.value;

        } else if (type === 'emailId') {

            // console.log(event.target.value)
            userDto['emailId'] = event.target.value;

        }else if (type === 'pbiAccount') {

            // console.log(event.target.value)
            userDto['pbiAccount'] = event.target.value;

        } else if (type === 'nmscId') {
            // console.log(event);

            if (this.isNotEmpty(event)) {
                this.props.dispatch(fetchNmscLang(event.value));
                userDto['languageCode'] = null;
                selectedItem['languageCode'] = [];

                userDto['nmscId'] = event.value;
                selectedItem['nmscId'] = event;
            } else {
                userDto['nmscId'] = null;
                selectedItem['nmscId'] = [];

                userDto['languageCode'] = null;
                selectedItem['languageCode'] = [];
            }

        } else if (type === 'languageCode') {

            // console.log(event);
            if (this.isNotEmpty(event)) {
                userDto['languageCode'] = event.value;
                selectedItem['languageCode'] = event;
            } else {
                userDto['languageCode'] = null;
                selectedItem['languageCode'] = [];
            }


        } else if (type === 'britDealerChamp') {

            // console.log(event);
            if (this.isNotEmpty(event)) {

                userDto['britDealerChamp'] = event.value;
                selectedItem['britDealerChamp'] = event;
            } else {
                userDto['britDealerChamp'] = null;
                selectedItem['britDealerChamp'] = [];
            }


        } else if (type === 'status') {

            // console.log(event);
            if (this.isNotEmpty(event)) {

                userDto['status'] = event.value;
                selectedItem['status'] = event;
            } else {

                userDto['status'] = null;
                selectedItem['status'] = [];
            }

        }

        this.setState({ userDto, selectedItem });

    }
    validateEmail = (value) => {
        // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // console.log(value)
        if (undefined === value || null === value) {
            return false;
        }
        if (value.indexOf('@') === -1 || value.indexOf('.') === -1) {
            return false;
        }

        return true;
        // return re.test(String(email).toLowerCase());
    }

    validatePbiAccount = (value) => {
        // const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // console.log(value)
    
        if (value.length<3) {
            return false;
        }
        return true;
        // return re.test(String(email).toLowerCase());
    }

    validateName = (value) => {
        // console.log(value)
        if (undefined === value || null === value) {
            return false;
        }
        if (value.indexOf(' ') !== -1 && value.indexOf(' ') < 2) {
            return false;
        }

        return true;
    }


    validateTarsId = (value) => {
        if (undefined === value || null === value) {
            return false;
        }
        if (value.indexOf('@') === 0 || value.length < 5) {
            return false;
        }

        return true;
    }

    saveUser = (e) => {

        // console.log(e);
        let userDto = { ...this.state.userDto };

        // console.log(userDto)

        if ((this.isNotEmpty(userDto.nmscId) && this.isNotEmpty(userDto.tarsId) && 
            this.isNotEmpty(userDto.firstName)
            && this.isNotEmpty(userDto.lastName) && this.isNotEmpty(userDto.languageCode) && this.isNotEmpty(userDto.emailId)
            && this.isNotEmpty(userDto.status))
            ||
            (this.isNotEmpty(userDto.nmscId) && this.isNotEmpty(userDto.tarsId) && this.isNotEmpty(userDto.status)
            && (this.state.userDto.status === 'INACTIVE'))
            ) {


            // console.log(this.validateEmail(userDto.emailId));
            if (!this.validateEmail(userDto.emailId) && !(this.state.userDto.status === 'INACTIVE')) {
                toastr.error(I18n.t("userManagement.invalidEmail"));

            }else if(this.state.userDto.flagBRiT && this.isNotEmpty(userDto.pbiAccount) && !this.validatePbiAccount(userDto.pbiAccount) ){
                toastr.error(I18n.t("userManagement.invalidPbiAccount"));
            }
            else if (!this.validateName(userDto.firstName) && !(this.state.userDto.status === 'INACTIVE')) {
                toastr.error(I18n.t("userManagement.invalidFirstName"));

            } else if (!this.validateName(userDto.lastName) && !(this.state.userDto.status === 'INACTIVE')) {
                toastr.error(I18n.t("userManagement.invalidLastName"));

            } else if (!this.validateTarsId(userDto.tarsId)) {
                toastr.error(I18n.t("userManagement.invalidTars"));

            } else {
                // console.log(this.state.userDto);
                // console.log(this.props.searchDto);

                this.props.dispatch(saveUser(this.state.userDto, this.props.searchDto, this.props.userDto, this.props.isSearchClicked, this));
            }



        } else {
            toastr.error(I18n.t("toastr.modalMandatory"));

        }


    }

    isNotEmpty = (value) => {
        if (undefined === value || null === value) {
            return false;
        }
        else if ((value + "").trim() === "") {
            return false;
        }
        return true;
    }

    onBlurEmail = (event) => {
        // console.log(event.target.value)
        if (!this.validateEmail(event.target.value) && event.target.value!=="") {
            toastr.error(I18n.t("userManagement.invalidEmail"));
        }
    }

    onBlurName = (event, type) => {
        // console.log(event.target.value)
        if (!this.validateName(event.target.value) && event.target.value!=="") {
            if (type === "lastName") {
                toastr.error(I18n.t("userManagement.invalidLastName"));

            }
            if (type === "firstName") {
                toastr.error(I18n.t("userManagement.invalidFirstName"));

            }
        }
    }

    onBlurTarsId = (event) => {
        // console.log(event.target.value)
        if (!this.validateTarsId(event.target.value) && event.target.value!=="") {
            toastr.error(I18n.t("userManagement.invalidTars"));
        }
    }

    onBlurPbiAccount = (event)=>{
        if(this.isNotEmpty(event.target.value)){
            event.target.value.length<3 ? toastr.error(I18n.t("userManagement.invalidPbiAccount")):null
        }
    }

    roleView = () => {

        return (
            <div>
                <label className="col-md-12 control-label">
                    {I18n.t("UserManagement.role")}
                </label>
                <div className="col-md-12">
                    {this.props.userInfo.roleList.map((obj) =>
                        <div className="form-control" style={{ backgroundColor: '#e9ecef' }}>
                            {obj}
                        </div>
                    )
                    }
                </div>
            </div>
        );
    }

    render() {
        // console.log(this.state.userDto);
        // console.log(this.state.selectedItem);
        // console.log(this.props.nmscList)
        // console.log(this.props.countryList)

        return (
            <Container>
                <Modal
                    className={
                        "addEditUserModal addEditUserModal-" + this.props.theme.name
                    }
                    id="addEditUserModal"
                    isOpen="true"
                    toggle={() => this.preventDefault()}
                >
                    <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>
                        {this.props.modalFor === "Create" ? I18n.t("userManagement.createUser") : this.props.modalFor !== "View" ? I18n.t("userManagement.editUser")
                            : I18n.t("userManagement.viewUser")}
                    </ModalHeader>
                    <ModalBody>
                        <form className="form-horizontal">
                            <label className="col-md-12 control-label">
                                {I18n.t("userManagement.nmscId")}
                                <span className="redFont"> *</span>
                            </label>

                            <div className="col-md-12">
                                <Select
                                    placeholder={I18n.t('others.select')}
                                    value={this.state.selectedItem.nmscId}
                                    onChange={(e) => this.onFeildChange(e, "nmscId")}
                                    // options={this.state.nmscList}
                                    options={this.props.nmscList}

                                    isClearable
                                    isDisabled={this.props.modalFor !== "Create" ? true : (this.props.user.userMdl.nmsc.toUpperCase() !== 'TME' && this.props.user.userMdl.nmsc.toUpperCase() !== 'TMME') ? true : false}
                                />
                            </div>
                            <label className="col-md-12 control-label">
                                {I18n.t("userManagement.tarsId")}
                                <span className="redFont"> *</span>

                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    onBlur={(e) => this.onBlurTarsId(e)}
                                    onChange={(e) => this.onFeildChange(e, "tarsId")}
                                    value={this.state.userDto.tarsId}
                                    disabled={this.props.modalFor !== "Create" ? true : false}
                                />

                            </div>
                            <label className="col-md-12 control-label">
                                {I18n.t("userManagement.firstName")}
                                {(this.state.userDto.status === 'INACTIVE') ?
                                      "" : <span className="redFont"> *</span>}
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    onBlur={(e) => this.onBlurName(e, "firstName")}
                                    onChange={e => this.onFeildChange(e, "firstName")}
                                    value={this.state.userDto.firstName}
                                    disabled={this.props.modalFor === "View" ? true : false}
                                />
                            </div>
                            <label className="col-md-12 control-label">
                                {I18n.t("userManagement.lastName")}
                                {(this.state.userDto.status === 'INACTIVE') ?
                                      "" : <span className="redFont"> *</span>}
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    onBlur={(e) => this.onBlurName(e, "lastName")}
                                    onChange={(e) => this.onFeildChange(e, "lastName")}
                                    value={this.state.userDto.lastName}
                                    disabled={this.props.modalFor === "View" ? true : false}
                                />
                            </div>
                            <label className="col-md-12 control-label">
                                {I18n.t("userManagement.emailId")}
                                {(this.state.userDto.status === 'INACTIVE') ?
                                      "" : <span className="redFont"> *</span>}
                            </label>

                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    maxLength="100"
                                    onBlur={(e) => this.onBlurEmail(e)}
                                    onChange={(e) => this.onFeildChange(e, "emailId")}
                                    value={this.state.userDto.emailId}
                                    disabled={this.props.modalFor === "View" ? true : false}
                                />
                            </div>
                            {this.props.user.userMdl.flagBRiT?
                            <label className="col-md-12 control-label" style ={{paddingTop:"5px"}}>
                                {I18n.t("userManagement.pbiAccount")}
                            </label>:""}
                            {this.props.user.userMdl.flagBRiT?
                             <div className="col-md-12">
                                 <input
                                     type="text"
                                     className="form-control"
                                     maxLength="100"
                                     onBlur={(e) => this.onBlurPbiAccount(e)}
                                     onChange={(e) => this.onFeildChange(e, "pbiAccount")}
                                     value={this.state.userDto.pbiAccount}
                                     disabled={this.props.modalFor === "View" ? true : false}
                                 />
                             </div>:""
                            }
                            <label className="col-md-12 control-label">
                                {I18n.t("others.countryLanguageCode")}
                                {(this.state.userDto.status === 'INACTIVE') ?
                                      "" : <span className="redFont"> *</span>}
                            </label>

                            <div className="col-md-12">
                                <Select
                                    placeholder={I18n.t('others.select')}
                                    value={this.state.selectedItem.languageCode}
                                    onChange={(e) => this.onFeildChange(e, "languageCode")}
                                    options={this.props.countryList}
                                    isClearable
                                    isDisabled={this.props.modalFor === "View" ? true : (this.state.userDto.nmscId !== null && this.state.userDto.nmscId !== '') ? false : true}
                                />
                            </div>

                            {this.props.user.userMdl.businessAreaKey === "BRiT" ?
                                <label className="col-md-12 control-label">
                                    {I18n.t("userManagement.britDealerChamp")}
                                </label> : ""}
                            {this.props.user.userMdl.businessAreaKey === "BRiT" ?

                                <div className="col-md-12">
                                    <Select
                                        placeholder={I18n.t('others.select')}
                                        value={this.state.selectedItem.britDealerChamp}
                                        onChange={(e) => this.onFeildChange(e, "britDealerChamp")}
                                        options={this.state.britDealerChampList}
                                        isClearable
                                        isDisabled={this.props.modalFor === "View" ? true : false}
                                    />
                                </div> : ""}


                            <label className="col-md-12 control-label">
                                {I18n.t("Table.status")}
                                <span className="redFont"> *</span>
                            </label>

                            <div className="col-md-12">
                                <Select
                                    placeholder={I18n.t('others.select')}
                                    value={this.state.selectedItem.status}
                                    onChange={(e) => this.onFeildChange(e, "status")}
                                    options={this.state.statusList}
                                    isClearable
                                    isDisabled={this.props.modalFor === "View" ? true : false}
                                />
                            </div>

                            {this.props.userInfo && this.props.userInfo.roleList !== undefined && this.props.userInfo.roleList.length > 0 && this.props.modalFor === "View" ?

                                this.roleView()

                                : ""}

                        </form>
                    </ModalBody>
                    {this.props.modalFor !== "View" ?
                        <ModalFooter>
                            <button
                                className={"buttonCancel-" + this.props.theme.name}
                                onClick={() => this.props.toggle(this.props.modalFor)}
                            >
                                {I18n.t("tmeAdminMaster.cancel")}
                            </button>
                            {this.props.modalFor === "Create" ? (
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={e => {
                                        this.saveUser(e);
                                    }}
                                >
                                    {I18n.t("tmeAdminMaster.save")}
                                </button>
                            ) : (
                                <button
                                    className={"button-" + this.props.theme.name}
                                    onClick={e => {
                                        this.saveUser(e);
                                    }}
                                >
                                    {I18n.t("tmeAdminMaster.update")}
                                </button>
                            )}
                        </ModalFooter>
                        : ""
                    }
                </Modal>
            </Container>
        );


    }

}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        userInfo: state.userManagementReducer.userInfo,
        nmscList: state.userManagementReducer.nmscList,
        countryList: state.userManagementReducer.countryList



    };
};
export default withRouter(connect(mapStateToProps)(withTheme(UserManageModal)));
