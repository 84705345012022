import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { fetchMasterKpiList, importLocalKpi } from '../../actions/tabAction.jsx';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTheme } from '../../themes/theming';
import './index.css';
var I18n = require('react-redux-i18n').I18n;

class ImportTablePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalFor: '',
            selectedRow: {
                masterKpiId: 0,
                toolboxId: this.props.toolboxId,
                name: '',
                definition: '',
                benchmark: '',
                unit: '',
                status: '',
                additionalInfo: '',
                mandatory: true,
                publishedOn: '',
                createdBy: 'ADMIN',
                createdTimestamp: new Date()
            },
            toolboxId: this.props.toolboxId,
            masterKpiList: [],
            selected: [],
            selectedForImport: []
        };
        this.buttonFormatter = this.buttonFormatter.bind(this);
    }

    componentWillMount() {
        this.props.dispatch(fetchMasterKpiList(this.state.toolboxId, false));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.masterKpiList) {
            const kpiList = nextProps.masterKpiList;
            var selectedKpi = [];
            var selectedKpiForImport = [];
            var allKpis = [];
            for (var i = 0, size = kpiList.length; i < size; i++) {
                var item = kpiList[i];
                if (item.mandatory === true && item.status !== "Retired" && !this.props.masterKpi.includes(item.masterKpiId)) {
                    selectedKpiForImport.push(item.masterKpiId);
                }
                if (item.mandatory === true || item.status === "Retired" || this.props.masterKpi.includes(item.masterKpiId)) {
                    selectedKpi.push(item.masterKpiId);
                }
                allKpis.push(item.masterKpiId);
            }
            this.setState({ selected: selectedKpi });
            this.setState({ selectedForImport: selectedKpiForImport });
            this.setState({ masterKpiList: nextProps.masterKpiList });
            this.setState({ allKpis: allKpis });
        }
    }

    toggle = (action) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
        if (action === "Create") {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow['masterKpiId'] = 0;
            selectedRow['name'] = '';
            selectedRow['definition'] = '';
            selectedRow['benchmark'] = '';
            selectedRow['status'] = '';
            selectedRow['mandatory'] = true;
            selectedRow['publishedOn'] = '';
            selectedRow['additionalInfo'] = '';
            this.setState({ selectedRow });
        }
    }
    buttonFormatter(cell, row) {
        return (
            <div className="btn-toolbar float-right">
                {row.additionalInfo && row.additionalInfo.length > 0 ?
                    <a><div title={row.additionalInfo} className="info-icon" /></a> : ""}
            </div>
        );
    }

    flagFormatter(cell, row, rowIndex) {
        // if (cell === true) {
        //     this.setState({ selected: [...this.state.selected, row.masterKpiId] });
        // }
        return (
            <div>
                {cell === true ?
                    <div title={I18n.t('Table.mandatory')}  className="active-flag" /> :
                    <div title="Not Mandatory" className="inactive-flag" size="2x" />
                }
            </div>
        );
    }

    benchMarkFormatter(cell, row) {
        return (
            <div >
                {row.benchmark + " " + row.unit}
            </div>
        );
    }

    statusFormatter(cell, row) {
        return (
            <div >
                {I18n.t('status.'+row.status.toLowerCase())}
            </div>
        );
    }

    importNmsc = () => {
        if (this.state.selectedForImport.length > 0) {
            this.props.dispatch(importLocalKpi(this.state.selectedForImport, this.state.toolboxId, this.props.user.userMdl));
        }
        this.props.toggle(false);
    }
    benchmarkTitle(columns){
        
        if(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA"){
            columns[2].text=I18n.t('Table.penEbenchmark')
            columns[2].headerStyle.width="11.8%"
            columns[1].headerStyle.width="22.6%"
            columns[2].style.width="12%"
            columns[1].style.width="23%"
            return columns;
        }else if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
        || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS" || this.props.user.userMdl.activeProgram=== "NAS"){
            columns[2].text=I18n.t('Table.benchmark')
            return columns;
        }else {
            //..
        }
    }
    render() {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0].accessType : null;
        var columns = [{
            dataField: 'name',
            text: I18n.t('Table.kpi'),
            sort: true,
            style: { width: "20%", textAlign: "left" },
            headerStyle: { width: "19.7%", textAlign: "left" },

        }, {
            dataField: 'definition',
            text: I18n.t('Table.definition'),
            sort: true,
            style: { width: "26%", textAlign: "left" },
            headerStyle: { width: "25.3%", textAlign: "left" },

        },
   /*      {
            dataField: 'unit',
            text: I18n.t('tmeAdminMaster.unit'),
            sort: true,
            style: { width: "5%"},
            headerStyle: { width: "5%" },

        }, */ {
            dataField: 'benchmark',
            text: I18n.t('Table.benchmark'),
            sort: true,
            style: { width: "11%", wordWrap: "break-word" },
            formatter: this.benchMarkFormatter,
            headerStyle: { width: "10.7%" },

        }, {
            dataField: 'status',
            text: I18n.t('Table.status'),
            sort: true,
            classes: (cell, row, rowIndex, colIndex) => {
                if (cell === "Published") { return 'greenColor'; }
                else if (cell === "Saved") { return 'orangeColor'; }
                else { return 'greyColor'; }
            },
            style: { width: "11%", textAlign: "left" },
            headerStyle: { width: "10.8%", textAlign: "left" },
            formatter: this.statusFormatter

        }, {
            dataField: 'mandatory',
            text: I18n.t('Table.mandatory'),
            sort: true,
            formatter: this.flagFormatter,
            style: { width: "10.7%" },
            headerStyle: { width: "10.5%" },

        }, {
            dataField: 'publishedOnDate',
            text: I18n.t('Table.publishedOn'),
            sort: true,
            style: { width: "12%" },
            headerStyle: { width: "12%" },

        }, {
            dataField: "",
            text: "",
            formatter: this.buttonFormatter,
            style: { width: "6%" },
            headerStyle: { width: "6%" },

        }];
        columns=this.benchmarkTitle(columns);

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox") {
                    e.target.checked = true;
                    var index = this.state.selectedForImport.indexOf(row.masterKpiId);
                    if (index > -1) {
                        this.state.selectedForImport.splice(index, 1);
                    } else {
                        this.state.selectedForImport.push(row.masterKpiId);
                    }
                } else {
                    const selectedRow = { ...this.state.selectedRow };
                    selectedRow['masterKpiId'] = row.masterKpiId;
                    selectedRow['name'] = row.name;
                    selectedRow['definition'] = row.definition;
                    selectedRow['benchmark'] = row.benchmark;
                    selectedRow['status'] = row.status;
                    selectedRow['mandatory'] = row.mandatory;
                    selectedRow['publishedOn'] = row.publishedOn;
                    selectedRow['additionalInfo'] = row.additionalInfo;
                    this.setState({ selectedRow });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            hideSelectAll: true,
            //  hideSelectColumn: this.props.user.userMdl.roles !== undefined && (this.props.user.userMdl.roles.indexOf('NMSC_INTERNAL') !== -1 || this.props.user.userMdl.roles.indexOf('NMSC_FIELD') !== -1 || this.props.user.userMdl.roles.indexOf('NMSC_EXTERNAL') !== -1) ? true : false,
            selected: this.state.selectedForImport,
            nonSelectable: (accessType === 'R/W' ? this.state.selected : this.state.allKpis)
            // onSelect: (row, isSelect, rowIndex, e) => {
            //     this.setState({ selectedForImport: [...this.state.selectedForImport, row.masterKpiId] });
            // }
        };

        return (
            <div>

                <div className="nmscImportTable">
                    <BootstrapTable
                        keyField='masterKpiId'
                        data={this.state.masterKpiList}
                        columns={columns}
                        selectRow={selectRow}
                        hover
                        condensed
                        rowEvents={rowEvents}
                        noDataIndication={I18n.t('tabsDetails.NoKPI')}
                        refs="nmscImportTable"
                    />
                </div>

                <hr />
                {accessType === "R/W" ?
                    <div className="modal-footer" style={{ borderTop: '0px' }}>
                        <button className={"buttonCancel-" + this.props.theme.name} onClick={this.props.toggle}>{I18n.t('tmeAdminMaster.cancel')}</button>
                        <button className={"button-" + this.props.theme.name} onClick={this.importNmsc}>{I18n.t('tabsDetails.import')}</button>
                    </div> : ""}
            </div >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        masterKpiList: state.tabReducer.masterKpiList,
        user: state.loginReducer
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ImportTablePage)));