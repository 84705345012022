import React from 'react';
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter
} from 'mdbreact';
import { withTheme } from '../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import { saveMainQuestion, saveLocalisedMainQuestionnaire } from './../../actions/mainQuestionAction';
import { removeChapterReducer } from './../../actions/questionSummaryAction';
import './index.css';
import { Form } from 'react-bootstrap';
var I18n = require('react-redux-i18n').I18n;

class MainQuestion extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // value: "",
      // selectedRow:{
      //   // seqNum:'',
      //   // name:'',
      //   description:'',
      //   reason:'',
      //   // status:'',
      //   // chapterId:'',
      //   //loginId:this.props.user.userMdl.username
      description: this.props.selectedQuestion ? this.props.selectedQuestion.description : "",
      reason: this.props.selectedQuestion ? this.props.selectedQuestion.reason : "",
      name: this.props.selectedQuestion ? this.props.selectedQuestion.name : "",
      chapterId: this.props.chapterId,
      questionNumber: this.props.questionNumber,
      editQuestionNumber: this.props.editQuestionNumber
    }
    // mainQuestionDto:{
    //   seqNum:'',
    //   name:'abc',
    //   description:'',
    //   reason:'',
    //   status:'',
    //   chapterId:'',
    //   //loginId:''
    //   loginId:this.props.user.userMdl.username
    // }
    //}
  }

  onHandleChange = (e) => {
    // const selectedRow = { ...this.state.selectedRow };
    //   selectedRow["description"] = e.target.value;
    //   this.setState({ selectedRow });
    this.setState({ description: e.target.value });
  }
  onChange = (e) => {
    // const selectedRow = { ...this.state.selectedRow };
    //   selectedRow["reason"] = e.target.value;
    //   this.setState({ selectedRow });
    this.setState({ reason: e.target.value });
  }
  onNameChange = (e) => {
    this.setState({ name: e.target.value });
  }
  saveMainQuestion = e => {

    this.props.dispatch(removeChapterReducer());
    if (!this.state.name || this.state.name === "" || !this.state.description || this.state.description === "") {
      toastr.error(I18n.t("toastr.modalMandatory"));
      return;
    }
    e.preventDefault();

    var isNMSCAdminUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
          isNMSCAdminUser = true;
          break;
        }
      }
    }

    //---------START: Logic for type----------------------------
    // If questionId is zero i.e. add of new question by nmsc means, locally created
    var type = "";
    if (this.props.questionId === 0) {
      type = "LOCAL";
    }
    //If questionId is not zero i.e edit of either i. local question or ii. TME question.
    // So if selected type is tme then else local type
    else {
      if (this.props.selectedQuestion.type === "TME")
        type = "TME";
      else
        type = "LOCAL";
    }
    //---------END: Logic for type----------------------------------

    //Add of NMSC Quesion
    if (isNMSCAdminUser) {
      console.log(this.props.selectedQuestion)
      console.log("Please write Nmsc Code");
      console.log(this.props.questionId);
      var nmscQuestionDTO = {
        nmscChapterId: this.props.nmscChapterId,
        nmscQuestionnaireId: this.props.questionId,
        seqNum: this.props.selectedQuestion ? this.props.selectedQuestion.seqNum : 0,
        name: this.state.name,
        nmscLang: this.props.activeTabNmscLang,
        nmscCode: this.props.user.userMdl.nmsc,
        description: this.state.description,
        reason: this.state.reason,
        chapterId: this.props.chapterId,
        type: type,
        loginId: this.props.user.userMdl.username
      }

      console.log(nmscQuestionDTO);
      //this.props.dispatch(saveLocalisedMainQuestionnaire(nmscQuestionDTO, this.props.questionId, this.props.toolboxId));
      //return;
      // if (this.props.selectedQuestion.type === 'TME') {
      //   this.props.dispatch(saveNmscMainQuestion(nmscQuestionDTO, this.props.questionId, this.props.toolboxId));
      // }
      //This will be called when nmsc localised question will be created
      // else {
      this.props.dispatch(saveLocalisedMainQuestionnaire(nmscQuestionDTO, this.props.questionId, this.props.toolboxId));

      //}

      this.props.addMainQuestion(this.props.modalFor)
    }

    //Add TME QUESTION
    else {
      var mainQuestionDto = {
        //questionId:this.props.selectedQuestion ? this.props.selectedQuestion.questionId : 0,
        questionId: this.props.questionId,
        seqNum: this.props.selectedQuestion ? this.props.selectedQuestion.seqNum : 0,
        name: this.state.name,
        description: this.state.description,
        reason: this.state.reason,
        chapterId: this.props.chapterId,
        loginId: this.props.user.userMdl.username
      }
      this.props.dispatch(saveMainQuestion(mainQuestionDto, this.props.questionId, this.props.toolboxId));
      this.props.addMainQuestion(this.props.modalFor)
    }
  };

  componentWillMount() {
  }

  componentWillReceiveProps(nextProps) {

  }
  preventDefault() { }
  render() {
    return (
      <Container>
        <Modal
          className={"addEditQuestionModal addEditQuestionModal-" + this.props.theme.name}
          id="addEditActivityModal"
          isOpen="true"
          addMainQuestion={() => this.preventDefault()}
        >
          <ModalHeader toggle={() => this.props.addMainQuestion(this.props.modalFor)}>
            {this.props.modalFor === "Add" ? I18n.t("questionnaire.addMainQuestion") : I18n.t("questionnaire.editMainQuestion")}
          </ModalHeader>
          <ModalBody>
            <Form style={{ margin: "40px",wordBreak:"normal" }}>
              <label>
                {this.props.modalFor === "Add" ? (I18n.t("questionnaire.questionTitle")) : (I18n.t("questionnaire.questionTitle") + this.props.editQuestionNumber)}
                {/* {this.props.modalFor === "Add" ?(I18n.t("questionnaire.questionTitle") + this.props.questionNumber) : (I18n.t("questionnaire.questionTitle") + this.props.editQuestionNumber)} */}
              </label>
              <span className="redFont"> *</span>
              <input type="text"
                className="form-control"
                // rows="2"
                maxLength="50"
                //  onChange={e => this.onHandleChange(e, "description")}
                onChange={this.onNameChange}
                value={this.state.name}
              />
              <br />
              <label>
                {I18n.t("questionnaire.question")}
              </label>
              <span className="redFont"> *</span>
              <textarea
                className="form-control"
                rows="3"
                maxLength="500"
                //  onChange={e => this.onHandleChange(e, "description")}
                onChange={this.onHandleChange}
                value={this.state.description}
              />
              {/* </div> */}
              <br />
              <label>
                {I18n.t("questionnaire.why")}
              </label>
              <div>
                <textarea
                  className="form-control"
                  rows="5"
                  maxLength="1000"
                  //  onChange={e => this.onChange(e, "reason")}
                  onChange={this.onChange}
                  value={this.state.reason}
                />
              </div>
              <br />
            </Form>
          </ModalBody>
          <ModalFooter>
            <button
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => this.props.addMainQuestion(this.props.modalFor)}
            >
              {I18n.t("tmeAdminMaster.cancel")}
            </button>
            {this.props.modalFor === "Add" ? (
              <button
                className={"button-" + this.props.theme.name}
                onClick={this.saveMainQuestion}
              >
                {I18n.t("tmeAdminMaster.save")}
              </button>
            ) : (
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={this.saveMainQuestion}
                >
                  {I18n.t("tmeAdminMaster.update")}
                </button>
              )}
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(MainQuestion)));
