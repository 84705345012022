import axios from 'axios';

import { toastr } from 'react-redux-toastr';
// import fileSaver from 'file-saver';

export function downloadCreateActivityTemplate(downloadDto) {


    return function (dispatch) {
      //   dispatch(setBlockingState(true));
      axios
        .post("/bulkActivities/download" , downloadDto, {
          headers:
          {
            'Content-type': 'application/json',
            'Accept': 'application/json',
            'Pragma': 'no-cache'
          }
        })
        .then(function (response) {
          // toastr.success(I18n.t('toastr.uploadSuccess'));
          // toastr.success("Success", response.data);
          toastr.success(response.data);
  
          dispatch({
            type: "BULK_DOWNLOAD_ACTIVITY_DATA",
            payload: {isUploaded:true}
          })
  
           //dispatch(getBulkDownloadList(downloadDto));
  
        })
        .catch(function (err) {
          // toastr.error(I18n.t('toastr.upload_error'));
          console.log(err);
          //dispatch(getBulkDownloadList(downloadDto));
  
        });
    };
  }
// 