const translationsTR = {
  tr: {
    Policy: {
      HeaderMsg: "Kişisel verilerinizin Kaizen Uzman Aracı çerçevesinde işlenmesi için gizlilik uyarısı",
      Message1: "Bu Gizlilik Bildirimi, Kişisel Verilerinizin Kaizen Uzman Aracı çerçevesinde işlenmesiyle ilgilidir. Kişisel Verilerinizin işlenmesinin genel yöntemlerinin tanımlandığı ve devam edebilmek için kabul etmeniz gereken Genel Toyota Gizlilik ve Kişisel Verileri Koruma Politikası'nın daima okunması gerekir.",
      Message2: "Avenue du Bourget / Bourgetlaan 60, 1140 Brüksel, Belçika'daki merkez ofis Toyota Motor Europe NV / SA, veri merkezi olarak Kişisel Verilerinizi (aşağıda belirtildiği gibi) aşağıda açıklanan amaçlar için toplar.",
      Message3: "Hangi kişisel veri toplanacak?",
      Message4: "Kişisel Verilerinizin aşağıdaki kategori/lerini Kaizen Uzman Aracı'na göre toplayacağız.",
      Table1: {
        tr1: "Kişisel veri Kategori/leri",
        tr2: "Kullanıcı Numarası",
        tr3: "Kullanıcı Adı ",
        tr4: "Marka (Toyota, Lexus)",
        tr5: "Tercih edilen dil",
        tr6: "Bağlı kuruluş"
      },

      Message5: "Kişisel verilerinizin işlenmesinin amacı ve yasal dayanağı.",
      Message6: "Kişisel Verilerinizi aşağıda belirtilen amaçlar ve yasal dayanaklar doğrultusunda işleyeceğiz:",
      Table2: {
        tr1: {
          th1: "Amaç",
          th2: "Yasal dayanak"
        },
        tr2: {
          td1: "Satıcı Performans Kaizen'i",
          td2: "Meşru menfaatlerin veri merkezi tarafından takibi"
        },
        tr3: {
          td1: "Bilgi İşlem Desteği",
          td2: "Meşru menfaatlerin veri merkezi tarafından takibi"
        }
      },
      Message7: "Kişisel verilerin saklanma süresi",
      Message8: "Kişisel verileriniz aşağıda belirtilen süre boyunca saklanacaktır:",
      Table3: {
        tr1: {
          th1: "Saklama süresi",
          th2: "Kişisel veri Kategori/leri"
        },
        tr2: {
          td1: "Yukarıda açıklanan kişisel bilgiler, Kaizen Uzman Aracı'nın son kapanış gününden itibaren en fazla 5 yıl + 1 Ay süreyle saklanacaktır.",
          td2: "Kullanıcı adı, Soyisim, Dil tercihi, organizasyon"
        }
      },
      Message9: "İsteğe Bağlı: Kişisel verilerinizin Avrupa Ekonomik Bölgesi (AEB) dışındaki ülkelere ifşa edilmesi / aktarılması",
      Message10: "Kişisel Verileriniz, aşağıda belirtildiği şekilde AEB dışındaki ülkelerde alıcı için erişilebilir olacaktır:",
      Table4: {
        tr1: {
          td1: "Tanımla",
          td2: "Ülke",
          td3: ""
        },
        tr2: {
          td1: "Avrupa Ekonomik Bölgesi Dışı",
          td2: "Sistem bilgisi",
          td3: "Hindistan"
        }
      },
      Message11: "Uyarı anladım, okudum, Kişisel Verileri Koruma Kanununa ve Genel Toyota Gizliliğine uygun davranmayı kabul ediyorum"
    },
    ACCESSORIES: "Aksesuar",
    activity: {
      Assignee: "Atanan Kişi",
      CompletionDate: "Tamamlanma tarihi",
      Dealer: "Bayi",
      FirstVisit: "İlk Ziyaret",
      kpi: {
        kpi: "KPI rakamlardan oluşmalıdır",
        kpiLength: "KPI için en fazla 10 karakter girilebilir",
        mandatory: "Lütfen tüm zorunlu alanlara geçerli veriler girin",
        mandatoryTargets: "Lütfen tüm KPI'ları giriniz.",
        target: "Hedef sadece rakamlardan oluşmalıdır",
        targetLength: "Hedef için en fazla 10 karakter girilebilir"
      },
      ReminderOn: "Hatırlatma Açık",
      statusDD: "Durum",
      Toolbox: "İş Modeli",
      activityCreation: "Aktivite Oluşturma işlemi başlamıştır.",
      activityCreated: "Aktivite Oluşturulmuştur."
    },
    DPOK: "Yedek Parça Operasyonu",
    EFFICIENCY: "Verimlilik",
    FIX_IT_RIGHT: "FIR",
    footer: {
      message: "Zorunlu Alanlar"
    },
    FOUNDATION: "Temel Kriterler",
    MAINTENANCE_REMINDERS: "Bakım Hatırlatma",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Temeller",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Nisan",
      aug: "Ağustos",
      dec: "Aralık",
      feb: "Şubat",
      jan: "Ocak",
      jul: "Temmuz",
      jun: "Haziran",
      mar: "Mart",
      may: "Mayıs",
      nov: "Kasım",
      oct: "Ekim",
      sep: "Eylül"
    },
    others: {
      back: "Geri",
      closed: "Kapandı",
      completed: "Tamamlandı",
      countryLanguageCode: "Ülke / Dil Kodu ",
      createActivity: "Etkinlik Oluştur",
      createdDate: "Oluşturulan Tarih",
      dataUneditableNote:
        "Not:  Kayıt edilen veri değiştirilemez Veriyi değiştirmek için bölge müdürünüz ile irtibata geçiniz",
      date: "Tarih",
      downloadQuestionnaire: "Anketi İndir",
      editActivity: "Etkinlik Düzenle",
      initialHelpText: "İlk KPI ölçümü, önceki altı ayın tamamının ortalama değeri olmalıdır",
      inProgress: "Sürüyor",
      name: "İsim  ",
      next: "İleri",
      noActivities: "Etkinlik bulunamadı",
      noResults: "Sonuç yok",
      of: "/",
      ongoing: "Devam Ediyor",
      open: "Aç",
      page: "Sayfa",
      planned: "Planlandı",
      problemDescription: "Sorun açıklaması",
      signature: "İmza",
      select: "Seç",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "İndirme sırasında bir hata oluştu.",
      no_published_subquestion: "Aktiviteye başlamak için anketi yayınlayınız.",
      activity_upload_error_msg: "Aktivite detaylarını yüklerken hata oluştu.",
      action_plan_created: "Aksiyon Planı başarıyla oluşturuldu",
      action_plan_deleted: "Aksiyon Planı başarıyla silindi",
      action_plan_saved: "Aksiyon Planı başarıyla katdedildi",
      activity_kpi_saved: "Aktivite KPIları başarıyla kaydedildi",
      master_kpi_confirm_delete:
        "Seçilen KPI'ı silmek istediğinizden emin misiniz?",
      master_kpi_created: "Ana KPI Başarı ile Oluşturuldu",
      master_kpi_deleted: "Ana KPI Silindi",
      master_kpi_not_found: "Ana KPI Bulunamadı",
      master_kpi_published: "Seçilen Ana KPI Başarı ile Yayınlandı",
      master_kpi_retired: "Ana KPI İptal Edildi",
      master_kpi_saved: "Ana KPI Başarı ile Kayıt Edildi",
      subquestion_created: "Soru kaydedildi",
      subquestion_updated: "Soru güncellendi",
      subquestionnaire_not_found: "Soru bulunamadı",
      subquestion_retired: "Soru kaldırıldı",
      subquestion_deleted: "Soru silindi",
      subquestion_published: "Sorular yayınlandı",
      mainquestion_created: "Ana Sorular oluşturuldu",
      mainquestion_deleted: "Ana Sorular silindi",
      mainquestion_updated: "Ana soru güncellendi",
      duplicateMsg: "Mükerrer kayıt",
      deleteErr: "Dosya silme esnasında hata",
      toolbox_deleted: "İş Modeli başarıyla silinmiştir.",
      toolbox_already_exists: "Bu İş Modeli zaten var",
      toolbox_saved: "İş Modeli kaydedildi.",
      toolbox_created: "İş Modeli oluşturuldu.",
      toolbox_not_found: "İş Modeli bulunamadı.",
      published_content_found: "Yayınlanan öğeler olduğundan bu İş Modeli silinemez.",
      dealers_already_mapped: " Seçilen bayiler bu kullanıcı için zaten eşleştirildi. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Bayi eklendi. ",
      dealers_added: "  Bayi eklendi. ",
      total_dealers_mapped_now: "Şu anda bu kullanıcıyla eşlenmiş toplam bayi sayısı. ",
      no_dealer_available: "Seçilen kriterler için uygun bayi (ler) yok.",
      proper_dealer_selected: "Lütfen uygun bayi seçiniz.",
      user_referenced_ongoing_activity: "Kullanıcı şu anda devam eden aktivitelere atanmıştır. Lütfen bu Aktivitelerde Yetkili Kişiyi değiştirin ve tekrar deneyin. ",
      want_to_remove_mapping: "Bayi için kullanıcı detaylarını silmek istediğinizden emin misiniz ?",
      user_mapping_removed: "Seçilen bayiler, kullanıcının haritasından başarıyla çıkarıldı.",
      dealer_closed_in_A2D: "Bayi A2D'de \"Kapalı\" olarak kaydedildi..",
      network_level_not_found: "Lütfen KET ağ kurulumunuza göre geçerli bir ağ seviyeleri kombinasyonu seçin.",
      duplicate_dealer_uuid: "KET veritabanında bayi zaten mevcut",
      dealer_created: "Bayi Oluşturuldu",
      dealer_updated: "Bu bayinin özellikleri güncellendi.",
      dealer_not_present: "Bu bayi A2D'de \"Kapalı\" olarak kayıtlıdır.",
      dealer_uuid_other_nmsc: "Seçilen A2D uuid kodu ağınızda kullanılamıyor.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Silmek istediğinize emin misiniz?",
      addActionPlanNotAllowed: "Aksiyon planı kapandıktan, tamamlandıktan veya reddedildikten sonra değiştirilemez.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Aktiviteyi reddetmekten emin misiniz?  Daha sonra değişiklik mümkün olmayacaktır.",
      rejectMsg: "Aktiviteyi reddetmekten emin misiniz?  Daha sonra değişiklik mümkün olmayacaktır.",
      selectNMSCTab: "Lütfen yayınlamak için ilgili dili seçiniz.",
      all: "Tümü",
      deleteConfirm: "Seçili KPI'ı silmek istediğinizden emin misiniz?",
      no: "Нayır",
      retireConfirm: "Bu KPI'ı iptal etmek istediğinizden emin misiniz? Bu KPI iptal edildikten sonra tekrar etkinleştirilemez",
      warning: "Uyarı",
      yes: "Evet",
      saveDataConfirmation: "Veri kayıt edilecek, emin misiniz?",
      saveKPIWarning: "Not: Veriler kaydedildikten sonra düzenlenemez. Veriyi değiştirmek için lütfen NMSC KET yöneticisine başvurun",
      addKpiNotAllowed: "Gelecek ayın KPI'ları mevcut ay  bittikten sonra girilebilir.",
      activityLaunch: "Bu aktiviteyi yayınlamak istediğinize eminmisiniz? Yayılanmanın ardından, aktivite tekrar planlı statüsüne getirilemez veya silinemez.",
      deleteConfirmSubQuestionNaire: "Seçili  soruyu silmek istediğinize emin misiniz?",
      retireConfirmSubQuestionNaire: "Soruyu kaldırmak istediğinize emin misiniz?",
      deleteConfirmMainQuestion: "Ana soru ve soruları silme isteğinizi onaylayınız.",
      deleteConfirmChapter: "Bu bölümü silmek istediğinize emin misiniz?",
      deleteLibraryFolder: "Bu klasörü silmek istediğinize emin misiniz?",
      deletePublishedFolder: "Bazı dosyalar sorular ilişkilendirilmiş olabilir. Soruları silmeye ve bağlantıları kaldırmaya emin misiniz?",
      importQuestion: "Soruları içe aktarmak istediğinize emin misiniz? Güncel sorular silinecektir.",
      noQuestion: "Uygun soru yok.",
      MandatoryWarning: "Lütfen zorunlu tüm alanları giriniz.",
      certificationSubmission:
        "İş Modeli sertifika onayı için TME'ye gönderilmiştir.",
      sameToolBox: "Bir İş Modeli'ni birden fazla onaylayamazsınız. Lütfen farklı İş Modeli seçiniz.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Bu bayi için 'Değer zincirinde' oluşturulan tüm Faaliyetler kaldırılacaktır. Devam etmek istediğine emin misin?",
      updateBritConfirmDealer: "Bu bayi için 'BRiT'de oluşturulan tüm Etkinlikler kaldırılacaktır. Devam etmek istediğine emin misin?",
      completed_activity_delete:"Veriler silindikten sonra geri alınamaz. Silmek istediğine emin misin ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "Bu bayi için 'EA'de oluşturulan tüm Etkinlikler kaldırılacaktır. Devam etmek istediğine emin misin?"

    },
    status: {
      published: "Yayınlandı",
      retired: "İptal Edildi",
      saved: "Kayıt Edildi",
      rejected: "Reddedildi",
      open: "Aç",
      closed: "Kapandı",
      close: "Kapandı",
      ongoing: "Devam Ediyor",
      completed: "Tamamlandı",
      complete: "Tamamlandı",
      planned: "Planlandı",
      overdue: "geçmiş",
      due: "kadar",
      pending: "Pending",

    },
    Rejected: "Reddedildi",
    Open: "Aç",
    Closed: "Kapandı",
    Close: "Kapandı",
    Ongoing: "Devam Ediyor",
    Completed: "Tamamlandı",
    Complete: "Tamamlandı",
    Planned: "Planlandı",
    tabDetails: {
      noActionAvailable: "Aksiyon yok",
      activeDealer: "Bu bayi şu anda A2D'de Aktif olarak kayıtlı ve silinemez.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivite",
      activityCompletionDate: "Aktivite Tamamlanma Tarihi",
      activityCompletionDateFrom: "Aktivite Tamamlanma Tarihi",
      activityCompletionDateTo: "Aktivite Tamamlanma Tarihi",
      activityStartDate: "Aktivite Başlangıç Tarihi",
      activityStartDateFrom: "Aktivite Başlangıç Tarihi",
      activityStartDateTo: "Aktivite Başlangıç Tarihi",
      assignedTo: "Atanan Kişi",
      assignee: "Atanan Kişi",
      benchmark: "En İyi",
      city: "Şehir",
      completedBy: "Tamamlayan Kişi ",
      completionBy: "Tarafından tamamlandı",
      completionDate: "Tamamlanma tarihi",
      counterMeasure: "Karşı Önlem",
      country: "Ülke",
      createdBy: "Oluşturan Kişi",
      dealer: "Bayi",
      dealerNameAD: "Bayi Adı",
      dealerName: "Bayi Adı",
      definition: "Tanım",
      description: "Açıklama",
      dueDate: "Tamamlanması Gereken Tarih",
      endDate: "Bitiş Tarihi",
      firstVisit: "İlk Ziyaret",
      followup: "Takip",
      franchise: "Temsilci",
      history: "Geçmiş",
      id: "Kimlik",
      initial: "Başlangıç",
      kpi: "KPI",
      launch: "Devreye Al",
      mandatory: "Zorunlu",
      nmsc: "Distribütör",
      problem: "Problem",
      publishedOn: "Yayınlandı",
      questionReference: "Soru Referansı",
      reference: "Referans",
      reminderOn: "Hatırlatma Açık",
      score: "Skor",
      startDate: "Başlangıç Tarihi",
      status: "Durum",
      target: "Target",
      toolbox: "İş Modeli",
      view: "Görünüm",
      kpiResult: "KPI sonuçları",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Yer",
      ActionItemReport: "Devam Eden Aksiyon Maddeleri",
      certification: "Sertifika Durumu",
      noOfToolbox: "Tamamlanmış İş Modeli No",
      program: "Program",
      certificationDate: "Sertifika Tarihi",
      activityId : 'Aktivite ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Aksiyon Planı",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Aksiyon Planı",
      confirmDeleteMessage: "Seçili KPI'ı silmek istediğinizden emin misiniz?",
      confirmRetireMessage: "Daha Önce Yayınlanmış Olan KPI'ı İptal Etmek İstediğinizden Emin Misiniz?",
      errorRetireMsg: "Aktif ve TME tarafından zorunlu tutulmuş bir KPI olduğu için bu KPI iptal edilemiyor",
      import: "Aktar",
      importKpiMessage: "Lütfen Ana KPI Listesinden KPI Aktarınız ",
      kpi: "Temel Performans Göstergeleri (KPI)",
      library: "Kütüphane",
      noActionAvailable: "Aksiyon yok",
      NoKPI: "KPI Mevcut Değil",
      publish: "Yayınla",
      questionnaire: "Anket",
      tmeKPIList: "Toyota Avrupa Ana KPI Listesi",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "İş Modeli adı",
      newToolboxName: "Yeni ad",
      deleteToolbox: "İş Modelini sil?",
      programName: "Program adı",
      ToolboxName_EN: "İş Modeli adı",
      deleteTool: "İş Modelini silmek istediğinize emin misiniz?",
      selectToolbox: "Silinecek iş modelini seçiniz.",
      editToolBox: "İş Modelini düzenle",
      addToolbox: "İş Modeli ekle",
      toolbox: "İş Modeli için isim giriniz",
      addActionPlan: "Ekle Aksiyon Planı",
      actionPlanItem: "Aksiyon Planı - Madde #",
      add: "Ekle",
      addActivity: "Aktivite Ekle",
      additionalInformation: "Ek Bilgi",
      benchmark: "En İyi",
      cancel: "İptal",
      createEditActivity: "Aktivite Oluştur / Değiştir",
      createKPI: "KPI Oluştur",
      delete: "Sil",
      download: "İndir",
      edit: "Değiştir",
      editKPI: "KPI Değişiklik Yap",
      keyPerformanceIndicator: "KPI",
      kpiDefinition: "KPI Açıklaması",
      mandatoryKpi: "Zorunlu KPI",
      save: "Kayıt",
      search: "Arama",
      unit: "Birim",
      update: "Güncelle",
      validData: "Lütfen Uygun Veri Giriniz",
      hide: "Sakla",
      show: "Göster",
      upload_dealer_network: "Bayi ağı yükle",
      upload_question: "Soruları yükle",
      upload_user: "Kullanıcı detaylarını yükle",
      upload_activity: "Aktiviteleri yükle",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Aktiviteyi kapatmak için tüm soruları cevaplayınız.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Lütfen devam etmek ilk ve hedef KPI verisini giriniz.",
      upload_error: "Bayi ağı yüklerken hata oluştu.",
      action_item_create_warning: "Maksimum 5 kayıda izin veriliyor.",
      actionPlanStartDateValidation: "Bitiş Tarihi, Başlangıç Tarihinden önce olamaz",
      actionsNotClosed: "Aksiyon planında devam eden eylemler var, lütfen bir etkinliği kapatmadan önce tüm eylemleri kapatın",
      activityDuplicate: "Aynı Bayi ve İş Modeli için zaten başka bir Etkinlik var",
      assigneeNotExist: "Seçilen Atanacak kişi, sisteme kaydedilmemiş",
      assigneeValidation: "Geçersiz Atanacak Kişi",
      atleastOnePublish: "Lütfen yayınlamak için en az bir KPI seçin",
      cannotRetireActive: "Aktif ve TME tarafından zorunlu tutulmuş bir KPI'dır, iptal edilemez ",
      completionDate: "Tamamlanma tarihi ilk ziyaret tarihinden önce olamaz",
      confirDeletePlannedActivity: "Bu planlanmış etkinliği silmek istediğinizden emin misiniz?",
      DateformatValidation: "Tarih GG/AA/YYYY şeklinde olmalıdır.",
      DateValidation: "Başlangıç tarihi bitiş tarihinden önce olmalıdır.",
      dealerValidation: "Geçersiz Bayi",
      deleteSelectedConfirm: "Seçili KPI'ı silmek istediğinizden emin misiniz?",
      duplicate: "Aynı ad ve açıklamaya sahip başka bir KPI zaten var",
      modalMandatory: "Lütfen tüm zorunlu alanlara geçerli veriler girin",
      noPublishKpi: "KPI'lar henüz seçili Araç Kutusu için yayınlanmadı",
      validSearchCriteria: "Lütfen geçerli bir arama kriteri girin",
      multiSelectPlaceHolder: "En az 5 karakter giriniz",
      multiSelectPlaceHolderUser: "En az 3 karakter giriniz",
      startDateValidation: "Başlangıç tarihi bitiş tarihinden büyük olamaz.",
      fileSelectionErr: "Lütfen önce dosya listesini seçiniz",
      atleastOneQuestion: "Lütfen içe aktarmak için en az bir soru seçin.",
      atleastOneSubQues: "Yayınlamak için en az bir soru seçiniz.",
      uploadErr: "Yükle esnasında hata oluşan dosya sayısı :",
      uploadSuccess: "Bayi ağı sorunsuz yüklendi",
      file_upload_success: "Dosyalar sorunsuz yüklendi",
      KPIinOngoingClosedActivity: "Bu KPI bir veya daha fazla Devam Eden/Kapalı aktivitede yer almaktadır. Lütfen değişiklik yapmadan önce bu aktiviteleri kapatınız.",
      Role_Error: "Kullanıcı kurulumu eksik, lütfen NMSC KET Yöneticisi ile iletişime geçin.",
      points_allocation_incorrect: "Yanlış puan tahsisi, lütfen değiştirin.",
      activity_deleted:"Aktivite silinmiştir.",
      activity_certified_dealer:"Bayi, seçilen aktivite için sertifikalandırılmıştır, silinemez.",
      activity_submited_for_certificate_dealer:"Bu aktivite TME'ye gönderilen Sertifikasyon talebine dahil edilmiştir, bu nedenle durum değiştirilemez."
    },
    TopNav: {
      ActivityLog: "Aktiviteler",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Ana Sayfa",
      logout: "Çıkış",
      MasterDataUpload: "Ana Verileri Karşıya Yükle",
      Toolbox: "İş Modelleri",
      welcome: "Hoşgeldiniz",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: " Soru Ekle",
      editSubQuestion: " Soru Düzenle",
      subQuestionTitle: " Soru",
      additionalInfo: "Ek Bilgi",
      na: "Bu soru için 'Uygun Değil' cevabı ekle",
      question: "Soru",
      questionTitle: "Soru Başlığı",
      why: "Neden ?",
      addMainQuestion: "Ana Soru Ekle",
      editMainQuestion: "Ana Soru Düzenle",
      previousQuestion: "Önceki Soru",
      nextQuestion: "Sonraki Soru",
      what: "Ne ?",
      library: "Kütüphane",
      subQuestionTable: {
        no: "Нayır",
        subQuestion: "Alt Soru",
        status: "Durum",
        publishedDate: "Yayınlanma Tarihi",
        notApplicable: "Uygun değil"
      },
      links: {
        question_link_button: "Bağlantı",
        question_link_include_folder: "Alt klasör ekle",
        question_link_add_link: "Kütüphaneden dosya bağla",
        question_link_view_folder: "Kütüphaneyi gör"
      },
      mainQuestion: "Yeni / Güncel Ana Soru :",
      subQuestion: "Yeni / Güncel  Soru :",
      retiredQuestion: "Eski sorular",
      libraries: "Yeni / Güncel dosyalar",
      noFiles: "Uygun dosya yok."
    },
    library: {
      selectFile: "Lütfen en az bir dosya seçiniz.",
      fileSelected: "Seçilen dosya",
      publish_library_file: "Lütfen en az bir dosya seçiniz.",
      library_files_deleted: "Dosyayı silmek istediğinizden emin misiniz?",
      library_files_linked_deleted: "Dosyayı ve bağlantıları silmek için emin misiniz?",
      max_size_error: "Dosyaların boyutu 1 GB limitin üzerinde.",
      empty_file_desc: "Lütfen dosya bilgisi giriniz.",
      file_not_selected: "Lütfen yüklenecek dosyaları seçiniz. ( En fazla 1 GB)",
      file_type_error: "Dosya biçimi uygun değil",
      file_name_error: "Dosya adı için uygun olmayan karakter",
      file_name_lenght_error: "Dosya adı en fazla 50 karakter olabilir",
      addFolder: "Klasör Ekle",
      editFolder: "Klasör Düzenle",
      currentFolder: "Klasör Adı",
      parentFolder: "Ana Klasör Adı",
      emptyFolderName: "Klasör Adı boş olamaz",
      invalidFolderName: "Klasör isminde " / " sembolü yer alamaz.",
      folder_created: "Klasör Adı oluşturuldu",
      folder_updated: "Klasör Adı düzenlendi",
      folder_not_found: "Klasör bulunamadı",
      lib_invalid_toolbox: "Hatalı işmodeli seçildi",
      lib_folder_already_exists: "Klasör daha önceden oluşturuldu",
      lib_folder_deleted: "Klasör silindi",
      library_files_published: "Dosya yayımlandı",
      addFile: "Dosya ekle",
      editFile: "Dosya düzenle",
      fileName: "Dosya adı",
      upload: "Yükle",
      desc: "Açıklama",
      folderCreationErr: "İki seviye limitli",
      subFolderWarning: "Dosya eklemek içn alt klasör seçiniz.",
      deleteWarning: "Silmek için klasör seçiniz.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Bölüm daha önceden oluşturuldu",
      chapter_saved: "Bölüm kaydedildi",
      chapter_cannot_delete: "Sorular yayımlandığı için bu bölüm silinemiyor.",
      failed: "Hata !",
      chapter_deleted: "Bölüm silindi.",
      chapter_nonEmpty: "Bölüm adı oluşturunuz.",
      addchapter: "Bölüm ekle",
      editChapter: "Bölümü düzenle.",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Ek",
      comments: "Yorumlar",
      evidence: "Kaynak",
      evidences: "Kaynaklar",
      yes: "Evet",
      no: "Нayır",
      na: "Uygun Değil",
      uploadEvidence: "Kaynak yükle",
      fileUpload: "Lütfen yüklenecek dosyaları seçiniz. ( En fazla 1 GB)",
      who: "Kim ?",
      answer: "Cevap",
      comment: "Yorumlar",
      date: "Tarih",
      submit: "Kabul",
      commentMsg: "Yorumları ekleyiniz.",
      noCommentAvailable: "Yorum yok.",
      uploadSuccessMsg: "Dosyalar sorunsuz yüklendi",
      uploadErrorMsg: "Yükle esnasında hata oluşan dosya sayısı :",
      fileWithSameName: "Aynı isimli dosya zaten mevcut.",
      noEvidence: "Kanıt mevcut değil.",
      createAction: "Aksiyon maddesi oluştur",
      chapterName: "Bölüm Adı",
      questionNo: "Soru no",
      when: "Ne zaman?",
      noRecords: "Kayıt yok",
      kaizenPoints: "Kaizen Puanı",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Soru",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Ön Kontrol",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Kaporta & Boya",
      TSM_USED_CAR: "2. El Araçlar",
      value_chain: "Değer Zinciri ( Value Chain )",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Duyuru Panosu",
      kpi_target_not_set: "KPI hedefleri belirlenmemiş.",
      kpi_initial_not_set: "KPI ilk değerleri belirlenmemiş.",
      kpi_monthly_not_set: "KPI aylık değerler belirlenmemiş.",
      kpi_below_target: "KPI hedefi mevcuttan düşük.",
      activation: "Aktivasyon",
      users: "Kullanıcılar",
      nmscs: "NMSC's",
      activeRetailer: "Aktif Bayiler",
      certificationRate: "Sertifikasyon Oranı",
      reCertificationRate: "Tekrar Sertifikasyon Oranı",
      activityDue: "Aktivite tarihi",
      avgdays: "Ortalama Gün/iş Modeli",
      overDueAct: "Geçmiş Aktiviteler",
      actionItem: "Aksiyon Maddeleri",
      bussResult: "Sonuçlar",
      toolboxImpl: "İş Modeli Geliştirmesi",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Güncellemeler",
      rolloutTopNmsc: "Tüm Sonuçlar - En iyi NMSC'ler",
      bestPractice: "Best Practice Panosu",
      availableKpi: "Uygun KPI'lar",
      selectedKpi: "Seçilmiş KPI'lar",
      dealerBestPractice: "Bayi BP tablosu",
      kpiList: "KPI Listesi",
      go: "git",
      nodata: "Uygun veri yok",
      businessResultTop: "İş Sonuçları - En iyi bayiler",
      businessResultNMSC: "Sonuçlar - En iyi NMSC'ler"


    },
    dealerCertification: {
      dealerCertification: "Bayi sertifikasyonu",
      readyForCertification: "Sertifiasyona Hazır",
      certification: "Sertifikasyon",
      submittedOn: "Onaylanmış",
      certificateDate: "Sertifika Tarihi",
      reCertificateDate: "Tekrar Sertifikasyon Tarihi",
      toolboxDetails: "İş Modeli Detayları",
      approve: "Onay",
      reject: "Red",
      pending: "Bekliyor",
      region: "bölge"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Yürütme kimliği",
      uploadedOn: "Güncellenme",
      lastUpdatedOn: "Son güncelleme tarihi",
      noDataTable: "No data to view",
      kpiUpload: "KPI Yükleme",
      action: "Aksiyon",
      processed: "İşlendi",
      error: "Hata",
      received: "Received",
      createActivity: "Aktivite yarat.",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Kullanıcı yönetimi",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Adı",
      lastName: "Soyadı",
      emailId: "E-Posta",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Hesabı",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "İş Alanı",
      valueChainAndBRiT: "Değer Zinciri & BRIT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Değer Zinciri sadece",
      britOnly: "BRIT sadece",
      valueChainAndEA: "Değer Zinciri & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA sadece",
      valueChainAndBRiTAndEA: "Değer Zinciri & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Adı",
      lastName: "Soyadı",
      emailId: "E-Posta",
      languageCode: "Dil kodu",
      ACTIVE: "Active",
      INACTIVE: "Pasif",
      addUser: "Kullanıcı ekle",
      Assign: "Giriş",
      List: "Liste",
      user: "Kullanıcı",
      error_message: "Bir hata oluştu lütfen tekrar deneyiniz.",
      role: "Kullanıcı rolü",
      standardMessage: "No User Found",
      dealerCode: "Bayi Kodu",
      a2d: "A2D UUID",
      britReady: "Bayi BRIT READY'de",
      britLive: "Bayi BRIT LIVE'de",
      britJapan: "Bayi BRIT JAPONYA keşif'de",
      britEss:"Bayi EES Aksiyon Planını güncelledi.",
      addDealer: "Bayi ekle",
      noDealer: "Bayi bulunamadı",
      dealerUuid: "Bayi UUID kodu",
      language: "Dil",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Lütfen geçerli A2D uuid giriniz.",
      invalidDealerName: "Lütfen geçerli bayi adı giriniz.",
      invalidCity: "Lütfen geçerli lokasyon giriniz.",
      invaliDealerCode: "Lütfen geçerli bayi kodu giriniz.",
      existingUuid: "A2D uuid kodu KET veritabanında zaten mevcut.",
      updateUuid: "A2D uuid kodunu güncelle",
      newUuid: "Yeni A2D uuid kodu",
    },
    networkManagement: {
      networkManagement: "Ağ yönetimi",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsTR;
