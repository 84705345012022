import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { Container, Col, Fa } from "mdbreact";
import { withTheme } from '../../themes/theming';
import { withRouter } from "react-router-dom";
import SearchActivity from "../searchActivity";
import ActivityModal from "../activityModal";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { ProgressBar } from "react-bootstrap";
import "./index.css";
import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { searchActivity, searchEmptyActivityList, deleteActivity } from "../../actions/activityAction.jsx";
import { getUserDetails } from "../../actions/loginAction";
import LaunchTargetScreen from '../launchTargetScreen/index'
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery')
window.jQuery = $;

class Activity extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      modal: false,
      modalFor: "",
      showSearchPanel: true,
      activityDto: {
        nmscCode: null,
        loggedInUser: this.props.user.userMdl.username,
        toolboxId: null,
        userId: null,
        status: null,
        // auditStatus:null,
        score: null,
        activityId: 0,
        loginId: null,
        dealerId: null,
        langCode: null,
        rows: 10,
        page: 1,
        programID: this.props.user.userMdl.activeProgramID,
        program: this.props.user.userMdl.activeProgram,
        businessArea: this.props.user.userMdl.businessAreaKey ==="EA" ? "VALUE_CHAIN,BRiT" : this.props.user.userMdl.businessAreaKey

      },
      data: [],
      page: 1,
      sizePerPage: 10
    };
  }
  componentWillMount() {

    if (
      this.props.user.userMdl === undefined ||
      this.props.user.userMdl === null ||
      this.props.user.userMdl.expired === undefined ||
      this.props.user.userMdl.expired
    ) {
      // get user Details from token.
      // console.log("component will mount")
      this.props.dispatch(getUserDetails());
    }
    var isNMSCAdminUser = false; // eslint-disable-line
    var isTMEAdminUser = false;
    var isNotAdmin = false; // eslint-disable-line
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN"|| roles[i].toUpperCase() === "KET-EA ADMIN"|| roles[i] === "KET-TSP Auditor" || this.props.user.userMdl.businessAreaKey==="TSP") {
          isTMEAdminUser = true;
          break;
        }
        isNotAdmin = true;

      }
    }
    if (isTMEAdminUser) {
      this.state.activityDto["type"] = "TME";
    }
    else {
      this.state.activityDto["type"] = "NMSC";
      this.state.activityDto["langCode"] = this.props.user.userMdl.lang;
      this.state.activityDto["nmscCode"] = this.props.user.userMdl.nmsc;
    }

    let searchDTO = this.state.activityDto;
    if (this.props.location.query !== undefined && this.props.location.query.activityDto !== undefined) {
      searchDTO = this.props.location.query.activityDto;
      this.setState({ activityDto: this.props.location.query.activityDto });
    }
    this.props.dispatch(searchActivity(searchDTO));
  }

  componentWillUnmount() {

    this.setState({
      data: []
    });
    this.props.dispatch(searchEmptyActivityList());
  }

  selectedTab = (index, label) => {
    this.setState({ selectedTab: index });
  };
  toggleSearch = toggle => {
    this.setState({
      showSearchFlag: !this.state.showSearchFlag
    });
  };
  toggleModal = action => {
    this.setState({
      modal: !this.state.modal,
      modalFor: action,
      selectedRow: {
        activityId: "",
        dealerId: "",
        assigneeId: "",
        toolbox: "",
        toolboxId: "",
        firstVisit: "",
        completionOn: "",
        status: "",
        // auditStatus: "",
        businessArea :null,
        userMdl: this.props.user.userMdl,
        reminderOn: "",
        loginId: this.props.user.userMdl.username
      }
    });
    if (action === "Create") {
      const selectedRow = { ...this.state.selectedRow };
      selectedRow["activityId"] = 0;
      selectedRow["toolboxId"] = "";
      selectedRow["firstVisit"] = "";
      selectedRow["completionOn"] = "";
      selectedRow["status"] = "Planned";
      // selectedRow["auditStatus"] = "";
      selectedRow["userId"] = "";
      selectedRow["reminderOn"] = "";
      selectedRow["dealerId"] = "";
      selectedRow["score"] = "";
      selectedRow["userMdl"] = this.props.user.userMdl;
      selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
      selectedRow["langCode"] = this.props.user.userMdl.lang;
      selectedRow["loginId"] = this.props.user.userMdl.username;
      selectedRow["businessAreaKey"] = this.props.user.userMdl.activeProgram=="TSP"?"TSP":this.props.user.userMdl.activeProgram;
      this.setState({ selectedRow });
    }
  };

  dispatchActivity = row => {

       console.log(row)
	   console.log(row.subQuePublished)
       console.log(row.status)
       console.log(this.props.user.userMdl.activeProgram)
    if (!row.subQuePublished && row.status === "Planned" && (this.props.user.userMdl.activeProgram === "TSM" || this.props.user.userMdl.activeProgram === "TSM_BNP" || this.props.user.userMdl.activeProgram === "TSM_USED_CAR" || this.props.user.userMdl.activeProgram === "TSM_SURVEYS")) {
      toastr.error(I18n.t("ServerMessage.no_published_subquestion"));
      return;
    }

    let searchCriteria = {
      brandList: this.state.activityDto.brandList
    };
    if (row.status === "PLANNED") {

      confirmAlert({
        customUI: ({ title, message, onClose }) => {
          return (
            <div className="deleteConfirmBox">
              <div className={"titleClass-" + this.props.theme.name}>
                {I18n.t("standardMessages.activityLaunch")}
              </div>
              <div className="modal-footer" style={{ borderTop: "0px" }}>
                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                  {I18n.t("standardMessages.no")}
                </button>
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={() => {
                    this.props.dispatch(
                      push({
                        pathname: "/actionPlan",
                        query: { selectedActivity: JSON.stringify(row), assignee: row.assignee, searchCriteria: this.state.activityDto }
                      })
                    );
                    onClose();
                  }}
                >
                  {I18n.t("standardMessages.yes")}
                </button>
              </div>
            </div>
          );
        }
      });
    }
    else {
      this.props.dispatch(
        push({
          pathname: "/actionPlan",
          query: { selectedActivity: JSON.stringify(row), assignee: row.assignee,searchCriteria: this.state.activityDto }
        })
      );
    }


  };

  deleteActivity = row => {
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className="deleteConfirmBox">
            <div className={"titleClass-" + this.props.theme.name}>
              {row.status === "Completed" || row.status ==="Rejected" ? I18n.t("standardMessages.completed_activity_delete"):I18n.t("standardMessages.delete")} {row.name}
            </div>
            <div className="modal-footer" style={{ borderTop: "0px" }}>
              <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                {I18n.t("standardMessages.no")}
              </button>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {
                  this.delete(row.activityId);
                  onClose();
                }}
              >
                {I18n.t("standardMessages.yes")}
              </button>
            </div>
          </div>
        );
      }
    });
  };

  delete = (activityId) => {
    this.props.dispatch(deleteActivity(activityId, this.state.activityDto));
  }

  buttonFormatter = (cell, row) => {
    console.log(row.status);
    let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
    let linkName = '';
    let linkIcon = '';
    if (row.status == "PLANNED") {
      linkName = 'Launch';
      linkIcon = 'rocket';
    }

    //for completed activities--start
    let isEdit_allow=false;
    if (row.status === "COMPLETED" || row.status === "REJECTED") {
      if (this.props.user.userMdl.roles !== undefined) {
        var roles = this.props.user.userMdl.roles;
        console.log(roles);
        for (var i = 0; i < roles.length; i++) {
          if (roles[i].toUpperCase().indexOf('KET TME ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 
          || roles[i].toUpperCase().indexOf('KET-BRIT TME ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-EA ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
            isEdit_allow = true;
            break;
          }
         }
      }
     
    }
    //for completed activities--end
   
    if (row.status !== "PLANNED") {
      linkName = 'View';
      linkIcon = 'eye';
    }
    return (
      <div className="btn-toolbar float-right">

        {(row.status === "PLANNED" || row.status === "ONGOING" || row.status === "CLOSED" || isEdit_allow) && accessType == "R/W" ?
          <a onClick={() => this.toggleModal("Edit")}>
            <div title={I18n.t("tmeAdminMaster.edit")} className="edit-icon" />
          </a> : ""}
        {(row.status == "PLANNED" || isEdit_allow) && accessType === "R/W" ? (
          <a onClick={() => this.deleteActivity(row)}>
            <div
              title={I18n.t("tmeAdminMaster.delete")}
              className="delete-icon"
            />
          </a>
        ) : (
          ""
        )}

      {console.log(row,linkName)}
        {row.assignee !== null && row.toolbox !== null && linkIcon !== '' && !(linkName === 'Launch' && accessType === "R") ?
          <a
            onClick={() => this.dispatchActivity(row)}
          >
            <Fa icon={linkIcon} fixed size="lg" title={linkName === 'Launch' ? (I18n.t("Table.launch")) : (I18n.t("Table.view"))} />
          </a> 
            : null}  
      </div>
    );
  };

  search = activityDto => {
    this.setState({ activityDto });
    this.setState({
      showSearchPanel: true
    });
    this.props.dispatch(searchActivity(activityDto));
  };
  linkFormatter = (cell, row) => {
    return (
      <div className="btn-toolbar float-right">
        <a>View</a>
      </div>
    );
  };

  renderShowsTotal(start, to, total) {
    var text =
      "Showing " +
      start +
      " to " +
      to +
      " of " +
      this.state.records +
      " records.";
    return <p style={{ color: "blue" }}>{text}</p>;
  }

  toolBoxFormatter = (cell, row) => {
    return (
      <div title={row.toolbox ? row.toolbox : ''} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.toolbox ? row.toolbox : ""}</div>
    );
  }

  activityIdFormatter = (cell, row) => {
    return (
      <div title={row.activityId ? row.activityId : ''} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.activityId ? row.activityId : ""}</div>
    );
  }

  dealerFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealer}</div>
    );
  }
  assigneeFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.assignee}</div>
    );
  }
  
  scoreFormatter = (cell, row) => {
    // console.log(cell);
    var labelCell = cell + "%";
    var success = parseInt(cell);
    var danger = 100 - parseInt(cell);
    return (
      <div className="scoreProgressBar" style={{ position: "relative" }}>
        <ProgressBar>
          <ProgressBar variant="success" now={success} key={1} />
          <ProgressBar variant="danger" now={danger} key={2} />
        </ProgressBar>
        <div className="scoreLabel">{labelCell}</div>
      </div>
    );
  }




  initialkpiFormatter = (cell, row) => {
    if(row.toolboxId===1){
    var color = "circleSize blank-act ";
    }
    else{
      var color = row.initialKpiIND==='True'?"circleSize success-act ":"circleSize default-act";
    }
    return (
      <div>
        <i className={"fa fa-circle " + color}></i>
      </div>
    );
  }
 
  targetsKpiFormatter = (cell, row) => {
    if(row.toolboxId===1){
      var color = "circleSize blank-act ";
    }
    else{
      var color = row.targetKpiIND==='True'?"circleSize success-act ":"circleSize default-act";
    }
    return (
      <div>
        <i className={"fa fa-circle " + color}></i>
      </div>
    );
  }

  monthlyKpiFormatter = (cell,row)=>{
    var addMonths = row.monthlyTarget && row.monthlyTarget.length > 0 ? (row.monthlyTarget.length) : 0;
    if(row.toolboxId===1){
      var indicatorClass = "circleSize blank-act ";
    }
    else if(addMonths===0){
        var indicatorClass = "circleSize default-act";
    }
    else if(addMonths>0){
      var dateParts = row.firstVisit.split("/");
      // month is 0-based, that's why we need dataParts[1] - 1
      var firstVisit = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
      firstVisit = moment(firstVisit)// moment(...) can also be used to parse dates in string format
      firstVisit.add(addMonths, 'months');
      firstVisit = moment(firstVisit).format("MM/DD/YYYY");
      firstVisit = new Date(firstVisit);
      var currentDate = new Date();
      currentDate.setDate(0);
       if (currentDate >= firstVisit) {
         var indicatorClass = "circleSize yellow-act";
       } else {
        var indicatorClass = "circleSize success-act";
       }
    }
    return (
      <div>
        <i className={"fa fa-circle " + indicatorClass}></i>
      </div>
    );

  }


  firstAuditFormatter = (cell,row)=>{
    if(row.firstAudit=="PASSED"){
      var color = "circleSize success-act" ;
    }
    else if (row.firstAudit=="FAILED"){
        var color = "circleSize danger-act";
    }
  
    return (
      <div title={row.firstAudit ? I18n.t('First Audit.' + row.firstAudit.toLowerCase()) : "Not Started"}>
          {row.firstAudit!==null ? <i className={"fa fa-circle " + color}/> : "-"}
        </div>
      );

  }

  kaizenFormatter= (cell,row)=>{
    if(row.kaizen==="Kaizen- reAudit"){
      var color = "circleSize success-act";
      }
      else if(row.kaizen==="launchKaizen") {
        var color = "circleSize yellow-act";
      }
      return (
        <div title={row.kaizen ? I18n.t('kaizen.' + row.kaizen.toLowerCase()) : "Not Started"}>
          {row.kaizen ? <i className={"fa fa-circle " + color}/> : "-"}
        </div>
      );

  }

  reAuditFormatter= (cell,row)=>{
    if(row.reAudit==="Passed"){
      var color = "circleSize success-act";
      }
      else if(row.reAudit==="ReAudit") {
        var color = "circleSize yellow-act";
      }
      else if (row.reAudit==="Failed") {
        var color = "circleSize danger-act";
      }
      return (
        <div title={row.reAudit ? I18n.t('reAudit.' + row.reAudit.toLowerCase()) : "Not Started"}>
          {row.reAudit ? <i className={"fa fa-circle " + color}/> : "-"}
        </div>
      );

  }

  auditStatusFormatter= (cell,row)=>{
    return (
      <div>
        <button
              variant="contained"
              style={{
                backgroundColor:
                row.auditStatus === "PASSED"
                    ? "#28a745"
                    : row.auditStatus === "FAILED"
                    ? "#e50000"
                    : "",
                lineHeight: 1.5,
                color: "white",
                textAlign: "center",
                fontSize: "0.675rem",
                //  display: 'inherit',
              }}
          disabled
        >
          {I18n.t('auditStatus.' + (row.auditStatus!==null?row.auditStatus.toLowerCase():""))}
        </button>
      </div>
    );
  }



  statusFormatter(cell, row) {
    return (
      <div title={row.status ? I18n.t('status.' + row.status.toLowerCase()) : ''}>
        <button
              variant="contained"
              style={{
                backgroundColor:
                row.status === "PLANNED"
                    ? "#a9a9a9"
                    : row.status === "ONGOING"
                    ? "#FBA803"
                    : row.status === "COMPLETED"
                    ? "#28a745"
                    : row.status === "CLOSED"
                    ? "#90ee90"
                    : row.status === "REJECTED"
                    ? "#e50000"
                    : "primary",
                lineHeight: 1.5,
                color: "white",
                textAlign: "center",
                fontSize: "0.675rem",
                //  display: 'inherit',
              }}
          disabled
        >
          {I18n.t('status.' + row.status.toLowerCase())}
        </button>
      </div>
    );
  }
  questionPercentFormatter = (cell, row) => {
    var labelCell = row.quesComplPercnt + "%";
    var success = parseInt(row.quesComplPercnt);
    var danger = 100 - parseInt(row.quesComplPercnt);
    return (
      <div className="scoreProgressBar" style={{ position: "relative" }}>
        <ProgressBar>
          <ProgressBar className="progressp" now={success} key={1} />
          <ProgressBar className="progressb" now={danger} key={2} />
        </ProgressBar>
        <div className="scoreLabel">{labelCell}</div>
      </div>
    );
  }
  actionPlanFormatter(cell, row) {
    return (
      <div title={row.actionPlan==="Open" ? "Ongoing":row.actionPlan==="Closed"?I18n.t('actionPlan.' + row.actionPlan.toLowerCase()) : '-'}>
    {row.actionPlan===null?<span style={{color:"red",fontWeight:"bold",fontSize:"medium"}}>_</span>  :
      <button
      variant="contained"
      style={{
        backgroundColor:
        row.actionPlan === "Open"
            ? "#FBA803"
            : row.actionPlan === "Closed"
            ? "#28A745"
            : "-",
        lineHeight: 1.5,
        color: "white",
        textAlign: "center",
        fontSize: "0.675rem",
      }}
      disabled
      >
      {row.actionPlan==="Open" ? "Ongoing":row.actionPlan==="Closed"?I18n.t('actionPlan.' + row.actionPlan) : '-'}
      </button>
    }     
      </div>
    );
  }

  pageChanged(page, sizePerPage) {
    const activityDto = { ...this.state.activityDto };
    activityDto["page"] = page;
    activityDto["rows"] = sizePerPage;
    this.setState({ activityDto });
    this.search(activityDto);
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

    if (!sortField) {
      const currentIndex = (page - 1) * sizePerPage;
      const activityDto = { ...this.state.activityDto };
      activityDto["page"] = page;
      activityDto["rows"] = sizePerPage;
      this.setState({ activityDto });
      this.search(activityDto);
      this.setState(() => ({
        page,
        sizePerPage
      }));

    } else {
      if (this.state.sortOrder === "asc") {
        sortOrder = "desc";
        this.setState({ sortOrder: "desc" })
      }
      else {
        sortOrder = "asc";
        this.setState({ sortOrder: "asc" })
      }
      this.setState({ sortField: this.indexOfSortField(sortField) });
      const activityDto = { ...this.state.activityDto };
      activityDto["sortOrder"] = sortOrder;
      activityDto["sortCol"] = sortField;
      this.setState({ activityDto });
      this.search(activityDto);
    }
  };

  indexOfSortField(sortField) {
    switch (sortField) {
      case "dealer":
        return 1
      case "city":
        return 2
      case "toolbox":
        return 3
      case "firstVisit":
        return 4
      case "status":
        return 5
      case "completionDateStr":
        return 6
      case "score":
        return 7
      case "kpis":
        return 8
      case "questionPercent":
          return 9
          case "firstAudit":
          return 10
      case "actionPlan":
        return 11
      case "createdBy":
        return 12
        case "kaizen":
            return 13
        case "reAudit":
          return 14
        case "auditStatus":
          return 15
      default:
        return 1
    }
  }

  componentWillReceiveProps(nextProps) {

    if (nextProps.activityList.activityList) {

      if (nextProps.activityList.activityList.records > 0) {
        this.setState({
          data: nextProps.activityList.activityList.activityDTOList
        });
        this.setState({ records: nextProps.activityList.activityList.records });
        this.setState({ total: nextProps.activityList.activityList.total });
      } else {
        this.setState({ data: [] });
        this.setState({ records: 0 });
        this.setState({ total: 0 });
      }
      var index = this.state.sortField;
      if (index) {
        setTimeout(() => {
          if (this.state.sortOrder === "asc") {
            $(".searchActivityTable .dropdown").addClass("hideSortIcon");
            $(".searchActivityTable .dropup").addClass("hideSortIcon");
            $(".searchActivityTable > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" + index + ") > span > span.dropup").addClass("showSortIcon");
          }
          else {
            $(".searchActivityTable .dropdown").addClass("hideSortIcon");
            $(".searchActivityTable .dropup").addClass("hideSortIcon");
            $(".searchActivityTable > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" + index + ") > span > span.dropdown").addClass("showSortIcon");
          }
        }, 60000);
      }
    }
    else {
      this.setState({ data: [] });
      this.setState({ records: 0 });
      this.setState({ total: 0 });
    }
  }


  render() {

    // console.log(this.state.activityDto);
    let accessType = (this.props.user && this.props.user.userMdl && this.props.user.userMdl.accessControl && this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0]) ?
      this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
    let isBrit = (this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") ? true : false
    // if(this.props.user.userMdl.activeProgram==='TSP'){}
    const columns = [
      {
        dataField: "dealer",
        text: I18n.t("Table.dealerName"),
        sort: true,
        style: { width: "9%", textAlign: "left" },
        headerStyle: { width: "9%", textAlign: "left" },
        formatter: this.dealerFormatter,
        title: true
      },
      {
        dataField: "city",
        text: I18n.t("Table.city"),
        sort: true,
        style: { width:"7%", textAlign: "left" },
        headerStyle: { width: "7%", textAlign: "left" },
        title: true
      },
      // {
      //   dataField: "toolbox",
      //   text: I18n.t("Table.toolbox"),
      //   sort: true,
      //   style: { width:"8%", textAlign: "left" },
      //   formatter: this.toolBoxFormatter,
      //   headerStyle: { width: "8%", textAlign: "left" },
      //   hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
      //   ? true : false
      // },
      {
        dataField: "assignee",
        text: I18n.t("Table.assignee"),
        sort: true,
        style: { width: "9.5%", textAlign: "left" },
        headerStyle: { width: "9.5%", textAlign: "left" },
        formatter: this.assigneeFormatter,
        title: true
      },
      {
        dataField: "firstVisit",
        text: I18n.t("Table.startDate"),
        sort: true,
        style: { width:"8%" },
        headerStyle: { width:"8%" ,textAlign: "left" },
        title: true
      },
      {
        dataField: "status",
        text: I18n.t("Table.status"),
        sort: true,
        formatter: this.statusFormatter,
        style: { width:"7%"},
        headerStyle: { width:"7%",textAlign: "left"  }
      },
      {
        dataField: "completionDateStr",
        text: I18n.t("Table.completionDate"),
        sort: true,
        style: { width:"8%" },
        headerStyle: { width: "7.5%" },
        title: true
      },
      {
        dataField: "score",
        text: I18n.t("Table.score"),
        sort: true,
        style: { width: "6%", display: isBrit ? 'none' : 'block'},
        formatter: this.scoreFormatter,
        headerStyle: { width: "6%",textAlign: "left" ,display: isBrit ? 'none' : 'block'}
      },
      {
        dataField: "questionPercent",
        text: I18n.t("Table.questionPercent"),
        sort: true,
        style: { width: "7%"},
        formatter: this.questionPercentFormatter,
        headerStyle: { width: "6.5%"}
      },
      {
        dataField: "target",
        text: I18n.t("Table.targetKpi"),
        style: { width: "4.5%",marginRight:"3px"},
        formatter: this.targetsKpiFormatter,
        headerStyle: { width: "5%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? true : false
      },
      {
        dataField: "initial",
        text: I18n.t("Table.initialKpi"),
        sort: true,
        style: { width: "4.5%",marginRight:"2px"},
        formatter: this.initialkpiFormatter,
        headerStyle: { width: "4.5%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? true : false
      },
      {
        dataField: "monthly",
        text: I18n.t("Table.monthlyKpi"),
        sort: true,
        style: { width: "5%",marginRight:"0px"},
        formatter: this.monthlyKpiFormatter,
        headerStyle: { width: "5.2%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? true : false
      },

      {
        dataField: "1st Audit",
        text: I18n.t("Table.firstAudit"),
        style: { width: "4.5%",marginRight:"3px"},
        formatter: this.firstAuditFormatter,
        headerStyle: { width: "5%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? false : true
      },
      {
        dataField: "Kaizen",
        text: I18n.t("Table.kaizen"),
        sort: true,
        style: { width: "4.5%",marginRight:"2px"},
        formatter: this.kaizenFormatter,
        headerStyle: { width: "4.5%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? false : true
      },
      {
        dataField: "Re-audit",
        text: I18n.t("Table.reAudit"),
        sort: true,
        style: { width: "5%",marginRight:"0px"},
        formatter: this.reAuditFormatter,
        headerStyle: { width: "6.2%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? false : true
      },
      {
        dataField: "Audit Status",
        text: I18n.t("Table.auditStatus"),
        sort: true,
        formatter: this.auditStatusFormatter,
        style: { width:"7%"},
        headerStyle: { width:"9%",textAlign: "left"  },
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? false : true
      },
      {
        dataField: "actionPlan",
        text: I18n.t("Table.actionPlan"),
        sort: true,
        style: { width: "7%"},
        formatter: this.actionPlanFormatter,
        headerStyle: { width: "6%"},
        hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNas
        ? true : false
      },
      {
        dataField: "activityId",
        text: I18n.t("Table.activityId"),
        sort: true,
        style: { width: "8%", display: isBrit ? 'block':'none',textAlign: "center" },
        formatter: this.activityIdFormatter,
        headerStyle: { width: "8%", display: isBrit ?'block' :'none',textAlign: "center" }
      },
      {
        dataField: "",
        text: "",
        formatter: this.buttonFormatter,
        style: { width:"9%" },
        headerStyle: { width:"9%",float:"right" }
      }
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow["activityId"] = row.activityId;
        selectedRow["dealerId"] = "" + row.dealerId;
        selectedRow["userId"] = "" + row.assigneeId;
        selectedRow["toolbox"] = row.toolbox;
        selectedRow["toolboxId"] = row.toolboxId;
        selectedRow["firstVisit"] = row.firstVisit;
        selectedRow["completionOn"] = row.completionDateStr;
        selectedRow["reminderOn"] = row.reminderOnStr;
        selectedRow["status"] = row.status;
        // selectedRow["auditStatus"] = row.auditStatus;
        selectedRow["userMdl"] = this.props.user.userMdl;
        selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
        selectedRow["langCode"] = this.props.user.userMdl.lang;
        selectedRow["loginId"] = this.props.user.userMdl.username;
        selectedRow["subQuePublished"] = row.subQuePublished;
        this.setState({ selectedRow });
      }
    };



    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize
    }) => (
      <div className={'activityTable parent-' + this.props.theme.name}>
        <BootstrapTable
          remote
          keyField="activityId"
          noDataIndication={I18n.t('others.noActivities')}
          data={data}
          columns={columns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
        />
      </div>
    );
    const { data, sizePerPage, page } = this.state;

    return (
      <div>
        {
          this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer
            ?
            <LaunchTargetScreen />
            :
            <Container className="mt-5 b-1 activityContainer">
              <Col md="12" className="activityTableContainer">
                <div className="activity-content">
                  {/* <form className="form-horizontal"> */}
                  <div className="col-md-12 row control-label noPadding">
                    <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                      {I18n.t("tmeAdminMaster.search")}
                    </label>
                    <label
                      md="0.5"
                      className="control-label boldFont"
                      style={{ marginTop: "0.2rem" }}
                    >
                      <a onClick={this.toggleSearch}>
                        {this.state.showSearchFlag ? (
                          <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                        ) : (
                          <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                        )}
                      </a>
                    </label>
                    <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'} style={{ width: "20%" }}>
                      {I18n.t('TopNav.ActivityLog')} ({this.state.records})
              </label>
                    <label className="control-label" style={{ width: "46%" }} />
                    {accessType === "R/W" && ((this.props.user.userMdl.activeProgram !== "BRiT" && this.props.user.userMdl.activeProgram !== "EA") || (this.props.user.userMdl.roles.includes("KET-EA Admin")) ) ?
                      <label
                        className="control-label boldFont"
                        style={{ textAlign: "right", width: "25%" }} >
                        {I18n.t("tmeAdminMaster.addActivity")}
                        <a onClick={() => this.toggleModal("Create")}>
                          <div title="" className="add-icon" />
                        </a>
                      </label> : ""}
                    <SearchActivity
                      flag={this.state.showSearchFlag}
                      search={this.search}
                      searchDto={this.state.activityDto}
                    />
                    <div className="col-md-12 searchActivityTable">
                      {this.state.showSearchPanel ?
                        <RemotePagination
                          data={data}
                          page={page}
                          sizePerPage={sizePerPage}
                          totalSize={this.state.records}
                          onTableChange={this.handleTableChange}
                        /> : ""}
                    </div>
                  </div>
                  {/* </form> */}
                  {this.state.modal === true ? (
                    <ActivityModal
                      modal={this.state.modal}
                      toggle={this.toggleModal}
                      goTo=""
                      modalFor={this.state.modalFor}
                      selectedRow={this.state.selectedRow}
                      activityDto={this.state.activityDto}
                    />
                  ) : null}
                </div>
              </Col>
            </Container>
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    activityList: state.activityReducer,
    refreshRequired: state.activityReducer.refreshRequired
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(Activity)));
