import React from 'react';
import { withTheme } from './theming';
import Switch from "react-switch";

const ThemeChanger = ({ theme, themes, themeFlag, onThemeChange }) => (
  <Switch
    checked={themeFlag === 1 ? true : false}
    onChange={onThemeChange}
    onColor={theme.primaryColor}
    onHandleColor={theme.primaryColor}
    handleDiameter={18}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={8}
    width={40}
    className="react-switch  mr-1"
    id="icon-switch"
  />
);

export default withTheme(ThemeChanger);
