const translationsPL = {
  pl: {
    Policy: {
      HeaderMsg: "Nota o ochronie prywatności w odniesieniu do przetwarzania danych osobowych w ramach Kaizen Expert Tool",
      Message1: "Niniejsza Nota o Ochronie Prywatności dotyczy przetwarzania danych osobowych w ramach Kaizen Expert Tool. Należy zawsze czytać ją razem z Ogólną Polityką Prywatności Toyoty i Polityką Ochrony Danych Osobowych, w których opisane są ogólne zasady przetwarzania danych osobowych użytkownika i które użytkownik musi zaakceptować, aby móc dalej postępować.",
      Message2: "Toyota Motor Europe NV/SA z główną siedzibą w Avenue du Bourget/Bourgetlaan 60, 1140 Brussels, Belgium, jako administrator danych zbiera Twoje dane osobowe (jak wskazano poniżej) w celach opisanych poniżej.",
      Message3: "Które z Twoich danych osobowych będą zbierane?",
      Message4: "Będziemy zbierać następujące kategorie danych osobowych w odniesieniu do Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategoria/kategorie danych osobowych:",
        tr2: "Identyfikator użytkownika",
        tr3: "Imię i nazwisko użytkownika",
        tr4: "Marka (Toyota, Lexus)",
        tr5: "Preferencja języka",
        tr6: "Organizacja stowarzyszona"
      },

      Message5: "Cel i podstawy prawne przetwarzania danych osobowych.",
      Message6: "Przetwarzamy Państwa dane osobowe w celach i na podstawie prawnej opisanej poniżej:",
      Table2: {
        tr1: {
          th1: "Cel:",
          th2: "Podstawa prawna:"
        },
        tr2: {
          td1: "KET - 1: Działania Kaizen dotyczące działalności dealera",
          td2: "Uzasadnione interesy administratora(-ów) danych"
        },
        tr3: {
          td1: "KET - 2: Wsparcie informatyczne",
          td2: "Uzasadnione interesy administratora(-ów) danych"
        }
      },
      Message7: "Okres przechowywania danych osobowych",
      Message8: "Twoje dane osobowe będą przechowywane przez okres wskazany poniżej:",
      Table3: {
        tr1: {
          th1: "Okres przechowywania:",
          th2: "Kategoria/kategorie danych osobowych:"
        },
        tr2: {
          td1: "Wszelkie dane osobowe, o których mowa powyżej, będą przechowywane maksymalnie przez 5 lat + 1 miesiąc od dnia ostatecznego zamknięcia narzędzia Kaizen Expert Tool.",
          td2: "Imię użytkownika, Nazwisko, Preferencje językowe, organizacja"
        }
      },
      Message9: "Opcjonalnie: W przypadku ujawnienia/przekazania Państwa danych osobowych do krajów spoza Europejskiego Obszaru Gospodarczego (EOG)",
      Message10: "Twoje dane osobowe zostaną udostępnione odbiorcom w krajach spoza EOG, jak wskazano poniżej:",
      Table4: {
        tr1: {
          td1: "Tożsamość",
          td2: "Kraj",
          td3: ""
        },
        tr2: {
          td1: "Odbiorcy spoza EOG",
          td2: "Infosys",
          td3: "Indie"
        }
      },
      Message11: "Niniejszym potwierdzam, że zapoznałem się z treścią niniejszej informacji, przeczytałem i akceptuję Ogólną Politykę Prywatności Toyoty oraz Politykę Ochrony Danych Osobowych."
    },
    ACCESSORIES: "Akcesoria",
    activity: {
      Assignee: "Przypisane do",
      CompletionDate: "Data zakończenia",
      Dealer: "Dealer",
      FirstVisit: "Pierwsza wizyta",
      kpi: {
        kpi: "Wskaźniki KPI powinny być liczbami (np. + x lub - x)",
        kpiLength: "Maksymalna długość dla wskaźnika KPI to 10 znaków",
        mandatory: "Wprowadź prawidłowe dane we wszystkich wymaganych polach",
        mandatoryTargets: "Wprowadź wszystkie KPI.",
        target: "Cel powinien być określony jako liczba",
        targetLength: "Maksymalna długość dla celu to 10 znaków"
      },
      ReminderOn: "Przypomnienie włączone",
      statusDD: "Status",
      Toolbox: "Panel biznesowy",
      activityCreation: "Rozpoczęto proces tworzenia działań.",
      activityCreated: "Działania zostały utworzone"
    },
    DPOK: "Zapasy magazynowe",
    EFFICIENCY: "Efektywność",
    FIX_IT_RIGHT: "Napr. za pierw. razem ",
    footer: {
      message: "Pola obowiązkowe"
    },
    FOUNDATION: "Wymagania podstawowe",
    MAINTENANCE_REMINDERS: "Przypom. o przeglądzie",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Podstawy",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Kwi",
      aug: "Sie",
      dec: "Gru",
      feb: "Lut",
      jan: "Sty",
      jul: "Lip",
      jun: "Cze",
      mar: "Mar",
      may: "Maj",
      nov: "Lis",
      oct: "Paź",
      sep: "Wrz"
    },
    others: {
      back: "Wstecz",
      closed: "Zamknięte",
      completed: "Zakończone ",
      countryLanguageCode: "Kod języka/kraju ",
      createActivity: "Utwórz działanie",
      createdDate: "Data utworzenia",
      dataUneditableNote:
        "Uwaga: Po zapisaniu danych nie będzie można ich edytować. Aby zmienić dane, skontaktuj się z administratorem NMSC.",
      date: "Data",
      downloadQuestionnaire: "Pobierz kwestionariusz",
      editActivity: "Edytuj działanie",
      initialHelpText:
        "Wstępny pomiar wskaźnika KPI powinien być średnią wartością z poprzednich sześciu pełnych miesięcy",
      inProgress: "W toku",
      name: "Imię i nazwisko",
      next: "Dalej",
      noActivities: "Nie znaleziono działań",
      noResults: "Brak wyników",
      of: "z",
      ongoing: "Bieżące",
      open: "Otwórz",
      page: "Strona",
      planned: "Zaplanowane",
      problemDescription: "Opis problemu",
      signature: "Podpis",
      select: "Wybierz",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Podczas pobierania wystąpił błąd.",
      no_published_subquestion: "Proszę wysłać kwestionariusz dla tego panelu biznesowego, aby rozpocząć to działanie.",
      activity_upload_error_msg: "Błąd podczas ładowania działania.",
      action_plan_created: "Plan działań pomyślnie utworzony",
      action_plan_deleted: "Plan działań pomyślnie usunięty",
      action_plan_saved: "Plan działań pomyślnie zapisany",
      activity_kpi_saved: "Wskaźniki KPI dla działania pomyślnie zapisane",
      master_kpi_confirm_delete:
        "Czy na pewno chcesz usunąć wybrane wskaźniki KPI?",
      master_kpi_created: "Pomyślnie utworzono główny wskaźnik KPI",
      master_kpi_deleted: "Główny wskaźnik KPI został usunięty",
      master_kpi_not_found: "Nie znaleziono głównego wskaźnika KPI",
      master_kpi_published: "Pomyślnie opublikowano wybrane główne wskaźniki KPI",
      master_kpi_retired: "Główny wskaźnik KPI został wycofany",
      master_kpi_saved: "Pomyślnie zapisano główny wskaźnik KPI",
      subquestion_created: "Pytanie pomocnicze zostało pomyślnie zapisane.",
      subquestion_updated: "Pytanie pomocnicze zostało pomyślnie zaktualizowane.",
      subquestionnaire_not_found: "Pytanie pomocnicze nie zostało znalezione.",
      subquestion_retired: "Pytanie pomocnicze zostało wycofane.",
      subquestion_deleted: "Pytanie pomocnicze zostało usunięte.",
      subquestion_published: "Pytania pomocnicze zostały pomyślnie opublikowane.",
      mainquestion_created: "Utworzono Kwestionariusz Główny.",
      mainquestion_deleted: "Kwestionariusz Główny został usunięty.",
      mainquestion_updated: "Pytanie główne zostało zaktualizowane.",
      duplicateMsg: "Zduplikowane wpisy w pliku Excel.",
      deleteErr: "Błąd podczas usuwania pliku",
      toolbox_deleted: "Panel biznesowy został pomyślnie usunięty",
      toolbox_already_exists: "Ten panel biznesowy już istnieje.",
      toolbox_saved: "Panel biznesowy został zapisany",
      toolbox_created: "Utworzono panel biznesowy",
      toolbox_not_found: "Nie znaleziono panelu biznesowego",
      published_content_found: "Tego panelu biznesowego nie można usunąć, ponieważ zawiera opublikowaną zawartość",
      dealers_already_mapped: " Wybrani dealerzy zostali już zmapowani dla tego użytkownika. ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dodano dealera. ",
      dealers_added: "  Dodano dealera. ",
      total_dealers_mapped_now: "Łączna liczba dealerów aktualnie przypisanych do tego użytkownika wynosi. ",
      no_dealer_available: "Brak dealerów dla wybranych kryteriów.",
      proper_dealer_selected: "Wybierz odpowiedniego dealera.",
      user_referenced_ongoing_activity: "Użytkownik jest aktualnie przypisany do trwających działań. Zmień osobę przypisaną do tych działań i spróbuj ponownie. ",
      want_to_remove_mapping: "Czy na pewno chcesz usunąć mapowanie użytkownika dla tych dealerów?",
      user_mapping_removed: "Wybrani dealerzy zostali pomyślnie usunięci z mapowania użytkownika.",
      dealer_closed_in_A2D: "Ten dealer jest zarejestrowany jako „Zamknięty” w A2D.",
      network_level_not_found: "Wybierz prawidłową kombinację poziomów sieci zgodnie z konfiguracją sieci KET.",
      duplicate_dealer_uuid: "Dealer już obecny w bazie danych KET.",
      dealer_created: "Utworzono dealera.",
      dealer_updated: "Atrybuty tego sprzedawcy zostały zaktualizowane.",
      dealer_not_present: "Ten dealer nie jest zarejestrowany w A2D.",
      dealer_uuid_other_nmsc: "Wybrany kod uuid A2D nie jest dostępny w Twojej sieci.",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Czy na pewno chcesz to usunąć?",
      addActionPlanNotAllowed: "Plan działań nie może zostać utworzony lub edytowany po zamknięciu, zakończeniu lub odrzuceniu działania.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Czy na pewno chcesz odrzucić to działanie? Po odrzuceniu działania dokonywanie zmian nie będzie możliwe.",
      rejectMsg: "Czy na pewno chcesz odrzucić to działanie? Po odrzuceniu działania dokonywanie zmian nie będzie możliwe.",
      selectNMSCTab: "Wybierz język NMSC do opublikowania",
      all: "Wszystkie",
      deleteConfirm: "Czy na pewno chcesz usunąć wybrany wskaźnik KPI?",
      no: "Nie",
      retireConfirm: "Czy na pewno chcesz wycofać ten wskaźnik KPI? Jeśli to zrobisz, nie będzie można go ponownie aktywować",
      warning: "Ostrzeżenie",
      yes: "Tak",
      saveDataConfirmation: "Czy na pewno chcesz zapisać dane?",
      saveKPIWarning: "Note:Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed: "Pole wskaźnika KPI dla następnego miesiąca zostanie dodane po zakończeniu bieżącego miesiąca.",
      activityLaunch: "Czy jesteś pewien, że chcesz uruchomić to działanie? Po wykonaniu tego kroku, działanie nie może być usunięte lub cofnięte do statusu 'zaplanowane'.",
      deleteConfirmSubQuestionNaire: "Czy na pewno chcesz usunąć wybrane pytanie pomocnicze?",
      retireConfirmSubQuestionNaire: "Czy na pewno chcesz wycofać wybrane pytanie pomocnicze?",
      deleteConfirmMainQuestion: "Proszę potwierdzić, jeżeli chcesz usunąć pytanie główne i związane z nim pytania pomocnicze.",
      deleteConfirmChapter: "Czy na pewno chcesz usunąć ten rozdział?",
      deleteLibraryFolder: "Czy na pewno chcesz usunąć ten folder?",
      deletePublishedFolder: "Niektóre pliki z tego folderu mogą być połączone z kwestionariuszem. Czy na pewno chcesz na stałe skasować ten podfolder i usunąć połączenie/a z kwestionariusza?",
      importQuestion: "Czy na pewno chcesz zaimportować to pytanie pomocnicze? Obecne pytanie pomocnicze zostanie zastąpione.",
      noQuestion: "Brak dostępnych pytań.",
      MandatoryWarning: "Proszę wypełnić wszystkie obowiązkowe pola dla wszystkich języków lokalnych.",
      certificationSubmission:
        "Panel biznesowy został przesłany do TME w celu certyfikacji dealera",
      sameToolBox: "Nie możesz przesłać tego samego panelu biznesowego więcej niż raz. Wybierz inny panel biznesowy.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Wszystkie działania utworzone dla tego dealera w 'Value Chain' zostaną usunięte. Czy na pewno chcesz kontynuować?",
      updateBritConfirmDealer: "Wszystkie działania utworzone dla tego dealera w 'BRiT' zostaną usunięte. Czy na pewno chcesz kontynuować?",
      completed_activity_delete:"Po usunięciu danych nie będzie można ich odzyskać. Czy na pewno chcesz usunąć dane?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Select All",
      unSelectAll: "Unselect All",
      select:"Select...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "Wszystkie działania utworzone dla tego dealera w 'EA' zostaną usunięte. Czy na pewno chcesz kontynuować?"

    },
    status: {
      published: "Opublikowany",
      retired: "Wycofany",
      saved: "Zapisany",
      rejected: "Odrzucone",
      open: "Otwórz",
      closed: "Zamknięte",
      close: "Zamknięte",
      ongoing: "Bieżące",
      completed: "Zakończone",
      complete: "Zakończone",
      planned: "Zaplanowane",
      overdue: "Opóźnienie",
      due: "Termin",
      pending: "Pending",
    },
    Rejected: "Odrzucone ",
    Open: "Otwórz",
    Closed: "Zamknięte",
    Close: "Zamknięte",
    Ongoing: "Bieżące",
    Completed: "Zakończone",
    Complete: "Zakończone",
    Planned: "Zaplanowane",
    tabDetails: {
      noActionAvailable: "Brak dostępnych działań",
      activeDealer: "Ten dealer jest obecnie zarejestrowany jako Aktywny w A2D i nie można go usunąć.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Działanie",
      activityCompletionDate: "Data ukończenia działania",
      activityCompletionDateFrom: "Data zakończenia działania (Od)",
      activityCompletionDateTo: "Data zakończenia działania (Do)",
      activityStartDate: "Data rozpoczęcia działania",
      activityStartDateFrom: "Data rozpoczęcia działania (Od)",
      activityStartDateTo: "Data rozpoczęcia działania (Do)",
      assignedTo: "Przypisane do",
      assignee: "Przypisane do",
      benchmark: "Benchmark",
      city: "Miasto",
      completedBy: "Wykonał(a) ",
      completionBy: "Zakończone przez",
      completionDate: "Data zakończenia",
      counterMeasure: "Środek zaradczy",
      country: "Kraj",
      createdBy: "Utworzył(a)",
      dealer: "Dealer",
      dealerNameAD: "Nazwa dealera (A2D)",
      dealerName: "Nazwa dealera",
      definition: "Definicja",
      description: "Opis",
      dueDate: "Termin",
      endDate: "Data zakończenia",
      firstVisit: "Pierwsza wizyta",
      followup: "Kontrola",
      franchise: "Franczyza",
      history: "Historia",
      id: "Nr",
      initial: "Wynik początkowy",
      kpi: "Wskaźniki KPI",
      launch: "Uruchom",
      mandatory: "Obowiązkowy",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Opublikowany dnia",
      questionReference: "Nr ref. problemu",
      reference: "Typ KPI",
      reminderOn: "Przypomnienie włączone",
      score: "Wynik",
      startDate: "Data rozpoczęcia",
      status: "Status",
      target: "Target",
      toolbox: "Panel biznesowy",
      view: "Wyświetl",
      kpiResult: "Wyniki KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokalizacja",
      ActionItemReport: "Działania w trakcie",
      certification: "Status certyfikacji",
      noOfToolbox: "Ilość zakończonych paneli biznesowych",
      program: "Program",
      certificationDate: "Data otrzymania certyfikatu",
      activityId :'Identyfikator działania',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan działania",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
      tabsDetails: {
      actionPlan: "Plan działania",
      confirmDeleteMessage: "Czy na pewno chcesz usunąć wybrany wskaźnik KPI?",
      confirmRetireMessage: "Potwierdź chęć wycofania wybranego wskaźnika KPI, ponieważ został opublikowany wcześniej",
      errorRetireMsg: "Tego wskaźnika KPI nie można wycofać, ponieważ jest on aktywny i wymagany przez TME",
      import: "Importuj",
      importKpiMessage: "Zaimportuj wskaźniki KPI z listy głównej wskaźników KPI TME",
      kpi: "Kluczowe wskaźniki wydajności",
      library: "Biblioteka",
      noActionAvailable: "Brak dostępnych działań",
      NoKPI: "Brak dostępnych wskaźników KPI",
      publish: "Opublikuj",
      questionnaire: "Kwestionariusz",
      tmeKPIList: "Lista główna wskaźników KPI TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nazwa panelu biznesowego",
      newToolboxName: "Nowa nazwa",
      deleteToolbox: "Usunąć panel biznesowy?",
      programName: "Nazwa programu",
      ToolboxName_EN: "Nazwa panelu biznesowego (EN)",
      deleteTool: "Czy na pewno chcesz usunąć ten panel biznesowy?",
      selectToolbox: "Proszę wybrać panel biznesowy do usunięcia.",
      editToolBox: "Edycja panelu",
      addToolbox: "Dodawanie panelu",
      toolbox: "Proszę wprowadzić nazwę dla tego panelu biznesowego.",
      addActionPlan: "Add Plan działania",
      actionPlanItem: "Plan działania – poz. nr",
      add: "Dodaj",
      addActivity: "Dodaj działanie",
      additionalInformation: "Dodatkowe informacje",
      benchmark: "Benchmark",
      cancel: "Anuluj",
      createEditActivity: "Tworzenie/edycja działania",
      createKPI: "Tworzenie  KPI",
      delete: "Usuń",
      download: "Pobierz",
      edit: "Edytuj",
      editKPI: "Edycja wskaźnika KPI",
      keyPerformanceIndicator: "Kluczowy wskaźnik wydajności KPI",
      kpiDefinition: "Definicja wskaźnika KPI",
      mandatoryKpi: "Obowiązkowy wskaźnik KPI",
      save: "Zapisz",
      search: "Wyszukaj",
      unit: "Jednostka",
      update: "Aktualizuj",
      validData: "Wprowadź poprawne dane",
      hide: "Ukryj",
      show: "Pokaż",
      upload_dealer_network: "Załaduj sieć dealerską",
      upload_question: "Załaduj kwestionariusz",
      upload_user: "Załaduj informacje użytkownika",
      upload_activity: "Załaduj działania",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Due date exceeded",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Proszę odpowiedzieć na wszystkie pytania, aby zamknąć to działanie.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Please enter the initial KPI measurement, the KPI targets and at least 1 month of measurement to continue.",
      initialTarget: "Proszę wproadzić początkowy pomiar wskaźników KPI oraz cele KPI, aby kontynuować.",
      upload_error: "Wystąpił błąd podczas ładowania sieci dealerskiej.",
      action_item_create_warning: "Maksymalnie 5 wpisów może być połączonych w jednym działaniu.",
      actionPlanStartDateValidation: "Data zakończenia nie może być wcześniejsza niż data rozpoczęcia",
      actionsNotClosed: "Plan działań zawiera czynności w toku — przed zamknięciem działania zamknij wszystkie czynności",
      activityDuplicate: "Istnieje już inne działanie dla tego samego dealera i zestawu narzędzi",
      assigneeNotExist: "Wyznaczony przedstawiciel nie jest zarejestrowany w systemie",
      assigneeValidation: "Nieprawidłowy wyznaczony przedstawiciel",
      atleastOnePublish: "Wybierz co najmniej jeden wskaźnik KPI do opublikowania",
      cannotRetireActive: "To jest aktywny wskaźnik KPI wymagany przez TME, więc nie można go wycofać ",
      completionDate: "Data ukończenia nie może być wcześniejsza niż data pierwszej wizyty",
      confirDeletePlannedActivity: "Czy na pewno chcesz usunąć to zaplanowane działanie?",
      DateformatValidation: "Data powinna mieć format DD / MM / RRRR",
      DateValidation: "Data rozpoczęcia musi być wcześniejsza niż data zakończenia",
      dealerValidation: "Nieprawidłowy dealer",
      deleteSelectedConfirm: "Czy na pewno chcesz usunąć wybrany wskaźnik KPI?",
      duplicate: "Już istnieje inny wskaźnik KPI o takiej samej nazwie i definicji",
      modalMandatory: "Wprowadź prawidłowe dane we wszystkich wymaganych polach",
      noPublishKpi: "Jeszcze nie opublikowano wskaźników KPI dla wybranego panelu",
      validSearchCriteria: "Wprowadź prawidłowe kryteria wyszukiwania",
      multiSelectPlaceHolder: "Wprowadź co najmniej 5 znaków",
      multiSelectPlaceHolderUser: "Wprowadź co najmniej 3 znaków",
      startDateValidation: "Data rozpoczęcia nie może być późniejsza niż data zakończenia",
      fileSelectionErr: "Proszę najpierw wybrać plik",
      atleastOneQuestion: "Proszę wybrać co najmniej jedno pytanie pomocnicze do importu.",
      atleastOneSubQues: "Wybierz co najmniej jedno pytanie dodatkowe do opublikowania.",
      uploadErr: "Liczba plików, których nie można załadować:",
      uploadSuccess: "Sieć dealerska została załadowana pomyślnie.",
      file_upload_success: "Plik/i zostały pomyślnie załadowane.",
      KPIinOngoingClosedActivity: "Ten kluczowy wskaźnik wydajności (ang. KPI) występuje w co najmniej jednym trwającym / zamkniętym działaniu. Prosimy o ukończenie tych działań przed wprowadzeniem jakichkolwiek zmian w KPI.",
      Role_Error: "Konfiguracja użytkownika jest niekompletna, skontaktuj się z administratorem NMSC KET.",
      points_allocation_incorrect: "Nieprawidłowe przydzielenie punktów, zmień.",
      activity_deleted:"Działanie usunięte pomyślnie",
      activity_certified_dealer:"Dealer uzyskał certyfikat na podstawie wybranego działania, nie można go usunąć.",
      activity_submited_for_certificate_dealer:"Działanie to zostało uwzględnione we wniosku o certyfikację wysłanym do TME, dlatego też status nie może zostać zmieniony."
    },
    TopNav: {
      ActivityLog: "Działania",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Strona główna",
      logout: "Wyloguj",
      MasterDataUpload: "Prześlij dane nadrzędne",
      Toolbox: "Panel biznesowy",
      welcome: "Witamy",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Dodaj pytanie pomocnicze",
      editSubQuestion: "Edytuj pytanie pomocnicze",
      subQuestionTitle: "Pytanie pomocnicze",
      additionalInfo: "Dodatkowe informacje",
      na: "Dodać „Nie dotyczy” jako ewentualną odpowiedź na to pytanie",
      question: "Pytanie",
      questionTitle: "Tytuł pytania",
      why: "Dlaczego?",
      addMainQuestion: "Dodaj pytanie główne",
      editMainQuestion: "Edytuj pytanie główne",
      previousQuestion: "Poprzednie pytanie",
      nextQuestion: "Następne pytanie",
      what: "Co?",
      library: "Biblioteka",
      subQuestionTable: {
        no: "Nie.",
        subQuestion: "Pytania pomocnicze",
        status: "Status",
        publishedDate: "Data opublikowania",
        notApplicable: "Nie dotyczy"
      },
      links: {
        question_link_button: "Podłącz",
        question_link_include_folder: "Dodaj podfolder",
        question_link_add_link: "Podłącz plik/i z biblioteki",
        question_link_view_folder: "Pokaż bibliotekę"
      },
      mainQuestion: "Nowe/Zaktualizowane pytania główne:",
      subQuestion: "Nowe/Zaktualizowane pytania pomocnicze:",
      retiredQuestion: "Wycofane pytania pomocnicze:",
      libraries: "Nowe/Zaktualizowane pliki w bibliotekach:",
      noFiles: "Brak dostępnych plików."
    },
    library: {
      selectFile: "Proszę wybrać przynajmniej jeden plik.",
      fileSelected: "Wybrane pliki",
      publish_library_file: "Proszę wybrać przynajmniej jeden plik do opublikowania",
      library_files_deleted: "Czy na pewno chcesz usunąć ten plik?",
      library_files_linked_deleted: "Czy na pewno chcesz usunąć ten plik i łącza utworzone w kwestionariuszu?",
      max_size_error: "Rozmiar plików przekracza limit 1 GB na jedną sesję ładowania.",
      empty_file_desc: "Proszę wprowadzić opis pliku.",
      file_not_selected: "Proszę wybrać pliki/i do załadowania (max. 1GB)",
      file_type_error: "Ten typ pliku jest niedozwolony.",
      file_name_error: "Nieprawidłowy znak w nazwie pliku.",
      file_name_lenght_error: "Nazwa pliku może zawierać maksymalnie 50 znaków.",
      addFolder: "Dodaj folder",
      editFolder: "Edytuj folder",
      currentFolder: "Nazwa folderu",
      parentFolder: "Nazwa folderu nadrzędnego",
      emptyFolderName: "Pole nazwy folderu nie może być puste.",
      invalidFolderName: "Nazwa folderu nie może zawierać znaku „/”.",
      folder_created: "Folder został pomyślnie utworzony.",
      folder_updated: "Folder został pomyślnie zaktualizowany.",
      folder_not_found: "Folder nie został odnaleziony",
      lib_invalid_toolbox: "Wybrano nieprawidłowy panel biznesowy",
      lib_folder_already_exists: "Ten folder już istnieje.",
      lib_folder_deleted: "Folder został pomyślnie usunięty.",
      library_files_published: "Pliki zostały opublikowane.",
      addFile: "Dodaj plik",
      editFile: "Edytuj plik",
      fileName: "Nazwa pliku",
      upload: "Upload",
      desc: "Opis",
      folderCreationErr: "Hierarchia ograniczona do dwóch poziomów.",
      subFolderWarning: "Proszę wybrać podfolder, aby dodać plik/i.",
      deleteWarning: "Proszę wybrać folder do usunięcia.",
      beforeAfterNotSelected: "Please select if the evidence is before or after kaizen",
    },
    chapter: {
      chapter_exist: "Ten rozdział już istnieje.",
      chapter_saved: "Zapisano rozdział",
      chapter_cannot_delete: "Rozdział ten nie może zostać usunięty, ponieważ pytania pomocnicze zostały już opublikowane.",
      failed: "Nie powiodło się!",
      chapter_deleted: "Rozdział usunięty",
      chapter_nonEmpty: "Proszę utworzyć nazwę rozdziału.",
      addchapter: "Dodaj rozdział",
      editChapter: "Edytuj rozdział",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Załączniki",
      comments: "Komentarze",
      evidence: "Potwierdzenie",
      evidences: "Potwierdzenie/a",
      yes: "Tak",
      no: "Nie",
      na: "N/A",
      uploadEvidence: "Załaduj potwierdzenie",
      fileUpload: "Proszę wybrać pliki/i do załadowania (max. 1GB)",
      who: "Kto?",
      answer: "Odpowiedz",
      comment: "Comment",
      date: "Data",
      submit: "Wyślij",
      commentMsg: "Proszę wpisać komentarze.",
      noCommentAvailable: "Brak dostępnych komentarzy.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Istnieje już plik o tej samej nazwie.",
      noEvidence: "Brak dostępnych dowodów",
      createAction: "Utwórz wpis dla działania",
      chapterName: "Nazwa rozdziału",
      questionNo: "Pytanie nr",
      when: "Kiedy?",
      noRecords: "Brak dostępnych danych.",
      kaizenPoints: "Punkty Kaizen",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Pytanie",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Is this evidence from before or after kaizen?",
      move:"Move",
      moveMsg: "File(s) has been moved successfully",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "WSBA",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Blacharstwo-Lakiernictwo",
      TSM_USED_CAR: "Używane samochody",
      value_chain: "Łańcuch wartości",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Tablica ogłoszeń",
      kpi_target_not_set: "Nie ustawiono celów KPI",
      kpi_initial_not_set: "Początkowy pomiar KPI nie został ustawiony",
      kpi_monthly_not_set: "Nie ustawiono miesięcznego pomiaru KPI",
      kpi_below_target: "Istnieją wskaźniki KPI poniżej celów",
      activation: "Aktywacja",
      users: "Użytkownicy",
      nmscs: "NMSC's",
      activeRetailer: "Aktywne stacje",
      certificationRate: "Wskaźnik certyfikacji",
      reCertificationRate: "Wskaźnik recertyfikacji",
      activityDue: "Termin działań",
      avgdays: "Średnia ilość dni / Toolbox",
      overDueAct: "Opóźnione działania",
      actionItem: "Działania",
      bussResult: "Wyniki biznesowe",
      toolboxImpl: "Wprowadzenie panelu biznesowego",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Ostatnie aktualizacje",
      rolloutTopNmsc: "Wyniki wdrożenia - Najlepsze NMSC",
      bestPractice: "Tablica najlepszych praktyk",
      availableKpi: "Dostępne wskaźniki KPI",
      selectedKpi: "Wybrane wskaźniki KPI",
      dealerBestPractice: "Rada najlepszych praktyk dealerów",
      kpiList: "Lista wskaźników KPI",
      go: "Idź",
      nodata: "Brak dostępnych danych",
      businessResultTop: "Wyniki biznesowe - Najlepsze stacje",
      businessResultNMSC: "Wyniki biznesowe - Najlepsze NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certyfikat dealera",
      readyForCertification: "Gotowy do certyfikacji",
      certification: "Certyfikacja",
      submittedOn: "Wysłano dnia",
      certificateDate: "Data certyfikatu",
      reCertificateDate: "Data ponownego certyfikatu",
      toolboxDetails: "Szczegóły panelu biznesowego",
      approve: "Akceptuj",
      reject: "Odrzuć",
      pending: "W trakcie",
      region: "Region"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Identyfikator wykonania",
      uploadedOn: "Przesłane w dniu",
      lastUpdatedOn: "Ostatnia aktualizacja w dniu",
      noDataTable: "No data to view",
      kpiUpload: "Przesyłanie KPI",
      action: "Akcja",
      processed: "Obrobiony",
      error: "Błąd",
      received: "Received",
      createActivity: "Utwórz działania",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Zarządzanie użytkownikami",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Imię",
      lastName: "Nazwisko",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database. {0}",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Konto PowerBI",
      invalidPbiAccount : "Konto PowerBI powinno zawierać co najmniej 3 znaki.",
      businessArea: "Obszar biznesowy",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Tylko Value Chain",
      britOnly: "Tylko BRiT",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Tylko",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Imię",
      lastName: "Nazwisko",
      emailId: "E-mail",
      languageCode: "Kod języka",
      ACTIVE: "Active",
      INACTIVE: "Nieaktywny",
      addUser: "Dodaj użytkownika",
      Assign: "Przydziel",
      List: "Lista",
      user: "Użytkownik",
      error_message: "Wystąpił błąd. Spróbuj ponownie później.",
      role: "Rola użytkownika",
      standardMessage: "No User Found",
      dealerCode: "Kod sprzedawcy",
      a2d: "A2D UUID",
      britReady: "Dealer uczestniczył w Brit Ready.",
      britLive: "Dealer uczestniczył w Brit Live.",
      britJapan: "Dealer uczestniczył w Brit Discovery Japan.",
      britEss:"Dealer zaktualizował Plan Działań EES",
      addDealer: "Dodaj sprzedawcę (z A2D)",
      noDealer: "Nie znaleziono dealera (ów).",
      dealerUuid: "Kod uuid dealera (A2D)",
      language: "Język",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Wprowadź prawidłowy identyfikator UUID A2D.",
      invalidDealerName: "Wprowadź prawidłową nazwę dealera.",
      invalidCity: "Podaj prawidłową lokalizację.",
      invaliDealerCode: "Wprowadź prawidłowy kod dealera.",
      existingUuid: "Kod uuid A2D jest już obecny w bazie danych KET.",
      updateUuid: "Zaktualizuj kod UUID A2D",
      newUuid: "Nowy kod uuid A2D",
    },
    networkManagement: {
      networkManagement: "Zarządzanie siecią",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Pliki do pobrania",
      historyFiles:"Witamy na stronie internetowej REACH IT. ",
      reachText1:"Witamy na stronie internetowej REACH IT.Zespół do spraw Zarządzania Środowiskowego TME wykorzystuje tę stronę do udostępnienia istotnych informacji związanych z SCIP : {(SCIP - Substances of Concern In Products)  Substancje Potencjalnie Niebezpieczne W Produktach  jako takich lub w ich mieszaninach} ustanowionych na mocy dyrektywy ramowej w sprawie odpadów (RDW) w celu wypełnienia obowiązku przetwarzania oraz uproszczonego zgłoszenia SCIP w krajowych instytucjach nadzorczych. Np. ELDIOM",
      reachText2:"W razie pytań prosimy o kontakt z zespołem Zarządzania Środowiskowego TME poprzez poniższy link",
      reachText3:"W przypadku pytań proszę kontaktować się ze swoim NMSC (TCE)",
      envMngt:"Zarządzanie Środowiskowe",
      fileStatistics: "Historia pobranych plików",
      Downloaded: "Pobrane pliki",
      not: "Nie",
      exportToExcel:"Export do Exela"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsPL;
