import { Col, Container, Row } from "reactstrap";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-super-treeview/dist/style.css";
import { Treebeard } from "react-treebeard";
import {
  getEvaluationChapters,
  getActivityDetails,
  getEvaluationVersion,
} from "../../../actions/evaluationAction";
import * as AppConst from '../../../AppConstant'
import { fetchKaizenPoints, kaizenCounter } from "../../../actions/kaizenAction";
import { withTheme } from "../../../themes/theming";
import ActionPlanModal from "../../actionPlanModal";
import QuestionDetails from "./questionDetails";
import "../index.css";
import { toSetSelectedQuestionId } from "../../../actions/mainQuestionAction";
import Style from "../style";
import { Tab } from "react-bootstrap-tabs";
import { ProgressBar } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import KaizenPoints from "./kaizenPoints";
import { launchKaizen,reAuditReEvaluate } from "../../../actions/activityAction";
import AuditQuestionDetails from "./auditQuestionDetails";
import ActionPlanTable from "../../actionPlanTable";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';

var I18n = require("react-redux-i18n").I18n;
const $ = require("jquery");

class KaizenSection extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.handleNextPage = this.handleNextPage.bind(this);
    this.handlePrevPage = this.handlePrevPage.bind(this);
    this.handleCurrentPage = this.handleCurrentPage.bind(this);
    this.state = {
      selectedCriticalityList:[
        {label:I18n.t('standardMessages.all'),value:"All"},
        {label:I18n.t('questionnaire.cr'),value:"Critical"},
        {label:I18n.t('questionnaire.nonCritical'),value:"Non-Critical"}
      ],
      selectedCriticality:"All",
      collapse: false,
      modal: false,
      evaluationTree: [],
      modalFor: "",
      selectedNodeId: "",
      versionId: "",
      progressBarGreen: 0,
      progressBarRed: 0,
      progressBarYes: 0,
      progressBarNo: 0,
      progressBarNa: 0,
      previousPage: true,
      nextPage: true,
      toggleKaizen: false,
      toggleActionItem: false,
      activityDetails: {},
      modalActionPlan: false,
      isButtonDisabled: false,
      ansSubquestionList: [],
      answerData: [],
      notApplicable:
        this.props.selectedRow && this.props.selectedRow.notApplicable === true
          ? true
          : false,
      critical:
        this.props.selectedRow && this.props.selectedRow.isCritical === true
          ? true
          : false,
          selectedToCreateAction: {},
      isLaunchedKaizenClicked: false,
      isLaunched: false,
      kaizenCounterNumber: []
    };
    this.onToggle = this.onToggle.bind(this);
    this.createActionItem = this.createActionItem.bind(this);
    this.openActionPlanTable = this.openActionPlanTable.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    /**Fetch counter */
    if (this.props?.fetchAllQuestion && this.props?.fetchAllQuestion !== prevProps.fetchAllQuestion) {
      const mainQIds = [].concat(...this.props?.fetchAllQuestion.map(item => 
        Array.isArray(item?.children) ? item?.children.map(child => child?.mainQId) : []
      ));
      this.props.dispatch(kaizenCounter(this.onSuccessGetCounter,this.onFailureGetCounter, this.props.activityId, mainQIds));
    }
    /** */
    $(".lib-node")
      .unbind()
      .on("click", function () {
        let element = $(this).find(".libraryToggleArrow")[0];
        if (element !== undefined) {
          if (element.classList.contains("rotate-true")) {
            $(element).removeClass("rotate-true").addClass("rotate-false");
          } else {
            $(element).removeClass("rotate-false").addClass("rotate-true");
          }
        }
      });
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: "EVALUATION_CHAPTERS",
      payload: null,
    });
  }
  /** Callback on success for fetch counter */
  onSuccessGetCounter=(response)=>{
    this.setState({kaizenCounterNumber: response})
   
  }

   /** Callback on fail for fetch counter */
  onFailureGetCounter=(error)=>{
    console.log("Error while fetching list of video",error)
  }

  componentDidMount() {
    let dealerCode = this.props.user.userMdl.nmsc;
    let dealerLang = this.props.user.userMdl.lang.toUpperCase();
    let toolboxId = this.props.toolbox;
    const activityId = this.props.activityId;
    const isLaunched = localStorage.getItem(`kaizenLaunched_${activityId}`);

    // console.log(dealerCode + " " + dealerLang + " " + toolboxId);
    this.setState({ activityid: this.props.activityId });
    this.props.dispatch(getEvaluationVersion(this.props.activityId, 'FIRST_AUDIT'));
    this.props.dispatch(getActivityDetails(this.props.activityId));
    this.props.dispatch(fetchKaizenPoints(this.props.activityId));
    if (isLaunched) {
      this.setState({ isLaunched: true });
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.evaluation.evaluationVersion) {
      this.setState({ versionId: nextProps.evaluation.evaluationVersion });
    }
    if (nextProps.evaluation.activityDetails) {
      this.setState({ activityDetails: nextProps.evaluation.activityDetails });
    }
    if (nextProps.evaluation.evalautionProgressBar) {
      // console.log(nextProps.evaluation.evalautionProgressBar)
      var progressBar = nextProps.evaluation.evalautionProgressBar.split("|");
      var yes = progressBar[0] !== undefined ? parseInt(progressBar[0]) : 0;
      var no = progressBar[2] !== undefined ? parseInt(progressBar[2]) : 0;
      var na = progressBar[1] !== undefined ? parseInt(progressBar[1]) : 0;
      var unanswered =
        progressBar[3] !== undefined ? parseInt(progressBar[3]) : 0;
      var totalQuestions =
        progressBar[4] !== undefined ? parseInt(progressBar[4]) : 0;
      var answers = progressBar[5] !== undefined ? parseInt(progressBar[5]) : 0;

      var totalIncomplete =
        progressBar[6] !== undefined ? parseInt(progressBar[6]) : 0;
      var progressBarGreen = (yes / (totalQuestions - na)) * 100;
      var progressBarRed = 100 - progressBarGreen;
      // console.log(progressBarGreen, progressBarRed);
      this.setState({
        activityCompletedAnswers: answers,
        activityCompletedQuestions: totalQuestions,
      });
      this.setState({
        progressBarGreen: progressBarGreen,
        progressBarRed: progressBarRed,
      });
      this.setState({
        progressBarYes: yes,
        progressBarNo: no,
        progressBarNa: na,
        progressBarTotalQuestions: totalQuestions,
      });
      const selectedRow = this.initializeSelectedToCreateAction();
      this.setState({ selectedToCreateAction: selectedRow });
    }
    /*         if (nextProps.evaluation.evaluationActivityCompleted) {
            var evaluation = nextProps.evaluation.evaluationActivityCompleted.split('|');
            var questions = evaluation[1] !== undefined ? evaluation[1] : 0;
            var answers = evaluation[0] !== undefined ? evaluation[0] : 0;
            this.setState({ activityCompletedAnswers: answers, activityCompletedQuestions: questions });
        } */
    if (
      nextProps.evaluation.evaluationChapters &&
      this.state.versionId !== 9999
    ) {
      if (
        nextProps.evaluation.evaluationChapters &&
        nextProps.evaluation.evaluationChapters.length > 0
      ) {
        if (this.state.selectedNodeId === "") {
          let selectedNodeId =
            nextProps.evaluation.evaluationChapters[0].children[0].mainQId;
          this.setState({ selectedNodeId: selectedNodeId });
          this.setState({
            selectedChapterId:
              nextProps.evaluation.evaluationChapters[0].chapterId,
          });
        }
      }
      this.setState({
        evaluationTree: nextProps.evaluation.evaluationChapters,
      });
    }
    this.handleCurrentPage();
    if (nextProps.evaluation.refreshRequired && this.state.versionId !== 9999) {
      let values = [];
      let dealerCode = this.props.user.userMdl.nmsc;
      let dealerLang = this.props.user.userMdl.lang.toUpperCase();
      let toolboxId = this.props.toolbox;

      if (nextProps.libraryReducer.folderId !== undefined) {
        values = nextProps.libraryReducer.folderId.split("|");
      }
      let id = values[0] !== undefined ? values[0] : 0;
      let name = values[1] !== undefined ? values[1] : "";
      // Get latest list of Library folders.
      if (this.state.cursor) {
        this.props.dispatch(
          getEvaluationChapters(
            nextProps.evaluation.evaluationVersion,
            this.props.activityId
          )
        );
        this.setState({ selectedNodeId: id });
        var cursor = this.state.cursor;
        cursor.mainQId = id;
        cursor.name = name;
        if (this.state.modalFor === "Add") {
          cursor.isParent = false;
        }
        this.setState({ cursor: cursor });
      } else {
        this.props.dispatch(
          getEvaluationChapters(
            nextProps.evaluation.evaluationVersion,
            this.props.activityId
          )
        );
        this.setState({ selectedNodeId: id });
        var cursor = {};
        cursor.mainQId = id;
        cursor.name = name;
        cursor.isParent = true;
        this.setState({ cursor: cursor });
      }
      this.setState({ showDelete: false, showEdit: false });
      // alert('Refresh!!' + this.props.toolboxId);
      this.props.dispatch({
        type: "REFRESH_DONE",
        payload: "",
      });
    }

    if (nextProps.evalautionProgressBar) {
      this.setState({ ansSubquestionList: nextProps.evalautionProgressBar });
    }

    if (nextProps.evaluationSubQuestion) {
      this.setState({ answerData: nextProps.evaluationSubQuestion });
    }
  }

  handleCurrentPage() {
    if (
      this.state.selectedNodeId &&
      this.state.evaluationTree &&
      Array.isArray(this.state.evaluationTree)
    ) {
      this.setState({ previousPage: true, nextPage: true });
      let chapterQ = [];
      let questionIndex = 0;
      this.state.evaluationTree.map((que) => {
        que.children.map((child) => {
          if (child.mainQId === this.state.selectedNodeId) {
            chapterQ = [...que.children];
            questionIndex = que.children.indexOf(child);
          }
        });
      });
      if (questionIndex === 0 && questionIndex === chapterQ.length - 1) {
        this.setState({ previousPage: false, nextPage: false });
      } else if (questionIndex === chapterQ.length - 1) {
        this.setState({ nextPage: false, previousPage: true });
      } else if (questionIndex === 0 && questionIndex !== chapterQ.length - 1) {
        this.setState({ previousPage: false, nextPage: true });
      } else {
        this.setState({ previousPage: true, nextPage: true });
      }
    }
  }

  handleNextPage() {
    let chapterQ = [];
    let questionIndex = 0;
    let selectedChapterId = 0;
    this.state.evaluationTree.map((que) => {
      que.children.map((child) => {
        if (child.mainQId === this.state.selectedNodeId) {
          chapterQ = [...que.children];
          questionIndex = que.children.indexOf(child);
          selectedChapterId = que.chapterId;
        }
      });
    });
    questionIndex = questionIndex + 1;
    this.setState({
      selectedNodeId: chapterQ[questionIndex].mainQId,
      selectedChapterId: selectedChapterId,
    });
    this.props.dispatch(
      toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
    );
    this.props.dispatch(
      getEvaluationChapters(
        this.state.versionId,
        this.props.activityId,
        selectedChapterId,
        chapterQ[questionIndex].mainQId
      )
    );
    this.scrollToTop();
  }
  handlePrevPage() {
    let chapterQ = [];
    let questionIndex = 0;
    let selectedChapterId = 0;
    this.state.evaluationTree.map((que) => {
      que.children.map((child) => {
        if (child.mainQId === this.state.selectedNodeId) {
          chapterQ = [...que.children];
          questionIndex = que.children.indexOf(child);
          selectedChapterId = que.chapterId;
        }
      });
    });
    questionIndex = questionIndex - 1;

    this.setState({
      selectedNodeId: chapterQ[questionIndex].mainQId,
      selectedChapterId: selectedChapterId,
    });
    this.props.dispatch(
      toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
    );
    this.props.dispatch(
      getEvaluationChapters(
        this.state.versionId,
        this.props.activityId,
        selectedChapterId,
        chapterQ[questionIndex].mainQId
      )
    );
    this.scrollToTop();
  }

  // createActionItem = (selectedQuestions) => {
    initializeSelectedToCreateAction = (selectedQuestions = []) => {
      var selectedRow = {};
      var description = "";
      var questionReference = "";
      selectedQuestions.map((question) => {
        if (description !== "") {
          description = description + "\n" + question.comments;
        } else {
          description = question.comments;
        }
        if (questionReference !== "") {
          questionReference = questionReference + "," + question.questionNo;
        } else {
          questionReference = question.questionNo;
        }
      });
      // selectedRow["status"]= this.props.activityStatus;
      selectedRow["activityId"] = this.props.activityId;
      selectedRow["actionPlanId"] = 0;
      selectedRow["actionPlanDisplayId"] = 0;
      selectedRow["description"] = description;
      selectedRow["counterMeasure"] = "";
      selectedRow["questionReference"] = questionReference;
      selectedRow["userId"] = this.props.user.userMdl.username;
      selectedRow["status"] = "Open";
      selectedRow["followUp"] = "";
      selectedRow["assignee"] = "";
      selectedRow["endDate"] = "";
      selectedRow["startDate"] = "";
      selectedRow["userMdl"] = this.props.user.userMdl;
      selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
      selectedRow["langCode"] = this.props.user.userMdl.lang;
      selectedRow["userId"] = this.props.user.userMdl.username;
      return selectedRow;
    };
    //New changes
    createActionItem = (selectedQuestions) => {
      const selectedRow =
        this.initializeSelectedToCreateAction(selectedQuestions);
      this.setState({ selectedToCreateAction: selectedRow });
      this.toggleKaizen();
      this.toggleActionItem();
    };
  deactiveNode = (currentNode) => {
    var flag = false;
    var nodeList = this.state.evaluationTree;
    for (var i = 0; i < nodeList.length; i++) {
      var parn = nodeList[i];
      var node = nodeList[i].children;
      if (node) {
        for (var j = 0; j < node.length; j++) {
          var child = node[j];
          if (child.mainQId !== currentNode.mainQId) {
            child.active = false;
          } else {
            flag = true;
          }
        }
      }
      if (!flag) {
        // parn.toggled = false;
        parn.active = false;
      }
    }
    this.setState({ evaluationTree: nodeList });
  };
  onToggle(node, toggled) {
    this.deactiveNode(node);
    //Child Node(Question Title)
    if (this.state.cursor) {
      this.state.cursor.active = false;
    }
    if (node.mainQId) {
      this.setState({ selectedNodeId: node.mainQId });
      node.active = true;
    }
    //Parent Node (Chapter)
    else {
      this.setState({ selectedNodeId: node.children[0].mainQId });
      node.children[0].active = true;
      this.setState({ selectedChapterId: node.chapterId });
    }
    if (node.children && node.children.length > 0) {
      node.toggled = toggled;
    }
    this.setState({ cursor: node });

    this.props.dispatch({
      type: "EVALUATION_ID",
      payload: null,
    });
  }

  traverse(node, path, list) {
    let that = this;
    if (!path) {
      path = [];
    }
    let obj = {
      id: node.mainQId,
      name: node.name,
    };
    path.push(obj);
    list.push(path);
    if (node.children) {
      node.children.forEach(function (item) {
        let newPath = [];
        newPath.push(path);
        that.traverse(item, newPath, list);
      });
    }
  }
  togglePane = () => {
    $(".rightPanel").hide();
    $("#libSideNav").toggle("fast", function () {
      let width =
        ($(".rightPanel").width() / $(".rightPanel").parent().width()) * 100;
      if (width > 90) {
        $(".rightPanel").css({ width: "72%" });
      } else {
        $(".rightPanel").css({ width: "97%" });
      }
      $(".rightPanel").show();
    });
  };

  toggleKaizen = () => {
    this.setState({ toggleKaizen: !this.state.toggleKaizen });
  };
  toggleActionItem = () => {
    this.setState({ toggleActionItem: !this.state.toggleActionItem });
  };
  openActionPlanTable = () => {
    // this.setState({modalActionPlan:!this.state.modalActionPlan});
    // this.props.selectTab(3);
  };

  scrollToTop = () => window.scrollTo(0, 0);
  onSelectedCriticalityChange = (selectedOption) => {
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
    })
    this.setState({ selectedCriticality: selectedOption })
}
LaunchKaizenSection = (e) => {
  e.preventDefault();
  const activityId = this.props.activityId;
  localStorage.setItem(`kaizenLaunched_${activityId}`, true);
  this.setState({ isLaunched: true });
  // Existing logic
  var launchKaizenDto = {
    businessAreaKey: this.props.user.userMdl.businessAreaKey,
    activityId: this.props.activityId,
    activityStatus: this.props.activityStatus,
    kaizen: this.props.kaizen,
  };
  let response = this.props.dispatch(launchKaizen(launchKaizenDto));
  console.log(response);
};
  reEvaluationClick = (e) => {
    let bodyForReAudit = {
      activityId: this.props.activityId,
      loginId: this.props.user.userMdl.username,
    }
    this.props.dispatch(reAuditReEvaluate(bodyForReAudit));
  };

  calculateTotalAnswer = () =>{
    let results = [];
    for (var i = 0; i < this.state?.ansSubquestionList?.length; i++) {
    var evaluation = this.state?.ansSubquestionList.split('|');
    var total = evaluation[4] !== undefined ? parseInt(evaluation[4]) : 0;
    results.push({
      total,
    });
    const res = results.map((item)=> item.total)
   return res;
}

  }

  /** Function to calculate critical and non-critical questions whose response is incomplete and No */
  calculateAnswers = () => {
    let noCountNotCritical = 0;
    let incompleteCountNotCritical = 0;
    let noCountCritical = 0;
    let incompleteCountCritical = 0;
  
    // Loop through answer data and categorize by critical and non-critical
    this.state?.kaizenCounterNumber.forEach(item => {
      const { answer, isCritical } = item;
  
      if (isCritical) {
        // For critical items, only count "No" and "Incomplete"
        if (answer === -1) noCountCritical++;
        if (answer === 2) incompleteCountCritical++;
      } else {
        // For non-critical items, count all answers
        if (answer === -1) noCountNotCritical++;
        if (answer === 2) incompleteCountNotCritical++;
      }
    });
  
    return {
      noCountNotCritical,
      incompleteCountNotCritical,
      noCountCritical,
      incompleteCountCritical
    };
  };
  
    /** Function to calculate answers without considering critical or non-critical */
    calculateAnswersWithoutCritical = () => {
      let yesCount = 0;
      let incompleteCount = 0;
  
      // Loop through answer data and count "No" and "Incomplete" answers
      this.state?.kaizenCounterNumber.forEach(item => {
        const { answer } = item;
  
        if (answer === 1) yesCount++;        // Count "No" answers
        if (answer === 2) incompleteCount++; // Count "Incomplete" answers
      });
  
      return {
        yesCount,
        incompleteCount
      };
    };


  render() {
    /**Destructuring the required values from function */
    const {
      noCountNotCritical,
      incompleteCountNotCritical,
      noCountCritical,
      incompleteCountCritical
    } = this.calculateAnswers();

    const { yesCount, incompleteCount } = this.calculateAnswersWithoutCritical();
    /** */

    const totalAnswers = this.calculateTotalAnswer(); // Fetch the number of total questions answered
    const totalPointsGained = yesCount + (incompleteCount * 0.5); //Formula for score 
    const percentageScrore = ((totalPointsGained/totalAnswers)*100).toFixed(3); // fix the decimal value upto three digits

    if (this.props.theme.name === "lexus" && $("div.toyota")) {
      $(".toggleTreebeard").removeClass("toyota");
      $(".toggleTreebeard").addClass("lexus");
      $(".toggleTreebeard").attr("src", "/images/upArrow-lexus.png");
    } else if (this.props.theme.name === "toyota" && $("div.lexus")) {
      $(".toggleTreebeard").removeClass("lexus");
      $(".toggleTreebeard").addClass("toyota");
      $(".toggleTreebeard").attr("src", "/images/T_Artboard 21mdpi.png");
    } else {
      //..
    }
    let userLang = this.props.user.userMdl.userLang;
    let userRole = this.props.user.userMdl.userRole;
    let accessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      accessType = this.props.user.userMdl.accessControl.filter(
        (obj) => obj.function === "ACTIVITY_EVALUATION"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
            (obj) => obj.function === "ACTIVITY_EVALUATION"
          )[0].accessType
        : "";
    }

    const dynamicKPITab = this.props.user.userMdl.userLangList.map(
      (obj, index) => {
        return <Tab label={obj.country + " / " + obj.language} key={index} />;
      }
    );

    dynamicKPITab.unshift(<Tab label="TME Master" key="tmeMaster" />);

    // Disable Kaizen Points button if kaizen section is completed
    const disableKaizenPointsButton = this.props.activityDetailsData && (this.props.activityDetailsData.kaizen === "COMPLETED") ? true : false

    return (
      <div>

        {((this.props.user.userMdl.roles.includes("KET TME Admin") ||
        this.props.user.userMdl.roles.includes("KET-TSP Auditor")
          ? "R"
          : accessType) === "R" ||
          accessType === "R/W") &&
          //(accessType === "R" || accessType === "R/W") &&
        this.state.versionId !== 9999 ? (
          <Container fluid className="ml-0 p-0 libraryContainer">
            {this.state.evaluationTree.length > 0 ? (
              <div>
                <Row style={{ minHeight: "486px", paddingLeft: "5px" }}>
                  <div
                    id="libSideNav"
                    style={{
                      paddingLeft: "4px",
                      borderRight: "1px solid lightGray",
                      width: "24%",
                    }}
                  >
                      <div className={"col-md-1.5 noPadding smallDD-" + this.props.theme.name}>
                        <ReactMultiSelectCheckboxes
                          value={this.state.selectedCriticality}
                          options={this.state.selectedCriticalityList}
                          onChange={this.onSelectedCriticalityChange}
                          placeholderButtonLabel={I18n.t('standardMessages.all')}
                        />
                        </div>
                      {/* <Row className="mb-1 bottomBorder canShrink" style={{ height: '30px' }}>
                                            <Col className="d-flex p-1" style={{ display: 'inherit', padding: '5px' }}>
                                                <div className="answerCountsTitle">{I18n.t("others.completed")}:</div>
                                                <div className="answerCountsAns">({this.state.activityCompletedAnswers}</div>
                                                <div className="answerCountsTotal">/{this.state.activityCompletedQuestions})</div>
                                            </Col>
                  </Row> */}

                

                    {/* <Row className="treeBeardContainter evaluationTree">
                      <Scrollbars
                        universal
                        thumbSize={300}
                        hideTracksWhenNotNeeded={true}
                        //autoHide
                        //autoHideTimeout={1000}
                        //autoHideDuration={200}
                        renderThumbVertical={({ style, ...props }) => (
                          <div
                            {...props}
                            style={{
                              ...style,
                              backgroundColor: this.props.theme.primaryColor,
                              width: "4px",
                              opacity: "0.7",
                              cursor: "pointer",
                            }}
                          />
                        )}
                      >
                        <Treebeard
                          data={this.state.evaluationTree}
                          onToggle={this.onToggle}
                          style={Style}
                          decorators={libraryDecorator}
                        />
                      </Scrollbars>
                    </Row> */}
                  </div>
                  <div className="pl-0 pr-0 thickRightBorder">
                    <div
                      className="pl-0 pr-0 d-flex"
                      style={{
                        height: "100%",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => this.togglePane()}
                    >
                      <img
                        id="toggle"
                        src={
                          "/images/collapsebtn-" +
                          this.props.theme.name +
                          ".png"
                        }
                        className="align-self-center"
                        style={{ height: "50px", cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="pl-1 m-1 rightPanel dealerRightPanel">
                    <Row className="p-1" style={{ minHeight: "30px" }}>
                    <Col lg="4" md="4" sm="4" xs="4">
                      {console.log("Rolewise",this.props.user.userMdl.roles)}
                      {/* {console.log("firstAudit",this.props.firstAudit)} */}
                      {(this.props.user.userMdl.roles.includes(
                          "KET NMSC Admin"
                        ) ||
                          this.props.user.userMdl.roles.includes(
                            "KET Dealer Champion"
                          )) && (
                      !this.state.isLaunched && <button
                          className={"button-" + this.props.theme.name}
                          disabled={this.props.activityDetailsData &&
                            (this.props.activityDetailsData.firstAudit === "PASSED" ||
                            this.props.activityDetailsData.firstAudit === "FAILED")
                          //   &&
                          //  (this.props.user.userMdl.roles.includes(AppConst.KET_NMSC_ADMIN)
                          //    || this.props.user.userMdl.roles.includes(AppConst.KET_Dealer_Champion)) 
                             ? false : true
 
                          }
                          onClick={
                            (e) =>
                              this.LaunchKaizenSection(e) 
                          }
                        >
                        
                          {I18n.t("Launch Kaizen Section")}
                        </button>
                          )}
                      </Col>
                      {(this.props?.activityDetailsData?.firstAudit == "FAILED"  || this.props?.activityDetailsData?.firstAudit == "PASSED") && <Col lg="2" md="1" sm="1" xs="1">
                      <div className="border " style={{justifyItems: "center"}}>
                        <div className="border-bottom answers-card-container">
                          <p  className="answers-card-headers">Gap Critical Items</p>
                        </div>
                       <div>
                          <p className="answers-card-values">{ noCountCritical+incompleteCountCritical}</p>
                        </div>
                      </div>
                      </Col>}
                      {(this.props?.activityDetailsData?.firstAudit == "FAILED"  || this.props?.activityDetailsData?.firstAudit == "PASSED") && <Col lg="2" md="1" sm="1" xs="1">
                      <div className="border" style={{justifyItems: "center"}}>
                        <div className="border-bottom answers-card-container">
                          <p className="answers-card-headers">Gap Non-Critical</p>
                        </div>
                        <div>
                        <p  className="answers-card-values">{noCountNotCritical+incompleteCountNotCritical}</p>
                        </div>
                      </div>
                      </Col>}
                      {(this.props?.activityDetailsData?.firstAudit == "FAILED"  || this.props?.activityDetailsData?.firstAudit == "PASSED") && <Col lg="2" md="1" sm="1" xs="1">
                      <div className="border" style={{justifyItems: "center"}}>
                        <div className="border-bottom answers-card-container">
                          <p className="answers-card-headers">Overall Score %</p>
                        </div>
                        <div>
                         {/* <p className="answers-card-values">{percentageScrore}</p> */}
                         <p className="answers-card-values">{this.props.score}</p>
                        </div>
                      </div>
                      </Col>}
                      <Col
                        lg="2"
                        md="2"
                        sm="5"
                        xs="5"
                        className="d-flex justify-content-end"
                      >
                        {/* { (this.props.user.userMdl.roles.includes("NMSC ADMIN MENU") || this.props.user.userMdl.roles.includes(" DEALER KAIZEN CHAMPION MENU ")  ) ?       */}

                        {
                        //this.props.kaizen?
                        this.state.isLaunched ||
                        localStorage.getItem(
                          `kaizenLaunched_${this.props.activityId}`
                        ) === "true" ? 
                        <button
                          className={"button-addfile-" + this.props.theme.name}
                          style={{ fontSize: "13px" }}
                          // onClick={() => this.toggleKaizen()}
                          disabled={disableKaizenPointsButton}
                          onClick={
                            (e) =>
                              //   (this.props.user.userMdl.roles.includes("NMSC ADMIN MENU") || this.props.user.userMdl.roles.includes(" DEALER KAIZEN CHAMPION MENU ")
                              //   ) ?
                              this.toggleKaizen(e)
                            //   : ""
                          }
                        >
                          {I18n.t("evaluation.kaizenPoints")}{" "}
                        </button>
                        :null}
                        {this.props.kaizen?
                        <div
                          className={
                            this.props.kaizenPoints.kaizenPointsData.length !==
                            0
                              ? "kaizenBlink-" + this.props.theme.name
                              : "kaizenBlinkStop-" + this.props.theme.name
                          }
                        />
                         :null}
                       
                      </Col>
                    </Row>

                    {/* <Row className="questionContent">
                                           {console.log("firstAudit",this.props.firstAudit)}
                                        {this.props.user.userMdl.activeProgram==="NAS" ?   
                                          <AuditQuestionDetails
                                                toolboxId={this.props.toolboxId}
                                                selectedNodeId={this.state.selectedNodeId}
                                                selectedChapterId={this.state.selectedChapterId}
                                                versionId={this.state.versionId}
                                                activityId={this.props.activityId} activityStatus={this.props.activityStatus}
                                                totalAnswerCount={this.state.activityCompletedAnswers}
                                                totalQuestionCount={this.state.activityCompletedQuestions}
                                                firstAudit = {this.props.firstAudit}
                                            /> :  
                                              <QuestionDetails
                                            toolboxId={this.props.toolboxId}
                                            selectedNodeId={this.state.selectedNodeId}
                                            selectedChapterId={this.state.selectedChapterId}
                                            versionId={this.state.versionId}
                                            activityId={this.props.activityId} activityStatus={this.props.activityStatus}
                                        />}
                                        </Row> */}

                                        <Row>
                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                              <div className="actionItemslistDt">
                                              <ActionPlanTable activity={this.state.selectedToCreateAction} searchCriteria={this.props.location.query.searchCriteria} 
                                              parentComponent={"KaizenSection"}
                                              />
                                              </div>
                                              {/* <div className="reEvaluationSec">
                                                  <button
                                                    style={{display:"flex",justifyContent:"flex-end"}}
                                                    className={"button-" + this.props.theme.name}
                                                    onClick={
                                                      (e) =>
                                                        this.reEvaluationClick(e)
                                                    }
                                                  >
                                                    {I18n.t("Table.reEvaluation")}
                                                  </button>
                                              </div> */}
                                                {this.state.nextPage ? (
                                                    <a>
                                                        <div
                                                            title={I18n.t("questionnaire.nextQuestion")}
                                                            className={"nextQuestion-"+this.props.theme.name}
                                                            onClick={this.handleNextPage}
                                                        />
                                                    </a>
                                                ) : (
                                                        <a>
                                                            <div
                                                                title={I18n.t("questionnaire.nextQuestion")}
                                                                className={"nextQuestion-disabled-"+this.props.theme.name}
                                                            />
                                                        </a>
                                                    )}
                                                {this.state.previousPage ? (
                                                    <a>
                                                        <div
                                                            onClick={this.handlePrevPage}
                                                            title={I18n.t("questionnaire.previousQuestion")}
                                                            className={"prevQuestion-"+this.props.theme.name}
                                                        />
                                                    </a>
                                                ) : (
                                                        <a>
                                                            <div
                                                                title={I18n.t("questionnaire.previousQuestion")}
                                                                className={"prevQuestion-disabled-"+this.props.theme.name}
                                                            />
                                                        </a>
                                                    )}
                                            </div>
                                        </Row>
                  </div>
                </Row>
              </div>
            ) : (
              <div style={{ textAlign: "center" }}>
                {I18n.t("standardMessages.noQuestion")}
              </div>
            )}
          </Container>
        ) : null}
        {this.state.toggleKaizen ? (
          <KaizenPoints
            createActionItem={this.createActionItem}
            activityStatus={this.props.activityStatus}
            activityId={this.props.activityId}
            toggleKaizen={this.toggleKaizen}
          />
        ) : (
          ""
        )}
        {this.state.toggleActionItem ? (
          <ActionPlanModal
            modal={this.state.toggleActionItem}
            toggle={this.toggleActionItem}
            modalFor="Create"
            selectedRow={this.state.selectedToCreateAction}
            activity={this.state.activityDetails}
            evaluationCreateActionItemOpen={true}
            evaluationCreateActionItem={this.openActionPlanTable}
          />
        ) : null}
      </div>
    );
  }
}
const libraryDecorator = {
  Header: ({ node, style }) => {
    const iconClass =
      node && node.mainQId !== 0 ? "fa fa-folder" : "fa fa-tasks-alt";
    const divStyle =
      node && node.children
        ? {
            display: "inline-block",
            width: "90%",
            height: "100%",
            verticalAlign: "text-top",
            marginLeft: "13px",
          }
        : { marginLeft: "2rem" };
    var activeClass = node.active ? "activeQuestion" : "";
    var answerFont =
      node && node.mainQId !== 0
        ? node.no > 0
          ? "redFont"
          : node.yes === node.question
          ? "greenFont"
          : "blackFont"
        : "blackFont";
    return (
      <div style={divStyle}>
        {node.children ? (
          <div>
            <div
              title={node.name}
              className={activeClass}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                wordBreak: "break-word",
                maxWidth: "85%",
                display: "inline-block",
              }}
            >
              {node.seqNumber}. {node.name + "  "}&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
            <span className="leftAnswerCountsTotal">(</span>
            <span className="leftAnswerCountsAns">{node.answer}</span>
            <span className="leftAnswerCountsTotal">
              /{node.question})&nbsp;&nbsp;
            </span>

            <span className="leftAnswerCountsTotal1">
              {Math.round((node.yes / (node.question - node.na)) * 100) + "%"}
            </span>
          </div>
        ) : (
          <div>
            {" "}
            <span
              title={node.name}
              className={activeClass}
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                wordBreak: "break-word",
                maxWidth: "87%",
                display: "inline-block",
              }}
            >
              {node.name + "  "}&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
            <span className="leftAnswerCountsTotal">(</span>
            <span className={"leftAnswerCountsAns " + answerFont}>
              {node.answer}
            </span>
            <span className="leftAnswerCountsTotal">/{node.question})</span>
          </div>
        )}
      </div>
    );
  },
  Toggle: ({ node, style }) => {
    return (
      <div style={{ display: "inline-block", width: "5%" }}>
        <img
          src={"/images/T_Artboard 21mdpi.png"}
          height={"25px"}
          width={"25px"}
          className="toggleTreebeard toyota p-1 rotate-true libraryToggleArrow"
          alt=""
        />
      </div>
    );
  },
  Loading: (props) => {
    return <div style={props.style}>loading...</div>;
  },
  Container: (props) => {
    return (
      <div
        className="pl-2 lib-node evalTree"
        onClick={props.onClick}
        style={{ paddingRight: "13px" }}
      >
        {props.node.children && props.node.children.length > 0 ? (
          <props.decorators.Toggle />
        ) : (
          <div />
        )}
        <props.decorators.Header node={props.node} />
      </div>
    );
  },
};

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    libraryReducer: state.libraryReducer,
    evaluation: state.evaluationReducer,
    kaizenPoints: state.kaizenReducer,
   // firstAuditDetails:state.evaluationReducer.firstAuditDetails,
    firstAudit:state.evaluationReducer.firstAudit,
    kaizen:state.activityReducer.kaizen,
    requestReAudit:state.activityReducer.requestReAudit,
    activityDetailsData: state.evaluationReducer.activityDetails,
    evalautionProgressBar: state.evaluationReducer.evalautionProgressBar,
    evaluationSubQuestion: state.evaluationReducer.evaluationSubQuestion,
    score: state.libraryReducer.score,
    fetchAllQuestion: state.questionSummaryReducer.fetchAllQuestion,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(KaizenSection)));
