export default function kaizenReducer(state = {
}, action) {
    switch (action.type) {
        case "KAIZEN_POINTS":
            return Object.assign({}, state, {
                kaizenPointsData: action.payload
            });
        default:
            return state;
    }
};