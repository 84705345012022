const translationsSL = {
  sl: {
    Policy: {
      HeaderMsg: "Obvestilo o zasebnosti za obdelavo vaših osebnih podatkov v okviru Kaizen Expert Tool",
      Message1: "To obvestilo o zasebnosti se nanaša na obdelavo vaših osebnih podatkov v okviru Kaizen Expert Tool. Vedno ga je treba prebrati skupaj s Splošno Toyotino politiko o zasebnosti in varovanju osebnih podatkov, v kateri so opisani splošni postopki obdelave vaših osebnih podatkov, ki jih morate sprejeti, da boste lahko nadaljevali.",
      Message2: "Toyota Motor Europe NV / SA, s sedežem na Avenue du Bourget / Bourgetlaan 60, 1140 Bruselj, Belgija, kot nadzornik podatkov, zbira vaše osebne podatke (kot je navedeno spodaj) za namene, kot so opisani spodaj.",
      Message3: "Kateri osebni podatki bodo zbrani?",
      Message4: "Zbirali bomo naslednje kategorije osebnih podatkov v Kaizen Expert Tool:",
      Table1: {
        tr1: "Kategorije osebnih podatkov:",
        tr2: "Uporabniško ime",
        tr3: "Uporabniško ime (polno ime)",
        tr4: "Znamka (Toyota, Lexus)",
        tr5: "Jezikovna nastavitev",
        tr6: "Povezana organizacija"
      },

      Message5: "Namen in pravne podlage za obdelavo vaših osebnih podatkov.",
      Message6: "Vaše osebne podatke bomo obdelali za namene in pravne podlage, ki so opisani spodaj:",
      Table2: {
        tr1: {
          th1: "Namen:",
          th2: "Pravna podlaga:"
        },
        tr2: {
          td1: "KET - 1: Uspešnost trgovca - Kaizen",
          td2: "Upravičeni interes upravljavca podatkov"
        },
        tr3: {
          td1: "KET - 2: Informacijska podpora",
          td2: "Upravičeni interes upravljavca podatkov"
        }
      },
      Message7: "Trajanje hrambe vaših osebnih podatkov",
      Message8: "Vaši osebni podatki bodo shranjeni za čas, ki je naveden spodaj:",
      Table3: {
        tr1: {
          th1: "Obdobje hrambe:",
          th2: "Kategorije osebnih podatkov:"
        },
        tr2: {
          td1: "Vsi zgoraj opisani osebni podatki se bodo hranili največ 5 let + 1 mesec od dneva končnega zaprtja Kaizen Expert Tool.",
          td2: "Ime uporabnika, Priimek, Jezikovne nastavitve, Organizacija"
        }
      },
      Message9: "Neobvezno: V primeru razkritja / prenosa vaših osebnih podatkov v države zunaj Evropskega gospodarskega prostora (EGP)",
      Message10: "Vaši osebni podatki bodo na voljo prejemnikom v državah zunaj EGP, kot je navedeno spodaj:",
      Table4: {
        tr1: {
          td1: "Identiteta",
          td2: "Država",
          td3: ""
        },
        tr2: {
          td1: "Prejemnik (i) zunaj EGP",
          td2: "Infosys",
          td3: "Indija"
        }
      },
      Message11: "Potrjujem, da sem razumel to obvestilo; Prebral sem in sprejel Toyotino splošno politiko o zasebnosti in varovanju osebnih podatkov."
    },
    ACCESSORIES: "Acc selling power",
    activity: {
      Assignee: "Prevzemnik",
      CompletionDate: "Datum zaključka",
      Dealer: "Serviser",
      FirstVisit: "Prvi obisk",
      kpi: {
        kpi: "KPI je lahko le pozitivna ali negativna številka.",
        kpiLength: "Najdaljša dolžina za KPI je 10 znakov",
        mandatory: "Prosimo, vnesite veljavne podatke v vsa obvezna polja",
        mandatoryTargets: "Vnesite vse KPI.",
        target: "Ciljne vrednosti so lahko pozitivne ali negativne številke",
        targetLength: "Najdaljša dolžina za ciljno vrednost je 10 znakov"
      },
      ReminderOn: "Opomnik vklopljen",
      statusDD: "Stanje",
      Toolbox: "Zbirka orodij",
      activityCreation: "Postopek ustvarjanja dejavnosti se je začel.",
      activityCreated: "Dejavnosti so bile ustvarjene"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Učinkovitost",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Obvezna polja"
    },
    FOUNDATION: "Osnovni vprašalnik",
    MAINTENANCE_REMINDERS: "Servisni opomnik",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Osnove",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Avg",
      dec: "Dec",
      feb: "Feb",
      jan: "Jan",
      jul: "Jul",
      jun: "Jun",
      mar: "Mar",
      may: "Maj",
      nov: "Nov",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Nazaj",
      closed: "Zaprto",
      completed: "Zaključeno ",
      countryLanguageCode: "Koda države/jezika ",
      createActivity: "Ustvari dejavnost",
      createdDate: "Datum nastanka",
      dataUneditableNote:
        "Opomba: Podatkov, ki so bili enkrat shranjeni, ni mogoče urejati. Za spremembo podatkov se obrnite na skrbnika NMSC.",
      date: "Datum",
      downloadQuestionnaire: "Prenesi vprašalnik",
      editActivity: "Uredi dejavnost",
      initialHelpText: "Začetna meritev KPI mora biti povprečna vrednost predhodnih šestih polnih mesecev",
      inProgress: "Trenutno poteka",
      name: "Ime",
      next: "Naprej",
      noActivities: "Nobena dejavnost ni bila najdena",
      noResults: "Ni rezultatov",
      of: "od",
      ongoing: "V teku",
      open: "Odpri",
      page: "Stran",
      planned: "Načrtovano",
      problemDescription: "Opis težave",
      signature: "Podpis",
      select: "Izberite",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Med prenosom je prišlo do napake.",
      no_published_subquestion: "Prosimo, objavite vprašalnik za to polje z orodji, da začnete to dejavnost.",
      activity_upload_error_msg: "Napaka med prenosom podrobnosti o dejavnosti.",
      action_plan_created: "Akcijski plan je bil uspešno ustvarjen",
      action_plan_deleted: "Akcijski plan je bil uspešno izbrisan",
      action_plan_saved: "Akcijski plan je bil uspešno shranjen",
      activity_kpi_saved: "KPI-ji dejavnosti so bili uspešno shranjeni",
      master_kpi_confirm_delete:
        "Ali ste prepričani da želite izbrisati izbrane KPI-je?",
      master_kpi_created: "Glavni KPI je bil uspešno ustvarjen",
      master_kpi_deleted: "Glavni KPI je bil izbrisan",
      master_kpi_not_found: "Glavni KPI ni najden",
      master_kpi_published: "Izbrani glavni KPI.ji so bili uspešno objavljeni",
      master_kpi_retired: "Glavni KPI je bil odstranjen",
      master_kpi_saved: "Glavni KPI je bil uspešno shranjen",
      subquestion_created: "Podvprašanje je bilo uspešno shranjeno.",
      subquestion_updated: "Podvprašanje je bilo uspešno posodobljeno.",
      subquestionnaire_not_found: "Podvprašanje ni bilo najdeno.",
      subquestion_retired: "Podvprašanje je bilo umaknjeno.",
      subquestion_deleted: "Podvprašanje je bilo izbrisano.",
      subquestion_published: "Podvprašanja so bila uspešno objavljena.",
      mainquestion_created: "Izdelan je bil glavni vprašalnik.",
      mainquestion_deleted: "Glavni vprašalnik je bil izbrisan.",
      mainquestion_updated: "Glavno vprašanje je bilo posodobljeno.",
      duplicateMsg: "Podvojene zapise v datoteki Excel.",
      deleteErr: "Napaka pri brisanju datoteke",
      toolbox_deleted: "Toolbox je bil uspešno izbrisan",
      toolbox_already_exists: "Ta Toolbox že obstaja.",
      toolbox_saved: "Toolbox shranjen",
      toolbox_created: "Ustvarjen Toolbox",
      toolbox_not_found: "Toolboxa ni mogoče najti",
      published_content_found: "Te zbirke orodij ni mogoče izbrisati, ker ima nekaj objavljene vsebine",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Ali ste prepričani, da želite izbrisati?",
      addActionPlanNotAllowed: "Akcijskega načrta ni mogoče ustvariti ali urediti, ko je aktivnost zaprta, dokončana ali zavrnjena.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Ali ste prepričani, da želite zavrniti to dejavnost? Spremembe po zavrnitvi niso možne.",
      rejectMsg: "Ali ste prepričani, da želite zavrniti to dejavnost? Spremembe po zavrnitvi niso možne.",
      selectNMSCTab: "Izberite jezik NMSC za objavo",
      all: "Vsi",
      deleteConfirm: "Ali ste prepričani, da želite izbrisati izbran KPI?",
      no: "Ne",
      retireConfirm: "Ali ste prepričani, da želite odstraniti ta KPI? Ko ga odstranite, KPI-ja ni možno ponovno aktivirati",
      warning: "Opozorilo",
      yes: "Da",
      saveDataConfirmation: "Ali ste prepričani, da želite shraniti podatke?",
      saveKPIWarning: "Note:Data cannot be edited once saved. To modify the data please contact the NMSC KET admin",
      addKpiNotAllowed: "Naslednji mesec za vnos KPI se lahko doda, ko se konča trenutni mesec.",
      activityLaunch: "Ali ste prepričani, da želite začeti to dejavnost? Po zagonu se aktivnost ne more obrniti nazaj na načrtovano stanje ali izbrisati.",
      deleteConfirmSubQuestionNaire: "Ali ste prepričani, da želite izbrisati izbrano podvprašanje?",
      retireConfirmSubQuestionNaire: "Ali ste prepričani, da želite odstraniti izbrano podvprašanje?",
      deleteConfirmMainQuestion: "Potrdite, ali želite izbrisati glavno vprašanje in z njim povezana podvprašanja.",
      deleteConfirmChapter: "Ali ste prepričani, da želite izbrisati poglavje?",
      deleteLibraryFolder: "Ali ste prepričani, da želite izbrisati to mapo?",
      deletePublishedFolder: "Nekatere datoteke v tej mapi so lahko povezane z vprašalnikom. Ali ste prepričani, da želite trajno izbrisati to podmapo in odstraniti povezave iz vprašalnika?",
      importQuestion: "Ali ste prepričani, da želite uvoziti to podvprašanje? Sedanje podvprašanje bo zamenjano.",
      noQuestion: "Na voljo ni nobenih vprašanj.",
      MandatoryWarning: "Prosimo, izpolnite vsa obvezna polja za vse lokalne jezike.",
      certificationSubmission:
        "Toolbox bil predložen TME za certificiranje trgovcev",
      sameToolBox: "Istega orodja ne morete oddati več kot enkrat. Izberite drugo orodje",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Izberi vse",
      unSelectAll: "Prekliči izbiro vseh",
      select:"Izberite ...",
      warningMsg:"V tej dejavnosti so odprti akcijski načrti. Bi radi dokončali dejavnost?",
      updateEaConfirmDealer: "All Activities created for this dealer in 'EA' will be removed. Are you sure you want to continue?"

    },
    status: {
      published: "Objavljeno",
      retired: "Odstranjeno",
      saved: "Shranjeno",
      rejected: "Zavrnjeno",
      open: "Odpri",
      closed: "Zaprto",
      close: "Zaprto",
      ongoing: "V teku",
      completed: "Zaključeno",
      complete: "Zaključeno",
      planned: "Načrtovano",
      overdue: "Prepozno",
      due: "Rok",
      pending: "Pending",
    },
    Rejected: "Zavrnjeno",
    Open: "Odpri",
    Closed: "Zaprto",
    Close: "Zaprto",
    Ongoing: "V teku",
    Completed: "Zaključeno",
    Complete: "Zaključeno",
    Planned: "Načrtovano",
    tabDetails: {
      noActionAvailable: "Na voljo ni ukrepov",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivnost",
      activityCompletionDate: "Datum zaključka dejavnosti",
      activityCompletionDateFrom: "Datum končanja aktivnosti od",
      activityCompletionDateTo: "Datum končanja aktivnosti do",
      activityStartDate: "Datum začetka dejavnosti",
      activityStartDateFrom: "Datum začetka aktivnosti od",
      activityStartDateTo: "Datum začetka aktivnosti od",
      assignedTo: "Dodeljeno",
      assignee: "Prevzemnik",
      benchmark: "Merilo",
      city: "Mesto",
      completedBy: "Zaključil ",
      completionBy: "Zaključil ",
      completionDate: "Datum zaključka",
      counterMeasure: "Protiukrep",
      country: "Država",
      createdBy: "Ustvaril",
      dealer: "Serviser",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Ime trgovca",
      definition: "Opredelitev",
      description: "Opis",
      dueDate: "Datum zapadlosti",
      endDate: "Datum zaključka",
      firstVisit: "Prvi obisk",
      followup: "Spremljanje",
      franchise: "Franšiza",
      history: "Zgodovina",
      id: "ID",
      initial: "Začetno",
      kpi: "KPI",
      launch: "Zaženi",
      mandatory: "Obvezno",
      nmsc: "NMSC",
      problem: "Težava",
      publishedOn: "Objavljeno na",
      questionReference: "Referenca vprašanja",
      reference: "Referenčno",
      reminderOn: "Opomnik vklopljen",
      score: "Rezultat",
      startDate: "Datum začetka",
      status: "Stanje",
      target: "Target",
      toolbox: "Toolbox",
      view: "Ogled",
      kpiResult: "Rezultati KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokacija",
      ActionItemReport: "Akcijski elementi v teku",
      certification: "Status certifikacije",
      noOfToolbox: "Št. Dokončanih Toolbox",
      program: "Program",
      certificationDate: "Datum potrjevanja",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Akcijski načrt",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Akcijski načrt",
      confirmDeleteMessage: "Ali ste prepričani, da želite izbrisati izbran KPI?",
      confirmRetireMessage: "Potrdite, ali želite odstraniti izbrani KPI, saj je ta že bil objavljen",
      errorRetireMsg: "KPI-ja ni možno odstraniti, saj je aktivni obvezni KPI TME",
      import: "Uvozi",
      importKpiMessage: "Prosimo, uvozite svoj KPI s seznama glavnih KPI-jev TME",
      kpi: "Ključni kazalniki uspešnosti (KPI)",
      library: "Knjižnica",
      noActionAvailable: "Na voljo ni ukrepov",
      NoKPI: "Na voljo ni KPI-jev",
      publish: "Objavi",
      questionnaire: "Vprašalnik",
      tmeKPIList: "Seznam glavnih KPI-jev TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Ime orodja",
      newToolboxName: "Novo ime",
      deleteToolbox: "Ali želite izbrisati polje z orodji?",
      programName: "Ime programa",
      ToolboxName_EN: "Ime orodja (EN)",
      deleteTool: "Ali ste prepričani, da želite izbrisati to orodje?",
      selectToolbox: "Izberite polje z orodji, ki ga želite izbrisati.",
      editToolBox: "Urejanje Orodja",
      addToolbox: "Dodaj Orodja",
      toolbox: "Vnesite ime za to polje z orodji.",
      addActionPlan: "Dodaj Akcijski načrt",
      actionPlanItem: "Akcijski načrt ‒ element št.",
      add: "Dodaj",
      addActivity: "Dodaj dejavnost",
      additionalInformation: "Dodatne informacije",
      benchmark: "Merilo",
      cancel: "Prekini",
      createEditActivity: "Ustvari/zaključi dejavnost",
      createKPI: "Ustvari KPI",
      delete: "Izbriši",
      download: "Prenesi",
      edit: "Uredi",
      editKPI: "Uredi KPI",
      keyPerformanceIndicator: "Ključni kazalnik uspešnosti (KPI)",
      kpiDefinition: "Opredelitev KPI",
      mandatoryKpi: "Obvezni KPI",
      save: "Shrani",
      search: "Iskanje",
      unit: "Enota",
      update: "Posodobi",
      validData: "Vnesite veljavne podatke",
      hide: "Skrij",
      show: "Pokaži",
      upload_dealer_network: "Naloži trgovsko mrežo",
      upload_question: "Vpišite vprašalnik",
      upload_user: "Prenesite podatke o uporabniku",
      upload_activity: "Nalaganje dejavnosti",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Rok je presežen",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Odgovorite na vsa vprašanja, da boste lahko to aktivnost zaključili.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Za nadaljevanje vnesite začetno meritev KPI, cilje KPI in vsaj 1 mesec merjenja.",
      initialTarget: "Vnesite začetno meritev KPI in cilje KPI, če želite nadaljevati.",
      upload_error: "Napaka pri nalaganju omrežja prodajalca.",
      action_item_create_warning: "Največ pet zapisov je dovoljeno združiti znotraj ene postavke.",
      actionPlanStartDateValidation: "Končni datum ne more biti pred začetnim datumom",
      actionsNotClosed: "V akcijskem načrtu potekajo ukrepi; prosimo, zaprite vse ukrepe, preden zaprete dejavnost",
      activityDuplicate: "Druga dejavnost za istega trgovca in isto zbirko orodij že obstaja",
      assigneeNotExist: "Izbrani prevzemnik ni registriran v sistemu",
      assigneeValidation: "Neveljaven prevzemnik",
      atleastOnePublish: "Izberite vsaj en KPI za objavo",
      cannotRetireActive: "To je aktivni obvezni KPI TME, ki ga ni mogoče odstraniti ",
      completionDate: "Datum zaključka ne more biti pred prvim datumom obiska",
      confirDeletePlannedActivity: "Ali ste prepričani, da želite izbrisati to načrtovano dejavnost?",
      DateformatValidation: "Datum mora biti v obliki DD / MM / LLLL",
      DateValidation: "Začetni datum mora biti pred končnim datumom",
      dealerValidation: "Neveljaven trgovec",
      deleteSelectedConfirm: "Ali ste prepričani, da želite izbrisati izbran KPI?",
      duplicate: "Drug KPI z istim imenom in opredelitvijo že obstaja",
      modalMandatory: "Prosimo, vnesite veljavne podatke v vsa obvezna polja",
      noPublishKpi: "KPI-ji še niso bili objavljeni za izbrano zbirko orodij",
      validSearchCriteria: "Prosimo, vnesite veljavne kriterije iskanja",
      multiSelectPlaceHolder: "Vneseti najmanj 5 znakov",
      multiSelectPlaceHolderUser: "Vneseti najmanj 3 znakov",
      startDateValidation: "Začetni datum ne sme biti daljši od končnega datuma",
      fileSelectionErr: "Prosim izberite datoteko ",
      atleastOneQuestion: "Izberite vsaj eno podvprašanje za uvoz.",
      atleastOneSubQues: "Izberite vsaj eno podvprašanje za objavo.",
      uploadErr: "Število datotek, ki jih ni mogoče prenesti:",
      uploadSuccess: "Trgovska mreža je bila uspešno naložena.",
      file_upload_success: "Datoteke so bile uspešno prenesene.",
      KPIinOngoingClosedActivity: "Ta KPi je prisoten v eni ali več tekočih / zaprtih dejavnostih. Prosimo, dokončajte te dejavnosti, preden spremenite KPI.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Dejavnosti",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Domov",
      logout: "Odjavi me",
      MasterDataUpload: "Naloži glavna podatke",
      Toolbox: "Toolbox",
      welcome: "Dobrodošli",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Dodaj podvprašanje",
      editSubQuestion: "Uredi podskupino",
      subQuestionTitle: "Podvprašanje",
      additionalInfo: "Dodatne informacije",
      na: "Kot možen odgovor na to vprašanje dodajte besedilo „Se ne uporablja“",
      question: "Vprašanje",
      questionTitle: "Naslov vprašanja",
      why: "Zakaj?",
      addMainQuestion: "Dodaj glavno vprašanje",
      editMainQuestion: "Uredi glavno vprašanje",
      previousQuestion: "Prejšnje vprašanje",
      nextQuestion: "Naslednje vprašanje",
      what: "Kaj?",
      library: "Knjižnica",
      subQuestionTable: {
        no: "Ne",
        subQuestion: "Podvprašanja",
        status: "Stanje",
        publishedDate: "Datum objave",
        notApplicable: "Se ne uporablja"
      },
      links: {
        question_link_button: "Povezava",
        question_link_include_folder: "Vključite podmapo",
        question_link_add_link: "Poveži datoteke iz knjižnice",
        question_link_view_folder: "Prikaži knjižnico"
      },
      mainQuestion: "Nova / posodobljena glavna vprašanja:",
      subQuestion: "Nova / posodobljena podvprašanja:",
      retiredQuestion: "Upoštevana podvprašanja:",
      libraries: "Nove / posodobljene datoteke v knjižnicah:",
      noFiles: "Datoteke niso na voljo"
    },
    library: {
      selectFile: "Izberite vsaj eno datoteko.",
      fileSelected: "Izbrane datoteke",
      publish_library_file: "Izberite vsaj eno datoteko za objavo",
      library_files_deleted: "Ali ste prepričani, da želite izbrisati to datoteko?",
      library_files_linked_deleted: "Ali ste prepričani, da želite izbrisati to datoteko in povezave, ustvarjene v vprašalniku?",
      max_size_error: "Velikost datotek presega omejitev 1 GB za prenos.",
      empty_file_desc: "Vnesite opis datoteke.",
      file_not_selected: "Izberite datoteke, ki jih želite prenesti (največ 1 GB)",
      file_type_error: "Ta vrsta datoteke ni dovoljena.",
      file_name_error: "Neveljaven znak v imenu datoteke.",
      file_name_lenght_error: "Ime datoteke lahko vsebuje največ 50 znakov.",
      addFolder: "Dodaj mapo",
      editFolder: "Uredi mapo",
      currentFolder: "Ime mape",
      parentFolder: "Ime nadrejene mape",
      emptyFolderName: "Ime mape ne sme biti prazno.",
      invalidFolderName: "Ime mape ne sme vsebovati '/'.",
      folder_created: "Mapa je bila uspešno ustvarjena.",
      folder_updated: "Mapa je bila uspešno posodobljena.",
      folder_not_found: "Mapa ni bila najdena",
      lib_invalid_toolbox: "Izbran je neveljavni Toolbox",
      lib_folder_already_exists: "Ta mapa že obstaja.",
      lib_folder_deleted: "Mapa je bila uspešno izbrisana.",
      library_files_published: "Datoteke so bile objavljene.",
      addFile: "Dodaj datoteko",
      editFile: "Uredi datoteko",
      fileName: "Ime datoteke",
      upload: "Prenos",
      desc: "Opis",
      folderCreationErr: "Hierarhija je omejena na dve ravni.",
      subFolderWarning: "Izberite podmapo za dodajanje datotek.",
      deleteWarning: "Izberite mapo, ki jo želite izbrisati.",
      beforeAfterNotSelected: "Izberite, ali so dokazi pred ali za kaizenom",
    },
    chapter: {
      chapter_exist: "To poglavje že obstaja.",
      chapter_saved: "Poglavje shranjeno",
      chapter_cannot_delete: "To poglavje ni mogoče izbrisati, ker so bila podvprašanja že objavljena.",
      failed: "Neuspešno!",
      chapter_deleted: "Poglavje izbrisano",
      chapter_nonEmpty: "Ustvarite ime poglavja.",
      addchapter: "Dodaj poglavje",
      editChapter: "Uredi poglavje",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Priloge",
      comments: "Komentarji",
      evidence: "Dokazi",
      evidences: "Dokazi",
      yes: "Da",
      no: "Ne",
      na: "N / A",
      uploadEvidence: "Naložite dokaze",
      fileUpload: "Izberite datoteke, ki jih želite prenesti (največ 1 GB)",
      who: "Kdo?",
      answer: "Odgovor",
      comment: "Comment",
      date: "Datum",
      submit: "Pošlji",
      commentMsg: "Vnesite komentarje.",
      noCommentAvailable: "Ni komentarjev.",
      uploadSuccessMsg: "Datoteke so bile uspešno prenesene.",
      uploadErrorMsg: "Število datotek, ki jih ni mogoče prenesti:",
      fileWithSameName: "Druga datoteka z istim imenom že obstaja.",
      noEvidence: "Ni dokazov",
      createAction: "Ustvari element akcije",
      chapterName: "Ime poglavja",
      questionNo: "Vprašanje št.",
      when: "Kdaj?",
      noRecords: "Zapisov ni na voljo.",
      kaizenPoints: "Kaizen točke",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Vprašanje",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Je to dokaz pred ali po kaizenu?",
      move:"Premakni se",
      moveMsg: "Datoteke so bile uspešno premaknjene",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "WAC",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Rabljeni avtomobili",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Oglasna deska",
      kpi_target_not_set: "KPI niso nastavljeni",
      kpi_initial_not_set: "Začetna meritev KPI ni nastavljena",
      kpi_monthly_not_set: "Mesečno merjenje KPI ni nastavljeno",
      kpi_below_target: "KPI pod določenim ciljem",
      activation: "Aktivacija",
      users: "Uporabniki",
      nmscs: "NMSC's",
      activeRetailer: "Aktivni trgovci na drobno",
      certificationRate: "Stopnja potrjevanja",
      reCertificationRate: "Stopnja ponovnega potrjevanja",
      activityDue: "Rok za dejavnosti",
      avgdays: "Povprečni dnevi / Toolbox",
      overDueAct: "Prepozne dejavnosti",
      actionItem: "Akcijski predmeti",
      bussResult: "Poslovni rezultati",
      toolboxImpl: "Izvedba Toolbox",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Zadnje posodobitve",
      rolloutTopNmsc: "Rezultati predstavitve - najboljši NMSC",
      bestPractice: "Odbor za najboljše prakse",
      availableKpi: "Na voljo KPI",
      selectedKpi: "Izbrani KPI-ji",
      dealerBestPractice: "Pregled primerov z najboljšimi praksami",
      kpiList: "Seznam KPI",
      go: "GO",
      nodata: "Podatki niso na voljo",
      businessResultTop: "Poslovni rezultati - Najboljši prodajalci",
      businessResultNMSC: "Poslovni rezultati - najboljši NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certifikat zastopnika",
      readyForCertification: "Pripravljen za certificiranje",
      certification: "Certificiranje",
      submittedOn: "Oddano dne",
      certificateDate: "Datum potrdila",
      reCertificateDate: "Datum ponovnega potrdila",
      toolboxDetails: "Podrobnosti o Toolbox",
      approve: "Odobriti",
      reject: "Zavrni",
      pending: "V teku",
      region: "Regija"



    },

    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },
    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First name",
      lastName: "Last name",
      emailId: "E-mail",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Účet PBI",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"
    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      Assign: "Assign",
      List: "List",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsSL;
