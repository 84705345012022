// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Table header stick  */

.nmscImportTable thead, .nmscImportTable tbody, .nmscImportTable tr, .nmscImportTable td, .nmscImportTable th { display: block; }
.nmscImportTable .react-bootstrap-table{
    height: 25rem !important;
}
.nmscImportTable tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.nmscImportTable tbody {
    height: 21.8rem;
    overflow-y: scroll;
    border:solid 1px #a8aaac;
}

.nmscImportTable tbody > tr > td { 
    width: 7rem; 
    float: left;
} 
.nmscImportTable thead > tr > th { 
    width: 7rem;
    float: left;
} 
.nmscImportTable thead > tr > th:first-child { 
    width: 2rem !important;
    float: left;
}
.nmscImportTable tbody > tr > td:first-child  { 
    width: 2rem;
    float: left;
}

.nmscImportTable tbody tr td,.nmscImportTable thead tr th{
   word-wrap: break-word;
    
}


`, "",{"version":3,"sources":["webpack://./src/components/nmscImportTable/index.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB,gHAAgH,cAAc,EAAE;AAChI;IACI,wBAAwB;AAC5B;AACA;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;GACG,qBAAqB;;AAExB","sourcesContent":["/* Table header stick  */\n\n.nmscImportTable thead, .nmscImportTable tbody, .nmscImportTable tr, .nmscImportTable td, .nmscImportTable th { display: block; }\n.nmscImportTable .react-bootstrap-table{\n    height: 25rem !important;\n}\n.nmscImportTable tr:after {\n    content: ' ';\n    display: block;\n    visibility: hidden;\n    clear: both;\n}\n\n.nmscImportTable tbody {\n    height: 21.8rem;\n    overflow-y: scroll;\n    border:solid 1px #a8aaac;\n}\n\n.nmscImportTable tbody > tr > td { \n    width: 7rem; \n    float: left;\n} \n.nmscImportTable thead > tr > th { \n    width: 7rem;\n    float: left;\n} \n.nmscImportTable thead > tr > th:first-child { \n    width: 2rem !important;\n    float: left;\n}\n.nmscImportTable tbody > tr > td:first-child  { \n    width: 2rem;\n    float: left;\n}\n\n.nmscImportTable tbody tr td,.nmscImportTable thead tr th{\n   word-wrap: break-word;\n    \n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
