import React from 'react';
import { connect } from 'react-redux';
import { withTheme, themes } from '../../../themes/theming';
import { NavbarNav, NavItem, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { withRouter, Link } from 'react-router-dom';
import './index.css';
import { push } from "connected-react-router";
const $ = require('jquery')
window.jQuery = $;

class CeaNetUcAdminMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            theme: themes.default,
            selectedTabName: ''
        };
        this.onClick = this.onClick.bind(this);
        this.addCss = this.addCss.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.theme.name !== this.props.theme.name) {
            this.addCss({ target: { innerText: this.state.selectedTabName } });
        }
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    addUser(e, url) {
        e.preventDefault();
        this.props.dispatch(push(url));
    }

    addCss(e) {
        let selectedTabName = e.target.innerText;
        this.setState({ selectedTabName: selectedTabName });
        $("a").filter(function () {
            if ($(this).text() === selectedTabName) {
                $(this).addClass("selectedMenu");
            } else {
                $(this).removeClass("selectedMenu");
            }
            return null;
        });
    }


    render() {
        const navLinkClass = this.props.theme.name + "-nav-link nav-link";
        const navLinkClassAdmin = this.props.theme.name + "-nav-link nav-link backColor";
        return (
            <NavbarNav left>

                {this.props.user && this.props.user.userMdl && this.props.user.userMdl.accessNavList &&
                    this.props.user.userMdl.accessNavList.map((t, index) => {
                        return t.childFunction && t.childFunction.length ?
                            <NavItem key={index}>
                                <Dropdown>
                                    <DropdownToggle nav caret className={navLinkClassAdmin} onClick={this.addCss.bind(this)}>
                                        {t.function}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {t.childFunction.map(m => {
                                            return <DropdownItem
                                                className="dd_item"
                                                key={m.function}
                                                onClick={((e) => this.addUser(e, m.aditionalData))}
                                            >
                                                {m.function}
                                            </DropdownItem>
                                        })}
                                    </DropdownMenu>
                                </Dropdown>
                            </NavItem>
                            :

                            index === 0 ?
                                <NavItem active key={index}>
                                    <Link className={navLinkClass} to={t.aditionalData ? t.aditionalData : "toolbox"} onClick={this.addCss.bind(this)}>
                                        {/* <Fa icon="home" fixed className="mr-1" /> */}
                                        {t.function}
                                    </Link>
                                </NavItem>
                                :
                                <NavItem key={index}>
                                    <Link className={navLinkClass} to={t.aditionalData ? t.aditionalData : "toolbox"} onClick={this.addCss.bind(this)}>
                                        {/* <Fa icon="home" fixed className="mr-1" /> */}
                                        {t.function}
                                    </Link>
                                </NavItem>


                    }
                    )}
            </NavbarNav>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.router
    }
}

export default withRouter(connect(mapStateToProps)(withTheme(CeaNetUcAdminMenu)));