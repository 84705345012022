import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import { withTheme } from "../../../themes/theming";
import { MDBCol, MDBRow, Fa } from 'mdbreact';
import '../index.css';

var I18n = require("react-redux-i18n").I18n;
class RecentUpdates extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            recentUpdates: []
        };
    }
    componentWillMount() {
        
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            userId: this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("recentActivities", "SET_RECENT_UPDATES", queryDto));
    }

    componentWillUnmount() {
        this.props.dispatch({
            type: "SET_RECENT_UPDATES",
            payload: []
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.recentUpdates && nextProps.recentUpdates.length !== this.state.recentUpdates.length) {
            this.setState({ recentUpdates: nextProps.recentUpdates, isLoading: false });
        }
        else {
            this.setState({ isLoading: false });
        }
    }
    render() {
        let baseUrl = "/ket/image/download/";
        // let baseUrl="http://localhost:9090/ket/image/download/"

        return (
            <div>
                <div className={"widget-header left-align-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.recentUpdates')}>
                    {I18n.t('dashboard.recentUpdates')}
                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="large-widget-content ml-1 mr-1">
                        {this.state.recentUpdates.length > 0 ?
                            <div>
                                {this.state.recentUpdates.map((activity, index) => {
                                    let imageUrl = baseUrl + activity.toolboxLocation.replace(/\//g, "$");
                                    if (this.props.theme.name === 'toyota') {
                                        imageUrl = baseUrl + activity.toolboxLocation.replace(/\//g, "$").replace('.png', '.png');
                                    }
                                    return (
                                        <MDBRow key={index} className="mt-2">
                                            <MDBCol md="2" lg="2" className="mr-0 pr-0 pb-2 bottom-border" style={{ paddingLeft: '5px' }} >
                                                <MDBRow style={{ padding: '0px 25%' }}>
                                                    <img src={imageUrl} style={{ height: '40px' }} title={activity.toolboxName} className="toolbox_image_icon" alt=""/>
                                                </MDBRow>
                                                <div style={{ fontWeight: 'bold', textAlign: 'center' }} title={activity.toolboxName} className={"small-font toolbox-name-" + this.props.theme.name}>{activity.toolboxName}</div>
                                            </MDBCol>
                                            <MDBCol md="4" lg="4" className="mr-0 pr-0 pb-2 bottom-border">
                                                <div className="small-font" style={{ fontWeight: "bold" }} title={activity.dealerName}>{activity.dealerName}</div>
                                                <MDBRow className="small-font">{activity.dealerCity} - {activity.country}</MDBRow>
                                                <MDBRow className="small-font mt-2" style={{ fontWeight: "bold" }}>{I18n.t('Table.assignedTo') + ":"}</MDBRow>
                                                <MDBRow className="small-font">{activity.assignedTo}</MDBRow>
                                            </MDBCol>
                                            <MDBCol md="6" lg="6" className="mr-0 pr-0 pb-2 bottom-border">
                                                <MDBRow>
                                                    <MDBCol md="8" lg="7" className="small-font left-border" title="Key Performance Indicator">{I18n.t('tmeAdminMaster.keyPerformanceIndicator')}</MDBCol>
                                                    <MDBCol md="4" lg="5" className="small-font">
                                                        {activity.kpiDate}
                                                        {(activity.kpiDate !== undefined && activity.kpiDate !== null)
                                                            ? <Fa icon="circle" fixed className="green-indicator" />
                                                            : <span />}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="8" lg="7" className="small-font left-border">{I18n.t('tabsDetails.questionnaire')}</MDBCol>
                                                    <MDBCol md="4" lg="5" className="small-font">
                                                        {activity.questionnaireDate}
                                                        {(activity.questionnaireDate !== undefined && activity.questionnaireDate !== null)
                                                            ? <Fa icon="circle" size="sm" fixed className="green-indicator" />
                                                            : <span />}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="8" lg="7" className="small-font left-border" title="Action Plan">{I18n.t('tabsDetails.actionPlan')}</MDBCol>
                                                    <MDBCol md="4" lg="5" className="small-font">
                                                        {activity.actionPlanDate}
                                                        {(activity.actionPlanDate !== undefined && activity.actionPlanDate !== null)
                                                            ? <Fa icon="circle" fixed className="green-indicator" />
                                                            : <span />}
                                                    </MDBCol>
                                                </MDBRow>
                                                <MDBRow>
                                                    <MDBCol md="8" lg="7" className="small-font left-border">{I18n.t('Table.status')}</MDBCol>
                                                    <MDBCol md="4" lg="5" className="small-font">
                                                        {activity.statusDate}
                                                        {(activity.statusDate !== undefined && activity.statusDate !== null)
                                                            ? <Fa icon="circle" fixed className="green-indicator" />
                                                            : <span />}
                                                    </MDBCol>
                                                </MDBRow>
                                            </MDBCol>
                                        </MDBRow>);
                                })}
                            </div>
                            : <div></div>}
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        recentUpdates: state.dashboardReducer.recentUpdates
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(RecentUpdates)));