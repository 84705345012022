import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../../themes/theming';
import { MDBCol, MDBRow} from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import { getNoticeBoard } from '../../../actions/dashboardAction';

import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class DealerNoticeBoard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            noticeBoard: {
                TARGET_NOT_SET: false,
                INITIAL_MEASUREMENT_NOT_SET: false,
                MONTHLY_MEASUREMENT_NOT_SET: false,
                KPI_BELOW_TARGET: false

            }

        };
    }

    componentDidMount() {
        //console.log(this.props.userMdl.username);
        this.props.dispatch(getNoticeBoard(this.props.userMdl.username,this.props.userMdl.activeProgram,this.props.userMdl.businessAreaKey));

    }
    componentWillMount() {

    }
    componentWillReceiveProps(nextProps) {
        console.log(nextProps.noticeBoard);
        if (nextProps.noticeBoard) {
            this.setState({ noticeBoard: nextProps.noticeBoard })
        }

    }
    // fetchData = (filter) => {
    //     this.setState({ isLoading: true });
    //     let activeProgram = this.props.userMdl.activeProgram;
    //     let nmscCode = this.props.userMdl.nmsc;
    //     let langCode = this.props.userMdl.lang;
    //     let queryDto = {
    //         userNmscCode: nmscCode,
    //         userLangCode: langCode,
    //         programList: [activeProgram],
    //         nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
    //         userId: this.props.userMdl.username
    //     };
    //     //this.props.dispatch(fetchScrollableWidgetData("kaizenPoints", "WIDGET_KAIZEN_POINTS_REPORT", queryDto));
    // }
    render() {
        let count = 0;
        console.log(this.state.noticeBoard)
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.notice_board')}>{I18n.t('dashboard.notice_board')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">

                        {this.state.noticeBoard.TARGET_NOT_SET ?
                            <MDBRow style={{ minHeight: "0px" }} className="br-table-row pl-1 report-bottom-border">
                                <MDBCol lg="12" md="12" className="widget_noticeBoard">

                                    {++count + ". " + I18n.t('dashboard.kpi_target_not_set')}
                                </MDBCol>
                            </MDBRow> : null}

                        {this.state.noticeBoard.INITIAL_MEASUREMENT_NOT_SET ?
                            <MDBRow style={{ minHeight: "0px" }} className="br-table-row pl-1 report-bottom-border">
                                <MDBCol lg="12" md="12" className="widget_noticeBoard">
                                    {++count + ". " + I18n.t('dashboard.kpi_initial_not_set') }
                                </MDBCol>
                            </MDBRow> : null}

                        {this.state.noticeBoard.MONTHLY_MEASUREMENT_NOT_SET ?
                            <MDBRow style={{ minHeight: "0px" }} className="br-table-row pl-1 report-bottom-border">
                                <MDBCol lg="12" md="12" className="widget_noticeBoard">
                                    {++count + ". " + I18n.t('dashboard.kpi_monthly_not_set') }
                                </MDBCol>
                            </MDBRow> : null}

                        {this.state.noticeBoard.KPI_BELOW_TARGET ?
                            <MDBRow style={{ minHeight: "0px" }} className="br-table-row pl-1 report-bottom-border">
                                <MDBCol lg="12" md="12" className="widget_noticeBoard">
                                    {++count + ". " +  I18n.t('dashboard.kpi_below_target') }
                                </MDBCol>
                            </MDBRow> : null}



                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        noticeBoard: state.dashboardReducer.noticeBoard
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(DealerNoticeBoard)));