// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Modal CSS */

.importClass {
  max-width: 64.375rem !important;
}

.importClass-toyota .modal-dialog, .importClass-toyota .modal-content {
  border-radius: 24px !important;
  border: 1px solid #e50000 !important;
}

.importClass-lexus .modal-dialog, .importClass-lexus .modal-content {
  border-radius: 24px !important;
  border: 1px solid #252525 !important;
}

.importClass-toyota .modal-header {
  background-color: #e50000;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 0.5rem !important;
}

.importClass-lexus .modal-header {
  background-color: #252525;
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  padding: 0.5rem !important;
}

.modal-title {
  color: white;
  font-size: 1.0rem !important;
  padding-left: 0.5rem;
}

.modal-header .close {
  color: white;
  padding: 1.5rem !important;
  margin: -1.3rem -1rem -2rem auto !important;
  opacity: 1;
}

.redFont {
  color: #e50000;
}

.modal-body {
  font-size: 0.81rem;
  color: #595d60;
  padding: 0.7rem !important;
}`, "",{"version":3,"sources":["webpack://./src/components/importModal/index.css"],"names":[],"mappings":"AAAA,cAAc;;AAEd;EACE,+BAA+B;AACjC;;AAEA;EACE,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,8BAA8B;EAC9B,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,uCAAuC;EACvC,wCAAwC;EACxC,0BAA0B;AAC5B;;AAEA;EACE,yBAAyB;EACzB,uCAAuC;EACvC,wCAAwC;EACxC,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,4BAA4B;EAC5B,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,2CAA2C;EAC3C,UAAU;AACZ;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,0BAA0B;AAC5B","sourcesContent":["/* Modal CSS */\n\n.importClass {\n  max-width: 64.375rem !important;\n}\n\n.importClass-toyota .modal-dialog, .importClass-toyota .modal-content {\n  border-radius: 24px !important;\n  border: 1px solid #e50000 !important;\n}\n\n.importClass-lexus .modal-dialog, .importClass-lexus .modal-content {\n  border-radius: 24px !important;\n  border: 1px solid #252525 !important;\n}\n\n.importClass-toyota .modal-header {\n  background-color: #e50000;\n  border-top-left-radius: 20px !important;\n  border-top-right-radius: 20px !important;\n  padding: 0.5rem !important;\n}\n\n.importClass-lexus .modal-header {\n  background-color: #252525;\n  border-top-left-radius: 20px !important;\n  border-top-right-radius: 20px !important;\n  padding: 0.5rem !important;\n}\n\n.modal-title {\n  color: white;\n  font-size: 1.0rem !important;\n  padding-left: 0.5rem;\n}\n\n.modal-header .close {\n  color: white;\n  padding: 1.5rem !important;\n  margin: -1.3rem -1rem -2rem auto !important;\n  opacity: 1;\n}\n\n.redFont {\n  color: #e50000;\n}\n\n.modal-body {\n  font-size: 0.81rem;\n  color: #595d60;\n  padding: 0.7rem !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
