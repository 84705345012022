import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "mdbreact";
import { withRouter } from "react-router-dom";
import CopyKpiHistory from "./components/copyKpiHistory";
import {copyCeaKpi, copyNetUcKpi ,copyLexusKpi} from "../../actions/ceaNetKPIAction";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import Select from "react-select";
import { withTheme } from "../../themes/theming";
import $ from "jquery";
import "./index.css";
import * as AppConst from '../../AppConstant';
import PointsAllocation from './pointsAllocation';
var I18n = require("react-redux-i18n").I18n;

class AnnualSetup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: 0,
            migrationYearUC: [{ label: 2019, value: 2019 }, { label: 2020, value: 2020 }, { label: 2021, value: 2021 }, { label: 2022, value: 2022 }],
            migrationYearNET: [
			{ label: 2012, value: 2012 },
			{ label: 2013, value: 2013 },
			{ label: 2014, value: 2014 },
			{ label: 2015, value: 2015 },
			{ label: 2016, value: 2016 },
            { label: 2017, value: 2017 },
            { label: 2018, value: 2018 },
            { label: 2019, value: 2019 },
            { label: 2020, value: 2020 },
            { label: 2021, value: 2021 },
            { label: 2022, value: 2022 },
            { label: 2023, value: 2023 }
        ],
            migrationToYear : [],
            migrationFromYear : [],
            fromYearOption: {},
            toYearOption: {}
        };
    }

    componentDidMount(){
        let ceaDefaultYear = this.props.user.userMdl.ceaDefaultYears;        

        let fromYearOption = ceaDefaultYear;
        let toYearOption = {label: ceaDefaultYear.value + 1, value: ceaDefaultYear.value + 1};

        /* Used Car and Network copy old kpis - Migration code start*/
        var migrationToYear = [];
        var migrationFromYear = [];
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            migrationToYear = [...this.state.migrationYearUC, ceaDefaultYear];
            migrationFromYear = [...this.state.migrationYearUC, toYearOption];
        }else{
            migrationToYear = [...this.state.migrationYearNET, ceaDefaultYear];
            migrationFromYear = [...this.state.migrationYearNET, toYearOption];
        }
        /* Used Car and Network copy old kpis - Migration code end*/
        
        this.setState({fromYearOption, toYearOption, migrationToYear,migrationFromYear});
    }

    selectedTab = (index, label) => {
        this.setState({ selectedTab: index});
    }

    handleTabStyle() {
        if (this.props.theme.name === "toyota") {
            $(".uploadContainer .nav-link").css('border', '1px solid transparent');
            $(".uploadContainer .nav-link.active").css('border-bottom-color', '#e50000')

        } else {
            $(".uploadContainer .nav-link").css('border', '1px solid transparent');
            $(".uploadContainer .nav-link.active").css('border-bottom-color', '#252525')
        }
    }

    onClickCopy = (e) => {
        let fromYear = this.state.fromYearOption.value;
        let toYear = this.state.toYearOption.value;
        let programID = this.props.user.userMdl.activeProgramID;
        let programName = this.props.user.userMdl.activeProgramName;
        let userDetails = {
            username: this.props.user.userMdl.username,
            fullName: this.props.user.userMdl.fullName,
            nmsc: this.props.user.userMdl.nmsc,
            roles: this.props.user.userMdl.roles
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramCea){
            this.props.dispatch(copyCeaKpi(programID, programName, fromYear, toYear, userDetails));
        }
        else if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            let currentYear = new Date();
            currentYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : currentYear.getfullYear();
            let copyKpiDto = {
                username: this.props.user.userMdl.username,
                fullName: this.props.user.userMdl.fullName,
                selectedYear: currentYear,
                fromYear: fromYear,
                toYear: toYear,
                program : programID
            }
            this.props.dispatch(copyLexusKpi(copyKpiDto, programID, programName, fromYear, toYear));
        }
        else{
            let currentYear = new Date();
            currentYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : currentYear.getfullYear();
            let copyKpiDto = {
                username: this.props.user.userMdl.username,
                fullName: this.props.user.userMdl.fullName,
                selectedYear: currentYear,
                fromYear: fromYear,
                toYear: toYear,
                program : programID
            }
            this.props.dispatch(copyNetUcKpi(copyKpiDto, programID, programName, fromYear, toYear));
        }                
    }
    changeFromYear = e => {
        console.log(e)
        this.setState({
            fromYearOption: e
        });
    };
    changeToYear = e => {
        console.log(e)
        this.setState({
            toYearOption: e
        });
    };
    render() {
        this.handleTabStyle();
        const fromYearOption = this.state.fromYearOption;
        const toYearOption = this.state.toYearOption;
        return (
            <Container fluid className="ml-0 mb-1">
                <Row className="mr-0">
                    <Col md="12" className="paddingTab uploadContainer">
                    {this.props.user.userMdl.activeProgram === AppConst.activeProgramCea ?
                        <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab} >
                            <Tab label={I18n.t('Points Allocation')}>
                                <div className="downloadTreeContainer">
                                    <br />
                                    <PointsAllocation />
                                </div>
                            </Tab>
                            <Tab label={I18n.t('Copy Set Up')}>
                                <div className="downloadTreeContainer">
                                    <br />
                                    <div className="col-md-12" style={{ padding: "0px" }}>
                                        <div className="col-md-12 row control-label">
                                            <label className="col-md-4 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('Action')}</label>
                                            <div className="col-md-6 row control-label">
                                                <label className="col-md-5 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('From Year')}</label>
                                                <label className="col-md-5 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('To Year')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group control-label">
                                            <div className={"col-md-4 noPadding"} >
                                                <label className="col-md-4 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('Copy KPI')}</label>
                                            </div>
                                            <div className={"col-md-6 row"}>
                                                <div className={"col-md-5"} style={{ paddingLeft: '0rem'}}>
                                                    <Select
                                                        placeholder= {I18n.t('others.select')}
                                                        defaultValue={fromYearOption}
                                                        value={fromYearOption}
                                                        options={[fromYearOption]}                                                        
                                                        isDisabled={true}
                                                    />
                                                </div>
                                                <div className={"col-md-5"} style={{ paddingLeft: '0rem'}}>
                                                    <Select
                                                        placeholder= {I18n.t('others.select')}
                                                        defaultValue={toYearOption}
                                                        value={toYearOption}
                                                        options={[toYearOption]}
                                                        isDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <CopyKpiHistory data={this.props.copyKPIHistoryList} />
                                    <br />
                                    <Row>
                                        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                            <Col md="2" style={{ marginTop: '-1px', float: "Right", width: "auto" }}>
                                                <button className={"button-" + this.props.theme.name} onClick={() => this.onClickCopy() }>
                                                    {I18n.t('Copy')}
                                                </button>
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Tab>
                        </Tabs>
                        :
                        <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab} >
                            <Tab label={I18n.t('Copy Set Up')}>
                                <div className="downloadTreeContainer">
                                    <br />
                                    <div className="col-md-12" style={{ padding: "0px" }}>
                                        <div className="col-md-12 row control-label">
                                            <label className="col-md-4 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('Action')}</label>
                                            <div className="col-md-6 row control-label">
                                                <label className="col-md-5 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('From Year')}</label>
                                                <label className="col-md-5 control-label" style={{ paddingLeft: '0rem', fontWeight: 'bold' }}>{I18n.t('To Year')}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-12 input-group control-label">
                                            <div className={"col-md-4 noPadding"} >
                                                <label className="col-md-4 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('Copy KPI')}</label>
                                            </div>
                                            <div className={"col-md-6 row"}>
                                                <div className={"col-md-5"} style={{ paddingLeft: '0rem'}}>
                                                    <Select
                                                        placeholder= {I18n.t('others.select')}
                                                        defaultValue={fromYearOption}
                                                        value={fromYearOption}
                                                        options={this.state.migrationToYear}
                                                        onChange={e => {
                                                            this.changeFromYear(e);
                                                        }}
                                                        // options={[fromYearOption]}
                                                        // isDisabled={true}
                                                    />
                                                </div>
                                                <div className={"col-md-5"} style={{ paddingLeft: '0rem'}}>
                                                    <Select
                                                        placeholder= {I18n.t('others.select')}
                                                        defaultValue={toYearOption}
                                                        value={toYearOption}
                                                        options={this.state.migrationFromYear}
                                                        onChange={e => {
                                                            this.changeToYear(e);
                                                        }}
                                                        // options={[toYearOption]}
                                                        // isDisabled={true}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <CopyKpiHistory data={this.props.copyKPIHistoryList} />
                                    <br />
                                    <Row>
                                        <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                                            <Col md="2" style={{ marginTop: '-1px', float: "Right", width: "auto" }}>
                                                <button className={"button-" + this.props.theme.name} onClick={() => this.onClickCopy() }>
                                                    {I18n.t('Copy')}
                                                </button>
                                            </Col>
                                        </div>
                                    </Row>
                                </div>
                            </Tab>
                        </Tabs>
                    }
                        <br />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        copyKPIHistoryList: state.ceaNetKPIReducer.copyKPIHistoryList
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(AnnualSetup))
);
