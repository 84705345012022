export default function dealerManagementReducer(state = {
    dealerManagementDetails: null,
    linkFilters :{},
    userInfo: {},
    nmscList:[],
    countryList:[],
    addModalDropDown:{},
    a2dDealerDetails:{},

    
}, action) {
    switch (action.type) {           
        case "FETCH_DEALER_REFRESH":
            console.log(action.payload)
            return Object.assign({}, state, {
                dealerManagementDetails: action.payload
                }); 
        case "FETCH_DEALERS_NAME_LIST":
            return Object.assign({}, state, {
                addModalDropDown: {dealerNameList:action.payload}
                }
                ); 
        case "A2D_DEALER_DETAILS_BY_NAME":
            return Object.assign({}, state, {
                a2dDealerDetails: {dealerDetails:action.payload}
                    }
                ); 
                
        case "A2D_DEALER_DETAILS_BY_UUID":
            return Object.assign({}, state, {
                a2dDealerDetails: {dealerDetails:action.payload}
                        }
                );  

        case "L3_DEALER_DATA":
                    return Object.assign({}, state, {
                        addModalDropDown: { l3List: action.payload }
                    });
        case "L4_DEALER_DATA":
                    return Object.assign({}, state, {
                        addModalDropDown: { l4List: action.payload }
                    });
        case "L5_DEALER_DATA":
                    return Object.assign({}, state, {
                        addModalDropDown: { l5List: action.payload }
                    });
        case "L6_DEALER_DATA":
                    return Object.assign({}, state, {
                        addModalDropDown: { l6List: action.payload }
                    }); 
                    
        case "REFRESH_DATA":
                return Object.assign({}, state, {
                addModalDropDown: action.payload,
                a2dDealerDetails:  action.payload
                 });    
        case "DEALER_LANG":
                 return Object.assign({}, state, {
                    addModalDropDown:{dealerLang: action.payload }
                });
                
        case "SAVE_SEARCH_FILTER":        
            return Object.assign({}, state, {
                dealerSearchFilter: action.payload 
            });

        case  "SEARCH_FILTER_REFRESH":
            return Object.assign({}, state, {
                dealerSearchFilter: action.payload 
            });    

        default:
            return state;

    }
};