export default function tabReducer(state = {
    userMdl: {}, blocking: false, closeModal: false,
    // KPIinOngoingActivity:false
}, action) {
    switch (action.type) {
        case "MASTER_KPI_DATA":
            return Object.assign({}, state, {
                masterKpiList: action.payload,
                closeModal: false
            });
        case "LOCAL_KPI_DATA":
            return Object.assign({}, state, {
                nmscList: action.payload,
                closeModal: false
            });
        case "SAVE_KPI_DATA":
            return Object.assign({}, state, {
                message: action.payload,
                closeModal: true
            });
        case "IMPORT_LOCAL_KPI_DATA":
            return Object.assign({}, state, {
                message: action.payload,
                closeModal: false
            });
        case "DELETE_KPI_DATA":
            return Object.assign({}, state, {
                message: action.payload,
                closeModal: false
            });
        case "PUBLISH_KPI":
            return Object.assign({}, state, {
                message: action.payload,
                closeModal: false
            });
        // case "KPI_PRESENT_IN_ONGOING/CLOSED_ACTIVITY":
        //     return Object.assign({}, state, {
        //         KPIinOngoingActivity: action.payload
        //     });
        case "UNITS":
            return Object.assign({}, state, {
                unitsList: action.payload,
                closeModal: false
            });
        case "EAUNITS":
                return Object.assign({}, state, {
                    eaUnitsList: action.payload,
                    closeModal: false
                });
        case "LOCAL_LANG_KPI_DATA":
            return Object.assign({}, state, {
                nmscLocalLangList: action.payload,
                closeModal: false
            });
        case "KPI_FILE_NAME":
            return Object.assign({}, state, {
                fileName: action.payload,
                closeModal: false
            });
        case "SAVE_ACTIVITY_DATA":
            return Object.assign({}, state, {
                message: action.payload,
                closeModal: true
            });
        case "TME_FILE_NAME_LIST":
            return Object.assign({}, state, {
                tmeFileList: action.payload,
                closeModal: true
            });
        case "MAIN_QUESTION_CONTENT":
            return Object.assign({}, state,{
                questionContent: action.payload,
                closeModal: false
            });
        case "GET_SUB_QUESTIONS":
            return Object.assign({}, state,{
                subQuestions: action.payload,
                closeModal: false
            });
        case "GET_NMSC_SUB_QUESTIONS":
            return Object.assign({}, state,{
                nmscSubQuestions: action.payload,
                closeModal: false
            });
        case "MAIN_CHAPTER_SEQ":
            return Object.assign({}, state,{
                chapterSequence: action.payload,
                closeModal: false
            });
        case "NMSC_MAIN_CHAPTER_SEQ":
            return Object.assign({}, state,{
                nmscChapterSequence: action.payload,
                closeModal: false
            });
        case "NMSC_MAIN_QUESTION_CONTENT":
            return Object.assign({}, state,{
                nmscQuestionContent: action.payload,
                closeModal: false
            });

            
        default:
            return state;
    }
};