import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
import { fetchKaizenPoints } from "./kaizenAction.jsx";
import * as AppConst from "../AppConstant";
import FileDownload from "js-file-download";
var I18n = require("react-redux-i18n").I18n;
/* eslint-disable */
/**
 * Fetch nmsc from server.
 */
export function fetchActivityFilters(activityDto, searchFor) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/activitySearchFilters/" + searchFor, activityDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        var type =
          searchFor === "nmscCode"
            ? "NMSC_DATA"
            : searchFor === "l1"
              ? "BRAND_DATA"
              : searchFor === "l2"
                ? "COUNTRY_DATA"
                : searchFor === "l3"
                  ? "REGION_DATA"
                  : "ASSIGNEE_DATA";
        //dispatch(setBlockingState(false));
        dispatch({
          type: type,
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
/**
 * Fetch nmsc from server.
 */
export function fetchDealer(activityDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/dealer", activityDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "DEALER_CODE",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
/**
 * Fetch nmsc from server.
 */
export function fetchActivityStatus() {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/activity/activityStatus", {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_STATUS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Publish selected kpis.
 */
export function fetchToolBox(program, type) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/activity/activitytoolBox/" + program, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_TOOLBOX",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchToolBoxForTme(program) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/activity/tmeActivityToolBox/" + program, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_TOOLBOX",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


/**
 * Fetch nmsc from server.
 */
export function searchActivity(activityDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/search", activityDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function launchEvaluations(activityDt) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/launchEvaluations", activityDt, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "ACTIVITY_CEA_EVALUTIONS_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function launchEvalutionsForPerformance(activityDt) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/launchEvalutionsForPerformance", activityDt, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "ACTIVITY_CEA_EVALUTIONS_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function launchEvaluationsUpdate(activityList, searchEvaluationDTO,actionDto,activityStatusList,search,activeProgram) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/launchEvaluationsStatusUpdate", activityList, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if(activityStatusList && activityStatusList.length>1){
          searchEvaluationDTO.statusList = []
        }else if(activityStatusList && activityStatusList.length===1){
          if(searchEvaluationDTO.statusList[0] === 'DRAFT'){
            searchEvaluationDTO.statusList = ['ONGOING']
          }
        }
        toastr.success(I18n.t("ceaEvaluation."+response.data.desc))
        search(searchEvaluationDTO)
        dispatch(fetchEvaluationFilters(searchEvaluationDTO,'activityStatus'))
        dispatch(closeEvaluationCheck(actionDto,activeProgram))
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function launchTargetSetting(activityDt) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/launchTargetSetting", activityDt, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if (response.data === "Evaluations are already exist") {
          toastr.error("", I18n.t("ceaEvaluation.evaluationsAlreadyExist"));
        } else if (response.data === "Evaluations point calculation is not correct") {
          toastr.error("", I18n.t("ceaEvaluation.evaluationPointsNotCorrect"));
        } else if (response.data === "Some Error") {
          toastr.error("", "Some Error from Server.");
        }
        else {
          toastr.success(I18n.t("ceaEvaluation.targetSettingLaunched"));
          // dispatch(launchTargetActivity(activityDto))
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function moveToLaunchSettings(activityDt) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/moveToLaunchSettings", activityDt, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        if (response.data === "Evaluations are already exist") {
          toastr.error("", I18n.t("ceaEvaluation.evaluationsAlreadyExist"));
        } else if (response.data === "Evaluations point calculation is not correct") {
          toastr.error("", I18n.t("ceaEvaluation.evaluationPointsNotCorrect"));
        } else if (response.data === "Some Error") {
          toastr.error("", "Some Error from Server.");
        } else if (response.data === "NO_PUBLISHED_KPI") {
          toastr.error(I18n.t("toastr.noPublishKpi"));
        }
        else {
          toastr.success(I18n.t("ceaEvaluation.targetSettingLaunched"));
          // dispatch(launchTargetActivity(activityDto))
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function getActivity(searchEvaluationDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/ceaSearch", searchEvaluationDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_CEA_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function launchTargetActivity(searchEvaluationDTO) {
  let url = "/activity/ceaSearch"
  if(searchEvaluationDTO.program === AppConst.activeProgramUc){
    url = "/activity/updateEvaluationStatus"
  }
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post(url, searchEvaluationDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        if(response.status===200 && searchEvaluationDTO.program === AppConst.activeProgramUc){
          dispatch(getActivity(searchEvaluationDTO))
        }else{
          dispatch({
            type: "ACTIVITY_CEA_LIST",
            payload: response.data
          });
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Fetch blank activity list from server.
 */
export function searchEmptyActivityList() {
  return function (dispatch) {
    dispatch({
      type: "ACTIVITY_LIST",
      payload: null
    });


  };
}

export function fetchAssignee(dealerId, activeProgram) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    const saveActivityDTO = {
      dealerId: Number(dealerId),
      programName: activeProgram
    };
    axios
      .post("/activity/assignee", saveActivityDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ASSIGNEE_CODE",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchActivityKpis(activityId, lang, nmsc, activeProgram, selectedActivity, searchCriteria) {
  return function (dispatch) {
    let url = "/activity/" + activityId + "/kpi/" + lang + "/" + nmsc + "/" + activeProgram;
    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_KPI_LIST",
          payload: response.data
        });
        dispatch(fetchActivityDetails(activityId, selectedActivity, searchCriteria));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function saveActivity(activityId, activeProgram, data, selectedActivity, searchCriteria) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/activity/" + activityId + "/" + activeProgram + "/saveKpi";
    axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch({
          type: "ACTIVITY_KPI_SAVED",
          payload: response.data
        });
        dispatch(fetchActivityDetails(activityId, selectedActivity, searchCriteria));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function fetchActivityDetails(activityId, selectedRow, searchCriteria) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get('/activity/' + activityId + "/" + searchCriteria.nmscCode + "/" + searchCriteria.langCode +
        "/" + searchCriteria.type, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTIVITY_DETAIL",
          payload: response.data
        });
        // dispatch(push({
        //   pathname: "/actionPlan",
        //   query: { selectedActivity: selectedRow, searchCriteria: searchCriteria }
        // }));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function deleteActivity(activityId, searchCriteria) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete("/activity/" + activityId)
      .then(response => {

        if (response.data.desc === 'activity_certified_dealer') {
          toastr.error(I18n.t("toastr.activity_certified_dealer"));

        }else if(response.data.desc==='activity_deleted'){
          toastr.success(I18n.t("toastr.activity_deleted"));


        }
        //dispatch(setBlockingState(false));
        dispatch(searchActivity(searchCriteria));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

// get all action plans from activity id
export function fetchActionPlans(activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get('/actionPlan/' + activityId, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTION_PLAN_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}


export function saveActionPlans(data, activityId,viewStatusDTO, this_) {
  return function (dispatch) {

    //dispatch(setBlockingState(true));
    let url = "/actionPlan/save";
    axios
      .post(url, data, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch({
          type: "ACTION_PLAN_SAVED",
          payload: response.data
        });
        dispatch(fetchActionPlans(activityId));
        dispatch(fetchKaizenPoints(activityId))

        this_.props.toggle(this_.props.modalFor);
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        this_.disableSave();
        toastr.error("" + error);
      });

    dispatch(fetchActionPlans(activityId));
    console.log(viewStatusDTO);
    if(viewStatusDTO){
      dispatch(saveVisibilityOnUpdate(viewStatusDTO));
    }
  }


}
export function fetchActionPlanStatus() {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/actionPlan/actionPlanStatus", {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "ACTION_PLAN_STATUS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


// get all followup comments from action plan id
export function getFollowUpList(activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get('/actionPlan/' + activityId + "/followUp", {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "FOLLOWUP_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}
export function saveActivityMonthTarget(targetList, activityId, lang, nmsc) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/activity/saveMonthlyTarget";
    axios
      .post(url, targetList, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch({
          type: "ACTIVITY_KPI_SAVED",
          payload: response.data
        });
        //  dispatch(fetchActivityKpis(activityId, lang, nmsc,null));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function downloadActionPlanDtls(id, lang, nmscCode, brand, activeProgram, activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios({
      method: "get",
      contentType: "application/pdf",
      url: "/actionPlan/" + id + "/download/" + lang + "/" + nmscCode + "/" + brand + "/" + activeProgram + "/" + activityId,
      headers: {
        Pragma: "no-cache"
      },
      responseType: "arraybuffer"
    })
      .then(function (response) {
        //dispatch(setBlockingState(false));
        FileDownload(response.data, decodeURI(response.headers["filename"].replace(/\+/g, " ")));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function deleteActionPlan(actionPlanId, activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));

    axios({
      method: "delete",
      Accept: "application/json",
      url: "/actionPlan/" + actionPlanId + "/delete"
    })
      .then(function (response) {
        //dispatch(setBlockingState(true));
        toastr.success((I18n.t("ServerMessage." + response.data.desc)));
        dispatch(fetchActionPlans(activityId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function downloadActionPlanSummary(activityId, lang, nmscCode, brand, activeProgram) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios({
      method: "get",
      contentType: "application/pdf",
      url: "/activity/" + activityId + "/download/" + lang + "/" + nmscCode + "/" + brand + "/" + activeProgram,
      responseType: "arraybuffer"
    })
      .then(function (response) {
        //dispatch(setBlockingState(false));
        FileDownload(response.data, decodeURI(response.headers["filename"].replace(/\+/g, " ")));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchDealerActivityDetails(activityId,nmscCode,langCode) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    const saveActivityDTO = {
      activityId: Number(activityId),
      nmscCode: nmscCode,
      langCode: langCode
    };
    axios
      .post("/activity/retailerActual", saveActivityDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "RETAILER_ACTUAL",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

// get all sub question info for particular activity id
export function fetchSubQueInfo(activityId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get('/actionPlan/fetchSubQuestions/' + activityId, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        let subQuesInfo = []
        response.data.map(function (x, i) {
          subQuesInfo[x.seqNumber] = x
        })
        console.log(subQuesInfo)
        dispatch({
          type: "SUB_QUES_INFO",
          payload: subQuesInfo
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function fetchDealerCode(activityDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/dealerCode", activityDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "DEALER_CODE_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}


export function fetchEvaluationFilters(searchEvaluationDTO, searchFor) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/evaluationSearchFilters/" + searchFor, searchEvaluationDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        var type =
          searchFor === "nmscCode"
            ? "EVAL_NMSC_CODE_DATA"
            : searchFor === "businessFunction"
              ? "EVAL_BUSINESS_FUNCTION_DATA"
              : searchFor === "activityStatus"
                ? "EVAL_ACTIVITY_STATUS"
                : ""

        //dispatch(setBlockingState(false));
        dispatch({
          type: type,
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function closeEvaluationCheck(activityDt,activeProgram) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/closeEvaluation", activityDt, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "CLOSE_EVALUATION",
          payload: response.data
        });
        if(response.data.launchEvaluation){
          if(activeProgram === AppConst.activeProgramNet){
            dispatch(launchEvalutionsForPerformance(activityDt))
          }else{
            dispatch(launchEvaluations(activityDt))
          }        
        }

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function checkForPendingParameters(ongoinActivityList, closePopUP) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/checkPendingParameters", ongoinActivityList, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        return new Promise((resolve, reject) => {
          resolve(response.data.pendingParameter)
        }).then((result) => {
          closePopUP(result)
        })
      }).catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}


export function closeEvaluation(ongoingActivitiesList,activityDto,actionDTO,search,activityStatusList) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/activity/closeEvaluationUpdate", ongoingActivitiesList, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        toastr.success(I18n.t("ceaEvaluation."+response.data.desc))
        if(activityStatusList && activityStatusList.length>1){
          activityDto.statusList = []
        }else if(activityStatusList && activityStatusList.length === 1){
          if(activityDto.statusList[0] === 'ONGOING'){
            activityDto.statusList = ['COMPLETED']
          }
        }
        search(activityDto)
        dispatch(fetchEvaluationFilters(activityDto,'activityStatus'))
        dispatch(closeEvaluationCheck(actionDTO))
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function saveVisibilityOnUpdate(statusDTO,searchCriteria) {
  return function (dispatch) {
    let url = "/activity/saveVisibilityOnUpdate";
    axios
      .post(url, statusDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        // toastr.success(response.data.desc);
      })
      .catch(function (error) {
        toastr.error("" + error);
      });
  };
}