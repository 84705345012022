import React from 'react';
import LoginForm from './components/LoginForm.jsx';
import { connect } from 'react-redux';
import {withRouter} from 'react-router-dom';

class Login extends React.Component {
componentWillMount(){
    console.log('Login route called');
}
    render() {
        
        return (
            <LoginForm />
        );
    }
}
export default withRouter(connect()(Login));