import React from 'react';
import { withTheme } from "../../../themes/theming";
import Switch from "react-switch";

const NotificationsSwitch = ({ theme, notificationsFlag, onToggle }) => (
  <Switch
    checked={notificationsFlag === 1 ? true : false}
    onChange={onToggle}
    onColor={theme.primaryColor}
    onHandleColor={theme.primaryColor}
    handleDiameter={18}
    uncheckedIcon={false}
    checkedIcon={false}
    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
    height={8}
    width={40}
    className="react-switch mr-1"
    id="notifications-switch"
  />
);

export default withTheme(NotificationsSwitch);
