export default function questionSummaryReducer(state = {
  questionResponse: {}, blocking: false, tabList: [], nmscQuestionResponse: {}
}, action) {
  switch (action.type) {
    case "QUESTION_SUMMARY":
      return Object.assign({}, state, {
        questionResponse: action.payload

      });
      case "NMSC_QUESTION_SUMMARY":
      return Object.assign({}, state, {
        nmscQuestionResponse: action.payload

      });
    case "CHAPTER_SAVED":
      return Object.assign({}, state, {
        chapterSaved: action.payload

      });
    case "CHAPTER_ID":
      return Object.assign({}, state, {
        chapterId: action.payload

      });
    case "QUESTION_ID":
      return Object.assign({}, state, {
        selectedQuestionId: action.payload

      });

    case "GET_PUBLISHED_SUB_QUESTIONS":
      return Object.assign({}, state, {
        publishedSubQuestions: action.payload

      });
    case "GET_SAVED_SUB_QUESTIONS":
      return Object.assign({}, state, {
        savedSubQuestions: action.payload

      });
    case "STAT_COUNT":
      return Object.assign({}, state, {
        statCount: action.payload

      });

    case "NMSC_TAB_LIST":
      return Object.assign({}, state, {
        tabList: action.payload
      });

    case "FIRST_LOAD":
      return Object.assign({}, state, {
        firstLoad: action.payload

      });

      case "SUB_Q_PUBLISHED":
      return Object.assign({}, state, {
        subQPublished: action.payload
      });

    default:
      return state;
  }
};