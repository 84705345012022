import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow } from 'mdbreact';
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
var I18n = require("react-redux-i18n").I18n;
class CertificationRate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: {},
            totalValue: null,
            toyotaValue: null,
            lexusValue: null

        };
    }

    componentWillMount() {
        let activeProgram = this.props.user.userMdl.activeProgram;
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        // Fetch certificationRate.
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            nmscFlag: true,
            userId: this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.setState({ isLoading: true });

        this.props.dispatch(fetchScrollableWidgetData("certificationRate", "CERTIFICATION_RATE", queryDto));
    }
    componentWillReceiveProps(nextProps) {
        // if (nextProps.dashboardReducer && nextProps.dashboardReducer.certificationRate
        //     && this.state.data && this.state.data.value !== nextProps.dashboardReducer.certificationRate.value) {
        //     this.setState({ data: nextProps.dashboardReducer.certificationRate, isLoading: false });
        // }
        if (nextProps.certificationRate) {
            this.setState({
                totalValue: nextProps.certificationRate.value,
                toyotaValue: nextProps.certificationRate.toyotaValue,
                lexusValue: nextProps.certificationRate.lexusValue, isLoading: false
            });

        }
    }
    render() {
        var totalValue = null;
        var toyotaValue = null;
        var lexusValue = null;
        if (this.state.totalValue != null) {
            totalValue = this.state.totalValue + "%"
        }
        if (this.state.toyotaValue != null) {
            toyotaValue = this.state.toyotaValue + "%"
        }
        if (this.state.lexusValue != null) {
            lexusValue = this.state.lexusValue + "%"
        }


        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.certificationRate')}>{I18n.t('dashboard.certificationRate')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{ minHeight: "3rem" }}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {totalValue}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow className="widget_brand_count_section">
                            <MDBCol lg="6" md="6">
                                <img src="images/TMEToyotaEllipse.png" alt="logo" height="21px" className="theme_logo" />
                            </MDBCol>
                            <MDBCol lg="6" md="6">
                                <img src="images/Lexus2D.jpg" alt="logo" height="20px" className="theme_logo" />
                            </MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol lg="6" md="6" className="widget_brand_count">{toyotaValue}</MDBCol>
                            <MDBCol lg="6" md="6" className="widget_brand_count">{lexusValue}</MDBCol>

                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        // blocking: state.loginReducer,
        router: state.routing,
        certificationRate: state.dashboardReducer.certificationRate,

    };
};
export default withRouter(connect(mapStateToProps)(withTheme(CertificationRate)));