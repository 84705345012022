import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Fa } from "mdbreact";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";

import {
    uploadBulk,uploadValueChainQuestionnaire
} from "../../../actions/bulkUploadAction";
import { withTheme } from "../../../themes/theming";
import $ from "jquery";
import "./../index.css";
var I18n = require("react-redux-i18n").I18n;

class UploadPanel extends React.Component {



    constructor(props) {
        super(props);
        this.state = {
            // uploadResponse: true,

        };
        this.bulkUploadBxRef= React.createRef();
    }


    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.bulkUploadProps)

        // this.setState({ uploadResponse: nextProps.bulkUploadProps.bukUploadResponse });
    }


    onClickUpload = (e) => {

        console.log(this.state.selectedFile)

        if (this.state.selectedFile !== undefined && this.state.selectedFile) {
            var ext = this.state.selectedFile.name.split(".").pop();

            console.log(ext)
            if (ext && (ext.toUpperCase() === 'XLS' || ext.toUpperCase() === 'XLSX')) {
                const postData = new FormData();
                postData.append('file', this.state.selectedFile);

                let  fileCriteria={
                    sizePerPage: 10,
                    page: 1,
                    fileType: 'BRIT_UPLOAD_ACTIVITY',
                    username : this.props.user.userMdl.username,

                }

                // this.props.user.userMdl.username
                if(this.props.fileType==="VC_QUESTIONNAIRE_UPLOAD") {
                    fileCriteria={
                        sizePerPage: 10,
                        page: 1,
                        fileType: 'VC_QUESTIONNAIRE_UPLOAD',
                        username : this.props.user.userMdl.username,
                    }
                    this.props.dispatch(uploadBulk(fileCriteria,postData,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang,this.props.user.userMdl.username,"uploadValueChainQuestionnaire"));
                } else if(this.props.fileType==="TSP_UPLOAD_ACTIVITY") {
                    fileCriteria={
                        sizePerPage: 10,
                        page: 1,
                        fileType: 'TSP_UPLOAD_ACTIVITY',
                        username : this.props.user.userMdl.username,
                    }
                    this.props.dispatch(uploadBulk(fileCriteria,postData,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang,this.props.user.userMdl.username,"uploadTSPActivities"));
                } 



                else if(this.props.fileType==="BRIT_UPLOAD_ACTIVITY"){
                    this.props.dispatch(uploadBulk(fileCriteria,postData,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang,this.props.user.userMdl.username,"uploadBrit"));

                }else{
                    
                    if(this.props.fileType==="EA_UPLOAD_ACTIVITY"){
                        fileCriteria={
                            sizePerPage: 10,
                            page: 1,
                            fileType: 'EA_UPLOAD_ACTIVITY',
                            username : this.props.user.userMdl.username,
        
                        }
                        this.props.dispatch(uploadBulk(fileCriteria,postData,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang,this.props.user.userMdl.username,"uploadEA"));
                    } 
                    
                    
                    
                    else{
                        fileCriteria={
                            sizePerPage: 10,
                            page: 1,
                            fileType: 'VALUE_CHAIN_UPLOAD_ACTIVITY',
                            username : this.props.user.userMdl.username,
        
                        }
                        this.props.dispatch(uploadBulk(fileCriteria,postData,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang,this.props.user.userMdl.username,"uploadValueChain"));
                    }                    
                }

            } else {
                toastr.error(I18n.t('toastr.fileSelectionErr'));
            }
        } else {
            toastr.error(I18n.t('toastr.fileSelectionErr'));

        }
    }

    onFileChange = event => {
        console.log(event)
        if (event.target.files[0]) {
            this.setState({ selectedFile: event.target.files[0] })
            this.setState({ uploadLabel: event.target.files[0].name })

            $("#bulkUploadBx").attr("placeholder", event.target.files[0].name);

        }
    }


    render() {
        // console.log(this.props.bulkUploadProps)
        return (

            <Container
                fluid
                className="ml-0 mb-2 pb-0"
                style={{ border: "1px solid grey", borderRadius: "10px" }}>

                <Row>
                    <span className="sectionLabel">{I18n.t('uploadBulk.fileUpload')}</span>
                </Row>
                <Row className="mt-2 mb-2">
                    <Col md="4" className="pr-0">
                        <input
                            id="bulkUploadBx"
                            ref={this.bulkUploadBxRef}
                            type="text"
                            className="form-control"
                            placeholder={I18n.t('uploadBulk.selectFile')}
                            readOnly
                        />
                    </Col>
                    <Col md="1" className="ml-0 pl-0">
                        <label className="input-group-btn">
                            <div
                                className="btn btn-sm mt-0"
                                style={{ width: "75%", backgroundColor: "#6c757d" }}
                            >
                                <span
                                    style={{ display: "inline-flex", marginLeft: "-10px" }}
                                >
                                    <Fa icon="folder-open" />
                                                &nbsp;&nbsp;
                                                {I18n.t('uploadBulk.browse')}
                                    &hellip;
                                            </span>
                                <input
                                    type="file"
                                    onChange={this.onFileChange}
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"

                                    // accept={".xlsx,.xls"}
                                    style={{
                                        display: "none"
                                    }}
                                />
                            </div>
                        </label>
                    </Col>
                    <Col md="7">
                        <button
                            className={"button-" + this.props.theme.name}
                            disabled={this.state.uploadResponse}
                            style={{ float: "right" }}
                            onClick={() => this.onClickUpload()}
                        >
                            {/* Upload */}
                            {I18n.t('library.upload')}
                                    </button>
                        {/* <button
                            className={"button-" + this.props.theme.name}
                            // disabled={this.state.uploadResponse}
                            style={{ float: "right" }}
                        // onClick={e => this.uploadNetworkFile(e)}
                        >
                            Export Template
                                    </button> */}
                    </Col>
                </Row>
            </Container>

        );

    }

}


const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(UploadPanel))
);

