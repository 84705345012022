import React from 'react';
import { connect } from 'react-redux';
import Header from './components/header';
import Footer from './components/footer';
import { ThemeProvider, themes } from '../../themes/theming';
import { withRouter } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import './index.css';

class Container extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            blocking: false,
            isLexus: false,
            theme: themes.default,
        };
    }
   
    componentWillMount() {


     


    }
    componentWillReceiveProps(nextProps) {
        this.setState({ blocking: nextProps.blocking.blocking });
    }
    handleThemeChange = (themeName) => {
        this.setState({ theme: themes[themeName], isLexus: !this.state.isLexus });
    };
    render() {
        return (
            <BlockUi tag="div" blocking={this.state.blocking} message="Loading, please wait">
                <ThemeProvider theme={this.props.user.userMdl.themeFlag ? themes['default'] : themes['dark']}>
                    <Header handler={this.handleThemeChange} isLexus={this.state.isLexus} />
                    {this.props.children}
                    <Footer />
                </ThemeProvider>
            </BlockUi>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing
    }
}
// export default withRouter(connect(mapStateToProps, null, null, { pure: false })(Container));
export default withRouter(connect(mapStateToProps)(Container));