import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from 'mdbreact';
import Select from 'react-select';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { saveLocalKpi, getUnitsList, getEaUnitsList } from '../../actions/tabAction.jsx';
import './index.css';
import { Tab, Tabs } from 'react-bootstrap-tabs';
import { withTheme } from '../../themes/theming';
import * as AppConst from '../../AppConstant';
import { toastr } from 'react-redux-toastr';
var I18n = require('react-redux-i18n').I18n;

class NmscModal extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            selectedStatus: null,
            selectedRow: this.props.selectedRow,
            list: [],
            disable: false
        };
    }
    saveKpi = () => {
        this.validationAddKPI() ? this.saveLocalKpi() : toastr.error(I18n.t('standardMessages.MandatoryWarning'));

        //this.props.dispatch(saveKpi(this.state.selectedRow));
        //this.props.toggle(false);
    }

    saveLocalKpi() {
        console.log(this.state.list)
        console.log(this.props.toolboxId)

        console.log(this.props.user.userMdl)

        console.log(this)

        this.props.dispatch(saveLocalKpi(this.state.list, this.props.toolboxId, this.props.user.userMdl, this));


    }
    validationAddKPI() {


        var kpiList = this.state.list;
        var isValid = false;
        var errorList = [];
        kpiList.map((obj, index) => {
            if(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA"){
                var brand = [];
                if(obj.brandToyota){
                    brand.push(AppConst.BRAND_TOYOTA)
                }
                if(obj.brandLexus){
                    brand.push(AppConst.BRAND_LEXUS)
                }
                obj.brand = brand.length> 0 ? brand.toString() : ""
                if (obj.nameEn.length === 0 || obj.definitionEn.length === 0 || obj.name.length === 0
                    || obj.definition.length === 0 || obj.target.length === 0 || obj.unit.length === 0 || obj.brand.length === 0) {
                    errorList.push(index);
                } 
            }else if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
            || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS"){

                if (obj.nameEn.length === 0 || obj.definitionEn.length === 0 || obj.name.length === 0
                    || obj.definition.length === 0 || obj.benchmark.length === 0) {
                    errorList.push(index);
                }
            }else{
                //..
            }
        });
        return errorList.length === 0 ? true : false;


    }

    onMandatory = (event, action, index) => {
        const list = this.state.list;
        list[index][action] = !this.state.list[index][action];
        this.setState({ list });
      };

    onChangeBrand = (event, action, index) => {
        const list = this.state.list;
        list[index][action] = !this.state.list[index][action];
        this.setState({ list });
    };

    onAddKPIChange = (event, action, index) => {


        if (action === "benchmark") {
            var Number = /^[\d\/\(\)\- \.]+$/;
            var benchmarkValue = event.target.value;
            if (Number.test(benchmarkValue) || benchmarkValue == "") {
                this.state.list[index][action] = event.value;
            } else {
                //this.state.selectedRow.benchmark = numText;
                //toastr.error('The benchmark field should contain numbers only.')
                return;
            }
        }

        if (action === "target") {
            var Number = /^[\d\/\(\)\- \.]+$/;
            var targetValue = event.target.value;
            if (Number.test(targetValue) || targetValue == "") {
                this.state.list[index][action] = event.target.value;
            } else {
                //this.state.selectedRow.benchmark = numText;
                //toastr.error('The benchmark field should contain numbers only.')
                return;
            }
        }


        const addKPI = { ...this.state.list[index] };
        if (event) {
            if (action === "unit") {
                this.state.list[index][action] = event.value;
                this.state.list[index].unitSelection = event;
            } else {
                this.state.list[index][action] = event.target.value;
            }
            this.setState({ addKPI: addKPI });
        }
        else {
            const list = this.state.list;
            list[index]["unit"] = '';
            list[index].unitSelection = '';
            this.setState({
                list
            });
        }
    }

    onStatusChange = (selectedOption) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow['unit'] = selectedOption !== null ? selectedOption.value : '';
        this.setState({
            selectedRow: selectedRow,
            selectedUnit: selectedOption
        });
        // this.state.selectedUnit = selectedOption;
        // this.state.selectedRow.unit = selectedOption.value;
        // this.setState({ selectedRow: this.state.selectedRow });
    }

    onHandleChange = (event, action) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow[action] = event.target.value;
        this.setState({ selectedRow });
    }


    selectedTab = (index, label) => {
        this.setState({ selectedTab: index })
    }
    componentWillMount() {
        this.props.dispatch(getUnitsList());
        this.props.dispatch(getEaUnitsList());
        var this_ = this;
        this.props.user.userMdl.userLangList.map((obj, index) => {
            this_.state.list.push({
                localKpiId: 0,
                toolboxId: this.props.toolboxId,
                packageProgramMappingId:this.props.toolboxId,
                brand: '',
                name: '',
                definition: '',
                nameEn: '',
                definitionEn: '',
                benchmark: '',
                target: '',
                unit: '',
                status: '',
                additionalInfo: '',
                mandatory: '',
                publishedOn: '',
                createdBy: this.props.user.userMdl.username,
                updatedBy: this.props.user.userMdl.username,
                reference: "LOCAL",
                createdTimestamp: new Date(),
                nmscCode: this.props.user.userMdl.nmsc,
                langCode: obj.language.toUpperCase()
            })
        })
        if (this.props.modalFor === "Edit" && this.props.reference === "TME"
    
    ) {
            this.setState({ disable: true });
        }
        else {
            this.setState({ disable: false });
        }
    }
    componentDidMount() {

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.unitsList || nextProps.eaUnitsList) {
            // this.setState({ unitsListKPI: nextProps.unitsList });
            const options1 = nextProps.unitsList !== undefined ?
            nextProps.unitsList.map(d => ({
                value: d,
                label: d
              }))
              : ''
              const options2 = nextProps.eaUnitsList !==undefined ?
              nextProps.eaUnitsList.map(d => ({
                value: d==="mï¿½"?"m³" :d,
                label: d==="mï¿½"?"m³" :d
              }))
              : ''
            this.setState({
                unitListKPILIST: this.props.user.userMdl.activeProgram==="EA"? 
                nextProps.eaUnitsList!==undefined ?options2:options1:options1
            });
        }

        if (nextProps.nmscLocalLangList) {
            var list = [];
            this.props.user.userMdl.userLangList.map((obj, index) => {
                nextProps.nmscLocalLangList.map((row, rowIndex) => {
                    if((this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA") && this.state.list[index].target === '' && (row.target === '' || row.target === undefined)){
                        row.target=''
                    }                    
                    if ((this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") && row.brand !== undefined && row.brand !== '') {
                        let brandArr = row.brand.split(',');
                        if(brandArr.length>0){
                            row.brandToyota = brandArr.includes(AppConst.BRAND_TOYOTA) ? true : false
                            row.brandLexus = brandArr.includes(AppConst.BRAND_LEXUS) ? true : false
                        }
                    }  
                    if (row.langCode == obj.language) {
                        row.unitSelection = { value: row.unit, label: row.unit };
                        row.nmscCode = this.props.user.userMdl.nmsc;
                        list.push(row)
                    }
                });

            });
            this.setState({ list: list });
        }
    }
    benchmarkTitle=()=>{
        if(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
        || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS"){
          return I18n.t("tmeAdminMaster.benchmark");
        }else if(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA"){
          return I18n.t('Table.penEbenchmark');
        }else{
          //..
        }
      }
    preventDefault() { }

    render() {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'KPI_DETAIL_NMSC_TARGET')[0] ?
            this.props.user.userMdl.accessControl.filter(obj => obj.function === 'KPI_DETAIL_NMSC_TARGET')[0].accessType : null;

        var benchmarkTitle=I18n.t("tmeAdminMaster.benchmark");
        benchmarkTitle=this.benchmarkTitle();
        const dynamicKPITab = this.props.user.userMdl.userLangList.map((obj, index) => {
            return (

                <Tab label={obj.country + " / " + obj.language} key={index}>
                    <div className="col-md-12">
                        <form className="form-horizontal nmscModalForm">
                            {(this.props.user.userMdl.activeProgram ===  "BRiT" || this.props.user.userMdl.activeProgram ===  "EA") ? 
                                <div>
                                    <label className="col-md-6 control-label">
                                        <input
                                            type="checkbox"
                                            id="brandToyota"
                                            checked={this.state.list[index].brandToyota}
                                            onChange={(e) => this.onChangeBrand(e, 'brandToyota', index)}
                                        /> {I18n.t("tmeAdminMaster.toyota")}
                                    </label>
                                    <label className="col-md-6 control-label">
                                        <input
                                            type="checkbox"
                                            id="brandLexus"
                                            checked={this.state.list[index].brandLexus}
                                            onChange={(e) => this.onChangeBrand(e, 'brandLexus', index)}
                                        /> {I18n.t("tmeAdminMaster.lexus")}
                                    </label>
                                </div> : null           
                            }
                            <label className="col-md-12 control-label">{I18n.t('tmeAdminMaster.keyPerformanceIndicator')} (EN) <span className="redFont">*</span></label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    autoFocus
                                    disabled={this.state.disable}
                                    value={this.state.list[index].nameEn}
                                    onChange={(e) => this.onAddKPIChange(e, 'nameEn', index)}
                                    maxLength="50"
                                />
                            </div>

                            <label className="col-md-12 control-label">{I18n.t('tmeAdminMaster.kpiDefinition')} (EN) <span className="redFont">*</span></label>
                            <div className="col-md-12">
                                <textarea className="form-control" rows="3"
                                    onChange={(e) => this.onAddKPIChange(e, 'definitionEn', index)}
                                    value={this.state.list[index].definitionEn}
                                    disabled={this.state.disable}
                                    maxLength="100" />
                            </div>

                            <label className="col-md-12 control-label">{I18n.t('tmeAdminMaster.keyPerformanceIndicator')} <span className="redFont">*</span></label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className="form-control"
                                    autoFocus
                                    value={this.state.list[index].name}
                                    maxLength="50"
                                    onChange={(e) => this.onAddKPIChange(e, 'name', index)}
                                />
                            </div>

                            <label className="col-md-12 control-label">{I18n.t('tmeAdminMaster.kpiDefinition')} <span className="redFont">*</span></label>
                            <div className="col-md-12">
                                <textarea className="form-control" rows="3"
                                    onChange={(e) => this.onAddKPIChange(e, 'definition', index)}
                                    value={this.state.list[index].definition}
                                    maxLength="100"
                                />
                            </div>
                            {!((this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA") && (this.props.reference===undefined || this.props.reference==="LOCAL" || this.state.reference==="LOCAL"))?
                                <div className="col-md-12 row control-label">
                                    <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{benchmarkTitle} <span className="redFont">*</span></label>
                                    <label className="col-md-6 control-label" style={{ paddingLeft: '0.5rem', marginTop: "10px" }}>{I18n.t('tmeAdminMaster.unit')}</label>
                                </div>
                            :null}
                            {!((this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA") && (this.props.reference===undefined || this.props.reference==="LOCAL" || this.state.reference==="LOCAL"))?
                            <div className="col-md-12 input-group control-label">
                                {(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
                                || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS")?
                                <input
                                    type="text"
                                    className="form-control col-md-7"
                                    value={this.state.list[index].benchmark}
                                    maxLength="10"
                                    onChange={(e) => this.onAddKPIChange(e, 'benchmark', index)}
                                />
                                :(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA")?
                                <input
                                    type="text"
                                    className="form-control col-md-7"
                                    style={{color:"black"}}
                                    value={this.state.list[index].benchmark}
                                    maxLength="10"
                                    disabled
                                />:null
                                }
                                
                                &nbsp;
                                {(this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_USED_CAR"
                                || this.props.user.userMdl.activeProgram==="TSM_BNP" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS")?
                                <Select                       
                                    placeholder= {I18n.t('others.select')}
                                    value={this.state.list[index].unitSelection}
                                    onChange={(e) => this.onAddKPIChange(e, 'unit', index)}
                                    options={this.state.unitListKPILIST}
                                    isClearable={true}
                                    className="addkpi_modal_unit"
                                />:(this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA")?<Select
                                    value={this.state.list[index].unitSelection}
                                    isDisabled={true}
                                    options={this.state.unitListKPILIST}
                                    isClearable={true}
                                    className="addkpi_modal_unit"
                                />:null}
                            </div>
                            :null}

                            {(this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") ?
                                <div>
                                    <div className="col-md-12 row control-label">
                                        <label className="col-md-6 control-label" style={{ paddingLeft: '0.3rem' }}>{I18n.t('Table.nmscTarget')} <span className="redFont">*</span></label>
                                        <label className="col-md-6 control-label" style={{ paddingLeft: '0.5rem', marginTop: "10px" }}>{I18n.t('tmeAdminMaster.unit')} <span className="redFont">*</span> </label>
                                    </div>
                                    <div className="col-md-12 input-group control-label">
                                        <input
                                            type="text"
                                            className="form-control col-md-7"
                                            value={this.state.list[index].target}
                                            maxLength="10"
                                            disabled={accessType  ===  "R/W"  ? false : true}
                                            onChange={(e) => this.onAddKPIChange(e, 'target', index)}
                                        />
                                        &nbsp;
                                         <Select
                                            placeholder= {I18n.t('others.select')}
                                            value={this.state.list[index].unitSelection}
                                            onChange={(e) => this.onAddKPIChange(e, 'unit', index)}
                                            options={this.state.unitListKPILIST}
                                            isDisabled={this.state.disable}
                                            isClearable={true}
                                            className="addkpi_modal_unit"
                                        />
                                    </div> </div> : ""}
                            

                            <label className="col-md-12 control-label">{I18n.t('tmeAdminMaster.additionalInformation')}</label>
                            <div className="col-md-12">
                                <textarea className="form-control"
                                    rows="3" onChange={(e) => this.onAddKPIChange(e, 'additionalInfo', index)}
                                    value={this.state.list[index].additionalInfo}
                                    maxLength="500"
                                />
                            </div>
                    {this.props.user.userMdl.roles.includes("KET-BRiT NMSC Admin") ?
                    <div className="col-md-12 checkbox">
                        <label>
                            <input
                                type="checkbox"
                                id="mandatory"
                                checked={this.state.list[index].mandatory}
                                onChange={(e) => this.onMandatory(e, 'mandatory', index)}
                            />{" "}
                                {I18n.t("tmeAdminMaster.mandatoryKpi")}
                        </label>
                    </div>:"" }
                        </form>
                    </div>
                </Tab>
            )
        })

        return (
            <Modal className={"addEditNmscModal addEditNmscModal-" + this.props.theme.name} isOpen="true" toggle={() => this.preventDefault()}>
                <ModalHeader toggle={() => this.props.popUpClose(this.props.modalFor)}>{this.props.modalFor==="Create"? I18n.t("tmeAdminMaster.createKPI"):I18n.t("tmeAdminMaster.editKPI")}</ModalHeader>
                <ModalBody>
                    <div className="col-md-12 row">
                        <div className="col-md-3" style={{ padding: "0.45rem" }}>
                            {dynamicKPITab.length > 0 ?
                                <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab} defaultActiveKey={1} className="createKPITabs">
                                    {dynamicKPITab}
                                </Tabs> : null}
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ padding: "0.2rem 2rem", float: "left", border: "none" }}>
                    <span style={{ color: "red", width: "100%" }}>* {I18n.t("footer.message")}</span>
                    <button className={"buttonCancel-" + this.props.theme.name} onClick={() => this.props.popUpClose(this.props.modalFor)}>{I18n.t("tmeAdminMaster.cancel")}</button>
                    {this.props.modalFor === 'Create' ?
                        <button className={"button-" + this.props.theme.name} onClick={this.saveKpi}>{I18n.t("tmeAdminMaster.save")}</button>
                        : <button className={"button-" + this.props.theme.name} onClick={this.saveKpi}>{I18n.t("tmeAdminMaster.update")}</button>}
                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        nmscLocalLangList: state.tabReducer.nmscLocalLangList,
        unitsList: state.tabReducer.unitsList,
        eaUnitsList: state.tabReducer.eaUnitsList
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(NmscModal)));