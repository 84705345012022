import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import QuestionContent from './questionContent';
import QuestionSummary from './questionSummary';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withTheme } from '../../themes/theming';
import './index.css';
import { toastr } from 'react-redux-toastr';
const $ = require('jquery')

class Questionnaire extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            questionId: '',
            chapterId: '',
            questionsIdList: '',
            isCollapsed: false,
            subQuestionsToBePublished:[],
        }
        this.handleSavedSubQuestions = this.handleSavedSubQuestions.bind(this);
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.savedSubQuestions && this.state.savedSubQuestions !== nextProps.savedSubQuestions){
            // this.setState({subQuestionsToBePublished:nextProps.savedSubQuestions});
        }

    }

    togglePane = () => {
        this.setState({ isCollapsed: !this.state.isCollapsed });
        $(".leftNav .leftPanelContent").toggle();

    }

    nmscImport = () => {
        toastr.success("import successful");
    }
    mainQSelectionId = (id, chapter, list) => {
        console.log('chapter:' + chapter);
        this.setState({ questionId: id, chapterId: chapter, questionsIdList: list });

    }

    handleSavedSubQuestions(subQuestion){
        if(this.state.subQuestionsToBePublished.includes(subQuestion)){
            let index = this.state.subQuestionsToBePublished.indexOf(subQuestion);
            let subQuestions =this.state.subQuestionsToBePublished;
            subQuestions.splice(index,1);
            this.setState({subQuestionsToBePublished:subQuestions});
        }else{
            let subQuestions =this.state.subQuestionsToBePublished;
            subQuestions.push(subQuestion);
            this.setState({subQuestionsToBePublished:subQuestions});
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.packageMapPrimaryKey !== this.props.packageMapPrimaryKey) {
            this.mainQSelectionId('', '', this.state.questionsIdList);
        }
    }



    render() {
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'QUESTIONNAIRE_CHAPTER')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'QUESTIONNAIRE_CHAPTER')[0].accessType : "";
        }
        var isNMSCAdminUser = false;
        // var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isNMSCAdminUser = true;
                    // isNotAdmin = false;
                    break;
                }
                // isNotAdmin = true;
            }
        }

        return (

            <div className="questionnaire">
                {accessType === "R/W" || accessType === "R" ?
                    <span>
                        {!this.state.isCollapsed ?
                            <div className="row">
                                <div className="leftPane leftNav">
                                    <div className="leftPanelContent">
                                        <QuestionSummary activeTabNmscLang= {this.props.activeTabNmscLang} toolboxId={this.props.packageMapPrimaryKey} mainQSelectionId={this.mainQSelectionId} isNMSCAdminUser={isNMSCAdminUser} type={this.props.type}/>
                                    </div>
                                    <div style={{ float: 'right' }} className="scrollbar">
                                        <div className={"thickRightBorder leftNavCollapseBtn-"+this.props.theme.name} onClick={() => this.togglePane()} />
                                    </div>
                                </div>
                                <div className="rightPane">
                                  <QuestionContent handleSavedSubQuestions={this.handleSavedSubQuestions} savedSubQuestions={this.state.subQuestionsToBePublished} activeTabNmscLang= {this.props.activeTabNmscLang} activeNMSCTabLink= {this.props.activeNMSCTabLink} toolboxId={this.props.packageMapPrimaryKey} questionId={this.state.questionId} chapterId={this.state.chapterId} questionList={this.state.questionsIdList} isNMSCAdminUser={isNMSCAdminUser} type={this.props.type} />
                                </div>
                            </div>
                            :
                            <div className="row">
                                <div className="leftPaneCollapsed leftNav">
                                    <div className="leftPanelContent">
                                        <QuestionSummary activeTabNmscLang= {this.props.activeTabNmscLang} toolboxId={this.props.packageMapPrimaryKey} mainQSelectionId={this.mainQSelectionId} isNMSCAdminUser={isNMSCAdminUser} type={this.props.type}/>
                                    </div>
                                    <div style={{ float: 'right' }} className="scrollbar">
                                        <div className={"thickRightBorder leftNavCollapseBtn-"+this.props.theme.name} onClick={() => this.togglePane()} />
                                    </div>
                                </div>
                                <div className="rightPaneCollapsed">
                                    <QuestionContent handleSavedSubQuestions={this.handleSavedSubQuestions} savedSubQuestions={this.state.subQuestionsToBePublished} activeTabNmscLang= {this.props.activeTabNmscLang} activeNMSCTabLink= {this.props.activeNMSCTabLink} toolboxId={this.props.packageMapPrimaryKey} questionId={this.state.questionId} chapterId={this.state.chapterId} questionList={this.state.questionsIdList} isNMSCAdminUser={isNMSCAdminUser} type={this.props.type}/>
                                </div>
                            </div>

                        }
                    </span> : <div style={{ height: '50px' }} />}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        savedSubQuestions: state.questionSummaryReducer.savedSubQuestions,
        modalState: state.tabReducer.closeModal,
        user: state.loginReducer
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(Questionnaire)));