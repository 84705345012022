import React from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import routes from './routes'
import axios from 'axios';

const App = ({ history }) => {
  axios.defaults.baseURL = '/ket/v1';
  // axios.defaults.headers['Access-Control-Allow-Origin'] = "*";
  axios.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if (error.response === undefined || error.response.status === undefined) {
      console.log("******** Is Logout required?" + error.message);
      alert(error.message);
      // window.location="/";
    }
    if (error.response && error.response.status !== undefined && error.response.status === 403) {
      console.log("******** Is Logout required?" + error.message);
      alert(error.message);
      // window.location = "/";
    }
    return Promise.reject(error);
  });
  return (
    <ConnectedRouter history={history}>
      {routes}
    </ConnectedRouter>

  );
}

App.propTypes = {
  history: PropTypes.object.isRequired
}

export default (App)