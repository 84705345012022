import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Fa } from "mdbreact";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import {
    uploadMonthlyKpi, uploadUsedCars, uploadNetworks,uploadLexusNetworks
} from "../../../actions/bulkUploadAction";
import { withTheme } from "../../../themes/theming";
import $ from "jquery";
import "./../index.css";
import * as AppConst from '../../../AppConstant';
var I18n = require("react-redux-i18n").I18n;

class UploadKpiPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // uploadResponse: true,
        };
    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {
        console.log(nextProps.bulkUploadProps)
    }

    onClickUpload = (e) => {

        if (this.state.selectedFile !== undefined && this.state.selectedFile) {
            var ext = this.state.selectedFile.name.split(".").pop();

            if (ext && (ext.toUpperCase() === 'XLS' || ext.toUpperCase() === 'XLSX')) {
                const postData = new FormData();
                postData.append('file', this.state.selectedFile);

                let  fileCriteria={
                    sizePerPage: 10,
                    page: 1,
                    fileType: this.props.user.userMdl.activeProgram === AppConst.activeProgramUc ?
                     'USED_CARS_BULK_UPLOAD': this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ?
                     'NETWORK_BULK_UPLOAD' :this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ?
                     'LEXUS_NETWORK_BULK_UPLOAD': 'VALUE_CHAIN_KPI_UPLOAD',
                    username : this.props.user.userMdl.username,
                }
                if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
                    this.props.dispatch(uploadUsedCars(fileCriteria, postData, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang, this.props.user.userMdl.username,this.props.user.userMdl.activeProgramID,this.props.user.userMdl.ceaDefaultYears.value, AppConst.activeProgramUc));
                }else if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
                    this.props.dispatch(uploadNetworks(fileCriteria, postData, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang, this.props.user.userMdl.username,this.props.user.userMdl.activeProgramID,this.props.user.userMdl.ceaDefaultYears.value,AppConst.activeProgramNet));
                }else if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
                    this.props.dispatch(uploadLexusNetworks(fileCriteria, postData, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang, this.props.user.userMdl.username,this.props.user.userMdl.activeProgramID,this.props.user.userMdl.ceaDefaultYears.value,AppConst.activeProgramLN));
                }else{
                    this.props.dispatch(uploadMonthlyKpi(fileCriteria, postData, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang, this.props.user.userMdl.username));
                }             

            } else {
                toastr.error(I18n.t('toastr.fileSelectionErr'));
            }
        } else {
            toastr.error(I18n.t('toastr.fileSelectionErr'));

        }
    }

    onFileChange = event => {
        console.log(event)
        if (event.target.files[0]) {
            this.setState({ selectedFile: event.target.files[0] })
            this.setState({ uploadLabel: event.target.files[0].name })

            $("#bulkUploadBx").attr("placeholder", event.target.files[0].name);

        }
    }

    render() {
        return (
            <Container
                fluid
                className="ml-0 mb-2 pb-0"
                style={{ border: "1px solid grey", borderRadius: "10px" }}>
                <Row>
                    <span className="sectionLabel">File Upload & Template Download</span>
                </Row>
                <Row className="mt-2 mb-2">
                    <Col md="4" className="pr-0">
                        <input
                            id="bulkUploadBx"
                            ref="bulkUploadBx"
                            type="text"
                            className="form-control"
                            placeholder="Select File to Upload"
                            readOnly
                        />
                    </Col>
                    <Col md="1" className="ml-0 pl-0">
                        <label className="input-group-btn">
                            <div
                                className="btn btn-sm mt-0"
                                style={{ width: "75%", backgroundColor: "#6c757d" }}
                            >
                                <span
                                    style={{ display: "inline-flex", marginLeft: "-10px" }}
                                >
                                    <Fa icon="folder-open" />
                                                &nbsp;&nbsp;
                                                {I18n.t('uploadBulk.browse')}
                                    &hellip;
                                            </span>
                                <input
                                    type="file"
                                    onChange={this.onFileChange}
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    style={{
                                        display: "none"
                                    }}
                                />
                            </div>
                        </label>
                    </Col>
                    <Col md="7">
                        <button
                            className={"button-" + this.props.theme.name}
                            disabled={this.state.uploadResponse}
                            style={{ float: "right" }}
                            onClick={() => this.onClickUpload()}
                        >
                            {I18n.t('library.upload')}
                                    </button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(UploadKpiPanel))
);