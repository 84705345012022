import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { toastr } from "react-redux-toastr";
var I18n = require("react-redux-i18n").I18n;

export function fetchUserSearchFields(searchDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    //   console.log("IN fetchUserSearchFields")
    //   console.log(searchDto)
    axios
      .post("/userManagement/userSearchFieldDetails", searchDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "USER_SEARCH_FIELDS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function fetchUserDetails(userDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(userDto)
    axios
      .post("/userManagement/userManagementDetails", userDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "USER_DETAILS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function filtersUserManagement(selectedfilters) {

  return function (dispatch) {
    dispatch({
      type: 'USER_SELECTED_FILTERS',
      payload: selectedfilters
    });

  };
}


export function searchEmptyUserList() {

  return function (dispatch) {
    dispatch({
      type: "USER_DETAILS",
      payload: null
    });
    dispatch({
      type: "USER_SELECTED_FILTERS",
      payload: null
    });
  };
}


//To fetch user details
export function fetchUser(networkUserId) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get("/userManagement/" + networkUserId, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "USER_INFO",
          payload: response.data
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

//To save and upadte user
export function saveUser(userDto, searchDto, userSearchDto, isSearchClicked, this_) {


  userSearchDto['adminUserType'] = searchDto.adminUserType;

  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post("/userManagement/save", userDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));

        console.log(response.data.desc)
        if (response.data.desc === 'duplicate_tarsuser' || response.data.desc === 'user_refer_on'
          || response.data.desc === 'user_define_in_tool') {
          toastr.error(I18n.t("userManagement." + response.data.desc));

        } else {
          this_.props.toggle(true);
          toastr.success(I18n.t("userManagement." + response.data.desc));
        }

        if (isSearchClicked === true) {
          dispatch(fetchUserDetails(searchDto));
        }
        dispatch(fetchUserSearchFields(userSearchDto));

      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchNmscLang(filter) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get("/userManagement/nmscLangList/" + filter, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)
        if (filter === 'nmsc') {

          dispatch({
            type: "NMSC_LIST",
            payload: response.data
          });

        } else {
          dispatch({
            type: "COUNTRY_LIST",
            payload: response.data
          });
        }
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchLinkFilters(linkDto, searchFor) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/userManagement/linkFilters/" + searchFor, linkDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        var type =
          searchFor === "nmscCode"
            ? "NMSC_DATA"
            : searchFor === "l1"
              ? "FRANCHISE_DATA"
              : searchFor === "l2"
                ? "COUNTRY_DATA"
                : searchFor === "l3"
                  ? "L3_DATA"
                  : searchFor === "l4"
                    ? "L4_DATA"
                    : searchFor === "l5"
                      ? "L5_DATA"
                      : "L6_DATA"

        //dispatch(setBlockingState(false));
        dispatch({
          type: type,
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchLinkFiltersSerach(linkDto, searchFor) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/userManagement/linkFilters/" + searchFor, linkDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        var type =
          searchFor === "nmscCode"
            ? "NMSC_DATA_SEARCH"
            : searchFor === "l1"
              ? "FRANCHISE_DATA_SEARCH"
              : searchFor === "l2"
                ? "COUNTRY_DATA_SEARCH"
                : searchFor === "l3"
                  ? "L3_DATA_SEARCH"
                  : searchFor === "l4"
                    ? "L4_DATA_SEARCH"
                    : searchFor === "l5"
                      ? "L5_DATA_SEARCH"
                      : "L6_DATA_SEARCH"

        //dispatch(setBlockingState(false));
        dispatch({
          type: type,
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function fetchLinkDealerList(linkDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(linkDto)
    axios
      .post("/userManagement/linkDealerList", linkDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "DEALER_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}
export function fetchLinkCityList(linkDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(linkDto)
    axios
      .post("/userManagement/linkCityList", linkDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "CITY_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function fetchLinkDealerListSearch(linkDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(linkDto)
    axios
      .post("/userManagement/linkDealerList", linkDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "DEALER_LIST_SEARCH",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function assignDealers(linkDto, listSearchDto) {

  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post("/userManagement/assignDealersToUser", linkDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        //   console.log(response.data)

        console.log(response)

        if (response.data.noDealerFound) {

          toastr.error((I18n.t("ServerMessage.no_dealer_available")))

        } else if (response.data.alreadyDealerAssigned) {

          if (response.data.alreadyAssignedDealerCount === 1) {
            toastr.error(response.data.alreadyAssignedDealerCount + (I18n.t("ServerMessage.dealers_already_mapped")))
          }
          else {
            toastr.error(response.data.alreadyAssignedDealerCount + (I18n.t("ServerMessage.dealers_already_mapped")))
          }

        }
        else {
          if (response.data.newAssignedDealerCount === 1) {
            toastr.success(response.data.newAssignedDealerCount + (I18n.t("ServerMessage.dealer_added")) + (I18n.t("ServerMessage.total_dealers_mapped_now")) + response.data.totalAssignedDealerCount)
          } else {
            toastr.success(response.data.newAssignedDealerCount + (I18n.t("ServerMessage.dealers_added")) + (I18n.t("ServerMessage.total_dealers_mapped_now")) + response.data.totalAssignedDealerCount)
          }

          if (listSearchDto !== undefined) {
            dispatch(fetchDealerListDetails(listSearchDto))
          }
        }

        dispatch({
          type: "ASSIGN_DEALER_REFRESH",
          payload: true
        })

      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };

}

export function assignDealersFilterData(linkDto) {
  return function (dispatch) {
    dispatch({
      type: "SEARCH_FILTER_DATA",
      payload: linkDto
    })
  };
}

export function fetchDealerListDetails(searchDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(searchDto)
    axios
      .post("/userManagement/dealerDetailsList", searchDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        //   console.log(response.data)

        dispatch({
          type: "DEALER_OBJ_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}
export function checkForOngoingActivities(queryDto, removePopUP) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/userManagement/checkOngoingActivities", queryDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        return new Promise((resolve, reject) => {
          dispatch({
            type: "ONGOING_ACTIVITIES",
            payload: response.data
          });
          resolve(response.data.ongoingActivities)
        }).then((result) => {
          removePopUP(result)
        })

      }).catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}
export function removeMappings(queryDto, searchDto) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post("/userManagement/removeMappings", queryDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        if (response.data.desc === "bad_request")
          toastr.error(I18n.t("UserManagement.error_message"));
        else {
          toastr.success(I18n.t("ServerMessage." + response.data.desc));
        }
        dispatch(fetchDealerListDetails(searchDto))


      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function fecthDealerlanguage(queryDto) {
  return function (dispatch) {
    console.log(queryDto)
    axios
      .post("/userManagement/fecthDealerlanguage", queryDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "DEALER_LANG",
          payload: response.data.languageCode
        });
      })
      .catch(function (error) {
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function fetchUserEmails(searchDto) {
  return function (dispatch) {
    axios
      .post("/userManagement/email", searchDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "USER_EMAILS_LIST",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}

export function getuserDetailsData(searchDto) {
  return function (dispatch) {
    axios
      .post("/monthlyFileUpload/getuserDetailsData", searchDto, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "USER_DETAILS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("UserManagement.error_message"));
      });
  };
}