import React from "react";
import { connect } from "react-redux";
import { withTheme, themes } from "../../../themes/theming";
import { NavbarNav, NavItem, Fa } from "mdbreact";
import { withRouter, Link } from "react-router-dom";
import "./index.css";
var I18n = require("react-redux-i18n").I18n;
const $ = require("jquery");
window.jQuery = $;

class ReachItMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      theme: themes.default,
      selectedTabName: "",
    };
    this.onClick = this.onClick.bind(this);
    this.addCss = this.addCss.bind(this);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.theme.name !== this.props.theme.name) {
      this.addCss({ target: { innerText: this.state.selectedTabName } });
    }
  }
  onClick() {
    this.setState({
      collapse: !this.state.collapse,
    });
  }

  addCss(e) {
    let selectedTabName = e.target.innerText;
    this.setState({ selectedTabName: selectedTabName });
    $("a").filter(function () {
      if ($(this).text() === selectedTabName) {
        $(this).addClass("selectedMenu");
      } else {
        $(this).removeClass("selectedMenu");
      }
      return null;
    });
  }

  render() {
    const navLinkClass = this.props.theme.name + "-nav-link nav-link";
    return (
      <NavbarNav left>
        {" "}
        <NavItem active>
          <Link
            className={navLinkClass}
            to="/reachIthome"
            onClick={this.addCss.bind(this)}
          >
            <Fa icon="home" fixed className="mr-1" />
            {I18n.t("reachItDashboard.topNavReachIT")}
          </Link>
        </NavItem>
      </NavbarNav>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.router,
  };
};

export default withRouter(
  connect(mapStateToProps)(withTheme(ReachItMenu))
);
