export default function activityReducer(state = {
    userMdl: {}, blocking: false, closeModal: false, refreshRequired: false, actionPlanRefreshRequired: false,
    activityEvalutionsList: [],
    evaluationFilter :{},kaizen:false
}, action) {
    switch (action.type) {
        case "NMSC_DATA":
            return Object.assign({}, state, {
                nmscList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_CEA_EVALUTIONS_LIST": {
            return Object.assign({}, state, {
                activityEvalutionsList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        }
        case "BRAND_DATA":
            return Object.assign({}, state, {
                brandList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "COUNTRY_DATA":
            return Object.assign({}, state, {
                countryList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "REGION_DATA":
            return Object.assign({}, state, {
                regionList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ASSIGNEE_DATA":
            return Object.assign({}, state, {
                assigneeData: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "DEALER_CODE":
            return Object.assign({}, state, {
                dealerList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "DEALER_CODE_LIST":
            return Object.assign({}, state, {
                dealerCodeData: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_STATUS":
            return Object.assign({}, state, {
                activityStatus: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_TOOLBOX":
            return Object.assign({}, state, {
                activityToolBox: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_LIST":
            return Object.assign({}, state, {
                activityList: action.payload,
                closeModal: false,
                refreshRequired: false
            });     
        case "ACTIVITY_CEA_LIST":
            return Object.assign({}, state, {
                activityCEAList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ASSIGNEE_CODE":
            return Object.assign({}, state, {
                assigneeList: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_KPI_LIST":
            return Object.assign({}, state, {
                activityKpis: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_DELETED":
            return Object.assign({}, state, {
                closeModal: false,
                refreshRequired: true
            });
        case "ACTIVITY_KPI_SAVED":
            return Object.assign({}, state, {
                closeModal: false,
                refreshRequired: true,
                actionPlanRefreshRequired: false
            });
        case "REFRESH_DONE":
            return Object.assign({}, state, {
                closeModal: false,
                refreshRequired: false
            });
        case "ACTIVITY_DETAIL":
            return Object.assign({}, state, {
                selectedRow: action.payload,
            });
        case "ACTION_PLAN_LIST":
            return Object.assign({}, state, {
                actionPlanList: action.payload,
                actionPlanRefreshRequired: true
            });
        case "ACTION_PLAN_REFRESH_DONE":
            return Object.assign({}, state, {
                actionPlanRefreshRequired: false
            });
        case "FOLLOWUP_LIST":
            return Object.assign({}, state, {
                followUpList: action.payload,
            });
        case "ACTION_PLAN_STATUS":
            return Object.assign({}, state, {
                actionPlanStatus: action.payload,
            });
        case "RETAILER_ACTUAL":
            return Object.assign({}, state, {
                retailerActual: action.payload,
                closeModal: false,
                refreshRequired: false
            });
        case "SUB_QUES_INFO":
            return Object.assign({}, state, {
                subQuesInfo: action.payload,

            });
        case "QUES_INFO":
                return Object.assign({}, state, {
                    quesInfoData: action.payload,
    
                });
            case "EVAL_NMSC_CODE_DATA":
                return Object.assign({}, state, {
                    evaluationFilter: { nmscCodeList: action.payload }
                }); 
            case "EVAL_BUSINESS_FUNCTION_DATA":
                return Object.assign({}, state, {
                    evaluationFilter: { businessFunctionList: action.payload }
                });
            case "EVAL_ACTIVITY_STATUS":
                    return Object.assign({}, state, {
                        evaluationFilter: { activityStatusList: action.payload }
                    });  
            case "CLOSE_EVALUATION":
                    return Object.assign({}, state, {
                    closeEvaluation: action.payload 
                    });     
            case "LAUNCH_KAIZEN":
                    return Object.assign({},state,{
                            kaizen:action.payload
                });
            case "REQUEST_REAUDIT":
                    return Object.assign({},state,{
                                requestReAudit:action.payload
                });
        

        default:
            return state;
    }
};