// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileImage{ 
    /* background-image: url(../../assets/images/mobile.png); */
    /* height: 35px;
    width: 10px;
    float: right;
    background-repeat: no-repeat;
    background-position: center;        */
    padding: 0 150px 130px;
    height: 720px;
    color: #fff;
    /* background: url(../../assets/images/mobile.png) no-repeat top; */
    background-position-y: 5px;
    width: 100%;
    max-width: 1680px;
    min-width: 978px;
    margin: 0 auto;
    background-size: contain;
}
.DownloadView{
    position:inherit !important;
}
.fa.fa-android{
    padding-left: 4px;
}
.fa.fa-apple{
    padding-left: 4px;
}
.kaizenImage{
max-width: 70% !important;
max-height: 70% !important}

.Downloadbtn .btn{
    padding: 0.54rem 1.14rem !important;
}

@media only screen and (min-width: 768px) {
    .mobileSize{
        margin-top: 3%;
        width: 100%; 
        text-align: left
    }
    
}

@media only screen and (max-width: 768px) and (min-width: 0px) {
    .mobileSize{
        margin-top: 3%;
        width: 100%; 
        font-size: 30px;
        text-align: left
    }
    
}
`, "",{"version":3,"sources":["webpack://./src/components/download/index.css"],"names":[],"mappings":"AAAA;IACI,2DAA2D;IAC3D;;;;yCAIqC;IACrC,sBAAsB;IACtB,aAAa;IACb,WAAW;IACX,mEAAmE;IACnE,0BAA0B;IAC1B,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,cAAc;IACd,wBAAwB;AAC5B;AACA;IACI,2BAA2B;AAC/B;AACA;IACI,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;AACA;AACA,yBAAyB;AACzB,0BAA0B;;AAE1B;IACI,mCAAmC;AACvC;;AAEA;IACI;QACI,cAAc;QACd,WAAW;QACX;IACJ;;AAEJ;;AAEA;IACI;QACI,cAAc;QACd,WAAW;QACX,eAAe;QACf;IACJ;;AAEJ","sourcesContent":[".mobileImage{ \n    /* background-image: url(../../assets/images/mobile.png); */\n    /* height: 35px;\n    width: 10px;\n    float: right;\n    background-repeat: no-repeat;\n    background-position: center;        */\n    padding: 0 150px 130px;\n    height: 720px;\n    color: #fff;\n    /* background: url(../../assets/images/mobile.png) no-repeat top; */\n    background-position-y: 5px;\n    width: 100%;\n    max-width: 1680px;\n    min-width: 978px;\n    margin: 0 auto;\n    background-size: contain;\n}\n.DownloadView{\n    position:inherit !important;\n}\n.fa.fa-android{\n    padding-left: 4px;\n}\n.fa.fa-apple{\n    padding-left: 4px;\n}\n.kaizenImage{\nmax-width: 70% !important;\nmax-height: 70% !important}\n\n.Downloadbtn .btn{\n    padding: 0.54rem 1.14rem !important;\n}\n\n@media only screen and (min-width: 768px) {\n    .mobileSize{\n        margin-top: 3%;\n        width: 100%; \n        text-align: left\n    }\n    \n}\n\n@media only screen and (max-width: 768px) and (min-width: 0px) {\n    .mobileSize{\n        margin-top: 3%;\n        width: 100%; \n        font-size: 30px;\n        text-align: left\n    }\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
