// import { Modal, ModalBody, ModalHeader, ModalFooter } from "mdbreact";
import BootstrapTable from 'react-bootstrap-table-next';
import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import { connect } from "react-redux";
import { fetchReadyForCertificationsDetails, submitCertification } from "../../../actions/certificationAction";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import { toastr } from "react-redux-toastr";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
var I18n = require("react-redux-i18n").I18n;

// import { Fa } from 'mdbreact';
class ToolBoxDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            readyForCertification: [],
            nonSelectable: [],
            selected: [],
            selectedActivityId: []
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchReadyForCertificationsDetails(this.getQueryDTO()));
        var roles = this.props.user.userMdl.roles;
        var isAdminUser = false;
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET TME ADMIN') !== -1) {
                isAdminUser = true;
                break;
            }
        }
        this.setState({ isAdminUser });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.readyForCertification && nextProps.readyForCertification.length > 0) {
            this.setState({ readyForCertification: nextProps.readyForCertification });
            let nonSelectable = [];
            let allActivities = [];
            nextProps.readyForCertification.map((data) => {
                if (data.certificationStatus === "Certified" || data.certificationStatus === "Submitted") {
                    var index = nonSelectable.indexOf(data.activityId);
                    if (index <= -1) {
                        nonSelectable.push(data.activityId);
                    }
                }
                allActivities.push(data.activityId);
                return null;
            })
            this.setState({ nonSelectable });
            this.setState({ allActivities });
        }
    }

    getQueryDTO = () => {
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let userId = this.props.user.userMdl.username;
        return {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: this.props.programFilter,
            nmscCode: this.props.nmscFilter,
            brandList: this.props.brandFilter,
            countryList: this.props.countryFilter,
            userId: userId,
            dealerList: [this.props.selectedDealerId],
            statusList: this.props.programFilter,
            numberOfCompletedToolBox: 0
        };
    }

    rowData() {

    }

    dispatchActivity = (row) => {
        var searchCriteria = {
            langCode: this.props.user.userMdl.lang,
            loggedInUser: this.props.user.userMdl.username,
            nmscCode: this.props.user.userMdl.nmsc,
            type: "NMSC",
        }
        this.props.dispatch(
            push({
                pathname: "/actionPlan",
                query: { selectedActivity: JSON.stringify(row), searchCriteria: searchCriteria }
            })
        );
    }
    submitCertification = () => {
        var toolBoxList = [];
        var isToolBoxExists = false;
        var queryDto = this.state.selected;
        for (var i = 0; i < queryDto.length; i++) {
            var index = toolBoxList.indexOf(queryDto[i].toolboxName);
            if (index > -1) {
                isToolBoxExists = true;
                break;
            }
            else {
                toolBoxList.push(queryDto[i].toolboxName);
            }
        }

        if (isToolBoxExists) {
            toastr.error(I18n.t('standardMessages.sameToolBox'));
        }
        else {
            this.props.dispatch(submitCertification(this.state.selected, this.props.selectedDealerId, this.props.user.userMdl.username, this.getTableQueryDTO()));
            this.props.toggelPopUp();
        }
    }

    toolBoxFormatter = (cell, row) => {
        return (
            <div className="queslink" style={{ textDecoration: 'underline', textAlign: "center", display: "initial" }} >
                <a onClick={() => this.dispatchActivity(row)}> {row.toolboxName}</a>
            </div>
        );
    }

    getTableQueryDTO = () => {
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let userId = this.props.user.userMdl.username;
        //let activeProgram = this.props.user.userMdl.activeProgram;

        return {
            userNmscCode: this.props.certificationReducer.CertiFlag ? null : nmscCode,
            userLangCode: this.props.certificationReducer.CertiFlag ? null : langCode,
            nmscCode: this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            userId: userId,
            numberOfCompletedToolBox: null,
            dealerId: this.props.certiFilters.dealerList !== undefined && this.props.certiFilters.dealerList[0] ? this.props.certiFilters.dealerList[0] : this.props.selectedDealerId,
            programList: this.props.certiFilters.programList,
            dealerList: this.props.certiFilters.dealerList,
            statusList: this.props.certiFilters.statusList,
            nmscCodeList: this.props.certiFilters.nmscCodeList,
            brandList: this.props.certiFilters.brandList,
            countryList: this.props.certiFilters.countryList,
            noOfCompletedToolBox: this.props.certiFilters.noOfCompletedToolBox
        };
    }

    preventDefault() { }
    render() {
        const columns = [{
            dataField: 'toolboxName',
            text: I18n.t('tmeAdminMaster.ToolboxName'),
            title: true,
            formatter: this.toolBoxFormatter,
            style: { width: "16.1%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "0" },
            headerStyle: { width: "15.5%", textAlign: "left" }
        }, {
            dataField: 'reference',
            text: I18n.t('Table.reference'),
            style: { width: "8.9%",textAlign: "left",paddingLeft: "38px" },
            headerStyle: {width: "9%",textAlign: "center",paddingRight: "35px" }
        }, {
            dataField: 'program',
            text: I18n.t('tmeAdminMaster.programName'),
            style: {width: "12%",textAlign: "left",paddingLeft: "45px" },
            headerStyle: {width: "10%",textAlign: "center",paddingRight:"19px"}
        }, 
        {
            dataField:'approvedBy',
            text:I18n.t('Table.approvedBy'),
            style:{width:"16.1%",textAlign:"left",paddingLeft:"35px"},
            headerStyle:{width: "13%",textAlign: "left",paddingLeft: "16px"}
           },
         {
            dataField: 'firstVisitDate',
            text: I18n.t('Table.startDate'),
            style: { width: "13%", textAlign: "center",paddingRight:"40px" },
            headerStyle: { width: "12%",textAlign: "center",paddingRight: "52px" }
        }, {
            dataField: 'completionDate',
            text: I18n.t('Table.completionDate'),
            style: { width: "13%", textAlign: "center",paddingRight:"46px" },
            headerStyle: {width: "12%",textAlign: "left",paddingRight: "3px"}
        }, {
            dataField: 'certificationDate',
            text: I18n.t('Table.certificationDate'),
            style: { width: "15%", textAlign: "center",paddingRight:"81px" },
            headerStyle: {width: "12%",textAlign: "left",paddingRight: "51px"}
        }, {
            dataField: 'certificationStatus',
            text: I18n.t('Table.certification'),
            style: {width: "18%",textAlign: "center",paddingRight: "61px" },
            headerStyle: { width: "12%",textAlign: "right",overflow:"hidden" }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                var exist = this.state.isAdminUser ? this.state.nonSelectable.some(item => item === row.activityId) : true;
                if (!exist && (e.target.type === "checkbox" || e.target.cellIndex === 0)) {
                    e.target.checked = true;
                    var index = this.state.selectedActivityId.indexOf(row.activityId);
                    if (index > -1) {
                        this.state.selectedActivityId.splice(index, 1);
                        this.state.selected.splice(index, 1)
                    } else {
                        this.state.selectedActivityId.push(row.activityId);
                        this.state.selected.push(row);
                    }

                    this.setState({ selected: this.state.selected });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            selected: this.state.selectedActivityId,
            nonSelectable: this.state.isAdminUser ? this.state.nonSelectable : this.state.allActivities,
            onSelectAll: (isSelect, rows, e) => {
                let selectedActivityId = [];
                let selected = [];
                rows.forEach((row) => {
                    selectedActivityId.push(row.activityId);
                    selected.push(row);
                });
                if (isSelect && selectedActivityId.length > 0) {
                    this.setState({ selectedActivityId: selectedActivityId, selected: selected });
                    return selectedActivityId;
                }
                else {
                    this.setState({ selectedActivityId: [], selected: [] });
                }
            }
        };


        return (
            <div className="certificationDetailsMain">
                <Modal centered
                    className={"certificationDetailsModal certificationDetailsModal-" + this.props.theme.name}
                    isOpen={true} style={{ maxWidth: '50%!important' }}
                    toggle={() => this.preventDefault()} >
                    <ModalHeader toggle={() => this.props.toggelPopUp()}>
                        {I18n.t('dealerCertification.toolboxDetails')}
                    </ModalHeader>
                    <ModalBody>
                        <BootstrapTable
                            keyField='activityId'
                            data={this.state.readyForCertification}
                            columns={columns}
                            hover
                            condensed
                            rowEvents={rowEvents}
                            noDataIndication={I18n.t('evaluation.noRecords')}
                            selectRow={selectRow}
                        />

                    </ModalBody>
                    <ModalFooter>
                        {
                        this.state.selected.length >= 2 ?
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={this.submitCertification}
                            >
                                {I18n.t('evaluation.submit')}
                            </button> : <button
                                className={"button-" + this.props.theme.name}
                                disabled
                            >
                                {I18n.t('evaluation.submit')}
                            </button>
                        }
                    </ModalFooter>
                </Modal >
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        readyForCertification: state.certificationReducer.readyForCertification,
        user: state.loginReducer,
        filters: state.dashboardReducer.filters,
        certificationReducer: state.certificationReducer,
        certiFilters: state.certificationReducer.certiFilters,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ToolBoxDetails)));
