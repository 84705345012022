import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow} from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
var I18n = require("react-redux-i18n").I18n;
class RolloutResultsTopNmsc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            certificateData: []
        };
    }
    componentWillMount() {
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            userId: this.props.userMdl.username,
            activeBusinessAreaId : this.props.userMdl.activeBusinessAreaID,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("rollOutTopNmsc", "SET_ROLLOUT_TOPNMSC", queryDto));
        this.props.dispatch(fetchScrollableWidgetData("rollOutTopNmscCertificate", "SET_ROLLOUT_TOPNMSC_CERTIFICATE", queryDto));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.rollOutTopNmsc && nextProps.rollOutTopNmsc.length !== this.state.data.length) {
            this.setState({ data: nextProps.rollOutTopNmsc, isLoading: false });
        }
        if (nextProps.rollOutTopNmscCerti && nextProps.rollOutTopNmscCerti.length !== this.state.certificateData.length) {
            this.setState({ certificateData: nextProps.rollOutTopNmscCerti, isLoading: false });
        }
    }
    render() {
        return (
            <div style={{ width: "100%",overflow:"hidden" }}>
                <div className={"widget-header left-align-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.rolloutTopNmsc')}>
                    {I18n.t('dashboard.rolloutTopNmsc')}
                    {/* <Fa icon="filter" className="widget-filter-icon"/> */}
                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="rollout-widget-content" style={{ float: "left",width: "50%" }}>
                        <MDBRow className="mt-2 ml-1 mr-1 widget-table-header">
                            <MDBCol md="4" lg="4">{I18n.t('Table.nmsc')}</MDBCol>
                            <MDBCol md="8" lg="8" className="text-center">{I18n.t('dashboard.activeRetailer')}</MDBCol>
                            {/*   <MDBCol md="4" lg="2">{I18n.t('Table.nmsc')}</MDBCol>
                            <MDBCol md="4" lg="4" className="text-center">{I18n.t('dashboard.certificationRate')}</MDBCol> */}
                        </MDBRow>
                        {this.state.data.length > 0 ?
                            <div>

                                {this.state.data.map((rollOut, index) => {
                                    return (
                                        <MDBRow key={index} className="ml-1 mr-1 report-bottom-border" style={{ height: "25px", marginTop: "3px" }}>
                                            <MDBCol md="4" lg="4">{rollOut.nmscCode}</MDBCol>
                                            <MDBCol md="8" lg="8" className="text-center" >
                                                {rollOut.retailerPercent + '%'}
                                            </MDBCol>
                                            {/*      <MDBCol md="4" lg="2">{rollOut.nmscCode}</MDBCol>
                                            <MDBCol md="4" lg="4" className="text-center" >
                                                -
                                                 {rollOut.certificationRatePercent + "%"} 
                                            </MDBCol>  */}
                                        </MDBRow>);
                                })}
                            </div>
                            : <div></div>}
                    </div>
                    <div className="rollout-widget-content" style={{ float: "right",width: "50%" }}>
                        <MDBRow className="mt-2 ml-1 mr-1 widget-table-header">
                            <MDBCol md="4" lg="4">{I18n.t('Table.nmsc')}</MDBCol>
                            <MDBCol md="8" lg="8" className="text-center">{I18n.t('dashboard.certificationRate')}</MDBCol>
                        </MDBRow>
                        {this.state.certificateData.length > 0 ?
                            <div>
                                {console.log("Check2")}
                                {console.log(this.state.certificateData)}
                                {this.state.certificateData.map((rollOut, index) => {
                                    return (
                                        <MDBRow key={index} className="ml-1 mr-1 report-bottom-border" style={{ height: "25px", marginTop: "3px" }}>
                                            <MDBCol md="4" lg="4">{rollOut.nmscCode}</MDBCol>
                                            <MDBCol md="8" lg="8" className="text-center" >
                                                {rollOut.retailerPercent + '%'}
                                            </MDBCol>
                                        </MDBRow>);
                                })}
                            </div>
                            : <div></div>}
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        rollOutTopNmsc: state.dashboardReducer.rollOutTopNmsc,
        rollOutTopNmscCerti:state.dashboardReducer.rollOutTopNmscCerti
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(RolloutResultsTopNmsc)));