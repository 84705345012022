export default function dashboardReducer(state = {
    availableToolboxes: [], activeUsers: {}, activeRetailers: {}, avgDaysPerToolbox: {},
    overdueActivities: {}, dueActivities: {}, activities: {}, activityStatus: [], filters: {},
    recentUpdates: [], toolboxPercent: [], rollOutTopNmsc: [], availableKPIs: {}, businessResults: []
    , Tsmfilters: {}, Bnpfilters: {}, Usedcarfilters: {}, tmeSurveysfilters: {}, overdueActivitiesReport: [], actionItem: {}, reCertificationRate: {},
    certificationRate: {},
    TsmActivityDto: {
        nmscCode: null,
        loggedInUser: null,
        brand: null,
        country: null,
        region: null
    },
    UsedcarActivityDto: {
        nmscCode: null,
        loggedInUser: null,
        brand: null,
        country: null,
        region: null
    },
    BnpActivityDto: {
        nmscCode: null,
        loggedInUser: null,
        brand: null,
        country: null,
        region: null
    },
    tmeSurveysActivityDto: {
        nmscCode: null,
        loggedInUser: null,
        brand: null,
        country: null,
        region: null
    },

}, action) {
    switch (action.type) {
        case "SET_TOOLBOX_FILTER":
            return Object.assign({}, state, {
                availableToolboxes: action.payload
            });
        case "WIDGET_AVG_DAYS_PER_TOOLBOX":
            return Object.assign({}, state, {
                avgDaysPerToolbox: action.payload
            });
        case "WIDGET_OVERDUE_ACTIVITIES":
            return Object.assign({}, state, {
                overdueActivities: action.payload
            });
        case "WIDGET_DUE_ACTIVITIES":
            return Object.assign({}, state, {
                dueActivities: action.payload
            });
        case "SET_ACTIVITY_STATUS":
            return Object.assign({}, state, {
                activityStatus: action.payload
            });
        case "WIDGET_ACTIVITIES":
            return Object.assign({}, state, {
                activities: action.payload
            });
        case "ACTIVE_USERS":
            return Object.assign({}, state, {
                activeUsers: action.payload
            });
        case "ACTIVE_RETAILERS":
            return Object.assign({}, state, {
                activeRetailers: action.payload
            });
        case "SET_FILTERS":
            return Object.assign({}, state, {
                filters: action.payload
            });
        case "SET_RECENT_UPDATES":
            return Object.assign({}, state, {
                recentUpdates: action.payload
            });
        case "SET_TOOLBOX_PERCENTAGE":
            return Object.assign({}, state, {
                toolboxPercent: action.payload
            });
        case "SET_ROLLOUT_TOPNMSC":
            return Object.assign({}, state, {
                rollOutTopNmsc: action.payload
            });
        case "SET_AVAILABLE_KPIS":
            return Object.assign({}, state, {
                availableKPIs: action.payload
            });
        case "SET_BUSINESS_RESULTS":
            return Object.assign({}, state, {
                businessResults: action.payload
            });
        case "TSM_FILTER":
            return Object.assign({}, state, {
                Tsmfilters: action.payload
            });
        case "TSM_BNP_FILTER":
            return Object.assign({}, state, {
                Bnpfilters: action.payload
            });
        case "TSM_USED_CAR_FILTER":
            return Object.assign({}, state, {
                Usedcarfilters: action.payload
            });
        case "TSM_SURVEYS_FILTER":
            return Object.assign({}, state, {
                tmeSurveysfilters: action.payload
            });
        case "TSM_ACTIVITY_DTO":
            return Object.assign({}, state, {
                TsmActivityDto: action.payload
            });
        case "TSM_BNP_ACTIVITY_DTO":
            return Object.assign({}, state, {
                BnpActivityDto: action.payload
            });
        case "TSM_USED_CAR_ACTIVITY_DTO":
            return Object.assign({}, state, {
                UsedcarActivityDto: action.payload
            });
        case "TTSM_SURVEYS_ACTIVITY_DTO":
            return Object.assign({}, state, {
                tmeSurveysActivityDto: action.payload
            });
        case "WIDGET_OVERDUE_ACTIVITIES_REPORT":
            return Object.assign({}, state, {
                overdueActivitiesReport: action.payload
            });
        case "WIDGET_KAIZEN_POINTS_REPORT":
            return Object.assign({}, state, {
                dealerKaizenPoints: action.payload
            });
        case "WIDGET_ACTION_ITEM":
            return Object.assign({}, state, {
                actionItem: action.payload
            });

        case "NOTICE_BOARD":
            return Object.assign({}, state, {
                noticeBoard: action.payload
            });
        case "ACTION_ITEM_REPORT":
            return Object.assign({}, state, {
                actionItemReport: action.payload
            });

        case "BEST_PRACTICE":
            return Object.assign({}, state, {
                bestPractice: action.payload
            });
        case "SET_DEALER_PREFERENCES":
            return Object.assign({}, state, {
                dealerPreferences: action.payload
            });
        case "RE_CERTIFICATION_RATE":
            return Object.assign({}, state, {
                reCertificationRate: action.payload
            });
        case "CERTIFICATION_RATE":
            return Object.assign({}, state, {
                certificationRate: action.payload
            });
        case "SET_ROLLOUT_TOPNMSC_CERTIFICATE":
            return Object.assign({}, state, {
                rollOutTopNmscCerti: action.payload
            });
        case "MONTHLY_FILE_DATA":
            return Object.assign({}, state, {
                monthlyFileData: action.payload
            });
            
        default:
            return state;
    }
};