import React from "react";
import { connect } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Col, Fa } from "mdbreact";
import { withTheme } from '../../../themes/theming';
import { withRouter } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import '../index.css';
import { fetchDealers, searchEmptyDealerList, deleteDealer, fecthDealerlanguage, refreshSearchFilter } from '../../../actions/dealerManagementAction';
import SearchDealer from "./searchDealer";
import AddEditViewDealer from "./addEditViewDealer";
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery')
window.jQuery = $;

class MainDealerContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      isSearchClicked: false,
      modal: false,
      modalFor: "",
      page: 0,
      sizePerPage: 10,
      rows: 10,
      showSearchPanel: true,
      isAdmin: false,
      userDto: {
        role: [],
        adminUserLoginId: this.props.user.userMdl.username,
        adminUserLangCode: this.props.user.userMdl.lang,
        adminUserNmscCode: this.props.user.userMdl.nmsc,
        adminUserCountryCode: this.props.user.userMdl.countryCode,
        rows: 10,
        page: 1,
        programID: this.props.user.userMdl.activeProgramID,
        program: this.props.user.userMdl.activeProgram,
        nmscFlag: false,
        isAdmin: this.props.isAdmin,
        suggestionsDealer: [],
        dealerList: [],
        nmscCodeList: [],
        franchiseList: [],
        countryList: [],
        l3List: [],
        l4List: [],
        l5List: [],
        l6List: [],
        valueDealer: "",
        britReady: "",
        britLive: "",
        britJapan: "",
        dealerCode: '',
        a2dUuid: '',
        city: '',
        userProgram: this.props.user.userMdl.activeProgram,
        selectedNmscCode: [],
        selectedFranchise: [],
        selectedCountry: [],
        selectedL3: [],
        selectedL4: [],
        selectedL5: [],
        selectedL6: [],

      },
      searchDto: {
        adminUserType: null,
        adminUserLangCode: this.props.user.userMdl.lang,
        loggedInUser: this.props.user.userMdl.username,
        adminUserNmscCode: this.props.user.userMdl.nmsc,
        page: 0,
        sizePerPage: 10,
        rows: 10,
        nmscFlag: false,
        isAdmin: this.props.isAdmin,
        suggestionsDealer: [],
        dealerList: [],
        nmscCodeList: [],
        franchiseList: [],
        countryList: [],
        l3List: [],
        l4List: [],
        l5List: [],
        l6List: [],
        valueDealer: "",
        britReady: "",
        britLive: "",
        britJapan: "",
        dealerCode: '',
        a2dUuid: '',
        city: '',
        userProgram: this.props.user.userMdl.activeProgram,
        selectedNmscCode: [],
        selectedFranchise: [],
        selectedCountry: [],
        selectedL3: [],
        selectedL4: [],
        selectedL5: [],
        selectedL6: [],
      },
      data: [],
      records: 0,
      total: 0,
      sortField: null,
      sortOrder: "asc"
    };
  }
  componentWillMount() {

    // console.log("ComponentWillMountCheck - Main")
    if (
      this.props.user.userMdl === undefined ||
      this.props.user.userMdl === null ||
      this.props.user.userMdl.expired === undefined ||
      this.props.user.userMdl.expired
    ) {
      // get user Details from token.
      // console.log("component will mount")
      this.props.dispatch(fetchDealers())
    }
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    // var isNotAdminUser = false;

    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;

      // console.log(roles)
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf("NMSC") !== -1) {
          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase().indexOf("TME ADMIN") !== -1 || roles[i].toUpperCase().indexOf("TME USER") !== -1 || roles[i].toUpperCase().indexOf('KET-CEA ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-CEA USER') !== -1 ||
          roles[i].toUpperCase().indexOf('KET-CEA BUSINESS OWNER') !== -1) {
          isTMEAdminUser = true;
          break;

        }
        // isNotAdminUser = true;
      }
    }

    let userDTO = { ...this.state.userDto };
    let searchDTO = { ...this.state.searchDto }
    if (isTMEAdminUser) {

      userDTO["adminUserType"] = "TME";

      searchDTO["adminUserType"] = "TME";
      searchDTO["isTMEAdminUser"] = isTMEAdminUser;
      this.setState({ userDto: userDTO });
      this.setState({ searchDto: searchDTO });

    } else {

      userDTO["adminUserType"] = "NMSC";

      searchDTO["adminUserType"] = "NMSC";
      searchDTO["isNMSCAdminUser"] = isNMSCAdminUser;

      this.setState({ userDto: userDTO });
      this.setState({ searchDto: searchDTO });
    }

    // if(this.props.userManagementDetails !== null || this.props.userManagementDetails !== undefined) {
    //   this.setState({data : this.props.userManagementDetails.userManagementDTOList,
    //                 records :this.props.userManagementDetails.userManagementDTOList.records,
    //               total : this.props.userManagementDetails.userManagementDTOList.total })            
    // }  


    // this.props.dispatch(fetchUserSearchFields(searchDTO));
  }

  componentWillUnmount() {

    console.log("component will Unmount Check")

    this.setState({
      data: []
    });
    this.props.dispatch(searchEmptyDealerList());
    this.props.dispatch(refreshSearchFilter());
  }

  updateSearchDto = (userDTO) => {

    this.setState({ searchDto: userDTO });

  }

  // search = (userDTO) => {
  //   this.setState({searchDto : userDTO});
  //   this.setState({
  //     showSearchPanel: true,
  //     isSearchClicked:true,
  //     sortField : null
  //   });

  //   this.props.dispatch(fetchUserDetails(userDTO));
  // };

  selectedTab = (index, label) => {
    this.setState({ selectedTab: index });
  };
  toggleSearch = toggle => {
    this.setState({
      showSearchFlag: !this.state.showSearchFlag
    });
  };


  toggleDelete = (networkLevelDealerId) => {
    console.log(networkLevelDealerId)
    confirmAlert({
      customUI: ({ title, message, onClose }) => {
        return (
          <div className={"ConfirmBox-" + this.props.theme.name}>
            <div className="ConfirmBoxTitle">
              {I18n.t('standardMessages.warning')}
            </div>
            <div className="ConfirmBoxBody">
              {I18n.t('standardMessages.deleteConfirmDealer')}
            </div>
            <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
              <button
                className={"button-" + this.props.theme.name}
                onClick={() => {

                  this.props.dispatch(deleteDealer(networkLevelDealerId, this.state.userDto))
                  onClose();
                }}>
                {I18n.t("standardMessages.yes")}
              </button>
              <button className={"button-" + this.props.theme.name} onClick={() => onClose()}>
                {I18n.t("standardMessages.no")}
              </button>
            </div>
          </div>
        );
      }
    });
  }

  toggleModal = (action, row) => {
    if (action !== "Create") {
      let queryDto = {
        dealerUuid: row.dealerUuid
      }
      this.props.dispatch(fecthDealerlanguage(queryDto))

    }
    this.setState({
      modal: !this.state.modal,
      modalFor: action,
      selectedRow: row
    });
  }

  toggle = () => {
    this.setState({
      modal: false
    });
  }

  buttonFormatter = (cell, row) => {
    let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === "DEALER_MANAGEMENT") ? this.props.user.userMdl.accessControl.filter(obj => obj.function === "DEALER_MANAGEMENT").map(a => a.accessType) : null;

    // let ceaRow = false

    // let dealerRow = false
    if (row.role) {
      if (row.role.length > 0) {
        // ceaRow = row.role.filter(role => role.toUpperCase().indexOf("KET TME") !== -1 || role.toUpperCase().indexOf("KET NMSC") !== -1 || role.toUpperCase().indexOf("KET-BRIT") !== -1).length === 0
        // dealerRow = row.role.filter(role => role.toUpperCase().indexOf("KET DEALER") !== -1 || role.toUpperCase().indexOf("KET-BRIT DEALER") !== -1).length === row.role.length
      }

    }
    // let linkName = 'View';
    let linkIcon = 'eye';
    return (
      <div className="btn-toolbar float-right">



        {accessType.indexOf("R/W") !== -1 ?
          <a onClick={() => this.toggleModal("Edit", row)}>
            <div title={I18n.t("tmeAdminMaster.edit")} className="edit-icon" />
          </a> : ""}

        {accessType.indexOf("R/W") !== -1 || accessType.indexOf("R") !== -1 ?
          <a
            onClick={() => this.toggleModal("View", row)}
          >
            <Fa icon={linkIcon} fixed size="lg" title={I18n.t("Table.view")} />
          </a> : null}
        { accessType.indexOf("R/W") !== -1 ? (
          <a onClick={(e) => this.toggleDelete(row.networkLevelDealerId)}>
            <div
              title={I18n.t("tmeAdminMaster.delete")}
              className="delete-icon"
            />
          </a>
        ) : (
          ""
        )}
      </div>
    );
  };

  linkFormatter = (cell, row) => {
    return (
      <div className="btn-toolbar float-right">
        <a>View</a>
      </div>
    );
  };


  countryFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.l2}</div>
    );
  }

  dealerFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealerName}</div>
    );
  }


  dealerCodeFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealerCode}</div>
    );
  }

  cityFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.city}</div>
    );
  }

  nmscFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.nmscCode}</div>
    );
  }

  franchiseFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.l1}</div>
    );
  }

  statusFormatter(cell, row) {
    return (
      <div title={row.status && row.status !== null ? I18n.t('UserManagement.' + row.status) : ''}>
        {I18n.t('UserManagement.' + row.status)}
      </div>
    );
  }

  pageChanged(page, sizePerPage) {
    //const userDto = { ...this.state.userDto };
    const searchDto = { ...this.state.searchDto };

    searchDto["page"] = page;
    searchDto["rows"] = sizePerPage;
    searchDto["sizePerPage"] = sizePerPage;
    // this.setState({ userDto });
    // this.search1(userDto);
    this.setState({ searchDto });
    this.search2(searchDto);
  }


  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (!sortField) {
      // console.log(page)
      // const currentIndex = (page - 1) * sizePerPage;
      const searchDTO = { ...this.state.userDto };
      searchDTO["page"] = page;
      searchDTO["rows"] = sizePerPage;
      searchDTO["sizePerPage"] = sizePerPage
      this.setState({ searchDto: searchDTO, sizePerPage: sizePerPage, page: page });
      if (this.state.isSearchClicked && this.state.records > 0) {
        //console.log("table change in dto",searchDTO);
        this.props.dispatch(fetchDealers(searchDTO));
      }
    } else if (this.state.isSearchClicked && this.state.records > 0) {
      if (this.state.sortOrder === "asc") {
        // console.log("check1")
        sortOrder = "desc";
        this.setState({ sortOrder: "desc" })
      }
      else {
        sortOrder = "asc";
        this.setState({ sortOrder: "asc" })
      }
      this.setState({ sortField: this.indexOfSortField(sortField) });
      const searchDto = { ...this.state.searchDto };
      searchDto["sortOrder"] = sortOrder
      searchDto["sortCol"] = sortField;
      // console.log("page -" +page)
      this.setState({ searchDto });
      this.props.dispatch(fetchDealers(searchDto));
    }
  };

  indexOfSortField(sortField) {
    switch (sortField) {
      case "nmscCode":
        return 1
      case "l1":
        return 2
      case "l2":
        return 3
      case "dealerName":
        return 4
      case "dealerCode":
        return 5
      case "city":
        return 6
      default:
        return 1
    }
  }

  search = (dealerDto) => {
    this.setState({ searchDto: dealerDto });
    this.setState({ userDto: dealerDto });

    this.setState({
      showSearchPanel: true,
      isSearchClicked: true,
      sortField: null
    });

    this.props.dispatch(fetchDealers(dealerDto))
  };

  search2 = (searchDTO) => {
    this.setState({ searchDto: searchDTO });
    this.setState({
      showSearchPanel: true,
      isSearchClicked: true,
      sortField: null
    });

    this.props.dispatch(fetchDealers(searchDTO));
  };
  componentWillReceiveProps(nextProps) {

    console.log("ComponentWillRecivePropsCheck - Main")
    console.log(nextProps)

    if (nextProps.dealerManagement.dealerManagementDetails !== null) {

//      if (nextProps.dealerManagement.dealerManagementDetails !== 0 && nextProps.dealerManagement.dealerManagementDetails[0].records > 0) {
      if (nextProps.dealerManagement.dealerManagementDetails !== 0 && nextProps.dealerManagement.dealerManagementDetails[0] !== undefined && nextProps.dealerManagement.dealerManagementDetails[0].records > 0) {
        this.setState({ data: nextProps.dealerManagement.dealerManagementDetails });
        this.setState({ records: nextProps.dealerManagement.dealerManagementDetails !== 0 ? nextProps.dealerManagement.dealerManagementDetails[0].records : 0 });
        this.setState({ total: nextProps.dealerManagement.dealerManagementDetails !== 0 ? nextProps.dealerManagement.dealerManagementDetails[0].total : 0 });
        this.setState({ page: nextProps.dealerManagement.dealerManagementDetails[0].page })
        let searchDTO = { ...this.state.searchDto }
        searchDTO["page"] = nextProps.dealerManagement.dealerManagementDetails[0].page
        this.setState({ searchDto: searchDTO })
      }
      else {
        this.setState({ data: [] })
        this.setState({ records: 0 });
        this.setState({ total: 0 });
      }
      var index = this.state.sortField;
      if (index) {

        setTimeout(() => {
          if (this.state.sortOrder === "asc") {
            $(".searchActivityTable .dropdown").addClass("hideSortIcon");
            $(".searchActivityTable .dropup").addClass("hideSortIcon");
            $(".searchActivityTable > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" + index + ") > span > span.dropup").addClass("showSortIcon");
          }
          else {
            $(".searchActivityTable .dropdown").addClass("hideSortIcon");
            $(".searchActivityTable .dropup").addClass("hideSortIcon");
            $(".searchActivityTable > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" + index + ") > span > span.dropdown").addClass("showSortIcon");
          }
        }, 10);
      }
    }
    else {
      this.setState({ data: [] });
      this.setState({ records: 0 });
      this.setState({ total: 0 });
    }
  }

  render() {
    //   console.log("Main")
    console.log("in render", this.state.searchDto);
    console.log("in render", this.state.userDto);

    // console.log(this.state)
    console.log("searchClicked test")
    console.log(this.state)
    let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'USER_MANAGEMENT') ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'USER_MANAGEMENT').map(a => a.accessType) : [];
    const columns = [
      {
        dataField: "nmscCode",
        //text: I18n.t("Table.dealerName"),
        text: I18n.t("Table.nmsc"),
        sort: true,
        style: { width: "11%", textAlign: "left" },
        headerStyle: { width: "11%", textAlign: "left" },
        formatter: this.nmscFormatter,
        // title: true
      },
      {
        dataField: "l1",
        // text: I18n.t("Table.city"),
        text: I18n.t("Table.franchise"),
        sort: true,
        style: { width: "15%", textAlign: "left" },
        headerStyle: { width: "14.8%", textAlign: "left" },
        formatter: this.franchiseFormatter,
        title: true
      },
      {
        dataField: "l2",
        //text: I18n.t("Table.toolbox"),
        text: I18n.t("Table.country"),
        sort: true,
        style: { width: "14%", textAlign: "left" },
        formatter: this.countryFormatter,
        headerStyle: { width: "13.8%", textAlign: "left" },
        title: true
      },
      {
        dataField: "dealerName",
        //text: I18n.t("Table.toolbox"),
        text: I18n.t("Table.dealerName"),
        sort: true,
        style: { width: "19%", textAlign: "left" },
        formatter: this.dealerFormatter,
        headerStyle: { width: "18.8%", textAlign: "left" },
        title: true
      },


      {
        dataField: "dealerCode",
        //text: I18n.t("Table.toolbox"),
        text: I18n.t("UserManagement.dealerCode"),
        sort: true,
        style: { width: "15%", textAlign: "left" },
        formatter: this.dealerCodeFormatter,
        headerStyle: { width: "13.8%", textAlign: "left" },
        title: true
      },
      {
        dataField: "city",
        //text: I18n.t("Table.status"),
        text: I18n.t("Table.city"),
        sort: true,
        formatter: this.cityFormatter,
        style: { width: "14%", textAlign: "left" },
        headerStyle: { width: "13.8%", textAlign: "left" },
        //title: true
      },
      {
        dataField: "",
        text: "",
        formatter: this.buttonFormatter,
        style: { width: "12%" },
        headerStyle: { width: "11.8%" }
      }
      // , {
      //     dataField: "",
      //     text: "",
      //     formatter: this.linkFormatter,
      //     // headerStyle: { width: "17%" }
      // }
    ];

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow["networkLevelDealerId"] = row.networkLevelDealerId;
        selectedRow["nmscCode"] = "" + row.nmscCode;
        // selectedRow["dealer"] = row.dealer;
        //selectedRow["tarsId"] = "" + row.tarsId;
        selectedRow["dealerName"] = row.dealerName;
        selectedRow["dealerCode"] = row.dealerCode;
        selectedRow["dealerUuid"] = row.dealerUuid
        selectedRow["l1"] = row.l1;
        selectedRow["l2"] = row.l2;
        selectedRow["l3"] = row.l3;
        selectedRow["l4"] = row.l4;
        selectedRow["l5"] = row.l5;
        selectedRow["l6"] = row.l6;
        selectedRow["britReady"] = row.britReady
        selectedRow["britLive"] = row.britLive
        selectedRow["britJapan"] = row.britJapan
        selectedRow["eesAction"] = row.eesAction
        selectedRow["city"] = row.city;
        selectedRow["userMdl"] = this.props.user.userMdl;
        selectedRow["adminNmscCode"] = this.props.user.userMdl.nmsc;
        selectedRow["adminLangCode"] = this.props.user.userMdl.lang;
        selectedRow["adminLoginId"] = this.props.user.userMdl.username;
        selectedRow["businessArea"] = row.businessArea;        
        this.setState({ selectedRow });
      }
    };

    const { data, sizePerPage } = this.state;
    //const  sizePerPage  = this.state.data && this.state.data.length>0 ? this.state.data.length : 0;
    const { page } = this.state.searchDto
    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize
    }) => (
      <div className={'activityTable parent-' + this.props.theme.name}>
        <BootstrapTable
          remote
          keyField="networkLevelDealerId"
          noDataIndication={I18n.t('UserManagement.noDealer')}
          data={data}
          columns={columns}
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
        />
      </div>
    );
    console.log(this.state)

    return (
      <Container className="mt-5 b-1 activityContainer">
        <Col md="12" className="activityTableContainer">
          <div className="activity-content">
            {/* <form className="form-horizontal"> */}
            <div className="col-md-12 row control-label noPadding">
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                {I18n.t("tmeAdminMaster.search")}
              </label>
              <label
                md="0.5"
                className="control-label boldFont"
                style={{ marginTop: "0.2rem" }}
              >
                <a onClick={this.toggleSearch}>
                  {this.state.showSearchFlag ? (
                    <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                  ) : (
                    <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                  )}
                </a>
              </label>
              <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'} style={{ width: "20%" }}>
                {I18n.t("activity.Dealer")} ({this.state.records})
              </label>
              <label className="control-label" style={{ width: "46%" }} />
              {accessType.indexOf("R/W") !== -1 ?
                <label
                  className="control-label boldFont"
                  style={{ textAlign: "right", width: "25%" }} >
                  {I18n.t("UserManagement.addDealer")}

                  <a onClick={() => this.toggleModal("Create")}>
                    <div title={I18n.t("tmeAdminMaster.add")} className="add-icon" />
                  </a>
                </label> : ""}
              <SearchDealer
                isAdmin={true}
                userDto={this.state.userDto}
                flag={this.state.showSearchFlag}
                search={this.search}
                searchDto={this.state.searchDto}
              />
              {/* <SearchUser
                flag={this.state.showSearchFlag}
                searchDto={this.state.searchDto}
                search ={this.search}
                update = {this.updateSearchDto}
              /> */}
              <div className="col-md-12 searchActivityTable">
                {this.state.showSearchPanel ?
                  <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.state.records}
                    onTableChange={this.handleTableChange}
                  /> : ""}
              </div>
            </div>
            {/* </form> */}
            {this.state.modal === true ?
              <AddEditViewDealer
                modal={this.state.modal}
                modalFor={this.state.modalFor}
                toggle={this.toggle}
                selectedRow={this.state.selectedRow}
                searchDto={this.state.searchDto}
                userDto={this.state.userDto}
                isSearchClicked={this.state.isSearchClicked}
              /> : null
            }
          </div>
        </Col>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    userManagementDetails: state.userManagementReducer.userManagementDetails,
    dealerManagement: state.dealerManagementReducer
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(MainDealerContainer)));
