import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { withTheme } from '../../../themes/theming';
import Table from 'react-bootstrap/Table'
import { getStatCount } from '../../../actions/mainQuestionAction';
import ImportTMEQuestionnaireModal from "./importTMEQuestionnaireModal";
var I18n = require('react-redux-i18n').I18n;


class statCounter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            statCount: {
                mainQuestions: 0,
                subQuestions: 0,
                retiredSubQuest: 0,
                libraries: 0,
                importNMSCQuestion: false
            }

        }
    }

    componentWillMount() {
        this.props.dispatch(getStatCount(this.props.toolboxId, this.props.user.userMdl.nmsc));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.toolboxId !== this.props.toolboxId) {
            this.props.dispatch(getStatCount(this.props.toolboxId, this.props.user.userMdl.nmsc));
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.statCount) {
            this.setState({ statCount: nextProps.statCount });
            if (nextProps.statCount.mainQuestions === 0 && this.state.statCount.newSubQuestions === 0 && this.state.statCount.libraries === 0 && this.state.statCount.subQuestions === 0) {
                this.props.importDisable(true);
            }
            else {
                this.props.importDisable(false);
            }
        }

    }

    toggle = () => {
        this.setState({
            importNMSCQuestion: !this.state.importNMSCQuestion
        });
    }

    render() {
        return (
            <div>
                <Table className="tableCount">
                {this.props.type!=='NMSC'?
                    <tbody>
                        <tr>
                            <th> {I18n.t('questionnaire.mainQuestion')}</th>
                            <th style={{ color: "red" }}>{this.state.statCount.mainQuestions}</th>
                            <th><a onClick={this.toggle} style={{ color: "blue" }}>{I18n.t('questionnaire.subQuestion')}</a></th>
                            <th style={{ color: "red" }}>{this.state.statCount.subQuestions}</th>
                            <th> {I18n.t('questionnaire.libraries')}</th>
                            <th style={{ color: "red" }}>{this.state.statCount.libraries}</th>
                           
                        </tr>
                    </tbody>
                    :""}
                </Table>
                <div>
                    {this.state.importNMSCQuestion === true ? (
                        <ImportTMEQuestionnaireModal
                            toggle={this.toggle}
                            toolboxId={this.props.toolboxId}
                            activeTabNmscLang={this.props.activeTabNmscLang}
                        />
                    ) : (
                            ""
                        )}
                </div>

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        statCount: state.questionSummaryReducer.statCount,
        user: state.loginReducer
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(statCounter)));