import React from 'react';
import { Container, Modal, ModalBody, ModalHeader, ModalFooter } from 'mdbreact';
import { withTheme } from '../../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toastr } from "react-redux-toastr";
import { Treebeard } from 'react-treebeard';
import $ from 'jquery';
import { Row } from 'react-bootstrap';
import Style from '../../library/style';
import FolderDetails from './folderDetails';
import '../index.css';
import { getLibraryCount, linkQuestionReference, getLibraryFolders, saveQueLibraryLink, fetchNmscPublishedFolders, saveNmscQueLibraryLink } from '../../../actions/libraryAction';
var I18n = require('react-redux-i18n').I18n;

class AddLibraryFileModal extends React.Component {
/* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            editQuestionNumber: this.props.editQuestionNumber,
            collapse: false,
            modal: false,
            questionNo: '',
            modalFor: "",
            fileModal: false,
            fileModalFor: "",
            data: [],
            selectedNodeId: '',
            includedSubFolder: false,
            selectedFiles: [],
            selectedSubFolders: [],
            selectedFolder: false,
            chipData: [],
            row: '',
            showDelete: false,
            showEdit: false,
            isMainFolderFlag: false,
            libraryCount: 0,
            linkedFolders: [],
            alreadyLinked: []
        }
        this.onToggle = this.onToggle.bind(this);
        this.fileListFlag = this.fileListFlag.bind(this);
        this.selectedFiles = this.selectedFiles.bind(this);
        this.getNumberOfFileSelected = this.getNumberOfFileSelected.bind(this);
        this.includeSubFolder = this.includeSubFolder.bind(this);
        this.linkLibrary = this.linkLibrary.bind(this);
    }

    onHandleChange = (e) => {

        this.setState({ description: e.target.value });
    }
    onChange = (e) => {

        this.setState({ reason: e.target.value });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.selectedNodeId !== this.state.selectedNodeId) {
            let result = false;
            this.state.selectedSubFolders.map((folder) => {
                if (this.state.selectedNodeId === folder && result === false) {
                    result = true;
                }
                return null;
            })
            this.setState({ selectedFolder: result });
         }
    }

    componentWillMount() {
        if (this.props.isNmsc) {
            this.props.dispatch(fetchNmscPublishedFolders(this.props.toolboxId, null, null, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang));
        } else {
            this.props.dispatch(getLibraryCount(this.props.toolboxId));
            this.props.dispatch(getLibraryFolders(this.props.toolboxId));
        }
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.libraryReducer.closeModal) {
            // Close Modal Window.
            this.setState({ modal: false })
        }
        if (nextProps.libraryReducer.refreshRequired) {
            let values = [];
            if (nextProps.libraryReducer.folderId !== undefined) {
                values = nextProps.libraryReducer.folderId.split('|');
            }
            let id = values[0] !== undefined ? values[0] : 0;
            let name = values[1] !== undefined ? values[1] : '';
            // Get latest list of Library folders.
            if (this.state.cursor) {
                if (this.props.isNmsc) {
                    this.props.dispatch(fetchNmscPublishedFolders(this.props.toolboxId, null, null, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang));
                }
                else {
                    this.props.dispatch(getLibraryFolders(this.props.toolboxId, nextProps.libraryReducer.folderId, this.state.parentFolderId));
                }
                this.setState({ selectedNodeId: nextProps.libraryReducer.folderId });
                var cursor = this.state.cursor;
                cursor.id = id;
                cursor.name = name;
                cursor.isParent = false;
                this.setState({ cursor: cursor });
            }
            else {
                if (this.props.isNmsc) {
                    this.props.dispatch(fetchNmscPublishedFolders(this.props.toolboxId, null, null, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang));
                }
                else {
                    this.props.dispatch(getLibraryFolders(this.props.toolboxId, nextProps.libraryReducer.folderId, this.state.parentFolderId));
                }
                this.setState({ selectedNodeId: nextProps.libraryReducer.folderId });
                var cursor = {};
                cursor.id = id;
                cursor.name = name;
                cursor.isParent = true;
                this.setState({ cursor: cursor });
            }
            this.setState({ showDelete: false, showEdit: false })
            // alert('Refresh!!' + this.props.toolboxId);
            this.props.dispatch({
                type: "REFRESH_DONE",
                payload: ''
            });
        }
        if (nextProps.questionNo) {
            this.setState({ questionNo: nextProps.questionNo });
        }

       
        if (nextProps.libraryReducer.libraryCount) {
            console.log(nextProps.libraryReducer.libraryCount);
            this.setState({ libraryCount: nextProps.libraryReducer.libraryCount });
        }
        else{
            this.setState({ libraryCount: 0 });
        }
        if (nextProps.libraryReducer.folderListLink) {
            this.setState({ data: nextProps.libraryReducer.folderListLink, includedSubFolder: false });
        }
        if (this.props.toolboxId !== nextProps.toolboxId) {
            if (this.props.isNmsc) {
                this.props.dispatch(fetchNmscPublishedFolders(this.props.toolboxId, null, null, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang));
            }
            else {
                this.props.dispatch(getLibraryCount(this.props.toolboxId));
                this.props.dispatch(getLibraryFolders(this.props.toolboxId));
            }

        }
        if (this.props.alreadyLinked) {
            const alreadyLinked = [...this.props.alreadyLinked];
            let folderLinked = [];
            alreadyLinked.map((link) => {
                if (link.folderId !== 0) {
                    folderLinked.push(link.folderId);
                }
            });
            this.setState({ alreadyLinked: alreadyLinked, linkedFolders: folderLinked });

        }

        if (this.state.cursor && this.state.cursor.id !== 0 && !this.state.cursor.isParent) {
            this.setState({ isMainFolderFlag: false });
        }
        else {
            this.setState({ isMainFolderFlag: true });
        }
    }
    deactiveNode = (currentNode) => {
        var flag = false;
        var nodeList = this.state.data;
        for (var i = 0; i < nodeList.length; i++) {
            var parn = nodeList[i];
            var node = nodeList[i].children;
            if (node) {
                for (var j = 0; j < node.length; j++) {
                    var child = node[j];
                    if (child.id !== currentNode.id) {
                        child.active = false;
                    }
                    else {
                        flag = true
                    }
                }
            }
            if (!flag) {
                parn.toggled = false;
                parn.active = false;
            }

        }
        this.setState({ questionLeftPane: nodeList })
    }

    linkLibrary() {
        console.log(this.state.selectedFiles);
        let fileDetails = [];
        let fileList = [];
        let filesData = [...this.state.selectedFiles];
        let subFolders = [...this.state.selectedSubFolders];
        if (filesData.length <= 0 && subFolders.length <= 0) {
            toastr.error(I18n.t('library.selectFile'));
            return
        }
        else {
            let linkLibraryDTO = {
                fileId: '',
                folderId: '',
                questionId: '',
                status: 'TME'
            };
            filesData.map((folder) => {
                if (folder.files.length > 0) {
                    folder.files.map((file) => {
                        let linkLibraryDTO = { fileId: '', folderId: '', questionId: '', status: 'TME' };
                        linkLibraryDTO.fileId = file.fileId;
                        fileList.push(file.fileId);
                        linkLibraryDTO.questionId = this.props.questionId;
                        linkLibraryDTO.loginId = this.props.user.userMdl.username;
                        fileDetails.push(linkLibraryDTO);
                    })
                }
            });
            subFolders.map((subFolder) => {
                let linkLibraryDTO = { fileId: '', folderId: '', questionId: '', status: 'TME' };
                linkLibraryDTO.folderId = subFolder;
                linkLibraryDTO.questionId = this.props.questionId;
                linkLibraryDTO.loginId = this.props.user.userMdl.username;
                fileDetails.push(linkLibraryDTO);
            });
            let list = {
                questionNo: this.state.questionNo,
                fileList: fileList
            }

            if (this.props.isNmsc) {
                this.props.dispatch(saveNmscQueLibraryLink(fileDetails, this.props.user.userMdl.nmsc, this.props.activeTabNmscLang));
            }
            else {
                this.props.dispatch(saveQueLibraryLink(this.props.user.userMdl.username, fileDetails));
                this.props.dispatch(linkQuestionReference(list.questionNo, list.fileList));
            }

            this.props.addMainQuestion(this.props.modalFor);

        }


    }
    selectedFiles(folderFiles, nonSelected) {
        let isReplaced = false;
        let alreadySelected = [...this.state.selectedFiles];
        let index = -1;
        if (alreadySelected.length > 0) {
            alreadySelected.map((folder) => {
                if (folder.folderId === folderFiles.folderId && index === -1) {
                    index = alreadySelected.indexOf(folder);
                    isReplaced = true;
                }
            })
            if (!isReplaced) {
                alreadySelected.push(folderFiles);
            }
            else {
                alreadySelected.splice(index, 1);
                alreadySelected.push(folderFiles);

            }
        }
        else {
            alreadySelected.push(folderFiles);
        }
        this.setState({ selectedFiles: alreadySelected });
    }
    getNumberOfFileSelected() {
        let number = 0;
        this.state.selectedFiles.map((folder) => {
            folder.files.map((file) => {
                number = number + 1;
            });
        })
        return number;
    }
    onToggle(node, toggled) {
        this.deactiveNode(node)
        if (this.state.cursor) { this.state.cursor.active = false; }
        node.active = true;
        if (node.children) { node.toggled = toggled; }
        if (node.isParent) {
            this.setState({ parentFolderId: node.id });
        }
        this.setState({ cursor: node, selectedNodeId: node.id, showDelete: true, showEdit: true });
    }
    fileListFlag = (fileListFlag) => {
        this.setState({ fileListFlag: fileListFlag });
    }
    includeSubFolder = (e) => {
        // console.log(this.state.selectedSubFolders);
        if (e.target.checked) {
            // alert("checked");
            if (!this.state.cursor.children) {
                let selected = [...this.state.selectedFiles]
                let subFoldersSelected = [...this.state.selectedSubFolders];
                selected.map((folder) => {
                    if (folder.folderId === this.state.cursor.id) {
                        folder.files = [];
                    }
                })
                if (!subFoldersSelected.includes(this.state.cursor.id)) {
                    subFoldersSelected.push(this.state.cursor.id);
                }
                this.setState({ selectedFolder: true, selectedFiles: selected, includedSubFolder: true, selectedSubFolders: subFoldersSelected });
            }
        }
        else {
            // alert("unchecked");
            let subFoldersSelected = [...this.state.selectedSubFolders];
            let index = 9999;
            subFoldersSelected.map((folder) => {
                if (folder === this.state.cursor.id) {
                    index = subFoldersSelected.indexOf(folder);
                }
            })
            if (index !== 9999) {
                subFoldersSelected.splice(index, 1);
            }
            this.setState({ includedSubFolder: false, selectedFolder: false, selectedSubFolders: subFoldersSelected });
        }
    }
    preventDefault() { }
    render() {
        if(this.props.theme.name==="lexus" && $('span.toyota')){
            $(".toggleTreebeard").removeClass("toyota");
            $(".toggleTreebeard").addClass("lexus");
            $(".toggleTreebeard").attr("src","../../assets/images/upArrow-lexus.png");
        }
        else if(this.props.theme.name==="toyota" && $('span.lexus')){
            $(".toggleTreebeard").removeClass("lexus");
            $(".toggleTreebeard").addClass("toyota");
            $(".toggleTreebeard").attr("src","../../assets/images/T_Artboard 21mdpi.png");
        }else {
            //..
        }
        const folderDetails = <FolderDetails
            toolboxId={this.props.toolboxId}
            selectedNodeId={this.state.selectedNodeId}
            filesForPublish={this.state.selectedFiles}
            toggle={this.toggleFileModal}
            fileListFlag={this.fileListFlag}
            selectedFiles={this.selectedFiles}
            includedSubFolder={this.state.selectedFolder}
            isMainFolderFlag={this.state.isMainFolderFlag}
            alreadyLinked={this.state.alreadyLinked}
        />

      
        return (
            <Container>
                <Modal
                    className={"addEditQuestionModal  addEditQuestionModal-" + this.props.theme.name}
                    id="addEditActivityModal"
                    isOpen="true"
                    addMainQuestion={() => this.preventDefault()}
                >
                    <ModalHeader toggle={() => this.props.addMainQuestion(this.props.modalFor)}>
                        {I18n.t('questionnaire.links.question_link_add_link')}
                    </ModalHeader>
                    <ModalBody>

                        <Container fluid
                            className="ml-0 p-0 linkLibraryModal" >
                            {this.state.libraryCount !== 0 ?
                                <Row style={{ minHeight: "380px" }}>
                                    <div id="libLinkSideNav" style={{ borderRight: '1px solid lightGray', width: '34%' }}>
                                        <Row className="pl-3 libraryRoot" onClick={() => this.clearSelection()} >
                                            <img src={"../../assets/images/"+this.props.theme.name+"_library.png"} height="25" width="25" alt=""/>
                                            <span>Libraries</span>
                                        </Row>
                                        <Row className="libraryTreeWrapper canShrink">
                                            <Treebeard
                                                data={this.state.data}
                                                onToggle={this.onToggle}
                                                style={Style}
                                                decorators={libraryDecorator}
                                            />
                                        </Row>

                                    </div>
                                    <div className="pl-1 m-1 rightPanel libLinkRightPanel">
                                        <Row className="libraryTable">
                                            {folderDetails}
                                        </Row>
                                    </div>
                                </Row>

                                :
                                //<div>No Files Available</div>
                                <div><label style={{ marginLeft: '315px', marginTop: '12px' }}>  {I18n.t('questionnaire.noFiles')}</label> </div>
                            }
                        </Container>


                    </ModalBody>
                    <ModalFooter>
                        <Row className="noOfFiles">
                            <div>{this.getNumberOfFileSelected()}{I18n.t('library.fileSelected')}</div>
                            <br /><div>{this.state.linkedFolders.includes(this.state.selectedNodeId) ?
                                <input type="checkbox" checked disabled />
                                :
                                <input type="checkbox" onChange={(e) => this.includeSubFolder(e)} />
                            }{I18n.t('questionnaire.links.question_link_include_folder')}
                    </div>
                        </Row>
                        <button
                            className={"buttonCancel-" + this.props.theme.name}
                            onClick={() => this.props.addMainQuestion(this.props.modalFor)}
                        >
                            {I18n.t("tmeAdminMaster.cancel")}
                        </button>
                        <button
                            className={"button-" + this.props.theme.name}
                            onClick={() => { this.linkLibrary() }}
                        >
                        {I18n.t('questionnaire.links.question_link_button')}
            </button>
                    </ModalFooter>
                </Modal>
            </Container>
        );
    }
}
const libraryDecorator = {
    Header: ({ node, style }) => {
        const iconClass = node && node.id !== 0 ? 'fa fa-folder' : 'fa fa-tasks-alt';
        const divStyle = node && node.children ? { display: 'inline', paddingLeft: '0px' } : { display: 'inline', paddingLeft: '25px' };
        const iconStyle = { marginRight: '5px', color: '#ffbb33' };
        const name = node && node.name ? node.name : '';
        const textClass = node.active ? 'activeFolder' : '';
        return (
            <div style={divStyle}>
                <i className={iconClass} style={iconStyle} />
                <span className={textClass} style={{ wordBreak: 'break-all' }}>{name}</span>
            </div>
        );
    },
    Toggle: ({ node, style }) => {
        return (
            <span>
                <img src={"../../assets/images/T_Artboard 21mdpi.png"} height={'25px'} width={'25px'} className="toggleTreebeard toyota p-1 rotate-true libraryToggleArrow" alt=""/>
            </span>
        );
    },
    Loading: (props) => {
        return (
            <div style={props.style}>
                loading...
            </div>
        );
    },
    Container: (props) => {
        return (
            <div className="pl-2 lib-node" onClick={props.onClick}>
                {props.node.children && props.node.children.length > 0 ?
                    <props.decorators.Toggle /> : <div />}
                <props.decorators.Header node={props.node} />
            </div>
        );
    }
};
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddLibraryFileModal)));
