const translationsIS = {
  is: {
    Policy: {
      HeaderMsg: "Persónuverndaryfirlýsing vegna úrvinnslu persónuupplýsinga þinna í viðmóti Kaizen Expert Tool",
      Message1: "Þessi persónuverndaryfirlýsing er vegna úrvinnslu persónuupplýsinga þinna í viðmóti Kaizen Expert Tool. Alltaf þarf að lesa hana ásamt General Toyota Privacy og persónuverndarreglugerðina og tilgreinir hún með hvaða hætti unnið er úr þínum persónuupplýsingum og sem þú þarft að samþykkja til þess að halda áfram.",
      Message2: "Toyota Motor Europe NS/SA, með höfuðstöðvar í  Avenue du Bourget/Bourgetlaan 60, 1140 Brussel, Belgíu, hefur hlutverk ábyrgðaraðila og safnar persónuupplýsingum um þig í þeim tilgangi sem skilgreindur er að neðan.",
      Message3: "Hvaða persónuupplýsingum um þig verður safnað?",
      Message4: "Við munum safna eftirfarandi flokkum persónuupplýsinga um þig með tilliti til Kaizen Expert Tool.",
      Table1: {
        tr1: "Flokkur/ar persónuupplýsinga:",
        tr2: "Notendanafn",
        tr3: "Fullt nafn notanda",
        tr4: "Vörumerki (Toyota, Lexus)",
        tr5: "Valið tungumál",
        tr6: "Tengt fyrirtæki"
      },

      Message5: "Tilgangur og lagalegur grundvöllur fyrir söfnun persónuupplýsinga þinna.",
      Message6: "Við munum safna persónuupplýsingum um þig í þeim tilgangi og samkvæmt þeim lagalega grundvelli sem tilgreindur er að neðan.",
      Table2: {
        tr1: {
          th1: "Tilgangur:",
          th2: "Lagalegur grundvöllur:"
        },
        tr2: {
          td1: "KET - 1: Árangur þjónustuaðila í Kaizen",
          td2: "Lögmætir hagsmunir ábyrgðaraðila"
        },
        tr3: {
          td1: "KET - 2: Tæknileg aðstoð",
          td2: "Lögmætir hagsmunir ábyrgðaraðila"
        }
      },
      Message7: "Varðveislutímabil persónuupplýsinga þinna",
      Message8: "Varðveilsutími perósnuupplýsinga þinna er eftirfarandi:",
      Table3: {
        tr1: {
          th1: "Varðveislutímabil:",
          th2: "Flokkur/ar persónuupplýsinga:"
        },
        tr2: {
          td1: "Allar þær persónuupplýsingar sem lýst er hér að ofan verða varðveittar að hámrki í 5 ár + 1 mánuð frá þeim degi sem Kaizen Expert Tool er lokað.",
          td2: "Fornafn notanda, eftirnafn, tungumál, fyrirtæki"
        }
      },
      Message9: "Valkvætt: Ef til þess kæmi að persónuupplýsingar þínar yrðu skráðar í löndum utan Evrópska Efnahagssvæðisins (EES)",
      Message10: "Persónuupplýsingar þínar verða aðgengilegar viðtakendum í löndum utan EES á þann máta sem tilgreindur er að neðan:",
      Table4: {
        tr1: {
          td1: "Persónurekjanlegar upplýsingar",
          td2: "Land",
          td3: ""
        },
        tr2: {
          td1: "Viðtakandi utan EES",
          td2: "Infosys",
          td3: "Indland"
        }
      },
      Message11: "Ég staðfesti hér með skilning minn á þessari tilkynningu; Ég kynnt mér og samþykkt General Toyota Privacy og persónuverndarlöggjöfina."
    },
    ACCESSORIES: "Aukahlutir",
    activity: {
      Assignee: "Úthlutunarhafi",
      CompletionDate: "Lokadagsetning",
      Dealer: "Þjónustuaðili",
      FirstVisit: "Fyrsta heimsókn",
      kpi: {
        kpi: "KPI skal rita sem tölu",
        kpiLength: "Hámarks stafafjöldi fyrir KPI er 10",
        mandatory: "Færðu gild gögn inn í alla skilyrta reiti",
        mandatoryTargets: "Vinsamlega sláðu inn allar lykiltölur",
        target: "Setja skal markmið fram sem tölu",
        targetLength: "Hámarks stafafjöldi fyrir markmið er 10"
      },
      ReminderOn: "Áminning skráð",
      statusDD: "Staða",
      Toolbox: "Toolbox",
      activityCreation: "Aðgerðaferli er hafið",
      activityCreated: "Verkþáttur hefur verið stofnaður"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Skilvirkni",
    FIX_IT_RIGHT: "Fix It Right",
    footer: {
      message: "Skilyrtir reitir"
    },
    FOUNDATION: "Grunnur",
    MAINTENANCE_REMINDERS: "Þjónustuáminning",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Grundvallaratriði",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Apr",
      aug: "Ág",
      dec: "Des",
      feb: "Feb",
      jan: "Jan",
      jul: "Júl",
      jun: "Jún",
      mar: "Mar",
      may: "Maí",
      nov: "Nóv",
      oct: "Okt",
      sep: "Sep"
    },
    others: {
      back: "Til baka",
      closed: "Lokað",
      completed: "Lokið ",
      countryLanguageCode: "Land/tungumálskóði ",
      createActivity: "Stofna verk",
      createdDate: "Stofnunardagur",
      dataUneditableNote:
        "Ath.: Ekki er hægt að breyta gögnum sem hafa verið vistuð. Hafðu samband við NMSC-stjórnanda til að breyta gögnum.",
      date: "Dagsetning",
      downloadQuestionnaire: "Sækja spurningalista",
      editActivity: "Breyta verki",
      initialHelpText:
        "Upphafleg mæling árangursmælikvarða ætti að vera meðaltal undanfarinna sex mánaða",
      inProgress: "Í vinnslu",
      name: "Nafn",
      next: "Áfram",
      noActivities: "Engin verk fyrir hendi",
      noResults: "Engar niðurstöður",
      of: "af",
      ongoing: "Í gangi",
      open: "Opna",
      page: "Síða",
      planned: "Fyrirhugað",
      problemDescription: "Lýsing vandamáls",
      signature: "Undirskrift",
      select: "Velja",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Villa kom upp við niðurhal",
      no_published_subquestion: "Vinsamlega virkjaðu spurningalistann fyrir viðkomandi toolbox til að loka þessu verkefni.",
      activity_upload_error_msg: "Villa kom upp við að hlaða upp lýsingu verks.",
      action_plan_created: "Aðgerðaráætlun stofnuð",
      action_plan_deleted: "Aðgerðaráætlun eytt",
      action_plan_saved: "Aðgerðaráætlun vistuð",
      activity_kpi_saved: "Verkefnistengd KPI vistuð",
      master_kpi_confirm_delete:
        "Ertu viss um að þú viljið eyða völdu KPI",
      master_kpi_created: "Master KPI var stofnað",
      master_kpi_deleted: "Master KPI hefur verið eytt",
      master_kpi_not_found: "Master KPI fannst ekki",
      master_kpi_published: "Valin Master KPI voru birt",
      master_kpi_retired: "Master KPI hefur verið gert óvirkt",
      master_kpi_saved: "Master KPI var vistað",
      subquestion_created: "Fylgispurningin hefur verið vistuð.",
      subquestion_updated: "Fylgispurningin hefur verið uppfærð.",
      subquestionnaire_not_found: "Fylgispurningin finnst ekki.",
      subquestion_retired: "Fylgispurningin hefur verið gerð óvirk.",
      subquestion_deleted: "Fylgispurningunni hefur verið eytt.",
      subquestion_published: "Fylgispurningarnar hafa verið vistaðar.",
      mainquestion_created: "Listi meginspurninga hefur verið búinn til.",
      mainquestion_deleted: "Lista meginspurninga hefur verið eytt.",
      mainquestion_updated: "Meginspurningin hefur verið uppfærð.",
      duplicateMsg: "Tvöfalda skráningar í Excel skránni.",
      deleteErr: "Villa kom upp við eyðingu skráar",
      toolbox_deleted: "Toolboxi hefur verið eytt",
      toolbox_already_exists: "Þetta toolbox er þegar til staðar",
      toolbox_saved: "Toolbox vistað",
      toolbox_created: "Toolbox stofnað",
      toolbox_not_found: "Toolbox finnst ekki",
      published_content_found: "Ekki er hægt að eyða þessu toolboxi þar sem það inniheldur birt gögn.",
      dealers_already_mapped: " Valdir þjónustuaðilar hafa þegar verið skráðir á þennan notanda ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Þjónustuaðila bætt við. ",
      dealers_added: "  Þjónustuaðila bætt við. ",
      total_dealers_mapped_now: "Heildarfjöldi þjónustuaðila sem úthlutað er til notanda er ",
      no_dealer_available: "Enginn þjónustuaðili laus í valin viðmið",
      proper_dealer_selected: "Vinsamlega veldu viðeigandi þjónustuaðila",
      user_referenced_ongoing_activity: "Notanda hefur nú þegar verið úthlutað Verkum sem eru í vinnslu. Vinsamlegast veldu annan notanda fyrir þessi verk og reyndu aftur. ",
      want_to_remove_mapping: "Ertu viss um að þú viljir fjarlægja heimild notanda fyrir þessa þjónustuaðila",
      user_mapping_removed: "Valdir þjónustuaðilar hafa verið fjarlægðir úr úthlutun notanda",
      dealer_closed_in_A2D: "Þjóunstuaðili skráður sem \"lokaður\" í A2D",
      network_level_not_found: "Vinsamlega veldu gilda samsetningu þjónustusvæða miðað við uppsetningu þína í KET",
      duplicate_dealer_uuid: "Þjónustuaðili er þegar til staðar í gagnagrunni KET",
      dealer_created: "Þjónustuaðili stofnaður",
      dealer_updated: "Eiginleikar þjónustuaðila hafa verið uppfærðir",
      dealer_not_present: "Þessi þjónustuaðili er ekki skráður í A2D",
      dealer_uuid_other_nmsc: "Valinn A2D UUID kóði er ekki fáanlegur fyrir þitt markaðssvæði",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Ertu viss um að þú viljir eyða?",
      addActionPlanNotAllowed: "Ekki er hægt að stofna eða breyta aðgerðaráætlun eftir að Verki hefur verið lokað, fullklárað, eða hafnað.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Ertu viss um að þú viljir hafna þessu verki? Ekki er hægt að breyta því eftir höfnun",
      rejectMsg: "Ertu viss um að þú viljir hafna þessu verki? Ekki er hægt að breyta því eftir höfnun",
      selectNMSCTab: "Veldu tungumál til birtingar",
      all: "Allt",
      deleteConfirm: "Viltu örugglega eyða völdum árangursmælikvarða?",
      no: "Nei",
      retireConfirm:
        "Ertu viss um að þú viljir gera þennan árangursmælikvarða óvirkan? Þegar þessi árangursmælikvarði hefur verið gerður óvirkur er ekki hægt að virkja hann á ný",
      warning: "Viðvörun",
      yes: "Já",
      saveDataConfirmation: "Ertu viss um að þú viljir vista gögnin?",
      saveKPIWarning:
        "Ath.: Ekki er hægt að breyta gögnum sem hafa verið vistuð. Hafðu samband við KET-stjórnanda hjá innflutningsaðila til að breyta gögnum",
      addKpiNotAllowed: "Hægt er að færa inn KPI stöðu næsta mánaðar þegar núverandi mánuði lýkur. ",
      activityLaunch: "Ertu viss um að þú viljir gangsetja þetta verk? Þegar verk hefur verið gangsett er ekki hægt að færa aftur á áætlunarstig eða eyða því.",
      deleteConfirmSubQuestionNaire: "Viltu örugglega eyða valinni fylgispurningu?",
      retireConfirmSubQuestionNaire: "Viltu örugglega gera valda fylgispurningu óvirka?",
      deleteConfirmMainQuestion: "Vinsamlegast staðfestu að þú viljir eyða viðkomandi meginspurningu ásamt meðfylgjandi fylgispurningum.",
      deleteConfirmChapter: "Viltu örugglega eyða viðkomandi kafla?",
      deleteLibraryFolder: "Viltu örugglega eyða viðkomandi möppu?",
      deletePublishedFolder: "Einhverjar skrár innan möppunnar gætu verið tengdar spurningalistanum. Ertu viss um að þú viljir endanlega eyða þessari undirmöppu og fjarlægja allar tengingar hennar úr spurningalistanum?",
      importQuestion: "Viltu örugglega hlaða inn viðkomandi fylgispurningu? Núverandi fylgispurningu verður skipt út.",
      noQuestion: "Engar spurningar lausar.",
      MandatoryWarning: "Vinsamlegast fylltu út alla skilyrta reiti fyrir viðeigandi tungumál.",
      certificationSubmission:
        "Toolbox hefur verið sent til TME til viðurkenningar",
      sameToolBox: "Ekki er hægt að skila inn sama Toolboxi oftar en einu sinni. Vinsamlegast veldu annað Toolbox.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Haka við allt",
      unSelectAll: "Haka af öllu",
      select:"Velja…",
      warningMsg:"Er opin aðgerðaráætlun í þessu verki. Viltu ljúka við aðgerðaráætlunina?",
      updateEaConfirmDealer: "All Activities created for this dealer in 'EA' will be removed. Are you sure you want to continue?"

    },
    status: {
      published: "Birt",
      retired: "Óvirkt",
      saved: "Vistað",
      rejected: "Hafnað",
      open: "Opna",
      closed: "Lokað",
      close: "Lokað",
      ongoing: "Í gangi",
      completed: "Lokið",
      complete: "Lokið",
      planned: "Fyrirhugað",
      overdue: "Komið fram yfir skil",
      due: "Komið að skilum",
      pending: "Pending",
    },
    Rejected: "Hafnað",
    Open: "Opna",
    Closed: "Lokað",
    Close: "Lokað",
    Ongoing: "Í gangi",
    Completed: "Lokið",
    Complete: "Lokið",
    Planned: "Fyrirhugað",
    tabDetails: {
      noActionAvailable: "Engar aðgerðir í boði",
      activeDealer: "Þessi þjónustuaðili er þegar skráður sem Virkur í A2D og ekki er hægt að eyða honum",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Verkefni",
      activityCompletionDate: "Lokadagur verks",
      activityCompletionDateFrom: "Lokadagsetning verkefnis frá",
      activityCompletionDateTo: "Lokadagsetning verkefnis til",
      activityStartDate: "Upphafsdagur verks",
      activityStartDateFrom: "Upphafsdagsetning verkefnis frá",
      activityStartDateTo: "Upphafsdagsetning verkefnis til",
      assignedTo: "Úthlutað til",
      assignee: "Úthlutunarhafi",
      benchmark: "Viðmið",
      city: "Borg",
      completedBy: "Lokið af ",
      completionBy: "Klárast fyrir",
      completionDate: "Lokadagsetning",
      counterMeasure: "Gagnráðstöfun",
      country: "Land",
      createdBy: "Stofnað af",
      dealer: "Þjónustuaðili",
      dealerNameAD: "Nafn þjónustuaðila (A2D)",
      dealerName: "Nafn söluaðila",
      definition: "Skilgreining",
      description: "Lýsing",
      dueDate: "Verklokadagur",
      endDate: "Lokadagur",
      firstVisit: "Fyrsta heimsókn",
      followup: "Eftirfylgni",
      franchise: "Sérleyfishafi",
      history: "Ferill",
      id: "Auðkenni",
      initial: "Upphafl.",
      kpi: "KPI",
      launch: "Hefja",
      mandatory: "Skilyrt",
      nmsc: "NMSC",
      problem: "Vandamál",
      publishedOn: "Birt",
      questionReference: "Tilvísun í spurningu",
      reference: "Tilvísun",
      reminderOn: "Áminning skráð",
      score: "Einkunn",
      startDate: "Upphafsdagur",
      status: "Staða",
      target: "Target",
      toolbox: "Toolbox",
      view: "Skoða",
      kpiResult: "KPI niðurstöður",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Staðsetning",
      ActionItemReport: "Verkþættir í vinnslu",
      certification: "Staða viðurkenningar",
      noOfToolbox: "Fjöldi kláraðra Toolboxa",
      program: "Forrit",
      certificationDate: "Dagsetning viðurkenningar",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Aðgerðaáætlun",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Aðgerðaáætlun",
      confirmDeleteMessage: "Viltu örugglega eyða völdum árangursmælikvarða?",
      confirmRetireMessage:
        "Staðfestu ef þú vilt gera valið KPI óvirkt þar sem það hefur þegar verið birt",
      errorRetireMsg:
        "Ekki er hægt að gera þennan árangursmælikvarða óvirkan þar sem hann er skilyrtur virkur árangursmælikvarði hjá TME",
      import: "Flytja inn",
      importKpiMessage: "Flyttu inn KPI af TME Master KPI listanum",
      kpi: "Árangursmælikvarðar (KPI)",
      library: "Gagnasafn",
      noActionAvailable: "Engar aðgerðir í boði",
      NoKPI: "Engin KPI tiltæk",
      publish: "Birta",
      questionnaire: "Spurningalisti",
      tmeKPIList: "TME Master KPI listi",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Heiti á Toolboxi",
      newToolboxName: "Nýtt nafn",
      deleteToolbox: "Eyða toolboxi?",
      programName: "Nafn forrits",
      ToolboxName_EN: "Enskt heiti á Toolboxi",
      deleteTool: "Ertu viss um að þú viljir eyða viðkomandi toolboxi?",
      selectToolbox: "Vinsamlega veldu toolbox til að eyða.",
      editToolBox: "Breyta toolboxi",
      addToolbox: "Bæta við toolboxi",
      toolbox: "Vinsamlega sláðu inn nafn fyrir þetta toolbox.",
      addActionPlan: "Add Aðgerðaáætlun",
      actionPlanItem: "Aðgerðaáætlun - Liður #",
      add: "Bæta við",
      addActivity: "Bæta við verki",
      additionalInformation: "Nánari upplýsingar",
      benchmark: "Viðmið",
      cancel: "Hætta við",
      createEditActivity: "Stofna/breyta aðgerð",
      createKPI: "Stofna KPI",
      delete: "Eyða",
      download: "Sækja",
      edit: "Breyta",
      editKPI: "Breyta KPI",
      keyPerformanceIndicator: "Árangursmælikvarði (KPI)",
      kpiDefinition: "KPI-skilgreining",
      mandatoryKpi: "Skilyrt KPI",
      save: "Vista",
      search: "Leit",
      unit: "Eining",
      update: "Uppfæra",
      validData: "Sláðu inn gild gögn",
      hide: "Fela",
      show: "Sýna",
      upload_dealer_network: "Hlaða upp þjónustuneti",
      upload_question: "Hlaða upp Spurningalista",
      upload_user: "Hlaða upp Notendaupplýsingum",
      upload_activity: "Hlaða upp Verkum",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Komið fram yfir lokadagsetningu",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Vinsamlega svaraðu öllum spurningunum áður en þú getur lokað viðkomandi verkefni.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Vinsamlegast settu inn upphaflegu lykiltölurnar, markmið og a.m.k. 1 mánaðarlega uppfærslu til að halda áfram.",
      initialTarget: "Vinsamlega sláðu inn fyrirliggjandi lykiltölur (KPI's) og markmið.",
      upload_error: "Villa kom upp við að hlaða upp þjónustuneti.",
      action_item_create_warning: "Hægt er að samtengja að hámarki fimm skráningar við einn verkþátt.",
      actionPlanStartDateValidation:
        "Lokadagsetning getur ekki verið á undan upphafsdagsetningu",
      actionsNotClosed:
        "Aðgerðir standa yfir í aðgerðaáætluninni. Lokaðu öllum aðgerðum áður en þú lokar verki",
      activityDuplicate:
        "Annað verk fyrir sama söluaðila og toolbox er þegar til",
      assigneeNotExist: "Valinn viðtakandi hefur ekki verið skráður í kerfinu",
      assigneeValidation: "Ógildur viðtakandi",
      atleastOnePublish:
        "Veldu að minnsta kosti einn árangursmælikvarða til að birta",
      cannotRetireActive:
        "Þetta er skilyrtur virkur árangursmælikvarði hjá TME sem ekki er hægt að gera óvirkan ",
      completionDate:
        "Lokadagur getur ekki verið á undan dagsetningu fyrstu heimsóknar",
      confirDeletePlannedActivity: "Viltu örugglega eyða þessu áætlaða verki?",
      DateformatValidation: "Dagsetningarform á að vera DD/MM/YYYY",
      DateValidation: "Upphafsdagsetning þarf að vera á undan lokadagsetningu",
      dealerValidation: "Ógildur söluaðili",
      deleteSelectedConfirm: "Viltu örugglega eyða völdum árangursmælikvarða?",
      duplicate: "Annar árangursmælikvarði með sama heiti og skilgreiningu er þegar til",
      modalMandatory: "Færðu gild gögn inn í alla skilyrta reiti",
      noPublishKpi: "The KPI's have not been published yet for the selected Toolbox",
      validSearchCriteria: "Sláðu inn gild leitarskilyrði",
      multiSelectPlaceHolder: "Sláðu að lágmarki 5 stafi",
      multiSelectPlaceHolderUser: "Sláðu að lágmarki 3 stafi",
      startDateValidation: "Upphafsdagsetning getur ekki verið hærri en lokadagsetning",
      fileSelectionErr: "Vinsamlegast veldur skránna fyrst",
      atleastOneQuestion: "Vinsamlegast veldu að lágmarki eina fylgispurningu til að hlaða inn.",
      atleastOneSubQues: "Vinsamlegast veldu amk eina undirspurningu til birtingar.",
      uploadErr: "Fjöldi skráa sem ekki tókst að hlaða upp:",
      uploadSuccess: "Þjónustuneti hefur verið hlaðið upp.",
      file_upload_success: "Skránum hefir verið hlaðið upp.",
      KPIinOngoingClosedActivity: "Þessi lykiltala er til staðar í einum, eða fleiri, virkum/óvirkum verkþáttum. Vinsamlegast kláraðu þá verkþætti áður breytingar eru gerðar á lykiltölunni.",
      Role_Error: "Uppsetning notanda er ókláruð, vinsamlega hafðu samband við KET stjórnanda hjá TÍS.",
      points_allocation_incorrect: "Röng úthlutun stiga, vinsamlegast leiðréttu",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Verk",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Heim",
      logout: "Útskráning",
      MasterDataUpload: "Hlaða upp aðalgögnum",
      Toolbox: "Verkfæri",
      welcome: "Velkomin",
      Admin: "Stjórnandi síðu"
    },
    questionnaire: {
      addSubQuestion: "Bæta við fylgispurningu",
      editSubQuestion: "Breyta fylgispurningu",
      subQuestionTitle: "Fylgispurning",
      additionalInfo: "Nánari upplýsingar",
      na: "Bæta við 'Á ekki við' sem svarmöguleika fyrir þessa spurningu",
      question: "Spurning",
      questionTitle: "Titill spurningar",
      why: "Af hverju?",
      addMainQuestion: "Bæta við meginspurningu",
      editMainQuestion: "Breyta meginspurningu",
      previousQuestion: "Fyrri spurning",
      nextQuestion: "Næsta spurning",
      what: "Hvað?",
      library: "Gagnasafn",
      subQuestionTable: {
        no: "Nei",
        subQuestion: "Fylgispurningar",
        status: "Staða",
        publishedDate: "Útgáfudagur",
        notApplicable: "Á ekki við"
      },
      links: {
        question_link_button: "Tengill",
        question_link_include_folder: "Undirmappa fylgir með",
        question_link_add_link: "Tengja skrá/r frá gagnasafni",
        question_link_view_folder: "Skoða gagnasafn"
      },
      mainQuestion: "Nýjar/uppfærðar meginspurningar:",
      subQuestion: "Nýjar/uppfærðar fylgispurningar:",
      retiredQuestion: "Óvirkar fylgispurningar:",
      libraries: "Nýjar/uppfærðar skrár í gagnasöfnum:",
      noFiles: "Engar skrár í boði."
    },
    library: {
      selectFile: "Vinsamlegast veldu minnst eina skrá",
      fileSelected: "Valdar skrár",
      publish_library_file: "Vinsamlegast velda minnst eina möppu til að birta",
      library_files_deleted: "Ertu viss um að þú viljir eyða skránni?",
      library_files_linked_deleted: "Ertu viss um að þú viljir eyða viðkomandi skrá ásamt þeim tengingum sem hún hefur í spurningalistann?",
      max_size_error: "Stærð valdra skráa er umfram leyfileg mörk",
      empty_file_desc: "Vinsamlegast færðu inn lýsingu á skránni",
      file_not_selected: "Vinsamlegast veldu fjölda skráa sem á að hlaða upp (hámark 1GB)",
      file_type_error: "Ógild skráartegund.",
      file_name_error: "Ógildur stafur í heiti skráar.",
      file_name_lenght_error: "Heiti skráar má vera að hámarki 50 stafir.",
      addFolder: "Bæta við möppu",
      editFolder: "Breyta möppu",
      currentFolder: "Heiti möppu",
      parentFolder: "Yfirheiti möppu",
      emptyFolderName: "Heiti möppu getur ekki verið tómt.",
      invalidFolderName: "Nafn möppu má ekki innihalda " / "",
      folder_created: "Mappan hefur verið búin til.",
      folder_updated: "Mappan hefur verið uppfærð.",
      folder_not_found: "Mappa finnst ekki",
      lib_invalid_toolbox: "Rangt Toolbox valið",
      lib_folder_already_exists: "Þessi mappa er þegar til.",
      lib_folder_deleted: "Möppunni hefur verið eytt.",
      library_files_published: "Skrárnar hafa verið virkjaðar.",
      addFile: "Bæta við skrá",
      editFile: "Uppfæra skrá",
      fileName: "Heiti skráar",
      upload: "Hlaða upp",
      desc: "Lýsing",
      folderCreationErr: "Stigveldi takmarkað við tvö stig.",
      subFolderWarning: "Vinsamlegast veldu undirmöppu til að bæta við skrá/m.",
      deleteWarning: "Vinsamlegast veldur möppu til að eyða.",
      beforeAfterNotSelected: "Vinsamlegast merktu við hvort sönnunargögn eru fyrir eða eftir Kaizen",
    },
    chapter: {
      chapter_exist: "Þessi kafli er þegar til.",
      chapter_saved: "Kafli vistaður",
      chapter_cannot_delete: "Ekki er hægt að eyða viðkomandi kafla þar sem fylgispurningar hafa verið gefnar út.",
      failed: "Mistókst !",
      chapter_deleted: "Kafla eytt",
      chapter_nonEmpty: "Vinsamlegast sláðu inn kaflaheiti.",
      addchapter: "Bæta við kafla",
      editChapter: "Breyta kafla",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Viðhengi",
      comments: "Ummæli",
      evidence: "Staðfestingargögn",
      evidences: "Staðfestingargögn",
      yes: "Já",
      no: "Nei",
      na: "N/A",
      uploadEvidence: "Hlaða upp staðfestingargögnum",
      fileUpload: "Vinsamlegast veldu fjölda skráa sem á að hlaða upp (hámark 1GB)",
      who: "Hver?",
      answer: "Svar",
      comment: "Comment",
      date: "Dagsetning",
      submit: "Senda inn",
      commentMsg: "Vinsamlega sláðu inn athugasemdir.",
      noCommentAvailable: "Engar athugasemdir fundust.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload: ",
      fileWithSameName: "Önnur skrá heitir þegar sama nafni",
      noEvidence: "Engin stuðningsgögn til staðar",
      createAction: "Stofna Verkþátt",
      chapterName: "Kaflaheiti",
      questionNo: "Spurning nr.",
      when: "Hvenær?",
      noRecords: "Engar skrár í boði.",
      kaizenPoints: "Kaizen atriði",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Spurning",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Eru sönnunargögn fyrir eða eftir Kaizen?",
      move:"Færa",
      moveMsg: "Flutningur gagna tókst",
      noToYesMsg:"Previous answer was NO"
    },
    WALK_AROUND_CHECK: "Sjónræn skoðun",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Málning og rétting",
      TSM_USED_CAR: "Notaðir bílar",
      value_chain: "Virðiskeðjan",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Tilkynningatafla",
      kpi_target_not_set: "Markmið fyrir lykiltölur eru ekki skráð",
      kpi_initial_not_set: "Upphafsmæling lykiltölu ekki skráð",
      kpi_monthly_not_set: "Mánaðarleg mæling á lykiltölu ekki skráð",
      kpi_below_target: "Lykiltölur undir markmiði til staðar",
      activation: "Gangsetning",
      users: "Notendur",
      nmscs: "NMSC's",
      activeRetailer: "Virkir þjónustuaðilar",
      certificationRate: "Tíðni viðurkenninga",
      reCertificationRate: "Tíðni endur-viðurkenninga",
      activityDue: "Verk sem eru komin að skilum",
      avgdays: "Meðaltal daga/Toolbox",
      overDueAct: "Verk sem komin eru yfir skil",
      actionItem: "Verkþættir",
      bussResult: "Árangur",
      toolboxImpl: "Toolbox innleiðing",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Nýlegar uppfærslur",
      rolloutTopNmsc: "Árangur innleiðingar - Efstu NMSC",
      bestPractice: "Best prectice tafla",
      availableKpi: "KPI í boði",
      selectedKpi: "Valdir KPI",
      dealerBestPractice: "Best Practice aðferðir þjónustuaðila",
      kpiList: "KPI listi",
      go: "Hefja",
      nodata: "Engin gögn til staðar",
      businessResultTop: "Árangur - Efstu þjónustuaðilar",
      businessResultNMSC: "Árangur - Efstu NMSC"


    },
    dealerCertification: {
      dealerCertification: "Viðurkenning þjónustuaðila",
      readyForCertification: "Tilbúið til viðurkenningar",
      certification: "Viðurkenning",
      submittedOn: "Skilað þann",
      certificateDate: "Viðurkenningardags.",
      reCertificateDate: "Endur-viðurkenningardags.",
      toolboxDetails: "Toolbox upplýsingar",
      approve: "Samþykkja",
      reject: "Hafna",
      pending: "Í vinnslu",
      region: "Svæði"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Auðkenning framkvæmdar",
      uploadedOn: "Hlaðið upp þann",
      lastUpdatedOn: "Síðasta uppfærsla",
      noDataTable: "No data to view",
      kpiUpload: "Hlaða upp lykiltölum",
      action: "Aðgerð",
      processed: "Afgreitt",
      error: "Villa",
      received: "Received",
      createActivity: "Stofna verkþætti",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Breyta notendum",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornafn",
      lastName: "Eftirnafn",
      emailId: "Netfang",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Fornafn",
      lastName: "Eftirnafn",
      emailId: "Netfang",
      languageCode: "Tungumál kóði",
      ACTIVE: "Active",
      INACTIVE: "Óvirkur",
      addUser: "Bæta við notanda",
      Assign: "Úthluta",
      List: "Listi",
      user: "Notandi",
      error_message: "An Error Occurred. Please try again later",
      role: "Hlutverk notanda",
      standardMessage: "No User Found",
      dealerCode: "Númer þjónustuaðila",
      a2d: "A2D UUID",
      britReady: "Söluaðili tók þátt í Brit Ready",
      britLive: "Söluaðili tók þátt í Brit Live",
      britJapan: "Söluáðili tók þátt í Brist Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Bæta við þjónustuaðila (frá A2D)",
      noDealer: "Engir þjónustuaðilar fundust",
      dealerUuid: "UUID kóði þjónustuaðila (A2D)",
      language: "Tungumál",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Vinsamlega sáðu inn gilt A2D UUID",
      invalidDealerName: "Vinsamlega sláðu inn gildan þjónustuaðila",
      invalidCity: "Vinsamlega sláðu inn gilda staðsetningu",
      invaliDealerCode: "Vinsamlega sláðu inn gilt númer þjónustuaðila",
      existingUuid: "A2D UUID kóði er þegar til staðar í gagnagrunni KET",
      updateUuid: "Uppfæra A2D UUID kóða",
      newUuid: "Nýr A2D UUID kóði",
    },
    networkManagement: {
      networkManagement: "Stjórnun þjónustunets",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsIS;
