import axios from "axios";
import { fetchQuestionContent, fetchSubQuestions, fetchChapterSequence, fetchNmscQuestionContent, fetchNmscSubQuestions, fetchNmscChapterSequence } from './tabAction.jsx';
import { toastr } from "react-redux-toastr";
import { toSetSelectedQuestionId } from "./mainQuestionAction";
import { getNmscSavedSubQuestions } from "./subQuestionAction";
var I18n = require("react-redux-i18n").I18n;
/* eslint-disable */
export function getQuestionSummary(packageProgramMappingId, chapterId, questionId, firstLoadFlag) {

  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get(
        "/chapter/" + packageProgramMappingId + "/chapterlist/",
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        //dispatch(setBlockingState(false));
        var nodeList = response.data;
        if (firstLoadFlag) {
          var chapt = nodeList[0];
          if (chapt) {
            var node = chapt.children;
            var question = node[0];
            if (question) {
              question.active = true;
              chapt.toggled = true;
              dispatch(fetchChapterSequence(question.mainQId))
              dispatch(fetchQuestionContent(question.mainQId))
              dispatch(fetchSubQuestions(question.mainQId))
              dispatch(toSetSelectedQuestionId(question.mainQId))
            }
            /*  else {
               chapt.active = true;
             } */
          }
          dispatch({
            type: "FIRST_LOAD",
            payload: true
          });

        }
        else {
          if (chapterId && (questionId && questionId != 0)) {
            for (var i = 0; i < nodeList.length; i++) {
              var chapt = nodeList[i];
              if (chapt.chapterId === chapterId) {
                var node = nodeList[i].children;
                for (var j = 0; j < node.length; j++) {
                  var question = node[j];
                  if (question.mainQId === questionId) {
                    question.active = true;
                  }
                  else {
                    question.active = false;
                  }
                }
                chapt.toggled = true;
              }
              else {
                chapt.toggled = false;
              }

            }

            dispatch(fetchChapterSequence(questionId))
            dispatch(fetchQuestionContent(questionId))
            dispatch(fetchSubQuestions(questionId))
            dispatch(toSetSelectedQuestionId(questionId))
          }
          else if (chapterId && !questionId) {
            for (var i = 0; i < nodeList.length; i++) {
              var node = nodeList[i];
              if (node.chapterId === chapterId) {
                let child=node.children;
                if(child.length>0){
                  child[0].active=true;
                  node.toggled=true;
                  dispatch(fetchChapterSequence(child[0].mainQId))
                  dispatch(fetchQuestionContent(child[0].mainQId))
                  dispatch(fetchSubQuestions(child[0].mainQId))
                  dispatch(toSetSelectedQuestionId(child[0].mainQId))
                }else{
                  node.active = true;
                }
                
              }
              else {
                node.active = false;
              }
            }
          }
          else if (questionId && questionId === 0) {
            dispatch(toSetSelectedQuestionId(null));
          }
          else {
            dispatch(fetchChapterSequence(null));
            dispatch(toSetSelectedQuestionId(null));
          }
        }

        dispatch({
          type: "QUESTION_SUMMARY",
          payload: nodeList
        });
        
        if(nodeList && nodeList.length===0)
        {
          dispatch(toSetSelectedQuestionId(null));
        }

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function saveChapter(chapterId, chapterName, packageProgramMappingId) {
  console.log("packageProgramMappingId",packageProgramMappingId);

  return function (dispatch) {
    //iF IT IS NEW CHAPTER set selectedQuestionId to null to refresh the right panel 
    if(chapterId===0){
      dispatch(toSetSelectedQuestionId(null));
    }
    //Reset chapter_saved before axios call
    dispatch({
      type: "CHAPTER_SAVED",
      payload: ""
    });
    //dispatch(setBlockingState(true));
    // if(questionnaireType===null){
      var URL = "/chapter/" + packageProgramMappingId  + "/"+ chapterName + "/" + chapterId + "/saveChapter";
    // }else{
    //   var URL = "/chapter/" + packageProgramMappingId + "/" + questionnaireType + "/"+ chapterName + "/"+ chapterId + "/saveChapter";
    // }
    axios
      .get(
        URL,
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        if (response.data === "chapter_already_exists") {
          toastr.error(I18n.t("chapter.chapter_exist"));

          //dispatch(setBlockingState(false));


        } else {
          //toastr.success("Chapter Saved");

          dispatch({
            type: "CHAPTER_SAVED",
            payload: "CHAPTER_SAVED"
          });

          dispatch({
            type: "CHAPTER_ID",
            payload: response.data
          });

          dispatch(getQuestionSummary(packageProgramMappingId, response.data));
          //dispatch(setBlockingState(false));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("Chapter.failed"));
      });
  };
}

export function deleteChapter(chapterId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete(
        "/chapter/" + chapterId + "/" + packageProgramMappingId + "/deleteChapter",
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {

        if (response.data !== chapterId) {
          // toastr.success(I18n.t("chapter.chapter_deleted"));
          dispatch(getQuestionSummary(packageProgramMappingId));
          //dispatch(setBlockingState(false));

        } else {
          toastr.error(I18n.t("chapter.chapter_cannot_delete"));
          //dispatch(setBlockingState(false));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("Chapter.failed"));
      });
  };
}

export function deleteAllQuestionary(programId, packageProgramMappingId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete(
        "/chapter/" + programId + "/" + packageProgramMappingId + "/deleteAllQuestionary",
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        if (response.data === "DELETE_SUCC") {
           toastr.success(I18n.t("chapter.DELETE_SUCC"));
          dispatch(getQuestionSummary(packageProgramMappingId));
          //dispatch(setBlockingState(false));

        } else {
          toastr.error(I18n.t("chapter.DELETE_ERR"));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("Chapter.failed"));
      });
  };
}

export function getChapterSequence(chapterId, questionId, nodeList) {
  return function (dispatch) {

    if (chapterId && questionId) {
      for (var i = 0; i < nodeList.length; i++) {
        var chapt = nodeList[i];
        if (chapt.chapterId === chapterId) {
          var node = nodeList[i].children;
          for (var j = 0; j < node.length; j++) {
            var question = node[j];
            if (question.mainQId === questionId) {
              question.active = true;

              dispatch(fetchChapterSequence(question.mainQId))
              dispatch(fetchQuestionContent(question.mainQId))
              dispatch(fetchSubQuestions(question.mainQId))

            }
            else {
              question.active = false;
            }


          }
          chapt.toggled = true;
        }
        else {
          chapt.toggled = false;
        }

      }
    }
    dispatch({
      type: "QUESTION_SUMMARY",
      payload: nodeList
    });

  };
}

export function getNmscChapterSequence(chapterId, questionId, nodeList,isNMSCAdminUser) {
  return function (dispatch) {

    if (chapterId && questionId) {
      for (var i = 0; i < nodeList.length; i++) {
        var chapt = nodeList[i];
        if (chapt.chapterId === chapterId) {
          var node = nodeList[i].children;
          for (var j = 0; j < node.length; j++) {
            var question = node[j];
            if (question.mainQId === questionId) {
              question.active = true;


              dispatch(fetchNmscChapterSequence(question.mainQId))
              dispatch(fetchNmscQuestionContent(question.mainQId,!isNMSCAdminUser))
              dispatch(fetchNmscSubQuestions(question.mainQId,!isNMSCAdminUser))

            }
            else {
              question.active = false;
            }


          }
          chapt.toggled = true;
        }
        else {
          chapt.toggled = false;
        }

      }
    }
    dispatch({
      type: "NMSC_QUESTION_SUMMARY",
      payload: nodeList
    });

  };
}

export function removeChapterReducer() {
  return function (dispatch) {
    //Reset chapter_saved before axios call
    dispatch({
      type: "CHAPTER_SAVED",
      payload: ""
    });
  }
}

export function getNmscQuestionSummary(packageProgramMappingId, nmsc, lang, chapterId, questionId, firstLoadFlag,isPublished) {
  return function (dispatch) {
    
    //dispatch(setBlockingState(true));
    if(isPublished)
    {
      var url= "/chapter/" + packageProgramMappingId + "/chapterListPublished/" + nmsc + "/" + lang;
    }
    else
    {
      var url= "/chapter/" + packageProgramMappingId + "/chapterList/" + nmsc + "/" + lang;
    }
 
   axios
      .get(
        url,
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(getNmscSavedSubQuestions(packageProgramMappingId, nmsc, lang));
        var nodeList = response.data.questionerDTOs;
        if (firstLoadFlag) {
          var chapt = nodeList[0];
          if (chapt) {
            var node = chapt.children;
            var question = node[0];
            if (question) {
              question.active = true;
              chapt.toggled = true;
              dispatch(fetchNmscChapterSequence(question.mainQId));
              dispatch(fetchNmscQuestionContent(question.mainQId,isPublished));
              dispatch(fetchNmscSubQuestions(question.mainQId,isPublished));
              dispatch(toSetSelectedQuestionId(question.mainQId));
            }
            /*  else {
               chapt.active = true;
             } */
          }
          dispatch({
            type: "FIRST_LOAD",
            payload: true
          });

        }
        else {
          if (chapterId && (questionId && questionId !== 0)) {
            for (var i = 0; i < nodeList.length; i++) {
              var chapt = nodeList[i];
              if (chapt.chapterId === chapterId) {
                var node = nodeList[i].children;
                for (var j = 0; j < node.length; j++) {
                  var question = node[j];
                  if (question.mainQId === questionId) {
                    question.active = true;
                  }
                  else {
                    question.active = false;
                  }
                }
                chapt.toggled = true;
              }
              else {
                chapt.toggled = false;
              }

            }

            dispatch(fetchNmscChapterSequence(questionId))
            dispatch(fetchNmscQuestionContent(questionId,isPublished))
            dispatch(fetchNmscSubQuestions(questionId,isPublished))
            dispatch(toSetSelectedQuestionId(questionId))
          }
          else if (chapterId && !questionId) {
            for (var i = 0; i < nodeList.length; i++) {
              var node = nodeList[i];
              if (node.chapterId === chapterId) {
                node.active = true;
              }
              else {
                node.active = false;
              }
            }
           // dispatch(toSetSelectedQuestionId(null));
          }
          else if (questionId && questionId === 0) {
            dispatch(toSetSelectedQuestionId(null));
          }
          else {
            dispatch(fetchNmscChapterSequence(null));
            dispatch(toSetSelectedQuestionId(null));
          }
        }

        dispatch({
          type: "QUESTION_SUMMARY",
          payload: nodeList
        });

        if(nodeList && nodeList.length===0)
        {
          dispatch(toSetSelectedQuestionId(null));
        }

        // dispatch({
        //   type: "QUESTION_SUMMARY",
        //   payload: response.data.questionerDTOs
        // });
        dispatch({
          type: "NMSC_TAB_LIST",
          payload: response.data.tabList
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function saveNmscChapter(chapterDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
      //iF IT IS NEW CHAPTER set selectedQuestionId to null to refresh the right panel 
      if(chapterDTO.chapterId===0){
        dispatch(toSetSelectedQuestionId(null));
      }
    //Reset chapter_saved before axios call
    dispatch({
      type: "CHAPTER_SAVED",
      payload: ""
    });
    axios
      .put("/chapter/saveNmscChapter", chapterDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        if (response.data === "chapter_already_exists") {
          toastr.error(I18n.t("chapter.chapter_exist"));
        } else {
          dispatch({
            type: "CHAPTER_SAVED",
            payload: "CHAPTER_SAVED"
          });
          dispatch({
            type: "CHAPTER_ID",
            payload: response.data
          });
          dispatch(getNmscQuestionSummary(chapterDTO.toolboxId, chapterDTO.nmscCode, chapterDTO.nmscLang, response.data));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function setFirstPageLoad() {
  return function (dispatch) {
    //Reset chapter_saved before axios call
    dispatch({
      type: "FIRST_LOAD",
      payload: false
    });
  }
}

export function deleteNmscChapter(chapterId, packageProgramMappingId,nmsc,lang) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .delete(
        "/chapter/" + chapterId + "/" + packageProgramMappingId + "/deleteNmscChapter",
        {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        }
      )
      .then(response => {
        if (response.data !== chapterId) {
          dispatch(getNmscQuestionSummary(packageProgramMappingId,nmsc,lang,null,null,true));
          //dispatch(setBlockingState(false));
        } else {
          toastr.error(I18n.t("chapter.chapter_cannot_delete"));
          //dispatch(setBlockingState(false));
        }
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error(I18n.t("Chapter.failed"));
      });
  };
}