import { Col, Container, Row } from "reactstrap";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "react-super-treeview/dist/style.css";
import { Treebeard } from 'react-treebeard';
import { getEvaluationChapters, getActivityDetails,getEvaluationVersion } from '../../actions/evaluationAction';
import { fetchKaizenPoints} from "../../actions/kaizenAction";
import { withTheme } from "../../themes/theming";
import ActionPlanModal from '../actionPlanModal';
import QuestionDetails from './components/questionDetails';
import "./index.css";
import {
    toSetSelectedQuestionId
} from "../../actions/mainQuestionAction";
import Style from './style';
import { Tab } from "react-bootstrap-tabs";
import { ProgressBar } from "react-bootstrap";
import { Scrollbars } from 'react-custom-scrollbars';
import KaizenPoints from "./components/kaizenPoints";
import AuditQuestionDetails from "./components/auditQuestionDetails";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {MDBIcon } from "mdbreact";
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery')

class Evaluation extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.handleNextPage = this.handleNextPage.bind(this);
        this.handlePrevPage = this.handlePrevPage.bind(this);
        this.handleCurrentPage = this.handleCurrentPage.bind(this);
        this.state = {
            collapse: false,
            modal: false,
            evaluationTree: [],
            modalFor: "",
            selectedNodeId: '',
            versionId: '',
            progressBarGreen: 0,
            progressBarRed: 0,
            progressBarYes: 0,
            progressBarNo: 0,
            progressBarNa: 0,
            previousPage: true,
            nextPage: true,
            toggleKaizen: false,
            toggleActionItem:false,
            activityDetails:{},
            modalActionPlan:false,
            blocking: false,
            /** States for filter search */
            isSearch: false, 
            selectedStatusList: [
                // { label: I18n.t('status.All'), value: "All" },
                { label: I18n.t('status.Complaint'), value: 1 },
                { label: I18n.t('status.Incomplete'), value: 2 },
                { label: I18n.t('status.nonComplaint'), value: -1 },
                { label: I18n.t('status.N/A'), value: 0 },
                { label: I18n.t('status.No Answer') },
            ],
            selectedStatus: [],
            selectedCriticalityList:[
               // {label:I18n.t('standardMessages.all'),value:"All"},
                {label:I18n.t('questionnaire.cr'),value:"Critical"},
                {label:I18n.t('questionnaire.nonCritical'),value:"Non-Critical"}
              ],
            selectedCriticality:[],
            /** */
        };
        this.onToggle = this.onToggle.bind(this);
        this.createActionItem = this.createActionItem.bind(this);
        this.openActionPlanTable = this.openActionPlanTable.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        $(".lib-node").unbind().on('click', function () {
            let element = $(this).find('.libraryToggleArrow')[0];
            if (element !== undefined) {
                if (element.classList.contains('rotate-true')) {
                    $(element).removeClass('rotate-true').addClass('rotate-false');
                } else {
                    $(element).removeClass('rotate-false').addClass('rotate-true');
                }
            }
        });
    }
    /** Function to handle critical filter */
    onSelectedCriticalityChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        this.setState({ selectedCriticality: selectedOption })
        this.setState({isSearch: false})
    }

    /** Function to handle status filter - complaint, inComplete, NA, etc*/
    onSelectedStatusChange = (selectedOption) => {
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
        })
        this.setState({ selectedStatus: selectedOption })
        this.setState({isSearch: false})
    }
    componentWillUnmount() {
        this.props.dispatch({
            type: "EVALUATION_CHAPTERS",
            payload: null
        });
    }
    componentDidMount() {

        let dealerCode = this.props.user.userMdl.nmsc;
        let dealerLang = this.props.user.userMdl.lang.toUpperCase();
        let toolboxId = this.props.toolbox;
        // console.log(dealerCode + " " + dealerLang + " " + toolboxId);
        this.setState({ activityid: this.props.activityId });
        this.props.dispatch(getEvaluationVersion(this.props.activityId, 'FIRST_AUDIT'));
        this.props.dispatch(getActivityDetails(this.props.activityId));
        this.props.dispatch(fetchKaizenPoints(this.props.activityId));

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.evaluation.evaluationVersion) {
            this.setState({ versionId: nextProps.evaluation.evaluationVersion });
        }
        if(nextProps.evaluation.activityDetails){
            this.setState({activityDetails:nextProps.evaluation.activityDetails});
        }
        if (nextProps.evaluation.evalautionProgressBar) {
            // console.log(nextProps.evaluation.evalautionProgressBar)
            var progressBar = nextProps.evaluation.evalautionProgressBar.split('|');
            var yes = progressBar[0] !== undefined ? parseInt(progressBar[0]) : 0;
            var no = progressBar[2] !== undefined ? parseInt(progressBar[2]) : 0;
            var na = progressBar[1] !== undefined ? parseInt(progressBar[1]) : 0;
            var unanswered = progressBar[3] !== undefined ? parseInt(progressBar[3]) : 0;
            var totalQuestions = progressBar[4] !== undefined ? parseInt(progressBar[4]) : 0;
            var answers = progressBar[5] !== undefined ? parseInt(progressBar[5]) : 0;

            var totalIncomplete = progressBar[6] !== undefined ? parseInt(progressBar[6]) : 0;
            var progressBarGreen = ((yes) / (totalQuestions-na)) * 100;
            var progressBarRed = 100 - progressBarGreen;
            // console.log(progressBarGreen, progressBarRed);
            this.setState({ activityCompletedAnswers: answers, activityCompletedQuestions: totalQuestions });
            this.setState({ progressBarGreen: progressBarGreen, progressBarRed: progressBarRed });
            this.setState({ progressBarYes: yes, progressBarNo: no, progressBarNa: na, progressBarTotalQuestions: totalQuestions });
        }
/*         if (nextProps.evaluation.evaluationActivityCompleted) {
            var evaluation = nextProps.evaluation.evaluationActivityCompleted.split('|');
            var questions = evaluation[1] !== undefined ? evaluation[1] : 0;
            var answers = evaluation[0] !== undefined ? evaluation[0] : 0;
            this.setState({ activityCompletedAnswers: answers, activityCompletedQuestions: questions });
        } */
        if (nextProps.evaluation.evaluationChapters && this.state.versionId !== 9999) {
            if (nextProps.evaluation.evaluationChapters && nextProps.evaluation.evaluationChapters.length > 0) {
                if (this.state.selectedNodeId === '') {
                    let selectedNodeId = nextProps.evaluation.evaluationChapters[0].children[0].mainQId;
                    this.setState({ selectedNodeId: selectedNodeId });
                    this.setState({ selectedChapterId: nextProps.evaluation.evaluationChapters[0].chapterId });
                }
            }
            this.setState({ evaluationTree: nextProps.evaluation.evaluationChapters });
        }
        this.handleCurrentPage();
        if (nextProps.evaluation.refreshRequired && this.state.versionId !== 9999) {
            let values = [];
            let dealerCode = this.props.user.userMdl.nmsc;
            let dealerLang = this.props.user.userMdl.lang.toUpperCase();
            let toolboxId = this.props.toolbox;

            if (nextProps.libraryReducer.folderId !== undefined) {
                values = nextProps.libraryReducer.folderId.split('|');
            }
            let id = values[0] !== undefined ? values[0] : 0;
            let name = values[1] !== undefined ? values[1] : '';
            // Get latest list of Library folders.
            if (this.state.cursor) {

                this.props.dispatch(getEvaluationChapters(nextProps.evaluation.evaluationVersion, this.props.activityId));
                this.setState({ selectedNodeId: id });
                var cursor = this.state.cursor;
                cursor.mainQId = id;
                cursor.name = name;
                if (this.state.modalFor === "Add") {
                    cursor.isParent = false;
                }
                this.setState({ cursor: cursor });
            }
            else {

                this.props.dispatch(getEvaluationChapters(nextProps.evaluation.evaluationVersion, this.props.activityId));
                this.setState({ selectedNodeId: id });
                var cursor = {};
                cursor.mainQId = id;
                cursor.name = name;
                cursor.isParent = true;
                this.setState({ cursor: cursor });
            }
            this.setState({ showDelete: false, showEdit: false })
            // alert('Refresh!!' + this.props.toolboxId);
            this.props.dispatch({
                type: "REFRESH_DONE",
                payload: ''
            });
        }

    }

    handleCurrentPage() {
        if (this.state.selectedNodeId && this.state.evaluationTree && Array.isArray(this.state.evaluationTree)) {
            this.setState({ previousPage: true, nextPage: true });
            let chapterQ = [];
            let questionIndex = 0;
            this.state.evaluationTree.map(que => {
                que.children.map(child => {
                    if (child.mainQId === this.state.selectedNodeId) {
                        chapterQ = [...que.children];
                        questionIndex = que.children.indexOf(child);
                    }
                });
            });
            if (questionIndex === 0 && questionIndex === chapterQ.length - 1) {
                this.setState({ previousPage: false, nextPage: false });
            } else if (questionIndex === chapterQ.length - 1) {
                this.setState({ nextPage: false, previousPage: true });
            } else if (questionIndex === 0 && questionIndex !== chapterQ.length - 1) {
                this.setState({ previousPage: false, nextPage: true });
            } else {
                this.setState({ previousPage: true, nextPage: true });
            }
        }
    }

    handleNextPage() {
        let chapterQ = [];
        let questionIndex = 0;
        let selectedChapterId = 0;
        this.state.evaluationTree.map(que => {
            que.children.map(child => {
                if (child.mainQId === this.state.selectedNodeId) {
                    chapterQ = [...que.children];
                    questionIndex = que.children.indexOf(child);
                    selectedChapterId=que.chapterId;
                }
            });
        });
        questionIndex = questionIndex + 1;
        this.setState({ selectedNodeId: chapterQ[questionIndex].mainQId, selectedChapterId:selectedChapterId});
        this.props.dispatch(
            toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
        );
        this.props.dispatch(getEvaluationChapters(this.state.versionId, this.props.activityId,
            selectedChapterId,
            chapterQ[questionIndex].mainQId));
            this.scrollToTop();

    }
    handlePrevPage() {
        let chapterQ = [];
        let questionIndex = 0;
        let selectedChapterId = 0;
        this.state.evaluationTree.map(que => {
            que.children.map(child => {
                if (child.mainQId === this.state.selectedNodeId) {
                    chapterQ = [...que.children];
                    questionIndex = que.children.indexOf(child);
                    selectedChapterId=que.chapterId;
                }
            });
        });
        questionIndex = questionIndex - 1;

        this.setState({ selectedNodeId: chapterQ[questionIndex].mainQId,selectedChapterId:selectedChapterId });
        this.props.dispatch(
            toSetSelectedQuestionId(chapterQ[questionIndex].mainQId)
        );
        this.props.dispatch(getEvaluationChapters(this.state.versionId, this.props.activityId,
            selectedChapterId,
            chapterQ[questionIndex].mainQId));
        this.scrollToTop();
    }

    createActionItem=(selectedQuestions)=>{
        var selectedRow = { };
        var description ="";
        var questionReference="";
        selectedQuestions.map((question)=>{
            if(description!==""){
                description=description+"\n"+question.comments;
            }else{
                description=question.comments;
            }
            if(questionReference!==""){
                questionReference=questionReference+","+question.questionNo;
            }else{
                questionReference=question.questionNo;
            }
        })
        selectedRow["activityId"] = this.props.activityId;
        selectedRow["actionPlanId"] = 0;
        selectedRow["actionPlanDisplayId"] = 0;
        selectedRow["description"] = description;
        selectedRow["counterMeasure"] = "";
        selectedRow["questionReference"] = questionReference;
        selectedRow["userId"] = "";
        selectedRow["status"] = "Open";
        selectedRow["followUp"] = "";
        selectedRow["assignee"] = "";
        selectedRow["endDate"] = "";
        selectedRow["startDate"] = "";
        selectedRow["userMdl"] = this.props.user.userMdl;
        selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
        selectedRow["langCode"] = this.props.user.userMdl.lang;
        selectedRow["userId"] = this.props.user.userMdl.username;
        this.setState({selectedToCreateAction:selectedRow});
        this.toggleKaizen();
        this.toggleActionItem();

    }
    deactiveNode = (currentNode) => {
        var flag = false;
        var nodeList = this.state.evaluationTree;
        for (var i = 0; i < nodeList.length; i++) {
            var parn = nodeList[i];
            var node = nodeList[i].children;
            if (node) {
                for (var j = 0; j < node.length; j++) {
                    var child = node[j];
                    if (child.mainQId !== currentNode.mainQId) {
                        child.active = false;
                    }
                    else {
                        flag = true
                    }
                }
            }
            if (!flag) {
                // parn.toggled = false;
                parn.active = false;
            }

        }
        this.setState({ evaluationTree: nodeList })
    }
    onToggle(node, toggled) {
        this.deactiveNode(node);
        //Child Node(Question Title)
        if (this.state.cursor) { this.state.cursor.active = false; }
        if (node.mainQId) {
            this.setState({ selectedNodeId: node.mainQId });
            node.active = true;
        }
        //Parent Node (Chapter)
        else {
            this.setState({ selectedNodeId: node.children[0].mainQId });
            node.children[0].active = true;
            this.setState({ selectedChapterId: node.chapterId });
        }
        if (node.children && node.children.length > 0) { node.toggled = toggled; }
        this.setState({ cursor: node });

        
        this.props.dispatch({
            type: "EVALUATION_ID",
            payload: null
        });
    }




    traverse(node, path, list) {
        let that = this;
        if (!path) {
            path = [];
        }
        let obj = {
            id: node.mainQId,
            name: node.name
        };
        path.push(obj);
        list.push(path);
        if (node.children) {
            node.children.forEach(function (item) {
                let newPath = [];
                newPath.push(path);
                that.traverse(item, newPath, list);
            });
        }
    }
    togglePane = () => {
        $(".rightPanel").hide();
        $("#libSideNav").toggle("fast", function () {
            let width = $(".rightPanel").width() / $(".rightPanel").parent().width() * 100;
            if (width > 90) {
                $(".rightPanel").css({ "width": "72%" });
            } else {
                $(".rightPanel").css({ "width": "97%" });
            }
            $(".rightPanel").show();
        });
    }

    toggleKaizen = () => {
        this.setState({ toggleKaizen: !this.state.toggleKaizen })
    }
    toggleActionItem = () => {
        this.setState({ toggleActionItem: !this.state.toggleActionItem })
    }
    openActionPlanTable=()=>{
        // this.setState({modalActionPlan:!this.state.modalActionPlan});  
        // this.props.selectTab(3);    
    }

    scrollToTop = () => window.scrollTo(0, 0);


    render() {
        if(this.props.theme.name==="lexus" && $('div.toyota')){
            $(".toggleTreebeard").removeClass("toyota");
            $(".toggleTreebeard").addClass("lexus");
            $(".toggleTreebeard").attr("src","/images/upArrow-lexus.png");
        }
        else if(this.props.theme.name==="toyota" && $('div.lexus')){
            $(".toggleTreebeard").removeClass("lexus");
            $(".toggleTreebeard").addClass("toyota");
            $(".toggleTreebeard").attr("src","/images/T_Artboard 21mdpi.png");
        }else {
            //..
        }
        let userLang = this.props.user.userMdl.userLang;
        let userRole = this.props.user.userMdl.userRole;
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'ACTIVITY_EVALUATION')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'ACTIVITY_EVALUATION')[0].accessType : "";
        }


        const dynamicKPITab = this.props.user.userMdl.userLangList.map(
            (obj, index) => {
                return <Tab label={obj.country + " / " + obj.language} key={index} />;
            }
        );

        dynamicKPITab.unshift(<Tab label="TME Master" key='tmeMaster' />);
        return (
            <div>
                {(accessType === "R" || accessType === "R/W") && this.state.versionId !== 9999 ?
                    <Container fluid
                        className="ml-0 p-0 libraryContainer" >
                        {this.state.evaluationTree.length > 0 ?
                            <div>
                                <Row style={{ minHeight: "486px", paddingLeft: '5px' }}>
                                    <div id="libSideNav" style={{ paddingLeft: '4px', borderRight: '1px solid lightGray', width: '24%' }}>

                                        <Row className="mb-1 bottomBorder canShrink" style={{ height: '30px' }}>
                                            <Col className="d-flex p-1" style={{ display: 'inherit', padding: '5px' }}>
                                                <div className="answerCountsTitle">{I18n.t("others.completed")}:</div>
                                                <div className="answerCountsAns">({this.state.activityCompletedAnswers}</div>
                                                <div className="answerCountsTotal">/{this.state.activityCompletedQuestions})</div>
                                            </Col>
                                        </Row>
                                        <Row className="treeBeardContainter evaluationTree">
                                            <Scrollbars universal
                                                thumbSize={300}
                                                hideTracksWhenNotNeeded={true}
                                                //autoHide
                                                //autoHideTimeout={1000}
                                                //autoHideDuration={200}
                                                renderThumbVertical={({ style, ...props }) =>
                                                    <div {...props} style={{ ...style, backgroundColor: this.props.theme.primaryColor, width: "4px", opacity: "0.7", cursor: "pointer" }} />
                                                }>

                                                <Treebeard
                                                    data={this.state.evaluationTree}
                                                    onToggle={this.onToggle}
                                                    style={Style} 
                                                    decorators={libraryDecorator}
                                                />
                                            </Scrollbars>
                                        </Row>

                                    </div>
                                    <div className="pl-0 pr-0 thickRightBorder">
                                        <div className="pl-0 pr-0 d-flex" style={{ height: '100%', backgroundColor: 'white', cursor: 'pointer' }} onClick={() => this.togglePane()}>
                                            <img id="toggle" src={"/images/collapsebtn-"+this.props.theme.name+".png"} className="align-self-center" style={{ height: "50px", cursor: "pointer" }} alt=""/>
                                        </div>
                                    </div>
                                    <div className="pl-1 m-1 rightPanel dealerRightPanel">
                                        <Row className="p-1" style={{ minHeight: '30px' }}>
                                        {this.props?.user?.userMdl?.activeProgram === "NAS" ? (
                                            <Col lg="2" md="2" sm="2" xs="2">
                                                <ReactMultiSelectCheckboxes
                                                    value={this.state.selectedCriticality}
                                                    options={this.state.selectedCriticalityList}
                                                    onChange={this.onSelectedCriticalityChange}
                                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                                />
                                            </Col>
                                        ): <Col lg="2" md="2" sm="2" xs="2"></Col>}
                                        {this.props?.user?.userMdl?.activeProgram === "NAS" ? (<Col  lg="2" md="2" sm="2" xs="2">
                                            <ReactMultiSelectCheckboxes
                                                value={this.state.selectedStatus}
                                                options={this.state.selectedStatusList}
                                                onChange={this.onSelectedStatusChange}
                                                placeholderButtonLabel={I18n.t('standardMessages.all')}
                                            />
                                            </Col>): <Col lg="2" md="2" sm="2" xs="2"></Col>}
                                            {this.props?.user?.userMdl?.activeProgram === "NAS" ? (<Col  lg="2" md="2" sm="2" xs="2">
                                            <a 
                                                className={this.state.isSearch ? "searchIcon-toyota" : "searchIcon-toyota-default"}
                                                onClick={() => this.setState({ isSearch: true })}>
                                                <MDBIcon icon={"search"} fixed size="2x" title={I18n.t("Search") } 
                                                /> 
                                            </a>
                                            </Col>) : <Col lg="2" md="2" sm="2" xs="2"></Col>}
                                            <Col lg="3" md="3" sm="3" xs="3">
                                                <ProgressBar>
                                                    <ProgressBar stripped animated variant="success" now={this.state.progressBarGreen} key={1} />
                                                    <ProgressBar stripped animated variant="danger" now={this.state.progressBarRed} key={2} />
                                                </ProgressBar>
												<div className={"progressLabel"}>{Math.round(this.state.progressBarGreen)+"%"}</div>
                                            </Col>
                                            <Col lg="2" md="2" sm="5" xs="5" className="d-flex justify-content-end">
                                            {this.props.user.userMdl.activeProgram =="NAS" ? 
                                            ((this.props.activityDetailsData && this.props.activityDetailsData.firstAudit ===  "PASSED") ? <h4 className="resultShowPass"> PASSED</h4> : null || 
                                           (this.props.activityDetailsData && this.props.activityDetailsData.firstAudit === "FAILED") ? <h4 className="resultShowFail">FAILED</h4> : null
                                                ): null}

                                            {this.props.user.userMdl.activeProgram!=="NAS" ? 
                                                <button className={"button-addfile-" + this.props.theme.name}  style={{ fontSize: "13px" }}
                                                    onClick={() => this.toggleKaizen()}>{I18n.t("evaluation.kaizenPoints")} </button>:" "}
                                                <div className={this.props?.kaizenPoints?.kaizenPointsData?.length!==0 ? "kaizenBlink-" + this.props?.theme?.name : "kaizenBlinkStop-" + this.props.theme.name}/>    
                                            </Col>
                                        </Row>
                                     
                                        <Row className="questionContent">
                                           {console.log("firstAudit",this.props.firstAudit)}
                                        {this.props.user.userMdl.activeProgram==="NAS" ?   
                                          <AuditQuestionDetails
                                                toolboxId={this.props.toolboxId}
                                                selectedNodeId={this.state.selectedNodeId}
                                                selectedChapterId={this.state.selectedChapterId}
                                                versionId={this.state.versionId}
                                                activityId={this.props.activityId} activityStatus={this.props.activityStatus}
                                                totalAnswerCount={this.state.activityCompletedAnswers}
                                                totalQuestionCount={this.state.activityCompletedQuestions}
                                                firstAudit = {this.props.firstAudit}
                                                isFromFirstAudit = {true}
                                                selectedCriticality={this.state.selectedCriticality}
                                                selectedStatus={this.state.selectedStatus}
                                                isSearch={this.state.isSearch}
                                            /> :  
                                              <QuestionDetails
                                            toolboxId={this.props.toolboxId}
                                            selectedNodeId={this.state.selectedNodeId}
                                            selectedChapterId={this.state.selectedChapterId}
                                            versionId={this.state.versionId}
                                            activityId={this.props.activityId} activityStatus={this.props.activityStatus}
                                        />}
                                        </Row>

                                        <Row>
                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                {this.state.nextPage ? (
                                                    <a>
                                                        <div
                                                            title={I18n.t("questionnaire.nextQuestion")}
                                                            className={"nextQuestion-"+this.props.theme.name}
                                                            onClick={this.handleNextPage}
                                                        />
                                                    </a>
                                                ) : (
                                                        <a>
                                                            <div
                                                                title={I18n.t("questionnaire.nextQuestion")}
                                                                className={"nextQuestion-disabled-"+this.props.theme.name}
                                                            />
                                                        </a>
                                                    )}
                                                {this.state.previousPage ? (
                                                    <a>
                                                        <div
                                                            onClick={this.handlePrevPage}
                                                            title={I18n.t("questionnaire.previousQuestion")}
                                                            className={"prevQuestion-"+this.props.theme.name}
                                                        />
                                                    </a>
                                                ) : (
                                                        <a>
                                                            <div
                                                                title={I18n.t("questionnaire.previousQuestion")}
                                                                className={"prevQuestion-disabled-"+this.props.theme.name}
                                                            />
                                                        </a>
                                                    )}
                                            </div>
                                        </Row>
                                    </div>
                                    
                                </Row>
                            </div>
                            : <div style={{ textAlign: "center" }}>{I18n.t('standardMessages.noQuestion')}</div>}

                    </Container> : null}
                {this.state.toggleKaizen ? <KaizenPoints createActionItem={this.createActionItem} activityStatus={this.props.activityStatus} activityId={this.props.activityId} toggleKaizen={this.toggleKaizen} /> : ""}
                {this.state.toggleActionItem ?  <ActionPlanModal
                modal={this.state.toggleActionItem}
                toggle={this.toggleActionItem}
                modalFor="Create"
                selectedRow={this.state.selectedToCreateAction}
                activity={this.state.activityDetails}
                evaluationCreateActionItemOpen={true}
                evaluationCreateActionItem={this.openActionPlanTable}
                />:null}
            </div>
        );
    }
}
const libraryDecorator = {
    Header: ({ node, style }) => {
        const iconClass = node && node.mainQId !== 0 ? 'fa fa-folder' : 'fa fa-tasks-alt';
        const divStyle = node && node.children ? { display: 'inline-block', width: '90%',height:'100%',verticalAlign:'text-top',marginLeft:'13px' } : { marginLeft: '2rem' };
        var activeClass = node.active ? "activeQuestion" : "";
        var answerFont = node && node.mainQId !== 0 ? 
        node.no > 0 ? 'redFont' : node.yes===node.question ? 'greenFont' : 'blackFont' :'blackFont';
        return (
            <div style={divStyle}>
                {node.children ?
                    <div><div  title={node.name} className={activeClass} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",wordBreak: 'break-word',	maxWidth: '85%' , display: 'inline-block' }}>
                        
                        {node.seqNumber}. {node.name+"  "}&nbsp;&nbsp;&nbsp;&nbsp;</div>
                        <span className="leftAnswerCountsTotal">(</span><span className="leftAnswerCountsAns">{node.answer}</span><span className="leftAnswerCountsTotal">/{node.question})&nbsp;&nbsp;</span>
                      
                       <span className="leftAnswerCountsTotal1">{Math.round(((node.yes) / ((node.question)-(node.na)))*100)+"%"}
                        </span>

                        </div> : <div> <span  title={node.name} className={activeClass} style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",wordBreak: 'break-word',	maxWidth: '87%' , display: 'inline-block'}}>
                        {node.name+"  "}&nbsp;&nbsp;&nbsp;&nbsp;</span><span className="leftAnswerCountsTotal">(</span><span className={"leftAnswerCountsAns "+answerFont}>{node.answer}</span><span className="leftAnswerCountsTotal">/{node.question})</span>
                    </div>}

            </div>
        );
    },
    Toggle: ({ node, style }) => {

        return (
            <div style={{display:"inline-block",width:"5%"}}>
                <img src={"/images/T_Artboard 21mdpi.png"} height={'25px'} width={'25px'} className="toggleTreebeard toyota p-1 rotate-true libraryToggleArrow" alt=""/>
            </div>
        );
    },
    Loading: (props) => {
        return (
            <div style={props.style}>
                loading...
            </div>
        );
    },
    Container: (props) => {
        return (
            <div className="pl-2 lib-node evalTree" onClick={props.onClick} style={{ paddingRight: "13px" }}>
                {props.node.children && props.node.children.length > 0 ?
                    <props.decorators.Toggle /> : <div />}
                <props.decorators.Header node={props.node}  />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        libraryReducer: state.libraryReducer,
        evaluation: state.evaluationReducer,
        kaizenPoints: state.kaizenReducer,
        activityDetailsData: state.evaluationReducer.activityDetails


    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(Evaluation))
);
