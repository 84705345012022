import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter} from "mdbreact";
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import { toastr } from "react-redux-toastr";
import '../index.css';
import { fetchSubQueInfo } from '../../../actions/activityAction.jsx';
import { fetchKaizenPoints, downloadKaizenPoints } from "../../../actions/kaizenAction";
import QuestionInfo from '../../questionInfo';
var I18n = require("react-redux-i18n").I18n;

class kaizenPoints extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            kaizenPoints: [],
            selectedToDownload: [],
            selectedToCreateAction:[],
            activityDetails:{},
            modalAction:true,
            isInternalUser:false,
            quesModal:false,

        }
        this.createActionItem = this.createActionItem.bind(this);
        this.toggleAction = this.toggleAction.bind(this);

    }
    componentDidMount() {
    
    }


    componentWillMount() {
        // console.log(this.props.activityStatus);
        if(this.props.activityStatus!=="Closed" && this.props.activityStatus!=="Completed" && this.props.activityStatus!=="Rejected")
        {
            this.setState({actionItemToBeCreated:true});
        }else{
            this.setState({actionItemToBeCreated:false});
        }
        this.props.dispatch(fetchKaizenPoints(this.props.activityId));
        this.props.dispatch(fetchSubQueInfo(this.props.activityId))

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.kaizenPoints.kaizenPointsData) {
            this.setState({ kaizenPoints: nextProps.kaizenPoints.kaizenPointsData })
        }

    }

    downloadPdf = () => {
        this.props.dispatch(downloadKaizenPoints(this.props.activityId, this.props.user.userMdl.lang, this.props.theme.name, this.state.selectedToDownload,this.props.user.userMdl.nmsc))
    }
    toggleAction = () => {
        this.setState({
            modalAction: !this.state.modalAction,
            modalFor: "Create"
        });
    }
    createActionItem=()=>{
        if(this.state.selectedToCreateAction.length >0 && this.state.selectedToCreateAction.length <=5 ){
            this.props.createActionItem(this.state.selectedToCreateAction)
        }else{
            if(this.state.selectedToCreateAction.length <=0){
                //..
            }else{
                toastr.error(I18n.t("toastr.action_item_create_warning"));
            }
        }
        
    }
    quesRefFormatterNew=(cell, row)=>{
        let questionRef=cell.split(',')
        let QuesList=this.props.subQuesInfo
        // console.log("QuesList",QuesList)
        let quesInfo=[]
        let quesInfoDesc=''
         quesInfo=questionRef.map(function(x,i){
            if(QuesList!==undefined && QuesList[x]){
                return QuesList[x].subQuesDescription
            }
        })
        // console.log("quesInfo",quesInfo)
        if(quesInfo.length>0){
            quesInfoDesc = quesInfo.toString()
        }
        return (
            <div >
                {quesInfoDesc}
            </div>
        );
    }
    // quesRefFormatter=(cell, row)=>{    
    //     let questionRef=cell.split(",")
    //     return (
    //         <div className="btn-toolbar  queslink" style={{ display:'flex',textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap"}} onClick={() => this.toggleQuesInfo(questionRef)}> 
    //          <u> {cell}</u>  
    //         </div>
    //     );

    // }
    // toggleQuesInfo=action=>{
       
    //     let QuesList=this.props.subQuesInfo
    //     let quesInfo=[]
    //      quesInfo=action.map(function(x,i){
    //         if(QuesList[x]){
    //             return {ques:x,info:QuesList[x].subQuesDescription}
    //         }else{
    //             return {ques:x,info:""}


    //         }
    //     })

    //     this.setState({
    //         quesModal: !this.state.quesModal,
    //         modalFor: quesInfo

    //     });
    // }


    preventDefault() { }
    render() {
       
        var isInternalUser=false;
        // console.log(this.props.user.userMdl.accessControl);
        if(!this.props.user.userMdl.accessControlList.filter(obj => obj.function === 'ACTIVITY_EVALUATION_ACTION')[0]){
            isInternalUser=true;
        }
        const columns = [{
            dataField: 'chapterName',
            text: I18n.t('evaluation.chapterName'),
            title: true,
            style: { width: "15%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap",maxWidth: "0", textTransform: "uppercase" },
            headerStyle: { width: "14.9%", textAlign: "left", paddingLeft: "18px" },
            sort:true,
        }, {
            dataField: 'questionNo',
            text: I18n.t('evaluation.no'),
            title: true,
            style: { width: "6.8%", textAlign: "left" },
            headerStyle: { width: "5%", textAlign: "left",paddingLeft:"12px"},
            sort:true,
        }, 
        {
            dataField: 'questionNo',
            text: I18n.t('evaluation.question'),
            title: true,
            style: { width: "25%", textAlign: "left"},
            formatter: this.quesRefFormatterNew,
            headerStyle: { width: "19%", textAlign: "left",paddingLeft:"33px" },
        },
        {
            dataField: 'who',
            text: I18n.t('evaluation.who'),
            style: { width: "15%", textAlign: "left", wordWrap: "break-word",paddingLeft:"27px" },
            headerStyle: { width: "17.2%", textAlign: "left",paddingLeft:"120px" },
            sort:true,
        }, {
            dataField: 'when',
            text: I18n.t('evaluation.when'),
            style: { width: "15.5%", textAlign: "left",paddingLeft:"27px" },
            headerStyle: {  width: "14.6%", textAlign: "left",paddingLeft:"81px" },
            sort:true,
        }, {
            dataField: 'comments',
            text: I18n.t('evaluation.comments'),
            style: { width: "23.5%", textAlign: "left", wordWrap: "break-word",wordBreak:"break-all" },
            headerStyle: { width: "26.2%", textAlign: "left",paddingLeft:"58px" }
        }];
        <BootstrapTable keyField='chapterName' data={kaizenPoints} columns={ columns } />

        const defaultSorted = [{
            dataField: 'chapterName', 
            order: 'desc' 
          }];
        const rowEvents = {
            onClick: (e, row, rowIndex) => {        
                if (e.target.type === "checkbox" || e.target.cellIndex === 0) {
                    e.target.checked = true;
                    var index = this.state.selectedToDownload.indexOf(row.evaluationId);
                    if (index > -1) {
                        this.state.selectedToDownload.splice(index, 1);
                        this.state.selectedToCreateAction.splice(index,1)
                    } else {
                        this.state.selectedToDownload.push(row.evaluationId);
                        this.state.selectedToCreateAction.push(row);
                    }

                    this.setState({ selectedToDownload: this.state.selectedToDownload });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedToDownload,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                let selectedToCreateAction = [];
                rows.forEach((row) => {
                    selectedRows.push(row.evaluationId);
                    selectedToCreateAction.push(row);
                });
                if (isSelect && selectedRows.length > 0) {
                    this.setState({ selectedToDownload: selectedRows,selectedToCreateAction:selectedToCreateAction });
                    return selectedRows;
                }
                else {
                    this.setState({ selectedToDownload: [],selectedToCreateAction:[] });
                }
            }
        };

        return (
            <div className="kaizenModelMain"> 
            <Modal centered
                className={"kaizenPointsModal kaizenPointsModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggleKaizen()}>
                    {I18n.t("evaluation.kaizenPoints")}
                </ModalHeader>
                <ModalBody>
                    <BootstrapTable
                        keyField='evaluationId'
                        data={this.state.kaizenPoints}
                        columns={columns}
                        selectRow={selectRow}
                        hover
                        condensed
                        rowEvents={rowEvents}
                        noDataIndication={I18n.t('evaluation.noRecords')}
                    />
                    
                </ModalBody>
                <ModalFooter>
                {this.state.selectedToDownload.length > 0 && !isInternalUser && this.state.actionItemToBeCreated ?
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={()=>{this.createActionItem()}}
                    >
                        {I18n.t("evaluation.createAction")}
                    </button>: <button
                            className={"button-" + this.props.theme.name}
                            disabled
                        >
                            {I18n.t("evaluation.createAction")}
                        </button>}
                    {this.state.selectedToDownload.length > 0 ? <button
                        className={"button-" + this.props.theme.name}
                        onClick={this.downloadPdf}
                    >
                        {I18n.t("tmeAdminMaster.download")}
                    </button> :
                        <button
                            className={"button-" + this.props.theme.name}
                            disabled
                        >
                            {I18n.t("tmeAdminMaster.download")}
                        </button>}


                </ModalFooter>
            </Modal >
             
             {this.state.quesModal === true ?
                <QuestionInfo
                    modal={this.state.quesModal}
                    toggle={this.toggleQuesInfo}
                    modalFor={this.state.modalFor}

                /> : null}
            </div>
            
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        kaizenPoints: state.kaizenReducer,
        evaluation:state.evaluationReducer,
        subQuesInfo:state.activityReducer.subQuesInfo

    };
};
export default withRouter(connect(mapStateToProps)(withTheme(kaizenPoints)));


