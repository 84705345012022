import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../../themes/theming";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import Select from "react-select";

import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { toastr } from "react-redux-toastr";
import {MDBIcon } from "mdbreact";
import * as AppConst from '../../../AppConstant';
import "./../index.css";
import {fetchKpiEntryFilters,searchBulkKpiDetails} from '../../../actions/bulkCeaKpiEntryAction';
// import Autosuggest from 'react-autosuggest';
var I18n = require("react-redux-i18n").I18n;


class SearchKpiEntry extends React.Component{
    constructor(props){
        super(props);
        this.state ={ 
            nmscCodeList : [],
            brandList : [],
            subPillarList : [],
            activityStatusList : [],
            kpiList : [],
            businessFunctionList : [],
            selectedNmscCodeList : [],
            selectedBrandList : [],
            selectedSubPillarList : [],
            selectedActivityStatusList : [],
            selectedKpiList : [],
            selectedBusinessFunction : [],   
            businessOwnerBusinessfuncList:[],    
            yearList:[],
            selectedEvaluationYear:[],
            searchDto: {
                nmscCodeList : [] ,
                brandList:[],
                kpiList:[],
                //here businessFunction = ProgramPackageMappingId
                businessFunction:0,
                subPillarList:[],
                activityStatusList:[],
                evaluationYear: this.props.user.userMdl.ceaDefaultYears.value,
                activeProgramId : this.props.user.userMdl.activeProgramID  ,
                userNmscCode : this.props.user.userMdl.nmsc,
                userId:this.props.user.userMdl.username,
                ceaAdmin : false,
                netAdmin : false,
                ucUser : false,
                businessOwner : false    
            }
        }
    }

    componentWillMount(){

        let role =[];

        role = this.props.user.userMdl.roles
        let searchDto = {...this.state.searchDto}
        if(role.includes(AppConst.CEA_ROLES.KET_CEA_Admin)){
            searchDto["ceaAdmin"] = true
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            searchDto["netAdmin"] = true
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            searchDto["ucUser"] = true
        }
        if(role.includes(AppConst.CEA_ROLES.KET_CEA_BO) || role.includes(AppConst.NET_ROLES.KET_NET_BO)){
            searchDto["businessOwner"] = true
        }
        searchDto["evaluationYear"] = this.props.user.userMdl.ceaDefaultYears.value;
        this.setState({searchDto})

        this.props.dispatch(fetchKpiEntryFilters(searchDto,"nmscCode"))
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"activityStatus"))
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"businessFunction"))
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))
       
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }
    } 

    componentDidUpdate(prevProps, prevState) {

        let searchDto = {...this.state.searchDto}
        console.log(this.props.kpiEntryFilters)
        if(prevProps.kpiEntryFilters.nmscCodeList!== this.props.kpiEntryFilters.nmscCodeList && this.props.kpiEntryFilters.nmscCodeList!== undefined ){
            if(this.props.kpiEntryFilters.nmscCodeList.length===1){
                let nmscObj = this.props.kpiEntryFilters.nmscCodeList[0]
                nmscObj["selected"] = "selected"
                searchDto["nmscCodeList"]=[nmscObj.value]
                this.setState({selectedNmscCodeList:[nmscObj],nmscCodeList:this.props.kpiEntryFilters.nmscCodeList,searchDto})
            }else{
                this.setState({nmscCodeList: this.props.kpiEntryFilters.nmscCodeList})
            }
        }
       

        if(prevProps.kpiEntryFilters.businessFunctionList!== this.props.kpiEntryFilters.businessFunctionList && this.props.kpiEntryFilters.businessFunctionList!== undefined ){

            if(this.props.kpiEntryFilters.businessFunctionList.length !== 0){
                let businessFunObj = this.props.kpiEntryFilters.businessFunctionList[0]
                businessFunObj["selected"]="selected"
                let selectedBusinessFunction = [businessFunObj]
                searchDto["businessFunction"] = Number(businessFunObj.value)
                this.setState({businessFunctionList:this.props.kpiEntryFilters.businessFunctionList,selectedBusinessFunction:selectedBusinessFunction,searchDto})
                this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))
                this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))
                this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
            }
    
        }

        if(prevProps.kpiEntryFilters.subPillarList!== this.props.kpiEntryFilters.subPillarList && this.props.kpiEntryFilters.subPillarList!== undefined ){
            this.setState({subPillarList: this.props.kpiEntryFilters.subPillarList})
        }

        if(prevProps.kpiEntryFilters.masterKpiList!== this.props.kpiEntryFilters.masterKpiList && this.props.kpiEntryFilters.masterKpiList!== undefined ){
            this.setState({kpiList: this.props.kpiEntryFilters.masterKpiList}) 
        }
        if(prevProps.kpiEntryFilters.brandList!== this.props.kpiEntryFilters.brandList && this.props.kpiEntryFilters.brandList!== undefined ){
            this.setState({brandList: this.props.kpiEntryFilters.brandList}) 
        }
        if(prevProps.kpiEntryFilters.activityStatusList!== this.props.kpiEntryFilters.activityStatusList && this.props.kpiEntryFilters.activityStatusList!== undefined ){
            
            if(this.props.kpiEntryFilters.activityStatusList.length === 1) {
                let activityStatusObj = this.props.kpiEntryFilters.activityStatusList[0]
                activityStatusObj["selected"]="selected"
                let selectedActivityStatusList = [activityStatusObj]
                searchDto["activityStatusList"] = [activityStatusObj.value]
                this.setState({activityStatusList: this.props.kpiEntryFilters.activityStatusList, selectedActivityStatusList: selectedActivityStatusList, searchDto}) 
            }else{
                this.setState({activityStatusList: this.props.kpiEntryFilters.activityStatusList}) 
            }
        }
    }


    onNmscCodeChange = (selectedOption) => {
        
         const searchDto = { ...this.state.searchDto };
         var valueOptions = [];
         selectedOption.map(function (item) {
             valueOptions.push(item.value);
             return null;
         })
         searchDto["nmscCodeList"] = valueOptions;
         this.setState({ searchDto });
         this.setState({ selectedNmscCodeList: selectedOption })  
    }

    onBrandChange = (selectedOption) => {
        
        const searchDto = { ...this.state.searchDto };
        var valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        searchDto["brandList"] = valueOptions;
        this.setState({ searchDto });
        this.setState({ selectedBrandList: selectedOption })
       this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))
    //    this.props.dispatch(fetchKpiEntryFilters(searchDto,"businessFunction"))
       this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))
       if(valueOptions.length===0){
        this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
       }
       
   }

   onStatusChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
        return null;
    })
    searchDto["activityStatusList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedActivityStatusList: selectedOption })
}

onBusinessFunctionChange = (selectedOption) => {
        
    console.log(selectedOption)
    const searchDto = { ...this.state.searchDto };
    searchDto["businessFunction"] = Number(selectedOption.value);
    searchDto["kpiList"]=[]
    searchDto["brandList"]=[]
    searchDto["subPillarList"]=[]
    this.setState({ searchDto });
    this.setState({ selectedBusinessFunction: [selectedOption],selectedBrandList:[],selectedKpiList:[],selectedSubPillarList:[]})
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))

}

onKpiChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
        return null;
    })
    searchDto["kpiList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedKpiList: selectedOption })
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))

   if(valueOptions.length===0){
    this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))
   }
   //this.props.dispatch(fetchKpiEntryFilters(searchDto,"businessFunction"))

}

onSubPillarChange = (selectedOption) => {
        
    const searchDto = { ...this.state.searchDto };
    var valueOptions = [];
    selectedOption.map(function (item) {
        valueOptions.push(item.value);
        return null;
    })
    searchDto["subPillarList"] = valueOptions;
    this.setState({ searchDto });
    this.setState({ selectedSubPillarList: selectedOption })
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"brand"))
   this.props.dispatch(fetchKpiEntryFilters(searchDto,"kpi"))
   if(valueOptions.length===0){
    this.props.dispatch(fetchKpiEntryFilters(searchDto,"subPillar"))

   }

   //this.props.dispatch(fetchKpiEntryFilters(searchDto,"businessFunction"))
}

    search = (e) => {
        let searchDto = {...this.state.searchDto}
        e.preventDefault(); 

        if(this.state.businessFunctionList.length===0){
            toastr.error(I18n.t("kpiEntry.launchEvaluationError"))
            return
        }
        this.props.updateSerachDto(searchDto)
        this.props.dispatch(searchBulkKpiDetails(searchDto,"BulkKpiActuals"))
       
    }

    render(){  
        
        console.log("nmsc in state",this.state.nmscCodeList)
        
        return (
        <div className="col-md-12" style={{ padding: "0px" }}>
            <div id="searchId" style={{ borderColor: this.props.theme.primaryColor }} className="col-md-12 searchDash">
                <div>
                    <div className="col-md-12 row control-label">                    
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400',marginLeft:'-8px' }}>{I18n.t('ceaEvaluation.nmsc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400',
                         display: this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet ? 'block' : 'none'}}>{I18n.t('kpiEntry.subPillar')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400',marginLeft:'-5px' }}>{I18n.t('kpiEntry.businessFunc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.kpi')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.brand')}</label>
                        <label className="col-md-1 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('kpiEntry.status')}</label>
                    </div>
                </div>
                <div className={"col-md-12 input-group control-label dealerFilter-"+this.props.theme.name} style={{paddingLeft: '5px'}}>
                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                    {this.state.nmscCodeList.length===1?<Select
                                className = "dsabledSelectKpiEntry"
                                value={this.state.selectedNmscCodeList}
                                options={this.state.nmscCodeList}
                                isDisabled={true}
                                />:<ReactMultiSelectCheckboxes
                                value={this.state.selectedNmscCodeList}
                                onChange={(e)=>this.onNmscCodeChange(e)}
                                options={this.state.nmscCodeList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                    }
                        </div>
                        {this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet ?
                        <div style = {{margin : '-2px'}}/>:""}
                        {this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet ?
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        {this.state.subPillarList.length===0?
                        <Select
                        isDisabled={true}
                        placeholder={""}
                        className = {this.state.subPillarList.length===0?"disabledFieldKpiEntry":"activeFieldKpiEntry-" + this.props.theme.name}
                         />
                        :
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedSubPillarList}
                                onChange={(e)=>this.onSubPillarChange(e)}
                                options={this.state.subPillarList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        }
                        </div>:""}
                        <div style = {{margin : '-3px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <Select
                                value={this.state.selectedBusinessFunction}
                                onChange={(e)=>this.onBusinessFunctionChange(e)}
                                options={this.state.businessFunctionList}
                                isDisabled={
                                    this.state.businessFunctionList.length===0
                                      ? true
                                      : false
                                  }
                                  className = {this.state.businessFunctionList.length===0?"disabledFieldKpiEntry":"activeFieldKpiEntry-" + this.props.theme.name}
                                placeholder={""}
                            />
                        </div>
                        {/* <div style = {{margin : '6px'}}/> */}
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        {this.state.kpiList.length === 0?
                            <Select
                            isDisabled={true}
                            placeholder={""}
                            className = {this.state.kpiList.length===0?"disabledFieldKpiEntry":"activeFieldKpiEntry-" + this.props.theme.name}
                        />
                        :
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedKpiList}
                                onChange={(e)=>this.onKpiChange(e)}
                                options={this.state.kpiList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                        }
                        </div>
                        <div style = {{margin : '-2px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                       { this.state.brandList.length === 0?
                            <Select
                                isDisabled={true}
                                placeholder={""}
                                className = {this.state.brandList.length===0?"disabledFieldKpiEntry":"activeFieldKpiEntry-" + this.props.theme.name}
                            /> 
                       :
                        <ReactMultiSelectCheckboxes
                                value={this.state.selectedBrandList}
                                onChange={(e)=>this.onBrandChange(e)}
                                options={this.state.brandList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                            }
                        </div>
                        <div style = {{margin : '-2px'}}/>
                        <div className={"col-md-1 activitysStatus noPadding bigDD-" + this.props.theme.name}>
                        { this.state.activityStatusList.length <= 1?
                            <Select
                            value={this.state.activityStatusList.length === 1 ? this.state.selectedActivityStatusList[0]:''} 
                            isDisabled={true}
                            placeholder={""}
                            className = {this.state.activityStatusList.length <= 1 ?"dsabledSelectActivityStatus disabledFieldKpiEntry":"activeFieldKpiEntry-" + this.props.theme.name}
                            />
                        :
                            <ReactMultiSelectCheckboxes
                                    value={this.state.selectedActivityStatusList}
                                    onChange={(e)=>this.onStatusChange(e)}
                                    options={this.state.activityStatusList}
                                    placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                                />
                        }
                        </div>
                        {this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? <div style = {{marginLeft : '50px'}}/> : ""}
                        <div className={"col-md-1 searchIcon-"+ this.props.theme.name} style={{ paddingRight: '0px' }}>
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("ceaEvaluation.search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>
                        </div>
                </div>
                <br/>
                {/* <div className="col-md-12 row control-label"> */}
                {/* <label className="col-md-2 control-label" style={{ paddingLeft: '2px' }}>{I18n.t('kpiEntry.evaluationYear')}</label> */}
                {/* </div> */}
                {/* <div className={"col-md-12 input-group control-label dealerFilter-"+this.props.theme.name}>
                    <div className={"col-md-12 searchIcon-"+ this.props.theme.name} style={{ paddingRight: '20px' }}> */}
                        {/* <button className={"button-" + this.props.theme.name + " noMargin"}
                            style = {{float : 'right' }} onClick={(e) =>this.search(e)}>
                            {I18n.t('ceaEvaluation.search')}
                        </button> */}
                        {/* <a 
                            onClick={(e) =>this.search(e)}>
                            <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("ceaEvaluation.search")} 
                            style = {{float : 'right' }}
                            /> 
                        </a>
                    </div>
                </div> */}
            </div>                    
            <div id="hrId" className="col-md-12 hrClass">
                <hr style={{ margin: '0px', borderTop: "1px solid" }} />
            </div>
        </div>
    );
  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        kpiEntryFilters : state.ceaKpiEntryReducer.kpiEntryFilters,
        router: state.routing
    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(SearchKpiEntry)));