import { createTheming } from '@callstack/react-theme-provider';
import type { ThemingType } from '@callstack/react-theme-provider';

export type Theme = {
  primaryColor: string,
  accentColor: string,
  backgroundColor: string,
  textColor: string,
  secondaryColor: string,
  logo: string,
  name: string,
  iconColor: string
};

export const themes: { [key: string]: Theme } = {
  default: {
    primaryColor: '#e50000',
    accentColor: '#458622',
    backgroundColor: '#e50000 red darken-4',
    textColor: '#504f4d',
    secondaryColor: '#7F5315',
    logo: 'images/TMEToyotaEllipse.png',
    name: 'toyota',
    iconColor: 'toyota-icon'
  },
  dark: {
    primaryColor: '#252525',
    accentColor: '#458622',
    backgroundColor: '#424242 grey darken-3',
    textColor: '#FFC777',
    secondaryColor: '#252525',
    logo: 'images/Lexus2D.jpg',
    name: 'lexus',
    iconColor: 'lexus-icon'
  },
};

const { ThemeProvider, withTheme }: ThemingType<Theme, $Shape<Theme>> = createTheming(
  themes.default
);

export { ThemeProvider, withTheme };
