import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
import BootstrapTable from 'react-bootstrap-table-next';
import { fetchBestPractice } from '../../../actions/dashboardAction';

var I18n = require("react-redux-i18n").I18n;
class BestPracticeBoard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            bestPractice: []

        };
    }
    componentDidMount() {
        this.props.dispatch(fetchBestPractice(this.props.userMdl));

    }
    componentWillReceiveProps(nextProps) {
        // console.log("--------------------------------------");
        if (nextProps.bestPractice) {
            this.setState({
                bestPractice: nextProps.bestPractice
            })
        }
        // console.log(nextProps.bestPractice);

    }


    render() {


        function currencyFormatter(cell, row) {
            let val;
            if (cell) {
                val = cell + " " + row.unit
            }
            else {
                val = "-"
            }
            if(val!=="-"){
                return (
                    <span style={{textAlign: "left"}}>
                        {val}
                    </span>
                );
            }else{
                return (
                    <span>
                        {val}
                    </span>
                );

            }
        }

        function percentageFormatter(cell, row) {
            // console.log(Math.sign(cell))
            let val;
            let ColorFlag=''
            if (Math.sign(cell)===1) {
                val ="+"+ cell + " %";
                ColorFlag=true
            }
            else if(Math.sign(cell)===-1){
                val =cell + " %";
                ColorFlag=false
            }
            else {
                val ="-"
            }
            if(ColorFlag){
                return (
                    <span style={{fontWeight:"Bold",color:"green",textAlign: "left"}}>{val}</span>
                );
           }else if(ColorFlag===false){
                return (
                    <span style={{fontWeight:"Bold",color:"red",textAlign: "left"}}>{val}</span>
                );
           }else{
                return (
                    <span>{val}</span>
                );
           }
        }

        const columns = [{
            dataField: 'kpiName',
            text: " ",
            style: {
                textAlign: "left", width: "50%", textOverflow: "ellipsis", whiteSpace: "nowrap",
                overflow: "hidden",fontSize:"13.5px",fontWeight:"400", paddingLeft : "15px"
            },
            title:true,
            headerStyle: { textAlign: "left", width: "50%" },
        }, {
            dataField: 'kpiAvgTarget',
            text: " ",
            style: {textAlign: "right", width: "25%",fontSize:"13.5px",fontWeight:"400" },
            headerStyle: { textAlign: "left", width: "25%" },
            formatter: currencyFormatter
        },
        {
            dataField: 'kpiAvgPercentage',
            text: " ",
            style: { textAlign: "right",width: "25%",fontSize:"13.5px",fontWeight:"400", paddingRight : "10px" },
            headerStyle: { textAlign: "left", width: "25%" },
            formatter: percentageFormatter
        }];
        return (
            // <div style={{ width: "100%" }} className="best-practice-board">
            <div style={{height:"8rem"}}>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.bestPractice')}>
                {I18n.t('dashboard.bestPractice')}
                </div>

                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="best-practice-board">
                        <BootstrapTable
                            keyField='actionPlanId'
                            data={this.state.bestPractice}
                            columns={columns}
                        //selectRow={selectRow}
                        // hover
                        //condensed
                        //rowEvents={rowEvents}
                        //noDataIndication={I18n.t('evaluation.noRecords')}
                        />
                    </div>
                </LoadingOverlay>

            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        bestPractice: state.dashboardReducer.bestPractice
    };
};



export default withRouter(connect(mapStateToProps)(withTheme(BestPracticeBoard)));