import React from 'react';
import { withTheme } from '../../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import '../index.css';
import { toastr } from 'react-redux-toastr';
var I18n = require('react-redux-i18n').I18n;
// const $ = require('jquery')
// window.jQuery = $;


class UpdateUuid extends React.Component {

    constructor(props) {
        super(props);
        this.state ={

            existingUuid :"",
            newUuid :""

        }


    }

    isNotEmpty = (value) => {
        if (undefined === value || null === value) {
            return false;
        }
        else if ((value + "").trim() === "") {
            return false;
        }
        return true;
    }

    newUuidUpdate=()=>{
        this.setState({newUuid:""})
    }
    onUuidChange=(e)=>{
        this.setState({newUuid : e.target.value})
    }

    componentDidUpdate(){
       this.onEditUuidClick()
    }

    onEditUuidClick = () => {
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className={"ConfirmBox-" + this.props.theme.name} style={{width:"610px"}}>
                        <div className="ConfirmBoxTitle modal-header">
                            {I18n.t("dealerManagement.updateUuid")}
                        <button type="button" className="close" aria-label="Close" onClick ={()=>{
                             onClose()
                            this.newUuidUpdate()
                            this.props.toggle()
                           }}>
                            <span area-hidden="true">x</span></button>   
                        </div>
                        <div className="ConfirmBoxBody">
                        <form className="form-horizontal">
                        <div className="col-md-12 row control-label">
                            <label className="col-md-6 control-label updateUuidConfirmBox" style={{ paddingLeft: '0.3rem' }}> {I18n.t("dealerManagement.existingUuid")}</label>                                                                
                            <label className="col-md-6 control-label updateUuidConfirmBox">{I18n.t("dealerManagement.newUuid")}</label>
                        </div>
                        <div className="col-md-12 input-group">
                        <div className="col-md-6" style={{paddingLeft:"5px"}}>
                            <input
                                style={{paddingLeft:"3px",width: "16.5rem"}}
                                type="text"
                                className="form-control black-font"
                                value={this.state.existingUuid}
                                disabled="disabled" 
                            /> </div>
                            <div className="col-md-6">
                           <input
                                style={{paddingLeft:"3px",width: "16.5rem"}}
                                type="text"
                                className="form-control black-font"
                                value={this.state.newUuid}
                                onChange = {(e)=>this.onUuidChange(e)}
                            />
                            </div>
                        </div>
                    </form>
                        </div>
                        <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-"+this.props.theme.name} onClick={() => {
                                onClose()
                                this.newUuidUpdate()
                                this.props.toggle()
                                }}>
                                {I18n.t("standardMessages.Cancel")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={(e) => {
                                   this.updateUuid(e,onClose)
                                    
                                }}>
                                {I18n.t("tmeAdminMaster.update")}
                            </button>
                            
                        </div>
                    </div>
                );
            }
        });
        }

    updateUuid=(e,onClose)=>{
        e.preventDefault(); 

        if(this.isNotEmpty(this.state.newUuid)){
            this.props.updateUuid(this.state.newUuid,onClose)
        }else{
            toastr.error(I18n.t("dealerManagement.invalidUuid"));
        }

    }

        

    preventDefault=()=>{

    }

    componentDidMount(){

        this.setState({existingUuid:this.props.existingUuid})

    }

    onFeildChange=(e)=>{
        this.setState({newUuid : e.target.value})
    }

    render() {
        return null
 
    }
}
const mapStateToProps = state => {
    return { 
        language : state.userManagementReducer.dealerLang
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(UpdateUuid)));