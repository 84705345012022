import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../../themes/theming";
import { withRouter } from "react-router-dom";
import { MDBCard,MDBCardHeader,MDBCardBody,Fa} from 'mdbreact';
import { Accordion } from 'react-bootstrap';
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory  from 'react-bootstrap-table2-editor';
import CeaNetKpiModal from '../../ceaNetKpiModal';
import { toastr } from "react-redux-toastr";
import {saveBulkKpiActuals, restDataKpiEntry} from '../../../actions/bulkCeaKpiEntryAction';
import * as AppConst from '../../../AppConstant';
import "./../index.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
var I18n = require("react-redux-i18n").I18n;

class KpiEntryOverView extends React.Component{
    /* eslint-disable */
    constructor(props){
        super(props);
        this.state ={ 
            nmscWiseKpiData:[],   
            isActive:false,
            isEditable:false,
            activeIndex:null,
            modal: false,
            selectedRow:{},
            monthTargetDTOList : [],
            valid: true,
            expanded: []
        }
    }      

    handleOnClick = (index)=>{
        this.setState({activeIndex :index});
    }   

    actualsFormatter =(cell,row)=>{
        return (
            <div></div>
        );
    }

    onChangeTextBox = (e, row, cell) => {
        // This is intentional
     };
    
     onBlurTextBox = (e, row, cell, points) => {           
        let val = e.target.value.trim();
        console.log(typeof (val));
        console.log(typeof (points));
        var Number = /^\d*\.?\d{0,6}$/;
      
        if (Number.test(val) || val === "" ) {
            e.target.value = val            
            if(cell.target===val){
                return
            }

            // if(parseInt(val) > parseInt(points)){
            //     toastr.error(I18n.t("kpiEntry.actuals1"))
            //     e.target.value = cell.target
            //     return
            // }
            cell.target = val
            

            let selectedObj = {
                dealerMonthlyTargetId: cell.dealerMonthlyTargetId,
                target: val
            }      
            let monthList = this.state.monthTargetDTOList.filter((item) => item.dealerMonthlyTargetId === cell.dealerMonthlyTargetId);  
            if(monthList.length === 0){
                this.state.monthTargetDTOList.push(selectedObj);
            }else{
                this.state.monthTargetDTOList.map(obj=>{
                    if(obj.dealerMonthlyTargetId === cell.dealerMonthlyTargetId){
                        obj.target = val
                    }
                })
            }
        }else{
            toastr.error(I18n.t("kpiEntry.notNumber"))
            e.target.value = cell.target
        }                          
    };

    inputFormatter = (cell, row, rowIndex, points) => {
        return (
            <input
            type="text"
            // value={(cell && cell !== undefined) ? cell.target : ""}
            defaultValue={(cell && cell !== undefined) ? cell.target : ""}
            onChange={e => this.onChangeTextBox(e, row, cell)}
            onBlur={e => this.onBlurTextBox(e, row, cell, points)}
            
            className="form-control"
            disabled={(cell && cell !== undefined && cell.editable) ? false :true}           
            />
        );
    };
      
    seqFormatter =(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.seqNum}</div>
        );
    }

    kpiFormatter =(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal" }}>{row.name}</div>
        );
    }

    frequencyFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.evaluationFrequency}</div>
        );
    }

    typeTargetFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.typeOfTarget}</div>
        );
    }

    targetFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.target}</div>
        );
    }

    unitFormatter=(cell,row)=>{
        return (
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.unit}</div>
        );
    }

    toggleView=(row)=>{
        this.setState({
            modal: !this.state.modal,
            modalFor: 'View',
            selectedRow: {localKpiId:row.kpiId}
        });
    }

    //for kpi view
    popUpClose = (action) => {

        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
    }

    buttonFormatter = (cell, row) => {
        return (
          <div className="btn-toolbar float-left">
            <a onClick={() => this.toggleView(row)}>
              <Fa icon={"eye"} fixed size="lg" title={I18n.t('ceaEvaluation.view')} />
            </a>
          </div>
        );
      }

    createTable =(data,nmscName)=>{
        const columns = [
            {
                dataField: "seqNumber",
                text:I18n.t("kpiEntry.srNo"),
                editable: false,
                style: { width: "70px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "70px", textAlign: "center" },
                formatter: this.seqFormatter,
                title: true
            },
            {
                dataField: "name",
                text: I18n.t('kpiEntry.kpi'),
                editable: false,
                // sort: true,
                style: { width: "250px", textAlign: "left",
                // minWidth:"18rem"
                },
                headerStyle: { width: "250px", textAlign: "left" },
                 formatter: this.kpiFormatter,
                title: true
            },
            {
                dataField: "",
                text: "",
                editable: false,
                // sort: true,
                style: { width: "39px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "39px", textAlign: "center" },
                formatter: this.buttonFormatter,
                title: true
            },
            {
                dataField: "evaluationFrequency",
                text: I18n.t("kpiEntry.frequency"),
                // editable: true,             
                // sort: true,
                style: { width: "95px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "95px", textAlign: "center" },
                formatter: this.frequencyFormatter,
                title: true
            },
            {
                dataField: "typeOfTarget",
                text: I18n.t("kpiEntry.typeOfTarget"),
                editable: false,
                // sort: true,
                style: { width: "115px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "115px", textAlign: "center" },
                formatter: this.typeTargetFormatter,
                title: true,
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            }, 
            {
                dataField: "target",
                text: I18n.t("kpiEntry.target"),
                editable: false,
                // sort: true,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center" },
                 formatter: this.targetFormatter,
                title: true,
                hidden:this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer ? true : false
            },
            {
                dataField: "unit",
                text: I18n.t("kpiEntry.unit"),
                editable: false,
                // sort: true,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center" },
                formatter: this.unitFormatter,
                title: true
            },
            {   
                dataField: "actuals",
                text:I18n.t("kpiEntry.actuals"),
                editable: false,
                // sort: true,
                style: { width: "500px", textAlign: "left" },
                headerStyle: { width: "500px", textAlign: "left" },
                formatter: this.actualsFormatter,
                title: true
            },  
            // {
            //     dataField: "actuals1",
            //     text:"",
            //     editable: false,            
            //     title: true
            // }           
        ];  
        const expandRow = {
            renderer: (row, rowIndex) => (
                this.createParamTable(row)
            ),            
            showExpandColumn:true,
            // expandByColumnOnly : true,
            // expanded: this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_BO) ? this.state.expanded : '',
            expanded: this.state.expanded,
          };
        return(
            <div key={nmscName} className="parentParamTable col-md-12 row control-label noPadding">                                 
                <BootstrapTable
                    keyField="kpiEvaluationId"
                    noDataIndication={I18n.t('dashboard.nodata')}
                    data={data}
                    columns={columns}
                    rowStyle={ { overflow: 'auto' } }
                    expandRow={ expandRow }
                />
            </div>
        );
    }

    pramaKeyValueFormatter =(cell,row)=>{
        return <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "normal"}}>{row.paramKey + " = "+ row.paramValue}</div>
    }

    createParamTable = (data)=>{
        const columns = [            
            {
                dataField: "seqNum",
                text:"",
                editable: false,
                style: { width: "70px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "70px", textAlign: "center",minWidth:"3rem" },
                formatter: this.seqFormatter,
                // headerAttrs : {
                //     hidden : true
                // }
            },
            {
                dataField: "paramKey",
                text:I18n.t("kpiEntry.inputTxt"),
                style: { width: "245px", textAlign: "left"},
                headerStyle: { width: "250px", textAlign: "left"},
                editable: false,
                formatter: this.pramaKeyValueFormatter
            },
            {
                dataField: "empty1",
                text: "",
                editable: false,
                style: { width: "39px", textAlign: "center",minWidth:"3rem" },
                headerStyle: { width: "39px", textAlign: "center",minWidth:"3rem" },
                hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? true : false
            },
            {
                dataField: "empty2",
                text: "",
                editable: false,
                style: { width: "95px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "95px", textAlign: "center",minWidth:"5rem" },
                hidden:this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? true : false
            },
            {
                dataField: "empty3",
                text:"",
                editable: false,
                style: { width: "115px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "115px", textAlign: "center",minWidth:"5rem" }
            },
            {
                dataField: "empty4",
                text:"",
                editable: false,
                style: { width: "80px", textAlign: "center",minWidth:"5rem" },
                headerStyle: { width: "80px", textAlign: "center",minWidth:"5rem" }
            },
            {
                dataField: "paramKey",
                text:"",
                editable: false,
                style: { width: "70px", textAlign: "center",
                    // minWidth:"5rem" 
                },
                headerStyle: { width: "70px", textAlign: "center",
                    // minWidth:"5rem" 
                },                
            }                       
        ];
        let obj = { };
        let length = 1;
        let actualsList = ['CY']
        if(data.evaluationFrequency === "Monthly"){
            length = 12;            
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)
        }
        if(data.evaluationFrequency === "Quarterly"){
            length = 4;  
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)
        }        
        if(data.evaluationFrequency === "Half Yearly"){          
            length = 2; 
            actualsList = this.monthFormatterBasedOnFrequency(data.evaluationFrequency)          
        }
        for (var i = 0; i < length; i++) {
            obj = {
                dataField: "monthTargetDTOList["+i+"]",
                text: actualsList[i],                
                editable:true,
                style: { 
                    width: "120px", 
                    textAlign: "left" },
                formatter: this.inputFormatter,
                formatExtraData:data.points,
                headerStyle: { width: "120px", textAlign: "left" }
            };               
            columns.push(obj)
        }                
        let col6 = {
            text:"",
            dataField: "empty6",
            editable: false,
            style: { 
                width: "120px", 
                textAlign: "left" },
                headerStyle: { width: "120px", textAlign: "left" }
            }   
        let col7 = {
            text:"",
            dataField: "empty7",
            editable: false,
            style: { 
                width: "120px", 
                textAlign: "left" },
                headerStyle: { width: "120px", textAlign: "left" }
            }             
        if(data.evaluationFrequency !== "Quarterly"){
            columns.push(col6)
            columns.push(col7)
            if(data.evaluationFrequency === "Yearly"){
                columns.push(col6)
            }
        }
        const expandRow = {
            showExpandColumn:false          
        }
        return(
            <div key={data.seqNum} className="childParamTable col-md-12 row control-label noPadding">                                 
                <BootstrapTable
                    keyField="identityNum"
                    noDataIndication={I18n.t('dashboard.nodata')}
                    data={data.kpiParamDTOList}
                    columns={columns}
                    cellEdit={cellEditFactory({
                        beforeSaveCell(oldValue, newValue, row, column, done) {                                                
                            return { async: true };
                        }
                    })}
                    expandRow={ expandRow }
                />
            </div>
        );        
    }

    monthFormatterBasedOnFrequency = (frequency)=>{
        switch(frequency){
            case 'Monthly':
                let allMonthList = [I18n.t('kpiEntry.Jan'), I18n.t('kpiEntry.Feb'), I18n.t('kpiEntry.Mar'), I18n.t('kpiEntry.Apr'), I18n.t('kpiEntry.May'), I18n.t('kpiEntry.Jun'), I18n.t('kpiEntry.Jul'), I18n.t('kpiEntry.Aug'), I18n.t('kpiEntry.Sep'), I18n.t('kpiEntry.Oct'), I18n.t('kpiEntry.Nov'), I18n.t('kpiEntry.Dec')];
                return allMonthList;
            case 'Quarterly' :
                let quaterList = [I18n.t('kpiEntry.q1'),I18n.t('kpiEntry.q2'),I18n.t('kpiEntry.q3'),I18n.t('kpiEntry.q4')]
                return quaterList
            case 'Half Yearly' :
                let halfYearList = [I18n.t('kpiEntry.h1'),I18n.t('kpiEntry.h2')]
                return halfYearList
            case 'Yearly' :
                let yearList = [I18n.t('kpiEntry.cy')]
                return  yearList
            default:
                return []
        }
    }

    renderNmscData =(nmscObj,index)=>{
        return(          
            <MDBCard key={index} className={"kpiEntryCard"}>
                <Accordion.Toggle as={MDBCardHeader} eventKey={index === 0 ?'0':index} onClick ={(index)=>this.handleOnClick(index)}>                        
                    <label md="0.2" className={'control-label'} style={{fontSize: "12px",textTransform :"capitalize",marginBottom:"0px"}}>
                    <span style={{ display: 'inline-block', height: '20px' }} >{this.state.activeIndex==index?
                    <img className="toggleTreebeard toyota" src="../../assets/images/q_down.png"  height={'20px'} width={'20px'} alt=""/>:
                    <img className="toggleTreebeard toyota" src="../../assets/images/T_Artboard 21mdpi.png"  height={'20px'} width={'20px'} alt=""/>
                    }
                    </span >
                    <span style={{ display: 'inline-block', height: '20px',paddingLeft:"4px" }}>{nmscObj.name+" - "+nmscObj.nmscCode}</span>
                    </label>
                    </Accordion.Toggle>
                <Accordion.Collapse  eventKey={index === 0 ?'0':index}>
                    <MDBCardBody className ="cardPadding">
                        {this.createTable(nmscObj.ceaKpiEvaluationList,nmscObj.name)}  
                    </MDBCardBody>
                </Accordion.Collapse>
            </MDBCard>
        )
    }

    componentDidUpdate(prevProps, prevState) {
        let expandedIds = []
        if(prevProps.kpiEntryDeatils !== this.props.kpiEntryDeatils && this.props.kpiEntryDeatils !== undefined ){
        if(this.props.kpiEntryDeatils.length>0){
            this.setState({isEditable:this.props.kpiEntryDeatils[0].editable})
            this.props.kpiEntryDeatils.map(obj=>{
                expandedIds = expandedIds.concat(obj.kpiEvaluationIdsList);
            })    
        }   
        this.setState({nmscWiseKpiData:this.props.kpiEntryDeatils,expanded:expandedIds})                
        }
    }

    onclickCancel(){
    confirmAlert({
        customUI: ({ title, message, onClose }) => {
            return (
            <div className="deleteConfirmBox">
                <div className={"titleClass-" + this.props.theme.name}>                          
                {this.state.monthTargetDTOList.length!==0 ?
                I18n.t("kpiEntry.dataNotSaved")
                : I18n.t("kpiEntry.wantToProceed")}
                </div><br></br>
                <div className="modal-footer" style={{ borderTop: "0px" }}>
                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                    {I18n.t("kpiEntry.no")}
                </button>
                <button
                    className={"button-" + this.props.theme.name}
                    onClick={() => {
                    this.props.dispatch(restDataKpiEntry());
                    onClose();
                    }}
                >
                    {I18n.t("kpiEntry.yes")}
                </button>
                </div>
            </div>
            );
        }
        });        
    }

    onclickSave(){
        let saveKpiBulkActualsDTO = {
            userId :  this.props.user.userMdl.username,
            monthTargetDTOList : this.state.monthTargetDTOList
        }  
        if(this.state.monthTargetDTOList.length===0){
            toastr.error(I18n.t("kpiEntry.enterActualsData"))
            return
        }
        this.props.dispatch(saveBulkKpiActuals(saveKpiBulkActualsDTO,this.props.searchDto,"BulkKpiActuals",false)) 
        this.setState({monthTargetDTOList:[],nmscWiseKpiData:[]})
    }

    render(){
        let nmscWiseKpiData = this.state.nmscWiseKpiData
        if(nmscWiseKpiData.length!==0){
        return (
            <div >
            <div className="col-md-12" style={{padding:"0px",marginTop:"8px"}}>
            {
                // this.props.user.userMdl.roles.includes(AppConst.CEA_ROLES.KET_CEA_BO) ?
                nmscWiseKpiData.map((data,index)=>
                    <Accordion defaultActiveKey={index === 0 ?'0':index} key={data.nmscName} className ="kpiEntryAccordian">
                        {this.renderNmscData(data,index)} 
                    </Accordion>
                ) 
                // :
                // <Accordion className ="kpiEntryAccordian"> 
                // {
                //     nmscWiseKpiData.map((data,index)=>this.renderNmscData(data,index))
                // }
                // </Accordion>
            }                     
            </div>
            <div className="col-md-12 input-group control-label">
            <div className="col-md-12" style={{ padding: '0px',marginTop:'5px' }}>
                {this.state.isEditable ?
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={(e)=>{this.onclickSave()}}
                        style = {{float : 'right' }} 
                        >
                        {I18n.t("kpiEntry.save")}
                    </button>
                    :""}
                <button
                    className={"buttonCancel-" + this.props.theme.name}
                    onClick={(e)=>{this.onclickCancel()}}
                    style = {{float : 'right' }} 
                    >
                    {I18n.t("kpiEntry.cancel")}
                </button>                        
            </div>
            </div>
            {/* kpi view--start */}
            {this.state.modal === true ?
                            <CeaNetKpiModal
                                modal={this.state.modal}
                                toggle={this.popUpClose}
                                modalFor={this.state.modalFor}
                                selectedRow={this.state.selectedRow}
                            /> : null}
            {/* kpi view--end */}
            </div>   
        );
        }
        else{
            return (
                <div>
                </div>
            )
        }
  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        kpiEntryDeatils: state.ceaKpiEntryReducer.kpiEntryDeatils
    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(KpiEntryOverView)));