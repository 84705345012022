import React from "react";
import BootstrapTable2 from "react-bootstrap-table-next";
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import { toastr } from "react-redux-toastr";
import { withTheme } from "../../../themes/theming";
import "../index.css";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as AppConst from "../../../AppConstant";
var I18n = require("react-redux-i18n").I18n;


class ParamTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramDto: props.type === 'View' || props.type === 'Edit' ? props.paramDTO.map((obj, i) => {
                    obj.id = i + 1;
                    return obj;
            }) : [{ id: 1, paramId: 0, dataEntryKet: true, paramKey: "", paramValue: "" }],

            selected: props.type === 'View' || props.type === 'Edit' ? [] : [1],
            notSelectable: props.type === 'View' ? props.paramDTO.map(obj => { return obj.paramId }) : []

        };
    }

    componentDidMount() {
        if (this.props.type === 'View' || this.props.type === 'Edit') {
            this.props.paramTableData(this.state.paramDto);
            // console.log("componentDidMount")

            let selected = [];
            let paramDtoUpdated = [];
            if (this.state.paramDto && this.state.paramDto.length > 0) {
                this.state.paramDto.map(obj => {
                    if (obj.dataEntryKet) {
                        if (this.props.type === 'View') {
                            selected.push(obj.paramId)
                        } else {
                            selected.push(obj.id)
                        }
                    }
                    if(obj.paramKey!==AppConst.paramKey){
                        paramDtoUpdated.push(obj)
                    }
                    return null;
                })
            }
            this.setState({ selected, paramDto : paramDtoUpdated  })
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if (prevState.paramDto !== this.state.paramDto) {
            // console.log("componentDidUpdate 1")

            this.props.paramTableData(this.state.paramDto);
        }


        if (prevState.paramDto !== this.state.paramDto) {
            // console.log("componentDidUpdate 2")

            let selected = [];
            if (this.state.paramDto && this.state.paramDto.length > 0) {
                this.state.paramDto.map(obj => {
                    if (obj.dataEntryKet) {
                        if (this.props.type === 'View') {
                            selected.push(obj.paramId)
                        } else {
                            selected.push(obj.id)
                        }
                    }
                    return null;
                })
            }
            this.setState({ selected })
        }
    }

    // handleChange() {
    //    console.log('hello');
    // }
    headerAdd = (cell, row) => {
        // console.log(cell, row)
      //  console.log(this.state.selectedRow.status);
        //add parameter button
        if (this.props.type !== 'View' && this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet&& this.props.user.userMdl.activeProgram !== AppConst.activeProgramLN) {
            // if (this.state.paramDto !== undefined && this.state.paramDto.length <= 5) {
            if (this.state.paramDto !== undefined ) {
                return (
                    // <div>
                    //     <a
                    //         onClick={() => this.addRow(cell, row)}
                    //     ><div title={I18n.t('CeaKpi.addKPI')} className="add-icon cea-kpiAddIcon" /></a>
                    // </div>
                    <button
                        className={"addParam-" + this.props.theme.name}
                        title={I18n.t("CeaKpi.addParam")}
                        onClick={() => this.addRow(cell, row)}
                    >
                        {I18n.t("CeaKpi.addParam")}
                    </button>
                );
            } 
            // else {
            //     return (
            //         // <div>
            //         //     <a><div title={I18n.t("CeaKpi.maxFiveParam")} className="addDisabledIcon cea-kpiAddIcon" /></a>
            //         // </div>
            //         <button style={{ cursor: "not-allowed" }}
            //             className={"addParam-" + this.props.theme.name}
            //             title={I18n.t("CeaKpi.maxFiveParam")}
            //         >
            //             {I18n.t("CeaKpi.addParam")}
            //         </button>
            //     );
            // }
        }
    }

    deleteRow = (cell, row, rowIndex) => {
        // console.log(cell, row, rowIndex)
        if (this.props.user.userMdl.activeProgram !== AppConst.activeProgramLN){
        return (
            <div className="btn-toolbar float-right">
                <a onClick={(e) => this.deleteParam(cell, row, rowIndex)}><div title={I18n.t('CeaKpi.delete')} className="delete-icon" /></a>
            </div>
        );
    }


    }

    deleteParam = (cell, row, rowIndex) => {
        let paramDto = [...this.state.paramDto]
        // console.log(paramDto)
        let newParamDto = []
        let tempParamDto = paramDto.filter(obj => obj.id !== cell);

        // this.setState(() => ({
        //     selected: this.state.selected.filter(x => x !== cell)
        // }));
        tempParamDto.map((obj, i) => {
            obj.id = i + 1;
            newParamDto.push(obj);
            return null;
        });

        // console.log(newParamDto)
        this.setState({ paramDto: newParamDto });
    }
    addRow = (cell, row) => {
        // console.log(cell, row)
        let paramDto = [...this.state.paramDto]
        // console.log(paramDto)
        paramDto.push({ id: paramDto.length + 1, paramId: 0, dataEntryKet: true, paramKey: "", paramValue: "" })

        let selected = [...this.state.selected]
        selected.push(paramDto.length + 1)
        // console.log(paramDto)
        this.setState({
            paramDto: paramDto
            , selected: selected
        });

    }

    onAfterSaveCell = (oldValue, newValue, row, cell) => {
        // console.log(oldValue, newValue, row, cell)
        if (cell.dataField === "paramKey") {
            if (this.validateInputs(row.paramKey, cell.dataField)) {
                return;
            }
            row.paramKey = newValue;
        }
        if (cell.dataField === "paramValue") {
            if (this.validateInputs(row.paramValue, cell.dataField)) {
                return;
            }
            row.paramValue = newValue;
        }
        // console.log(this.state.paramDto)
        this.props.paramTableData(this.state.paramDto);
    }

    validateInputs = (input, field) => {
        if (field === "paramKey") {
            if (input === "") {
                toastr.error(I18n.t("CeaKpi.validParam"));
                return true;
            }
        }
        if (field === "paramValue") {
            if (input === "") {
                toastr.error(I18n.t("CeaKpi.validDesc"));
                return true;
            }
        }
    }

    inputFormatter = (cell, row, rowIndex) => {
        // console.log(cell, row, rowIndex)
        if( this.props.type !== 'View' &&  (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram !== AppConst.activeProgramNet) && (this.props.status!=='PROCESSED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)&& (this.props.status!=='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)){
            return (
                <select className="parameter_select">
                    <option value={cell}>{cell}</option>
                </select>
            );

        }else{
            return (
                <input
                    type="text"
                    defaultValue={(cell && cell !== undefined) ? cell : ""}
                    className="form-control"
                    disabled={
                    (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? true
                    : (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true: (this.props.status==="PUBLISHED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true: this.props.type !== 'View' ? false : true}
                />
                
            );
        }
       
    };

    textAreaFormatter = (cell, row, rowIndex) => {
        return (
            <input
                type="textarea"
                defaultValue={(cell && cell !== undefined) ? cell : ""}
                className="form-control"
                disabled={
                    (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? true
                    : (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? true : (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?true: this.props.type !== 'View' ? false : true}
            />
        );
    };

    render() {

        // console.log(this.props.paramDTO)
        // console.log(this.props.status)
        // console.log(this.state.paramDto)
        // console.log(this.state.selected)
        // console.log(this.state.notSelectable)

        const selectRow = {
            mode: 'checkbox',
            selectionRenderer:this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ?  ({ mode, checked, disabled }) => (
                disabled
              ) : false,
            clickToSelect: false,
            clickToEdit: false,
            selected: this.state.selected,
            nonSelectable: this.props.type === 'View' || this.props.user.userMdl.activeProgram === AppConst.activeProgramNet || this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ? this.state.notSelectable : [],

            onSelect: (row, isSelect, rowIndex, e) => {
                // console.log(row, isSelect, rowIndex, e)

                if (this.props.type !== 'View') {

                    if (isSelect) {
                        this.setState(() => ({
                            selected: [...this.state.selected, row.id]
                        }));
                    } else {
                        this.setState(() => ({
                            selected: this.state.selected.filter(x => x !== row.id)
                        }));
                    }
                    let paramDto = [...this.state.paramDto];

                    paramDto = paramDto.map((obj, i) => {
                        if (rowIndex === i) {
                            obj.dataEntryKet = isSelect;
                            return obj;
                        } else {
                            return obj;
                        }
                    })
                    // console.log(paramDto)

                    this.setState({ paramDto });
                }
            },
            // hideSelectAll: true,
            selectionHeaderRenderer: () => I18n.t("CeaKpi.dataEntry"),

        };



        var columns = [
            {
                dataField: 'paramKey',
                text: I18n.t('CeaKpi.paramater'),

                style: {
                    width: "30%", textAlign: "center", wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                editable:
                (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? false :
                (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? false:
                (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?false:
                this.props.type === 'View' ? false : true,
                headerStyle: { width: "18%", textAlign: "left" },
                formatter: this.inputFormatter,
                editor: {
                    type: Type.SELECT,
                    options: [{
                        value: '%',
                        label: '%'
                    }, {
                        value: 'Number',
                        label: 'Number'
                    }, {
                        value: 'Score',
                        label: 'Score'
                    }]
                },

                editCellStyle: (cell, row, rowIndex, colIndex) => {
                    const width = "30%";
                    return { width };
                },
            },


            {
                dataField: 'paramValue',
                text: I18n.t('CeaKpi.paramDesc'),
                formatter: this.textAreaFormatter,
                style: {
                    width: "45%", textAlign: "center", wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                headerStyle: { width: "30%" },
                editable: 
                (this.props.type === 'Edit' && this.props.user.userMdl.activeProgram === AppConst.activeProgramNet) ? false :
                (this.props.status==="PROCESSED" && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)? false:
                (this.props.status==='PUBLISHED' && this.props.user.userMdl.activeProgram === AppConst.activeProgramLN)?false:
                this.props.type === 'View' ? false : true,

                editCellStyle: (cell, row, rowIndex, colIndex) => {
                    const width = "45%";
                    return { width };
                },
                editor: {
                    type: Type.TEXTAREA
                }
            },

            {
                dataField: "id",
                text: I18n.t('Table.action'),
                headerFormatter: this.headerAdd,
                formatter: this.deleteRow,
                style: { width: "10%" },
                headerStyle: { width: "35%" },
                editable: false,
                hidden: this.props.type === 'View' || this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? true : false

            }];

        return (
            <div className="parameterTable">
                <BootstrapTable2
                    keyField={this.props.type !== 'View' ? 'id' : 'paramId'}
                    data={this.state.paramDto}
                    noDataIndication={I18n.t('CeaKpi.noParamAvai')}
                    columns={columns}
                    cellEdit={cellEditFactory({
                        mode: 'click',
                        blurToSave: true,
                        afterSaveCell: this.onAfterSaveCell
                    })}
                    selectRow={selectRow}
                />
               
            </div>
        );
    }
}
const mapStateToProps = (state) => {
return {
    user: state.loginReducer
};
};
export default withRouter(connect(mapStateToProps)(withTheme(ParamTable)));
  
