import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { withRouter } from "react-router-dom";

class Redirect extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    componentDidMount() {
        let filterData = {};
        if(this.props.location.state && this.props.location.state.filterData){
            filterData = this.props.location.state.filterData;
        }
        this.props.history.push('/home', {'filterData': filterData});
    }
    render() {
        return (
            <div>Redirecting</div>
        );
    }
}
export default withRouter(Redirect);
