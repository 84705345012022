import { MDBCol, MDBRow, MDBCard } from 'mdbreact';
import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { withRouter } from "react-router-dom";
import { withTheme } from '../../themes/theming';
import Activation from './widgets/activation';
import ActiveRetailers from './widgets/activeRetailers';
import Activities from './widgets/activities';
import ActivitiesDue from './widgets/activitiesDue';
import AvgDaysPerToolbox from './widgets/avgDaysPerToolbox';
import BusinessResults from './widgets/businessResults';
import BusinessResultsTopNmsc from './widgets/businessResultsTopNmsc';
import CertificationRate from './widgets/certificationRate';
import OverdueActivities from './widgets/overdueActivities';
import RecentUpdates from './widgets/recentUpdates';
import ReCertificationRate from './widgets/reCertificationRate';
import RolloutResultsTopNmsc from './widgets/rolloutResultsTopNmsc';
import ToolboxImplementation from './widgets/toolboxImplementation';
import OverdueActivitiesForNMSC from './widgets/overdueActivitiesForNMSC'

class WidgetContainer extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 7,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 7,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };
        const responsive = {          

            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items:  this.props.userMdl.activeProgram !== 'TSM_SURVEYS' ? 7 : 5
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 3
            },
            mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 2
            }
        };
        return (
            <div className="dashboardDiv">
                <Carousel
                    swipeable={false}
                    draggable={false}
                    showDots={false}
                    responsive={responsive}
                    infinite={false}
                    keyBoardControl={true}
                    customTransition="all .5"
                    transitionDuration={500}
                    containerClass="carousel-container"
                    itemClass="carousel-item-padding-40-px">
                    {/* <Slider {...settings}> */}
                    <MDBCard className="scrollable-widgets">
                        <Activation
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl}
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>
                    <MDBCard className="scrollable-widgets">
                        <ActiveRetailers
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl}
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>
                    <MDBCard className="scrollable-widgets">
                        <Activities
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl}
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>
                    <MDBCard className="scrollable-widgets">
                        <ActivitiesDue
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl}
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>
                    <MDBCard className="scrollable-widgets">
                        <OverdueActivities
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl} 
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>
                    <MDBCard className="scrollable-widgets">
                        <AvgDaysPerToolbox
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl} 
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard>

                 {this.props.userMdl.activeProgram !== 'TSM_SURVEYS' &&
                     <MDBCard className="scrollable-widgets">
                        <CertificationRate 
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl} 
                            nmscFlag={this.props.nmscFlag}/>
                    </MDBCard> }
                    {this.props.userMdl.activeProgram !== 'TSM_SURVEYS' &&
                     <MDBCard className="scrollable-widgets"><ReCertificationRate   
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl} 
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCard> }
                    {/* </Slider> */}
                </Carousel>
                <MDBRow className="mt-3 mb-5 mr-2">
                    <MDBCol lg="4" md="6" className="ket-widget">
                        <RecentUpdates
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl} />
                    </MDBCol>
                    <MDBCol lg="4" md="6">
                        <MDBRow className="ket-widget mb-2">
                            <BusinessResults
                                nmscFilter={this.props.nmscFilter}
                                brandFilter={this.props.brandFilter}
                                countryFilter={this.props.countryFilter}
                                regionFilter={this.props.regionFilter}
                                userMdl={this.props.userMdl} />
                        </MDBRow>
                        <MDBRow className="ket-widget mb-2"><BusinessResultsTopNmsc /></MDBRow>
                        
                        {this.props.userMdl.activeProgram !== 'TSM_SURVEYS' &&
                        <MDBRow className="ket-widget mb-0" style={{  }}>
                            {!this.props.nmscFlag ?<RolloutResultsTopNmsc 
                                nmscFilter={this.props.nmscFilter}
                                brandFilter={this.props.brandFilter}
                                countryFilter={this.props.countryFilter}
                                regionFilter={this.props.regionFilter}
                                userMdl={this.props.userMdl} />:
                            <OverdueActivitiesForNMSC
                                nmscFilter={this.props.nmscFilter}
                                brandFilter={this.props.brandFilter}
                                countryFilter={this.props.countryFilter}
                                regionFilter={this.props.regionFilter}
                                userMdl={this.props.userMdl}
                                nmscFlag={this.props.nmscFlag}
                            />}
                        </MDBRow>
    }
                    </MDBCol>
                    <MDBCol lg="4" md="6" className="ket-widget">
                        <ToolboxImplementation
                            nmscFilter={this.props.nmscFilter}
                            brandFilter={this.props.brandFilter}
                            countryFilter={this.props.countryFilter}
                            regionFilter={this.props.regionFilter}
                            userMdl={this.props.userMdl}
                            nmscFlag={this.props.nmscFlag} />
                    </MDBCol>
                </MDBRow>
            </div>
        );
    }
}
export default withRouter((withTheme(WidgetContainer)));
