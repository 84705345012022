import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../../themes/theming";
import BootstrapTable from "react-bootstrap-table-next";
import SearchUser from "../../userManagement/components/searchUser";
import * as AppConst from "../../../AppConstant";
import paginationFactory from "react-bootstrap-table2-paginator";
import {
  fetchUserSearchFields,
  getuserDetailsData,
  searchEmptyUserList,
} from "../../../actions/userManagementAction";
import { exportToExcelCall } from "../../../actions/dashboardAction";
import {
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "./../index.css";
const $ = require("jquery");
window.jQuery = $;
let I18n = require("react-redux-i18n").I18n;

class LatestFilePanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSearchClicked: false,
      showSearchFlag: true,
      nmscFlag: false,
      searchDto: {
        nmscIdList: null,
        tarsId: null,
        firstName: null,
        lastName: null,
        email: null,
        roleList: null,
        statusList: null,
        downloadedList: [],
        adminUserType: null,
        adminUserLangCode: this.props.user.userMdl.lang,
        loggedInUser: this.props.user.userMdl.username,
        adminUserNmscCode: this.props.user.userMdl.nmsc,
        page: 0,
        sizePerPage: 10,
        rows: 10,
        fileId: null,
      },
      data: [],
      records: 0,
      total: 0,
      sortField: null,
      sortOrder: "asc",
    };
  }

  componentWillMount() {
    let searchDTO = { ...this.state.searchDto };
    console.log("searchDTO", searchDTO);
    if (
      this.props.user.userMdl.roles.includes(
        AppConst.REACHIT_ROLES.KET_REACHIT_Admin
      )
    ) {
      searchDTO["adminUserType"] = "TME";
      searchDTO["isTMEAdminUser"] = true;
    } else {
      searchDTO["adminUserType"] = "NMSC";
      searchDTO["isNMSCAdminUser"] = true;      
    }
  
    this.props.dispatch(fetchUserSearchFields(searchDTO));
    if(searchDTO["adminUserType"]==="NMSC"){
      searchDTO["nmscIdList"] = [this.props.user.userMdl.nmsc];
    }
    searchDTO["roleList"] = [this.props.reachItRoleList];
    searchDTO["fileId"] = this.props.fileData.fileId;
    this.setState({ searchDto: searchDTO });
    this.props.dispatch(getuserDetailsData(searchDTO));
  }

  search = (userDTO) => {
    userDTO["roleList"] = [this.props.reachItRoleList];
    userDTO["fileId"] = this.props.fileData.fileId;
    this.setState({ searchDto: userDTO });
    this.props.dispatch(getuserDetailsData(userDTO));
  };

  onExcelClick = (userDTO) => {
    userDTO["roleList"] = [this.props.reachItRoleList];
    userDTO["fileId"] = this.props.fileData.fileId;
    this.setState({ searchDto: userDTO });
    this.props.dispatch(exportToExcelCall(userDTO));
  };

  componentWillUnmount() {
    this.setState({
      data: [],
    });
    this.props.dispatch(searchEmptyUserList());
  }

  updateSearchDto = (userDTO) => {
    this.setState({ searchDto: userDTO });
  };

  preventDefault() {}

  pageChanged(page, sizePerPage) {
    const searchDto = { ...this.state.searchDto };
    searchDto["page"] = page;
    searchDto["rows"] = sizePerPage;
    searchDto["sizePerPage"] = sizePerPage;
    this.setState({ searchDto });
    this.search(searchDto);
  }

  handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
    if (!sortField) {
      const searchDto = { ...this.state.searchDto };
      searchDto["roleList"] = [this.props.reachItRoleList];
      searchDto["fileId"] = this.props.fileData.fileId;
      searchDto["page"] = page;
      searchDto["rows"] = sizePerPage;
      searchDto["sizePerPage"] = sizePerPage;
      this.setState({ searchDto });
      if (this.state.records > 0) {
        this.props.dispatch(getuserDetailsData(searchDto));
      }
    } else if (this.state.records > 0) {
      if (this.state.sortOrder === "asc") {
        sortOrder = "desc";
        this.setState({ sortOrder: "desc" });
      } else {
        sortOrder = "asc";
        this.setState({ sortOrder: "asc" });
      }
      this.setState({ sortField: this.indexOfSortField(sortField) });
      const searchDto = { ...this.state.searchDto };
      searchDto["roleList"] = [this.props.reachItRoleList];
      searchDto["fileId"] = this.props.fileData.fileId;
      searchDto["sortOrder"] = sortOrder;
      searchDto["sortCol"] = sortField;
      this.setState({ searchDto });
      this.props.dispatch(getuserDetailsData(searchDto));
    }
  };

  indexOfSortField(sortField) {
    switch (sortField) {
      case "nmscId":
        return 1;
      case "tarsId":
        return 2;
      case "firstName":
        return 3;
      case "lastName":
        return 4;
      case "emailId":
        return 5;
      case "downloadFlag":
        return 7;
      default: 
      return "nmscId";
    }
  }

  componentWillReceiveProps(nextProps) {
    let searchDTO = { ...this.state.searchDto };
    if(searchDTO["adminUserType"]==="NMSC"){
      searchDTO["nmscIdList"] = [this.props.user.userMdl.nmsc];
    }
    if (nextProps.userManagementDetails !== null) {
      if (nextProps.userManagementDetails.records > 0) {
        this.setState({
          data: nextProps.userManagementDetails.userManagementDTOList,
        });
        this.setState({ records: nextProps.userManagementDetails.records });
        this.setState({ total: nextProps.userManagementDetails.total });        
        searchDTO["page"] = nextProps.userManagementDetails.page;
        this.setState({ searchDto: searchDTO });
      } else {
        this.setState({ data: [] });
        this.setState({ records: 0 });
        this.setState({ total: 0 });
      }
      var index = this.state.sortField;
      if (index) {
        setTimeout(() => {
          if (this.state.sortOrder === "asc") {
            $(".actionPlanTableModal .dropdown").addClass("hideSortIcon");
            $(".actionPlanTableModal .dropup").addClass("hideSortIcon");
            $(
              ".actionPlanTableModal > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" +
                index +
                ") > span > span.dropup"
            ).addClass("showSortIcon");
          } else {
            $(".actionPlanTableModal .dropdown").addClass("hideSortIcon");
            $(".actionPlanTableModal .dropup").addClass("hideSortIcon");
            $(
              ".actionPlanTableModal > div > div.react-bootstrap-table > table > thead > tr > th:nth-child(" +
                index +
                ") > span > span.dropdown"
            ).addClass("showSortIcon");
          }
        }, 60000);
      }
    } else {
      this.setState({ data: [] });
      this.setState({ records: 0 });
      this.setState({ total: 0 });
    }
  }

  nmscFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.nmscId}
      </div>
    );
  };

  tarsIDFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.tarsId}
      </div>
    );
  };

  countryFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.country}</div>
    );
  }
  dealerFormatter = (cell, row) => {
    return (
      <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}>{row.dealerName}</div>
    );
  }
  firstNameFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.firstName}
      </div>
    );
  };

  lastNameFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.lastName}
      </div>
    );
  };

  emailIdFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.email}
      </div>
    );
  };

  statusFormatter(cell, row) {
    return (
      <div
        title={
          row.downloadFlag && row.downloadFlag !== null
            ? I18n.t("reachItDashboard.Downloaded")
            : I18n.t("reachItDashboard.not") +
              " " +
              I18n.t("reachItDashboard.Downloaded")
        }
      >
        {row.downloadFlag && row.downloadFlag !== null
          ? I18n.t("reachItDashboard.Downloaded")
          : I18n.t("reachItDashboard.not") +
            " " +
            I18n.t("reachItDashboard.Downloaded")}
      </div>
    );
  }

  render() {
    const columnsNmsc = [
      {
        dataField: "nmscId",
        text: I18n.t("UserManagement.nmscId"),
        sort: true,
        style: { width: "9.2%", textAlign: "left" },
        headerStyle: { width: "9%", textAlign: "left" },
        formatter: this.nmscFormatter,
      },
      {
        dataField: "tarsId",
        text: I18n.t("UserManagement.tarsId"),
        sort: true,
        style: { width: "15.7%", textAlign: "left" },
        headerStyle: { width: "15%", textAlign: "left" },
        formatter: this.tarsIDFormatter,
        title: true,
      },
      {
        dataField: "firstName",
        text: I18n.t("UserManagement.firstName"),
        sort: true,
        style: { width: "10.9%", textAlign: "left" },
        formatter: this.firstNameFormatter,
        headerStyle: { width: "10.7%", textAlign: "left" },
        title: true,
      },
      {
        dataField: "lastName",
        text: I18n.t("UserManagement.lastName"),
        sort: true,
        style: { width: "11.1%", textAlign: "left" },
        formatter: this.lastNameFormatter,
        headerStyle: { width: "11.7%", textAlign: "left" },
        title: true,
      },
      {
        dataField: "email",
        text: I18n.t("UserManagement.emailId"),
        sort: true,
        style: { width: "16%", textAlign: "left" },
        formatter: this.emailIdFormatter,
        headerStyle: { width: "15.7%", textAlign: "left" },
        title: true,
      },
      {
        dataField: "dealerName",
        text: I18n.t("Table.dealerName"),
        sort: true,
        style: { width: "12%", textAlign: "left" },        
        headerStyle: { width: "12%", textAlign: "left" },
        formatter: this.dealerFormatter,
        title: true
      },
      {
        dataField: "country",
        text: I18n.t("Table.country"),
        sort: true,
        style: { width: "10.9%", textAlign: "left" },
        headerStyle: { width: "10.7%", textAlign: "left" },
        formatter: this.countryFormatter,
        title: true,
      },
      {
        dataField: "downloadFlag",
        text: I18n.t("Table.status"),
        formatter: this.statusFormatter,
        sort: true,
        style: { width: "12%", textAlign: "left" },
        headerStyle: { width: "11.5%", textAlign: "left" },
      },
    ];
    const columnsAdmin = [
      {
        dataField: "nmscId",
        text: I18n.t("UserManagement.nmscId"),
        sort: true,
        style: { width: "10.2%", textAlign: "left" },
        headerStyle: { width: "10%", textAlign: "left" },
        formatter: this.nmscFormatter,
      },
      {
        dataField: "tarsId",
        text: I18n.t("UserManagement.tarsId"),
        sort: true,
        style: { width: "15.7%", textAlign: "left" },
        headerStyle: { width: "15.7%", textAlign: "left" },
        formatter: this.tarsIDFormatter,
        title: true,
      },
      {
        dataField: "firstName",
        text: I18n.t("UserManagement.firstName"),
        sort: true,
        style: { width: "12.9%", textAlign: "left" },
        formatter: this.firstNameFormatter,
        headerStyle: { width: "12.7%", textAlign: "left" },
        title: true,
      },
      {
        dataField: "lastName",
        text: I18n.t("UserManagement.lastName"),
        sort: true,
        style: { width: "13.1%", textAlign: "left" },
        formatter: this.lastNameFormatter,
        headerStyle: { width: "13.7%", textAlign: "left" },
        title: true,
      },

      {
        dataField: "email",
        text: I18n.t("UserManagement.emailId"),
        sort: true,
        style: { width: "17%", textAlign: "left" },
        formatter: this.emailIdFormatter,
        headerStyle: { width: "16.7%", textAlign: "left" },
        title: true,
      },
      {
        dataField: "country",
        text: I18n.t("Table.country"),
        sort: true,
        style: { width: "15.9%", textAlign: "left" },
        headerStyle: { width: "15.7%", textAlign: "left" },
        formatter: this.countryFormatter,
        title: true,
      },

      {
        dataField: "downloadFlag",
        text: I18n.t("Table.status"),
        formatter: this.statusFormatter,
        sort: true,
        style: { width: "12%", textAlign: "left" },
        headerStyle: { width: "12.5%", textAlign: "left" },
      },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        const selectedRow = { ...this.state.selectedRow };
        selectedRow["networkUserId"] = row.networkUserId;
        selectedRow["nmscId"] = "" + row.nmscId;
        selectedRow["tarsId"] = "" + row.tarsId;
        selectedRow["firstName"] = row.firstName;
        selectedRow["lastName"] = row.lastName;
        selectedRow["roles"] = row.role;
        selectedRow["status"] = row.status;
        selectedRow["languageCode"] = row.languageCode;
        selectedRow["userMdl"] = this.props.user.userMdl;
        selectedRow["adminNmscCode"] = this.props.user.userMdl.nmsc;
        selectedRow["adminLangCode"] = this.props.user.userMdl.lang;
        selectedRow["adminLoginId"] = this.props.user.userMdl.username;
        this.setState({ selectedRow });
      },
    };

    const { data } = this.state;
    const { sizePerPage, page } = this.state.searchDto;
    const RemotePagination = ({
      data,
      page,
      sizePerPage,
      onTableChange,
      totalSize,
    }) => (
      <div className={"activityTable parent-" + this.props.theme.name}>
        <BootstrapTable
          remote
          keyField="netwoekUserId"
          noDataIndication={I18n.t("UserManagement.No User Found")}
          data={data}
          columns={
            this.props.user.userMdl.roles.includes(
              AppConst.REACHIT_ROLES.KET_REACHIT_Admin
            )
              ? columnsAdmin
              : columnsNmsc
          }
          pagination={paginationFactory({ page, sizePerPage, totalSize })}
          onTableChange={onTableChange}
          rowEvents={rowEvents}
        />
      </div>
    );
    return (
      <Container
        fluid
        className="ml-0 mb-2 pb-0"
        style={{
          // border: "1px solid grey",
          borderRadius: "unset",
          padding: "unset",
        }}
      >
        <Modal
          className={
            "targetClassNew addActionActivityModal-" + this.props.theme.name
          }
          id="addActionActivityModal"
          isOpen={true}
          toggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={this.props.toggle}>
            {I18n.t("reachItDashboard.fileStatistics")}
          </ModalHeader>
          <ModalBody>
            <div className="actionKpiContainer">
              <div className="row">
                <SearchUser
                  flag={this.state.showSearchFlag}
                  searchDto={this.state.searchDto}
                  search={this.search}
                  onExcelClick={this.onExcelClick}
                  update={this.updateSearchDto}
                />
                <div className="actionPlanTableModal tblht targetClassNew bgclr">
                  <RemotePagination
                    data={data}
                    page={page}
                    sizePerPage={sizePerPage}
                    totalSize={this.state.records}
                    onTableChange={this.handleTableChange}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    userManagementDetails: state.userManagementReducer.userManagementDetails,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(LatestFilePanel)));