import { Col, Container, Fa, Row } from "mdbreact";
import React from "react";
import { connect } from "react-redux";
import { toastr } from "react-redux-toastr";
import { withRouter } from "react-router-dom";
import "react-super-treeview/dist/style.css";
import { Treebeard } from 'react-treebeard';
import { deleteFolder, fetchTmeFolders, publishLibraryFiles, fetchNmscFolders, deleteNmscFolder, publishNmscLibraryFiles,fetchNmscPublishedFolders,deleteAllLibraries } from '../../actions/libraryAction';
import { withTheme } from "../../themes/theming";
import { confirmAlert } from 'react-confirm-alert'; // Import
import AddEditFolder from "./components/addEditFolder";
import AddEditFile from "./components/addEditFile";
import LibraryDetails from './components/libraryDetails';
import ChipsFile from './components/chipsFile';
import "./index.css";
import Style from './style';
import { Tab, Tabs } from "react-bootstrap-tabs";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;
const $ = require('jquery')

class Library extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            modal: false,
            modalFor: "",
            fileModal: false,
            fileModalFor: "",
            data: [],
            selectedNodeId: '',
            selectedFiles: [],
            chipData: [],
            row: '',
            showDelete: false,
            showEdit: false,
            isMainFolderFlag: false,
            isNmsc: false,
            activeTabNmscLang: ""
        };
        this.onToggle = this.onToggle.bind(this);
        this.fileListFlag = this.fileListFlag.bind(this);
        this.selectedFiles = this.selectedFiles.bind(this);
        this.publishLibrary = this.publishLibrary.bind(this);
        this.handleChipsData = this.handleChipsData.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.toolboxId !== this.props.toolboxId) {
            this.setState({ showDelete: false, showEdit: false, chipData: [], selectedFiles: [], selectedNodeId: '',cursor:null })
        }
        $(".lib-node").unbind().on('click', function () {
            let element = $(this).find('.libraryToggleArrow')[0];
            if (element !== undefined) {
                if (element.classList.contains('rotate-true')) {
                    $(element).removeClass('rotate-true').addClass('rotate-false');
                } else {
                    $(element).removeClass('rotate-false').addClass('rotate-true');
                }
            }
        });
    }
    componentDidMount() {
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
                    isNMSCAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;

            }
        }
        this.setState({ isNmsc: isNMSCAdminUser });
        this.setState({ isNotAdmin: isNotAdmin });
        if (isNMSCAdminUser || isNotAdmin) {
            let nmscCode = this.props.user.userMdl.nmsc;
            let langCode = 'TME';
            let selectedTabIndex=0;
            this.props.user.userMdl.userLangList.map((obj, index) => {
               if(obj.language.toUpperCase() === this.props.user.userMdl.lang.toUpperCase())
              {
                langCode=obj.language;
                selectedTabIndex=index+1;
              }
            });
            this.setState({ selectedTab: selectedTabIndex});
            if (isNMSCAdminUser) {
                this.setState({activeTabNmscLang:langCode});
                this.props.dispatch(fetchNmscFolders(this.props.packageMapPrimaryKey, null, null, nmscCode, langCode));
            }
            else {
                this.props.dispatch(fetchNmscPublishedFolders(this.props.packageMapPrimaryKey, null, null, nmscCode, this.props.user.userMdl.lang));
            }
        } else {
            this.props.dispatch(fetchTmeFolders(this.props.packageMapPrimaryKey));
        }
    }
    componentWillReceiveProps(nextProps) {
        
        if (nextProps.libraryReducer.closeModal) {
            // Close Modal Window.
            this.setState({ modal: false })
        }
        if (nextProps.libraryReducer.refreshRequired) {
            let values = [];
            if (nextProps.libraryReducer.folderId !== undefined) {
                values = nextProps.libraryReducer.folderId.split('|');
            }
            let id = values[0] !== undefined ? values[0] : 0;
            let name = values[1] !== undefined ? values[1] : '';
            // Get latest list of Library folders.
            if (this.state.cursor) {
                if (this.state.isNmsc) {
                    if (this.state.modalFor === "Edit") {
                        var parentFolderId=0;
                    var data=nextProps.libraryReducer.data;
                    data.map((folder)=>{
                        if(folder.children && folder.children.length>0){
                            folder.children.map((child)=>{
                                if(child.id === id){
                                    parentFolderId=folder.id;
                                }
                            })
                            
                        }
                    });
                    this.props.dispatch(fetchNmscFolders(this.props.packageMapPrimaryKey, id, parentFolderId, this.props.user.userMdl.nmsc, this.state.activeTabNmscLang));
                }
                else{
                this.props.dispatch(fetchNmscFolders(this.props.packageMapPrimaryKey, id, this.state.cursor.id, this.props.user.userMdl.nmsc, this.state.activeTabNmscLang));}
                } else if (this.state.isNotAdmin) {
                    this.props.dispatch(fetchNmscPublishedFolders(this.props.packageMapPrimaryKey, id, this.state.parentFolderId,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
                }
                else {
                    if (this.state.modalFor === "Edit") {
                        var parentFolderId=0;
                    var data=nextProps.libraryReducer.data;
                    data.map((folder)=>{
                        if(folder.children && folder.children.length>0){
                            folder.children.map((child)=>{
                                if(child.id === id){
                                    parentFolderId=folder.id;
                                }
                            })
                            
                        }
                    });
                    this.props.dispatch(fetchTmeFolders(this.props.packageMapPrimaryKey, id, parentFolderId));
                }
                else{
                    this.props.dispatch(fetchTmeFolders(this.props.packageMapPrimaryKey, id, this.state.cursor.id));
                }
                }
                // this.props.dispatch(fetchTmeFolders(this.props.toolboxId, id, this.state.parentFolderId));	
                this.setState({ selectedNodeId: id });
                var cursor = this.state.cursor;
                cursor.id = id;
                cursor.name = name;
                if (this.state.modalFor === "Add") {
                    cursor.isParent = false;
                }
                this.setState({ cursor: cursor });
            }
            else {
                if (this.state.isNmsc) {
                    this.props.dispatch(fetchNmscFolders(this.props.packageMapPrimaryKey, id, null,this.props.user.userMdl.nmsc, this.state.activeTabNmscLang));
                } else if (this.state.isNotAdmin) {
                    this.props.dispatch(fetchNmscPublishedFolders(this.props.packageMapPrimaryKey, id, this.state.parentFolderId,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
                } else {
                    this.props.dispatch(fetchTmeFolders(this.props.packageMapPrimaryKey, id));
                }
                // this.props.dispatch(fetchTmeFolders(this.props.toolboxId, id));
                this.setState({ selectedNodeId: id });
                var cursor = {};
                cursor.id = id;
                cursor.name = name;
                cursor.isParent = true;
                if(cursor.id!==0){
                this.setState({ cursor: cursor });
                }
                else{
                this.setState({ cursor: null });
                } }
            this.setState({ showDelete: false, showEdit: false })
            // alert('Refresh!!' + this.props.toolboxId);
            this.props.dispatch({
                type: "REFRESH_DONE",
                payload: ''
            });
        }
        if (nextProps.libraryReducer.data) {
            this.setState({ data: nextProps.libraryReducer.data });
        }
        if (this.props.toolboxId !== nextProps.toolboxId) {
            if (this.state.isNmsc) {
                this.props.dispatch(fetchNmscFolders(nextProps.packageMapPrimaryKey, null, null, this.props.user.userMdl.nmsc, this.state.activeTabNmscLang));
            } else if (this.state.isNotAdmin) {
                this.props.dispatch(fetchNmscPublishedFolders(nextProps.packageMapPrimaryKey, null, null, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang));
            } else {
                this.props.dispatch(fetchTmeFolders(nextProps.packageMapPrimaryKey));
            }
            // this.props.dispatch(fetchTmeFolders(nextProps.toolboxId));
        }

        if (this.state.cursor && this.state.cursor.id !== 0 && !this.state.cursor.isParent) {
            this.setState({ isMainFolderFlag: false });
        }
        else {
            this.setState({ isMainFolderFlag: true });
        }
    }


    activeNMSCTabLink = (activeTabNmscLang) => {
        this.setState({ activeTabNmscLang: activeTabNmscLang })
    }

    selectedTab = (index, label) => {

        this.props.dispatch({
            type: "NMSC_FILE_LIST",
            payload: []
        });

        let activeTabNmscLang = label.split(" / ")[1];

        //if selected tab is TME Master then set default language as 'def'
        if (label === 'TME Master') { activeTabNmscLang = 'TME' }

        this.setState({ selectedTab: index, activeTabNmscLang: activeTabNmscLang ,chipData:[],selectedFiles: []});
        this.activeNMSCTabLink(activeTabNmscLang);
        if (this.state.isNmsc) {
            this.props.dispatch(fetchNmscFolders(this.props.packageMapPrimaryKey, null, null, this.props.user.userMdl.nmsc, activeTabNmscLang));
        }
        else {
              this.props.dispatch(fetchNmscPublishedFolders(this.props.packageMapPrimaryKey, null, null, this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
        }

        this.setState({ showDelete: false, showEdit: false, chipData: [], selectedFiles: [], selectedNodeId: '',cursor:null })

    };

    deactiveNode = (currentNode) => {
        var flag = false;
        var nodeList = this.state.data;
        for (var i = 0; i < nodeList.length; i++) {
            var parn = nodeList[i];
            var node = nodeList[i].children;
            if (node) {
                for (var j = 0; j < node.length; j++) {
                    var child = node[j];
                    if (child.id !== currentNode.id) {
                        child.active = false;
                    }
                    else {
                        flag = true
                    }
                }
            }
            if (!flag) {
                parn.toggled = false;
                parn.active = false;
            }

        }
        this.setState({ questionLeftPane: nodeList })
    }
    onToggle(node, toggled) {
        //To underline 'TME Library' text when clicked
        this.setState({textUnderline:''});        
        this.deactiveNode(node)
        if (this.state.cursor) { this.state.cursor.active = false; }
        node.active = true;
        if (node.children) { node.toggled = toggled; }
        if (node.isParent) {
            this.setState({ parentFolderId: node.id });
        }
        this.setState({ cursor: node, selectedNodeId: node.id, showDelete: true, showEdit: true });
    }
    selectedFiles(folderFiles) {
        let isReplaced = false;
        let alreadySelected = [...this.state.selectedFiles];
        let index = -1;
        if (alreadySelected.length > 0) {
            alreadySelected.map((folder) => {
                if (folder.folderId === folderFiles.folderId && index === -1) {
                    index = alreadySelected.indexOf(folder);
                    isReplaced = true;
                }
            })
            if (!isReplaced) {
                alreadySelected.push(folderFiles);
            }
            else {
                alreadySelected.splice(index, 1);
                alreadySelected.push(folderFiles);

            }
        }
        else {
            alreadySelected.push(folderFiles);
        }
        console.log(alreadySelected);
        let chipData = [];
        alreadySelected.map((file) => {
            if (file.files.length > 0) {
                file.files.map((data) => {
                    let format = {
                        key: data.fileId,
                        label: data.fileName
                    }
                    chipData.push(format);
                })
            }
        })
        this.setState({ selectedFiles: alreadySelected, chipData: chipData });
    }
    navAction(action) {
        if (action === 'Add') {
            if (this.isAdditionAllowed()) {
                this.toggleModal(action);
            } else {
                toastr.error(I18n.t("library.folderCreationErr"))
            }
        } else if (action === 'Edit') {
            this.toggleModal(action);
        } else {
            //alert("Delete Confirmation Box");
            if (this.state.cursor && this.state.cursor.id !== 0) {
                if (this.state.fileListFlag !== true) {
                    confirmAlert({
                        customUI: ({ title, message, onClose }) => {
                            return (
                                <div className="deleteConfirmBox">
                                    <div className={"titleClass-" + this.props.theme.name}>
                                        {I18n.t('standardMessages.deleteLibraryFolder')}
                                    </div>
                                    <div className="modal-footer" style={{ borderTop: "0px" }}>
                                        <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                            {I18n.t("standardMessages.no")}
                                        </button>
                                        <button
                                            className={"button-" + this.props.theme.name}
                                            onClick={() => {
                                                // console.log("check "+this.state.fileListFlag
                                                this.setState({ cursor: null });
                                                if (this.state.isNmsc) {
                                                    this.props.dispatch(deleteNmscFolder(this.state.cursor.id, this.props.packageMapPrimaryKey, this.state.activeTabNmscLang, this.props.user.userMdl.nmsc))
                                                }
                                                else {
                                                    this.props.dispatch(deleteFolder(this.state.cursor.id, this.props.packageMapPrimaryKey))
                                                }
                                                onClose();

                                            }}
                                        >
                                            {I18n.t("standardMessages.yes")}
                                        </button>
                                    </div>
                                </div>
                            );
                        }
                    });
                }
                else {
                    confirmAlert({
                        customUI: ({ title, message, onClose }) => {
                            return (
                                <div className="deleteConfirmBox">
                                    <div className={"titleClass-" + this.props.theme.name}>
                                        {I18n.t('standardMessages.deletePublishedFolder')}
                                    </div>
                                    <div className="modal-footer" style={{ borderTop: "0px" }}>
                                        <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                            {I18n.t("standardMessages.no")}
                                        </button>
                                        <button
                                            className={"button-" + this.props.theme.name}
                                            onClick={() => {
                                                // console.log("check "+this.state.fileListFlag
                                                if (this.state.isNmsc) {
                                                    this.props.dispatch(deleteNmscFolder(this.state.cursor.id, this.props.packageMapPrimaryKey, this.state.activeTabNmscLang, this.props.user.userMdl.nmsc))
                                                }
                                                else {
                                                    this.props.dispatch(deleteFolder(this.state.cursor.id, this.props.packageMapPrimaryKey))
                                                }
                                                onClose();

                                            }}
                                        >
                                            {I18n.t("standardMessages.yes")}
                                        </button>
                                    </div>
                                </div>
                            );
                        }
                    });
                }
                // this.props.dispatch(deleteFolder(this.state.cursor.id, this.props.toolboxId));
            } else {
                toastr.error(I18n.t("library.deleteWarning"))
            }
        }
    }
    showInfo() {
        alert("Info");
    }
    isAdditionAllowed() {
        let parentIdList = [];
        this.state.data.forEach(d => {
            parentIdList.push(d.id);
        });
        if (this.state.cursor && this.state.cursor.id && parentIdList.indexOf(Number(this.state.cursor.id)) === -1) {
            return false;
        }
        return true;
    }
    handleChipsData(deletedData) {
        //handle selectedFiles and filesForPublish variables of state
        let key = deletedData.key;
        let indexPublishFiles = -1;
        let indexSelectedFiles = -1;
        let data = [...this.state.selectedFiles];
        data.map((folder) => {
            folder.files.map((file) => {
                if (file.fileId === key && indexSelectedFiles === -1) {
                    indexSelectedFiles = folder.files.indexOf(file);
                }
            })
            if (indexSelectedFiles !== -1) {
                folder.files.splice(indexSelectedFiles, 1);
            }
        })
        let chipData = [...this.state.chipData];
        let chipIndex = -1;
        chipData.map((chip) => {
            if (chip.key === key && chipIndex === -1) {
                chipIndex = chipData.indexOf(chip);
            }
        });
        chipData.splice(chipIndex, 1);
        this.setState({ selectedFiles: data, chipData: chipData });;


    }
    publishLibrary() {
        // alert('publish library');
        let fileList = [];
        if (this.state.selectedFiles.length > 0) {
            this.state.selectedFiles.map((folder) => {
                let files = [...folder.files];
                if (files.length > 0) {
                    files.map((file) => {
                        fileList.push(file.fileId);
                    })
                }
                console.log(fileList);
            })
            let lastFolder = this.state.selectedFiles[this.state.selectedFiles.length - 1].folderId;
            if (fileList.length > 0) {
                if(this.state.isNmsc){
                    this.props.dispatch(publishNmscLibraryFiles(lastFolder, fileList,this.props.user.userMdl.nmsc));
                    }
                    else{
                    this.props.dispatch(publishLibraryFiles(lastFolder, fileList));
                    }
            }
            this.setState({ selectedFiles: [], chipData: [] });
        } else {
            toastr.error(I18n.t("library.publish_library_file"));
        }
    }
    traverse(node, path, list) {
        let that = this;
        if (!path) {
            path = [];
        }
        let obj = {
            id: node.id,
            name: node.name
        };
        path.push(obj);
        list.push(path);
        if (node.children) {
            node.children.forEach(function (item) {
                let newPath = [];
                newPath.push(path);
                that.traverse(item, newPath, list);
            });
        }
    }
    getFolderPath() {
        if (this.state.cursor) {
            let currentTarget = this.state.cursor.id;
            var arrayList = []
            for (var i = 0; i < this.state.data.length; i++) {
                var list = [];
                this.traverse(this.state.data[i], [], list);
                arrayList.push(list);
            }
            // get Current Target from list.
            for (var index in arrayList) {
                let objArray = arrayList[index];
                // Iterate internal Array.
                for (i in objArray) {
                    console.log('objArray[i][objArray[i].length - 1].id' + objArray[i][objArray[i].length - 1].id);
                    console.log('currentTarget' + currentTarget);
                    if (objArray[i][objArray[i].length - 1].id === currentTarget) {
                        console.log('matched');
                        return <div>
                            <Fa icon="folder" fixed className="mr-1" size="lg" style={{ color: 'rgb(255, 187, 51)' }} />
                            {/* <i className="fa fa-folder" style={{color: 'rgb(255, 187, 51)'}}/> */}
                            <span style={{ color: 'dodgerblue', fontSize: '13px', paddingLeft: '1%' }} >
                                {this.convertListToPath(objArray[i])}
                            </span>
                        </div >;
                    }
                }
            }
        }
    }
    togglePane = () => {
        $(".rightPanel").hide();
        $("#libSideNav").toggle("fast", function () {
            let width = $(".rightPanel").width() / $(".rightPanel").parent().width() * 100;
            if (width > 90) {
                $(".rightPanel").css({ "width": "72%" });
            } else {
                $(".rightPanel").css({ "width": "97%" });
            }
            $(".rightPanel").show();
        });
    }
    convertListToPath(list) {
        let path = "";
        for (var i = 0; i < list.length; i++) {
            let name = "";
            if (list[i].constructor === Array) {
                for (var j = 0; j < list[i].length; j++) {
                    name = name + list[i][j].name;
                    if (j < list[i].length - 1) {
                        name = name + " > ";
                    }
                }
            } else {
                name = list[i].name;
            }
            path = path + name;
            if (i < list.length - 1) {
                path = path + " > ";
            }
        }
        return path;
    }
    toggleModal = (mode) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: mode
        });
    }
    clearSelection = () => {
        //To remove underline from 'TME Library' text when clicked on folde/subfolder
        this.setState({textUnderline:'libraryRoot_underline'})
        this.setState({ cursor: null, showDelete: false, showEdit: false });
        $('.activeFolder').removeClass('activeFolder');
    }
    toggleFileModal = (mode, row) => {
        this.setState({
            fileModal: !this.state.fileModal,
            fileModalFor: mode,
            row: row
        });
    }
    fileListFlag = (fileListFlag) => {
        this.setState({ fileListFlag: fileListFlag });
    }
    addFile = () => {
        if (this.state.cursor && this.state.cursor.id !== 0 && !this.state.cursor.isParent) {
            this.toggleFileModal('Add');
        } else {
            toastr.error(I18n.t('library.subFolderWarning'))
        }
    }
    deleteLibrariesConfirm = () => {
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t('standardMessages.deleteAllLibraries')}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.deleteLibraries();
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });    
    }
    deleteLibraries = () => {
        this.props.dispatch(deleteAllLibraries(this.props.user.userMdl.activeProgramID,this.props.packageMapPrimaryKey));
    }

    render() {
        console.log("Test",this.props)
        if(this.props.theme.name==="lexus" && $('span.toyota')){
            $(".toggleTreebeard").removeClass("toyota");
            $(".toggleTreebeard").addClass("lexus");
            $(".toggleTreebeard").attr("src","../../assets/images/upArrow-lexus.png");
        }
        else if(this.props.theme.name==="toyota" && $('span.lexus')){
            $(".toggleTreebeard").removeClass("lexus");
            $(".toggleTreebeard").addClass("toyota");
            $(".toggleTreebeard").attr("src","../../assets/images/T_Artboard 21mdpi.png");
        }else {
            //..
        }
        let accessType = '';
        if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
            accessType = this.props.user.userMdl.accessControl.filter(obj =>
                obj.function === 'LIBRARY')[0] ? this.props.user.userMdl.accessControl.filter(obj =>
                    obj.function === 'LIBRARY')[0].accessType : "";
        }
        const folderPath = this.getFolderPath();
        const libraryDetails = <LibraryDetails
            toolboxId={this.props.toolboxId}
            selectedNodeId={this.state.selectedNodeId}
            filesForPublish={this.state.selectedFiles}
            toggle={this.toggleFileModal}
            isMainFolderFlag={this.state.isMainFolderFlag}
            fileListFlag={this.fileListFlag}
            selectedFiles={this.selectedFiles}
            isNmsc={this.state.isNmsc}
            isNotAdmin={this.state.isNotAdmin}
            activeTabNmscLang={this.state.activeTabNmscLang} />;
        const chips = <ChipsFile
            handleChipsData={this.handleChipsData}
            chipData={this.state.chipData}
            theme={this.props.theme.name} />

        const dynamicKPITab = this.props.user.userMdl.userLangList.map(
            (obj, index) => {
                return <Tab label={obj.country + " / " + obj.language} key={index} />;
            }
        );

        dynamicKPITab.unshift(<Tab label="TME Master" key='tmeMaster' />);

        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
            var roles = this.props.user.userMdl.roles;
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
                    isNMSCAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN") {
                    isTMEAdminUser = true;
                    isNotAdmin = false;
                    break;
                }
                isNotAdmin = true;
            }
        }
        return (
          <div>
            {accessType === "R/W" || accessType === "R" ? (
              <Container fluid className="ml-0 p-0 libraryContainer">
                {this.props.user.userMdl.roles.includes(AppConst.KET_TME_ADMIN) ? (
                  <button
                    className={"button-addfile-" + this.props.theme.name}
                    title={I18n.t("tmeAdminMaster.delete") + " "+ I18n.t("tabsDetails.library")}
                    style={{ margin: "5px 0px 0px 12px" }}
                    onClick={() => this.deleteLibrariesConfirm()}
                  >
                    {I18n.t("tmeAdminMaster.delete")}{" "}
                  </button>
                ) : (
                  ""
                )}
                <Row style={{ minHeight: "486px", paddingLeft: "5px" }}>
                  <div
                    id="libSideNav"
                    style={{ borderRight: "1px solid lightGray", width: "24%" }}
                  >
                    {accessType === "R/W" &&
                    this.state.activeTabNmscLang !== "TME" ? (
                      <Row
                        className="mb-1 bottomBorder canShrink"
                        style={{ height: "30px" }}
                      >
                        <Col
                          className="d-flex justify-content-end"
                          style={{ display: "inherit", padding: "5px" }}
                        >
                          {this.state.showEdit ? (
                            <a
                              className="canShrink"
                              onClick={() => this.navAction("Edit")}
                            >
                              <div
                                title={I18n.t("tmeAdminMaster.edit")}
                                className="edit-icon pl-0 pr-0"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {this.state.showDelete ? (
                            <a
                              className="canShrink"
                              onClick={() => this.navAction("Delete")}
                            >
                              <div
                                title={I18n.t("tmeAdminMaster.delete")}
                                className="delete-icon pl-0 pr-0"
                                style={{ marginRight: "0px" }}
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          <a
                            className="canShrink"
                            onClick={() => this.navAction("Add")}
                          >
                            <div
                              title={I18n.t("tmeAdminMaster.add")}
                              className="lib-add-icon pl-0 pr-0"
                            />
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        {" "}
                        <Col
                          className="d-flex justify-content-end"
                          style={{ display: "inherit", padding: "5px" }}
                        ></Col>
                      </Row>
                    )}
                    <Row
                      className="pl-3 libraryRoot"
                      onClick={() => this.clearSelection()}
                    >
                      <img
                        src={
                          "../../assets/images/" + this.props.theme.name + "_library.png"
                        }
                        height="25"
                        width="25"
                        alt=""
                      />
                      <span className={this.state.textUnderline}>
                        {isNMSCAdminUser || isNotAdmin
                          ? "NMSC Library"
                          : "TME Library"}
                      </span>
                    </Row>
                    <Row className="libraryTreeWrapper canShrink">
                      <Treebeard
                        data={this.state.data}
                        onToggle={this.onToggle}
                        style={Style}
                        decorators={libraryDecorator}
                      />
                    </Row>
                  </div>
                  <div className="pl-0 pr-0 thickRightBorder">
                    <div
                      className="pl-0 pr-0 d-flex"
                      style={{
                        height: "100%",
                        backgroundColor: "white",
                        cursor: "pointer",
                      }}
                      onClick={() => this.togglePane()}
                    >
                      <img
                        id="toggle"
                        src={
                          "../../assets/images/collapsebtn-" +
                          this.props.theme.name +
                          ".png"
                        }
                        className="align-self-center"
                        style={{ height: "50px", cursor: "pointer" }}
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="pl-1 m-1 rightPanel libRightPanel">
                    {isNMSCAdminUser ? (
                      <Tabs
                        onSelect={this.selectedTab}
                        selected={this.state.selectedTab}
                        defaultActiveKey={1}
                        className={"nmscTabs " + this.props.theme.name}
                      >
                        {dynamicKPITab}
                      </Tabs>
                    ) : (
                      ""
                    )}
                    <Row
                      className="p-1"
                      style={{
                        borderBottom: "1px solid lightGrey",
                        minHeight: "30px",
                      }}
                    >
                      <Col lg="9" md="9" sm="6" xs="6">
                        {folderPath}
                      </Col>
                      <Col
                        lg="3"
                        md="3"
                        sm="6"
                        xs="6"
                        className="d-flex justify-content-end"
                      >
                        {/* <MDBPopover component="div" placement="left" className="lib-info-icon" style={{ cursor: 'pointer', marginTop: '5px', marginRight: '10px' }}>
                                    <MDBPopoverHeader>{this.state.cursor ? this.state.cursor.name : ""}</MDBPopoverHeader>
                                    <MDBPopoverBody>
                                        {this.state.cursor ? this.state.cursor.addInfo : ""}
                                    </MDBPopoverBody>
                                </MDBPopover> */}
                        {/* <div onClick={() => this.showInfo()} title="Info" className="info-icon" style={{cursor: 'pointer', marginTop: '5px', marginRight: '10px'}} /> */}
                        {accessType === "R/W" &&
                        this.state.activeTabNmscLang !== "TME" ? (
                          <button
                            className={
                              "button-addfile-" + this.props.theme.name
                            }
                            onClick={() => this.addFile()}
                          >
                            {" "}
                            {I18n.t("library.addFile")}{" "}
                          </button>
                        ) : null}
                      </Col>
                    </Row>
                    {this.state.isMainFolderFlag ? null : (
                      <Row className="chips">{chips}</Row>
                    )}
                    <Row
                      className={
                        "libraryTable libraryTable-" +
                        (this.state.isMainFolderFlag ? "isMain" : "")
                      }
                    >
                      {libraryDetails}
                    </Row>
                    {accessType === "R/W" &&
                    this.state.activeTabNmscLang !== "TME" ? (
                      <Row>
                        <button
                          className={
                            "canShrink button-" + this.props.theme.name
                          }
                          style={{ fontSize: "1rem" }}
                          onClick={this.publishLibrary}
                        >
                          {" "}
                          {I18n.t("tabsDetails.publish")}{" "}
                        </button>
                      </Row>
                    ) : null}
                  </div>
                </Row>
                {this.state.modal === true ? (
                  <AddEditFolder
                    modal={this.state.modal}
                    toggle={this.toggleModal}
                    modalFor={this.state.modalFor}
                    parentFolder={this.state.cursor}
                    toolboxId={this.props.toolboxId}
                    isNmsc={this.state.isNmsc}
                    nmscCode={this.props.user.userMdl.nmsc}
                    langCode={this.state.activeTabNmscLang}
                    packageMapPrimaryKey={this.props.packageMapPrimaryKey}
                  />
                ) : null}
                {this.state.fileModal === true ? (
                  <AddEditFile
                    modal={this.state.fileModal}
                    toggle={this.toggleFileModal}
                    modalFor={this.state.fileModalFor}
                    parentFolder={this.state.cursor}
                    toolboxId={this.props.toolboxId}
                    row={this.state.row}
                    isNmsc={this.state.isNmsc}
                    activeTabNmscLang={this.state.activeTabNmscLang}
                    packageMapPrimaryKey={this.props.packageMapPrimaryKey}
                  />
                ) : null}
              </Container>
            ) : null}
          </div>
        );
    }
}

const libraryDecorator = {
    Header: ({ node, style }) => {
        const iconClass = node && node.id !== 0 ? 'fa fa-folder' : 'fa fa-tasks-alt';
        const divStyle = node && node.children ? { display: 'inline', paddingLeft: '0px' } : { display: 'inline', paddingLeft: '25px' };
        const iconStyle = { marginRight: '5px', color: '#ffbb33' };
        const name = node && node.name ? node.name : '';
        const textClass = node.active ? 'activeFolder' : '';
        return (
            <div style={divStyle}>
                <i className={iconClass} style={iconStyle} />
                <span className={textClass} style={{ wordBreak: 'break-all' }}>{name}</span>
            </div>
        );
    },
    Toggle: ({ node, style }) => {
        return (
            <span>
                <img src={"../../assets/images/T_Artboard 21mdpi.png"} height={'25px'} width={'25px'} className="toggleTreebeard toyota p-1 rotate-true libraryToggleArrow" alt=""/>
            </span>
            
            
        );
    },
    Loading: (props) => {
        return (
            <div style={props.style}>
                loading...
            </div>
        );
    },
    Container: (props) => {
        return (
            <div className="pl-2 lib-node" onClick={props.onClick}>
                {props.node.children && props.node.children.length > 0 ?
                    <props.decorators.Toggle /> : <div />}
                <props.decorators.Header node={props.node} />
            </div>
        );
    }
};

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        libraryReducer: state.libraryReducer
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(Library))
);
