const translationsHR = {
  hr: {
    Policy: {
      HeaderMsg: "Politika privatnosti za obradu vaših osobnih podataka u okviru Kaizen Expert alata (KET)",
      Message1: "Ova politika privatnosti odnosi se na obradu vaših osobnih podataka u okviru Kaizen Expert alata. Uvijek trebate pročitati navedena pravila zajedno s Općom politikom privatnosti i zaštite osobnih podataka tvrtke Toyota u kojoj su opisani općeniti načini obrade vaših Osobnih podataka i koje morate prihvatiti kako biste mogli nastaviti dalje.",
      Message2: "Toyota Motor Europe NV/SA sa sjedištem u Avenue du Bourget/Bourgetlaan 60, 1140 Bruxelles, Belgija kao 'Voditelj obrade podataka', prikuplja vaše osobne podatke (kako je navedeno u nastavku) u svrhe koje su opisane u nastavku.",
      Message3: "Koji će se vaši osobni podaci prikupljati?",
      Message4: "Prikupljat ćemo slijedeće kategorije vaših osobnih podataka u skladu s Kaizen Expert alatom:",
      Table1: {
        tr1: "Kategorije osobnih podataka:",
        tr2: "Korisnički ID",
        tr3: "Korisničko ime (puno ime i prezime)",
        tr4: "Marka (Toyota, Lexus)",
        tr5: "Odabrani jezik",
        tr6: "Povezana tvrtka"
      },

      Message5: "Svrha i pravne osnove obrade vaših osobnih podataka",
      Message6: "Vaše osobne podatke obrađivat ćemo za svrhe i na pravnim osnovama opisanima u nastavku",
      Table2: {
        tr1: {
          th1: "Svrha:",
          th2: "Pravne osnove:"
        },
        tr2: {
          td1: "KET-1: Kaizen performanse partnera",
          td2: "Zakonski interes voditelja obrade podataka"
        },
        tr3: {
          td1: "KET-2: IT podrška",
          td2: "Zakonski interes voditelja obrade podataka"
        }
      },
      Message7: "Razdoblje čuvanja vaših osobnih podataka",
      Message8: "Vaši osobni podaci bit će čuvani na razdoblje navedeno niže:",
      Table3: {
        tr1: {
          th1: "Razdoblje čuvanja:",
          th2: "Kategorije osobnih podataka:"
        },
        tr2: {
          td1: "Sve kategorije vaših osobnih podataka opisanih gore biti će čuvane maksimalno 5 godina i 1 mjesec od dana finalnog zatvaranja Kaizen Expert alata.",
          td2: "Korisničko ime, prezime, odabrani jezik, tvrtka"
        }
      },
      Message9: "Opcija: otkrivanje/prijenos vaših osobnih podataka primateljima u državama izvan Europskog gospodarskog prostora (EGP)",
      Message10: "Vaši osobni podaci mogu biti dani na raspolaganje primatelju/-ima u državama izvan EGP-a kako je napisano dolje.",
      Table4: {
        tr1: {
          td1: "Identitet",
          td2: "Država",
          td3: ""
        },
        tr2: {
          td1: "Primatelj/-i izvan EGP-a",
          td2: "Infosys",
          td3: "Indija"
        }
      },
      Message11: "Ovime potvrđujem da sam razumio ovu obavijest, da sam pročitao i prihvatio Opću politiku privatnosti i zaštite osobnih podataka tvrtke Toyota."
    },
    ACCESSORIES: "Dodatna oprema",
    activity: {
      Assignee: "Dodijeljeno",
      CompletionDate: "Datum završetka",
      Dealer: "Ovlašteni partner",
      FirstVisit: "Prvi posjet",
      kpi: {
        kpi: "KPI bi trebali biti brojevi",
        kpiLength: "Maksimalna duljina KPI-a je 10 znakova",
        mandatory: "Unesite valjane podatke u sva obavezna polja",
        mandatoryTargets: "Unesite sve KPI-e",
        target: "Cilj trebaju biti samo brojevi",
        targetLength: "Maksimalna duljina cilja je 10 znakova"
      },
      ReminderOn: "Podsjetnik je uključen",
      statusDD: "Status",
      Toolbox: "Komplet alata",
      activityCreation: "Pokrenut je postupak kreiranja aktivnosti.",
      activityCreated: "Kreirane su aktivnosti"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Učinkovitost",
    FIX_IT_RIGHT: "Fix it Right",
    footer: {
      message: "Obavezna polja"
    },
    FOUNDATION: "Temelji",
    MAINTENANCE_REMINDERS: "Servisni podsjetnik",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Temelji",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Tra",
      aug: "Kol",
      dec: "Pro",
      feb: "Velj",
      jan: "Sij",
      jul: "Srp",
      jun: "Lip",
      mar: "Ožu",
      may: "Svi",
      nov: "Stu",
      oct: "Lis",
      sep: "Ruj"
    },
    others: {
      back: "Natrag",
      closed: "Zatvoreno",
      completed: "Završeno ",
      countryLanguageCode: "Država / kôd jezika ",
      createActivity: "Stvori aktivnost",
      createdDate: "Datum stvaranja",
      dataUneditableNote:
        "Napomena: Spremljeni podaci ne mogu se uređivati. Da biste izmijenili podatke, kontaktirajte administratora NMSC-a.",
      date: "Datum",
      downloadQuestionnaire: "Preuzmi upitnik",
      editActivity: "Uredi aktivnost",
      initialHelpText: "Početno KPI mjerenje trebala bi biti prosječna vrijednost prethodnih punih šest mjeseci",
      inProgress: "U tijeku",
      name: "Ime i prezime",
      next: "Sljedeće",
      noActivities: "Nije pronađena nijedna aktivnost",
      noResults: "Nema rezultata",
      of: "od",
      ongoing: "Aktualno",
      open: "Otvoreno",
      page: "Stranica",
      planned: "Planirano",
      problemDescription: "Opis problema",
      signature: "Potpis",
      select: "Odaberi",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Došlo je do greške tijekom preuzimanja.",
      no_published_subquestion: "Pošaljite upitnik za ovaj Kaizen modul da biste započeli s ovom aktivnošću.",
      activity_upload_error_msg: "Pogreška prilikom učitavanja pojedinosti o aktivnosti.",
      action_plan_created: "Akcijski plan uspješno izrađen",
      action_plan_deleted: "Akcijski plan uspješno obrisan",
      action_plan_saved: "Akcijski plan uspješno spremljen",
      activity_kpi_saved: "KPI aktivnosti uspješno spremljeni",
      master_kpi_confirm_delete:
        "Da li ste sigurni da želite obrisati odabrani KPI?",
      master_kpi_created: "Glavni KPI uspješno stvoren",
      master_kpi_deleted: "Glavni KPI je izbrisan",
      master_kpi_not_found: "Glavni KPI nije pronađen",
      master_kpi_published: "Odabrani glavni KPI-jevi uspješno objavljeni",
      master_kpi_retired: "Glavni KPI je arhiviran",
      master_kpi_saved: "Glavni KPI uspješno spremljen",
      subquestion_created: "Potpitanje je uspješno spremljeno.",
      subquestion_updated: "Potpitanje je uspješno ažurirano.",
      subquestionnaire_not_found: "Potpitanje nije pronađeno.",
      subquestion_retired: "Potpitanje je povučeno.",
      subquestion_deleted: "Potpitanje je obrisano.",
      subquestion_published: "Potpitanje je uspješno objavljeno.",
      mainquestion_created: "Glavni upitnik je napravljen.",
      mainquestion_deleted: "Glavni upitnik je obrisan.",
      mainquestion_updated: "Glavno pitanje je ažurirano.",
      duplicateMsg: "Dvostruki zapisi u Excel datoteci.",
      deleteErr: "Greška prilikom brisanja datoteke",
      toolbox_deleted: "Kaizen modul je uspješno obrisan",
      toolbox_already_exists: "Ovaj Kaizen modul već postoji.",
      toolbox_saved: "Kaizen modul je spremljen",
      toolbox_created: "Kaizen modul je kreiran",
      toolbox_not_found: "Kaizen modul nije pronađen",
      published_content_found: "Ovaj Kaizen modul se ne može izbrisati jer ima neki objavljeni sadržaj",
      dealers_already_mapped: " Dealers already mapped ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Dealer added. ",
      dealers_added: "  Dealer added. ",
      total_dealers_mapped_now: "Total dealers mapped now are ",
      no_dealer_available: "No Dealer available for this assign criteria",
      proper_dealer_selected: "Proper dealer shall be selected",
      user_referenced_ongoing_activity: "User is referenced in Ongoing activity/activities. ",
      want_to_remove_mapping: "Are you sure you want to remove mapping?",
      user_mapping_removed: "Selected dealers unmapped successfully",
      dealer_closed_in_A2D: "Dealer not active in A2D",
      network_level_not_found: "Please select valid Network levels",
      duplicate_dealer_uuid: "Dealer already available",
      dealer_created: "Dealer Created",
      dealer_updated: "Dealer Updated",
      dealer_not_present: "Dealer not present in A2D",
      dealer_uuid_other_nmsc: "Please Enter Dealer Uuid for Same NMSC",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Jeste li sigurni da želite izbrisati?",
      addActionPlanNotAllowed: "Akcijski plan ne može se napraviti ili urediti nakon što je aktivnost zatvorena, završena ili odbijena.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Jeste li sigurni da želite odbiti ovu aktivnost? Promjene nisu moguće nakon odbijanja.",
      rejectMsg: "Jeste li sigurni da želite odbiti ovu aktivnost? Promjene nisu moguće nakon odbijanja.",
      selectNMSCTab: "Molimo odaberite NMSC jezik za objavljivanje",
      all: "Sve",
      deleteConfirm: "Sigurno želite izbrisati odabrani KPI?",
      no: "Ne",
      retireConfirm: "Sigurno želite arhivirati ovaj KPI? Nakon arhiviranja ovaj KPI ne može se ponovno aktivirati",
      warning: "Upozorenje",
      yes: "Da",
      saveDataConfirmation: "Sigurno želite spremiti podatke?",
      saveKPIWarning: "Napomena: podaci se ne mogu urediti nakon spremanja. Da biste izmijenili podatke, kontaktirajte administratora NMSC KET-a",
      addKpiNotAllowed: "Unos KPI-a za sljedeći mjesec može se dodati nakon završetka tekućeg mjeseca.",
      activityLaunch: "Da li ste sigurni da želite pokrenuti ovu aktivnost? Kada pokrenete aktivnost, ona se ne može vratiti natrag u planirani status ili izbrisati.",
      deleteConfirmSubQuestionNaire: "Da li ste sigurni da želite obrisati odabrano potpitanje?",
      retireConfirmSubQuestionNaire: "Da li ste sigurni da želite povući odabrano potpitanje?",
      deleteConfirmMainQuestion: "Molim potvrdite da li želite obrisati glavno pitanje i njegova povezana potpitanja.",
      deleteConfirmChapter: "Da li ste sigurni da želite obrisati poglavlje?",
      deleteLibraryFolder: "Da li ste sigurni da želite obrisati mapu (datoteku)?",
      deletePublishedFolder: "Neke datoteke unutar ove mape mogu biti povezane s upitnikom. Jeste li sigurni da želite trajno izbrisati ovu podmapu i ukloniti veze iz upitnika?",
      importQuestion: "Da li ste sigurni da želite uvesti ovo potpitanje? Trenutna potpitanja će biti zamijenjena.",
      noQuestion: "Nema dostupnih pitanja.",
      MandatoryWarning: "Ispunite sva obavezna polja za sve lokalne jezike.",
      certificationSubmission:
        "Kaizen modul je predan TME-u na certifikaciju partnera",
      sameToolBox: "Ne možete poslati isti Kaizen modul više od jednom. Odaberite drugi Kaizen modul.",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "All Activities created for this dealer in 'Value Chain' will be removed. Are you sure you want to continue?",
      updateBritConfirmDealer: "All Activities created for this dealer in 'BRiT' will be removed. Are you sure you want to continue?",
      completed_activity_delete:"Data cannot be retrieved once deleted. Are you sure you want to delete ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Odaberi sve",
      unSelectAll: "Poništi odabir svih",
      select:"Odaberi...",
      warningMsg:"There are Open Action plan(s) in this activity. Would you like to Complete the activity?",
      updateEaConfirmDealer: "All Activities created for this dealer in 'EA' will be removed. Are you sure you want to continue?"

    },
    status: {
      published: "Objavljeno",
      retired: "Arhivirano",
      saved: "Spremljeno",
      rejected: "Odbijeno",
      open: "Otvoreno",
      closed: "Zatvoreno",
      close: "Zatvoreno",
      ongoing: "Aktualno",
      completed: "Završeno",
      complete: "Završeno",
      planned: "Planirano",
      overdue: "Zakašnjelo",
      due: "Rok",
      pending: "Pending",
    },
    Rejected: "Odbijeno",
    Open: "Otvoreno",
    Closed: "Zatvoreno",
    Close: "Zatvoreno",
    Ongoing: "Aktualno",
    Completed: "Završeno",
    Complete: "Završeno",
    Planned: "Planirano",
    tabDetails: {
      noActionAvailable: "Nijedna mjera nije dostupna",
      activeDealer: "Dealer is active.",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Aktivnost",
      activityCompletionDate: "Datum završetka aktivnosti",
      activityCompletionDateFrom: "Datum završetka aktivnosti od",
      activityCompletionDateTo: "Datum završetka aktivnosti do",
      activityStartDate: "Datum početka aktivnosti",
      activityStartDateFrom: "Datum početka aktivnosti od",
      activityStartDateTo: "Datum početka aktivnosti do",
      assignedTo: "Dodijeljeno",
      assignee: "Dodijeljeno",
      benchmark: "Osnovni pokazatelj",
      city: "Grad",
      completedBy: "Završio/la ",
      completionBy: "Završetak do",
      completionDate: "Datum završetka",
      counterMeasure: "Protumjera",
      country: "Država",
      createdBy: "Stvorio/la",
      dealer: "Ovlašteni partner",
      dealerNameAD: "Dealer name (A2D)",
      dealerName: "Naziv ovlaštenog partnera",
      definition: "Definicija",
      description: "Opis",
      dueDate: "Datum roka",
      endDate: "Datum završetka",
      firstVisit: "Prvi posjet",
      followup: "Aktivnosti praćenja",
      franchise: "Franšiza",
      history: "Povijest",
      id: "ID",
      initial: "Početno",
      kpi: "KPI",
      launch: "Pokreni",
      mandatory: "Obavezno",
      nmsc: "NMSC",
      problem: "Problem",
      publishedOn: "Objavljeno",
      questionReference: "Referenca pitanja",
      reference: "Referenca",
      reminderOn: "Podsjetnik je uključen",
      score: "Rezultat",
      startDate: "Datum početka",
      status: "Status",
      target: "Target",
      toolbox: "Kaizen modul",
      view: "Prikaži",
      kpiResult: "KPI rezultati",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Lokacija",
      ActionItemReport: "Stavke akcije u tijeku",
      certification: "Status certifikata",
      noOfToolbox: "Broj završenih modula",
      program: "Program",
      certificationDate: "Datum ovjere",
      activityId : 'Activity ID',
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan djelovanja",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Plan djelovanja",
      confirmDeleteMessage: "Sigurno želite izbrisati odabrani KPI?",
      confirmRetireMessage: "Potvrdite želite li arhivirati odabrani KPI jer je ovo već objavljeno",
      errorRetireMsg: "Ovaj KPI ne može se arhivirati jer je to aktivni obavezni KPI TME-a",
      import: "Uvezi",
      importKpiMessage: "Uvezite svoj KPI iz TME-ova popisa glavnih KPI-jeva",
      kpi: "Ključni pokazatelji uspješnosti",
      library: "Preglednik",
      noActionAvailable: "Nijedna mjera nije dostupna",
      NoKPI: "Nema dostupnih KPI-jeva",
      publish: "Objavi",
      questionnaire: "Upitnik",
      tmeKPIList: "TME-ov popis glavnih KPI-jeva",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Naziv modula",
      newToolboxName: "Novo ime",
      deleteToolbox: "Želite li izbrisati Kaizen modul?",
      programName: "Ime programa",
      ToolboxName_EN: "Naziv Kaizen modula (EN)",
      deleteTool: "Jeste li sigurni da želite izbrisati ovaj Kaizen modul?",
      selectToolbox: "Odaberite Kaizen modul koji želite izbrisati.",
      editToolBox: "Uređivanje Kaizen modula",
      addToolbox: "Dodavanje Kaizen modula",
      toolbox: "Unesite naziv ovog Kaizen modula.",
      addActionPlan: "Dodaj Plan djelovanja",
      actionPlanItem: "Plan djelovanja – Stavka #",
      add: "Dodaj",
      addActivity: "Dodaj aktivnost",
      additionalInformation: "Dodatne informacije",
      benchmark: "Osnovni pokazatelj",
      cancel: "Otkaži",
      createEditActivity: "Stvori/uredi aktivnost",
      createKPI: "Stvori KPI",
      delete: "Izbriši",
      download: "Preuzmi",
      edit: "Uredi",
      editKPI: "Uredi KPI",
      keyPerformanceIndicator: "Ključni pokazatelj uspješnosti",
      kpiDefinition: "Definicija KPI-ja",
      mandatoryKpi: "Obavezni KPI",
      save: "Spremi",
      search: "Traži",
      unit: "Jedinica",
      update: "Ažuriraj",
      validData: "Unesite valjane podatke",
      hide: "Sakrij",
      show: "Prikaži",
      upload_dealer_network: "Učitaj mrežu partnera",
      upload_question: "Učitaj upitnik",
      upload_user: "Učitaj detalje korisnika",
      upload_activity: "Učitaj aktivnosti",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Rok je prekoračen",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Odgovorite na sva pitanja kako biste dovršili ovu aktivnost.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Unesite početno mjerenje KPI-a, ciljeve KPI-a i najmanje 1 mjesec mjerenja za nastavak.",
      initialTarget: "Unesite početno mjerenje KPI i ciljeve KPI da biste nastavili.",
      upload_error: "Pogreška prilikom učitavanja mreže partnera.",
      action_item_create_warning: "U jednu stavku aktivnosti dopušteno je spojiti najviše pet zapisa.",
      actionPlanStartDateValidation: "Datum završetka ne može biti raniji od datuma početka",
      actionsNotClosed: "Postoje akcije u tijeku u akcijskom planu; zatvorite sve akcije prije zatvaranja aktivnosti",
      activityDuplicate: "Druga aktivnost za istog ovlaštenog partnera i kaizen modul već postoji",
      assigneeNotExist: "Odabrana odgovorna osoba nije registrirana u sustavu",
      assigneeValidation: "Nevaljana odgovorna osoba",
      atleastOnePublish: "Odaberite barem jedan KPI za objavu",
      cannotRetireActive: "Ovo je aktivni obavezni KPI TME-a koji se ne može arhivirati ",
      completionDate: "Datum završetka ne može biti raniji od datuma početka",
      confirDeletePlannedActivity: "Sigurno želite izbrisati ovu planiranu aktivnost?",
      DateformatValidation: "Datum treba biti u formatu DD/MM/GGGG",
      DateValidation: "Datum početka mora biti prije datuma završetka",
      dealerValidation: "Nevaljani ovlašteni partner",
      deleteSelectedConfirm: "Sigurno želite izbrisati odabrani KPI?",
      duplicate: "Drugi KPI jednakog naziva i definicije već postoji",
      modalMandatory: "Unesite valjane podatke u sva obavezna polja",
      noPublishKpi: "KPI-ji još nisu objavljeni za odabrani komplet alata",
      validSearchCriteria: "Unesite valjane kriterije pretraživanja",
      multiSelectPlaceHolder: "Unesite najmanje 5 znakova",
      multiSelectPlaceHolderUser: "Unesite najmanje 3 znakova",
      startDateValidation: "Datum početka ne može biti veći od datuma završetka",
      fileSelectionErr: "Molimo prvo odaberite datoteku",
      atleastOneQuestion: "Molim odaberite barem jedno potpitanje za uvoz.",
      atleastOneSubQues: "Odaberite najmanje jedno podpitanje za objavljivanje.",
      uploadErr: "Broj datoteka koje nije moguće učitati:",
      uploadSuccess: "Mreža partnera je uspješno učitana.",
      file_upload_success: "Datoteke su uspješno učitane.",
      KPIinOngoingClosedActivity: "Ovaj KPI je prisutan u jednoj ili više tekućih / zatvorenih aktivnosti. Dovršite ove aktivnosti prije bilo kakve promjene KPI-a.",
      Role_Error: "The user setup is incomplete, please contact the NMSC KET Admin.",
      points_allocation_incorrect: "Points allocation is incorrect. Please modify.",
      activity_deleted:"Activity deleted successfully",
      activity_certified_dealer:"Dealer certified for the selected Activity, hence cannot be deleted",
      activity_submited_for_certificate_dealer:"The status of toolbox cannot be changed as request for certification has been sent to TME"
    },
    TopNav: {
      ActivityLog: "Aktivnosti",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Početna stranica",
      logout: "Odjava",
      MasterDataUpload: "Učitaj glavne podatke",
      Toolbox: "Kaizen modul",
      welcome: "Dobro došli",
      Admin: "Administrator"
    },
    questionnaire: {
      addSubQuestion: "Dodajte potpitanje",
      editSubQuestion: "Uredite potpitanje",
      subQuestionTitle: "Potpitanje",
      additionalInfo: "Dodatne informacije",
      na: "Dodajte 'Nije primjenjivo' kao mogući odgovor na ovo pitanje",
      question: "Pitanje",
      questionTitle: "Naslov pitanja",
      why: "Zašto?",
      addMainQuestion: "Dodajte pitanje",
      editMainQuestion: "Uredite pitanje",
      previousQuestion: "Prethodno pitanje",
      nextQuestion: "Slijedeće pitanje",
      what: "Što?",
      library: "Preglednik",
      subQuestionTable: {
        no: "Ne.",
        subQuestion: "Potpitanja",
        status: "Status",
        publishedDate: "Datum",
        notApplicable: "Nije primjenjivo"
      },
      links: {
        question_link_button: "Veza",
        question_link_include_folder: "Uključi podmapu",
        question_link_add_link: "Poveži datoteke iz knjižnice",
        question_link_view_folder: "Pogledaj knjižnicu"
      },
      mainQuestion: "Nova/ažurirana glavna pitanja: ",
      subQuestion: "Nova/ažurirana podpitanja: ",
      retiredQuestion: "Povučena potpitanja:",
      libraries: "Nove/ažurirane datoteke u pregledniku:",
      noFiles: "Nema dostupnih datoteka."
    },
    library: {
      selectFile: "Odaberite barem jednu datoteku.",
      fileSelected: "Odabrane datoteke",
      publish_library_file: "Odaberite barem jednu datoteku za objavljivanje",
      library_files_deleted: "Jeste li sigurni da želite obrisati ovu datoteku?",
      library_files_linked_deleted: "Jeste li sigurni da želite izbrisati datoteku i vezu stvorenu u Upitniku?",
      max_size_error: "Veličina datoteka premašuje ograničenje od 1 GB po učitavanju.",
      empty_file_desc: "Unesite opis datoteke",
      file_not_selected: "Odaberite datoteke koje želite učitati (najviše 1 GB)",
      file_type_error: "Ova vrsta datoteke nije dopuštena.",
      file_name_error: "Nevažeći znak u nazivu datoteke.",
      file_name_lenght_error: "Naziv datoteke može imati maksimalno 50 znakova.",
      addFolder: "Dodajte mapu",
      editFolder: "Uredite mapu",
      currentFolder: "Naziv mape",
      parentFolder: "Naziv nadređene mape",
      emptyFolderName: "Naziv mape ne može biti prazan.",
      invalidFolderName: "Ime mape ne može sadržavati '/'.",
      folder_created: "Mapa je uspješno napravljena.",
      folder_updated: "Mapa je uspješno ažurirana.",
      folder_not_found: "Mapa nije pronađena",
      lib_invalid_toolbox: "Odabran je nevažeći alat",
      lib_folder_already_exists: "Mapa već postoji.",
      lib_folder_deleted: "Mapa je uspješno obrisana.",
      library_files_published: "Datoteke su objavljene.",
      addFile: "Dodajte datoteku",
      editFile: "Uredite datoteku",
      fileName: "Naziv datoteke",
      upload: "Učitaj",
      desc: "Opis",
      folderCreationErr: "Hijerarhija je ograničena na dvije razine.",
      subFolderWarning: "Odaberite podmapu za dodavanje datoteka.",
      deleteWarning: "Odaberite mapu za brisanje.",
      beforeAfterNotSelected: "Molim odaberite je li dokaz prije ili poslije Kaizen aktivnosti",
    },
    chapter: {
      chapter_exist: "Poglavlje već postoji.",
      chapter_saved: "Poglavlje spremljeno",
      chapter_cannot_delete: "Ovo poglavlje ne može biti obrisano jer su potpitanja već objavljena.",
      failed: "Nije uspjelo!",
      chapter_deleted: "Poglavlje obrisano",
      chapter_nonEmpty: "Kreirajte naziv poglavlja.",
      addchapter: "Dodaj poglavlje",
      editChapter: "Uredi poglavlje",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Prilozi",
      comments: "Komentari",
      evidence: "Dokaz",
      evidences: "Dokaz(i)",
      yes: "Da",
      no: "Ne",
      na: "N/A",
      uploadEvidence: "Učitaj dokumente",
      fileUpload: "Odaberite datoteke koje želite učitati (najviše 1 GB)",
      who: "Tko?",
      answer: "Odgovor",
      comment: "Comment",
      date: "Datum",
      submit: "Potvrdi",
      commentMsg: "Unesite komentare.",
      noCommentAvailable: "Nema komentara.",
      uploadSuccessMsg: "File(s) have been uploaded successfully",
      uploadErrorMsg: "Broj datoteka koje nije moguće učitati:",
      fileWithSameName: "Postoji datoteka sa istim nazivom.",
      noEvidence: "Nema dostupnih dokaza",
      createAction: "Stvori stavku aktivnosti",
      chapterName: "Naziv poglavlja",
      questionNo: "Pitanje br.",
      when: "Kada?",
      noRecords: "Nema dostupnih zapisa.",
      kaizenPoints: "Kaizen bodovi",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Pitanje",
      before:"Before",
      after:"After",
      beforeAfterLabel:"Je li ovo dokaz prije ili poslije Kaizen aktivnosti?",
      move:"Premjesti",
      moveMsg: "Datoteke su uspješno premještene",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Walk around check",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Body & Paint",
      TSM_USED_CAR: "Rabljeni automobili",
      value_chain: "Value Chain",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Obavijesti",
      kpi_target_not_set: "KPI ciljevi nisu postavljeni",
      kpi_initial_not_set: "KPI početno mjerenje nije postavljeno",
      kpi_monthly_not_set: "Mjesečno mjerenje KPI-a nije postavljeno",
      kpi_below_target: "KPI ispod trenutnog cilja",
      activation: "Aktiviranje",
      users: "Korisnici",
      nmscs: "NMSC's",
      activeRetailer: "Aktivni partneri",
      certificationRate: "Ocjena certifikata",
      reCertificationRate: "Ocjena ponovno izdanog certifikata",
      activityDue: "Očekivane aktivnosti",
      avgdays: "Prosječni dani/Modul",
      overDueAct: "Istekle aktivnosti",
      actionItem: "Stavke akcije",
      bussResult: "Poslovni rezultati",
      toolboxImpl: "Implementacija modula",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Nedavna ažuriranja",
      rolloutTopNmsc: "Rezultati prikazivanja - Top NMSC-a",
      bestPractice: "Redosljed najboljih praksi",
      availableKpi: "Dostupni KPI",
      selectedKpi: "Odabrani KPI",
      dealerBestPractice: "Pregled primjera dobre prakse",
      kpiList: "KPI lista",
      go: "IDI",
      nodata: "Podaci nisu dostupni",
      businessResultTop: "Rezultati poslovanja - Top partneri",
      businessResultNMSC: "Rezultati poslovanja - Top NMSC-a"


    },
    dealerCertification: {
      dealerCertification: "Certifikat partnera",
      readyForCertification: "Spremni za certifikaciju",
      certification: "Certifikacija",
      submittedOn: "Zaprimljeno",
      certificateDate: "Datum certifikata",
      reCertificateDate: "Datum ponovnog certifikata",
      toolboxDetails: "Detalji modula",
      approve: "Odobreno",
      reject: "Odbaci",
      pending: "U tijeku",
      region: "Regija"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Execution Id",
      uploadedOn: "Uploaded On",
      lastUpdatedOn: "Last updated on",
      noDataTable: "No data to view",
      kpiUpload: "KPI Upload",
      action: "Action",
      processed: "Processed",
      error: "Error",
      received: "Received",
      createActivity: "Create Activities",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Users management",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "PBI Account",
      invalidPbiAccount : "PBI Account should contain minimum 3 characters.",
      businessArea: "Business Area",
      valueChainAndBRiT: "Value Chain & BRiT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Value Chain Only",
      britOnly: "BRiT Only",
      valueChainAndEA: "Value Chain & EA",
      britAndEA: "BRiT & EA",
      eaOnly: "EA Only",
      valueChainAndBRiTAndEA: "Value Chain & BRiT & EA"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "First Name",
      lastName: "Last Name",
      emailId: "E-Mail ID",
      languageCode: "Language Code",
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      addUser: "Add User",
      user: "User",
      error_message: "An Error Occurred. Please try again later",
      role: "Role",
      standardMessage: "No User Found",
      dealerCode: "Dealer Code",
      a2d: "A2D UUID",
      britReady: "Retailer participated in Brit Ready",
      britLive: "Retailer participated in Brit Live",
      britJapan: "Retailer participated in Brit Discovery Japan",
      britEss:"Retailer has updated EES Action Plan",
      addDealer: "Add Dealer",
      noDealer: "No Dealer Found",
      dealerUuid: "Dealer uuid",
      language: "Language",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Please enter valid Uuid",
      invalidDealerName: "Please enter valid Dealer Name",
      invalidCity: "Please enter valid City",
      invaliDealerCode: "Please enter valid Dealer Code",
      existingUuid: "Existing Uuid",
      updateUuid: "Update Uuid",
      newUuid: "New Uuid",
    },
    networkManagement: {
      networkManagement: "Network management"
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Downloads",
      historyFiles:"History of previous files",
      reachText1:"Welcome to the REACH IT homepage. The TME Environmental Management team will use this page to share relevant  information related to SCIP : Substances of Concern In articles as such or in complex objects (Products) established under the Waste Framework Directive (WFD) in order for you to fulfill your duty to process with your SCIP simplified notification.",
      reachText2:"In case of query, please contact the TME Environmental Management team via below link.",
      reachText3:"In case of query, please contact your NMSC.",
      envMngt:"Environmental Management",
      fileStatistics: "File Downloaded Statistics",
      Downloaded: "Downloaded",
      not: "Not",
      exportToExcel:"Export To Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsHR;
