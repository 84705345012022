import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Fa, MDBIcon } from "mdbreact";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import {
    downloadFile,
    getMonthlyKpiUploadedList,getkpiUploadList
} from "../../../actions/bulkUploadAction";
import { withTheme } from "../../../themes/theming";
import * as AppConst from '../../../AppConstant';
import "./../index.css";
var I18n = require("react-redux-i18n").I18n;

class HistoryKpiTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fileCriteria: {
                sizePerPage: 10,
                page: 1,
                fileType: this.props.user.userMdl.activeProgram === AppConst.activeProgramUc ? 
                'USED_CARS_BULK_UPLOAD': this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? 'NETWORK_BULK_UPLOAD':this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ? 'LEXUS_NETWORK_BULK_UPLOAD':
                'VALUE_CHAIN_KPI_UPLOAD'
            },
            totalDisplaySize: 0
        };
    }

    componentWillMount() {

    }

    componentDidMount() {
        let fileCriteria = { ...this.state.fileCriteria }

        fileCriteria["username"] = this.props.user.userMdl.username;
        this.props.dispatch(getkpiUploadList(fileCriteria));
        this.props.dispatch(getMonthlyKpiUploadedList(fileCriteria));
    }

    componentWillReceiveProps(nextProps) {
    }

    topToBottom = () => {
        return (
            <Row>
                <div className="col-md-12 col-xs-12 col-sm-12 col-lg-12">
                    <Col md="2" style={{ marginTop: '-1px', float: "Right", width: "auto" }}>
                        <a onClick={() => { window.scrollTo(0, 0) }}>
                            <span style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{I18n.t("standardMessages.backToTop")}
                                <Fa icon={"arrow-circle-up"} fixed size="3x" />
                            </span>
                        </a>
                    </Col>
                </div>
            </Row>
        )
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        let fileCriteria = { ...this.state.fileCriteria }
        fileCriteria["page"] = page;
        fileCriteria["sizePerPage"] = sizePerPage;
        fileCriteria["username"] = this.props.user.userMdl.username;
        this.props.dispatch(getMonthlyKpiUploadedList(fileCriteria));
        this.setState({ fileCriteria });
    }

    dateFormatterUploaded = (columns, row) => {
        return moment(row.createdTimestamp).format("DD/MM/YYYY hh:mm:ss A")
    }

    dateFormatterUpdated = (columns, row) => {
        return moment(row.updatedTimestamp).format("DD/MM/YYYY hh:mm:ss A")
    }

    actionColumn = (cell, row, rowIndex) => {
        let uploadFileReqDTO = {
            username: this.props.user.userMdl.username,
            nmsc: "",
            lang: "",
            fileType: this.props.user.userMdl.activeProgram === AppConst.activeProgramUc ? 'USED_CARS_BULK_UPLOAD'
            : this.props.user.userMdl.activeProgram === AppConst.activeProgramNet ? 'NETWORK_BULK_UPLOAD'
            :this.props.user.userMdl.activeProgram === AppConst.activeProgramLN ? 'LEXUS_NETWORK_BULK_UPLOAD'
            : 'VALUE_CHAIN_KPI_UPLOAD'            
        }
        return (
            <div className="btn-toolbar">
                <MDBIcon icon="download" className="customSundryTableIcon" size="lg" style={{ cursor: 'pointer' }}
                    onClick={(e) => this.props.dispatch(downloadFile(row.id, row.fileName, uploadFileReqDTO))}
                />
            </div>
        );
    }

    statusFormatter(cell, row) {
        return (
          <div title={row.executionStatus ? I18n.t('uploadBulk.'+ row.executionStatus.toLowerCase()) : ''}>
            {I18n.t('uploadBulk.' + row.executionStatus.toLowerCase())}
          </div>
        );
      }

    render() {

        let data = this.props.data && this.props.data.dataList && this.props.data.dataList.length > 0 ? this.props.data.dataList : [];
        let page = this.state.fileCriteria.page;
        let sizePerPage = this.state.fileCriteria.sizePerPage;
        let totalSize = this.props.data ? this.props.data.recordCount : 10;

        console.log(page + ":" + sizePerPage + ":" + totalSize)
        const columns = [

            {
                dataField: "id",
                text: I18n.t('uploadBulk.executionId'),
                style: { width: "13%", textAlign: "left" },
                headerStyle: { width: "12.8%", textAlign: "left" },
                title: true
            },
            {
                dataField: "fileName",
                text: I18n.t('library.fileName'),
                style: { width: "21%", textAlign: "left" },
                headerStyle: { width: "20.9%", textAlign: "left" },
                title: true
            },
            {
                dataField: "executionStatus",
                text: I18n.t('Table.status'),
                formatter: this.statusFormatter,
                style: { width: "14%", textAlign: "center" },
                headerStyle: { width: "13.8%", textAlign: "center" }
            },
            {
                dataField: "createdTimestamp",
                text: I18n.t('uploadBulk.uploadedOn'),
                title: true,
                formatter: this.dateFormatterUploaded,
                style: { width: "15%" },
                headerStyle: { width: "14.9%" },
            },
            {
                dataField: "updatedTimestamp",
                text: I18n.t('uploadBulk.lastUpdatedOn'),
                title: true,
                formatter: this.dateFormatterUpdated,
                style: { width: "16%" },
                headerStyle: { width: "15.9%" },
            },
            {
                dataField: "",
                text: I18n.t('uploadBulk.action'),
                formatter: this.actionColumn,
                style: {
                    width: "19%", display: "flex",
                    justifyContent: "center"
                },
                headerStyle: {
                    width: "18.9%",
                },
            }
        ];

        const RemotePagination = ({

            data,
            page,
            sizePerPage,
            onTableChange,
            totalSize
        }) => (
            <div className={'historyTable parent-' + this.props.theme.name}>
                <BootstrapTable
                    remote
                    keyField="id"
                    noDataIndication={I18n.t('uploadBulk.noDataTable')}
                    data={data}
                    columns={columns}
                    pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    onTableChange={onTableChange}
                />
            </div>
        );
        return (

            <Container
                fluid
                className="ml-0 mb-2 pb-0"
                style={{ border: "1px solid grey", borderRadius: "10px" }}>

                <Row>
                    <span className="sectionLabel">Uploaded File History and Messages</span>
                </Row>
                <div className="col-md-12 searchHistoryTable">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        onTableChange={this.handleTableChange}
                    />
                    {this.topToBottom()}
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(HistoryKpiTable))
);