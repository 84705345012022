import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../../themes/theming";
import Autosuggest from "react-autosuggest";
import Select from "react-select";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { fetchActivityFilters, fetchDealer, fetchToolBoxForTme } from '../../../actions/activityAction';
import { fetchNmscToolboxByType,fetchTmeToolboxForNmsc } from "../../../actions/leftNavAction.jsx";
import { downloadTemplate } from "../../../actions/bulkUploadAction.jsx";
import { downloadCreateActivityTemplate } from "../../../actions/bulkActivitiesAction.jsx";
import { withRouter } from "react-router-dom";
import $ from "jquery";
import "./../index.css";
import DownloadPanel from "./downloadPanel";
var I18n = require("react-redux-i18n").I18n;

class SearchFilterActivity extends React.Component{
    /* eslint-disable */
    constructor(props){
        super(props);
        this.state ={
            showTableFlag: false,
            //showSearchFlag: false
            suggestions: [],
            valueDealer:'',
            nmscList: [],
            dealerList: [],
            toolboxList: [],
            countryList: [],
            selectedNmsc: [],
            selectedToolbox: [],
            selectedCountry: [],
            selectedDealer: [],
            activityToolBox: [],
            nmscFlag: false,
            downloadDto: {
                //nmscCode: this.props.user.userMdl.nmsc,
                nmscCode: null,
                loggedInUser: this.props.user.userMdl.username,
                toolboxId: null,
                userId: this.props.user.userMdl.username,
                status: null,
                score: null,
                activityId: null,
                toolboxId: null,
                loginId: null,
                dealerId: null,
                type: 'TME',
                langCode: this.props.user.userMdl.lang,
                rows: 10,
                page: 1,
                programID:this.props.user.userMdl.activeProgramID,
                program: this.props.user.userMdl.activeProgram,
                isValueChain: this.props.user.userMdl.flagValueChain,
                isBRiT: this.props.user.userMdl.flagBRiT,
                isCEA: this.props.user.userMdl.flagCEA,
                isEA:this.props.user.userMdl.flagEA,
                businessArea: this.props.user.userMdl.businessAreaKey ==="EA" ? "EA" : this.props.user.userMdl.businessAreaKey
              },
            searchDto: {
                nmscCode: null,
                userId: this.props.user.userMdl.username,
                country: null,
                dealer: null,
                toolbox: null,
            },
            defaultAllObj:[{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }], 
        }
    }

    componentWillMount(){
        var isNMSCAdminUser = false;
        var isTMEAdminUser = false;
        var isNotAdmin = false;
        if (this.props.user.userMdl.roles !== undefined) {
          var roles = this.props.user.userMdl.roles;
          for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1 || roles[i].toUpperCase().indexOf('KET-EA NMSC ADMIN') !== -1) {
                isNMSCAdminUser = true;
                break;
              }
              else if (roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN") {
                isTMEAdminUser = true;
                break;
            }
            isNotAdmin = true;
    
          }
        }
        console.log(this.state.downloadDto)
        if (this.state.downloadDto !== undefined) {
            // console.log("got saved criteria" + JSON.stringify(this.state.downloadDto));
            let selectedNmscList = this.getSelectedList(this.state.downloadDto.nmscCodeList);
            let selectedDealerList = this.getSelectedList(this.state.downloadDto.dealerList);
            let selectedCountryList = this.getSelectedList(this.state.downloadDto.countryList);
            let selectedToolboxList = this.getSelectedList(this.state.downloadDto.toolboxList);
            this.setState({
                downloadDto: this.state.downloadDto,
                selectedNmsc: selectedNmscList,
                selectedDealer: selectedDealerList,
                selectedCountry: selectedCountryList,
                selectedToolbox: selectedToolboxList,
                valueDealer: this.state.downloadDto.dealer !== undefined ? this.state.downloadDto.dealer : '',
            });
            this.props.dispatch(fetchActivityFilters(this.state.downloadDto, "nmscCode"));
            // this.props.dispatch(fetchDownloadFilters(this.state.downloadDto, "toolbox"));
            this.props.dispatch(fetchActivityFilters(this.state.downloadDto, "l2"));
            this.props.dispatch(fetchDealer(this.state.downloadDto));
            //this.props.dispatch(fetchDownloadFilters(this.state.downloadDto, "userId"));
            if(!isTMEAdminUser){
                console.log(this.props.user.userMdl)
                this.props.dispatch(fetchNmscToolboxByType(this.props.user.userMdl.activeProgramID,this.props.user.userMdl.nmsc,this.props.user.userMdl.lang));
            }
            else{
            //this.props.dispatch(fetchToolBox(this.props.user.userMdl.activeProgram,"TME"));
            this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
            }
        }
    }

/**
     * Method to create selected option list for multiselect element.
     */
    getSelectedList(selectedList) {
        let filteredList = [];
        let obj;
        var Number = /^[\d\/\(\)\- \.]+$/;
        if (selectedList !== undefined) {
            selectedList.map(value => {
                if (Number.test(value)){
                     obj = { 
                        label: value,            
                        value: value,
                        selected: 'selected'
                    };
                }
                else{
                 obj = {             
                    label: I18n.t("status." + value.toLowerCase()),
                    value: value,
                    selected: 'selected'
                };
            }
                filteredList.push(obj);
            });
        }
        return filteredList;
    } 
    componentWillReceiveProps(nextProps) {

        // alert('componentWillReceiveProps')

        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

        let roles = [];
        roles = nextProps.user.userMdl.roles;
        console.log(nextProps)
        //If user role is NMSC or DEALER then set flag as true
        // This will set the search activity toolbox drop down for nmsc user
        if (nextProps.user.userMdl.roles) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase().indexOf("NMSC") !== -1 || roles[i].toUpperCase().indexOf("DEALER") !== -1) {
                    this.setState({ nmscFlag: true });
                    break;
                }
            }            
        }
        if (nextProps.dealerList.nmscList) {
            if(this.state.nmscFlag){
                this.setState({ nmscList: nextProps.dealerList.nmscList });
            }else{
                this.setState({ nmscList: this.state.defaultAllObj.concat( nextProps.dealerList.nmscList) });
            }
            var nmscValue = [];
            nextProps.dealerList.nmscList.map(function (item) {
                nmscValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["nmscCodeList"] = nmscValue;
            this.setState({ downloadDto }); 
        }

        if (nextProps.dealerList.countryList) {
            this.setState({ countryList: this.state.defaultAllObj.concat( nextProps.dealerList.countryList) });
            var countryValue = [];
            nextProps.dealerList.countryList.map(function (item) {
                countryValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["countryList"] = countryValue;
            this.setState({ downloadDto });        
        }

        if (nextProps.dealerList.dealerList) {
            this.setState({ dealerList: nextProps.dealerList.dealerList });
            var dealerValue = [];
            nextProps.dealerList.dealerList.map(function (item) {
                dealerValue.push(item.value);
            })
            const downloadDto = { ...this.state.downloadDto };
            downloadDto["dealerList"] = dealerValue;
            this.setState({ downloadDto });          
        }

        if(!this.state.nmscFlag){
        if (nextProps.activityToolBox.activityToolBox) {
            const options =  this.state.defaultAllObj.concat(nextProps.activityToolBox.activityToolBox).map((d) => ({
                value: d.value,
                label: d.label
            }));
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var i = 0; i < this.state.selectedToolbox.length; i++) {
                    let value = this.state.selectedToolbox[i].value;
                    // let label = this.state.selectedToolbox[i].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            this.setState({ activityToolBox: options });
            // var toolValue = [];
            //     nextProps.activityToolBox.activityToolBox.map(function (item) {
            //         toolValue.push(item.value);
            // })
            // const downloadDto = { ...this.state.downloadDto };
            // downloadDto["toolboxList"] = toolValue;
            // this.setState({ downloadDto });  
        } 
    }

        else{
        if (nextProps.toolboxByType.toolboxByType) {
            const options =[]
            options.push({ label: I18n.t('standardMessages.selectAll'),value:"All"},{"value": "None", "label": I18n.t('standardMessages.unSelectAll') })
            nextProps.toolboxByType.toolboxByType.map((d) => {
                options.push({
                    value: d.packageMapPrimaryKey,
                    label: d.toolboxName
                })
            });
            // Set Values for selectedToolbox.
            if (this.state.selectedToolbox.length > 0) {
                let modifiedList = [];
                for (var i = 0; i < this.state.selectedToolbox.length; i++) {
                    let value = this.state.selectedToolbox[i].value;
                    // let label = this.state.selectedToolbox[i].label;
                    let obj = options.filter(option => option.value === value);
                    let selectedObj = {
                        label: obj[0].label,
                        value: obj[0].value,
                        selected: 'selected'
                    };
                    modifiedList.push(selectedObj);
                }
                this.setState({ selectedToolbox: modifiedList });
            }
            this.setState({ activityToolBox: options });
        //     var toolValue = [];
        //     nextProps.toolboxByType.toolboxByType.map(function (item) {
        //         toolValue.push(item.packageMapPrimaryKey);
        // })
        // const downloadDto = { ...this.state.downloadDto };
        // downloadDto["toolboxList"] = toolValue;
        // this.setState({ downloadDto });  
        }
    }
    }

    getSuggestions = value => { 
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        return inputLength > 4 && this.state.dealerList !== undefined ? (this.state.dealerList.filter(lang =>
            lang.label.toLowerCase().slice(0, inputLength) === inputValue)
        ) : [];
    };
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
            suggestions: this.getSuggestions(value)
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            suggestions: []
        });
    };
    getSuggestionValue = suggestion => suggestion.label + "_" + suggestion.value;

    renderSuggestion = suggestion => (
        <div>
            {suggestion.label}
        </div>
    );

    onDealerChange = (event, { newValue }) => {
        var modifiedValue, modifiedId;
        if (newValue.indexOf("_") > -1) {
            modifiedValue = newValue.substr(0, newValue.indexOf("_"));
            modifiedId = newValue.substr(newValue.indexOf("_") + 1);
        } else {
            modifiedValue = newValue;
            modifiedId = 0;
        }
        const downloadDto = { ...this.state.downloadDto };
        downloadDto["dealer"] = modifiedValue;
        downloadDto["dealerId"] = modifiedId;
        this.setState({ downloadDto });
        this.setState({
            valueDealer: modifiedValue,
            id: modifiedId
        });
    };

    onToolBoxChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedToolbox: this.state.activityToolBox})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedToolbox: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedToolbox: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedToolbox: [] })
            }else{
                this.setState({  selectedToolbox: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.activityToolBox.length - 1) {
            this.setState({ selectedToolbox : this.state.activityToolBox}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })   
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedToolbox: selectedOpt})           
        }  
        const downloadDto = { ...this.state.downloadDto };
        downloadDto["toolboxList"] = valueOptions;
        // this.setState({ selectedToolbox: selectedOption })
        this.setState({ downloadDto });
        this.forceUpdate();
        // if(event.action === "select-option" && event.option.value === "None"){}else{
        //     this.reloadDropDowns(downloadDto);
        // }
    }

    onNmscChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedNmsc: this.state.nmscList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedNmsc: [] })
            }else{
                this.setState({  selectedNmsc: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.nmscList.length - 1) {
            this.setState({ selectedNmsc : this.state.nmscList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })     
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedNmsc: selectedOpt})            
        }  
        const downloadDto = { ...this.state.downloadDto };
        downloadDto["nmscCodeList"] = valueOptions;
        this.setState({ downloadDto });
        // this.setState({ selectedNmsc: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(downloadDto);
            if(valueOptions.length===0){
            this.props.dispatch(fetchToolBoxForTme(this.props.user.userMdl.activeProgramID));
            }
            else{
            this.props.dispatch(fetchTmeToolboxForNmsc(this.props.user.userMdl.activeProgramID,valueOptions));
            }
        }
    }

    onCountryChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedCountry: this.state.countryList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedCountry: [] })
            }else{
                this.setState({  selectedCountry: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.countryList.length - 1) {
            this.setState({ selectedCountry : this.state.countryList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })      
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedCountry: selectedOpt})        
        } 
        const downloadDto = { ...this.state.downloadDto };
        downloadDto["countryList"] = valueOptions;
        this.setState({ downloadDto });
        // this.setState({ selectedCountry: selectedOption })
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(downloadDto);
        }
    }

    reloadDropDowns = (downloadDto) => {
        this.props.dispatch(fetchActivityFilters(downloadDto, "nmscCode"));
        this.props.dispatch(fetchActivityFilters(downloadDto, "l2"));
        this.props.dispatch(fetchDealer(downloadDto));
    }

    onClickUpload = (e) => {

        // console.log(this.state.selectedFile)

                // this.props.user.userMdl.username

                this.state.downloadDto.isValueChain = this.props.user.userMdl.flagValueChain;
                this.state.downloadDto.isBRiT= this.props.user.userMdl.flagBRiT;
                this.state.downloadDto.isCEA= this.props.user.userMdl.flagCEA;
                this.state.downloadDto.isEA= this.props.user.userMdl.flagEA;
                const downloadDto = { ...this.state.downloadDto };
                downloadDto["isEA"] = this.props.user.userMdl.flagEA;
                this.setState({ downloadDto });
                {this.props.createActivityFlag?
                this.props.dispatch(downloadCreateActivityTemplate(this.state.downloadDto))
                :
                this.props.dispatch(downloadTemplate(this.state.downloadDto))
                }
                this.setState({showTableFlag:true});
                
    }

    render(){
        console.log("checkprops",this.props)
        console.log("check",this.props.createActivityFlag)
        const { suggestions, valueDealer } = this.state;
        // Autosuggest will pass through all these props to the input.
        const inputProps = {
            placeholder: I18n.t('toastr.multiSelectPlaceHolder'),
            value: valueDealer,
            onChange: this.onDealerChange
        };
        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
            <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchDash">
            <div>
                <div className="col-md-12 row control-label">
                <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}></label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('Table.nmsc')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.1rem', width: '6%', overflowWrap: 'normal', fontWeight: '400' }}>{I18n.t('Table.country')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.2rem', fontWeight: '400' }}>{I18n.t('Table.dealer')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '1.1rem', fontWeight: '400' }}>{I18n.t('Toolbox')}</label>

                </div>
                <div className="col-md-12 input-group control-label">
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            {this.state.nmscFlag ? <Select
                            placeholder= {I18n.t('others.select')}
                                value={this.state.nmscList[0]}
                                onChange={this.onNmscChange}
                                options={this.state.nmscList}
                                isDisabled={true}
                            /> :
                            <ReactMultiSelectCheckboxes
                                        value={this.state.selectedNmsc}
                                        options={this.state.nmscList}
                                        onChange={this.onNmscChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}

                                    />}
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedCountry}
                                    options={this.state.countryList}
                                    onChange={this.onCountryChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')} />
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name} style={{fontSize: 'smaller', fontWeight: '400' }}>
                            <Autosuggest 
                                    suggestions={suggestions}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                />
                            </div>
                            <div style = {{margin : '9px'}}/>

                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedToolbox}
                                    options={this.state.activityToolBox}
                                    onChange={this.onToolBoxChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            </div>
                            <div className="col-md-1" style={{ padding: '0px' }}>
                                <button className={"button-" + this.props.theme.name}
                                    onClick={() => this.onClickUpload() }>
                                    {I18n.t('tmeAdminMaster.generate')}
                        </button>
                            </div>
                            {/* {this.state.showTableFlag? */}
                            <DownloadPanel data={this.props.bulkUpload.uploadActivityFileList} downloadDto={this.state.downloadDto} createActivityFlag={this.props.createActivityFlag} />
                            {/* :""} */}
                        </div>
                    </div>
                    </div>
                    

    <div id="hrId" className="col-md-12 hrClass">
        <hr style={{ margin: '0px', borderTop: "1px solid" }} />
    </div>
</div>

);

  }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        dealerList: state.activityReducer,
        activityStatus: state.activityReducer,
        activityToolBox: state.activityReducer,
        toolboxByType: state.leftNavReducer,
        bulkUpload: state.bulkUploadReducer

    };
  };
export default withRouter(connect(mapStateToProps)(withTheme(SearchFilterActivity)));