import React from "react";
import { withTheme } from "../../themes/theming";
import { Fa } from 'mdbreact';
class Arrow extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    render() {
        const {  onClick, icon, parentClass,BtnClass} = this.props;
        return (
            <div className={parentClass}>
                <Fa  className={BtnClass+" widget_filter_arrow_" + this.props.theme.name} size="lg" icon={icon} onClick={onClick} />
            </div>
        );
    }
}
export default (withTheme(Arrow));