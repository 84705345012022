import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow, Fa } from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import { withTheme } from "../../../themes/theming";
import { fetchAvailableToolboxes, fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import Slider from "react-slick";
import Arrow from '../arrow';
import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class AvgDaysPerToolbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            availableToolboxes: [],
            data: {},
            userMdl: {},
            leftArrow:"widget_filter_arrow_disable",
            rightArrow:"widget_filter_arrow"
        };
    }
    componentDidMount() {
        // let activeProgram = this.props.user.userMdl.activeProgram;
        // let nmscCode = this.props.user.userMdl.nmsc;
        // let langCode = this.props.user.userMdl.lang;
        // Fetch all available toolboxes.
        // this.props.dispatch(fetchAvailableToolboxes(activeProgram, nmscCode, langCode));
        // Fetch AverageDaysPerToolbox.
        // this.fetchData("");
    }
    componentWillUpdate(){
        // alert('updating...' + JSON.stringify(this.state.userMdl));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.availableToolboxes
            && this.state.availableToolboxes.length !== nextProps.dashboardReducer.availableToolboxes.length) {
            this.setState({ availableToolboxes: nextProps.dashboardReducer.availableToolboxes });
            console.log(this.state.toolboxName);
        }
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.avgDaysPerToolbox) {
            this.setState({ data: nextProps.dashboardReducer.avgDaysPerToolbox, isLoading: false });
        }
        if(this.props.userMdl  && this.props.userMdl.activeProgram !== this.state.userMdl.activeProgram){
            this.setState({userMdl: this.props.userMdl});
            let activeProgram = this.props.userMdl.activeProgram;
            let nmscCode = this.props.userMdl.nmsc;
            let langCode = this.props.userMdl.lang;
            // Fetch all available toolboxes.
            this.props.dispatch(fetchAvailableToolboxes(activeProgram, nmscCode, langCode));
            // Fetch AverageDaysPerToolbox.
            this.fetchData("", activeProgram, nmscCode, langCode);
        }
    }
    fetchData = (toolboxKey, activeProgram, nmscCode, langCode) => {
        this.setState({ isLoading: true });
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            widgetFilter: toolboxKey,
            userId:this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("avgDaysPerToolBox", "WIDGET_AVG_DAYS_PER_TOOLBOX", queryDto));
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            adaptiveHeight: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
               
                // alert(JSON.stringify(this.state.availableToolboxes[next]));
                let toolbox = this.state.availableToolboxes[next];
                let activeProgram = this.state.userMdl.activeProgram;
                let nmscCode = this.state.userMdl.nmsc;
                let langCode = this.state.userMdl.lang;
                if (toolbox) {
                    this.fetchData(this.state.availableToolboxes[next].toolboxKey, activeProgram, nmscCode, langCode);
                }

                if(next===0){
                    this.setState({leftArrow:"widget_filter_arrow_disable",rightArrow:"widget_filter_arrow"})
                }
                else if(next===(this.state.availableToolboxes.length-1)){
                    this.setState({rightArrow:"widget_filter_arrow_disable",leftArrow:"widget_filter_arrow"})
                }else{
                    this.setState({rightArrow:"widget_filter_arrow",leftArrow:"widget_filter_arrow"})
 
                }
            },
            // prevArrow: <Arrow icon="arrow-circle-left" />,
            // nextArrow: <Arrow icon="arrow-circle-right" parentClass="nextBtnClass" />
            prevArrow: <Arrow icon="chevron-left" parentClass="previousBtnClass" BtnClass={this.state.leftArrow}/>,
            nextArrow: <Arrow icon="chevron-right" parentClass="nextBtnClass" BtnClass={this.state.rightArrow}/>
        };
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.avgdays')}>{I18n.t('dashboard.avgdays')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{ minHeight: "3.2rem", paddingLeft:"3px" }}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {this.state.data.value}
                                    {this.state.data.arrowDirection ?
                                        <Fa icon={this.state.data.arrowDirection} style={{ color: this.state.data.arrowColor }}
                                            className="widget_arrow_class" size="lg" />
                                        : <div></div>}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget-header_sub" style={{paddingLeft:"10px"}}>
                            {I18n.t("Table.toolbox")}
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget_slider_class">
                                <Slider {...settings}>
                                    {this.state.availableToolboxes && this.state.availableToolboxes.length && this.state.availableToolboxes.map((t, i) => {
                                        console.log(t.toolboxName);
                                        return (<div key={i}>
                                            <span className="widget_filter" title={t.toolboxName==='all'?I18n.t("standardMessages." + t.toolboxName) : t.toolboxName }>{ t.toolboxName ==='all'?I18n.t("standardMessages." + t.toolboxName) : t.toolboxName}</span>
                                        </div>);
                                    })}
                                </Slider>
                            </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        dashboardReducer: state.dashboardReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AvgDaysPerToolbox)));
