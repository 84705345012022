import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { withRouter } from 'react-router-dom';
import KpiActualsTable from './kpiActualsTable';
import ActionPlanTable from './actionPlanTable';
import { searchBulkKpiDetails } from '../../actions/bulkCeaKpiEntryAction.jsx';
import { withTheme } from '../../themes/theming';
import { push } from "connected-react-router";
import * as AppConst from '../../AppConstant';
import './index.css';
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
var I18n = require('react-redux-i18n').I18n;
var baseUrl="/ket/v1/questionnaire/download/";

class KpiActuals extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRow: {
                activityId: this.props.location.query.selectedActivity.activityId,
                dealer: "",
                city: "",
                toolbox: this.props.location.query.selectedActivity.toolbox,
                toolboxId: this.props.location.query.selectedActivity.toolboxId,
                createdBy: "",
                status: this.props.location.query.selectedActivity.status,
                reminderOn: "",
                userMdl: "",
                nmscCode: this.props.location.query.selectedActivity.nmscCode,
                langCode: "",
                loginId: "",
                location: this.props.location.query.selectedActivity.location
            },            
            toolboxLocation: "",
            nmscWiseKpiData:[],
            actualSearchDto:{},
            monthTargetDTOList: []
        };
    }
    componentDidMount() {
        let ceaYear = new Date();
        ceaYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : ceaYear.getfullYear();
        var row = JSON.parse(this.props.location.query.selectedActivity);        
        this.setState({ selectedRow: row });
        let role =[];
        role = this.props.user.userMdl.roles
        let actualSearchDto = {
            activityId: row.activityId,
            businessFunction: row.toolboxId,
            nmscCodeList: [row.nmscCode],
            userId: this.props.user.userMdl.username,
            activeProgramId :this.props.user.userMdl.activeProgramID,
            evaluationYear : ceaYear
        };
        if(role.includes(AppConst.CEA_ROLES.KET_CEA_Admin)){         
            actualSearchDto["ceaAdmin"] = true
        }
        if(role.includes(AppConst.CEA_ROLES.KET_CEA_BO)){
            actualSearchDto["businessOwner"] = true
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            actualSearchDto["netAdmin"] = true
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            actualSearchDto["ucUser"] = true
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            actualSearchDto["lexusNetworkUser"] = true
        }
        this.setState({ actualSearchDto: actualSearchDto });   
        this.props.dispatch(searchBulkKpiDetails(actualSearchDto,"KpiActuals"))
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.kpiActualDeatils !== this.props.kpiActualDeatils && this.props.kpiActualDeatils !== undefined ){
        if(this.props.kpiActualDeatils.length>0){
            this.setState({isEditable:this.props.kpiActualDeatils[0].editable})            
        }        
        this.setState({nmscWiseKpiData:this.props.kpiActualDeatils})                
        }
    }   

    selectedTab = (index, label) => {
        this.setState({ selectedTab: index })
    }

    updateActualsData = (data) =>{
        this.setState({
            monthTargetDTOList : data
        })
    }
    statusUpdate = (status) =>{
        var row = JSON.parse(this.props.location.query.selectedActivity);
        row.status = status;
        this.setState({ selectedRow: row });
    }
    backToEvaluateActivity = () => {
        if(this.state.monthTargetDTOList.length!==0){
            confirmAlert({
                customUI: ({ title, message, onClose }) => {
                    return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>                          
                        {I18n.t("kpiEntry.dataNotSaved")}
                        </div><br></br>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                        <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                            {I18n.t("kpiEntry.no")}
                        </button>
                        <button
                            className={"button-" + this.props.theme.name}
                            onClick={() => {                                
                                this.props.dispatch(
                                    push({
                                        pathname: "/activity",
                                        query: { activitySearchDto: this.props.location.query.searchCriteria }
                                    })
                                );
                                onClose();
                            }}
                        >
                            {I18n.t("kpiEntry.yes")}
                        </button>
                        </div>
                    </div>
                    );
                }
                });
        }else{
            this.props.dispatch(
                push({
                    pathname: "/activity",
                    query: { activitySearchDto: this.props.location.query.searchCriteria }
                })
            );
        }
    }
    render() {
        let iconClass = "_icon";
        var srt =this.state.selectedRow.location;
        if(srt && srt!==undefined){
            srt = srt.replace(/\//g, '$');
            srt = srt.replace(/\./g, '_red*');
        }
        return (
            <div className="activityDiv">
                <Container className={"b-1 actionPlanContainerKpi actionPlanContainerKpi-" + this.props.theme.name}
                    style={{ minHeight: "50em", backgroundColor: "rgba(247, 247, 247, 1)" }}>
                    <Row className="activityTabsHeaderRed">
                        <Col md="4" style={{ fontSize: "20px", textTransform: 'capitalize' }}>{this.state.selectedRow.nmscCode}</Col>
                        <Col md="2">{I18n.t("ceaEvaluation.evaluationYear")}</Col>
                        <Col md="2">{I18n.t("kpiEntry.status")}</Col>
                        <Col md="4">
                            <div style={{ paddingLeft: "3rem", float: "right", fontSize: "20px", fontWeight: "bold" }}>
                                <img src={baseUrl + srt} style={{ marginRight: "5px"}} className={iconClass} alt=""/>
                                {this.state.selectedRow.toolbox}
                            </div></Col>
                    </Row>
                    <Row className="selectedCriteria activityTabsHeader">
                        <Col md="4"></Col>  
                        <Col md="2">{this.props.user.userMdl.ceaDefaultYears.value}</Col>
                        <Col md="2" style={{textTransform: 'capitalize'}}>{this.state.selectedRow.status}</Col>
                        <Col md="4"></Col>
                    </Row>
                    <Col md="12" className="activityTabs mt-2">                       
                        {(this.state.selectedRow.status!== "Draft") ?
                         <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                            <Tab label={I18n.t('kpiEntry.kpi')}>
                                <KpiActualsTable 
                                kpiData={this.state.nmscWiseKpiData} 
                                activityId ={JSON.parse(this.props.location.query.selectedActivity).activityId}
                                actualSearchDto={this.state.actualSearchDto} 
                                isEditable={this.state.isEditable}
                                updateActualsData = {this.updateActualsData}
                                statusUpdate = {this.statusUpdate}
                                />
                            </Tab>
                            <Tab label={I18n.t('kpiEntry.actionPlan')}> 
                                <ActionPlanTable 
                                    activity={this.state.selectedRow} 
                                    searchCriteria={this.props.location.query.searchCriteria}
                                    parentComponent={"KpiActuals"}
                                />                                
                            </Tab>
                        </Tabs>
                        :
                        <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab}>
                            <Tab label={I18n.t('kpiEntry.actionPlan')}>  
                                <ActionPlanTable 
                                    activity={this.state.selectedRow} 
                                    searchCriteria={this.props.location.query.searchCriteria} 
                                    parentComponent={"KpiActuals"}
                                />                             
                            </Tab>
                        </Tabs>
                        }
                    </Col>
                    <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToEvaluateActivity} style={{ cursor: "pointer", marginBottom: "15px" }} />                    
                </Container>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        // router: state.routing,
        // fileName: state.tabReducer.fileName,
        kpiActualDeatils: state.ceaKpiEntryReducer.kpiActualDeatils
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(KpiActuals)));