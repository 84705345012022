const translationsFR = {
  fr: {
    Policy: {
      HeaderMsg: "Avis de confidentialité pour le traitement de vos données personnelles dans le cadre de l'outil Kaizen Expert",
      Message1: "Cet avis de confidentialité concerne le traitement de vos données personnelles dans le cadre de l'outil Kaizen Expert. Il doit toujours être lu conjointement avec la Politique générale de protection des données personnelles et des données personnelles de Toyota, qui décrit les modalités générales du traitement de vos données personnelles et que vous devez accepter pour pouvoir continuer.",
      Message2: "Toyota Motor Europe NV / SA, dont le siège social est situé avenue du Bourget 60, à 1140 Bruxelles, Belgique, en tant que contrôleur de données, collecte vos données personnelles (comme indiqué ci-dessous) aux fins décrites ci-dessous.",
      Message3: "Quelles données personnelles seront collectées?",
      Message4: "Nous collecterons les catégories suivantes de vos données personnelles concernant Kaizen Expert Tool:",
      Table1: {
        tr1: "Catégorie / Données de données personnelles:",
        tr2: "Identifiant d'utilisateur",
        tr3: "Nom d'utilisateur (Nom complet)",
        tr4: "Marque (Toyota,Lexus)",
        tr5: "Langue préférée",
        tr6: "Organisation affiliée"
      },

      Message5: "Raison et bases légales du traitement de vos données personnelles",
      Message6: "Nous traiterons vos données personnelles sur les bases légales et les raisons décrites ci-dessous:",
      Table2: {
        tr1: {
          th1: "Raisons:",
          th2: "Bases légales:"
        },
        tr2: {
          td1: "KET - 1: Performance du concessionnaire, Kaizen",
          td2: "Intérêt légitime poursuivi par le (s) contrôleur (s) de données"
        },
        tr3: {
          td1: "KET – 2: Support IT",
          td2: "Intérêt légitime poursuivi par le (s) contrôleur (s) de données"
        }
      },
      Message7: "Durée de conservation de vos données personnelles",
      Message8: "Vos données personnelles seront conservées pendant la durée indiquée ci-dessous",
      Table3: {
        tr1: {
          th1: "Durée de conservation:",
          th2: "Catégorie / Données de données personnelles:"
        },
        tr2: {
          td1: "Toutes les informations personnelles telles que décrites ci-dessus seront conservées pendant un maximum de 5 ans + 1 mois à compter du jour de la fermeture finale de l'outil expert Kaizen.",
          td2: "Nom d'utilisateur, nom de famille, préférence linguistique, organisation"
        }
      },
      Message9: "Facultatif: en cas de divulgation / transfert de vos données personnelles vers des pays extérieurs à l'Espace économique européen (EEE)",
      Message10: "Vos données personnelles seront rendues accessibles aux destinataires situés dans des pays extérieurs à l'EEE, comme indiqué ci-dessous:",
      Table4: {
        tr1: {
          td1: "Identité",
          td2: "Pays",
          td3: ""
        },
        tr2: {
          td1: "Destinataire (s) non EEE",
          td2: "Infosys",
          td3: "Inde"
        }
      },
      Message11: "Je confirme par la présente avoir compris cet avis; J'ai lu et accepté la politique générale de Toyota sur la protection de la vie privée et des données personnelles."
    },
    ACCESSORIES: "Accessoires",
    activity: {
      Assignee: "Délégataire",
      CompletionDate: "Terminé au",
      Dealer: "Concessionaire",
      FirstVisit: "Première visite",
      kpi: {
        kpi: "KPI doivent être des chiffres positifs ou négatifs",
        kpiLength: "La longueur maximum pour les KPI est de 10 caractères",
        mandatory: "Saisissez des données valides dans tous les champs obligatoires",
        mandatoryTargets: "Veuillez saisir tous les KPI svp",
        target: "Les objectifs doivent uniquement être des nombres ",
        targetLength: "La longueur maximum pour les objectifs est de 10 caractères"
      },
      ReminderOn: "Rappel ouvert",
      statusDD: "Statut",
      Toolbox: "Boîte à outils",
      activityCreation: "La procédure de création d'activité a été initiée",
      activityCreated: "Activité(s) crée(s)"
    },
    DPOK: "DPOK",
    EFFICIENCY: "Efficacité",
    FIX_IT_RIGHT: "Réparations",
    footer: {
      message: "Champs obligatoires"
    },
    FOUNDATION: "Fondation",
    MAINTENANCE_REMINDERS: "Rappel d’entretien",
    EMPLOYEE_ENGAGEMENT: "Employee Engagement",
    CUSTOMER_CENTRICITY: "Customer Centricity",
    TOYOTA_WAY: "Toyota Way",
    BUSINESS_SUSTAINABILITY: "Business Sustainability",
    FUNDAMENTALS: "Fondamentaux",
    FIX_IT_RIGHT_BnP: "Fix it Right (B&P)",
    UTILIZATION_AND_EFFICIENCY: "Utilization and Efficiency",
    CUSTOMER_EXPERIENCE: "Customer Experience",
    UPSELLING_OPPORTUNITIES: "Upselling Opportunities",
    MANAGING_CAPACITY: "Managing Capacity",
    VEHICLE_TRADE_IN: "Vehicle Trade-In",
    STOCK_MANAGEMENT: "Stock Management",
    REFURBISHMENT: "Refurbishment",
    months: {
      apr: "Avr.",
      aug: "Août",
      dec: "Déc.",
      feb: "Févr.",
      jan: "Janv.",
      jul: "Juil.",
      jun: "Juin",
      mar: "Mars",
      may: "Mai",
      nov: "Nov.",
      oct: "Oct.",
      sep: "Sept."
    },
    others: {
      back: "Retour",
      closed: "Clôturé",
      completed: "Terminé ",
      countryLanguageCode: "Pays / Code de langue ",
      createActivity: "Créer une activité",
      createdDate: "Date de création",
      dataUneditableNote:
        "Remarque : toutes les données enregistrées ne pourront plus être modifiées. Pour modifier les données, veuillez contacter un administrateur NMSC.",
      date: "Date",
      downloadQuestionnaire: "Télécharger le questionnaire",
      editActivity: "Modifier l’activité",
      initialHelpText: "La mesure de KPI initiale devrait être la valeur moyenne des six mois complets précédents",
      inProgress: "En cours",
      name: "Nom",
      next: "Suivant",
      noActivities: "Aucune activité trouvée",
      noResults: "Aucun résultat",
      of: "sur",
      ongoing: "En cours",
      open: "Ouverte",
      page: "Page",
      planned: "Planifié",
      problemDescription: "Description du problème",
      signature: "Signature",
      select: "Sélectionnez",
      bulkAction: "Bulk Action",
      bulkActions: "Bulk Actions"
    },
    ServerMessage: {
      error_message: "Une erreur s'est produite lors du téléchargement.",
      no_published_subquestion: "Veuillez publier le questionnaire de cette boîte à outils afin de démarrer cette Activité.",
      activity_upload_error_msg: "Echec lors du chargement du détail des activités",
      action_plan_created: "Plan d'action créé avec succès",
      action_plan_deleted: "Plan d'action supprimé avec succès",
      action_plan_saved: "Plan d'action sauvegardé avec succès",
      activity_kpi_saved: "Activité KPI sauvegardée avec succès",
      master_kpi_confirm_delete:
        "Etes-vous sûr de vouloir supprimer le KPI selectionné?",
      master_kpi_created: "Le KPI principal a été créé avec succès",
      master_kpi_deleted: "Le KPI principal a été supprimé",
      master_kpi_not_found: "KPI principal introuvable",
      master_kpi_published: "Les KPI sélectionnés ont été publiés avec succès",
      master_kpi_retired: "Le KPI principal a été retiré",
      master_kpi_saved: "Le KPI principal a été enregistré avec succès",
      subquestion_created: "La sous-question a été enregistrée avec succès",
      subquestion_updated: "La sous-question a été mise à jour avec succès",
      subquestionnaire_not_found: "La sous-question n'a pas été trouvée.",
      subquestion_retired: "La sous-question a été supprimée",
      subquestion_deleted: "La sous-question a été retirée",
      subquestion_published: "Les sous-questions ont été publiées avec succès",
      mainquestion_created: "Le questionnaire principal a été créé",
      mainquestion_deleted: "Le questionnaire principal a été supprimé",
      mainquestion_updated: "La question principale a été mise à jour",
      duplicateMsg: "Doublon d'enregistrement dans le fichier excel",
      deleteErr: "Erreur lors la tentative de suppression du fichier",
      toolbox_deleted: "La boite a outils a été supprimée avec succes",
      toolbox_already_exists: "Cette boite à outil existe déjà",
      toolbox_saved: "Boite à outils sauvegardée",
      toolbox_created: "Boite à outils créé",
      toolbox_not_found: "Boite à outils introuvable",
      published_content_found: "Cette boite à outils ne peut être supprimée car contient des éléments publiés",
      dealers_already_mapped: " Le(s) site(s) est / sont déjà rattaché(s) à cet utilisateur ",
      dealer_already_mapped: " Dealer already mapped ",
      dealer_added: "  Site ajouté. ",
      dealers_added: "  Site ajouté. ",
      total_dealers_mapped_now: "Le nombre de sites référencés pour cet utilisateur est actuellement de : ",
      no_dealer_available: "Aucun site ne correspond aux critères sélectionnés",
      proper_dealer_selected: "Veuillez sélectionner un site valide",
      user_referenced_ongoing_activity: "L'utilisateur est toujours rattaché à des activités en cours. Veuillez d'abord les réaffecter avant de retenter. ",
      want_to_remove_mapping: "Confirmez vous la demande de suppression de rattachement de l'utilisateur pour ce(s) site(s) ?",
      user_mapping_removed: "Succès : l'utilisateur n'est plus rattaché à ce ou ces site(s)",
      dealer_closed_in_A2D: "Ce site est identifié comme fermé dans A2D",
      network_level_not_found: "Veuillez sélectionner un type de site en accord avec les paramétrages KET de votre réseau",
      duplicate_dealer_uuid: "Site déjà présent dans la base de données KET",
      dealer_created: "Site créé",
      dealer_updated: "Les données du site ont été mises à jour",
      dealer_not_present: "Ce site n'est pas référencé dans A2D",
      dealer_uuid_other_nmsc: "Le code UUID (A2D) sélectionné ne correspond pas à votre réseau",
      target_kpi_updated: "The KPI's status have been updated successfully.",
      target_updated: "The KPI's target have been updated successfully.",
      kpi_launched: "The KPI's bulk launch successfully.",
      targetUpdate:"Application doesn't allow target to be modified for accepted status.",
    },
    standardMessages: {
      delete: "Confirmez vous la suppression ?",
      addActionPlanNotAllowed: "Le plan d'action ne peut être créé ou édité après que l'activité ait été clôturée, réalisée ou rejetée.",
      addToolboxNotAllowed: "Cannot create more than 19 toolbox",
      britRejectMsg: "Confirmez vous le rejet de cette activité ? Aucun retour en arrière ne sera possible",
      rejectMsg: "Confirmez vous le rejet de cette activité ? Aucun retour en arrière ne sera possible",
      selectNMSCTab: "Veuillez sélectionner votre langue avant de publier",
      all: "Tous",
      deleteConfirm: "Êtes-vous sûr(e) de vouloir supprimer le KPI sélectionné ?",
      no: "Non",
      retireConfirm: "Êtes-vous sûr(e) de vouloir retirer ce KPI ? Une fois retiré, ce KPI ne peut pas être réactivé",
      warning: "Avertissement",
      yes: "Oui",
      saveDataConfirmation: "Êtes-vous sûr(e) de vouloir enregistrer les données ?",
      saveKPIWarning: "Remarque : les données ne peuvent pas être modifiées une fois enregistrées. Pour modifier les données, veuillez contacter un administrateur KET NMSC.",
      addKpiNotAllowed: "La saisie des KPI pour le prochain mois est possible quand le mois en cours est terminé.",
      activityLaunch: "Etes vous sur de vouloir lancer cette activite ? Après lancement elle ne pourra être repassée en statut planifiée ou supprimée.",
      deleteConfirmSubQuestionNaire: "Êtes-vous sûr de vouloir supprimer la sous-question sélectionnée?",
      retireConfirmSubQuestionNaire: "Êtes-vous sûr de vouloir retirer la sous-question sélectionnée?",
      deleteConfirmMainQuestion: "Veuillez confirmer si vous souhaitez supprimer la question principale et ses sous-questions associées",
      deleteConfirmChapter: "Êtes-vous sûr de vouloir supprimer le chapitre?",
      deleteLibraryFolder: "Êtes-vous sûr de vouloir supprimer ce dossier?",
      deletePublishedFolder: "Des fichiers dans ce répertoire pourrait être liés au questionnaire. Etes vous sur de vouloir supprimer définitivement ce sous répertoire et ainsi retirer tous les liens depuis le questionnaire?",
      importQuestion: "Êtes-vous sûr de vouloir importer cette sous-question? La sous-question actuelle sera remplacée.",
      noQuestion: "Aucune question disponible.",
      MandatoryWarning: "Veuillez compléter tous les champs obligatoires pour les langues",
      certificationSubmission:
        "Boite à outils soumise à TME pour certification",
      sameToolBox: "Cette boite à outils a déjà été soumise : veuillez en sélectionner une autre svp",
      deleteConfirmDealer: "Are you sure you want to delete this dealer ?",
      updateVcConfirmDealer: "Toutes les activités \"chaine de valeur\" créés pour ce site seront supprimées. Voulez vous continuer ?",
      updateBritConfirmDealer: "Toutes les activités \"BRIT\" créés pour ce site seront supprimées. Voulez vous continuer ?",
      completed_activity_delete:"Une fois les données supprimées elles ne pourront être récupérées. Voulez vous continuer ?",
      deleteAllQuestionary: "Are you sure you want to delete the entire Questionnaire?",
      deleteAllLibraries: "Are you sure you want to delete all folders and files in the Library?",
      selectAll: "Sélectionner l'ensemble",
      unSelectAll: "Déselectionner l'ensemble",
      select:"Sélectionner",
      warningMsg:"Un ou des plans d'action est/sont en cours sur cette activité, voulez vous tout de même la clôturer ?",
      updateEaConfirmDealer: "Toutes les activités \"EA\" créés pour ce site seront supprimées. Voulez vous continuer ?"

    },
    status: {
      published: "Publié",
      retired: "Retiré",
      saved: "Enregistré",
      rejected: "Rejeté",
      open: "Ouvrir",
      closed: "Clôturé",
      close: "Clôturé",
      ongoing: "En cours",
      completed: "Terminé",
      complete: "Terminé",
      planned: "Planifié",
      overdue: "Dépassé",
      due: "Prévu",
      pending: "Pending",
    },
    Rejected: "Rejeté",
    Open: "Ouvrir",
    Closed: "Clôturé",
    Close: "Clôturé",
    Ongoing: "En cours",
    Completed: "Terminé",
    Complete: "Terminé",
    Planned: "Planifié",
    tabDetails: {
      noActionAvailable: "Aucune action disponible",
      activeDealer: "Le site est actuellement considéré comme actif dans la base A2D et ne peut donc être supprimé",
      dealerDeleted: "Dealer is deleted."
    },
    Table: {
      action: "",
      activity: "Activité",
      activityCompletionDate: "Date d’achèvement de l’activité",
      activityCompletionDateFrom: "Date d'achèvement de l'activité du",
      activityCompletionDateTo: "Date d'achèvement de l'activité au",
      activityStartDate: "Date de début de l’activité",
      activityStartDateFrom: "Date début d'activité du",
      activityStartDateTo: "Date début d'activité au",
      assignedTo: "Attribué à",
      assignee: "Délégataire",
      benchmark: "Repère",
      city: "Ville",
      completedBy: "Réalisé par ",
      completionBy: "Terminé par",
      completionDate: "Terminé au",
      counterMeasure: "Mesure corrective",
      country: "Pays",
      createdBy: "Créé par",
      dealer: "Concessionaire",
      dealerNameAD: "Nom du site (A2D)",
      dealerName: "Nom du concessionnaire",
      definition: "Définition",
      description: "Description",
      dueDate: "Échéance",
      endDate: "Date d’achèvement",
      firstVisit: "Première visite",
      followup: "Suivi",
      franchise: "Franchise",
      history: "Historique",
      id: "Identifiant",
      initial: "Valeur initiale",
      kpi: "KPI",
      launch: "Lancer",
      mandatory: "Obligatoire",
      nmsc: "NMSC",
      problem: "Problème",
      publishedOn: "Date de publication",
      questionReference: "Référence de la question",
      reference: "Référence",
      reminderOn: "Rappel ouvert",
      score: "Résultat",
      startDate: "Date de début",
      status: "Statut",
      target: "Cible",
      toolbox: "Boîte à outils",
      view: "Afficher",
      kpiResult: "Résultat KPI",
      nmscTarget: "NMSC Target",
      penEbenchmark: "PanE Benchmark",
      kpiField: "KPI",
      location: "Ville",
      ActionItemReport: "Points d'action en cours",
      certification: "Statut de certification",
      noOfToolbox: "Quantité de boite à outil réalisées",
      program: "Programme",
      certificationDate: "Certifié le ",
      activityId : "Identifiant d'activité",
      approvedBy:"Approved By",
      kpis:"KPIs",
      lastmonthKpis:"Last Month KPIs",
      questionPercent:"Completion %",
      actionPlan: "Plan d’action",
      monthlyKpi:"Monthly KPIs",
      initialKpi:"Initial KPIs",
      targetKpi:"Target KPIs",
     },
     tabsDetails: {
      actionPlan: "Plan d’action",
      confirmDeleteMessage: "Êtes-vous sûr(e) de vouloir supprimer le KPI sélectionné ?",
      confirmRetireMessage: "Veuillez confirmer la suppression du KPI sélectionné, celui-ci ayant déjà été publié",
      errorRetireMsg: "Ce KPI ne peut pas être retiré, car il s’agit d’un KPI actif obligatoire de TME",
      import: "Importer",
      importKpiMessage: "Veuillez importer votre KPI depuis la liste des principaux KPI de TME",
      kpi: "Indicateurs clés de performance",
      library: "Bibliothèque",
      noActionAvailable: "Aucune action disponible",
      NoKPI: "Aucun KPI disponible",
      publish: "Publier",
      questionnaire: "Questionnaire",
      tmeKPIList: "Liste des principaux KPI de TME",
      toyotaCityBrussels: "TOYOTA CITY BRUSSELS",
      attachment: "Attachment",
      attachments: "Attachment(s)",
      uploadAttachment: "Upload Attachment",
      noAttachment: "No attachments available",
    },
    tmeAdminMaster: {
      ToolboxName: "Nom de la boite à outil",
      newToolboxName: "Nouveau nom",
      deleteToolbox: "Supprimer la boîte à outils?",
      programName: "Nom du programme",
      ToolboxName_EN: "Nom de la boite à outil (EN)",
      deleteTool: "Confirmez vous la demande de suppression de cette boite à outils ?",
      selectToolbox: "Veuillez sélectionner une boîte à outils à supprimer.",
      editToolBox: "Editer la boite à outils",
      addToolbox: "Ajouter une boite à outils",
      toolbox: "Veuillez entrer un nom pour cette boîte à outils.",
      addActionPlan: "Add Plan d’action",
      actionPlanItem: "Plan d’action – Élément n°",
      add: "Ajouter",
      addActivity: "Ajouter une activité",
      additionalInformation: "Information additionnelle",
      benchmark: "Repère",
      cancel: "Annuler",
      createEditActivity: "Créer / modifier l’activité",
      createKPI: "Créer le KPI",
      delete: "Supprimer",
      download: "Télécharger",
      edit: "Modifier",
      editKPI: "Modifier le KPI",
      keyPerformanceIndicator: "Indicateur clé de performance",
      kpiDefinition: "Définition du KPI",
      mandatoryKpi: "KPI obligatoire",
      save: "Enregistrer",
      search: "Recherche",
      unit: "Unité",
      update: "Mettre à jour",
      validData: "Veuillez saisir des données valides",
      hide: "Masquer",
      show: "Afficher",
      upload_dealer_network: "Charger les données du réseau de RA",
      upload_question: "Charger le questionnaire",
      upload_user: "Charger les données utilisateurs",
      upload_activity: "Charger les activités",
      remove: "Remove",
      toyota: "Toyota",
      lexus: "Lexus",
      launch: "Launch",
      close: "Close",
      generate: "Generate",
      dueDateExceed:"Date limite dépassée",
      delQuestionnaire:"Delete questionnaire"
    },
    toastr: {
      activityStatus_condition: "Veuillez répondre à toutes les questions afin de pouvoir conclure cette Activité.",
      emptyTarget: "Please enter the NMSC target to publish the KPI",
      retailerActual: "Please enter the Retailer Actual",
      initialTargetMonthly: "Veuillez saisir au moins 3 indicateurs clefs (KPI) :  initial, cible et au moins 1 mesure mensuelle",
      initialTarget: "Veuillez saisir le KPI initial (au début de la démarche) puis l'objectif de KPI afin de continuer.",
      upload_error: "Erreur lors du chargement de la structure réseau.",
      action_item_create_warning: "Il est possible de fusionner maximum 5 enregistrements dans un item d'action.",
      actionPlanStartDateValidation: "La date d’achèvement ne peut pas être antérieure à la date de début",
      actionsNotClosed: "Des actions sont en cours dans le plan d’action. Fermez toutes les actions avant de fermer une activité",
      activityDuplicate: "Une autre activité pour le même concessionnaire et la même boîte à outils existe déjà",
      assigneeNotExist: "Le délégataire sélectionné n’a pas été enregistré dans le système",
      assigneeValidation: "Délagataire non valide",
      atleastOnePublish: "Sélectionnez au moins un KPI à publier",
      cannotRetireActive: "Il s’agit d’un KPI actif obligatoire de TME qui ne peut pas être retiré ",
      completionDate: "La date d’achèvement ne peut pas être antérieure à la date de la première visite",
      confirDeletePlannedActivity: "Êtes-vous sûr(e) de vouloir supprimer cette activité prévue ?",
      DateformatValidation: "Le format date attendu est JJ/MM/AAAA",
      DateValidation: "La date de début ne peut être après celle de fin",
      dealerValidation: "Concessionnaire non valide",
      deleteSelectedConfirm: "Êtes-vous sûr(e) de vouloir supprimer le KPI sélectionné ?",
      duplicate: "Un autre KPI avec le même nom et la même définition existe déjà",
      modalMandatory: "Saisissez des données valides dans tous les champs obligatoires",
      noPublishKpi: "Les KPI n’ont pas encore été publiés pour la boîte à outils sélectionnée",
      validSearchCriteria: "Saisissez un critère de recherche valide",
      multiSelectPlaceHolder: "Entrer au moins 5 caractères",
      multiSelectPlaceHolderUser: "Entrer au moins 3 caractères",
      startDateValidation: "La date de début ne peut être après celle de fin",
      fileSelectionErr: "Veuillez d'abord sélectionner le fichier svp.",
      atleastOneQuestion: "Veuillez sélectionner au moins une sous-question à importer.",
      atleastOneSubQues: "Veuillez sélectionner au moins une question pour la publier",
      uploadErr: "Nombre de fichier en échec pour le chargement :",
      uploadSuccess: "Structure réseau chargée avec succès.",
      file_upload_success: "Le(s) fichier(s) a(ont) été chargé(s) avec succès",
      KPIinOngoingClosedActivity: "Ce KPI est présent dans au moins une activité en cours ou clôturée. Veuillez svp compléter ces activités avant de modifier ce KPI",
      Role_Error: "Le paramétrage de l'utilisateur est incomplet : veuillez contacter l'assistance",
      points_allocation_incorrect: "Affectation de point(s) invalide : veuillez modifier",
      activity_deleted:"Activité supprimée",
      activity_certified_dealer:"L'activité que vous voulez supprimer est liée à la certification du site et ne peut donc être supprimée",
      activity_submited_for_certificate_dealer:"L'activité est incluse dans le circuit de validation pour la certification du site et ne peut donc être supprimée"
    },
    TopNav: {
      ActivityLog: "Activités",
      ApplicationName: "Kaizen Expert Tool",
      Home: "Accueil",
      logout: "Déconnexion",
      MasterDataUpload: "Charger les données de référence",
      Toolbox: "Boite à outils",
      welcome: "Bienvenue",
      Admin: "Admin"
    },
    questionnaire: {
      addSubQuestion: "Ajouter une sous-question",
      editSubQuestion: "Modifier la sous-question",
      subQuestionTitle: "Sous-question",
      additionalInfo: "Information additionnelle",
      na: "Ajouter 'Non applicable' comme réponse possible à cette question",
      question: "Question",
      questionTitle: "Titre de la question",
      why: "Pourquoi?",
      addMainQuestion: "Ajouter une question principale",
      editMainQuestion: "Modifier la question principale",
      previousQuestion: "Question précédente",
      nextQuestion: "Question suivante",
      what: "Quoi?",
      library: "Bibliothèque",
      subQuestionTable: {
        no: "Non.",
        subQuestion: "Sous-questions",
        status: "Statut",
        publishedDate: "Date de publication",
        notApplicable: "N'est pas applicable"
      },
      links: {
        question_link_button: "Lien",
        question_link_include_folder: "Inclure un sous répertoire",
        question_link_add_link: "Lien vers des fichiers de la librairie",
        question_link_view_folder: "Aller à la librairie"
      },
      mainQuestion: "Questions principales nouvelles / mises à jour:",
      subQuestion: "Sous-questions nouvelles / mises à jour:",
      retiredQuestion: "Sous-questions supprimé:",
      libraries: "Fichiers nouveaux / mis à jour dans les bibliothèques:",
      noFiles: "Pas de fichier disponible"
    },
    library: {
      selectFile: "Veuillez sélectionner au moins un fichier.",
      fileSelected: "Fichiers sélectionnés",
      publish_library_file: "Veuillez sélectionner au moins un fichier à publier.",
      library_files_deleted: "Etes vous sur de vouloir effacer ce fichier ?",
      library_files_linked_deleted: "Etes vous sur de vouloir effacer ce fichier et les liens qui pointent vers lui dans le questionnaire ?",
      max_size_error: "La taille du fichier ne peut excéder 1Gb.",
      empty_file_desc: "Veuillez saisir un nom de fichier.",
      file_not_selected: "Veuillez sélectionner le(s) fichier(s) à charger (max 1Gb).",
      file_type_error: "Ce type de fichier n'est pas autorisé.",
      file_name_error: "Caractère invalide dans le nom du fichier.",
      file_name_lenght_error: "Le nom du fichier peut comporter 50 caractères maximum.",
      addFolder: "Ajouter dossier",
      editFolder: "Modifier dossier",
      currentFolder: "Nom de dossier",
      parentFolder: "Nom du dossier parent",
      emptyFolderName: "Le nom du dossier ne peut pas être vide.",
      invalidFolderName: "Le nom de dossier ne peut contenir le caractère /",
      folder_created: "Le dossier a été créé avec succès.",
      folder_updated: "Le dossier a été mis à jour avec succès.",
      folder_not_found: "Dossier non trouvé",
      lib_invalid_toolbox: "Boîte à outils non valide sélectionnée.",
      lib_folder_already_exists: "Ce dossier existe déjà.",
      lib_folder_deleted: "Le dossier a été supprimé avec succès.",
      library_files_published: "Les fichiers ont été publiés.",
      addFile: "Ajouter le fichier",
      editFile: "Editer le fichier",
      fileName: "Nom de fichier",
      upload: "Télécharger",
      desc: "Description",
      folderCreationErr: "Maximum 2 niveaux de hierarchie.",
      subFolderWarning: "Veuillez sélectionner un sous répertoire où charger les fichiers.",
      deleteWarning: "Veuillez sélectionner le répertoire à supprimer.",
      beforeAfterNotSelected: "Veuillez indiquer si la photo est avant ou après plan d'action (Kaizen)",
    },
    chapter: {
      chapter_exist: "Ce chapitre existe déjà.",
      chapter_saved: "Chapite enregistré",
      chapter_cannot_delete: "Ce chapitre ne peut pas être supprimé car des sous-questions ont déjà été publiées.",
      failed: "Échoué !",
      chapter_deleted: "Chapitre supprimé",
      chapter_nonEmpty: "Veuillez saisir un nom de chapitre s'il vous plaît.",
      addchapter: "Ajouter un chapitre",
      editChapter: "Modifier le chapitre",
      DELETE_SUCC:"Questionnaires deleted successfully.",
      DELETE_SUCC_LIB:"Libraries deleted successfully.",
      DELETE_ERR : "Something Went wrong."
    },
    evaluation: {
      attachments: "Pièces jointes",
      comments: "Commentaires",
      evidence: "Preuve",
      evidences: "Preuve(s)",
      yes: "Oui",
      no: "Non",
      na: "N/A",
      uploadEvidence: "Charger une preuve",
      fileUpload: "Veuillez sélectionner le(s) fichier(s) à charger (max 1Gb).",
      who: "Qui?",
      answer: "Réponse",
      comment: "Comment",
      date: "Date",
      submit: "Soumettre",
      commentMsg: "Veuillez saisir les commentaires s'il vous plaît.",
      noCommentAvailable: "Pas de commentaire disponible.",
      uploadSuccessMsg: "File(s)  have been uploaded successfully",
      uploadErrorMsg: "Number of files failing to upload:",
      fileWithSameName: "Un fichier portant le même nom déjà existe .",
      noEvidence: "Veuillez ajouter un fichier (preuve) svp ",
      createAction: "Créer un item d'action",
      chapterName: "Nom du chapitre",
      questionNo: "Question N°",
      when: "Quand?",
      noRecords: "Pas d'enregistrement disponible.",
      kaizenPoints: "Points d'amélioration",
      renameSuccessMsg: "File has been renamed successfully",
      deleteSuccessMsg: "File has been deleted successfully",
      question: "Question",
      before:"Before",
      after:"After",
      beforeAfterLabel:"La photo date t'elle d'avant ou d'après le plan d'action (Kaizen) ?",
      move:"Déplacer",
      moveMsg: "Le fichier à été déplacé",
      noToYesMsg:"Previous answer was NO",
    },
    WALK_AROUND_CHECK: "Tour du véhicule",
    ketProgram: {
      TSM: "TSM/LSM",
      BRiT: "BRiT RI",
      TSM_BNP: "Carrosserie / peinture",
      TSM_USED_CAR: "VO",
      value_chain: "Chaine de valeur",
      brit: "BRiT",
      ea: "EA",
      per:"Performance",
      nettrans:"Network Transformation",
    },
    dashboard: {
      notice_board: "Panneau d'affichage",
      kpi_target_not_set: "Les KPI cible sont absents",
      kpi_initial_not_set: "KPI initial absent",
      kpi_monthly_not_set: "Mesure mensuelle des KPI absente",
      kpi_below_target: "KPI en dessous de la valeur cible actuellement définie",
      activation: "Activation",
      users: "Utilisateur",
      nmscs: "NMSC's",
      activeRetailer: "Sites actifs",
      certificationRate: "Taux de certification",
      reCertificationRate: "Taux de re-certification",
      activityDue: "Activités prévues",
      avgdays: "Durée moyenne par boite à outil (jours)",
      overDueAct: "Activités en retard",
      actionItem: "Points d'action",
      bussResult: "Résultats métier",
      toolboxImpl: "Implémentation boite à outils",
      statusCompletedL:"Status - Completed, Progress - ",
      statusClosedL:"Status - Closed, Progress - ",
      statusOngoingL:"Status - Ongoing, Progress - ",
      recentUpdates: "Mises à jour récentes",
      rolloutTopNmsc: "Statut déploiement : top NMSC",
      bestPractice: "Panneau des bonnes pratiques",
      availableKpi: "Indicateurs clés disponibles",
      selectedKpi: "Indicateurs clés sélectionnés",
      dealerBestPractice: "Tableau de bord (bonnes pratiques)",
      kpiList: "Liste d'indicateur clés de performance (KPI)",
      go: "Go",
      nodata: "Pas de données disponibles",
      businessResultTop: "Résultat métier : top sites",
      businessResultNMSC: "Résultats métier : top NMSC"


    },
    dealerCertification: {
      dealerCertification: "Certification réseau ",
      readyForCertification: "Prêt à être certifié",
      certification: "Certification",
      submittedOn: "Soumis le",
      certificateDate: "Date de certification",
      reCertificateDate: "Date de re-certification",
      toolboxDetails: "Détails de la boite à outils",
      approve: "Approuver",
      reject: "Rejeter",
      pending: "En cours",
      region: "Region"



    },
    uploadBulk: {
      browse: "Browse",
      executionId: "Id d'opération",
      uploadedOn: "Chargé le ",
      lastUpdatedOn: "Date dernière MAJ",
      noDataTable: "No data to view",
      kpiUpload: "Chargement des KPI",
      action: "Action",
      processed: "Fait",
      error: "Erreur",
      received: "Received",
      createActivity: "Créer des activités",
      fileUpload: "File Upload",
      targetSetting: "Target Setting",
      kpiEntry:"KPI Entry",
      accepted:"Accepted",
      launchNmsc:"Launch for NMSC"
    },

    userManagement: {
      userManagement: "Gestion des utilisateurs",
      createUser: "Create User",
      editUser: "Edit User",
      viewUser: "View User",
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Prénom",
      lastName: "Nom",
      emailId: "Courriel",
      britDealerChamp: "Brit Dealer Champion",
      invalidEmail: "Please enter valid E-Mail ID",
      invalidTars: "Please enter valid TARS ID",
      invalidFirstName: "Please enter valid First Name",
      invalidLastName: "Please enter valid Last Name",
      user_created: "User Created",
      user_updated: "User updated",
      duplicate_tarsuser: "This TARS id is already registered in the KET database.",
      user_define_in_tool: "User is defined as Business owner in Active Business Function. Hence, could not be made Inactive",
      user_refer_on: "User is referenced in Ongoing activity",
      pbiAccount : "Compte PowerBI",
      invalidPbiAccount : "Le compte PowerBi doit contenir minimum 3 caractères",
      businessArea: "Périmètre métier",
      valueChainAndBRiT: "Chaine de valeur et BRIT",
      only:"Only",
      optionText:"Please select at least one of the two options below",
      valueChainOnly: "Uniquement pour \"chaine de valeur\"",
      britOnly: "Uniquement pour \"BRIT\"",
      valueChainAndEA: "Chaine de valeur et EA",
      britAndEA: "BRiT et EA",
      eaOnly: "EA Uniquement pour",
      valueChainAndBRiTAndEA: "Chaine de valeur et BRiT et EA"

    },
    UserManagement: {
      nmscId: "NMSC ID",
      tarsId: "TARS ID",
      firstName: "Prénom",
      lastName: "Nom",
      emailId: "Courriel",
      languageCode: "Code langue",
      ACTIVE: "Active",
      INACTIVE: "Inactif",
      addUser: "Ajouter un utilisateur",
      Assign: "Affecter",
      List: "Liste",
      user: "Utilisateur",
      error_message: "Une erreur s'est produite : veuillez reessayer",
      role: "Fonction de l'utilisateur",
      standardMessage: "No User Found",
      dealerCode: "Code site",
      a2d: "A2D UUID",
      britReady: "Le concessionnaire a participé à \"Brit ready\"",
      britLive: "Le concessionnaire a participé à \"Brit Live\"",
      britJapan: "Le concessionnaire a participé au programme de découverte Brit au Japon",
      britEss:"Le site a actualisé son plan d'action d'engagement des salariés",
      addDealer: "Ajouter un site (source = A2D)",
      noDealer: "Site(s) non trouvé(s)",
      dealerUuid: "Code UUID du site (A2D)",
      language: "Langue",
      L3: "L3",
      L4: "L4",
      L5: "L5",
      L6: "L6",
    },
    CeaKpi: {
      survey: "Survey",
      evaluationItem: "Evaluation Item",
      kpiMetric: "KPI Evaluation Method",
      stratagicPillar: "Strategic Pillar",
      addKPI: "Add KPI",
      edit: "Edit",
      delete: "Delete",
      subPillar: "Sub-Pillar",
      kaizenImp_impact_def: "Kaizen Importance / Impact / Definition",
      evalMethod: "Evaluation Method",
      scoreMethod: "Scoring Method",
      typeOftarget: "Type of Target",
      target: "Target",
      mode: "Mode",
      points: "Points",
      inputFreq: "Input Frequency",
      cea_kpi_created: "CEA KPI has been created successfully",
      noPointsAllocated:"No Points defined for selected Pillar/Business function mapping",
      targetIncorrect:"Target value is Invalid",
      viewKpi:"View KPI",
      general:"General",
      targetScoring:"Targets & Scoring",
      inputs:"Input Parameters",
      maxFiveParam:"Maximum 5 parameters for a KPI can be added",
      validParam:"Please Enter Valid Parameter",
      validDesc:"Please Enter Valid Parameter Description",
      dataEntry:"Data Entry in KET",
      paramater:"Parameter",
      paramDesc:"Parameter Description",
      noParamAvai:"No Parameters Available",
      kpiDeleted:"KPI deleted Successfully",
      kpiDeleteError:"KPI Not found",
      kpiActualsError:"Actuals present. KPI cannot be deleted",
      cea_kpi_updated: "CEA KPI has been updated successfully",
      addParam:"Add Parameters"

    },
    dealerManagement: {
      invalidUuid: "Veuillez saisir un identifiant UUID (A2D) valide",
      invalidDealerName: "Veuillez saisir un nom de site valide",
      invalidCity: "Veuillez saisir une destination valide",
      invaliDealerCode: "Veuillez saisir un code site valide",
      existingUuid: "Ce code UUID est déjà référencé dans la base de données de KET",
      updateUuid: "Mise à jour du code UUID (A2D)",
      newUuid: "Nouveau code UUID (A2D)",
    },
    networkManagement: {
      networkManagement: "Gestion des sites",
    },
    kpiEntry: {
      cancel : "Cancel",
      save : "Save",
      no : "No",
      yes : "Yes",
      hide : "Hide",
      show : "Show",
      dataNotSaved : "Data is not saved. Entered data may be lost. Do you want to Proceed?",
      wantToProceed : "Do you want to proceed?",
      status: 'Status',
      evaluationYear: 'Evaluation Year',
      frequency: 'Frequency',
      typeOfTarget: 'Type of Target',
      target: 'Target',
      unit: 'Unit',
      standardMessage: 'No Kpi Actuals Data Found',
      srNo: 'S.No.',
      actuals: 'Actuals',
      subPillar: 'Sub Pillar',
      businessFunc: 'Business Function',
      brand: 'Brand',
      Jan : "Jan",
      Feb : "Feb",
      Mar : "Mar",
      Apr : "Apr",
      May : "May",
      Jun : "Jun",
      Jul : "Jul",
      Aug : "Aug",
      Sep : "Sep",
      Oct : "Oct",
      Nov : "Nov",
      Dec : "Dec",
      q1 : "Q1",
      q2 : "Q2",
      q3 : "Q3",
      q4 : "Q4",
      h1 : "H1",
      h2 : "H2",
      cy : "CY",
      kpi : "Key Performance Indicators",
      actuals1 : "Actuals should not be more than allotted points. Please re-enter",
      actionPlan	: "Action Plan",
      enterActualsData : "Please enter actuals data.",
      notNumber : "Please enter valid number.",
      serverErrorMsg : "An error occured, please try again later.",
      launchEvaluationError : "Please launch evaluations.",
      actualsDataSaved : "Actuals data uploaded successfully.",
      inputTxt:"Inputs"
    },
    ceaEvaluation :{
      nmsc : "NMSC",
      search : "Search",
      all : "All",
      status : "Status",
      businessFunction :"Business Function",
      noEvaluationsFound : "No Evalutions Found",
      evaluationYear : "Evalution Year",
      view : "View",
      evaluations : "Evaluations",
      launchTargetSetting: "Launch Target Setting",
      launchEvaluation : "Launch Evaluation",
    },
    britDashboard:{
      noVideo:"No videos available",
      playVideo:"Play Video",
      trainingVideos:"Training  Videos",
      download: "Download Video",
      back:"Back"
    },
    ceaActionPlan:{
      id: "ID",
      counterMeasure: "Countermeasure",
      questionReference: "KPI Reference",
      status: "Status",
      startDate: "Start Date",
      dueDate: "Due Date",
      assignedTo: "Assigned To",
      noActionAvailable: "No Actions available",
      add: "Add",
      download: "Download",
      edit: "Edit",
      delete: "Delete",
      deleteMsg: "Are you sure you want to delete ?",
      no: "No",
      yes: "Yes",
      addActionPlanNotAllowed: "The Action Plan cannot be created, or edited after the Activity has been Closed, Completed, or Rejected.",
      actionPlanItem: "Action Plan - Item #",
      addActionPlan: "Add Action Plan",
      open: "Open",
      actionPlanStartDateValidation:"End Date cannot be earlier than the Start Date",
      modalMandatory: "Please enter valid data in all the mandatory fields",
      problem: "Problem",
      description: "Description",
      followup: "Follow Up",
      history: "History",
      cancel: "Cancel",
      save: "Save",
      update: "Update",
      assignee: "Assignee",
      endDate: "End Date", 
      select: "Select",
      notApplicablePer:"Not Applicable for CEA, Networks and Used car"
    },
    reachItDashboard:{
      topNavReachIT:"ReachIT",
      downloads:"Téléchargements",
      historyFiles:"Historique des fichiers précédents",
      reachText1:"Bienvenue sur la page de ReachIT. L'équipe de Gestion Environnementale de TME utilisera cette page pour partager les informations concernant SCIP : Les substances préoccupantes individuelles ou dans des objets complexes (Produits) établies en vertu de la directive-cadre sur les déchets (DCE) afin que vous puissiez remplir votre obligation de traitement via la notification simplifiée SCIP.",
      reachText2:"Pour toute question, veuillez contacter l'équipe de Gestion Environnementale de TME via le lien ci-dessous",
      reachText3:"Pour toute question, veuillez contacter votre NMSC",
      envMngt:"Gestion Environnementale",
      fileStatistics: "Gestion Environnementale",
      Downloaded: "Téléchargés",
      not: "Non",
      exportToExcel:"Export vers Excel"
    },
    usedCarComment:{
      ongoing:"Ongoing",
      completed:"Completed",
      commentHistory:"Monthly Comments History",
      addComment:"Add Monthly Comment",
      month:"Month",
      comment:"Comment",
      username:"Username",
      email:"Email",
      createdDate:"Created Date"
    },
  }
};

export default translationsFR;
