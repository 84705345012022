// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.browseFileBox {
  padding: 0px; 
  width: 300px; 
  margin-left: 2%; 
}
.sectionLabel{
  font-weight: bold!important;
  margin-top: -1%!important;
  margin-left: 1%!important;
  background-color: white!important;
}
`, "",{"version":3,"sources":["webpack://./src/components/masterDataUpload/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;EACZ,eAAe;AACjB;AACA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,yBAAyB;EACzB,iCAAiC;AACnC","sourcesContent":[".browseFileBox {\n  padding: 0px; \n  width: 300px; \n  margin-left: 2%; \n}\n.sectionLabel{\n  font-weight: bold!important;\n  margin-top: -1%!important;\n  margin-left: 1%!important;\n  background-color: white!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
