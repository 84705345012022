import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import { Form } from 'react-bootstrap';
import { saveSubQuestion, saveNMSCSubQuestion, saveLocalSubQuestionnaire } from './../../actions/subQuestionAction'
import "./index.css";
import { toastr } from "react-redux-toastr";
import * as AppConst from '../../AppConstant';
// import AddLibraryFileModal from "./components/addLibraryFileModal";
import AddLibraryFileModal from "../questionnaire/components/addLibraryFileModal";
import LibraryLink from "../questionnaire/components/libraryLink";
import { confirmAlert } from "react-confirm-alert"; 
import { deleteNmscSubQuestionLibraryLink } from "../../actions/libraryAction";
var I18n = require("react-redux-i18n").I18n;

class SubQuestionnairModal extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      subQuestion: this.props.selectedRow ? this.props.selectedRow.name : "",
      additionalInfo: this.props.selectedRow ? this.props.selectedRow.additionalInfo : "",
      notApplicable: this.props.selectedRow && this.props.selectedRow.notApplicable === true ? true : false,
      critical: this.props.selectedRow && this.props.selectedRow.isCritical === true ? true : false,
      libraryModal: false,
      selectedLibraryLinks: [],
      libraryLinks: [],
      isNmsc: false,
      isSubQuestion: false,
    };
    this.addLibraryLink = this.addLibraryLink.bind(this);
    this.selectLibraryLink = this.selectLibraryLink.bind(this);
    this.saveLibraryLink = this.saveLibraryLink.bind(this);
    this.deleteLibraryLink = this.deleteLibraryLink.bind(this);
  }

  componentWillMount() {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      console.log("userMd1", this.props.user.userMdl)
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1) {
          isNMSCAdminUser = true;
          isNotAdmin = false;
          break;
        }
        else if (roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET TME ADMIN"
          || roles[i].toUpperCase() === "KET-TSP Auditor"

        ) {
          isTMEAdminUser = true;
          isNotAdmin = false;
          break;
        }
        isNotAdmin = true;

      }
    }
    if (this.props.user.userMdl.roles.includes('KET NMSC Admin')) {
      isNMSCAdminUser = true;
      isNotAdmin = false;
      this.setState({ isNmsc: isNMSCAdminUser });
      this.setState({ isNotAdmin: isNotAdmin });
    }
  }

  selectLibraryLink(files) {
    this.setState({ selectedLibraryLinks: files });
  }

  saveLibraryLink(files) {
    let links = [];
    if (files.length > 0) {
      files.map((file) => {
        links.push({ ...file });
      })
    }
    this.setState({ libraryLinks: links });
  }

  saveSubQuestion = e => {
    if (!this.state.subQuestion || this.state.subQuestion === "") {
      toastr.error(I18n.t("toastr.modalMandatory"));
      return;
    }
    if (this.state.notApplicable === true && this.state.critical === true) {
      toastr.error("A question can be labeled as either 'Not Applicable' or 'Critical', but not both. Please uncheck one option and proceed.");
      return;
    }
    e.preventDefault();
    if (this.props.isNmsc) {
      if (this.props.selectedRow && this.props.selectedRow.type && this.props.selectedRow.type === "TME") {
        var subQuestionnaireDTO = {
          id: this.props.selectedRow.nmscSubQuestionId,
          questionId: this.props.questionId,
          seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
          name: this.state.subQuestion,
          additionalInfo: this.state.additionalInfo,
          notApplicable: this.state.notApplicable,
          critical: this.state.critical,
          loginId: this.props.user.userMdl.username
        }
        this.props.dispatch(saveNMSCSubQuestion(subQuestionnaireDTO, this.props.questionId));
      }
      else {
        var subQuestionnaireDTO = {
          nmscSubQuestionId: this.props.selectedRow ? this.props.selectedRow.nmscSubQuestionId : 0,
          nmscQuestionId: this.props.questionId,
          seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
          name: this.state.subQuestion,
          additionalInfo: this.state.additionalInfo,
          notApplicable: this.state.notApplicable,
          critical: this.state.critical,
          loginId: this.props.user.userMdl.username,
          nmscCode: this.props.user.userMdl.nmsc,
          nmscLang: this.props.activeTabNmscLang,
          type: this.props.selectedRow ? this.props.selectedRow.type : "Local"
        }
        this.props.dispatch(saveLocalSubQuestionnaire(subQuestionnaireDTO, this.props.questionId));
      }
    }
    else {
      var subQuestionnaireDTO = {
        id: this.props.selectedRow ? this.props.selectedRow.id : 0,
        questionId: this.props.questionId,
        seqNum: this.props.selectedRow ? this.props.selectedRow.seqNum : 0,
        name: this.state.subQuestion,
        additionalInfo: this.state.additionalInfo,
        notApplicable: this.state.notApplicable,
        critical: this.state.critical,
        loginId: this.props.user.userMdl.username
      }
      this.props.dispatch(saveSubQuestion(subQuestionnaireDTO, this.props.questionId));
    }
    this.props.toggle(null);
    this.props.resetAttachment();
  };

  onSubQuestionChange = (e) => {
    this.setState({ subQuestion: e.target.value });

  }

  onAddInfoChange = (e) => {
    this.setState({ additionalInfo: e.target.value });

  }

  onNotApplicableChange = (e) => {
    this.setState({ notApplicable: !this.state.notApplicable });
  }

  onIsCriticalChange = (e) => {
    this.setState({ critical: !this.state.critical });
  }

  addLibraryLink = row => {
    this.setState({ libraryModal: !this.state.libraryModal, modalFor: "Add" });
    this.setState({ isSubQuestion: true })
  };
  onDeleteLibraryLink = () => {
    if (this.state.selectedLibraryLinks.length <= 0) {
      toastr.error(I18n.t('library.selectFile'));
    }
    else {
      confirmAlert({
        customUI: ({ title, message, onClose }) => {
          return (
            <div className="deleteConfirmBox">
              <div className={"titleClass-" + this.props.theme.name}>
                Do You Want to delete Library Link?
              </div>
              <div className="modal-footer" style={{ borderTop: "0px" }}>
                <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                  {I18n.t("standardMessages.no")}
                </button>
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={() => {
                    this.deleteLibraryLink();
                    onClose();
                  }}
                >
                  {I18n.t("standardMessages.yes")}
                </button>
              </div>
            </div>
          );
        }
      });
    }
  }

  deleteLibraryLink() {
    //   console.log(this.state.selectedLibraryLinks);
    if (this.state.selectedLibraryLinks.length > 0) {
      if (this.state.isNmsc) {
        this.props.dispatch(deleteNmscSubQuestionLibraryLink(this.props?.selectedRow?.nmscQuestionId, this.props?.selectedRow?.nmscSubQuestionId, this.state.selectedLibraryLinks));
      }
      else {
        this.props.dispatch(deleteQuestionReference(this.state.currentQuestion, this.state.currentQuestionNumber, this.state.selectedLibraryLinks));
      }
      this.setState({ selectedLibraryLinks: [] });
    }
  }


  preventDefault() { }
  render() {
    let subQuestionAccessType = "";
    if (this.props.user.userMdl && this.props.user.userMdl.accessControl) {
      subQuestionAccessType = this.props.user.userMdl.accessControl.filter(
        obj => obj.function === "SUB_QUESTIONNAIRE"
      )[0]
        ? this.props.user.userMdl.accessControl.filter(
          obj => obj.function === "SUB_QUESTIONNAIRE"
        )[0].accessType
        : null;
    }

    const libraryContainer = (
      <LibraryLink
        subQuestionId ={this.props?.selectedRow?.nmscSubQuestionId}
        // for fetching TME sub question Id 
        //subQuestionId ={this.props?.selectedRow?.id}
        selectLibraryLinks={this.selectLibraryLink}
        checkedFiles={this.state.selectedLibraryLinks}
        saveLibraryLink={this.saveLibraryLink}
        isAttachment={this.props.isAttachment}
        isAttachmentSub ={this.props.isAttachmentSub} />
      );
      
    return (
      !this.props.isAttachment ? (<Modal
        className={"addEditQuestionModal addEditQuestionModal-" + this.props.theme.name}
        isOpen={true}
        toggle={() => this.preventDefault()}
      >
        <ModalHeader toggle={() => this.props.toggle()}>
          {this.props.modalFor && this.props.modalFor === "Add" ? I18n.t("questionnaire.addSubQuestion") : I18n.t("questionnaire.editSubQuestion")}
        </ModalHeader>

        <ModalBody>
          <Form style={{ margin: "20px" }}>
            {this.props.modalFor === "Edit" && this.props.selectedRow.seqNum ? <label style={{ color: "red" }}>{this.props.selectedRow.seqNum}</label> : ""}
            <br />
            <br />
            <label className={"questionLabel"}>
              {I18n.t("questionnaire.subQuestionTitle")}
              <span className="redFont"> *</span>
            </label>
            <textarea
              className="form-control"
              rows="3" maxLength="1000" value={this.state.subQuestion} onChange={this.onSubQuestionChange}
            />
            < br />
            <label className={"questionLabel"}>
              {this.props.user.userMdl.activeProgramName == 'Network Assessment' ? I18n.t("questionnaire.guidelines") : I18n.t("questionnaire.additionalInfo")}
            </label>
            <div>

              <textarea
                className="form-control" onChange={this.onAddInfoChange}
                rows="3" maxLength="1000" value={this.state.additionalInfo}
              />
            </div>
            <br />
            <br />
            <Form.Group controlId="formBasicChecbox">
              {console.log(this.state.critical, this.state.notApplicable)}

              <Form.Check type="checkbox" label={I18n.t("questionnaire.na")} checked={this.state.notApplicable} onChange={this.onNotApplicableChange} />

              {/*   {this.props.selectedRow && this.props.selectedRow.type && this.props.selectedRow.type === "TME" ?
                < Form.Check type="checkbox" disabled label={I18n.t("questionnaire.na")} checked={this.state.notApplicable} onChange={this.onNotApplicableChange} /> : */}

              {this.props.user.userMdl.activeProgram === AppConst.activeProgramNas ?
                <Form.Check type="checkbox" label={I18n.t("questionnaire.cr")} checked={this.state.critical} onChange={this.onIsCriticalChange} /> : ""}
            </Form.Group>
          </Form>
          {this.state.libraryModal === true ? (
            <AddLibraryFileModal
              questionId={this.props.questionId}
              questionNo={this.state.currentQuestionNumber}
              toolboxId={this.props.toolboxId}
              chapterId={this.state.chapterId}
              mainModal={this.state.libraryModal}
              alreadyLinked={this.state.libraryLinks}
              addMainQuestion={this.addLibraryLink}
              modalFor={this.state.modalFor}
              activeTabNmscLang={this.props.activeTabNmscLang}
              isNmsc={this.state.isNmsc}
            />
          ) : null}
        </ModalBody>
        <ModalFooter>
          <button
            className={"buttonCancel-" + this.props.theme.name}
            onClick={() => { this.props.toggle(this.props.modalFor), this.props.resetAttachment(); }}
          >
            {I18n.t("tmeAdminMaster.cancel")}
          </button>
          {this.props.modalFor === "Add" ? (
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.saveSubQuestion}
            >
              {I18n.t("tmeAdminMaster.save")}
            </button>
          ) : (
            <button
              className={"button-" + this.props.theme.name}
              onClick={this.saveSubQuestion}
            >
              {I18n.t("tmeAdminMaster.update")}
            </button>
          )}
        </ModalFooter>
      </Modal>) : (
        <Modal
          className={"addEditQuestionModal addEditQuestionModal-" + this.props.theme.name}
          isOpen={true}
          toggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={() => this.props.toggle()}>
            {this.props.isAttachmentSub ? "Attachments" : "Library"}
          </ModalHeader>
          <ModalBody>
            <Form style={{ margin: "20px" }}>
              <div>
                {!this.props.isAttachmentSub && subQuestionAccessType === "R/W" && this.props.user.userMdl.activeProgramName == 'Network Assessment' && <div className="d-flex pt-3" style={{ cursor: 'pointer' }} >
                  <div style={{justifyContent: 'space-between', width: '100%', display: "flex"}}>
                    <div>
                      <span>Attachments</span>
                    </div>
                    <div>
                      <a><div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" onClick={() => this.onDeleteLibraryLink() }/></a>
                      <a><div title={I18n.t('tmeAdminMaster.add')} className="add-icon" onClick={() => this.addLibraryLink(null)} /></a>
                    </div>
                  </div>
                </div>}
                {this.props.modalFor !== "Add" && this.props.user.userMdl.activeProgramName == 'Network Assessment' &&
                  <div id="collapseText" className="libraryLinkContainer">
                    <div className="collapseContainerLibrary">
                      {libraryContainer}
                    </div>
                  </div>
                }
              </div>
            </Form>

            {this.state.libraryModal === true ? (
              <AddLibraryFileModal
                questionId={this.props.questionId}
                questionNo={this.state.currentQuestionNumber}
                toolboxId={this.props.toolboxId}
                chapterId={this.state.chapterId}
                mainModal={this.state.libraryModal}
                alreadyLinked={this.state.libraryLinks}
                addMainQuestion={this.addLibraryLink}
                modalFor={this.state.modalFor}
                activeTabNmscLang={this.props.activeTabNmscLang}
                isNmsc={this.state.isNmsc}
                isSubQuestion={this.state.isSubQuestion}
                subQuestionId={this.props.selectedRow.nmscSubQuestionId}
              />
            ) : null}
          </ModalBody>
          <ModalFooter>
            <button
              className={"buttonCancel-" + this.props.theme.name}
              onClick={() => { this.props.toggle(this.props.modalFor); 
                this.props.resetAttachment()   
                if (this.props.resetAttachmentSub) {
                this.props.resetAttachmentSub(); // Call only if it exists
              } }}
            >
              {I18n.t("tmeAdminMaster.cancel")}
            </button>
          </ModalFooter>
        </Modal>
      )
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
  };
};

export default withRouter(connect(mapStateToProps)(withTheme(SubQuestionnairModal)));
