import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import FileDownload from "js-file-download";
var I18n = require("react-redux-i18n").I18n;
/**
 * Fetch Available Toolboxes for User.
 */
export function fetchAvailableToolboxes(program, nmscCode, langCode) {
    return function (dispatch) {
        axios.get('/dashboard/toolboxes/' + program + '/' + nmscCode + '/' + langCode, {
            headers: {
                unblockUI: true
            }
        })
            .then(response => {
                if (response.data !== undefined) {
                    // console.log(response.data);
                    dispatch({
                        type: "SET_TOOLBOX_FILTER",
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

export function fetchAvailableKPIs(program, nmscCode, langCode, userId) {
    return function (dispatch) {
        axios.get('/dashboard/availableKPIs/' + program + '/' + nmscCode + '/' + langCode + '/' + userId, {
            headers: {
                unblockUI: true
            }
        })
            .then(response => {

                if (response.data !== undefined) {
                    // console.log(response.data);
                    dispatch({
                        type: "SET_AVAILABLE_KPIS",
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

/**
 * Fetch Widget Data.
 * 
 * @param {*} queryDTO 
 */
export function fetchScrollableWidgetData(widgetName, reducerName, queryDTO) {
    return function (dispatch) {
        axios.post('/dashboard/widget/' + widgetName, queryDTO,
            {
                headers: {
                    unblockUI: true,
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                if (response.data !== undefined) {
                    dispatch({
                        type: reducerName,
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

/**
 * Fetch Activity Status list.
 */
export function fetchActivityStatus() {
    return function (dispatch) {
        axios.get('/dashboard/activityStatus', {
            headers: {
                unblockUI: true
            }
        })
            .then(response => {
                if (response.data !== undefined) {
                    dispatch({
                        type: "SET_ACTIVITY_STATUS",
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

export function fetchFilters(activityDto, program) {
    return function (dispatch) {
        //dispatch(setBlockingState(true));
        axios
            .post("/dashboard/searchFilters", activityDto, {
                headers: {
                    unblockUI: true,
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                dispatch({
                    type: "SET_FILTERS",
                    payload: response.data
                });
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    };
}

export function saveUserKpiPreference(data, queryDTO, program) {
    return function (dispatch) {
        axios
            .post("/dashboard/widget/saveKpiPreference/" + program + "/" + queryDTO.userNmscCode, data, {
                headers: {
                    unblockUI: true,
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                toastr.success("User Preference Saved.")
                // Recalculate Business Results.
                dispatch(fetchScrollableWidgetData("businessResults", "SET_BUSINESS_RESULTS", queryDTO));
                dispatch(fetchAvailableKPIs(queryDTO.programList, queryDTO.userNmscCode, queryDTO.userLangCode, queryDTO.userId))
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    };
}

export function filtersDashboard(selectedfilters, activeProgram, activityDto) {

    return function (dispatch) {
        dispatch({
            type: activeProgram + "_FILTER",
            payload: selectedfilters
        });

        dispatch({
            type: activeProgram + "_ACTIVITY_DTO",
            payload: activityDto
        });
    };
}

export function saveDealerPreference(data, program, userId, queryDTO) {
    return function (dispatch) {
        axios
            .post("/dashboard/widget/saveDealerPreference/" + program + "/" + userId + "/" + queryDTO.userNmscCode, data, {
                headers: {
                    unblockUI: true,
                    "Content-type": "application/json",
                    Accept: "application/json",
                    Pragma: "no-cache"
                }
            })
            .then(response => {
                toastr.success("Dealer Preference Saved.")
                dispatch(fetchScrollableWidgetData("businessResults", "SET_BUSINESS_RESULTS", queryDTO));
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    };
}


export function getNoticeBoard(userId,program,businessArea) {
    return function (dispatch) {
        axios.get('/dashboard/widget/noticeBoard/' + program+ "/"+ userId +"/"+ businessArea, {
            headers: {
                unblockUI: true
            }
        })
            .then(response => {
                if (response.data !== undefined) {
                    // console.log(response.data);
                    dispatch({
                        type: "NOTICE_BOARD",
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

export function getActionItemReport(queryDto) {
    return function (dispatch) {
        axios.post('/dashboard/widget/actionItemReport', queryDto, {
            headers: {
                unblockUI: true
            }
        })
            .then(response => {
                if (response.data !== undefined) {
                    // console.log(response.data);
                    dispatch({
                        type: "ACTION_ITEM_REPORT",
                        payload: response.data
                    })
                }
            })
            .catch(function (error) {
                toastr.error(error.message);
            });
    }
}

export function downloadActionItem(dto, queryDto) {
    return function (dispatch) {
        var url = "/dashboard/widget/download";
        axios
            .post(url, queryDto, {
                contentType: "application/octet-stream",
                headers: {
                    Pragma: "no-cache"
                }
                ,
                responseType: "arraybuffer"
            })
            .then(function (response) {
                  let fileName = response.headers["filename"].replace("%2C", ",");
                  FileDownload(response.data, decodeURI(fileName.replace(/\+/g, " ")));
            })
            .catch(function (error) {
                //dispatch(setBlockingState(false));
                console.error(error);
            });
    };
}

export function fetchBestPractice(userMdl) {
    return function (dispatch) {
        var obj = {
            "userNmscCode": userMdl.nmsc,
            "userLangCode":userMdl.lang,
            "activeProgram":userMdl.activeProgram,
            "businessArea":userMdl.businessAreaKey
        }
        var url = "/dashboard/widget/fetchBestPractice";
        axios
            .post(url, obj, {
                
                headers: {
                    Pragma: "no-cache"
                },
                responseType: "json/application"
            })
            .then(function (response) {
                if (response.data !== undefined) {
                    dispatch({
                        type: "BEST_PRACTICE",
                        payload: response.data
                    })
                }

            })
            .catch(function (error) {
                console.error(error);
            });
    };
}

/**
 * Fetch ReachIT monthly file data
 */
 export function getMonthlyUploadedFilesData(userRole) {
    return function (dispatch) {
      //dispatch(setBlockingState(true));
      axios
        .get("/monthlyFileUpload/monthlyUploadedFilesData/" +userRole, {
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          }
        })
        .then(response => {
            console.log("response",response)
          //dispatch(setBlockingState(false));
          dispatch({
            type: "MONTHLY_FILE_DATA",
            payload: response.data
          });
        })
        .catch(function (error) {
          //dispatch(setBlockingState(false));
          console.error(error);
        });
    };
  }

  export function downloadMonthlyFile(obj, fileDto) {
    return function (dispatch) {
        var preSignedURL = obj.desc;
        axios({
        method: 'get',
        contentType: "application/octet-stream",
        url: preSignedURL,
        responseType: 'arraybuffer',
        headers: { 'Pragma': 'no-cache' }
        }).then(function (response) {
            FileDownload(response.data, obj.body.replace(/%20/g, " "));
        })        
        dispatch(saveFileDownloadData(fileDto));      
    };
  }

  export function saveFileDownloadData(fileDto) {
    return function (dispatch) {     
      axios
        .post("/monthlyFileUpload/saveFileDownloadData", fileDto, {
            headers: {
                unblockUI: true,
                "Content-type": "application/json",
                Accept: "application/json",
                Pragma: "no-cache"
            }
        })
        .then(response => {
        })
        .catch(function (error) {
          toastr.error("" + error);
        });
    };
  }
  

export function exportToExcelCall(searchDto) {
    return function (dispatch) {
        axios
        .post("/monthlyFileUpload/exportUserDetails", searchDto, {
          headers: {
            'Access-Control-Allow-Origin': '*',
            "Content-type": "application/json",
            Accept: "application/json",
            Pragma: "no-cache"
          },
          responseType: "arraybuffer" 
        })
        .then(function (response) {  
            FileDownload(response.data, decodeURI(response.headers["filename"].replace(/\+/g, " ")));  
          }) 
        .catch(function (error) {
          toastr.error(I18n.t("UserManagement.error_message"));
        });
    };
  }
