// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/Artboard 4.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.redLine{
  color: #e50000;
  font-size: 0.81rem;
}

/* Table header stick  */

.nmscTable thead, .nmscTable tbody, .nmscTable tr, .nmscTable td, .nmscTable th { display: block; }

.nmscTable tr:after {
    content: ' ';
    display: block;
    visibility: hidden;
    clear: both;
}

.nmscTable tbody {
    height: 70vh;
    overflow-y: scroll;
}

.nmscTable table {
    border-spacing: 3px 7px;
    border-collapse: separate;
}

.nmscTable tbody > tr > td { 
    width: 7rem; 
    float: left;
} 
.nmscTable thead > tr > th { 
    width: 7rem;
    float: left;
} 
.nmscTable thead > tr > th:first-child { 
    width: 2rem !important;
    float: left;
}
.nmscTable tbody > tr > td:first-child  { 
    width: 2rem;
    float: left;
}

.publishButton{
    bottom: 0;
    position: 'absolute';
    width: '100%';
}

.nmscTable .disabled, :disabled {
    pointer-events: none !important;
    background-color: grey;
}

.addDisabledIcon{
    margin: 0 0.3rem;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    height: 25px;
    width: 26px;
    cursor:not-allowed;
    background-position: center;
    float:right;
    padding:1rem;
}
.nmscTable tbody{
    height:76vh;
}`, "",{"version":3,"sources":["webpack://./src/components/nmscTable/index.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA,wBAAwB;;AAExB,kFAAkF,cAAc,EAAE;;AAElG;IACI,YAAY;IACZ,cAAc;IACd,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;AACA;IACI,sBAAsB;IACtB,WAAW;AACf;AACA;IACI,WAAW;IACX,WAAW;AACf;;AAEA;IACI,SAAS;IACT,oBAAoB;IACpB,aAAa;AACjB;;AAEA;IACI,+BAA+B;IAC/B,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,yDAA2D;IAC3D,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,2BAA2B;IAC3B,WAAW;IACX,YAAY;AAChB;AACA;IACI,WAAW;AACf","sourcesContent":[".redLine{\n  color: #e50000;\n  font-size: 0.81rem;\n}\n\n/* Table header stick  */\n\n.nmscTable thead, .nmscTable tbody, .nmscTable tr, .nmscTable td, .nmscTable th { display: block; }\n\n.nmscTable tr:after {\n    content: ' ';\n    display: block;\n    visibility: hidden;\n    clear: both;\n}\n\n.nmscTable tbody {\n    height: 70vh;\n    overflow-y: scroll;\n}\n\n.nmscTable table {\n    border-spacing: 3px 7px;\n    border-collapse: separate;\n}\n\n.nmscTable tbody > tr > td { \n    width: 7rem; \n    float: left;\n} \n.nmscTable thead > tr > th { \n    width: 7rem;\n    float: left;\n} \n.nmscTable thead > tr > th:first-child { \n    width: 2rem !important;\n    float: left;\n}\n.nmscTable tbody > tr > td:first-child  { \n    width: 2rem;\n    float: left;\n}\n\n.publishButton{\n    bottom: 0;\n    position: 'absolute';\n    width: '100%';\n}\n\n.nmscTable .disabled, :disabled {\n    pointer-events: none !important;\n    background-color: grey;\n}\n\n.addDisabledIcon{\n    margin: 0 0.3rem;\n    background-image: url(\"../../assets/images/Artboard 4.png\");\n    height: 25px;\n    width: 26px;\n    cursor:not-allowed;\n    background-position: center;\n    float:right;\n    padding:1rem;\n}\n.nmscTable tbody{\n    height:76vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
