import axios from "axios";
import { toastr } from "react-redux-toastr";
var I18n = require("react-redux-i18n").I18n;


/**
 * Fetch nmsc from server.
 */
export function fetchNmscCertification(queryDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/certification/nmsc/certificationDetails", queryDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch({
          type: "NMSC_CERTIFICATION",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * Fetch nmsc from server.
 */
export function fetchTmeCertToolBooxDetails(queryDto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    console.log(queryDto)
    axios
      .post("/certification/tme/toolBoxDetails", queryDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
          console.log(response.data,"response")
        dispatch({
          type: "TME_TOOLBOX_DETAILS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

/**
 * 
 * @param {*} queryDto 
 */
export function fetchReadyForCertificationsDetails(queryDto) {
  return function (dispatch) {
    axios
      .post("/certification/readyForCertificationsDetails", queryDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch({
          type: "READY_FOR_CERTIFICATION_DETAILS",
          payload: response.data
        });
      })
      .catch(function (error) {
        console.error(error);
      });
  };
}

/**
 * Fetch nmsc from server.
 */
export function submitCertification(queryDto, dealerId, userId,dto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/certification/nmsc/submitCertification/" + dealerId + "/" + userId, queryDto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        toastr.success(I18n.t('standardMessages.certificationSubmission'))
        dispatch(fetchNmscCertification(dto));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function submitComments(userId,dto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/certification/tme/submitComment/" + userId, dto, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        toastr.success("Comment submitted.")
        dispatch(fetchNmscCertification(dto));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function rejectCertification(activityIds,dealerId,userId,dto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
    .post("/certification/tme/rejectCertification/" + dealerId + "/" + userId, activityIds , {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        console.log(response.data);
        if(response.data.desc==="PRESENT_ONGOING_ACTIVITIES"){
          dispatch({
            type:  'ONGOING_ACTIVITY_PRESENT',
            payload: false
        });
        
        console.log("Payload------ sent to reducer")
        toastr.error("Activities for the same toolbox(es) are ongoing. The rejection can be submitted only after the ongoing Activities for the same toolbox(es) are Completed.")
        }
        else if(response.data.desc==="PRESENT_CLOSED_ACTIVITIES"){
          console.log("closed activity present");
          dispatch({
            type:  'ONGOING_ACTIVITY_PRESENT',
            payload: false
        });
        
        console.log("Payload------ sent to reducer")
          toastr.error("Activities for the same toolbox(es) are closed. The rejection can be submitted only after the closed Activities for the same toolbox(es) are Completed.")
        }
        else{
          dispatch({
            type:  'ONGOING_ACTIVITY_PRESENT',
            payload: true
        });
        console.log("Payload sent to reducer")
        toastr.success("Certification Rejected.")
      
      }

        // dispatch(fetchNmscCertification(dto));

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function approveCertification(activityIds,dealerId,userId,dto) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .post("/certification/tme/approveCertification/"+ dealerId + "/" + userId, activityIds , {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        toastr.success("Certification Approved.")
        // dispatch(fetchNmscCertification(dto));

      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function filtersCertification(selectedfilters,activityDto,flag) {
  return function (dispatch) {
      dispatch({
          type:  'CERTIFICATION_FILTER',
          payload: activityDto
      });

      dispatch({
          type: 'SELECTED_CERTIFICATION_FILTER',
          payload: selectedfilters
      });

      dispatch({
        type: 'CERTIFICATION_FILTER_STATUS',
        payload: flag
    });
  };
}

export function check() {
console.log("Chckkkk")
  return function (dispatch) {
      dispatch({
          type:  'CHECK'
      });
  };
}

export function emptyNmscCertification(selectedfilters) {
  return function (dispatch) {
    dispatch({
      type: "NMSC_CERTIFICATION",
      payload: null
    });
    dispatch({
      type: "CERTIFICATION_FILTER",
      payload: selectedfilters
    });
    dispatch({
      type: "CERTIFICATION_FILTER_STATUS",
      payload: false
    });    
  };
}

export function searchEmptyNmscCertification(selectedfilters) {
  return function (dispatch) {
    dispatch({
      type: "NMSC_CERTIFICATION",
      payload: null
    });  
  };
}