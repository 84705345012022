export default function certificationReducer(state = {
    nmscCertification: [],
    certiFilters: {
        nmscCode: null,
        userId: null,
        type: null,
        langCode: null,
        loggedInUser: null,
        dealerList:[]
    }, SelectedCertiFilters: {
        selectedProgram: [],
        selectedStatus: [],
        selectedToolboxNo: [],
        selectedDealer: ''
    },CertiFlag:false,
    ongoingActivity: false,
}, action) {
    // var newState;
    switch (action.type) {
        case "NMSC_CERTIFICATION":
            return Object.assign({}, state, {
                nmscCertification: action.payload
            });
        case "READY_FOR_CERTIFICATION_DETAILS":
            return Object.assign({}, state, {
                readyForCertification: action.payload
            });
        case "TME_TOOLBOX_DETAILS":
            return Object.assign({}, state, {
                tmeToolBox: action.payload
            });
        case "CERTIFICATION_FILTER":
            return Object.assign({}, state, {
                certiFilters: action.payload
            });

        case "SELECTED_CERTIFICATION_FILTER":
            return Object.assign({}, state, {
                SelectedCertiFilters: action.payload
            });

        case "CERTIFICATION_FILTER_STATUS":
            return Object.assign({}, state, {
                CertiFlag: action.payload
            });
        case "ONGOING_ACTIVITY_PRESENT":
            return Object.assign({}, state, {
                ongoingActivity: action.payload
            });
        case "CHECK":
            return Object.assign({}, state, {
                ongoingActivity: false
            });
        default:
            return state;

    }
};