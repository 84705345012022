import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col,MDBIcon,MDBCard,MDBCardHeader,MDBCardBody,Fa} from 'mdbreact';
import { Accordion } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTheme } from '../../themes/theming';
import { push } from "connected-react-router";

import { getUserDetails } from "../../actions/loginAction";
import {filtersUserManagement,fetchUserDetails} from '../../actions/userManagementAction'
import './index.css';
import LinkFilter from './components/linkFilter';
import ListLinkDealer from './components/ListLinkDealer';
var I18n = require('react-redux-i18n').I18n;

class LinkDealer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
             userDto : {
                networkUserId: null,
                firstName:null,
                lastName:null,
                nmscId: null,
                tarsId: null,
                languageCode : null,
                emailId : null,
                role : [],
                status: null
            },
            translatedStatus: "",
            isAdmin: false,
            accessType : [],
            isActive : false
        };
    }
    componentWillMount() {

        if (
            this.props.user.userMdl === undefined ||
            this.props.user.userMdl === null ||
            this.props.user.userMdl.expired === undefined ||
            this.props.user.userMdl.expired
        ) {
            // get user Details from token.
            this.props.dispatch(getUserDetails());
        }

        let row = JSON.parse(this.props.location.query.selectedUser);
        let accessType = this.props.location.query.accessType;


        if(accessType !== null || accessType !== undefined){

            if(accessType.indexOf("R/W") !== -1 ){
                this.setState({isAdmin : true })
            }
        }

        let userDTO = {...this.state.userDto}

        if(row !== undefined || row !== null ){

        userDTO["networkUserId"] = row.networkUserId
        userDTO["nmscId"]= row.nmscId
        userDTO["tarsId"] = row.tarsId
        userDTO["firstName"] = row.firstName
        userDTO["lastName"] = row.lastName
        userDTO["emailId"] = row.emailId
        userDTO["languageCode"] = row.languageCode
        userDTO["role"] = row.role
        userDTO["status"] = row.status
        
    }

        this.setState({userDto : userDTO})
        this.setState({accessType, selectedUser: row });
       
    }

    componentWillReceiveProps(nextProps) {


    }
    selectedTab = (index, label) => {
        this.setState({ selectedTab: index })
    }

    handleOnClick = ()=>{
        if(this.state.isActive){
        this.setState({isActive :false});}
        else{
            this.setState({isActive :true});
        }

    }

    // backToUserManagement = () =>{
    //     this.props.dispatch(
    //         push({pathname: "/userManagement"})
    //     ) 
    // }

    linkFilter = ()=>{
        return (<LinkFilter></LinkFilter>)
    }

   
    backToUserManagement = () => {
        console.log("linkDealer backToUserManagement Start");
        let selectedStatusList = []
        let selectedNmscIdList = []
        let selectedRole = []
        let obj;
        console.log("linkDealer backToUserManagement Before If");
        if(this.props.location.query.searchUserCriteria.nmscIdList!== undefined 
            && this.props.location.query.searchUserCriteria.nmscIdList!== null){
            this.props.location.query.searchUserCriteria.nmscIdList.map(value => {
                obj = { 
                    label: value,            
                    value: value,
                    selected: 'selected'
                };
                selectedNmscIdList.push(obj)
                return null;
            })  
        }
        console.log("linkDealer backToUserManagement End If");
        if(this.props.location.query.searchUserCriteria.roleList!==undefined 
            && this.props.location.query.searchUserCriteria.roleList!==null){
                this.props.location.query.searchUserCriteria.roleList.map(value => {
                    obj = { 
                        label: value,            
                        value: value,
                        selected: 'selected'
                    };
                    selectedRole.push(obj)
                    return null;
                }) 
        }
        console.log("linkDealer backToUserManagement End If 2");
        if(this.props.location.query.searchUserCriteria.statusList!==undefined 
            && this.props.location.query.searchUserCriteria.statusList!==null){
            this.props.location.query.searchUserCriteria.statusList.map(value => {
                obj = { 
                    label: value,            
                    value: value,
                    selected: 'selected'
                };
                selectedStatusList.push(obj)
                return null;
            })    
        }
        console.log("linkDealer backToUserManagement End If 3");        
        this.props.dispatch(filtersUserManagement({
            selectedNmscId : selectedNmscIdList,
            selectedRole : selectedRole,
            selectedStatus : selectedStatusList,
            valueFirstName : 
            (this.props.location.query.searchUserCriteria.firstName !== undefined && this.props.location.query.searchUserCriteria.firstName !== null) 
            ? this.props.location.query.searchUserCriteria.firstName : "",
            valueLastName : (this.props.location.query.searchUserCriteria.lastName !== undefined && this.props.location.query.searchUserCriteria.lastName !== null)
            ? this.props.location.query.searchUserCriteria.lastName : "" , 
            valueTarsId : (this.props.location.query.searchUserCriteria.tarsId !== undefined && this.props.location.query.searchUserCriteria.tarsId !== null)
            ? this.props.location.query.searchUserCriteria.tarsId : ""
        }))
        console.log("linkDealer backToUserManagement Dispatch filterUserManagement End");        
        this.props.dispatch(fetchUserDetails(this.props.location.query.searchUserCriteria));
        console.log("linkDealer backToUserManagement Dispatch fetchUserDetails End --", this.props.location.query.searchUserCriteria);        
        this.props.dispatch(
            push({
                pathname: "/userManagement",
                query: { searchUserCriteria: this.props.location.query.searchUserCriteria }
            })
        );
        console.log("linkDealer backToUserManagement Dispatch push End");    
        
    }
    render() {
        console.log(this.state)

        // let isActive = {...this.state.isActive}
        //this.props.user.userMdl.accessControl.filter(obj => obj.function === 'DOWNLOAD_Q')[0].accessType;
        return (
            <div className="activityDiv">
                <Container className={"b-1 actionPlanContainer actionPlanContainer-" + this.props.theme.name}
                    style={{ minHeight: "71em", backgroundColor: "rgba(247, 247, 247, 1)" }}>
                    <Row className="activityTabsHeaderRed">
                        <Fa icon="user-circle" fixed size="lg" style={{ marginTop: '10px'}} />
                        <Col md="8" style={{ fontSize: "20px" }}>{this.state.userDto.firstName && this.state.userDto.lastName?this.state.userDto.firstName +" "+this.state.userDto.lastName:null}</Col>
                        <Col md="1"></Col>
                    </Row>
                    <Row  style={{height : "20px"}}></Row>
                    <Row className="activityTabsHeader pl-5 pr-5" >
                        <Col md="2"style={{fontWeight:"bold"}}>{I18n.t('Table.nmsc')}</Col>
                        <Col md="2" style={{textAlign:"center",fontWeight:"bold"}}>{I18n.t('UserManagement.tarsId')}</Col>
                        <Col md="3" style={{textAlign:"center",fontWeight:"bold"}}>{I18n.t('UserManagement.emailId')}</Col>
                        <Col md="3" style={{textAlign:"center",fontWeight:"bold"}}>{I18n.t('UserManagement.role')}</Col>
                        <Col md="2" style={{textAlign:"center",fontWeight:"bold"}}>{I18n.t('UserManagement.languageCode')}</Col>
                
                    </Row>
                    <Row className="selectedCriteria activityTabsHeader pl-5 pr-5">
                        <Col md="2" style={{fontWeight:"300"}}>{this.state.userDto.nmscId}</Col>
                        <Col md="2" title={this.state.userDto.tarsId} style={{textAlign:"center", textOverflow: "ellipsis", overflow: "hidden",whiteSpace: "nowrap", fontWeight:"300"}}>{this.state.userDto.tarsId}</Col>
                        <Col md="3" title ={this.state.userDto.emailId} style={{ textAlign:"center",textOverflow: "ellipsis", overflow: "hidden",whiteSpace: "nowrap",fontWeight:"300"}} >{this.state.userDto.emailId}</Col>
                        <Col md="3" title ={this.state.userDto.role.join(", ")} style={{ textOverflow: "ellipsis", overflow: "hidden",whiteSpace: "nowrap",textAlign:"center",fontWeight:"300"}}>{this.state.userDto.role?this.state.userDto.role.length>1?this.state.userDto.role.join(","):this.state.userDto.role:""}</Col>
                        <Col md="2"style={{textAlign:"center",fontWeight:"300"}}>{this.state.userDto.languageCode}</Col>
                   </Row>
                   <Col md="12" className="activityTabs mt-2">
                    <Accordion defaultActiveKey="0">
                        <MDBCard className ="assignCard">
                            <Accordion.Toggle as={MDBCardHeader} eventKey="0" onClick ={()=>this.handleOnClick}>                        
                                <label  className={'control-label ' + this.props.theme.name + '-Assign-FontWithBold'}>
                                <MDBIcon fa="true" icon="chevron-down" />
                                </label>
                                <label md="0.2" className={'control-label ' + this.props.theme.name + '-Assign-FontWithBold'} style={{fontSize: "15px",textTransform :"capitalize"}}>
                                {I18n.t('UserManagement.List')}
                                </label>
                                </Accordion.Toggle>
                            <Accordion.Collapse eventKey="0">
                                <MDBCardBody className ="cardPadding">
                                    <div >
                                        <ListLinkDealer
                                            isAdmin ={this.state.isAdmin}
                                            userDto ={this.state.userDto} 
                                        />
                                    </div>
                                </MDBCardBody>
                            </Accordion.Collapse>
                        </MDBCard>
                    {this.state.isAdmin?
                    <MDBCard className ="assignCard">
                    <Accordion.Toggle as={MDBCardHeader} eventKey="1" onClick ={()=>this.handleOnClick}>
                    <label className={'control-label ' + this.props.theme.name + '-Assign-FontWithBold'}>
                            <MDBIcon fa="true" icon="chevron-down" />
                             </label>
                            <label md="0.2" className={'control-label ' + this.props.theme.name + '-Assign-FontWithBold'} style={{fontSize: "15px",textTransform :"capitalize"}}>
                            {I18n.t('UserManagement.Assign')}
                             </label>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                    <MDBCardBody >
                        <div key="link-filter">
                        <LinkFilter
                        isAdmin ={this.state.isAdmin}
                        userDto ={this.state.userDto} 
                        />
                        </div>
                    </MDBCardBody>    
                    </Accordion.Collapse>
                    </MDBCard>:null}
                    </Accordion>
                    </Col>
                    <img src={'../../assets/images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToUserManagement} style={{ cursor: "pointer" }} />
                </Container>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        // selectedRow: state.activityReducer.selectedRow
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(LinkDealer)));