import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { saveToolboxToDB, deleteToolbox } from "../../actions/leftNavAction.jsx";
import { withTheme } from '../../themes/theming';
import { Container } from "react-bootstrap";
import { toastr } from "react-redux-toastr";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;

class AddEditToolbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toolboxName: '',
            toolboxId: '',
            isDelete: false,
            toolboxList: [],
            selectedToolboxName: '',
            packageMapPrimaryKey: 0
        }
    }

    // componentDidMount() {
    //     if (this.props.toolboxName && this.props.modalFor === 'Edit') {
    //         this.setState({ toolboxName: this.props.toolboxName });
    //         this.setState({ toolboxId: this.props.toolboxId });
    //     }
    // }
    changeToolboxName = e => {
        this.setState({ toolboxName: e.target.value });
    }

    onChangeDelete = () => {
        this.state.isDelete = !this.state.isDelete; // eslint-disable-line
        this.setState({ isDelete: this.state.isDelete });
    };

    deleteToolbox = () => {
        if ((this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer) && this.props.user.userMdl.activeProgramID > 4) {
            toastr.error(I18n.t("ceaActionPlan.notApplicablePer"));
            return;
        }
        if (
            this.state.toolboxId.length === 0
        ) {
            toastr.error(I18n.t('tmeAdminMaster.selectToolbox'));
            return;
        }
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>{I18n.t('tmeAdminMaster.deleteTool')}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.props.dispatch(deleteToolbox(this.state.packageMapPrimaryKey, this.props.user.userMdl.activeProgramID, this.props.changeToolBoxFlag, this.props.user.userMdl.flagCEA,(this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer),
                                        this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : new Date().getFullYear(),
                                        this.props.user.userMdl.getNetworkUserPrimaryKey
                                    ))
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });

    }
    saveToolbox = () => {
        let toolboxName = this.state.toolboxName.replace(/\s/g, '').trim();
        if ((this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer) && this.props.user.userMdl.activeProgramID > 4) {
            toastr.error(I18n.t("ceaActionPlan.notApplicablePer"));
            return;
        }
        if (this.props.modalFor === 'Edit' && this.state.selectedToolboxName.length === 0) {
            toastr.error(I18n.t('tmeAdminMaster.selectToolbox'));
            return;
        }
        if (toolboxName.length === 0) {
            toastr.error(I18n.t('tmeAdminMaster.toolbox'));
            return;
        }
        let toolboxDTO = {
            toolboxId: this.state.toolboxId,
            toolboxName: this.state.toolboxName,
            action: this.props.modalFor,
            userId: this.props.user.userMdl.username,
            program: this.props.user.userMdl.activeProgramID,
            type: 'TME'
        };
        this.props.dispatch(saveToolboxToDB(toolboxDTO, this));
    }

    onToolboxChange = toolbox => {
        this.setState({ selectedToolboxName: toolbox.label });
        this.setState({ toolboxId: toolbox.value });
        this.setState({ packageMapPrimaryKey: toolbox.packageMapPrimaryKey })
    };

    preventDefault() { }
    render() {
        let isAddModal = this.props.modalFor && this.props.modalFor === "Add" ? true : false;
        return (
            <Modal centered
                className={"addEditModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '20%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>
                    {isAddModal ? I18n.t('tmeAdminMaster.addToolbox') :
                        I18n.t('tmeAdminMaster.editToolBox')}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        {isAddModal ?
                            <div>
                                <Row>
                                    <label className={"toolboxLabel"}>
                                        {I18n.t('tmeAdminMaster.programName')}
                                    </label>
                                    <input type="text"
                                        className="form-control black-font"
                                        defaultValue={this.props.user.userMdl.activeProgramName}
                                        disabled="disabled" />
                                </Row>
                                <br />
                            </div>
                            : <div>
                                <div className="col-md-12 checkbox" style={{ paddingLeft: '0px' }}>
                                    <label style={{ marginBottom: '0px', paddingTop: '8px' }}>
                                        <input
                                            type="checkbox"
                                            checked={this.state.isDelete}
                                            onChange={this.onChangeDelete}
                                        />{" "}
                                        {I18n.t('tmeAdminMaster.deleteToolbox')}
                                    </label>
                                </div>
                                <br />
                                <Row>
                                    <label className={"toolboxLabel"}>
                                        {I18n.t('tmeAdminMaster.ToolboxName')}
                                        <span className="redFont"> *</span>
                                    </label>
                                    <div className="col-md-12" style={{ padding: '0px' }}>
                                        <Select
                                            placeholder={I18n.t('others.select')}
                                            value={this.state.toolbox}
                                            onChange={this.onToolboxChange}
                                            options={this.props.toolList}
                                            isClearable
                                        />
                                    </div>
                                </Row>
                            </div>}
                        <br />
                        <Row>
                            <label className={"toolboxLabel"}>
                                {isAddModal ? I18n.t('tmeAdminMaster.ToolboxName') : I18n.t('tmeAdminMaster.newToolboxName')}
                                <span className="redFont"> *</span>
                            </label>
                            <input type="text"
                                className="form-control"
                                maxLength="50"
                                disabled={this.state.isDelete}
                                value={this.state.ToolboxName} onChange={this.changeToolboxName} />
                        </Row>
                        <br />
                    </Container>
                </ModalBody>
                <ModalFooter>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle(this.props.modalFor)}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button>
                    {!isAddModal ?
                        <button
                            className={"button-" + this.props.theme.name}
                            onClick={this.deleteToolbox}
                            hidden={!this.state.isDelete}
                        >
                            {I18n.t("tmeAdminMaster.delete")}
                        </button> : ""}
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={this.saveToolbox}
                        hidden={this.state.isDelete}
                    >
                        {I18n.t("tmeAdminMaster.save")}
                    </button>
                </ModalFooter>
            </Modal>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(AddEditToolbox)));
