import React from 'react';
import { connect } from 'react-redux';
import { Modal, ModalBody, ModalHeader, Container, Row, Col } from "mdbreact";
import { withRouter } from 'react-router-dom';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import { withTheme } from '../../themes/theming';
import './index.css';
var I18n = require('react-redux-i18n').I18n;

class TablePage extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }

    preventDefault() { }

    render() {  
        return (
            <Modal centered
                className={"warningWidth uploadModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.allocateModalNo()}>
                    {I18n.t("ceaActionPlan.warning")}
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <label className={"questionLabel"}>
                            {this.props.warningMsg}
                        </label>
                        <br />
                        <br />
                        <Row >
                            <Col className="col-md-12">
                                <button style = {{float : 'right' }} 
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => this.props.allocateModalNo()}
                                >
                                    {this.props.cancelTxt}
                                </button>
                                <button style = {{float : 'right' }} 
                                    className={"button-" + this.props.theme.name}
                                    onClick={() => this.props.allocateModalYes()}
                                >
                                    {this.props.confirmtxt}
                                </button>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        toolboxByType: state.leftNavReducer,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(TablePage)));