import React from "react";
import { withRouter } from "react-router-dom";
import { withTheme } from '../../themes/theming';
import ReactPlayer from 'react-player'

class BritDashboard extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <React.Fragment>
                <div>
                    {/* <video width="720" height="420" controls style={{ marginLeft: '20%', marginTop: '29px' }}>
                        <source src={this.props.video} type="video/mp4" />
                    </video> */}
                        <ReactPlayer 
                        controls={true}
                        url={this.props.video}
                        width="50%"
                        height="50%"
                        style={{ display:"flex",justifyContent:"center",alignItems:"center",padding:"2rem 0 0 0",margin:"auto" }}
                    />
                </div>
                < img className="backToBritVideoTable" src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.props.onToggle} style={{
                    cursor: "pointer", position: "absolute",
                    bottom: "0rem"
                }} />
            </React.Fragment>
        )
    }
}

export default withRouter((withTheme(BritDashboard)));
