import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "mdbreact";
import { withRouter } from "react-router-dom";
import HistoryTable from "./components/historyTable"
import UploadPanel from "./components/uploadPanel"
import SearchFilter from "./components/searchFilter"
import EvaluationSerach from "../../components/launchTargetScreen/components/evaluationSerach";
import SearchFilterActivity from "./components/searchFilterActivity"
import SearchFilterQuestionnaire from "./components/searchFilterQuestionnaire"
import SearchTargetSetting from "./components/searchTargetSetting"
import TreeTargetSetting from "./components/treeTargetSetting"
import KpiEntryOverView from "./components/kpiEntryOverView"
import HistoryKpiTable from "./components/historyKpiTable"
import HistoryQuestionnaireTable from "./components/historyQuestionnaireTable"
import UploadKpiPanel from "./components/uploadKpiPanel"
// import UploadQuestionnairePanel from "./components/uploadQuestionnairePanel"
import SearchKpiEntry from "./components/searchKpiEntry";
import { searchEmptyCEASearchFilters } from '../../actions/finalizeTargetAction.jsx';
import { getBulkDownloadList, getBulkUploadList, getMonthlyKpiUploadList } from "../../actions/bulkUploadAction";
import { Tabs, Tab } from 'react-bootstrap-tabs';
import { withTheme } from "../../themes/theming";
import $ from "jquery";
import "./index.css";
import * as AppConst from '../../AppConstant';
var I18n = require("react-redux-i18n").I18n;


class BulkUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSearchFlag: false,
      showSearchTargetFlag: true,
      showSearchKpiEntryFlag: true,
      selectedTab: 0,
      createActivityFlag: true,
      createQuestionnaireFlag: true,
      searchKpiEntryDTO: {},
      list: [],
      isNmsc: false,
      uploadList: [],
      kpiUploadList: [],
      uploadQuestionnaireList: [],
      fileCriteria: {
        sizePerPage: 10,
        page: 1,
        fileType: '',
        username: ''
      },
      activityDto: {
        nmscCodeList: [],
        selectedNmscCodeList : [],
        businessFunctionList : [],
        selectedBusinessFunction: [],
        statusList:[],
        userId: this.props.user.userMdl.username,
        userNmscCode : this.props.user.userMdl.nmsc,
        ceaYear: this.props.user.userMdl.ceaDefaultYears.value,
        programID: this.props.user.userMdl.activeProgramID,
        program: this.props.user.userMdl.activeProgram,
        loggedInUser: this.props.user.userMdl.username,
        rows: 10,
        page: 1,
        sizePerPage: 10,
        businessOwner:false,
        activeProgramName : this.props.user.userMdl.activeProgramName

    },
    };
  }

  componentDidMount() {
    this.props.dispatch(searchEmptyCEASearchFilters());
  }

  componentWillMount() {
    var isNMSCAdminUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf("KET NMSC ADMIN") !== -1) {
          isNMSCAdminUser = true;
          break;
        }
      }
    }
    this.setState({ isNmsc: isNMSCAdminUser });
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps.bulkUpload)
    if (nextProps.bulkUpload.dataUploadFileList) {
      this.setState({ list: nextProps.bulkUpload.dataUploadFileList })
    }
    if (nextProps.bulkUpload.uploadList) {
      this.setState({ uploadList: nextProps.bulkUpload.uploadList })
    }
    if (nextProps.bulkUpload.uploadKpiList) {
      this.setState({ kpiUploadList: nextProps.bulkUpload.uploadKpiList })
    }
    if (nextProps.bulkUpload.uploadQuestionnaireList) {
      this.setState({ questionnaireUploadList: nextProps.bulkUpload.uploadQuestionnaireList })
    }
  }

  onClickBritRefresh = (e) => {
    this.props.dispatch(getBulkDownloadList(this.state.list));
    this.props.dispatch(getBulkUploadList(this.state.uploadList));
  }

  onClickActivityRefresh = (e) => {
    this.props.dispatch(getBulkDownloadList(this.state.list));
    this.props.dispatch(getBulkUploadList(this.state.uploadList));
  }

  onClickKpiRefresh = (e) => {
    console.log(e)
    this.props.dispatch(getBulkDownloadList(this.state.list));
    this.props.dispatch(getMonthlyKpiUploadList(this.state.kpiUploadList));
  }
  onClickQuestionaireRefresh = (e) =>{
    this.props.dispatch(getBulkDownloadList(this.state.list));
    this.props.dispatch(getMonthlyKpiUploadList(this.state.kpiUploadList));
  }

  toggleSearch = toggle => {
    this.setState({
      showSearchFlag: !this.state.showSearchFlag,
      showSearchTargetFlag: !this.state.showSearchTargetFlag,
    });
  };
  toggleSearchKpiEntry = toggle => {
    this.setState({
      showSearchKpiEntryFlag: !this.state.showSearchKpiEntryFlag
    });
  };

  selectedTab = (index, label) => {
    if (this.props.user.userMdl.businessAreaKey === 'CEA' && index === 1) {
      this.setState({ selectedTab: index, showSearchKpiEntryFlag: true });
    }
    else {
      this.setState({ selectedTab: index, showSearchFlag: false, showSearchTargetFlag: false, showSearchKpiEntryFlag: false });
    }
  }

  handleTabStyle() {
    if (this.props.theme.name === "toyota") {
      $(".uploadContainer .nav-link").css('border', '1px solid transparent');
      $(".uploadContainer .nav-link.active").css('border-bottom-color', '#e50000')

    } else {
      $(".uploadContainer .nav-link").css('border', '1px solid transparent');
      $(".uploadContainer .nav-link.active").css('border-bottom-color', '#252525')
    }
  }

  updateSerachKpiEntryDTO = (searchKpiEntryDto) => {
    console.log("updateSerachKpiEntryDTO", searchKpiEntryDto)
    this.setState({ searchKpiEntryDTO: searchKpiEntryDto })
  }


  render() {
    this.handleTabStyle()
    console.log("list", this.state.list)
    console.log(this.props.user.userMdl.businessAreaKey)

    return (
      <Container fluid className="ml-0 mb-1">
        <Row className="mr-0">
          {this.props.user.userMdl.businessAreaKey === "BRiT" ? (
            <Col md="12" className="paddingTab uploadContainer">
              <Tabs
                onSelect={this.selectedTab}
                selected={this.state.selectedTab}
              >
                <Tab label={I18n.t("uploadBulk.kpiUpload")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickBritRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                      <SearchFilter flag={this.state.showSearchFlag} />
                    </div>
                    <br />
                    <div>
                      <UploadPanel
                        bulkUploadProps={this.props.bulkUpload}
                        fileType={"BRIT_UPLOAD_ACTIVITY"}
                      />
                      <br />
                      <HistoryTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.uploadActivityFileList}
                        fileType={"BRIT_UPLOAD_ACTIVITY"}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <br />
            </Col>
          ) : this.props.user.userMdl.businessAreaKey === "CEA" ||
            this.props.user.userMdl.activeProgram === "NET" || this.props.user.userMdl.activeProgram === "LN" ? (
            <Col md="12" className="paddingTab uploadContainer">
              {this.props.user.userMdl.businessAreaKey === "CEA" ? (
                <Tabs
                  onSelect={this.selectedTab}
                  selected={this.state.selectedTab}
                >
                  <Tab label={I18n.t("uploadBulk.targetSetting")}>
                    <div className="downloadTreeContainer">
                      <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label
                          md="0.5"
                          className={
                            "control-label " +
                            this.props.theme.name +
                            "-FontWithBold"
                          }
                        >
                          {I18n.t("ceaEvaluation.search")}
                        </label>
                        <label
                          md="0.5"
                          className="control-label boldFont"
                          style={{ marginTop: "0.2rem" }}
                        >
                          <a onClick={this.toggleSearch}>
                            {this.state.showSearchTargetFlag ? (
                              <div
                                title={I18n.t("tmeAdminMaster.hide")}
                                className={
                                  this.props.theme.name + "-hide-icon"
                                }
                              />
                            ) : (
                              <div
                                title={I18n.t("tmeAdminMaster.show")}
                                className={
                                  this.props.theme.name + "-show-icon"
                                }
                              />
                            )}
                          </a>
                        </label>
                        <SearchTargetSetting
                          flag={this.state.showSearchTargetFlag}
                        />
                      </div>
                      <div>
                        <TreeTargetSetting />
                      </div>
                      <br />
                    </div>
                  </Tab>
                  <Tab label={I18n.t("uploadBulk.kpiEntry")}>
                    <div className="downloadTreeContainer">
                      <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label
                          md="0.5"
                          className={
                            "control-label " +
                            this.props.theme.name +
                            "-FontWithBold"
                          }
                        >
                          {I18n.t("ceaEvaluation.search")}
                        </label>
                        <label
                          md="0.5"
                          className="control-label boldFont"
                          style={{ marginTop: "0.2rem" }}
                        >
                          <a onClick={this.toggleSearchKpiEntry}>
                            {this.state.showSearchKpiEntryFlag ? (
                              <div
                                title={I18n.t("tmeAdminMaster.hide")}
                                className={
                                  this.props.theme.name + "-hide-icon"
                                }
                              />
                            ) : (
                              <div
                                title={I18n.t("tmeAdminMaster.show")}
                                className={
                                  this.props.theme.name + "-show-icon"
                                }
                              />
                            )}
                          </a>
                        </label>
                        <SearchKpiEntry
                          flag={this.state.showSearchKpiEntryFlag}
                          updateSerachDto={this.updateSerachKpiEntryDTO}
                        />
                      </div>

                      <KpiEntryOverView
                        searchDto={this.state.searchKpiEntryDTO}
                      />

                      <br />
                    </div>
                  </Tab>
                </Tabs>
              ) : (
                <Tabs
                  onSelect={this.selectedTab}
                  selected={this.state.selectedTab}
                >
                  {/* <Tab label={I18n.t("uploadBulk.kpiEntry")}>
                    <div className="downloadTreeContainer">
                      <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label
                          md="0.5"
                          className={
                            "control-label " +
                            this.props.theme.name +
                            "-FontWithBold"
                          }
                        >
                          {I18n.t("ceaEvaluation.search")}
                        </label>
                        <label
                          md="0.5"
                          className="control-label boldFont"
                          style={{ marginTop: "0.2rem" }}
                        >
                          <a onClick={this.toggleSearchKpiEntry}>
                            {this.state.showSearchKpiEntryFlag ? (
                              <div
                                title={I18n.t("tmeAdminMaster.hide")}
                                className={
                                  this.props.theme.name + "-hide-icon"
                                }
                              />
                            ) : (
                              <div
                                title={I18n.t("tmeAdminMaster.show")}
                                className={
                                  this.props.theme.name + "-show-icon"
                                }
                              />
                            )}
                          </a>
                        </label>
                        <SearchKpiEntry
                          flag={this.state.showSearchKpiEntryFlag}
                          updateSerachDto={this.updateSerachKpiEntryDTO}
                        />
                      </div>

                      <KpiEntryOverView
                        searchDto={this.state.searchKpiEntryDTO}
                      />

                      <br />
                    </div>
                  </Tab> */}
                  <Tab label={I18n.t("uploadBulk.kpiUpload")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickKpiRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                            <EvaluationSerach
                              flag={this.state.showSearchFlag}
                              activityDto={this.state.activityDto}
                            />
                    </div>
                    <br />

                    <div>
                      <UploadKpiPanel
                        bulkUploadProps={this.props.bulkUpload}
                      />
                      <br />
                      <HistoryKpiTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.kpiUploadFileList}
                      />
                    </div>
                  </div>
                </Tab>
                </Tabs>
              )}
              <br />
            </Col>
          ) : this.props.user.userMdl.businessAreaKey === "EA" ? (
            <Col md="12" className="paddingTab uploadContainer">
              <Tabs
                onSelect={this.selectedTab}
                selected={this.state.selectedTab}
              >
                <Tab label={I18n.t("uploadBulk.createActivity")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickActivityRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                      <SearchFilterActivity
                        flag={this.state.showSearchFlag}
                        createActivityFlag={this.state.createActivityFlag}
                      />
                    </div>
                    <br />
                    <div>
                      <UploadPanel
                        bulkUploadProps={this.props.bulkUpload}
                        fileType={
                          this.props.user.userMdl.businessAreaKey === "EA"
                            ? "EA_UPLOAD_ACTIVITY"
                            : "VALUE_CHAIN_UPLOAD_ACTIVITY"
                        }
                      />
                      <br />
                      <HistoryTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.uploadActivityFileList}
                        fileType={
                          this.props.user.userMdl.businessAreaKey === "EA"
                            ? "EA_UPLOAD_ACTIVITY"
                            : "VALUE_CHAIN_UPLOAD_ACTIVITY"
                        }
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <br />
            </Col>
          ) : this.props.user.userMdl.activeProgram === AppConst.activeProgramUc? (
            <Col md="12" className="paddingTab uploadContainer">
              <Tabs
                onSelect={this.selectedTab}
                selected={this.state.selectedTab}
              >
                <Tab label={I18n.t("uploadBulk.kpiUpload")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickKpiRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                            <EvaluationSerach
                              flag={this.state.showSearchFlag}
                              activityDto={this.state.activityDto}
                            />
                    </div>
                    <br />

                    <div>
                      <UploadKpiPanel
                        bulkUploadProps={this.props.bulkUpload}
                      />
                      <br />
                      <HistoryKpiTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.kpiUploadFileList}
                      />
                    </div>
                  </div>
                </Tab>
              </Tabs>
              <br />
            </Col>
          ) :this.state.isNmsc===true?
          (
            <Col md="12" className="paddingTab uploadContainer">
              <Tabs
                onSelect={this.selectedTab}
                selected={this.state.selectedTab}
              >
                <Tab label={I18n.t("uploadBulk.createActivity")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickActivityRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                      <SearchFilterActivity
                        flag={this.state.showSearchFlag}
                        createActivityFlag={this.state.createActivityFlag}
                      />
                    </div>
                    <br />
                    <div>
                      <UploadPanel
                        bulkUploadProps={this.props.bulkUpload}
                        fileType={"VALUE_CHAIN_UPLOAD_ACTIVITY"}
                      />
                      <br />
                      <HistoryTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.uploadActivityFileList}
                        fileType={"VALUE_CHAIN_UPLOAD_ACTIVITY"}
                      />
                    </div>
                  </div>
                </Tab>

                <Tab label={I18n.t("uploadBulk.kpiUpload")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickKpiRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                      <SearchFilter flag={this.state.showSearchFlag} />
                    </div>
                    <br />

                    <div>
                      <UploadKpiPanel
                        bulkUploadProps={this.props.bulkUpload}
                      />
                      <br />
                      <HistoryKpiTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.kpiUploadFileList}
                      />
                    </div>
                  </div>
                </Tab>
                {/* tab for questionnaire upload/download */}
                 <Tab label={I18n.t("uploadBulk.questionnaireUpload")}>
                  <div className="downloadTreeContainer">
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                      <label
                        md="0.5"
                        className={
                          "control-label " +
                          this.props.theme.name +
                          "-FontWithBold"
                        }
                      >
                        {I18n.t("tmeAdminMaster.search")}
                      </label>
                      <label
                        md="0.5"
                        className="control-label boldFont"
                        style={{ marginTop: "0.2rem" }}
                      >
                        <a onClick={this.toggleSearch}>
                          {this.state.showSearchFlag ? (
                            <div
                              title={I18n.t("tmeAdminMaster.hide")}
                              className={
                                this.props.theme.name + "-hide-icon"
                              }
                            />
                          ) : (
                            <div
                              title={I18n.t("tmeAdminMaster.show")}
                              className={
                                this.props.theme.name + "-show-icon"
                              }
                            />
                          )}
                        </a>
                      </label>
                      <div
                        className="col-md-11"
                        style={{ paddingRight: "30px" }}
                      >
                        <button
                          className={
                            "button-" +
                            this.props.theme.name +
                            " " +
                            "float-right"
                          }
                          onClick={() => this.onClickQuestionaireRefresh()}
                        >
                          <i className="fa fa-refresh"></i>
                        </button>
                      </div>
                      <SearchFilterQuestionnaire flag={this.state.showSearchFlag}  createQuestionnaireFlag={this.state.createQuestionnaireFlag}/>
                    </div>
                    <br />


                    <div>
                      <UploadPanel
                      bulkUploadProps={this.props.bulkUpload}
                      fileType={"VC_QUESTIONNAIRE_UPLOAD"}
                    />
                      <br />
                      <HistoryQuestionnaireTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.kpiUploadFileList} 
                      />
                       {/* <HistoryTable
                        bulkUploadProps={this.props.bulkUpload}
                        data={this.props.bulkUpload.uploadActivityFileList}
                        fileType={"VC_QUESTIONNAIRE_UPLOAD"}
                      /> */}
                    </div>
                  </div> 
                </Tab>
              </Tabs>
              <br />
            </Col>
          ):
            (
              <Col md="12" className="paddingTab uploadContainer">
                <Tabs
                  onSelect={this.selectedTab}
                  selected={this.state.selectedTab}
                >
                  <Tab label={I18n.t("uploadBulk.createActivity")}>
                    <div className="downloadTreeContainer">
                      <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label
                          md="0.5"
                          className={
                            "control-label " +
                            this.props.theme.name +
                            "-FontWithBold"
                          }
                        >
                          {I18n.t("tmeAdminMaster.search")}
                        </label>
                        <label
                          md="0.5"
                          className="control-label boldFont"
                          style={{ marginTop: "0.2rem" }}
                        >
                          <a onClick={this.toggleSearch}>
                            {this.state.showSearchFlag ? (
                              <div
                                title={I18n.t("tmeAdminMaster.hide")}
                                className={
                                  this.props.theme.name + "-hide-icon"
                                }
                              />
                            ) : (
                              <div
                                title={I18n.t("tmeAdminMaster.show")}
                                className={
                                  this.props.theme.name + "-show-icon"
                                }
                              />
                            )}
                          </a>
                        </label>
                        <div
                          className="col-md-11"
                          style={{ paddingRight: "30px" }}
                        >
                          <button
                            className={
                              "button-" +
                              this.props.theme.name +
                              " " +
                              "float-right"
                            }
                            onClick={() => this.onClickActivityRefresh()}
                          >
                            <i className="fa fa-refresh"></i>
                          </button>
                        </div>
                        <SearchFilterActivity
                          flag={this.state.showSearchFlag}
                          createActivityFlag={this.state.createActivityFlag}
                        />
                      </div>
                      <br />
                      <div>
                        <UploadPanel
                          bulkUploadProps={this.props.bulkUpload}
                          fileType={"VALUE_CHAIN_UPLOAD_ACTIVITY"}
                        />
                        <br />
                        <HistoryTable
                          bulkUploadProps={this.props.bulkUpload}
                          data={this.props.bulkUpload.uploadActivityFileList}
                          fileType={"VALUE_CHAIN_UPLOAD_ACTIVITY"}
                        />
                      </div>
                    </div>
                  </Tab>

                  <Tab label={I18n.t("uploadBulk.kpiUpload")}>
                    <div className="downloadTreeContainer">
                      <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label
                          md="0.5"
                          className={
                            "control-label " +
                            this.props.theme.name +
                            "-FontWithBold"
                          }
                        >
                          {I18n.t("tmeAdminMaster.search")}
                        </label>
                        <label
                          md="0.5"
                          className="control-label boldFont"
                          style={{ marginTop: "0.2rem" }}
                        >
                          <a onClick={this.toggleSearch}>
                            {this.state.showSearchFlag ? (
                              <div
                                title={I18n.t("tmeAdminMaster.hide")}
                                className={
                                  this.props.theme.name + "-hide-icon"
                                }
                              />
                            ) : (
                              <div
                                title={I18n.t("tmeAdminMaster.show")}
                                className={
                                  this.props.theme.name + "-show-icon"
                                }
                              />
                            )}
                          </a>
                        </label>
                        <div
                          className="col-md-11"
                          style={{ paddingRight: "30px" }}
                        >
                          <button
                            className={
                              "button-" +
                              this.props.theme.name +
                              " " +
                              "float-right"
                            }
                            onClick={() => this.onClickKpiRefresh()}
                          >
                            <i className="fa fa-refresh"></i>
                          </button>
                        </div>
                        <SearchFilter flag={this.state.showSearchFlag} />
                      </div>
                      <br />

                      <div>
                        <UploadKpiPanel
                          bulkUploadProps={this.props.bulkUpload}
                        />
                        <br />
                        <HistoryKpiTable
                          bulkUploadProps={this.props.bulkUpload}
                          data={this.props.bulkUpload.kpiUploadFileList}
                        />
                      </div>
                    </div>
                  </Tab>
                
                </Tabs>
                <br />
              </Col>
            )
          }
        </Row>
      </Container>
    );

  }

}


const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    bulkUpload: state.bulkUploadReducer

  };
};
export default withRouter(
  connect(mapStateToProps)(withTheme(BulkUpload))
);