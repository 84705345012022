import { MDBCol, MDBRow } from 'mdbreact';
import React from "react";
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import { withTheme } from "../../../themes/theming";
import Slider from "react-slick";
import Arrow from '../arrow';
import '../index.css';
var I18n = require("react-redux-i18n").I18n;
class ActionItems extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            status: [{value: "OVERDUE", label: "Overdue"},{value: "DUE", label: "Due"},{value: "OPEN", label: "Open"}],
            data: {},
            leftArrow:"widget_filter_arrow_disable",
            rightArrow:"widget_filter_arrow"
        };
    }
    componentWillMount() {
        this.fetchData(this.state.status[0].value);
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.actionItem) {
            // console.log(nextProps.dashboardReducer.activities)
            this.setState({ data: nextProps.dashboardReducer.actionItem, isLoading: false });
        }
    }
    fetchData = (status) => {
        this.setState({ isLoading: true });
        let queryDto = {
            widgetFilter : status,
            userId : this.props.userMdl.username,
            activeProgram : this.props.userMdl.activeProgram,
            businessArea: this.props.userMdl.businessAreaKey 
        };
        this.props.dispatch(fetchScrollableWidgetData("actionItem", "WIDGET_ACTION_ITEM", queryDto));
    }
    render() {
        const settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            beforeChange: (current, next) => {
                // alert(JSON.stringify(this.state.availableToolboxes[next]));
               
                let status = this.state.status[next];
                if (status) {
                    this.fetchData(status.value);
                }

                if(next===0){
                    this.setState({leftArrow:"widget_filter_arrow_disable",rightArrow:"widget_filter_arrow"})
                }
                else if(next===(this.state.status.length-1)){
                    this.setState({rightArrow:"widget_filter_arrow_disable",leftArrow:"widget_filter_arrow"})
                }else{
                    this.setState({rightArrow:"widget_filter_arrow",leftArrow:"widget_filter_arrow"})
 
                }
            },
            // prevArrow: <Arrow icon="arrow-circle-left" />,
            // nextArrow: <Arrow icon="arrow-circle-right" parentClass="nextBtnClass" />
            prevArrow: <Arrow icon="chevron-left" parentClass="previousBtnClass" BtnClass={this.state.leftArrow}/>,
            nextArrow: <Arrow icon="chevron-right" parentClass="nextBtnClass" BtnClass={this.state.rightArrow}/>
        };
        return (
            <div>
                <div className={"widget-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.actionItem')}>{I18n.t('dashboard.actionItem')}</div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="scrollable-widget-content">
                        <MDBRow style={{  minHeight: "3.2rem" }}>
                            <MDBCol lg="12" md="12">
                                <div className="widget_count_class">
                                    {this.state.data.actionItem}
                                </div>
                            </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget-header_sub">
                            {I18n.t("Table.status")}
                           </MDBCol>
                        </MDBRow>
                        <MDBRow style={{minHeight: "1.7rem"}}>
                            <MDBCol  lg="12" md="12" className="widget_slider_class">
                                <Slider {...settings}>
                                    {this.state.status.map((t, i) => {
                                        return (<div key={i}>
                                            <span className="widget_filter" title={I18n.t('status.'+t.label.toLowerCase())}>{I18n.t('status.'+t.label.toLowerCase())}</span>
                                        </div>);
                                    })}
                                </Slider>
                           </MDBCol>
                        </MDBRow>
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        dashboardReducer: state.dashboardReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ActionItems)));