import React from 'react';
import { withTheme } from './themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { setLocale } from 'react-redux-i18n';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import en from './components/i18n/date-fns/locale/en';
import fr from './components/i18n/date-fns/locale/fr';
import de from './components/i18n/date-fns/locale/de';
import nl from './components/i18n/date-fns/locale/nl';
import pl from './components/i18n/date-fns/locale/pl';
import pt from './components/i18n/date-fns/locale/pt';
import ro from './components/i18n/date-fns/locale/ro';
import ru from './components/i18n/date-fns/locale/ru';
import tr from './components/i18n/date-fns/locale/tr';
import sl from './components/i18n/date-fns/locale/sl';
import et from './components/i18n/date-fns/locale/et';
import lt from './components/i18n/date-fns/locale/lt';
import lv from './components/i18n/date-fns/locale/lv';
import sv from './components/i18n/date-fns/locale/sv';
import * as AppConst from './AppConstant';
class KetLocale extends React.Component {
    
    componentDidUpdate(prevProps,prevState){
        let locale = "translations" + this.props.user.userMdl.lang.toUpperCase() + "." + this.props.user.userMdl.lang.toLowerCase();
        let lang = this.props.user.userMdl.lang;
        if(this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer){
            this.props.dispatch(setLocale("translationsEN.en"));
            registerLocale('lang', this.passLang("en"));
            setDefaultLocale('lang');
            return
        }
        this.props.dispatch(setLocale(locale));
        registerLocale('lang', this.passLang(lang));
        setDefaultLocale('lang');
    }
    
    
    componentDidMount() {
       
    }

    passLang(lang) {
        switch (lang) {
            case "en":
                return en
            case "fr":
                return fr
            case "de":
                return de
            case "nl":
                return nl
            case "pl":
                return pl
            case "pt":
                return pt
            case "ro":
                return ro
            case "ru":
                return ru
            case "tr":
                return tr
            case "sl":
                return sl
            case "et":
                return et
            case "lt":
                return lt
            case "lv":
                return lv
            case "sv":
                return sv
            default:
                return en
        }
    }

    render() {
        return (
            <div></div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.router
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(KetLocale)));