import React from "react";
import { connect } from "react-redux";
import { Container } from "mdbreact";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import { withTheme } from "../../../themes/theming";
import "./../index.css";
import * as AppConst from "../../../AppConstant";
import ReachItStatisticsModal from "./reachItStatisticsModal";
import { downloadMonthlyFile } from "../../../actions/dashboardAction";
var I18n = require("react-redux-i18n").I18n;

class HistoryFileTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      historyFileList: [],
      firstItem: {
        fileId: 0,
        fileName: "File not processed for month January-2023",
        downloadCount: 0,
        totalNmscOrDealerCount: 0,
        totalDealerCount: 0,
        validFile: false,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.monthlyFileData && nextProps.monthlyFileData.length) {
      console.log("nextProps.monthlyFileData",nextProps.monthlyFileData)
      let historyFileList = [];
      nextProps.monthlyFileData.map((obj, i) => {
        if (i !== 0) {
          historyFileList.push(obj);
        }
        return null;
      });
      this.setState({ historyFileList: historyFileList });
    }
  }

  modalOpen = (row) => {
    this.setState({ modal: !this.state.modal, firstItem: row });
  };

  downloadMonthlyFile = (row) => {
    let statusDTO = row.statusDTO
    let fileDto = {
      fileId: row.fileId,
      nmsc: this.props.user.userMdl.nmsc,
      userId: this.props.user.userMdl.username,
      userRole:this.props.user.userMdl.roles.includes(
        AppConst.REACHIT_ROLES.KET_REACHIT_Admin
      )
        ? "KET-ReachIT Admin"
        : this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
          )
        ? "KET-ReachIT NMSC User"
        : "KET-ReachIT Dealer"
    };
    if(typeof statusDTO !== 'undefined' && statusDTO !== null && statusDTO !== undefined){
      if(Object.keys(statusDTO).length){
        this.props.dispatch(downloadMonthlyFile(statusDTO, fileDto));
      }
    }   
  };

  fileNameFormatter = (cell, row) => {
    return (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {row.validFile ? (
          <a
            style={{ textDecoration: "underline", color: "cornflowerblue" }}
            onClick={() => this.downloadMonthlyFile(row)}
          >
            {row.fileName}
          </a>
        ) : (
          row.fileName
        )}
      </div>
    );
  };

  countFormatter = (cell, row) => {
    return row.validFile ? (
      <div
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {row.downloadCount}
        &nbsp; / &nbsp;
        {row.totalNmscOrDealerCount === 0 ? "0":        
          <a
            style={{ textDecoration: "underline", color: "cornflowerblue" }}
            onClick={(e) => this.modalOpen(row)}
          >
            {row.totalNmscOrDealerCount}
          </a>
        }
      </div>
    ) : (
      ""
    );
  };

  render() {
    const columns = [
      {
        dataField: "fileName",
        text: I18n.t("reachItDashboard.historyFiles"),
        style: {
          width: this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
          )
            ? "100%"
            : "50%",
          textAlign: "left",
        },
        headerStyle: {
          width: this.props.user.userMdl.roles.includes(
            AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
          )
            ? "100%"
            : "50%",
          textAlign: "left",
        },
        title: true,
        formatter: this.fileNameFormatter,
      },
      {
        dataField: "totalNmscOrDealerCount",
        text: this.props.user.userMdl.roles.includes(
          AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
        )
          ? I18n.t("Table.dealer") + " " + I18n.t("reachItDashboard.downloads")
          : I18n.t("Table.nmsc") + " " + I18n.t("reachItDashboard.downloads"),
        style: { width: "48%", textAlign: "center" },
        headerStyle: { width: "48%", textAlign: "center" },
        hidden: this.props.user.userMdl.roles.includes(
          AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
        )
          ? true
          : false,
        title: true,
        formatter: this.countFormatter,
      },
    ];

    return (
      <Container
        fluid
        className="ml-0 mb-2 pb-0"
        style={{
          border: "1px solid grey",
          borderRadius: "unset",
          padding: "unset",
        }}
      >
        <div
          className={
            this.props.user.userMdl.roles.includes(
              AppConst.REACHIT_ROLES.KET_REACHIT_Admin
            )
              ? "col-md-12 reachItTable minHtAdmin"
              : this.props.user.userMdl.roles.includes(
                  AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
                )
              ? "col-md-12 reachItTable minHtDealer"
              : "col-md-12 reachItTable"
          }
        >
          <BootstrapTable
            keyField="fileId"
            data={this.state.historyFileList}
            columns={columns}
            hover
            condensed
            noDataIndication={I18n.t("questionnaire.noFiles")}
          />
        </div>
        {this.state.modal === true ? (
          <ReachItStatisticsModal
            modal={this.state.modal}
            toggle={this.modalOpen}
            fileData={this.state.firstItem}
            reachItRoleList={this.props.user.userMdl.roles.includes(
              AppConst.REACHIT_ROLES.KET_REACHIT_Admin
            )
              ? AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
              : AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion}
          />
        ) : null}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    monthlyFileData: state.dashboardReducer.monthlyFileData,
  };
};
export default withRouter(
  connect(mapStateToProps)(withTheme(HistoryFileTable))
);
