import { Fa, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow } from 'mdbreact';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import React from "react";
import { Accordion } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { withRouter } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import { fetchAvailableKPIs, fetchScrollableWidgetData, saveUserKpiPreference, saveDealerPreference } from "../../../actions/dashboardAction";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import Select from 'react-select';
var I18n = require("react-redux-i18n").I18n;
class BusinessResults extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            availableKPIs: [],
            savedKPIs: [],
            openModal: false,
            openNodes: [],
            selectedKpi: '',
            selectedToolbox: '',
            kpiModified: false,
            businessResults: [],
            kpiLimit: 15,
            fromDate: null,
            toDate: null,
            nmscFlag: false,
            countryValue: null,
            openModalKpi: false,
            selectedToInsert: [],
            dealerKpi: [],
            nmscAdminFlag: false
        }
        this.addKpiRef= React.createRef();
        this.removeKpiRef= React.createRef();
    }
    componentWillMount() {
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let userId = this.props.userMdl.username;
        this.props.dispatch(fetchAvailableKPIs(activeProgram, nmscCode, langCode, userId));
        // Fetch Business Results based on Saved Kpi Preferences.
        this.props.dispatch(fetchScrollableWidgetData("businessResults", "SET_BUSINESS_RESULTS", this.getQueryDTO(this.state.fromDate, this.state.toDate)));
        let roles = [];
        roles = this.props.userMdl.roles;
        for (var i = 0; i < roles.length; i++) {
            if (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET NMSC USER" || roles[i].toUpperCase() === "KET NMSC EXTERNAL" || roles[i].toUpperCase() === "KET NMSC FIELD") {
                this.setState({ nmscFlag: true });
                break;
            }
        }
        for (var j = 0; j < roles.length; j++) {
            if (roles[j].toUpperCase() === "KET NMSC ADMIN") {
                this.setState({ nmscAdminFlag: true });
                break;
            }
        }

    }
    componentWillUnmount() {
        this.props.dispatch({
            type: "SET_BUSINESS_RESULTS",
            payload: []
        });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.availableKPIs && nextProps.availableKPIs.availKpiList) {
            // && nextProps.availableKPIs.availKpiList.length !== this.state.availableKPIs.length) {
            this.setState({
                availableKPIs: nextProps.availableKPIs.availKpiList,
            });
            var savedKpi = [];
            if (nextProps.availableKPIs.savedKpiList && nextProps.availableKPIs.savedKpiList.length > 0) {
                for (let i = 0; i < nextProps.availableKPIs.savedKpiList.length; i++) {
                    if (nextProps.availableKPIs.savedKpiList[i].kpiList.length > 0) {
                        savedKpi.push(nextProps.availableKPIs.savedKpiList[i]);
                    }
                }
                if (savedKpi.length > 0) {
                    this.setState({
                        savedKPIs: savedKpi
                    })
                }
            }
            if (nextProps.availableKPIs.savedKpiList && nextProps.availableKPIs.savedKpiList.length === 0) {
                this.setState({
                    savedKPIs: []
                })
            }
        }
        if (nextProps.businessResults && nextProps.businessResults.BUSINESS_RESULT && nextProps.businessResults.BUSINESS_RESULT.length > 0) {
             //console.log("in if nextProps.businessResults");
            this.setState({ businessResults: nextProps.businessResults.BUSINESS_RESULT, isLoading: false });
          }
        else {
            this.setState({ businessResults: [], isLoading: false });
        }
       
        if (this.state.nmscFlag && nextProps.businessResults && nextProps.businessResults.COUNTRY) {
                var countryValue = {
                    value: nextProps.businessResults.COUNTRY, label: nextProps.businessResults.COUNTRY
                }
                this.setState({ countryValue });
        }
                
        if (nextProps.dealerPreferences && nextProps.dealerPreferences.DEALER_KPI  && nextProps.dealerPreferences.DEALER_KPI.length > 0) {
            this.setState({ dealerKpi: nextProps.dealerPreferences.DEALER_KPI });
            let selectedToInsert =[];
            nextProps.dealerPreferences.DEALER_KPI.map((kpi) => {
                if (kpi.isDealerPreference) {
                    var index = selectedToInsert.indexOf(kpi.kpiId);
                    if (index <= -1) {
                        selectedToInsert.push(kpi.kpiId);
                    }
                }
            })
            this.setState({ selectedToInsert });
        }
        else {
            this.setState({ dealerKpi: [] });
        }

        if (nextProps.filters.nmscCode) {
            this.setState({ countryList: nextProps.filters.l2 });
        }


    }
    getQueryDTO = (startDate, endDate) => {
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        let userId = this.props.userMdl.username;
        if (startDate !== null && startDate instanceof Date) {
            let dt = moment(startDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (endDate !== null && endDate instanceof Date) {
            let dt = moment(endDate);
            endDate = dt.format("YYYY-MM-DD");
        }
        return {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            userId: userId,
            fromDate: startDate,
            toDate: endDate,
            countryValue: this.state.countryValue ? this.state.countryValue.value : null,
            businessArea: this.props.userMdl.businessAreaKey
        };
    }
    preventDefault() { }
    toggle = () => {
        this.setState({
            openModal: !this.state.openModal
        });
    }
    toggleDealerKpi = () => {
        this.setState({
            openModalKpi: !this.state.openModalKpi
        });
    }
    addKPI = () => {
        if (this.getKpiCount(this.state.savedKPIs) >= this.state.kpiLimit) {
            toastr.error('Max 15 Kpis allowed for dashboard Calculation.');
            return;
        }
        // console.log("this.state.selectedKpi:", this.state.selectedKpi)
        let toolboxId = this.state.selectedToolbox;
        let kpiId = this.state.selectedKpi.localKpiId ? this.state.selectedKpi.localKpiId : 0;
        let localKpiRefId = this.state.selectedKpi.localKpiRefId;
        let availableKPIs = this.state.availableKPIs;
        let savedKpiList = this.state.savedKPIs;
        // Fetch Node from Available List.
        let selectedToolbox = availableKPIs.filter((t) => t.toolboxId === toolboxId);
        let selectedKPI = selectedToolbox.length > 0 ? selectedToolbox[0].kpiList.filter((k) => (k.localKpiId === kpiId && k.localKpiRefId === localKpiRefId)) : [];
        if (selectedToolbox.length > 0 && selectedKPI.length > 0) {
            // Add KPI in list.
            let kpiAdded = false;
            for (let i = 0; i < savedKpiList.length; i++) {
                if (savedKpiList[i].toolboxId === toolboxId) {
                    savedKpiList[i].kpiList.push(selectedKPI[0]);
                    kpiAdded = true;
                    break;
                }
            }
            // Add Toolbox, if KPI not added.
            if (!kpiAdded) {
                let toolbox = {
                    toolboxId: selectedToolbox[0].toolboxId,
                    toolboxName: selectedToolbox[0].toolboxName,
                    kpiList: selectedKPI
                }
                savedKpiList.push(toolbox);
            }
            // Remove Kpi from available list.
            let filteredKpiList = selectedToolbox[0].kpiList.filter((k) => (k.localKpiId !== selectedKPI[0].localKpiId || k.localKpiRefId !== selectedKPI[0].localKpiRefId));
            selectedToolbox[0].kpiList = filteredKpiList;
            // Remove Toolbox, if no KPI left.
            if (selectedToolbox[0].kpiList.length === 0) {
                availableKPIs = availableKPIs.filter((a) => a.toolboxId !== selectedToolbox[0].toolboxId);
            } else {
                availableKPIs.map((toolbox) => {
                    if (toolbox.toolboxId === selectedToolbox[0].toolboxId) {
                        return selectedToolbox[0];
                    } else {
                        return toolbox;
                    }
                })
            }
            this.setState({ kpiModified: true, savedKPIs: savedKpiList, availableKPIs: availableKPIs, selectedKpi: '', selectedToolbox: '' });
        }
    }
    removeKPI = () => {
        let toolboxId = this.state.selectedToolbox;
        let kpiId = this.state.selectedKpi.localKpiId ? this.state.selectedKpi.localKpiId : 0;
        let localKpiRefId = this.state.selectedKpi.localKpiRefId;
        let availableKPIs = this.state.availableKPIs;
        let savedKpiList = this.state.savedKPIs;
        // Fetch Node from Saved List.
        let selectedToolbox = savedKpiList.filter((t) => t.toolboxId === toolboxId);
        let selectedKPI = selectedToolbox.length > 0 ? selectedToolbox[0].kpiList.filter((k) => (k.localKpiId === kpiId && k.localKpiRefId === localKpiRefId)) : [];
        if (selectedToolbox.length > 0 && selectedKPI.length > 0) {
            // Add KPI in Available list.
            let kpiAdded = false;
            for (let i = 0; i < availableKPIs.length; i++) {
                if (availableKPIs[i].toolboxId === toolboxId) {
                    availableKPIs[i].kpiList.push(selectedKPI[0]);
                    kpiAdded = true;
                    break;
                }
            }
            // Add Toolbox, if KPI not added.
            if (!kpiAdded) {
                let toolbox = {
                    toolboxId: selectedToolbox[0].toolboxId,
                    toolboxName: selectedToolbox[0].toolboxName,
                    kpiList: selectedKPI
                }
                availableKPIs.push(toolbox);
            }
            // Remove Kpi from Saved list.
            let filteredKpiList = selectedToolbox[0].kpiList.filter((k) => (k.localKpiId !== selectedKPI[0].localKpiId || k.localKpiRefId !== selectedKPI[0].localKpiRefId));
            selectedToolbox[0].kpiList = filteredKpiList;
            // Remove Toolbox, if no KPI left.
            if (selectedToolbox[0].kpiList.length === 0) {
                savedKpiList = savedKpiList.filter((a) => a.toolboxId !== selectedToolbox[0].toolboxId);
            } else {
                savedKpiList.map((toolbox) => {
                    if (toolbox.toolboxId === selectedToolbox[0].toolboxId) {
                        return selectedToolbox[0];
                    } else {
                        return toolbox;
                    }
                })
            }
            this.setState({ kpiModified: true, savedKPIs: savedKpiList, availableKPIs: availableKPIs, selectedKpi: '', selectedToolbox: '' });
        }
    }
    createTree = (data) => {
        if (data.length > 0) {
            return (
                <div>
                    <Accordion>
                        <div>
                            {data.map((obj, index) =>
                                <div key={index}>
                                    <Accordion.Toggle className="tree-item tree-item-parent" variant="link" eventKey={obj.toolboxId}>
                                        {obj.toolboxName}
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey={obj.toolboxId}>
                                        <div>
                                            <div className="br-select-table-header">
                                                <MDBCol md="6" lg="6">{I18n.t('Table.kpi')}</MDBCol>
                                                <MDBCol md="3" lg="3">{I18n.t('Table.reference')}</MDBCol>
                                                <MDBCol md="3" lg="3">{I18n.t('Table.mandatory')}</MDBCol>
                                            </div>
                                            {obj.kpiList.map((kpi, i) =>
                                                // <div key={i} className="tree-item tree-item-child" tabIndex={i}
                                                <div key={i} className={kpi.localKpiId===this.state.selectedKpi.localKpiId ? "tree-item tree-item-child selectedKpi" :"tree-item tree-item-child"} tabIndex={i}

                                                    onClick={() => this.setState({
                                                        selectedToolbox: obj.toolboxId,
                                                        selectedKpi: { localKpiId: kpi.localKpiId, localKpiRefId: kpi.localKpiRefId }
                                                    })}>
                                                    <MDBRow>
                                                        <MDBCol md="6" lg="6">{kpi.name}</MDBCol>
                                                        <MDBCol md="3" lg="3" style={{textAlign:"center"}} >{kpi.reference === "LOCAL" ? "LOCAL" : "TME" }</MDBCol>
                                                        <MDBCol md="3" lg="3" style={{textAlign:"center"}} >
                                                            {kpi.mandatory ?
                                                                <img src="/images/active-flag.png" className="mandatory-kpi-icon" alt=""/>
                                                                : <div></div>}
                                                        </MDBCol>
                                                    </MDBRow>
                                                </div>
                                            )}
                                        </div>
                                    </Accordion.Collapse>
                                </div>
                            )}
                        </div>
                    </Accordion>
                </div >
            );
        }
    }
    saveKpiPreference = () => {
        if (this.state.kpiModified && this.state.savedKPIs.length > 0) {
            let dataToSave = [];
            this.state.savedKPIs.map((obj) => {
                obj.kpiList.map((kpi) => {
                    dataToSave = [...dataToSave, {
                        userId: this.props.userMdl.username,
                        toolboxId: obj.toolboxId,
                        kpiId: kpi.localKpiId,
                        localKpiRefId: (kpi.localKpiId===0 ||  kpi.localKpiId===null ) ? kpi.localKpiRefId :null
                       
                    }];
                });            
            });
            if (dataToSave.length > 0) {
                this.setState({ isLoading: true });
                this.props.dispatch(saveUserKpiPreference(dataToSave, this.getQueryDTO(this.state.fromDate, this.state.toDate), this.props.user.userMdl.activeProgram));
                // Close Dialog Box.
                this.toggle();
            }
        } else if (this.state.kpiModified && this.state.savedKPIs.length === 0) {
            let dataToSave = [{
                userId: this.props.userMdl.username,
            }];
            this.setState({ isLoading: true });
            this.props.dispatch(saveUserKpiPreference(dataToSave, this.getQueryDTO(this.state.fromDate, this.state.toDate), this.props.user.userMdl.activeProgram));
            // Close Dialog Box.
            this.toggle();
        }
    }

    saveDealerKpi = () => {
        if (this.state.selectedToInsert.length > 5) {
            toastr.error('Max 5 Kpis allowed for dealer Dashboard.');
        }
        else {
            this.props.dispatch(saveDealerPreference(this.state.selectedToInsert, this.props.user.userMdl.activeProgram, this.props.userMdl.username, this.getQueryDTO(this.state.fromDate, this.state.toDate)))
            this.toggleDealerKpi();
        }
    }
    getPercentageRow = (percentage) => {
        let textclass = "";
        let value = percentage;
        if (percentage < 0) {
            textclass = 'red-font';
            value = "-  " + Math.abs(Math.round(percentage)) + ' %';
        } else if (percentage >= 0) {
            textclass = "green-font";
            value = "+  " + Math.round(percentage) + ' %';
        } else if (percentage === '-') {
            textclass = "no-font";
            value = percentage;
        }
        return <MDBCol lg="3" md="3"  style={{textAlign:"right", marginTop:"5px"}} className={textclass}>{value}</MDBCol>;
    }
    getKpiCount = (data) => {
        if (data.length > 0) {
            let count = 0;
            data.map(t => {
                t.kpiList.map(k => {
                    count = count + 1;
                })
            })
            return count;
        }
        return 0;
    }
    applyDateFilter = () => {
        let startDate = '';
        let endDate = '';
        if (this.state.fromDate !== null) {
            let dt = moment(this.state.fromDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (this.state.toDate !== null) {
            let dt = moment(this.state.toDate);
            endDate = dt.format("YYYY-MM-DD");
        }
        // Reload, if both dates are empty or both are not empty.
        if ((startDate !== '' && endDate !== '') || (startDate === '' && endDate === '')) {
            // End date should be greater than Start Date.
            if (startDate !== '' && endDate !== '' && this.state.fromDate.getTime() >= this.state.toDate.getTime()) {
                toastr.error("End date should be greater than Start date.")
            } else if ((startDate !== '' && this.state.fromDate.getTime() > new Date()) || (endDate !== '' && this.state.toDate.getTime() > new Date())) {
                toastr.error("Start Date or End Date should not be greater than Today's Date")
            }
            else {
                this.setState({ isLoading: true });
                this.props.dispatch(fetchScrollableWidgetData("businessResults", "SET_BUSINESS_RESULTS", this.getQueryDTO(startDate, endDate)));

            }
        }
    }

    handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    fetchDealerPreferncesKpi = (e) => {
        this.props.dispatch(fetchScrollableWidgetData("dealerPreferencesKpi", "SET_DEALER_PREFERENCES", this.getQueryDTO(this.state.fromDate, this.state.toDate)));
        this.setState({ openModalKpi: true });
    }

    onCountryChange = (e) => {
        this.setState({ countryValue: e });
    }

    render() {
        
        const columns = [{
            dataField: 'toolboxName',
            text: I18n.t('tmeAdminMaster.ToolboxName'),
            title: true,
            style: { width: "30%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "0"},
            headerStyle: { width: "30%", textAlign: "left" }
        }, {
            dataField: 'kpiName',
            text: I18n.t('Table.kpi'),
            style: { width: "60%", textAlign: "left" },
            headerStyle: { width: "60%", textAlign: "left" }
        }];
        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox" || e.target.cellIndex === 0) {
                    e.target.checked = true;
                    var index = this.state.selectedToInsert.indexOf(row.kpiId);
                    if (index > -1) {
                        this.state.selectedToInsert.splice(index, 1);

                    } else {
                        this.state.selectedToInsert.push(row.kpiId);

                    }

                    this.setState({ selectedToInsert: this.state.selectedToInsert });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            selected: this.state.selectedToInsert,
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                rows.forEach((row) => {
                    selectedRows.push(row.kpiId);

                });
                if (isSelect && selectedRows.length > 0) {
                    this.setState({ selectedToInsert: selectedRows });
                    return selectedRows;
                }
                else {
                    this.setState({ selectedToInsert: [] });
                }
            }
        };

        return (
            <div className="businessResult" style={{ width: "100%" }}>
                <div className={"large-widget-header left-align-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.bussResult')}>
                   {I18n.t('dashboard.bussResult')}
                    {this.state.nmscFlag ? <label style={{ float:"right",marginRight:"0.5rem", marginBottom: '0px' }}>{this.state.countryValue ? this.state.countryValue.label : ""}</label> : ""}
                    {this.state.nmscAdminFlag ? <Fa icon="cog" className="widget-filter-icon" onClick={() => this.fetchDealerPreferncesKpi()} /> : ""}
                    <MDBDropdown className="widget-filter-icon-container">
                        <MDBDropdownToggle tag="div" style={{ display: 'inline' }}>
                            <Fa icon="filter" className="widget-filter-icon" />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu tag="div" className={this.state.nmscFlag ? "widget-filter-dd-menu" : "widget-filter-dd-menu-TME"}>
                            <MDBDropdownItem toggle={false} tag="div">
                                {this.state.nmscFlag ?
                                    <MDBRow>
                                        <MDBCol md="5" lg="5" className="widget-filter-dd-header">{I18n.t('Table.country') + ":"}</MDBCol>
                                        <MDBCol md="7" lg="7" className="pl-2">
                                            <Select
                                            
                                                placeholder= {I18n.t('others.select')}
                                                className="statusDD"
                                                value={this.state.countryValue}
                                                //  style={{ paddingLeft: '0.5rem' }}
                                                options={this.state.countryList}
                                                isClearable={false}
                                                onChange={this.onCountryChange}
                                                //selected={{label: 'Open', value: 'Open' }}
                                                //isDisabled={this.state.disabledStatus}
                                                id="actionStatusId"

                                            />
                                        </MDBCol>
                                    </MDBRow> : ""}
                                <MDBRow><div style={{ height: '5px' }}></div></MDBRow>
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.startDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                        <DatePicker className="widget-filter-date-picker image"
                                            onChange={(e) => this.setState({ fromDate: e })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable={true}
                                            selected={this.state.fromDate}
                                            id="fromDate"
                                            name="fromDate"
                                            onChangeRaw={this.handleDateChangeRaw}
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <div className="marginTopDashboad"></div>
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.endDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                        <DatePicker className="widget-filter-date-picker image"
                                            onChange={(e) => this.setState({ toDate: e })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable={true}
                                            selected={this.state.toDate}
                                            id="toDate"
                                            name="toDate"
                                            onChangeRaw={this.handleDateChangeRaw}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                            <MDBDropdownItem toggle={true} tag="div">
                                <MDBRow style={{paddingLeft:"110px"}}>
                                    <MDBCol>
                                        <div>
                                            <button className={"filter-button filter-button-" + this.props.theme.name}
                                                onClick={() => this.applyDateFilter()}>
                                                {I18n.t('dashboard.go')}
                                            </button>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                    <Fa icon="cogs" className="widget-filter-icon" onClick={() => { this.setState({ openModal: true }) }} />
                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="medium-widget-content">
                        {this.state.businessResults.length > 0 ?
                            this.state.businessResults.map((row, index) => {
                                return (this.state.nmscFlag ?
                                    <MDBRow key={index} className="br-table-row pl-1 report-bottom-border" style={{height:"25px"}}>
                                        <MDBCol lg="6" md="6" title={row.toolboxName + "-" + row.kpiName} style={{marginTop:"3px"}} className="text-ellipsis">{row.kpiName}</MDBCol>
                                        <MDBCol lg="3" md="3" className="text-alignment" style={(row.unit && row.unit !== "") ? { paddingLeft: "1.5rem",marginTop:"3px"} : { paddingLeft: "1.5rem",marginTop:"3px"}}>{(row.unit && row.unit !== "") ? row.unit : "-"}</MDBCol>
                                        {this.getPercentageRow(row.percentage)}
                                    </MDBRow> : <MDBRow key={index} className="br-table-row pl-1 report-bottom-border" style={{height:"25px"}}>
                                        <MDBCol lg="9" md="9" title={row.toolboxName + "-" + row.kpiName} style={{marginTop:"3px"}} className="text-ellipsis">{row.kpiName}</MDBCol>
                                        {this.getPercentageRow(row.percentage)}
                                    </MDBRow>)
                            })
                            : <MDBRow className="pl-4">{I18n.t('evaluation.noRecords')}</MDBRow>}
                    </div>
                </LoadingOverlay>
                <Modal id="businessResultsKPIModal"
                    isOpen={this.state.openModal} centered
                    toggle={() => this.preventDefault()}
                    className={"kpi-modal-class addActionActivityModal-" + this.props.theme.name}>
                    <ModalHeader toggle={this.toggle}><b style={{ fontWeight: 'Bold' , fontSize:'15px' }}>{I18n.t('dashboard.kpiList')}</b></ModalHeader>
                    <ModalBody>
                        <div>
                            <MDBRow>
                                <div className="kpi-tree">
                                    <div className={"pick-list-header-" + this.props.theme.name}>{I18n.t('dashboard.availableKpi')} ({this.getKpiCount(this.state.availableKPIs)})</div>
                                    {this.createTree(this.state.availableKPIs)}
                                </div>
                                <div style={{ width: '10%' }}>
                                    <div ref={this.addKpiRef} className={"trigger-button trigger-button-" + this.props.theme.name}
                                        style={{ marginTop: '90%', marginBottom: '10%' }}
                                        onClick={() => this.addKPI()}>&gt;</div>
                                    <div ref={this.removeKpiRef} className={"trigger-button trigger-button-" + this.props.theme.name}
                                        onClick={() => this.removeKPI()}>&lt;</div>
                                </div>
                                <div className="kpi-tree">
                                    <div className={"pick-list-header-" + this.props.theme.name}>{I18n.t('dashboard.selectedKpi')} ({this.getKpiCount(this.state.savedKPIs)})</div>
                                    {this.createTree(this.state.savedKPIs)}
                                </div>
                            </MDBRow>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => this.toggle()} >
                                {I18n.t("tmeAdminMaster.cancel")}
                            </button>
                            <button className={"button-" + this.props.theme.name} onClick={() => this.saveKpiPreference()} >
                                {I18n.t("tmeAdminMaster.save")}
                            </button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal id="dealerKpi"
                    isOpen={this.state.openModalKpi} centered
                    toggle={() => this.preventDefault()}
                    className={"kpi-modal-class addActionActivityModal-" + this.props.theme.name}>
                     <ModalHeader toggle={this.toggleDealerKpi}><b style={{ fontWeight: 'Bold',fontSize:'15px' }}>{I18n.t('dashboard.dealerBestPractice')}</b></ModalHeader>
                    <ModalBody>
                        <div>
                            <BootstrapTable
                                keyField='kpiId'
                                data={this.state.dealerKpi}
                                columns={columns}
                                selectRow={selectRow}
                                hover
                                condensed
                                rowEvents={rowEvents}
                                noDataIndication={I18n.t('evaluation.noRecords')}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => this.toggleDealerKpi()} >
                                {I18n.t("tmeAdminMaster.cancel")}
                            </button>
                          
                                < button className={"button-" + this.props.theme.name} onClick={() => this.saveDealerKpi()} >
                                    {I18n.t("tmeAdminMaster.save")}
                                </button> :
                               
                        </div>
                    </ModalFooter>
                </Modal>
            </div >
        ); 
    }
}
const mapStateToProps = state => {
    return {
        availableKPIs: state.dashboardReducer.availableKPIs,
        businessResults: state.dashboardReducer.businessResults,
        dealerPreferences:state.dashboardReducer.dealerPreferences,
        user: state.loginReducer,
        filters: state.dashboardReducer.filters,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(BusinessResults)));
