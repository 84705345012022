import React from "react";
import BootstrapTable2 from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import "./index.css";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { saveActivityMonthTarget ,saveVisibilityOnUpdate} from "../../actions/activityAction";
import moment from "moment";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
var I18n = require("react-redux-i18n").I18n;

class ActionKpiModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activityKpis: this.props.activityKpis,
      monthlyTarget: [],
      month: "",
      year: "",
      valid: true,
      kpiUpdated:" ",

    };
  }
  componentWillMount() {
    var addMonths =
      this.state.activityKpis[0] && this.state.activityKpis[0].monthlyTarget
        ? this.state.activityKpis[0].monthlyTarget.length
        : 0;
    var dateParts = this.props.activity.firstVisit.split("/");
    var firstVisit = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
    firstVisit = moment(firstVisit); // moment(...) can also be used to parse dates in string format
    firstVisit.add(addMonths, "months");
    firstVisit = moment(firstVisit).format("MM/DD/YYYY");
    firstVisit = new Date(firstVisit);
    this.setState({ month: firstVisit.getMonth() });
    this.setState({ monthName: this.getMonthNameFromDate(firstVisit) });
    this.setState({ year: firstVisit.getFullYear() });
  }

  getMonthNameFromDate = dt => {
    var mlist = [
      I18n.t("months.jan"),
      I18n.t("months.feb"),
      I18n.t("months.mar"),
      I18n.t("months.apr"),
      I18n.t("months.may"),
      I18n.t("months.jun"),
      I18n.t("months.jul"),
      I18n.t("months.aug"),
      I18n.t("months.sep"),
      I18n.t("months.oct"),
      I18n.t("months.nov"),
      I18n.t("months.dec")
    ];
    return mlist[dt.getMonth()];
  };

  preventDefault() {}

  save = () => {
    var monthlyTarget = [];
    for (var i = 0; i < this.state.activityKpis.length; i++) {
      if (
        this.state.activityKpis[i].monthTarget === null ||
        this.state.activityKpis[i].monthTarget === ""
      ) {
        toastr.error(I18n.t("activity.kpi.mandatoryTargets"));
        return;
      }
    }
    this.state.activityKpis.map(activityKpi => {
      let newRow = {
        dealerActivityId: activityKpi.dealerActivityId,
        monthTarget: activityKpi.monthTarget,
        month: this.state.month,
        year: this.state.year,
        unit: activityKpi.unit
      };

      monthlyTarget.push(newRow);
      return null;
    });
    this.setState({ monthlyTarget });

    let data = {
      userId: this.props.user.userMdl.username,
      kpiDTOList: monthlyTarget
    };

    this.props.toggle();
    this.props.dispatch(
      saveActivityMonthTarget(
        data,
        this.props.activity.activityId,
        this.props.user.userMdl.lang,
        this.props.user.userMdl.nmsc
      )
    );
 //check Dealer Champion Role
 var isDealerUser = false;
 if (this.props.user.userMdl.roles !== undefined) {
   var roles = this.props.user.userMdl.roles;
   for (var j = 0; j < roles.length; j++) {
     if (roles[j].toUpperCase() ==="KET DEALER CHAMPION" || roles[j].toUpperCase() === "KET DEALER INTERNAL" || roles[j].toUpperCase() === "KET NMSC External" || this.props.user.userMdl.roles[j] === "KET NMSC User" || this.props.user.userMdl.roles[j] === "KET NMSC Field") {
         isDealerUser = true;
       break;
     }
   }
 }
 console.log(isDealerUser);
 if(isDealerUser===true){
     var viewStatusDTO = {
         activityId:  this.props.activity.activityId,
         loginId: this.props.user.userMdl.username,
         kpiUpdated :"True",
      
     }
     this.props.dispatch(saveVisibilityOnUpdate(viewStatusDTO));
 }



  };

  
  inputFormatter = (cell, row, rowIndex) => {
    return (
        <input
            type="text"
            defaultValue={(cell && cell !== undefined) ? cell : ""}
            onChange={e => this.onChangeTextBox(e, row, cell)}
            className="form-control"
            onKeyDown={e => this.autoTab(e, row, cell)}
            onBlur={e => this.onTabChange(e, row, cell)}
            disabled={false}
        />
    );
  };

onChangeTextBox = (e, row, cell) => {
}

autoTab = (e, row, cell) => {
    if (e.keyCode === 9) {
      this.onTabChange(e, row, cell)
    }
};  

onTabChange = (e, row, cell) => {
  console.log("actionKpiModal onTabChange start");
    let val = e.target.value ? e.target.value.trim().replace(' ' + row.unit, '') : e.target.value;
    var Number = /^[\d\/\(\)\- \.]+$/; 	// eslint-disable-line
    console.log("val",val)
    if (Number.test(val) || val === "") {
        e.target.value = val
        if (val.length > 10) {
            toastr.error(I18n.t('activity.kpi.targetLength'));
            e.target.value = row.monthTarget
        }
        row.monthTarget = val    
        this.state.activityKpis.map(obj=>{
            if(obj.kpiId === row.kpiId){
                obj.monthTarget = val
            }
            return null;
        })
    } else {
        toastr.error(I18n.t('activity.kpi.target'));
        e.target.value = row.monthTarget
    }
    console.log("actionKpiModal onTabChange end");
}; 

  render() {
    let roles = [];
    // let isAdminUser = false;
    if (this.props.user.userMdl.roles !== undefined) {
      roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase().indexOf("ADMIN") !== -1) {
          // isAdminUser = true;
          break;
        }
      }
    }
    let editAccess = false;
    if (
      this.props.user.userMdl.accessControl !== undefined &&
      this.props.user.userMdl.accessControl.length > 0
    ) {
      let activityAccess = this.props.user.userMdl.accessControl.filter(
        access => access.function === "ACTIVITY"
      );
      if (Object.assign({}, activityAccess)[0].accessType === "R/W") {
        editAccess = true;
      }
    }
    const columns = [
      {
        dataField: "name",
        text: I18n.t("Table.kpi"),
        sort: true,
        style: { width: "20%", textAlign: "left", wordWrap: "break-word" },
        headerStyle: { width: "19.7%", textAlign: "left" },
        editable: false
      },
      {
        dataField: "definition",
        text: I18n.t("Table.definition"),
        sort: true,
        style: { width: "28%", textAlign: "left", wordWrap: "break-word" },
        headerStyle: {
          width: "27.3%",
          textAlign: "left",
          wordWrap: "break-word"
        },
        editable: false
      },
      {
        dataField: "benchmark",
        text: I18n.t("Table.benchmark"),
        sort: true,
        style: { width: "12%", textAlign: "center" },
        headerStyle: { width: "11.7%", textAlign: "center" },
        editable: false
      },
      {
        dataField: "target",
        text: I18n.t("Table.target"),
        sort: true,
        style: { width: "12%", textAlign: "center" },
        editable: false,
        headerStyle: { width: "11.7%", textAlign: "center" }
      },
      {
        dataField: "initial",
        text: I18n.t("Table.initial"),
        // cellEdit: true,
        style: { width: "12%", textAlign: "center" },
        headerStyle: { width: "11.7%", textAlign: "center" },
        editable: false
      },
      {
        dataField: "monthTarget",
        text: this.state.monthName + " " + this.state.year,
        sort: true,
        formatter: this.inputFormatter,
        style: { width: "14%", textAlign: "center" },
        headerStyle: { width: "13.7%", textAlign: "center" },
        editable: true,
      }
    ];

    return ( 
        <Modal
          className={
            "targetClass addActionActivityModal-" + this.props.theme.name
          }
          id="addActionActivityModal"
          isOpen={true}
          toggle={() => this.preventDefault()}
        >
          <ModalHeader toggle={this.props.toggle}>
            {this.props.modalFor} {I18n.t("Table.kpiResult")}
          </ModalHeader>
          <ModalBody>
            <div className="actionKpiContainer">
              <div className="row">
                <div className="actionPlanTableModal">
                  <BootstrapTable2
                    classes="activityKpiModal"
                    keyField="kpiId"
                    noDataIndication={"No Action found"}
                    data={this.state.activityKpis}
                    columns={columns}
                    cellEdit={cellEditFactory({
                      beforeSaveCell(oldValue, newValue, row, column, done) {                                           
                          return { async: true };
                      }
                  })}
                  />
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            {editAccess ? (
              <div>
                <button
                  className={"buttonCancel-" + this.props.theme.name}
                  onClick={this.props.toggle}
                >
                  {I18n.t("tmeAdminMaster.cancel")}
                </button>
                <button
                  className={"button-" + this.props.theme.name}
                  onClick={this.save}
                >
                  {I18n.t("tmeAdminMaster.save")}
                </button>
              </div>
            ) : (
              <div> </div>
            )}
          </ModalFooter>
        </Modal>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(ActionKpiModal)));
