import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import { connect } from "react-redux";
import { fetchTmeCertToolBooxDetails } from "../../../actions/certificationAction";
import { withRouter } from "react-router-dom";
import { rejectCertification,approveCertification} from "../../../actions/certificationAction";
import SubmitComments from './submitComments';
import { push } from "connected-react-router";
var I18n = require("react-redux-i18n").I18n;

// import { Fa } from 'mdbreact';
class ToolBoxDetailsForTME extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tmeToolBox: [],
            toggleComments: false,
            activityIds: [],
            submittedIds : [],
            userId : this.props.user.userMdl.username,
            submittedActivityIds : [],
            roles : false,
            // toggleReject:false
        }
    }

    componentWillMount() {
        this.props.dispatch(fetchTmeCertToolBooxDetails(this.getQueryDTO()));
        this.props.user.userMdl.roles.forEach(element => {
            if(element === "KET TME Admin"){
                this.setState({
                    roles : true
                })
            }
        });
    }

  
    componentWillReceiveProps(nextProps) {
        if (nextProps.tmeToolBox && nextProps.tmeToolBox.length > 0) {
            this.setState({ tmeToolBox: nextProps.tmeToolBox });
            this.setState({ activityIds: nextProps.tmeToolBox[0].ids, button: false })
            if (nextProps.tmeToolBox[0].submittedIds.length > 0) {
                this.setState({submittedIds : nextProps.tmeToolBox[0].submittedIds})
            }
        }
    }

    getQueryDTO = () => {
        let program = []
        if(this.props.selectedProgram && this.props.selectedProgram.length>0){
            this.props.selectedProgram.forEach(element=>{
                program.push(element.value) 
            })
        }
        
        return { 
            networkLevelDealerId: this.props.selectedDealerId,
            programList : program
        };
    }

    rowData() {

    }

    dispatchActivity = (row) => {
        var searchCriteria = {
            langCode: this.props.user.userMdl.lang,
            loggedInUser: this.props.user.userMdl.username,
            nmscCode: this.props.user.userMdl.nmsc,
            type: "TME",
        }
        this.props.dispatch(
            push({
                pathname: "/actionPlan",
                query: { selectedActivity: JSON.stringify(row), searchCriteria: searchCriteria }
            })
        );
    }
    // submitCertification = () => {
    //     this.props.dispatch(submitCertification(this.state.selected, this.props.selectedDealerId, this.props.user.userMdl.username,this.getTableQueryDTO()));
    //     this.props.toggelPopUp();
    // }

    getTableQueryDTO = () => {
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        let userId = this.props.user.userMdl.username;
        //let activeProgram = this.props.user.userMdl.activeProgram;
    
        return {
          userNmscCode: this.props.certificationReducer.CertiFlag ? null : nmscCode,
          userLangCode: this.props.certificationReducer.CertiFlag ? null : langCode,
          nmscCode: this.props.nmscFilter,
          brand: this.props.brandFilter,
          country: this.props.countryFilter,
          userId: userId,
          numberOfCompletedToolBox: null,
          dealerId:this.props.certiFilters.dealerList !== undefined && this.props.certiFilters.dealerList[0] ? this.props.certiFilters.dealerList[0] : this.props.selectedDealerId,
          programList: this.props.certiFilters.programList,
          dealerList:this.props.certiFilters.dealerList,
          statusList: this.props.certiFilters.statusList,
          nmscCodeList:this.props.certiFilters.nmscCodeList,
          brandList:this.props.certiFilters.brandList,
          countryList:this.props.certiFilters.countryList,
          noOfCompletedToolBox:this.props.certiFilters.noOfCompletedToolBox
        };
      }

    toolBoxFormatter = (cell, row) => {
        return (
            <div className="queslink" style={{ textDecoration: 'underline'}}>
                <a onClick={() => this.dispatchActivity(row)}> {row.toolBoxNameEn}</a>
            </div>
        );
    }

    toggleComments = (param) => {
        if(param === "approve"){
            this.props.dispatch(approveCertification(this.state.activityIds,this.props.selectedDealerId,this.state.userId,this.getTableQueryDTO()));
        }
        // if(param === "reject"){
        //     this.props.dispatch(rejectCertification(this.state.submittedIds,this.props.selectedDealerId,this.state.userId,this.getTableQueryDTO()));
        // }
        this.setState({ toggleComments: !this.state.toggleComments });
    }
    toggleReject=(param)=>{
        if(param === "reject"){
            this.props.dispatch(rejectCertification(this.state.submittedIds,this.props.selectedDealerId,this.state.userId,this.getTableQueryDTO()));
            this.setState({ toggleReject: !this.state.toggleReject });
            console.log("----this.state.toggleReject",this.state.toggleReject)
        }   
    }


    preventDefault() { }
    render() {
        const columns = [{
            dataField: 'toolBoxNameEn',
            text: I18n.t('tmeAdminMaster.ToolboxName'),
            title: true,
            formatter: this.toolBoxFormatter,
            style: { width: "16.9%", textAlign: "left",textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", maxWidth: "0" },
            headerStyle: { width: "14.5%", textAlign: "left" }
        },
           {
            dataField: 'reference',
            text: I18n.t('Table.reference'),
            style: { width: "8.9%", textAlign: "left",paddingLeft:"45px" },
            headerStyle: { width: "11%", textAlign: "left",paddingLeft:"26px" }
        }, 
           {
            dataField: 'program',
            text: I18n.t('tmeAdminMaster.programName'),
            style: { width: "15%", textAlign: "left",paddingLeft:"73px" },
            headerStyle: { width: "15%", textAlign: "left",paddingLeft:"22px" }
        },
           {
         dataField:'submittedBy',
         text:I18n.t('Table.submittedBy'),
         style:{width:"22.1%",textAlign:"left",paddingLeft:"76px"},
         headerStyle:{width:"14%",textAlign: "left",paddingLeft:"14px"},
        },
           {
            dataField: 'firstVisitDate',
            text: I18n.t('Table.startDate'),
            style: { width: "11%",textAlign: "center",paddingRight:"54px"},
            headerStyle: { width: "9%",textAlign:"right",paddingRight:"28px" }
        }, 
           {
            dataField: 'completionDate',
            text: I18n.t('Table.completionDate'),
            style: { width: "10%",textAlign: "center",paddingRight:"30px" },
            headerStyle: { width: "12%",textAlign: "right",paddingRight:"18px" }
        }, 
           {
            dataField: 'certificationDateStr',
            text: I18n.t('Table.certificationDate'),
            style: { width: "18%",textAlign: "center",paddingRight: "45px" },
            headerStyle: { width: "10%",textAlign: "right" }
        }, 
           {
            dataField: 'certificationStatusStr',
            text: I18n.t('Table.certification'),
            style: {width: "14%",textAlign: "center",paddingRight: "45px"},
            headerStyle: { width: "14%",textAlign: "right",overflow:"hidden" }
        }];
            
        return (
            <div className="certificationDetailsMain">
                <Modal centered
                    className={"certificationDetailsModal certificationDetailsModal-" + this.props.theme.name}
                    isOpen={true} style={{ maxWidth: '50%!important' }}
                    toggle={() => this.preventDefault()} >
                    <ModalHeader toggle={() => this.props.toggel()}>
                    {I18n.t('dealerCertification.toolboxDetails')}
                    </ModalHeader>
                    <ModalBody>
                        <BootstrapTable
                            keyField='activityId'
                            data={this.state.tmeToolBox}
                            columns={columns}
                            noDataIndication={I18n.t('evaluation.noRecords')}
                            // selectRow={selectRow}
                            hover
                            condensed
                            // rowEvents={rowEvents}
                        />

                    </ModalBody>
                    <ModalFooter>
                    {this.state.tmeToolBox.length >0 && this.state.tmeToolBox[0].submittedIds.length > 0 && this.state.roles  ?
                     <button
                            className={"button-" + this.props.theme.name}
                            onClick={() => this.toggleReject("reject")}
                        >
                            {I18n.t('dealerCertification.reject')}
                        </button> :
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => this.toggleReject("reject")}
                                disabled
                            >
                                {I18n.t('dealerCertification.reject')}
                        </button>
                    }
                
                        {this.state.tmeToolBox.length >0 && this.state.tmeToolBox[0].submittedIds.length > 0 && this.state.roles ?
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => this.toggleComments("approve")}>
                                {I18n.t('dealerCertification.approve')}
                            </button> :
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => this.toggleComments("approve")}
                                disabled>
                                {I18n.t('dealerCertification.approve')}
                        </button>
                        }
                        {this.state.toggleComments||this.props.ongoingActivityFlag ? <SubmitComments toggleComments={this.toggleComments} activityIds={this.state.submittedIds} toggel={this.props.toggel}/> : ""}
                    </ModalFooter>
                </Modal >
            </div>
        );
    }
}

const mapStateToProps = state => {
    // console.log("----dsgjsdgfgsdhsldj",state)
    return {
        tmeToolBox: state.certificationReducer.tmeToolBox,
        user: state.loginReducer,
        filters: state.dashboardReducer.filters,
        certificationReducer: state.certificationReducer,
        certiFilters: state.certificationReducer.certiFilters,
        ongoingActivityFlag:state.certificationReducer.ongoingActivity
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ToolBoxDetailsForTME)));
