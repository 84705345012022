import axios from "axios";
import { setBlockingState } from "./loginAction.jsx";
import { fetchSubQuestions, fetchNmscSubQuestions } from "./tabAction.jsx";
import { toastr } from "react-redux-toastr";
import { getStatCount } from "./mainQuestionAction";
import { getNmscQuestionSummary, getQuestionSummary } from "./questionSummaryAction";
var I18n = require("react-redux-i18n").I18n;

export function saveSubQuestion(subQuestionnaireDTO, questionId) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    let url = "/question/save/subQuestionnaire";
    axios
      .post(url, subQuestionnaireDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch(fetchSubQuestions(questionId));
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function deleteSubQuestion(id, userId, questionId) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    let url = "/question/subQuestionnaire/" + id + "/" + userId;
    axios
      .delete(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch(fetchSubQuestions(questionId));
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}


export function publishNmscSubQuestion(userId, packageProgramMappingId, nmscLang,nmscCode,questionId,chapterId,subQuestionList) {
  return function (dispatch) {
  if(nmscLang===""){
    toastr.error(I18n.t("standardMessages.selectNMSCTab"));
    return;
  }

    //dispatch(setBlockingState(true));
    let url = "/question/publish/" + userId + "/" + packageProgramMappingId +"/" +nmscLang+ "/" +nmscCode;
    axios
      .post(url,subQuestionList, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
         //toastr.success(I18n.t("ServerMessage." + response.data.desc));
         dispatch(getNmscQuestionSummary(packageProgramMappingId,nmscCode, nmscLang,chapterId,questionId,false));
         dispatch(fetchNmscSubQuestions(questionId));
         dispatch({
          type: "SUB_Q_PUBLISHED",
          payload: "SUB_Q_PUBLISHED"
        });
        
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function publishSubQuestion(userId, packageProgramMappingId, questionId, chapterId) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    let url = "/question/publish/" + userId + "/" + packageProgramMappingId;
    axios
      .put(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        toastr.success(I18n.t("ServerMessage." + response.data.desc));
        // Get Chapter List.
        dispatch(getQuestionSummary(packageProgramMappingId, chapterId, questionId, false));
        dispatch(fetchSubQuestions(questionId));
        
        dispatch({
          type: "SUB_Q_PUBLISHED",
          payload: "SUB_Q_PUBLISHED"
        });
      })
      .catch(function (error) {
        dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function getNmscSavedSubQuestions(packageProgramMappingId,nmscCode,nmscLang) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/question/getNmscSavedSubQuestions/" + packageProgramMappingId + "/" + nmscLang + "/" + nmscCode, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));

        dispatch({
          type: "GET_SAVED_SUB_QUESTIONS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}
export function fetchPublishedSubQuestions(packageProgramMappingId, nmscLang,nmscCode) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    axios
      .get("/question/publishedUpdatedSubQuestions/" + packageProgramMappingId + "/" + nmscCode + "/" + nmscLang, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));

        dispatch({
          type: "GET_PUBLISHED_SUB_QUESTIONS",
          payload: response.data
        });
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        console.error(error);
      });
  };
}

export function importAllSubQuestions(packageProgramMappingId, lang, nmsc, importSubQuestionnaireDTO) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/importTmeSubQuestionnaire/" + packageProgramMappingId + "/" + nmsc;
    axios
      .post(url, importSubQuestionnaireDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        dispatch(getStatCount(packageProgramMappingId,nmsc));
        dispatch(getNmscQuestionSummary(packageProgramMappingId,nmsc, lang,null,null,true))
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function saveNMSCSubQuestion(subQuestionnaireDTO, questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/nmscSubQuestionnaire";
    axios
      .post(url, subQuestionnaireDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch(fetchNmscSubQuestions(questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}

export function saveLocalSubQuestionnaire(subQuestionnaireDTO, questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/save/nmscLocalSubQuestionnaire";
    axios
      .post(url, subQuestionnaireDTO, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch(fetchNmscSubQuestions(questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}
export function deleteNmscSubQuestion(nmscSubQuestionId, userId, questionId) {
  return function (dispatch) {
    //dispatch(setBlockingState(true));
    let url = "/question/nmscSubQuestionnaire/" + nmscSubQuestionId + "/" + userId;
    axios
      .delete(url, {
        headers: {
          "Content-type": "application/json",
          Accept: "application/json",
          Pragma: "no-cache"
        }
      })
      .then(response => {
        //dispatch(setBlockingState(false));
        // toastr.success(I18n.t("ServerMessage." + response.data.desc));
        dispatch(fetchNmscSubQuestions(questionId));
      })
      .catch(function (error) {
        //dispatch(setBlockingState(false));
        toastr.error("" + error);
      });
  };
}