import React from "react";
import { connect } from "react-redux";
import { withTheme } from "../../themes/theming";
import { Row, Col } from "mdbreact";
import { withRouter } from "react-router-dom";
import "./index.css";
import { Carousel } from "react-responsive-carousel";
import styles from "react-responsive-carousel/lib/styles/carousel.min.css"; // eslint-disable-line
import { fetchToolboxByType, fetchNmscToolboxByType, fetchNmscToolboxByLang, fetchUsersByRole } from "../../actions/leftNavAction.jsx";
import AddEditToolbox from './addEditToolbox.jsx';
import NmscAddEditToolbox from './nmscAddEditToolbox.jsx';
import AddEditBusiFunc from './addEditBusiFunc.jsx';
import * as AppConst from '../../AppConstant';
const $ = require("jquery");
window.jQuery = $;
var I18n = require("react-redux-i18n").I18n;
var baseUrl = "/ket/v1/questionnaire/download/";
//var baseUrl="http://localhost:9090/ket/v1/questionnaire/download/";
class Toolbox extends React.Component {
  /* eslint-disable */
  constructor(props) {
    super(props);
    this.state = {
      leftNavNamesList: [],
      foundation: "",
      fixItRight: "",
      efficiency: "",
      dpok: "",
      accessories: "",
      walkAround: "",
      maintenanceReminder: "",
      toolBoxList: [],
      theme: 'toyota',
      modal: false,
      showDelete: false,
      showEdit: true,
      modalFor: "",
      isNmsc: false,
      toolList: [],
      toolboxUrl: "",
      allowDownload: false,
      selectedToolbox: "",
      selectedToolboxKey: 0,
      selectedSlide: 0,
      nmscToolIdList: [],
      isCollapsed: false,
      nmscDropdownToolList: [],
      ceaBusinessOwnerList: [],
      selectedPackageMapPrimaryKey: 0
    };
    this.setToolboxUrl = this.setToolboxUrl.bind(this);
    this.getToolboxUrl = this.getToolboxUrl.bind(this);
    this.makeToolboxNav = this.makeToolboxNav.bind(this);
  }
  componentWillMount() {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA NMSC ADMIN") {
          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN"
          || roles[i].toUpperCase() === "TSM KET USER" || roles[i].toUpperCase() === "KET TME USER" || roles[i].toUpperCase() === "KET-BRIT TME USER" || roles[i].toUpperCase() === "KET-EA ADMIN") {

          isTMEAdminUser = true;
          break;
        }
        isNotAdmin = true;

      }
    }
    if (!isTMEAdminUser && (this.props.user.userMdl.activeProgramID <= 4 || this.props.user.userMdl.activeProgramID === 23 || this.props.user.userMdl.activeProgram === "TSM_SURVEYS")) {
      // nmscCode:this.props.userMdl.nmscCode,
      // langCode:this.props.userMdl.langCode
      this.props.dispatch(fetchNmscToolboxByType(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.nmsc, this.props.user.userMdl.lang));
      this.props.dispatch(fetchNmscToolboxByLang(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.nmsc));
    } else if ((this.props.user.userMdl.flagCEA || this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer) && this.props.user.userMdl.activeProgramID >= 4) {
      let ceaYear = new Date();
      ceaYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : ceaYear.getfullYear();
      this.props.dispatch(fetchToolboxByType(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.flagCEA, (this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer), ceaYear, this.props.user.userMdl.getNetworkUserPrimaryKey));
    } else {
      let ceaYear = new Date();
      ceaYear = this.props.user.userMdl.ceaDefaultYears ? this.props.user.userMdl.ceaDefaultYears.value : ceaYear.getfullYear();
      this.props.dispatch(fetchToolboxByType(this.props.user.userMdl.activeProgramID, this.props.user.userMdl.flagCEA, (this.props.user.userMdl.businessAreaKey === AppConst.businessAreaKeyPer), ceaYear, this.props.user.userMdl.getNetworkUserPrimaryKey));
    }

  }

  componentWillReceiveProps(nextProps) {
    const themeClass = this.props.theme.name + "-toolbox";

    if (nextProps.toolboxByType.toolboxByType) {
      this.setState({ toolBoxList: nextProps.toolboxByType.toolboxByType });
      nextProps.toolboxByType.toolboxByType.map((toolbox) => {
        var srt = toolbox.location.replace(/\//g, '$');
        srt = srt.replace(/\./g, '-white*');
        var url = "background-image: url('" + baseUrl + srt + "')";
        // if(toolbox.location === "DEFAULT_ICON/icon/default.png"){
        //   toolbox.toolboxKey=111111;
        //   } 
        $(".toolbox_" + toolbox.toolboxKey + "_container:hover").attr("style", url)
        if (this.state.selectedToolbox === toolbox.toolboxName) {
          if (nextProps.leftNavReducer && nextProps.leftNavReducer.message !== "toolbox_deleted") {

            this.addClass(toolbox.toolboxName, toolbox.toolboxId, toolbox.toolboxKey, toolbox.location, toolbox.packageMapPrimaryKey);
          }
        }
      });
      var options = nextProps.toolboxByType.toolboxByType.map((item) => ({
        value: item.toolboxId,
        // label: I18n.t(item.toolboxName),
        label: item.toolboxName,
        toolboxName_EN: item.toolboxName_EN,
        nmscToolboxId: item.nmscToolboxId,
        toolboxKey: item.toolboxKey,
        toolboxLocation: item.location,
        type: item.type,
        packageMapPrimaryKey: item.packageMapPrimaryKey,
        packageOwner: item.packageOwner
      }));
      this.setState({ toolList: options })

    }

    if (nextProps.leftNavReducer.closeModal) {
      this.setState({ modal: false, isCollapsed: !this.state.isCollapsed })
    }

    if (nextProps.nmscToolIdList) {
      this.setState({ nmscToolIdList: nextProps.nmscToolIdList })
    }

    if (nextProps.nmscDropdownToolList) {
      this.setState({ nmscDropdownToolList: nextProps.nmscDropdownToolList })
    }

    if (nextProps.ceaBusinessOwnerList) {
      this.setState({ ceaBusinessOwnerList: nextProps.ceaBusinessOwnerList })
    }

    if (nextProps.theme) {
      this.setState({ theme: nextProps.theme.name });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.theme.name !== this.props.theme.name) {
      this.setState({ showDelete: false, showEdit: false });
      this.state.toolBoxList.map((toolbox, index) => {
        if (this.state.selectedToolbox === toolbox.toolboxName) {
          this.propFunction(toolbox);
        }
      })
    }
    if (prevProps.toolboxByType.toolboxByType) {
      if (prevProps.toolboxByType.toolboxByType.length === 9 && this.props.toolboxByType.toolboxByType.length === 8) {
        this.setState({ selectedSlide: 0 })
      }
      else if (prevProps.toolboxByType.toolboxByType.length === 17 && this.props.toolboxByType.toolboxByType.length === 16) {
        this.setState({ selectedSlide: 1 })
      }

    }
  }

  componentDidMount() {
    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        if (this.props.user.userMdl.activeProgram !== "CEA_" && (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA NMSC ADMIN")) {
          isNMSCAdminUser = true;
          break;
        }
        else if (this.props.user.userMdl.activeProgram !== "CEA_" && (roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN")) {
          isTMEAdminUser = true;
          break;
        }
        isNotAdmin = true;

      }
    }
    if (
      this.props.user.userMdl.roles.includes("KET-CEA Admin") ||
      this.props.user.userMdl.roles.includes("KET-CEA Business Owner") ||
      this.props.user.userMdl.roles.includes("KET-NET Admin") ||
      this.props.user.userMdl.roles.includes("KET-LEXUS Admin") ||
      this.props.user.userMdl.roles.includes("KET-NET Business Owner")
    ) {
     if(this.props.user.userMdl.activeProgram === "CEA_") { 
      this.props.dispatch(fetchUsersByRole('CEA-BO'));
     }
     if(this.props.user.userMdl.activeProgram === "NET") {
      this.props.dispatch(fetchUsersByRole('NET-BO'));
     }
    }
    

 




    this.setState({ isNmsc: isNMSCAdminUser });
    this.setState({ isNotAdmin: isNotAdmin });
  }


  //This funtion is to handle theme for the side nav bar
  addClass = (toolBoxName, toolBoxId, toolboxKey, location, packageMapPrimaryKey) => {
    //to set the default slide selected
    var toolBoxIndex = 0;
    this.state.toolBoxList.map((tool) => {
      if (tool.toolboxKey === toolboxKey) { toolBoxIndex = this.state.toolBoxList.indexOf(tool) }
    });
    var selectedSlide = 0;

    // if(toolBoxIndex>=8){selectedSlide=1}else if(toolBoxIndex>=16){selectedSlide=1}
    if (toolBoxIndex >= 8 && toolBoxIndex < 16) { selectedSlide = 1 } else if (toolBoxIndex >= 16) { selectedSlide = 2 }

    this.setState({ selectedSlide: selectedSlide });

    //to set all the toolbox image as default black png
    //toolboxKey=toolboxKey.split(' ').join('_');
    if (this.state.selectedToolboxKey !== 0) {
      var srt = this.state.selectedToolboxLocation.replace(/\//g, '$');
      srt = srt.replace(/\./g, '*');
      var url = "background-image: url('" + baseUrl + srt + "') !important";
      $(".toolbox_activetoolbox_" + this.state.selectedToolboxKey).attr("style", url)
    }
    //remove active toolbox class
    $(".row.toolbox").removeClass(function (index, css) {
      return (css.match(/\btoolbox_activetoolbox\S+/g) || []).join(' '); // removes classes that starts with "toolbox_activetoolbox"
    });
    //set all the selected toolbox parameters
    this.setState({ selectedToolbox: toolBoxName });
    this.setState({
      selectedToolboxId: toolBoxId,
      selectedPackageMapPrimaryKey: packageMapPrimaryKey
    });
    this.setState({ selectedToolboxLocation: location });
    this.setState({ selectedToolboxKey: toolboxKey })
    //set container class to the toolbox clicked/selected
    var toolBoxIdDom = ".toolbox_" + toolboxKey + "_container";
    //change the css from background white to red
    $(".row.toolbox").removeClass("toolbox_hover");
    $(toolBoxIdDom).addClass("toolbox_hover toolbox-" + this.props.theme.name);
    var srt = location.replace(/\//g, '$');
    //replace the selected toolbox with the white png
    srt = srt.replace(/\./g, '-white*');
    $(toolBoxIdDom).addClass("toolbox_activetoolbox_" + toolboxKey);
    var url = "background-image: url('" + baseUrl + srt + "') !important";
    $(toolBoxIdDom).attr("style", url);
  };
  propFunction = (toolboxDetails) => {
    this.setState({ showDelete: true, showEdit: true });
    this.addClass(toolboxDetails.toolboxName, toolboxDetails.toolboxId, toolboxDetails.toolboxKey, toolboxDetails.location, toolboxDetails.packageMapPrimaryKey);
    this.props.changeToolBoxFlag(toolboxDetails.toolboxId, true, toolboxDetails);

  };
  setToolboxUrl(url) {
    console.log(url);
    this.setState({ toolboxUrl: url });
  }
  getToolboxUrl() {
    return this.state.toolboxUrl;
  }
  navAction(action) {
    if (action === 'Add') {
      this.toggleModal(action);
    } else if (action === 'Edit') {
      this.toggleModal(action);
    }
  }

  toggleModal = (mode) => {
    this.setState({
      modal: !this.state.modal,
      modalFor: mode
    });
    if (this.state.modal) {
      this.setState({
        isCollapsed: !this.state.isCollapsed
      });
    }
  }
  makeToolboxNav(element) {
    const themeClass = this.props.theme.name + "-toolbox";
    return (element[0].map((toolbox, index) => {
      if (index === 0) {
        var toolboxName = toolbox.toolboxName.split(' ').join('_').toLowerCase();
        var toolboxKey = toolbox.toolboxKey;
        var location = toolbox.location.replace(/\//g, '$');
        location = location.replace(/\./g, '*');

        var Toolboxdefault = "first_toolbox toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container" + " toolbox_default"

        // return <Row key={index} className={"first_toolbox toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container"}
        return <Row key={index} className={toolbox.location !== "DEFAULT_ICON/icon/default.png" ?
          "first_toolbox toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container" :
          Toolboxdefault}


          style={{ backgroundImage: "url('" + baseUrl + location + "')" }} title={toolbox.toolboxName}
          onClick={() => this.propFunction(toolbox)} >

          <Col className="m-1" style={{ textAlign: "center" }}>
            {/* {toolbox.flagOwner ? */}
            {/* <div style={{ textOverflow: "ellipsis", textDecoration: "underline", overflow: "hidden", whiteSpace: "nowrap", paddingTop: "30px", fontSize: "0.81rem" }}>
                {/* {I18n.t(toolbox.toolboxName)} */}
            {/* {toolbox.toolboxName} */}

            {/* </div> */}
            {/* : */}
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingTop: "30px", fontSize: "0.81rem" }}>
              {/* {I18n.t(toolbox.toolboxName)} */}
              {toolbox.toolboxName}
            </div>
            {/* } */}
          </Col>
        </Row>
      } else if (index === this.state.toolBoxList.length - 1) {
        var location = toolbox.location.replace(/\//g, '$');
        location = location.replace(/\./g, '*');
        var toolboxKey = toolbox.toolboxKey;
        var toolboxName = toolbox.toolboxName.split(' ').join('_').toLowerCase();

        var Toolboxdefault = "last_toolbox toolbox_style  toolbox " + themeClass + " toolbox_" + toolboxKey + "_container" + " toolbox_default"

        return <Row key={index} className={toolbox.location !== "DEFAULT_ICON/icon/default.png" ?
          "last_toolbox toolbox_style  toolbox " + themeClass + " toolbox_" + toolboxKey + "_container" :
          Toolboxdefault
        }
          style={{ backgroundImage: "url('" + baseUrl + location + "')" }} title={toolbox.toolboxName}
          onClick={() => this.propFunction(toolbox)} >
          <Col className="m-1" style={{ textAlign: "center" }}>
            {/* <div className={"toolbox_" + toolbox.toolboxName.toLowerCase()}></div> */}
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingTop: "20px", fontSize: "0.81rem" }}>
              {/* {I18n.t(toolbox.toolboxName)} */}
              {toolbox.toolboxName}
            </div>
          </Col>
        </Row>
      } else {


        var location = toolbox.location.replace(/\//g, '$');
        location = location.replace(/\./g, '*');
        var toolboxKey = toolbox.toolboxKey;
        var toolboxName = toolbox.toolboxName.split(' ').join('_').toLowerCase();

        var Toolboxdefault = "toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container" + " toolbox_default"
        // console.log(Toolboxdefault)
        // return <Row key={index} className={"toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container"}
        return <Row key={index} className={toolbox.location !== "DEFAULT_ICON/icon/default.png" ?
          "toolbox_style toolbox " + themeClass + " toolbox_" + toolboxKey + "_container"
          : Toolboxdefault}

          style={{ backgroundImage: "url('" + baseUrl + location + "')" }} title={toolbox.toolboxName} onClick={() => this.propFunction(toolbox)} >
          <Col className="m-1" style={{ textAlign: "center" }}>
            {/* <div className={"toolbox_" + toolbox.toolboxName.toLowerCase()}></div> */}
            <div style={{ textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap", paddingTop: "20px", fontSize: "0.81rem" }}>
              {/* {I18n.t(toolbox.toolboxName)} */}
              {toolbox.toolboxName}

            </div>
          </Col>
        </Row>
      }
    }));
  }
  togglePane = () => {
    this.setState({ isCollapsed: !this.state.isCollapsed });

  }
  render() {
    let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'TOOLBOX_ADD')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'TOOLBOX_ADD')[0].accessType : null;
    let tmeToolbox = this.state.toolBoxList.filter(toolbox => { if (toolbox.type === "TME") { return toolbox } })
    let nmscToolbox = this.state.toolBoxList.filter(toolbox => { if (toolbox.type === "NMSC") { return toolbox } })

    var isNMSCAdminUser = false;
    var isTMEAdminUser = false;
    var isDealer = false;
    var isNotAdmin = false;
    if (this.props.user.userMdl.roles !== undefined) {
      var roles = this.props.user.userMdl.roles;
      for (var i = 0; i < roles.length; i++) {
        // if (roles[i].toUpperCase().indexOf('KET NMSC ADMIN') !== -1 || roles[i].toUpperCase().indexOf('KET-BRIT NMSC Admin') !== -1) {
        if (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET-BRIT NMSC ADMIN" || roles[i].toUpperCase() === "KET-EA NMSC ADMIN") {

          isNMSCAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET-BRIT TME ADMIN" || roles[i].toUpperCase() === "KET TME ADMIN" || roles[i].toUpperCase() === "KET-EA ADMIN") {
          isTMEAdminUser = true;
          break;
        }
        else if (roles[i].toUpperCase() === "KET DEALER CHAMPION" || roles[i].toUpperCase() === "KET-BRIT DEALER CHAMPION" || roles[i].toUpperCase() === "KET DEALER INTERNAL" || roles[i].toUpperCase() === "KET-BRIT DEALER INTERNAL" || roles[i].toUpperCase() === "KET-EA DEALER CHAMPION") {
          isDealer = true;
          break;
        }
        isNotAdmin = true;

      }
    }

    const themeClass = this.props.theme.name + "-toolbox";
    const toolbox_style = "background-repeat: no-repeat,background-position: top";
    var toolbox_Obj = [];

    var empty = [];
    this.state.toolBoxList.map((toolbox, index) => {
      if (index < 25) {
        if (index < 8) {
          empty.push(toolbox);
        } else if (index >= 8 && index < 16) {
          empty.push(toolbox);
        } else {
          empty.push(toolbox);
        }
        if (index === 7 || index === 15 || index === 23 || ((index === this.state.toolBoxList.length - 1) && empty.length < 8)) {
          toolbox_Obj.push([empty]);
          empty = [];
        }
      }
    });
    if (this.props.user.userMdl.activeProgram === "CEA_" || this.props.user.userMdl.activeProgram === "NET" || this.props.user.userMdl.activeProgram === "UC") {
      isNMSCAdminUser = false
      isTMEAdminUser = false
    }
    return (
      <div className={"toolbox-" + this.props.theme.name}>
        <Carousel
          showArrows={false}
          showThumbs={false}
          showStatus={false}
          selectedItem={this.state.selectedSlide}
          width={"8rem"}
          useKeyboardArrows={true}
        >
          {toolbox_Obj.map((ele,index) => {
            return <div key={index}>{this.makeToolboxNav(ele)}</div>
          })}
        </Carousel>
        {/* <Row className={"last_toolbox toolbox_style"}   >
          <Col style={{ textAlign: "center",marginLeft:"-10px" }}>
            <div className={"addEditToolbox-"+this.props.theme.name} style={{ overflow: "hidden", whiteSpace: "nowrap", paddingTop: "26px" }}>
                <Col className="d-flex justify-content-center" style={{ display: 'inherit', padding: '5px' }}>
                <a className="canShrink" onClick={() => this.navAction('Edit')}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon pl-0 pr-0" /></a>
                {this.state.toolBoxList.length < 19 ? <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                  <a><div title={I18n.t('standardMessages.addToolboxNotAllowed')} className="add-icon-disabled" /></a>}
              </Col>
            </div>
          </Col>
        </Row> */}
        {
          isNMSCAdminUser || isTMEAdminUser ||
            this.props.user.userMdl.roles.includes("KET-CEA Admin") ||
            this.props.user.userMdl.roles.includes("KET-CEA Business Owner") ||
            this.props.user.userMdl.roles.includes("KET-NET Admin") || 
            this.props.user.userMdl.roles.includes("KET-NET Business Owner") ||
            this.props.user.userMdl.roles.includes("KET-LEXUS Admin") ||
            this.props.user.userMdl.roles.includes("KET-UC Admin") ?  this.state.isCollapsed ?
            <Row className={"last_toolbox toolbox_style"}   >
              <Col style={{ textAlign: "center", marginLeft: "-15px" }}>
                <div className={"addEditToolbox-" + this.props.theme.name} style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                  <div className="row collapseAddEdit">
                    <div>
                      <div className={"toogleSwitch-" + this.props.theme.name} onClick={() => this.togglePane()} />
                    </div>
                    <div className="justify-content-center addEditPadding" style={{ display: 'inherit', padding: '5px' }}>
                      <a className="canShrink" onClick={() => this.navAction('Edit')}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon pl-0 pr-0" /></a>
                      {(!isNMSCAdminUser && !isTMEAdminUser && (this.props.user.userMdl.roles.includes("KET-CEA Admin") || this.props.user.userMdl.roles.includes("KET-NET Admin") || this.props.user.userMdl.roles.includes("KET-UC Admin") || this.props.user.userMdl.roles.includes("KET-LEXUS Admin"))) ?
                        <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                        accessType === "R/W" ?
                          (tmeToolbox.length < 19 && nmscToolbox.length === 0) ? <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                            tmeToolbox.length <= 19 && nmscToolbox.length !== 0 ? ((nmscToolbox.length < 5) ? <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                              <a><div title={I18n.t("Cannot create more than 5 toolbox")} style={{ marginTop: "-0.2rem" }} className="add-icon-disabled" /></a>)
                              : <a><div title={I18n.t('standardMessages.addToolboxNotAllowed')} style={{ marginTop: "-0.2rem" }} className="add-icon-disabled" /></a> : ""}

                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            :
            <Row className={"last_toolbox toolbox_style"}   >
              <Col style={{ textAlign: "center", marginLeft: "-15px" }}>
                <div className={"addEditToolboxRemove-" + this.props.theme.name} style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
                  <div className="row collapseAddEdit">
                    <div >
                      <div className={"toogleSwitch-" + this.props.theme.name} onClick={() => this.togglePane()} />
                    </div>
                    <div className="justify-content-center collapsed" style={{ display: 'inherit', padding: '5px' }}>
                      <a className="canShrink" onClick={() => this.navAction('Edit')}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon pl-0 pr-0" /></a>
                      {accessType === "R/W" ?
                        (tmeToolbox.length < 19 && nmscToolbox.length === 0) ? <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                          tmeToolbox.length <= 19 && nmscToolbox.length !== 0 ? ((nmscToolbox.length < 5) ? <a className="canShrink" onClick={() => this.navAction('Add')}><div title={I18n.t('tmeAdminMaster.add')} className="lib-add-icon pl-0 pr-0" /></a> :
                            <a><div title={I18n.t("Cannot create more than 5 toolbox")} style={{ marginTop: "-0.2rem" }} className="add-icon-disabled" /></a>)
                            : <a><div title={I18n.t('standardMessages.addToolboxNotAllowed')} style={{ marginTop: "-0.2rem" }} className="add-icon-disabled" /></a> : ""}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            : ""
        }

        {
          this.state.modal === true ?
            (!isNMSCAdminUser && !isTMEAdminUser &&
              (this.props.user.userMdl.roles.includes("KET-CEA Admin") ||
                this.props.user.userMdl.roles.includes("KET-CEA Business Owner") ||
                this.props.user.userMdl.roles.includes("KET-NET Admin") ||
                this.props.user.userMdl.roles.includes("KET-NET Business Owner") || this.props.user.userMdl.roles.includes("KET-UC Admin")|| this.props.user.userMdl.roles.includes("KET-LEXUS Admin"))) ?
              <AddEditBusiFunc
                modal={this.state.modal}
                toggle={this.toggleModal}
                modalFor={this.state.modalFor}
                toolList={this.state.toolList}
                ceaBusinessOwnerList={this.state.ceaBusinessOwnerList}
                ceaYear={this.props.user.userMdl.ceaDefaultYears.value}
              />
              :
              this.state.isNmsc ?
                <NmscAddEditToolbox
                  modal={this.state.modal}
                  toggle={this.toggleModal}
                  modalFor={this.state.modalFor}
                  isNmsc={this.state.isNmsc}
                  toolList={this.state.toolList}
                  nmscCode={this.props.user.userMdl.nmsc}
                  changeToolBoxFlag={this.props.changeToolBoxFlag}
                  propFunction={this.propFunction}
                  langCode={this.state.activeTabNmscLang}
                  nmscDropdownToolList={this.state.nmscDropdownToolList}
                  nmscToolIdList={this.state.nmscToolIdList}
                /> :
                <AddEditToolbox
                  modal={this.state.modal}
                  toggle={this.toggleModal}
                  modalFor={this.state.modalFor}
                  toolList={this.state.toolList}
                  nmscCode={this.props.user.userMdl.nmsc}
                  changeToolBoxFlag={this.props.changeToolBoxFlag}
                  propFunction={this.propFunction}
                  langCode={this.state.activeTabNmscLang}
                /> : null
        }
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    leftNavList: state.leftNavReducer,
    toolboxByType: state.leftNavReducer,
    leftNavReducer: state.leftNavReducer,
    nmscToolIdList: state.leftNavReducer.nmscToolIdList,
    nmscDropdownToolList: state.leftNavReducer.nmscDropdownToolList,
    ceaBusinessOwnerList: state.leftNavReducer.ceaBusinessOwnerList,
  };
};
export default withRouter(connect(mapStateToProps)(withTheme(Toolbox)));
