import React from 'react';
import { withTheme } from './themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
class AppHandler extends React.Component {

  componentDidMount() {
    console.log('Login route pushed from Component did mount.');
    this.props.history.push('/login');
  }

  render() {
    return (
      <div></div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.router
  }
}
export default withRouter(connect(mapStateToProps)(withTheme(AppHandler)));