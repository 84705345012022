import React from 'react';
import { connect } from 'react-redux';
import { withTheme, themes } from '../../../themes/theming';
import { NavbarNav, NavItem, Fa, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'mdbreact';
import { withRouter, Link } from 'react-router-dom';
import './index.css';
import { push } from "connected-react-router";
import * as AppConst from "../../../AppConstant";
var I18n = require('react-redux-i18n').I18n;
const $ = require('jquery')
window.jQuery = $;

class TmeAdminMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            collapse: false,
            isWideEnough: false,
            theme: themes.default,
            selectedTabName: ''
        };
        this.onClick = this.onClick.bind(this);
        this.addCss = this.addCss.bind(this);
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.theme.name !== this.props.theme.name) {
            this.addCss({ target: { innerText: this.state.selectedTabName } });
        }
    }

    onClick() {
        this.setState({
            collapse: !this.state.collapse,
        });
    }

    add(e) {
        e.preventDefault();
        this.props.dispatch(push("/certification"));
    }

    addUser(e) {
        e.preventDefault();
        this.props.dispatch(push("/userManagement"));
    }
    addDealer(e) {
        e.preventDefault();
        this.props.dispatch(push("/dealerManagement"));
    }
    addActivities(e) {
        e.preventDefault();
        this.props.dispatch(push("/activityUpload"));
    }

    kpi(e) {
        e.preventDefault();
        this.props.dispatch(push("/kpiUpload"));
    }

    addCss(e) {
        let selectedTabName = e.target.innerText;
        this.setState({ selectedTabName: selectedTabName });
        $("a").filter(function () {
            if ($(this).text() === selectedTabName) {
                $(this).addClass("selectedMenu");
            } else {
                $(this).removeClass("selectedMenu");
            }
            return null;
        });
    }

    render() {
        const navLinkClass = this.props.theme.name + "-nav-link nav-link";
        const navLinkClassAdmin = this.props.theme.name + "-nav-link nav-link backColor";
        return (
            <NavbarNav left>
                <NavItem active >
                    <Link className={navLinkClass} to="/home" onClick={this.addCss.bind(this)}>
                        <Fa icon="home" fixed className="mr-1" />
                        {I18n.t('TopNav.Home')}
                    </Link>
                </NavItem>
                <NavItem >
                    <Link className={navLinkClass} to="/toolbox" onClick={this.addCss.bind(this)}>
                        <Fa icon="wrench" fixed className="mr-1" />
                        {I18n.t('TopNav.Toolbox')}
                    </Link>
                </NavItem>
                <NavItem >
                    <Link className={navLinkClass} to="/activity" onClick={this.addCss.bind(this)}>
                        <Fa icon="briefcase" fixed className="mr-1" />
                        {I18n.t('TopNav.ActivityLog')}</Link>
                </NavItem>
                {/* (this.props.user.userMdl.activeProgram !== "BRiT") ? <NavItem >
                    <Link className={navLinkClass} to="/masterDataUpload" onClick={this.addCss.bind(this)}>
                        <Fa icon="upload" fixed className="mr-1" />
                        {I18n.t('TopNav.MasterDataUpload')}</Link>
                </NavItem> : null */}
                {/* <NavItem >
                    <Link className={navLinkClass} to="/download" onClick={this.addCss.bind(this)}>
                        <Fa icon="download" fixed className="mr-1" />
                        {I18n.t('TopNav.Download')}</Link>
                </NavItem> */}
                {/* <MDBDropdown className={AdminHideShow}>
                    <MDBDropdownToggle nav caret className={navLinkClass}>
                        {I18n.t('TopNav.Admin')}
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="navLinkClass">
                        <Link className="navLinkClass" to="/certification" onClick={this.addCss.bind(this)}>
                            Dealer Certification
                        </Link>
                    </MDBDropdownMenu>
                </MDBDropdown>  */}

                {(this.props.user.userMdl.activeProgram !== "BRiT" && this.props.user.userMdl.activeProgram !== "EA") ? <NavItem>
                    <Dropdown>
                        <DropdownToggle nav caret className={navLinkClassAdmin} onClick={this.addCss.bind(this)}>
                            {I18n.t('TopNav.Admin')}
                        </DropdownToggle>
                        <DropdownMenu>
                            {this.props.user.userMdl.activeProgram !== "TSM_SURVEYS"? 
                                <DropdownItem
                                    className="dd_item"
                                    onClick={this.add.bind(this)}
                                >
                                    {I18n.t('dealerCertification.dealerCertification')}
                                </DropdownItem> : ''
                            }
                            <DropdownItem
                                className="dd_item"
                                onClick={this.addUser.bind(this)}
                            >
                                {I18n.t('userManagement.userManagement')}
                            </DropdownItem>
                            {/* <DropdownItem
                                className="dd_item"
                                onClick={this.addDealer.bind(this)}
                            >
                                {I18n.t('Dealer Management')}
                            </DropdownItem> */}
                            <DropdownItem
                                className="dd_item"
                                onClick={this.addDealer.bind(this)}
                            >
                                {I18n.t('networkManagement.networkManagement')}
                            </DropdownItem>
                            <DropdownItem
                                className="dd_item"
                                onClick={this.kpi.bind(this)}
                            >
                                {I18n.t('others.bulkActions')}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </NavItem>
                    :
                    <NavItem>
                        <Dropdown>
                            <DropdownToggle nav caret className={navLinkClassAdmin} onClick={this.addCss.bind(this)}>
                                {I18n.t('TopNav.Admin')}
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem
                                    className="dd_item"
                                    onClick={this.addUser.bind(this)}
                                >
                                    {I18n.t('userManagement.userManagement')}
                                </DropdownItem>
                                <DropdownItem
                                    className="dd_item"
                                    onClick={this.addDealer.bind(this)}
                                >
                                    {I18n.t('networkManagement.networkManagement')}
                                </DropdownItem>
                                {/* <DropdownItem
                                className="dd_item"
                                onClick={this.addDealer.bind(this)}
                            >
                                {I18n.t('Dealer Management')}
                            </DropdownItem> */}
                                <DropdownItem
                                    className="dd_item"
                                    onClick={this.kpi.bind(this)}
                                >
                                    {"Bulk Action"}
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </NavItem>}
                    {this.props.user.userMdl.activeProgram === "BRiT" ? 
                        <NavItem >
                            <a target="_blank" rel="noopener noreferrer" className={navLinkClass} href="https://apc01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fapp.powerbi.com%2FRedirect%3Faction%3DOpenApp%26appId%3D9b474e7c-2783-49c8-a688-17ddf81555b7%26ctid%3D52b742d1-3dc2-47ac-bf03-609c83d9df9f&data=04%7C01%7Cvrushali.k%40infosys.com%7C042c6add914f49659b2c08d98190fb5f%7C63ce7d592f3e42cda8ccbe764cff5eb6%7C0%7C0%7C637683281974747315%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=Ove%2B4f6fxJZBYBcoUlZc2937ZeHW1MX%2BbhaNbdB6NfY%3D&reserved=0" onClick={this.addCss.bind(this)}>
                            {'Power BI'}
                            </a>
                        </NavItem> 
                    : null}
                     {this.props.user.userMdl.activeProgram === "EA" &&
                        (this.props.user.userMdl.roles.includes(
                        AppConst.REACHIT_ROLES.KET_REACHIT_Admin
                        ) ||
                        this.props.user.userMdl.roles.includes(
                            AppConst.REACHIT_ROLES.KET_REACHIT_NMSC_Admin
                        ) ||
                        this.props.user.userMdl.roles.includes(
                            AppConst.REACHIT_ROLES.KET_REACHIT_Dealer_Champion
                        )) ?  (
                        <NavItem>
                            <Link
                            className={navLinkClass}
                            to="/reachIthome"
                            onClick={this.addCss.bind(this)}
                            >
                            <Fa icon="home" fixed className="mr-1" />
                            {I18n.t("reachItDashboard.topNavReachIT")}
                            </Link>
                        </NavItem>
                    ) : null}
            </NavbarNav>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.router
    }
}

export default withRouter(connect(mapStateToProps)(withTheme(TmeAdminMenu)));