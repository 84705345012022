import { Fa, MDBCol, MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBRow } from 'mdbreact';
import moment from 'moment';
import React from "react";
import { ProgressBar } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import LoadingOverlay from 'react-loading-overlay';
import { connect } from "react-redux";
import { toastr } from 'react-redux-toastr';
import { withRouter } from "react-router-dom";
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
import { withTheme } from "../../../themes/theming";
import '../index.css';

var I18n = require("react-redux-i18n").I18n;
class ToolboxImplementation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            data: [],
            fromDate: null,
            toDate: null,

        };
    }
    componentWillMount() {
        this.props.dispatch(fetchScrollableWidgetData("toolboxImplementation", "SET_TOOLBOX_PERCENTAGE", this.getQueryDTO(this.state.fromDate, this.state.toDate)));
    }
    componentWillReceiveProps(nextProps) {
        // if (nextProps.toolboxPercent && nextProps.toolboxPercent.length > 0) {
        if (nextProps.toolboxPercent) {
            this.setState({ data: nextProps.toolboxPercent, isLoading: false });
        }
    }
    getQueryDTO = (startDate, endDate) => {
        let activeProgram = this.props.userMdl.activeProgram;
        let nmscCode = this.props.userMdl.nmsc;
        let langCode = this.props.userMdl.lang;
        // let userId = this.props.userMdl.username;
        if (startDate !== null && startDate instanceof Date) {
            let dt = moment(startDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (endDate !== null && endDate instanceof Date) {
            let dt = moment(endDate);
            endDate = dt.format("YYYY-MM-DD");
        }
        return {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode: this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            userId: this.props.userMdl.username,
            fromDate: startDate,
            toDate: endDate,
            businessArea: this.props.userMdl.businessAreaKey
        };
    }
    applyDateFilter = () => {
        let startDate = '';
        let endDate = '';
        if (this.state.fromDate !== null) {
            let dt = moment(this.state.fromDate);
            startDate = dt.format("YYYY-MM-DD");
        }
        if (this.state.toDate !== null) {
            let dt = moment(this.state.toDate);
            endDate = dt.format("YYYY-MM-DD");
        }
        // Reload, if both dates are empty or both are not empty.
        if ((startDate !== '' && endDate !== '') || (startDate === '' && endDate === '')) {
            // End date should be greater than Start Date.
            if (startDate !== '' && endDate !== '' && this.state.fromDate.getTime() >= this.state.toDate.getTime()) {
                toastr.error("End date should be greater than Start date.")
            } else {
                this.setState({ isLoading: true });
                this.props.dispatch(fetchScrollableWidgetData("toolboxImplementation", "SET_TOOLBOX_PERCENTAGE", this.getQueryDTO(this.state.fromDate, this.state.toDate)));
            }
        }
    }
    render() {
        let baseUrl = "/ket/image/download/";
        // let baseUrl="http://localhost:9090/ket/image/download/"

        return (
            <div className='toolboxImplCss'>
                <div className={"large-widget-header left-align-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.toolboxImpl')}>
                    {I18n.t('dashboard.toolboxImpl')}
                  
                    <MDBDropdown className="widget-filter-icon-container">
                        <MDBDropdownToggle tag="div" style={{ display: 'inline' }}>
                            <Fa icon="filter" className="widget-filter-icon" />
                        </MDBDropdownToggle>
                        <MDBDropdownMenu tag="div" className="widget-filter-dd-menu widgetTransform">
                            <MDBDropdownItem toggle={false} tag="div">
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.startDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                        <DatePicker className="widget-filter-date-picker image"
                                            onChange={(e) => this.setState({ fromDate: e })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable={true}
                                            selected={this.state.fromDate}
                                            id="fromDate"
                                            name="fromDate"
                                        />
                                    </MDBCol>
                                </MDBRow>
                                <div className="marginTopDashboad"></div>
                                <MDBRow>
                                    <MDBCol md="3" lg="4" className="widget-filter-dd-header">{I18n.t('Table.endDate') + ":"}</MDBCol>
                                    <MDBCol md="3" lg="8" className="pl-2">
                                        <DatePicker className="widget-filter-date-picker image"
                                            onChange={(e) => this.setState({ toDate: e })}
                                            dateFormat="dd/MM/yyyy"
                                            isClearable={true}
                                            selected={this.state.toDate}
                                            id="toDate"
                                            name="toDate"
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                            <MDBDropdownItem toggle={true} tag="div">
                                <MDBRow style={{paddingLeft:"111px"}}>
                                    <MDBCol>
                                        <div>
                                            <button className={"filter-button filter-button-" + this.props.theme.name}
                                                onClick={() => this.applyDateFilter()}>
                                                {I18n.t('dashboard.go')}
                                            </button>
                                        </div>
                                    </MDBCol>
                                </MDBRow>
                            </MDBDropdownItem>
                        </MDBDropdownMenu>
                    </MDBDropdown>
                </div>
               
               <div className='LegendCss' style={{ display: 'inline-block' }} > 
               <svg height="25" width="20" >
               <circle cx="12" cy="12" r="5" fill="#28a745" ></circle>
               </svg>
               {I18n.t("status.completed")}
               <svg height="25" width="20">
               <circle cx="12" cy="12" r="5"  fill="#FFBB33"/>
               </svg>
               {I18n.t('status.closed')}
               <svg height="25" width="20">
               <circle cx="12" cy="12" r="5" fill="#33B5E5"/>
               </svg>
               {I18n.t('status.ongoing')}
               </div>
                    
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="large-widget-content">
                        {this.state.data.length > 0 ?
                            <div>
                                {this.state.data.map((toolbox, index) => {
                                    
                                    let imageUrl = baseUrl + toolbox.toolboxLocation.replace(/\//g, "$");
                                    let percentage = I18n.t("dashboard.statusCompletedL") +toolbox.percentage+"%";
                                    let percentageClose = I18n.t('dashboard.statusClosedL') +toolbox.percentageClose+"%";
                                    let percentageOngoing = I18n.t('dashboard.statusOngoingL') +toolbox.percentageOngoing+"%";
                                    if (this.props.theme.name === 'toyota') {
                                        imageUrl = baseUrl + toolbox.toolboxLocation.replace(/\//g, "$").replace('.png', '.png');
                                    }
                                    // let percentagevalue = null                                    
                                    // var danger = 100 - (toolbox.percentage);
                                    // let totalPercentage = toolbox.percentageClose + toolbox.percentage + toolbox.percentageOngoing;
                                    // if(Number.isInteger(totalPercentage)){
                                    //     percentagevalue = totalPercentage +".0"
                                    // }else{
                                    //     percentagevalue = totalPercentage
                                    // }
                                    // var labelCell = parseFloat(percentagevalue).toFixed(2) + "%";
                                    return (
                                        <MDBRow key={index} className="mt-2">
                                             
                                            <MDBCol md="2" lg="2" className="mr-0 pr-0 pb-0" style={{paddingLeft:'5px' }}>
                                            
                                                <MDBRow style={{padding: '0px 25%'}}>
                                                    <img src={imageUrl} style={{ height: '40px' }} title={toolbox.toolboxName} className="toolbox_image_icon" alt=""/>
                                                </MDBRow>
                                                <div style={{ fontWeight: 'bold',textAlign:'center' }} className={"small-font toolbox-name-" + this.props.theme.name} title={toolbox.toolboxName} >{toolbox.toolboxName}</div>
                                            </MDBCol>
                                           
                                            <MDBCol md="10" lg="10" className="mr-0 pr-1 pb-0">
                                                {/* <div className="scoreLabels">{labelCell}</div> */}
                                                
                                                <div className="scoreProgressBar" style={{ position: "relative" }}>
                                                     
                                                    <ProgressBar className="size">  
                                                        <ProgressBar variant="success" now={(toolbox.percentage)} key={1} data-toggle="tooltip" data-placement="top" title={toolbox.percentage<10 ? percentage:undefined} label={toolbox.percentage>10 ? `${(toolbox.percentage)}%` : false}/>                                                                                                               
                                                        <ProgressBar variant="warning" now={toolbox.percentageClose} key={2} data-toggle="tooltip" data-placement="top"  title={toolbox.percentageClose<10? percentageClose:undefined} label={toolbox.percentageClose>10 ? `${(toolbox.percentageClose)}%` : false}/>                                                            
                                                        <ProgressBar variant="info" now={toolbox.percentageOngoing} key={3} data-toggle="tooltip" data-placement="top"  title={toolbox.percentageOngoing<10 ? percentageOngoing:undefined} label={toolbox.percentageOngoing>10 ? `${(toolbox.percentageOngoing)}%` : false}/>
                                                        {/* <ProgressBar variant="danger" now={danger} key={2} />  */}
                                                    </ProgressBar>
                                                </div>
                                            </MDBCol>
                                        </MDBRow>);
                                })}
                                
                            </div>
                            : <div></div>}
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        toolboxPercent: state.dashboardReducer.toolboxPercent
        
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(ToolboxImplementation)));

