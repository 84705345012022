
import React from "react";
import { withTheme } from "../../../themes/theming";
import '../index.css';
import $ from 'jquery';
import Select from "react-select";
import 'react-datepicker/dist/react-datepicker.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { push } from "connected-react-router";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import * as AppConst from '../../../AppConstant';
import {fetchEvaluationFilters} from '../../../actions/activityAction.jsx'
import { downloadTemplate } from "../../../actions/bulkUploadAction.jsx";
import {MDBIcon } from "mdbreact";
import DownloadPanel from "../../bulkUpload/components/downloadPanel";
var I18n = require("react-redux-i18n").I18n;

class EvaluationSearch extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            show: false,
            nmscCodeList: [],
            statusList: [],
            businessFunctionList : [],
            selectedNmscCodeList : [],
            selectedStatusList : [],
            selectedBusinessFunction : [],   
            activityDto: {
                nmscCodeList: [],
                selectedNmscCodeList : [],
                businessFunctionList : [],
                selectedBusinessFunction : [],
                statusList:[],
                userId: this.props.user.userMdl.username,
                userNmscCode : this.props.user.userMdl.nmsc,
                ceaYear: this.props.user.userMdl.ceaDefaultYears.value,
                programID: this.props.user.userMdl.activeProgramID,
                program: this.props.user.userMdl.activeProgram,
                loggedInUser: this.props.user.userMdl.username,
                businessOwner:false,
                activeProgramName : this.props.user.userMdl.activeProgramName
            },
            downloadDto:{
                nmscCodeList: [],
                brandList: [],
                kpiList: [],
                programPackageMapIdList: [],
                loggedInUser: this.props.user.userMdl.username,
                activityStatusList: [],
                evaluationYear: this.props.user.userMdl.ceaDefaultYears.value,
                userNmscCode: this.props.user.userMdl.nmsc,
                userId: this.props.user.userMdl.username,
                type: "TME",
                programId: this.props.user.userMdl.activeProgramID,
                ceaAdmin: false,
                isPER: true,
                isUcUser: false,
                isNetUser:false,
                isLnUser:false,
            }
        };
    }
    componentWillReceiveProps(nextProps) {

        const activityDto = {...this.state.activityDto};
        activityDto["page"] = nextProps.activityDto.page;
        activityDto["rows"] = nextProps.activityDto.rows;
        activityDto["sizePerPage"] = nextProps.activityDto.sizePerPage;
        this.setState({activityDto})

        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

    }

    componentWillMount() {
    }


    componentDidMount(){

        let role =[];

        role = this.props.user.userMdl.roles
    
        let activityDto = {...this.state.activityDto}
        if(role.includes(AppConst.CEA_ROLES.KET_CEA_BO) || role.includes(AppConst.NET_ROLES.KET_NET_BO)){
            activityDto["businessOwner"] = true
        }

        activityDto["page"] = this.props.activityDto.page
        activityDto["sizePerPage"] = this.props.activityDto.sizePerPage;
        activityDto["rows"] = this.props.activityDto.rows
        activityDto["adminUserType"] = this.props.activityDto.adminUserType
        activityDto["showSortIcon"] = false
        this.setState({activityDto : activityDto},()=>{
            this.props.dispatch(fetchEvaluationFilters(activityDto,"nmscCode"))
            this.props.dispatch(fetchEvaluationFilters(activityDto,"businessFunction"))
            this.props.dispatch(fetchEvaluationFilters(activityDto,"activityStatus"))
        })
        if (this.props.location.query !== undefined && 
            this.props.location.query.activitySearchDto !== undefined) {
              activityDto = this.props.location.query.activitySearchDto;
              activityDto["sortOrder"] = null; 
              activityDto["sortCol"] = null; 
              console.log("Evalution serach loacation",this.props.location.query.activitySearchDto)              
              let selectedNmscCodeList = activityDto.selectedNmscCodeList;
              let selectedBusinessFunction = activityDto.selectedBusinessFunction;
              let selectedStatusList = []
              if(activityDto.statusList.length===1){
                let obj = {                    
                    value:activityDto.statusList[0].toUpperCase(),
                    label:activityDto.statusList[0].charAt(0).toUpperCase() + activityDto.statusList[0].slice(1).toLowerCase(),
                    selected:'selected'
                }
                selectedStatusList = [obj]
              }
              this.setState({activityDto : activityDto, selectedNmscCodeList,selectedBusinessFunction,selectedStatusList})
              this.props.search(activityDto)
        }
    }
   
    componentDidUpdate(prevProps, prevState) {
        let activityDto = {...this.state.activityDto}
        if(prevProps.evaluationFilter.nmscCodeList!== this.props.evaluationFilter.nmscCodeList && this.props.evaluationFilter.nmscCodeList!== undefined ){
            if(this.props.evaluationFilter.nmscCodeList.length===1){
                let nmscObj = this.props.evaluationFilter.nmscCodeList[0]
                nmscObj["selected"] = "selected"
                activityDto["nmscCodeList"]=[nmscObj.value]
                activityDto["selectedNmscCodeList"]=[nmscObj]
                this.setState({selectedNmscCodeList:[nmscObj],nmscCodeList:this.props.evaluationFilter.nmscCodeList,activityDto})
            }else{
                this.setState({nmscCodeList: this.props.evaluationFilter.nmscCodeList})
            }
        }

        if(prevProps.evaluationFilter.businessFunctionList!== this.props.evaluationFilter.businessFunctionList && this.props.evaluationFilter.businessFunctionList!== undefined ){   
             this.setState({businessFunctionList:this.props.evaluationFilter.businessFunctionList})
        }

        if(prevProps.evaluationFilter.activityStatusList!== this.props.evaluationFilter.activityStatusList && this.props.evaluationFilter.activityStatusList!== undefined ){       
            if(this.props.evaluationFilter.activityStatusList.length === 1) {
                let activityStatusObj = this.props.evaluationFilter.activityStatusList[0]
                activityStatusObj["selected"]="selected"
                let selectedActivityStatusList  = [activityStatusObj]
                activityDto["statusList"] = [activityStatusObj.value]   
                this.setState({statusList: this.props.evaluationFilter.activityStatusList, selectedStatusList: selectedActivityStatusList, activityDto}) 
            }else{
                if (this.props.location.query !== undefined && 
                    this.props.location.query.activitySearchDto !== undefined){
                    this.setState({statusList: this.props.evaluationFilter.activityStatusList}) 
                    this.props.dispatch(
                        push({
                          query: { activitySearchDto:undefined}
                        })
                      );
                    }
                else{   
                        this.setState({statusList: this.props.evaluationFilter.activityStatusList,selectedStatusList:[]}) 
                    }
                }
        }
        
        
    }

    onBusinessFunctionChange = (selectedOption) => {
        const activityDto = { ...this.state.activityDto };
        let valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        activityDto["businessFunctionList"] = valueOptions;
        activityDto["selectedBusinessFunction"] = selectedOption;
        this.setState({ activityDto });
        this.setState({ selectedBusinessFunction: selectedOption })
    }
    
 
    onNmscCodeChange = (selectedOption) => {
        
        const activityDto = { ...this.state.activityDto };
        let valueOptions = [];
        selectedOption.map(function (item) {
            valueOptions.push(item.value);
            return null;
        })
        activityDto["nmscCodeList"] = valueOptions;
        activityDto["selectedNmscCodeList"] = selectedOption;
        this.setState({ activityDto });
        this.setState({ selectedNmscCodeList: selectedOption })  
   }


    onStatusChange = (selectedOption) => {
        const activityDto = { ...this.state.activityDto };
        if(selectedOption){
        activityDto["statusList"] = [selectedOption.value]
        this.setState({ activityDto });
        this.setState({ selectedStatusList: [selectedOption]})
        }else{
        let statusList = [] 
        activityDto["statusList"] = statusList
        this.setState({ activityDto });
        this.setState({ selectedStatusList: []})
        }
    }   

    search = (e) => {
        e.preventDefault();
        const activityDto = {...this.state.activityDto}
        this.props.search(activityDto)
    }
    onClickUpload = (e) => {
        const activityDto = {...this.state.activityDto}
        const downloadDto = {...this.state.downloadDto}
        downloadDto['nmscCodeList'] = activityDto['nmscCodeList'];
        downloadDto['programPackageMapIdList'] = activityDto['businessFunctionList'];
        downloadDto['activityStatusList'] = activityDto['statusList'];
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramNet){
            downloadDto['isNetUser'] = true;
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramUc){
            downloadDto['isUcUser'] = true;
        }
        if(this.props.user.userMdl.activeProgram === AppConst.activeProgramLN){
            downloadDto['isLnUser'] = true;
        }
        this.setState({ downloadDto });
        this.props.dispatch(downloadTemplate(downloadDto))
    }
    render() {
        

        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchActivity">
                    <div className="col-md-12 row control-label">
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', width:'9rem', margin : '5px 5px 5px 0px' }}>{I18n.t('ceaEvaluation.nmsc')}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0.2rem', width:'12rem', margin : '5px' }}>{I18n.t("kpiEntry.businessFunc")}</label>
                        <label className="col-md-2 control-label" style={{ paddingLeft: '0.6rem', width:'9rem', margin : '5px 5px 5px 0px' }}>{I18n.t('kpiEntry.status')}</label>
                    </div>
                    <div className="col-md-12 input-group control-label evaluationSearch">
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        {this.state.nmscCodeList.length===1?<Select
                                className = "dsabledSelectNmsc"
                                value={this.state.selectedNmscCodeList}
                                options={this.state.nmscCodeList}
                                isDisabled={true}
                                />:<ReactMultiSelectCheckboxes
                                value={this.state.selectedNmscCodeList}
                                onChange={(e)=>this.onNmscCodeChange(e)}
                                options={this.state.nmscCodeList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                    }
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                            <ReactMultiSelectCheckboxes
                                value={this.state.selectedBusinessFunction}
                                onChange={(e)=>this.onBusinessFunctionChange(e)}
                                options={this.state.businessFunctionList}
                                placeholderButtonLabel={I18n.t('ceaEvaluation.all')}
                            />
                            
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        <Select
                                value={this.state.selectedStatusList && this.state.selectedStatusList.length?this.state.selectedStatusList:[{value:I18n.t('ceaEvaluation.all'),label:I18n.t('ceaEvaluation.all')}]}
                                onChange={(e)=>this.onStatusChange(e)}
                                options={this.state.statusList}
                                placeholder={I18n.t('ceaEvaluation.all')}
                                className = {"activeSelectStatus activeField-"+this.props.theme.name}
                                isClearable = {this.state.selectedStatusList && this.state.selectedStatusList.length?true:false}
                        /> 
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                        </div>
                        <div style = {{margin : '6px'}}/>
                        <div className={"col-md-2 noPadding smallDD-" + this.props.theme.name}>
                        </div>
                        <div style = {{margin : '10px 38px 10px 10px'}}/>
                        <div className={"col-md-1 searchIcon-"+ this.props.theme.name} style={{ borderTop: '0px', padding: '0px',  }}>                            
                            {this.props.location.pathname ==="/activity"?
                            <a 
                                onClick={(e) =>this.search(e)}>
                                <MDBIcon icon={"search"} fixed size="3x" title={I18n.t("ceaEvaluation.search")} 
                                style = {{float : 'right' }}
                                /> 
                            </a>:
                            <button className={"button-" + this.props.theme.name}
                                onClick={() => this.onClickUpload()}>
                                {I18n.t('tmeAdminMaster.generate')}
                            </button>}
                        </div>
                    </div>
                    {this.props.location.pathname ==="/activity"?'':
                        <DownloadPanel data={this.props.bulkUpload.uploadActivityFileList} downloadDto={this.state.downloadDto} createActivityFlag={false} />
                    }
                </div>
                <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        evaluationFilter : state.activityReducer.evaluationFilter,
        bulkUpload: state.bulkUploadReducer
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(EvaluationSearch)));