import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { connect } from 'react-redux';
import { fetchNmscList, deleteLocalKpi, publishLocalKPIs, fetchAllNmscLangList, fetchEmpltyNmscLangList } from '../../actions/tabAction.jsx';
import { withRouter } from 'react-router-dom';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import NmscModal from '../nmscModal';
import ImportModal from '../importModal';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
import './index.css';
import { toastr } from 'react-redux-toastr';
import { withTheme } from '../../themes/theming';
var I18n = require('react-redux-i18n').I18n;

class NmscTablePage extends React.Component {
/* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            ImportModal: false,
            modalFor: '',
            selectedRow: {
                localKpiId: 0,
                toolboxId: this.props.toolboxId,
                packageProgramMappingId:this.props.toolboxId,
                brand: '',
                name: '',
                definition: '',
                benchmark: '',
                unit: '',
                status: '',
                additionalInfo: '',
                mandatory: true,
                publishedOn: '',
                target: '',
                createdBy: 'ADMIN',
                createdTimestamp: new Date(),
                packageMapPrimaryKey:this.props.packageMapPrimaryKey
            },
            nmscList: [],
            selected: [],
            selectedMandatory: [],
            localKpiIdList: [],
            localKpiList: [],
            user: [],
            nmscLocalLangList: [],
            readOnlyKpiList : []
        };
        this.buttonFormatter = this.buttonFormatter.bind(this);
        this.headerAdd = this.headerAdd.bind(this);
        this.nmscTableRef = React.createRef();
    }

    componentWillMount() {
        console.log(this.props.packageMapPrimaryKey)
        console.log(this.props.toolboxId)

        this.props.dispatch(fetchNmscList(this.props.packageMapPrimaryKey !== undefined ?this.props.packageMapPrimaryKey:0, this.props.user.userMdl));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.user) {
            this.setState({ user: nextProps.user });
        }
        if (this.props.packageMapPrimaryKey !== nextProps.packageMapPrimaryKey) {
            console.log("delete "+nextProps.packageMapPrimaryKey+" , "+nextProps.packageProgramMappingId)
            this.props.dispatch(fetchNmscList(nextProps.packageMapPrimaryKey !== undefined ? nextProps.packageMapPrimaryKey :0, this.props.user.userMdl));
        }
        if (nextProps.nmscList) {
            const kpiList = nextProps.nmscList;
            var selectedKpi = [];
            var masterKpiList = [];
            var localKpiIdList = [];
            var localKpiList = [];
            var selectedKpiForImport = [];
            var readOnlyKpiList = [];
            var allKpis = [];
            for (var i = 0, size = kpiList.length; i < size; i++) {
                var item = kpiList[i];
                if (item.mandatory === true && item.status !== "Published" && item.status !== "Retired") {
                    selectedKpiForImport.push(item.localKpiId);
                    localKpiList.push(item);
                    localKpiIdList.push(item.localKpiId);
                }
                if ((item.mandatory === true && item.status !== "Published") || item.status === "Retired" || item.status === "Published") {
                    selectedKpi.push(item.localKpiId);
                }
                if((item.status === "Published")){
                    readOnlyKpiList.push(item);
                }
                masterKpiList.push(item.masterKpiId);
                allKpis.push(item.localKpiId);
            }
            this.setState({ selected: selectedKpi });
            this.setState({ masterKpiList: masterKpiList });
            this.setState({ selectedMandatory: selectedKpiForImport });
            this.setState({ nmscList: nextProps.nmscList });
            this.setState({ localKpiIdList: localKpiIdList });
            this.setState({ localKpiList :localKpiList });
            this.setState({ allKpis: allKpis });
            this.setState({ readOnlyKpiList: readOnlyKpiList });
        }
    }
    toggleNmsc = () => {
        this.setState({
            modalNmsc: !this.state.modalNmsc,
            // modalFor: action
        });
    }
    publishKIPs = () => {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI') ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0].accessType : null;
        if (this.state.localKpiIdList.length === 0 || accessType === "R") {
            toastr.error(I18n.t('toastr.atleastOnePublish'));
            return;
        }
        if ((this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") && this.state.localKpiList.length !== 0){
            const localKpiList = this.state.localKpiList;
            for (var i = 0, size = localKpiList.length; i < size; i++) {
                var item = localKpiList[i];
                if (!item.target || item.target === '' ) {
                    toastr.error(I18n.t('toastr.emptyTarget'))
                    return;
                }
        }
     } 
           /*
            *   Jira: KETVC-2492
            *   Descriptin: Automatic creation of activities for BRiT to be suppressed
            */
            // if (this.props.user.userMdl.activeProgram === "BRiT") {
            //     toastr.info(I18n.t('activity.activityCreation'));
            // }
            let saveActivityDTO = {
                toolboxId: this.props.toolboxId,
                loginId: this.props.user.userMdl.username,
                nmscCode: this.props.user.userMdl.nmsc,
                langCode: this.props.user.userMdl.lang,
                localKpiIds: this.state.localKpiIdList,
                packageMapPrimaryKey:this.props.packageMapPrimaryKey
            }
            this.props.dispatch(publishLocalKPIs(this.props.packageMapPrimaryKey, this.props.user.userMdl, saveActivityDTO));
        

    }
    toggle = (action, row) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
        this.setState({ reference: row.reference });
        if (action === "Create") {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow['localKpiId'] = 0;
            selectedRow['brand'] = '';
            selectedRow['name'] = '';
            selectedRow['definition'] = '';
            selectedRow['benchmark'] = '';
            selectedRow['status'] = '';
            selectedRow['target'] = '';
            selectedRow['mandatory'] = false;
            selectedRow['publishedOn'] = '';
            selectedRow['additionalInfo'] = '';
            this.setState({ selectedRow });
        }
        else if (action === "Edit") {
            this.props.dispatch(fetchAllNmscLangList(row.masterKpiId, row.localKpiRefId, this.props.user.userMdl));
        }
    }

    popUpClose = (action) => {
        this.setState({
            modal: !this.state.modal,
            modalFor: action
        });
        this.props.dispatch(fetchEmpltyNmscLangList());
    }
    onDeleteClick = (row) => {

        var msg = (row.status === 'Saved' && (row.publishedOnDate === null || row.publishedOnDate === '')) ? I18n.t('standardMessages.deleteConfirm') : I18n.t('standardMessages.retireConfirm');

        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className={"ConfirmBox-" + this.props.theme.name}>
                        <div className="ConfirmBoxTitle">
                            {I18n.t('standardMessages.warning')}
                        </div>
                        <div className="ConfirmBoxBody">
                            {msg}
                        </div>
                        <div className="ConfirmBoxFooter" style={{ borderTop: "0px" }}>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.props.dispatch(deleteLocalKpi(this.props.packageMapPrimaryKey, row.localKpiId, this.props.user.userMdl))
                                    onClose();
                                }}>
                                {I18n.t("standardMessages.yes")}
                            </button>
                            <button className={"button-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                        </div>
                    </div>
                );
            }
        });


    };
    buttonFormatter(cell, row) {

        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0].accessType : null;

        return (
            <div className="btn-toolbar float-right">
                {row.additionalInfo && row.additionalInfo.length > 0 ?
                    <a><div title={row.additionalInfo} className="info-icon" /></a> : ""}
                {(row.status === "Retired") ?
                    "" : (accessType === 'R/W' ? <a onClick={() => this.toggle('Edit', row)}><div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" /></a> : "")}
                {(row.reference === "TME" && row.mandatory === true && row.status !== "Published") || (row.status === "Retired") || accessType === 'R' ?
                    "" : (accessType === 'R/W' ? <a onClick={() => this.onDeleteClick(row)}><div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" /></a> : "")}
            </div>
        );
    }
    headerAdd(cell, row) {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0].accessType : null;
        
        //Improvement-Add Local KPI to be disabled without importing TME KPI. Similarly to be done for Questions. 
        let isTMERef=false;
        this.state.nmscList.filter(function (el) {
            if(el.reference==="TME"){
                isTMERef=true
            }
        })
        // console.log(isTMERef)
        let addDisabled=false
        if((this.props.user.userMdl.roles.includes("KET NMSC Admin") || this.props.user.userMdl.roles.includes("KET-BRiT NMSC Admin") || this.props.user.userMdl.roles.includes("KET-EA NMSC Admin")) &&
        (this.props.user.userMdl.activeProgram==="BRiT" || this.props.user.userMdl.activeProgram==="EA" || this.props.user.userMdl.activeProgram==="TSM" || this.props.user.userMdl.activeProgram==="TSM_BNP"|| this.props.user.userMdl.activeProgram==="TSM_USED_CAR" || this.props.user.userMdl.activeProgram==="TSM_SURVEYS") && isTMERef===false &&
        this.props.type === 'TME'){
            addDisabled=true
        }
        if(addDisabled!==true){
                return (
                    <div>
                        {accessType === "R/W" ?
                            <a onClick={() => this.toggle('Create', row)}><div title={I18n.t('tmeAdminMaster.add')} className="add-icon" /></a> : ""}</div>
                );
        }else{
                return (
                    <div>
                        {accessType === "R/W" ?
                            <a><div title={I18n.t('tmeAdminMaster.add')} className="addDisabledIcon" /></a> : ""}</div>
                );
        }
    }

    benchMarkFormatter(cell, row) {
        var benchmark = "";
        if (cell !== null && cell !== undefined && cell !== "") {
            benchmark = row.benchmark + " " + row.unit;
        }
        return (
            <div >
                {benchmark}
            </div>
        );
    }

    targetFormatter(cell, row) {
        var target = "";
        if (row.target !== undefined && row.target !== null) {
            target = row.target + " " + row.unit;
        }
        return (
            <div >
                {target}
            </div>
        );
    }

    statusFormatter(cell, row) {
        return (
            <div >
                {I18n.t('status.' + row.status.toLowerCase())}
            </div>
        );
    }

    flagFormatter(cell, row, rowIndex) {
        return (
            <div>
                {cell === true ?
                    <div title={I18n.t('Table.mandatory')} className="active-flag" /> :
                    <div title="Not Mandatory" className="inactive-flag" size="2x" />
                }
            </div>
        );
    }
    // onRowSelect = (row, isSelected) => {
    //     this.setState({
    //         selectedRows: [...this.state.selectedRows, row]
    //     })
    // }

    britKpiStyle = {
        width: "13%",
        textAlign: "left",
        wordWrap: "break-word"
    }
    britDealerKpiStyle={
        width: "16%",
        textAlign: "left",
        wordWrap: "break-word"
    }

    britKpiHeaderStyle = {
        width: "12.7%",
        textAlign: "left"
    }
    britDealerKpiHeaderStyle={
        width: "15.7%",
        textAlign: "left"

    }

    britDefinitionStyle = {
        width: "21%",
        textAlign: "left",
        whiteSpace: 'pre-wrap',
        wordWrap: "break-word"
    }
    britDealerDefinitionStyle={
        width: "24%",
        textAlign: "left",
        whiteSpace: 'pre-wrap',
        wordWrap: "break-word"  
    }

    britDefinitionHeaderStyle =
        {
            width: "20.7%",
            textAlign: "left"
        }
    britDealerDefinitionHeaderStyle =
    {
        width: "22%",
        textAlign: "left"
    }    

    britBenchmarkStyle = {
        width: "9%"
    }

    britBenchmarkHeaderStyle = {
        width: "8.8%"
    }
    britDealerBenchmarkHeaderStyle={
        width:"12%"
    }

    britMandatoryHeaderStyle = {
        width: "7%"
    }

    britReferenceStyle = {
        width: "7%",
        textAlign: "center"
    }
    britReferenceHeaderStyle = {
        width: "6.9%",
        textAlign: "center"
    }

    kpiStyle = {
        width: "18%",
        textAlign: "left",
        wordWrap: "break-word"
    }
    TsmDealerkpiStyle={
        width: "22%",
        textAlign: "left",
        wordWrap: "break-word"
    }

    kpiHeaderStyle = {
        width: "17.7%", textAlign: "left"
    }
    kpiTsmDealerHeaderStyle={
        width: "21.7%", textAlign: "left"
        
    }

    definitionStyle = {
        width: "22%",
        textAlign: "left",
        whiteSpace: 'pre-wrap',
        wordWrap: "break-word"
    }
    
    TsmDealerdefinitionStyle={
        width: "24%",
        textAlign: "left",
        whiteSpace: 'pre-wrap',
        wordWrap: "break-word"

    }
    definitionHeaderStyle = {
        width: "22.7%",
        textAlign: "left"
    }
    TsmDealerdefinitionHeaderStyle={
        width: "23.7%",
        textAlign: "left"
    }

    benchmarkStyle = { width: "11%" }
    TsmDealerbenchmarkStyle={width:"14%"}

    benchmarkHeaderStyle = { width: "9.8%" }
    TsmDealerbenchmarkHeaderStyle={ width: "13.8%" }

    mandatoryHeaderStyle = { width: "8%" }

    referenceStyle = {
        width: "8%",
        textAlign: "center"
    }
    referenceHeaderStyle = {
        width: "7.9%",
        textAlign: "center"
    }

    britPODStyle = { width: "11%" }

    podStyle = { width: "10%" }



    render() {
        // console.log(this.props.type)
        // console.log(this.state.nmscList)
        // console.log(this.state.readOnlyKpiList)
        //start-added for improvement Remove Reference (TME/Local) for Dealer users - KPI, Question & Lib
        let isDealer=this.props.user.userMdl.roles.includes("KET Dealer Champion") ||this.props.user.userMdl.roles.includes("KET Dealer Internal") ||this.props.user.userMdl.roles.includes("KET-BRiT Dealer Champion") ||this.props.user.userMdl.roles.includes("KET-EA Dealer Champion") ||this.props.user.userMdl.roles.includes("KET-BRiT Dealer Internal")
        let isTsmDealer=this.props.user.userMdl.roles.includes("KET Dealer Champion") ||this.props.user.userMdl.roles.includes("KET Dealer Internal")
        let isBritDealer=this.props.user.userMdl.roles.includes("KET-BRiT Dealer Champion")|| this.props.user.userMdl.roles.includes("KET-EA Dealer Champion") || this.props.user.userMdl.roles.includes("KET-BRiT Dealer Internal")
        //end
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'LOCAL_KPI')[0].accessType : null
        let isBrit = (this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") ? true : false
        const columns = [{
            dataField: 'name',
            text: I18n.t('Table.kpi'),
            sort: true,
            style: isBrit ? isBritDealer ? this.britDealerKpiStyle:this.britKpiStyle : isTsmDealer ? this.TsmDealerkpiStyle:this.kpiStyle,
            headerStyle: isBrit  ? isBritDealer ? this.britDealerKpiHeaderStyle:this.britKpiHeaderStyle : isTsmDealer ? this.kpiTsmDealerHeaderStyle:this.kpiHeaderStyle

        }, {
            dataField: 'definition',
            text: I18n.t('Table.definition'),
            sort: true,
            style: isBrit ? isBritDealer ? this.britDealerDefinitionStyle:this.britDefinitionStyle : isTsmDealer? this.TsmDealerdefinitionStyle:this.definitionStyle,
            headerStyle: isBrit ? isBritDealer ? this.britDealerDefinitionHeaderStyle :this.britDefinitionHeaderStyle : isTsmDealer ? this.TsmDealerdefinitionHeaderStyle :this.definitionHeaderStyle
        },
        /* {
            dataField: 'unit',
            text: I18n.t('tmeAdminMaster.unit'),
            sort: true,
            style: { width: "5%" },
            headerStyle: { width: "5%" }
        }, */ {
            dataField: 'benchmark',
            text: isBrit ? I18n.t('Table.penEbenchmark') : I18n.t('Table.benchmark'),
            sort: true,
            style: isBrit ? this.britBenchmarkStyle : isTsmDealer ? this.TsmDealerbenchmarkStyle:this.benchmarkStyle,
            formatter: this.benchMarkFormatter,
            headerStyle: isBrit ? isBritDealer ? this.britDealerBenchmarkHeaderStyle :this.britBenchmarkHeaderStyle : isTsmDealer ? this.TsmDealerbenchmarkHeaderStyle:this.benchmarkHeaderStyle
        },
        {
            dataField: 'target',
            text: I18n.t('Table.nmscTarget'),
            sort: true,
            style: { width: "10%" },
            formatter: this.targetFormatter,
            headerStyle: { width: "9.8%" },
            hidden: isBrit ? false : true
        },
        {
            dataField: 'status',
            text: I18n.t('Table.status'),
            sort: true,
            classes: (cell, row, rowIndex, colIndex) => {
                if (cell === "Published") { return 'greenColor'; }
                else if (cell === "Saved") { return 'orangeColor'; }
                else { return 'greyColor'; }
            },
            style: { width: "8%", textAlign: "center" },
            headerStyle: { width: "7.8%", textAlign: "center" },
            formatter: this.statusFormatter,
        }, {
            dataField: 'mandatory',
            text: I18n.t('Table.mandatory'),
            sort: true,
            formatter: this.flagFormatter,
            style: { width: "8%" },
            headerStyle: isBrit ? this.britMandatoryHeaderStyle : this.mandatoryHeaderStyle
        },
        
        {
            dataField: 'reference',
            text: I18n.t('Table.reference'),
            sort: true,
            //start-added for improvement Remove Reference (TME/Local) for Dealer users - KPI, Question & Lib
            hidden: isDealer ? true : false,
            //end
            style: isBrit ? this.britReferenceStyle : this.referenceStyle,
            headerStyle: isBrit ? this.britBenchmarkHeaderStyle : this.referenceHeaderStyle
        }
        ,{
            dataField: 'publishedOnDate',
            text: I18n.t('Table.publishedOn'),
            sort: true,
            style: isBrit ? this.britPODStyle : this.podStyle,
            headerStyle: { width: "9.7%" }
        }, {
            dataField: "action",
            text: I18n.t('Table.action'),
            headerFormatter: this.headerAdd,
            formatter: this.buttonFormatter,
            style: { width: "11%" },
            headerStyle: { width: "10%" }
        }];


        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                if (e.target.type === "checkbox") {
                    e.target.checked = true;
                    var index = this.state.localKpiIdList.indexOf(row.localKpiId);
                    if (index > -1) {
                        this.state.localKpiIdList.splice(index, 1);
                        this.state.localKpiList.splice(index,1);
                    } else {
                        this.state.localKpiIdList.push(row.localKpiId);
                        this.state.localKpiList.push(row);
                    }
                } else {
                    const selectedRow = { ...this.state.selectedRow };
                    selectedRow['localKpiId'] = row.localKpiId;
                    selectedRow['brand'] = row.brand;
                    selectedRow['name'] = row.name;
                    selectedRow['definition'] = row.definition;
                    selectedRow['benchmark'] = row.benchmark;
                    selectedRow['status'] = row.status;
                    selectedRow['target'] = row.target;
                    selectedRow['mandatory'] = row.mandatory;
                    selectedRow['publishedOn'] = row.publishedOn;
                    selectedRow['additionalInfo'] = row.additionalInfo;
                    selectedRow['localKpiRefId'] = row.localKpiRefId;
                    selectedRow['masterKpiId'] = row.masterKpiId;
                    selectedRow['nameEn'] = row.nameEn;
                    selectedRow['definitionEn'] = row.definitionEn;
                    this.setState({ selectedRow });
                }
            }
        };

        const selectRow = {
            mode: 'checkbox',
            clickToSelect: false,
            hideSelectAll: this.props.user.userMdl.activeProgram === "EA"? false:true,
            // hideSelectColumn: accessType != 'R/W' ? true : false,
            // selected: this.state.selectedMandatory,
            selected: this.state.localKpiIdList,
            nonSelectable: (accessType === 'R/W' ? this.state.selected : this.state.allKpis),
            onSelectAll: (isSelect, rows, e) => {
                let selectedRows = [];
                let selectedIds = [];

                rows.forEach((row) => {
                    if (row.status === 'Saved') {
                        selectedIds.push(row.localKpiId);
                        selectedRows.push(row);
                    }
                });
                if (isSelect && selectedIds.length > 0) {
                    this.setState({ 
                        localKpiList: selectedRows,
                        localKpiIdList: selectedIds
                    });
                    return selectedIds;
                } else {
                    let selectedRowsMandatory = this.state.localKpiList
                            .filter(kpi => this.state.selectedMandatory.includes(kpi.localKpiId));

                    this.setState({ 
                        localKpiList: selectedRowsMandatory,
                        localKpiIdList: this.state.selectedMandatory 
                    });
                }
            }
        };

        return (

            <div>
                <div className="nmscTable">
                    <BootstrapTable
                        keyField='localKpiId'
                        data= {accessType === "R/W"? this.state.nmscList : this.state.readOnlyKpiList}
                        columns={columns}
                        selectRow={selectRow}
                        hover
                        condensed
                        rowEvents={rowEvents}
                        noDataIndication={I18n.t('tabsDetails.NoKPI')}
                        ref={this.nmscTableRef}
                    />
                </div>

                <hr />
                {accessType === 'R/W' ?
                    <div className="row">
                     <div className="col-md-6 redLine">
                        {this.props.type === 'TME' ?
                       <div>
                            <label style={{ color: this.props.theme.primaryColor }}>{I18n.t("tabsDetails.importKpiMessage")}</label>
                            <br />
                            <img src={'images/import-' + this.props.theme.name + '.png'} alt="import" onClick={this.toggleNmsc} style={{ marginTop: "-2%", cursor: "pointer" }} />
                            </div>
                        : null }
                        </div> 

                        <div className="col-md-6" style={{ textAlign: 'right' }}>
                            <button className={'button-' + this.props.theme.name} onClick={this.publishKIPs}> {I18n.t('tabsDetails.publish')} </button>
                        </div>
                    </div> : ""}
                {this.state.modal === true ?
                    <NmscModal
                        modal={this.state.modal}
                        popUpClose={this.popUpClose}
                        modalFor={this.state.modalFor}
                        toolboxId={this.props.packageMapPrimaryKey}
                        reference={this.state.reference}
                        nmscLocalLangList={this.state.nmscLocalLangList}
                        user={this.state.user} />
                    : null}
                {this.state.modalNmsc === true ?
                    <ImportModal
                        modal={this.state.modalNmsc}
                        toggle={this.toggleNmsc}
                        toolboxId={this.props.packageMapPrimaryKey}
                        masterKpiList={this.state.masterKpiList}
                    /> : null}
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        nmscList: state.tabReducer.nmscList
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(NmscTablePage)));