import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Row } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { updateNmscToolboxToDB, saveNmscToolboxToDB, deleteNmscToolbox } from "../../actions/leftNavAction.jsx";
import { withTheme } from '../../themes/theming';
import { Tab, Tabs } from 'react-bootstrap-tabs';
import { toastr } from "react-redux-toastr";
import { confirmAlert } from "react-confirm-alert";
import Select from "react-select";
var I18n = require("react-redux-i18n").I18n;

class NmscAddEditToolbox extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            toolboxName: '',
            // nmscToolboxId: '',
            toolboxId: '',
            isDelete: false,
            toolboxList: [],
            selectedToolboxName: '',
            language: '',
            list: [],
            tmeDisabled: false
        }
    }

    componentWillMount() {
        var this_ = this;
        this.props.user.userMdl.userLangList.map((obj, index) => {
            this_.state.list.push({
                // nmscToolboxId: this.state.nmscToolboxId,
                toolboxId: 0,
                nmscToolboxId: 0,
                toolboxName: '',
                toolboxKey: '',
                location: '',
                toolboxName_EN: this.state.toolboxName_EN,
                action: this.props.modalFor,
                userId: this.props.user.userMdl.username,
                createdBy: this.props.user.userMdl.username,
                updatedBy: this.props.user.userMdl.username,
                createdTimestamp: new Date(),
                updatedTimestamp: new Date(),
                nmscCode: this.props.user.userMdl.nmsc,
                langCode: obj.language.toUpperCase(),
                program: this.props.user.userMdl.activeProgramID,
                type: 'NMSC'
            })
        })
    }
    componentWillReceiveProps(nextProps) {

        if (nextProps.nmscLocalLangList) {
            var list = [];
            this.props.user.userMdl.userLangList.map((obj, index) => {
                nextProps.nmscLocalLangList.map((row, rowIndex) => {
                    if (row.langCode === obj.language) {
                        row.nmscCode = this.props.user.userMdl.nmsc;
                        list.push(row)
                    }
                });

            });
            this.setState({ list: list });
        }

        if(nextProps.nmscToolIdList){
            this.setState({nmscToolIdList:nextProps.nmscToolIdList})
          }

        if(nextProps.nmscDropdownToolList){
            this.setState({nmscDropdownToolList:nextProps.nmscDropdownToolList})
          }

    }


    // componentDidMount() {
    //     if (this.props.toolboxName && this.props.modalFor === 'Edit') {
    //         this.setState({ toolboxName: this.props.toolboxName });
    //         this.setState({ toolboxId: this.props.toolboxId });
    //     }
    // }
    selectedTab = (index, label) => {
        this.setState({ selectedTab: index })
        this.props.nmscDropdownToolList[index].map((item) =>{
            if(this.state.toolboxId===item.toolboxId){
                this.state.list[index]["toolbox"]=item;
            }
        }); 
    }

    changeEnToolName = e => {
        this.setState({ toolboxName_EN: e.target.value });
    }

    changeToolboxName = (event, action, index) => {
        const toolboxName = { ...this.state.list[index] };
        this.state.list[index][action] = event.target.value;
        this.setState({ toolboxName: toolboxName });
        this.state.list[index]["toolboxName_EN"] = this.state.toolboxName_EN;
        this.state.list[index]["toolboxId"] = this.state.toolboxId;
        // this.state.list[index]["nmscToolboxId"]= this.state.nmscToolboxId;
        this.state.list[index]["location"] = this.state.toolboxLocation;

    }
    onChangeDelete = () => {
        this.state.isDelete = !this.state.isDelete;
        this.setState({ isDelete: this.state.isDelete });
    };

    disableDelete = (type) => {
        if(type==="TME"){
            this.setState({ tmeDisabled: true});
        }
       else{
        this.setState({ tmeDisabled: false});
       }
    };
    

    deleteToolbox = () => {
        if (
            this.state.toolboxId.length === 0
        ) {
            toastr.error(I18n.t('tmeAdminMaster.selectToolbox'));
            return;
        }
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>{I18n.t('tmeAdminMaster.deleteTool')}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    console.log("this.props.user.userMdl.activeProgramID",this.props.user.userMdl.activeProgramID)
                                    this.props.dispatch(deleteNmscToolbox(this.state.toolboxId, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.user.userMdl.activeProgramID, this.props.changeToolBoxFlag))
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });

    }

    validationAddToolbox() {
        var nmscToolboxList = this.state.list;
        var errorList = [];
        nmscToolboxList.map((obj, index) => {         
            if (obj.toolboxName_EN === null || obj.toolboxName_EN === '' || obj.toolboxName.length === 0 ) {
                errorList.push(index);
            }
        });   
        return errorList.length === 0 ? true : false;

    }
    saveToolbox = () => {     
        this.validationAddToolbox() ? this.saveNmscToolbox() : toastr.error(I18n.t('standardMessages.MandatoryWarning'));

    }
    saveNmscToolbox(){
            this.state.list.map((nmscItem) => {
                this.props.nmscToolIdList.map((item) => {
                    if (nmscItem.toolboxId === item.toolboxId && nmscItem.langCode === item.langCode) {
                        nmscItem.nmscToolboxId = item.nmscToolboxId;
                        nmscItem.toolboxKey = item.toolboxKey
                    }
                });
            });
            

        let nmscDTO = {
            nmscToolList: this.state.list,
            program: this.props.user.userMdl.activeProgramID,
            nmscCode: this.props.user.userMdl.nmsc,
            langCode: this.props.user.userMdl.lang,
            toolboxName_EN: this.state.toolboxName_EN,
            userId: this.props.user.userMdl.username,

        }

        this.props.modalFor === "Edit" ?
        this.props.dispatch(updateNmscToolboxToDB(nmscDTO, this.props.propFunction)) : this.props.dispatch(saveNmscToolboxToDB(nmscDTO, this.props.propFunction))
       
        }


    onToolboxChange = (event, action, index) => {
        // if (this.state.toolbox == null) {
        // this.state.list[index][action] = "";
        // } else {
        this.state.list[index][action] = event.value;
        this.setState({ toolboxId: event.value });
        //this.setState({ nmscToolboxId: event.nmscToolboxId });
        if (this.props.modalFor === "Edit") {
            this.setState({ toolboxKey: event.toolboxKey });
            this.setState({ toolboxLocation: event.toolboxLocation });
            this.setState({ selectedToolboxName: event.label });
            this.setState({ toolboxName_EN: event.toolboxName_EN });
            this.setState({ type: event.type });
            this.state.list[index]["toolbox"]=event;
           // this.setState({ toolbox: event });
            this.disableDelete(event.type);
        }
    };

    preventDefault() { }
    render() {

        let isAddModal = this.props.modalFor && this.props.modalFor === "Add" ? true : false;
        const dynamicKPITab = this.props.user.userMdl.userLangList.map((obj, index) => {

            return (

                <Tab label={obj.country + " / " + obj.language} key={index}>
                    <div className="col-md-12">
                        <form className="form-horizontal nmscModalForm">
                            {!isAddModal ? <div className="col-md-12 checkbox" style={{ paddingLeft: '0px' }}>
                                <label style={{ marginBottom: '0px', paddingTop: '8px' }} disabled = {this.state.tmeDisabled}>
                                    <input
                                        type="checkbox"
                                        checked={this.state.isDelete}
                                        onChange={this.onChangeDelete}
                                        disabled= {this.state.tmeDisabled}
                                    />{" "}
                                    {I18n.t('tmeAdminMaster.deleteToolbox')}
                                </label>
                            </div> : ""}
                            <br />
                            {isAddModal ?
                                <Row>
                                    <label className={"toolboxLabel"} style={{ marginBottom: '4px' }}>
                                        {I18n.t('tmeAdminMaster.programName')}
                                    </label>
                                    <br />
                                    <input type="text"
                                        className="form-control black-font"
                                        defaultValue={this.props.user.userMdl.activeProgramName}
                                        disabled="disabled" />
                                </Row> :
                                <Row>
                                    <label className={"toolboxLabel"}>
                                        {I18n.t('tmeAdminMaster.ToolboxName')}
                                        <span className="redFont"> *</span>
                                    </label>
                                    <div className="col-md-12" style={{ padding: '0px' }}>
                                        <Select
                                            placeholder= {I18n.t('others.select')}
                                            value={this.state.list[index].toolbox}
                                            onChange={(e) => this.onToolboxChange(e, 'toolboxId', index)}
                                            options={this.props.nmscDropdownToolList[index] || []}
                                            isClearable
                                        />
                                    </div>
                                </Row>}
                            <br />
                            <Row>
                                <label className={"toolboxLabel"}>
                                    {I18n.t('tmeAdminMaster.ToolboxName_EN')}
                                    <span className="redFont"> *</span>
                                </label>
                                <input type="text"
                                    className="form-control"
                                    maxLength="50"
                                    disabled={!isAddModal}
                                    // onChange={this.addEngToolboxName}
                                    onChange={(e) => this.changeEnToolName(e)}
                                    value={this.state.toolboxName_EN} />
                            </Row>
                            <Row>
                                <label className={"toolboxLabel"}>
                                    {I18n.t('tmeAdminMaster.newToolboxName')}
                                    <span className="redFont"> *</span>
                                </label>
                                <input type="text"
                                    className="form-control"
                                    maxLength="50"
                                    disabled={this.state.isDelete}
                                    value={this.state.list[index].toolboxName} onChange={(e) => this.changeToolboxName(e, 'toolboxName', index)} />
                            </Row>
                            <br /><br /><br /><br /><br /><br /><br />

                        </form>
                    </div>
                </Tab>
            )
        })
        return (

            <Modal className={"addEditNmscModal addEditNmscModal-" + this.props.theme.name} isOpen={true} toggle={() => this.preventDefault()}>
            <ModalHeader toggle={()=>this.props.toggle(this.props.modalFor)}>{isAddModal ? I18n.t('tmeAdminMaster.addToolbox') :
                         I18n.t('tmeAdminMaster.editToolBox')}
			</ModalHeader>
                <ModalBody>
                    <div className="col-md-12 row">
                        <div className="col-md-3" style={{ padding: "0.45rem" }}>
                            <Tabs onSelect={this.selectedTab} selected={this.state.selectedTab} defaultActiveKey={1} className="createKPITabs">
                                {dynamicKPITab}
                            </Tabs>
                            <br />

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter style={{ padding: "0.2rem 2rem", float: "left", border: "none" }}>
                    <span style={{ color: "red", width: "100%" }}>* {I18n.t("footer.message")}</span>
                    <button
                        className={"buttonCancel-" + this.props.theme.name}
                        onClick={() => this.props.toggle(this.props.modalFor)}
                    >
                        {I18n.t("tmeAdminMaster.cancel")}
                    </button> 
                    {!isAddModal ?
                        <button
                            className={"button-" + this.props.theme.name}
                            onClick={this.deleteToolbox}
                            hidden={!this.state.isDelete || this.state.tmeDisabled}
                        >
                            {I18n.t("tmeAdminMaster.delete")}
                        </button> : ""}
                    <button
                        className={"button-" + this.props.theme.name}
                        onClick={this.saveToolbox}
                        hidden={this.state.isDelete}
                    >
                        {I18n.t("tmeAdminMaster.save")}
                    </button>
                </ModalFooter>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        nmscLocalLangList: state.tabReducer.nmscLocalLangList,
        nmscToolIdList: state.leftNavReducer.nmscToolIdList,
        nmscDropdownToolList: state.leftNavReducer.nmscDropdownToolList

    };
};
export default withRouter(connect(mapStateToProps)(withTheme(NmscAddEditToolbox)));
