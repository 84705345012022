import React from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Fa } from "mdbreact";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";
import { getUserDetails } from "../../actions/loginAction";

import {
  uploadNetworkFile,
  setBlockingState,
  uploadQuestionsFile,
  uploadUserFile,
  deleteQuestionsFile,
  uploadActivityFile
} from "../../actions/uploadAction";
import { withTheme } from "../../themes/theming";
import $ from "jquery";
import "./index.css";
var I18n = require("react-redux-i18n").I18n;

class MasterDataUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadResponse: true,
      toolbox: "",
      nmsc: "",
      country: "",
      lang: ""
    };
    this.activityUploadBxRef=React.createRef();
    this.networkUploadBxRef= React.createRef();
    this.networkUploadExcelRef=React.createRef();
    this.questionUploadBxRef= React.createRef();
    this.questionUploadExcelRef= React.createRef();
    this.userUploadExcelRef= React.createRef();
    this.activityUploadExcelRef= React.createRef();
    this.userUploadBxRef= React.createRef();
  }

  componentWillMount() {

    if (
      this.props.user.userMdl === undefined ||
      this.props.user.userMdl === null ||
      this.props.user.userMdl.expired === undefined ||
      this.props.user.userMdl.expired
    ) {
      // get user Details from token.
      this.props.dispatch(getUserDetails());
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ uploadResponse: nextProps.uploadResponse.uploadResponse });
  }

  changeToolbox = e => {
    this.setState({
      toolbox: e.target.value
    });
  };

  changeCountry = e => {
    this.setState({
      country: e.target.value
    });
  };

  changeLang = e => {
    this.setState({
      lang: e.target.value
    });
  };

  changeNMSC = e => {
    this.setState({
      nmsc: e.target.value
    });
  };

  uploadNetworkFile(e) {
    e.preventDefault();
    if (this.networkUploadExcelRef.current && this.networkUploadExcelRef.current.files[0]) {
      var fileName = this.networkUploadExcelRef.current.files[0].name;
      var file = this.networkUploadExcelRef.current.files[0];
      this.props.dispatch(setBlockingState(true));
      var fileDTO = {
        fileType: ".xlsx",
        fileName: fileName
      };
      const postData = new FormData();
      postData.append(
        "fileDTO",
        new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
      );
      postData.append("file", file);
      this.props.dispatch(uploadNetworkFile(postData));
      $("#networkUploadBx").attr("placeholder", "");
      this.networkUploadExcelRef.current.value = "";
    } else {
      toastr.error(I18n.t('toastr.fileSelectionErr'));
    }
  }

  uploadQuestionsFile(e) {
    e.preventDefault();
    if (
      this.questionUploadExcelRef.current &&
      this.questionUploadExcelRef.current.files[0]
    ) {
      var fileName = this.questionUploadExcelRef.current.files[0].name;
      var file = this.questionUploadExcelRef.current.files[0];
      this.props.dispatch(setBlockingState(true));
      var fileDTO = {
        fileType: ".xlsx",
        fileName: fileName
      };
      const postData = new FormData();
      postData.append(
        "fileDTO",
        new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
      );
      postData.append("file", file);
      let srckey =
        this.state.toolbox +
        "_" +
        this.state.nmsc +
        "_" +
        this.state.country +
        "_" +
        this.state.lang;
      if (e.target.innerText === 'Delete') {
        this.props.dispatch(deleteQuestionsFile(fileName, srckey));
      } else {
        this.props.dispatch(uploadQuestionsFile(postData, srckey));
      }
      $("#questionUploadBx").attr("placeholder", "");
      this.questionUploadExcelRef.current.value = "";
    } else {
      toastr.error(I18n.t('toastr.fileSelectionErr'));
    }
  }

  uploadUserFile(e) {
    e.preventDefault();
    if (this.userUploadExcelRef.current && this.userUploadExcelRef.current.files[0]) {
      var fileName = this.userUploadExcelRef.current.files[0].name;
      var file = this.userUploadExcelRef.current.files[0];
      this.props.dispatch(setBlockingState(true));
      var fileDTO = {
        fileType: ".xlsx",
        fileName: fileName
      };
      const postData = new FormData();
      postData.append(
        "fileDTO",
        new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
      );
      postData.append("file", file);
      this.props.dispatch(uploadUserFile(postData));
      $("#userUploadBx").attr("placeholder", "");
      this.userUploadExcelRef.current.value = "";
    } else {
      toastr.error(I18n.t('toastr.fileSelectionErr'));
    }
  }


  uploadActivityFile(e) {
    e.preventDefault();
    if (this.activityUploadExcelRef.current && this.activityUploadExcelRef.current.files[0]) {
      var fileName = this.activityUploadExcelRef.current.files[0].name;
      var file = this.activityUploadExcelRef.current.files[0];
      this.props.dispatch(setBlockingState(true));
      var fileDTO = {
        fileType: ".xlsx",
        fileName: fileName
      };
      const postData = new FormData();
      postData.append(
        "fileDTO",
        new Blob([JSON.stringify(fileDTO)], { type: "application/json" })
      );
      postData.append("file", file);
      this.props.dispatch(uploadActivityFile(postData));
      $("#activityUploadBx").attr("placeholder", "");
      this.activityUploadExcelRef.current.value = "";
    } else {
      toastr.error(I18n.t('toastr.fileSelectionErr'));
    }
  }

  handleNetworkFileChange(file) {
    if (
      this.networkUploadExcelRef.current &&
      this.networkUploadExcelRef.current.files[0] &&
      this.networkUploadExcelRef.current.files[0].name
    ) {
      var fileName = this.networkUploadExcelRef.current.files[0].name;
      $("#networkUploadBx").attr("placeholder", fileName);
      this.setState({ uploadResponse: false });
    }
  }

  handleQuestionsFileChange(file) {
    if (
      this.questionUploadExcelRef.current &&
      this.questionUploadExcelRef.current.files[0] &&
      this.questionUploadExcelRef.current.files[0].name
    ) {
      var fileName = this.questionUploadExcelRef.current.files[0].name;
      $("#questionUploadBx").attr("placeholder", fileName);
      this.setState({ uploadResponse: false });
    }
  }

  handleUserDetailsFileChange(file) {
    if (
      this.userUploadExcelRef.current &&
      this.userUploadExcelRef.current.files[0] &&
      this.userUploadExcelRef.current.files[0].name
    ) {
      var fileName = this.userUploadExcelRef.current.files[0].name;
      $("#userUploadBx").attr("placeholder", fileName);
      this.setState({ uploadResponse: false });
    }
  }


  handleActivityFileChange(file) {
    if (
      this.activityUploadExcelRef.current &&
      this.activityUploadExcelRef.current.files[0] &&
      this.activityUploadExcelRef.current.files[0].name
    ) {
      var fileName = this.activityUploadExcelRef.current.files[0].name;
      $("#activityUploadBx").attr("placeholder", fileName);
      this.setState({ uploadResponse: false });
    }
  }

  render() {
    return (
      <Container
        fluid
        className="ml-0 mb-1"
        style={{ marginTop: "3%", width: "100%" }}
      >
        <Row
          className="rounded mb-0 border border-light"
          style={{ backgroundColor: "white" }}
        >
          <Col md="12">
            <Row>
              <Col md="12" className="d-flex justify-content-center mb-3 mt-1">
                <h5>{I18n.t("TopNav.MasterDataUpload")}</h5>
              </Col>
            </Row>
            <Container
              fluid
              className="ml-0 mb-2 pb-0"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <Row>
                <span className="sectionLabel">Upload Dealer Network</span>
              </Row>
              <Row className="mt-2 mb-2">
                <Col md="4" className="pr-0">
                  <input
                    id="networkUploadBx"
                    ref={this.networkUploadBxRef}
                    type="text"
                    className="form-control"
                    placeholder="Select File to Upload"
                    readOnly
                  />
                </Col>
                <Col md="1" className="ml-0 pl-0">
                  <label className="input-group-btn">
                    <div
                      className="btn btn-sm mt-0"
                      style={{ width: "75%", backgroundColor: "#6c757d" }}
                    >
                      <span
                        style={{ display: "inline-flex", marginLeft: "-10px" }}
                      >
                        <Fa icon="folder-open" />
                        &nbsp;&nbsp;Browse&hellip;
                      </span>
                      <input
                        type="file"
                        onChange={e => {
                          this.handleNetworkFileChange(e.target);
                        }}
                        ref={this.networkUploadExcelRef}
                        accept={".xlsx,.xls"}
                        style={{
                          display: "none"
                        }}
                      />
                    </div>
                  </label>
                </Col>
                <Col md="7">
                  <button
                    className={"button-" + this.props.theme.name}
                    disabled={this.state.uploadResponse}
                    style={{ float: "right" }}
                    onClick={e => this.uploadNetworkFile(e)}
                  >
                    Upload
                  </button>
                </Col>
              </Row>
            </Container>
            <br />
            <Container
              fluid
              className="ml-0 mb-3 pb-0"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <Row>
                <span className="sectionLabel">Upload Questionnaire</span>
              </Row>
              <Row className="mt-2">
                <Col md="4">
                  <select
                    className="browser-default custom-select"
                    value={this.state.toolbox}
                    onChange={e => {
                      this.changeToolbox(e);
                    }}
                  >
                    <option>Select Toolbox</option>
                    <option value="FOUNDATION">Foundation</option>
                    <option value="ACCESSORIES">Accessories</option>
                    <option value="WALK AROUND CHECK">Walk Around</option>
                    <option value="MAINTENANCE REMINDERS">
                      Maintenance Reminder
                    </option>
                    <option value="FIX IT RIGHT">Fix it Right</option>
                    <option value="EFFICIENCY">Efficiency</option>
                    <option value="DPOK">DPOK</option>
                  </select>
                </Col>
                <Col md="4">
                  <select
                    className="browser-default custom-select"
                    value={this.state.nmsc}
                    onChange={e => {
                      this.changeNMSC(e);
                    }}
                  >
                    <option>Select NMSC</option>
                    <option value="L&P">L&P</option>
                    <option value="TAD">TAD</option>
                    <option value="TAF">TAF</option>
                    <option value="TAG">TAG</option>
                    <option value="TBAL">TBAL</option>
                    <option value="TBEL">TBEL</option>
                    <option value="THEL">THEL</option>
                    <option value="TBL">TBL</option>
                    <option value="TCA">TCA</option>
                    <option value="TCAP">TCAP</option>
                    <option value="TCE">TCE</option>
                    <option value="TCY">TCY</option>
                    <option value="TDG">TDG</option>
                    <option value="TDK">TDK</option>
                    <option value="TES">TES</option>
                    <option value="TFAG">TFAG</option>
                    <option value="TFR">TFR</option>
                    <option value="TGB">TGB</option>
                    <option value="TICE">TICE</option>
                    <option value="TIRL">TIRL</option>
                    <option value="TMI">TMI</option>
                    <option value="TMKZ">TMKZ</option>
                    <option value="TMPL">TMPL</option>
                    <option value="TMR">TMR</option>
                    <option value="TNO">TNO</option>
                    <option value="TRO">TRO</option>
                    <option value="TSW">TSW</option>
                    <option value="TTMS">TTMS</option>
                    <option value="TUK">TUK</option>
                    <option value="UML">UML</option>
                  </select>
                </Col>
                <Col md="4">
                  <select
                    className="browser-default custom-select"
                    value={this.state.country}
                    onChange={e => {
                      this.changeCountry(e);
                    }}
                  >
                    <option>Select Country</option>
                    <option value="Albania">Albania</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belorussia">Belorussia</option>
                    <option value="Bosnia">Bosnia</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Greece">Greece</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kosovo">Kosovo</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Montenegro">Montenegro</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Netherlands">Netherlands</option>
                    <option value="Norway">Norway</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Serbia">Serbia</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Spain">Spain</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Turkey">Turkey</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                  </select>
                </Col>
              </Row>
              <Row className="mt-2">
                <Col md="4">
                  <select
                    className="browser-default custom-select"
                    value={this.state.lang}
                    onChange={e => {
                      this.changeLang(e);
                    }}
                  >
                    <option>Select Language</option>
                    <option value="BG">Bulgarian (BG)</option>
                    <option value="BS">Bosnian (BS)</option>
                    <option value="CRN">Montenegrin (CRN)</option>
                    <option value="CS">Czech (CS)</option>
                    <option value="DA">Danish (DA)</option>
                    <option value="DE">German (DE)</option>
                    <option value="EN">English (EN)</option>
                    <option value="ES">Spainish (ES)</option>
                    <option value="ET">Estonian (ET)</option>
                    <option value="FI">Finnish (FI)</option>
                    <option value="FR">French (FR)</option>
                    <option value="HB">Hebrew (HB)</option>
                    <option value="HR">Croatian (HR)</option>
                    <option value="HU">Hungarian (HU)</option>
                    <option value="IS">Icelandic (IS)</option>
                    <option value="IT">Italian (IT)</option>
                    <option value="LT">Lithuanian (LT)</option>
                    <option value="LV">Latvian (LV)</option>
                    <option value="MK">Macedonian (MK)</option>
                    <option value="NL">Dutch (NL)</option>
                    <option value="NO">Norwegian (NO)</option>
                    <option value="PL">Polish (PL)</option>
                    <option value="PT">Portuguese (PT)</option>
                    <option value="RO">Romanian (RO)</option>
                    <option value="RU">Russian (RU)</option>
                    <option value="SK">Slovak (SK)</option>
                    <option value="SL">Slovenian (SL) </option>
                    <option value="SQ">Albanian (SQ)</option>
                    <option value="SR">Serbian (SR)</option>
                    <option value="SV">Swedish (SV)</option>
                    <option value="TR">Turkish (TR)</option>
                    <option value="UK">Ukrainian (UK)</option>
                  </select>
                </Col>
                <Col md="3" className="pr-0">
                  <input
                    id="questionUploadBx"
                    ref={this.questionUploadBxRef}
                    type="text"
                    className="form-control"
                    placeholder="Select File to Upload"
                    readOnly
                  />
                </Col>
                <Col md="1" className="ml-0 pl-0">
                  <label className="input-group-btn">
                    <div
                      className="btn btn-sm mt-0"
                      style={{ width: "75%", backgroundColor: "#6c757d" }}
                    >
                      <span
                        style={{ display: "inline-flex", marginLeft: "-10px" }}
                      >
                        <Fa icon="folder-open" />
                        &nbsp;&nbsp;Browse&hellip;
                      </span>
                      <input
                        type="file"
                        onChange={e => {
                          this.handleQuestionsFileChange(e.target);
                        }}
                        ref={this.questionUploadExcelRef}
                        accept={".doc,.docx"}
                        style={{
                          display: "none"
                        }}
                      />
                    </div>
                  </label>
                </Col>
                <Col md="4">
                  <div style={{ float: "right" }}>
                    <button
                      className={"button-" + this.props.theme.name}
                      disabled={this.state.uploadResponse}
                      onClick={e => this.uploadQuestionsFile(e)}
                    >
                      Delete
                  </button>
                    <button
                      className={"button-" + this.props.theme.name}
                      disabled={this.state.uploadResponse}
                      onClick={e => this.uploadQuestionsFile(e)}
                    >
                      Upload
                  </button></div>
                </Col>
              </Row>
            </Container>
            <br />

            <Container
              fluid
              className="ml-0 mb-2 pb-0"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <Row>
                <span className="sectionLabel">Upload User Details</span>
              </Row>
              <Row className="mt-2 mb-2">
                <Col md="4" className="pr-0">
                  <input
                    id="userUploadBx"
                    ref={this.userUploadBxRef}
                    type="text"
                    className="form-control"
                    placeholder="Select File to Upload"
                    readOnly
                  />
                </Col>
                <Col md="1" className="ml-0 pl-0">
                  <label className="input-group-btn">
                    <div
                      className="btn btn-sm mt-0"
                      style={{ width: "75%", backgroundColor: "#6c757d" }}
                    >
                      <span
                        style={{ display: "inline-flex", marginLeft: "-10px" }}
                      >
                        <Fa icon="folder-open" />
                        &nbsp;&nbsp;Browse&hellip;
                      </span>
                      <input
                        type="file"
                        onChange={e => {
                          this.handleUserDetailsFileChange(e.target);
                        }}
                        ref={this.userUploadExcelRef}
                        accept={".xlsx,.xls"}
                        style={{
                          display: "none"
                        }}
                      />
                    </div>
                  </label>
                </Col>
                <Col md="7">
                  <button
                    className={"button-" + this.props.theme.name}
                    disabled={this.state.uploadResponse}
                    style={{ float: "right" }}
                    onClick={e => this.uploadUserFile(e)}
                  >
                    Upload
                  </button>
                </Col>
              </Row>
            </Container>
            <br />
            <Container
              fluid
              className="ml-0 mb-2 pb-0"
              style={{ border: "1px solid grey", borderRadius: "10px" }}
            >
              <Row>
                <span className="sectionLabel">Upload Activities</span>
              </Row>
              <Row className="mt-2 mb-2">
                <Col md="4" className="pr-0">
                  <input
                    id="activityUploadBx"
                    ref={this.activityUploadBxRef}
                    type="text"
                    className="form-control"
                    placeholder="Select File to Upload"
                    readOnly
                  />
                </Col>
                <Col md="1" className="ml-0 pl-0">
                  <label className="input-group-btn">
                    <div
                      className="btn btn-sm mt-0"
                      style={{ width: "75%", backgroundColor: "#6c757d" }}
                    >
                      <span
                        style={{ display: "inline-flex", marginLeft: "-10px" }}
                      >
                        <Fa icon="folder-open" />
                        &nbsp;&nbsp;Browse&hellip;
                      </span>
                      <input
                        type="file"
                        onChange={e => {
                          this.handleActivityFileChange(e.target);
                        }}
                        ref={this.activityUploadExcelRef}
                        accept={".xlsx,.xls"}
                        style={{
                          display: "none"
                        }}
                      />
                    </div>
                  </label>
                </Col>
                <Col md="7">
                  <button
                    className={"button-" + this.props.theme.name}
                    disabled={this.state.uploadResponse}
                    style={{ float: "right" }}
                    onClick={e => this.uploadActivityFile(e)}
                  >
                    Upload
                  </button>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.loginReducer,
    blocking: state.loginReducer,
    router: state.routing,
    uploadResponse: state.uploadReducer
  };
};
export default withRouter(
  connect(mapStateToProps)(withTheme(MasterDataUpload))
);
