import React from 'react';
import { connect } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { withTheme } from '../../themes/theming';
import { push } from "connected-react-router";
import { withRouter } from 'react-router-dom';
import ActionPlanModal from '../actionPlanModal';
import QuestionInfo from '../questionInfo';
import UploadAttachment from "./uploadAttachment"
import { Fa } from "mdbreact";
import { fetchActionPlans, searchActivity, downloadActionPlanDtls, deleteActionPlan, downloadActionPlanSummary, fetchSubQueInfo, fetchQueInfo, launchKaizen, reAuditReEvaluate } from '../../actions/activityAction.jsx';
import './index.css';
import { confirmAlert } from "react-confirm-alert"; // Import
import ClampLines from 'react-clamp-lines';
import * as AppConst from '../../AppConstant';
import moment from "moment";
import $ from 'jquery';
var I18n = require('react-redux-i18n').I18n;

class ActionPlanTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actionPlanList: [],
            actionPlanDto: {
                actionPlanId: '',
                description: '',
                activityId: '',
                quesModal: false
            },
            type: "",
            toggleAttachment: false,
            selectedQuestionId: this.props.selectedNodeId,
        };
    }
    componentWillMount() {
        if (this.props.activity !== undefined && this.props.activity.activityId !== undefined && this.props.activity.activityId !== 0) {
            this.props.dispatch(fetchActionPlans(this.props.activity.activityId));
            if (this.props.user.userMdl.activeProgram !== "BRiT" && this.props.user.userMdl.activeProgram !== "EA") {
                this.props.dispatch(fetchSubQueInfo(this.props.activity.activityId));

            }
            else if (this.props.user.userMdl.activeProgram === AppConst.activeProgramNas) {
                this.props.dispatch(fetchQueInfo(this.props.activity.activityId));
            }
            // this.props.dispatch(fetchSubQueInfoForActionPlanId(this.props.activity.activityId))
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.actionPlanList && nextProps.actionPlanRefreshRequired && nextProps.actionPlanList.constructor === Array) {
            this.setState({ actionPlanList: nextProps.actionPlanList });
            this.props.dispatch({
                type: "ACTION_PLAN_REFRESH_DONE",
                payload: false
            })
        }
    }
    componentDidUpdate() {
        if (this.props.activity.status !== undefined
            && (this.props.activity.status === 'Closed' || this.props.activity.status === 'Rejected' || this.props.activity.status === 'Completed')) {
            $('.controlledIcon').hide();
        } else {
            let accessControlList = this.props.user.userMdl.accessControl;
            $('.controlledIcon').each(function () {
                if (!this.classList.contains('hideIcon')) {
                    $(this).show();
                } else {
                    if ($(this).children().length > 0) {
                        // Validate if icon need to be shown.
                        let accessType = accessControlList.filter(obj => obj.function === 'ACTIVITY')[0] ?
                            accessControlList.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
                        let status = $(this).parent().parent().parent().siblings()[3].innerText;
                        let startDate = $(this).parent().parent().parent().siblings()[4].innerText;
                        let dateObj = startDate.split("/");
                        dateObj = new Date(dateObj[2], dateObj[1] - 1, dateObj[0]);
                        let element = 'delete';
                        if ($(this).children()[0].classList.contains('edit-icon')) {
                            element = 'edit';
                        }
                        // check for Edit Icon.
                        if (element === 'edit' && accessType === "R/W" && (status === 'Open' || status === 'Closed')) {
                            $(this).show();
                        }
                        // check for Delete Icon.
                        if (element === 'delete' && accessType === "R/W" && status === 'Open' && dateObj > new Date()) {
                            $(this).show();
                        }
                    }
                }
            });
        }
    }
    counterMeasureFormatter = (cell, row) => {
        return (
            <ClampLines
                text={cell}
                lines={2}
                ellipsis="..."
                buttons={false}
                className="custom-class"
            />
        );
    }

    toggleAttachment = () => {
        this.setState({ toggleAttachment: !this.state.toggleAttachment });
    }
    buttonFormatter = (cell, row, rowIndex, formatExtraData) => {
        let accessType = formatExtraData.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0] ? formatExtraData.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
        var startDate = row.startDate.split("/");
        startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);
    
        const activityStatus = formatExtraData.activity?.status || formatExtraData.selectedActivity?.status;
    
        const editIconClass = (accessType === "R/W" && row && (row.status === 'Open' || row.status === 'Closed')
            && activityStatus !== "Completed"
            && activityStatus !== "Rejected"
            && activityStatus !== "Closed") ? "controlledIcon" : "controlledIcon hideIcon";
    
        const deleteIconClass = (accessType === "R/W" && row.status === 'Open' && startDate > new Date()
            && activityStatus !== "Completed"
            && activityStatus !== "Rejected"
            && activityStatus !== "Closed") ? "controlledIcon" : "controlledIcon hideIcon";
    
        // warning sign to highlight the Action items which are overdue. 
        var dueDate = row.endDate.split("/");
        dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);
        let today = moment(new Date()).format('DD/MM/YYYY');
        var todayDate = today.split("/");
        todayDate = new Date(todayDate[2], todayDate[1] - 1, todayDate[0]);
    
        const warningIconClass =
            row.status === "Open" &&
            todayDate > dueDate &&
            this.props.activity?.status !== undefined &&
            (this.props.activity.status === "Ongoing" ||
                this.props.activity.status === "Closed")
                ? "controlledIcon1"
                : "hideIcon1";
    
        return (
            <div>
                <div className="btn-toolbar float-right">
                    <a className={editIconClass} onClick={() => !formatExtraData.disableButton && this.toggleAttachment()} >
                        <Fa icon="folder-open" fixed size="lg" title={I18n.t('library.upload')} />
                    </a>
                    <a onClick={() => !formatExtraData.disableButton && this.downloadAPDtls(row.actionPlanId)}>
                        <div title={I18n.t('tmeAdminMaster.download')} className="download-icon" />
                    </a>
                    <a className={editIconClass} onClick={() => !formatExtraData.disableButton && this.toggleDownload('Edit')}>
                        <div title={I18n.t('tmeAdminMaster.edit')} className="edit-icon" />
                    </a>
                    <a className={deleteIconClass} onClick={() => !formatExtraData.disableButton && this.deleteActionPlan(row)}>
                        <div title={I18n.t('tmeAdminMaster.delete')} className="delete-icon" />
                    </a>
                    {/* warning sign to highlight the Action items which are overdue.  */}
                    <a className={warningIconClass}>
                        <div title={I18n.t('tmeAdminMaster.dueDateExceed')} className="warning-icon" />
                    </a>
                </div>
                {/* warning sign to highlight the Action items which are overdue.  */}
                {/* {(this.props.activity.status !== undefined && this.props.activity.status === 'Ongoing' && row.status  === 'Open' && todayDate > dueDate) ?                    
                    <div id="div2" style={{ padding:"25px 0px 0px 60px", fontSize:"xx-small", color:"red"}}> {I18n.t('tmeAdminMaster.dueDateExceed')}</div>
                : ''}                */}
            </div>
        );
    }



    statusFormatter(cell, row) {
        return (
            <div>
                <button
                    variant="contained"
                    style={{
                        backgroundColor:
                            row.status === "Open"
                                ? "#cf142b"
                                : row.status === "Closed"
                                    ? "#3CB043"
                                    : row.status === "Completed"
                                        ? "#3CB043"
                                        : "primary",
                        lineHeight: 1,
                        color: "white",
                        textAlign: "center",
                        fontSize: "0.675rem",
                        //  display: 'inherit',
                    }}
                    disabled
                >
                    {I18n.t("others." + row.status.toLowerCase()) || "Primary"}
                </button>
            </div>
        );
    }
    dueDateFormatter(cell, row, rowIndex, formatExtraData) {
        var dueDate = row.endDate.split("/");
        dueDate = new Date(dueDate[2], dueDate[1] - 1, dueDate[0]);
        let today = moment(new Date()).format('DD/MM/YYYY');
        var todayDate = today.split("/");
        todayDate = new Date(todayDate[2], todayDate[1] - 1, todayDate[0]);
        const color =
            row.status === "Open" && todayDate > dueDate &&
                formatExtraData.activity.status &&
                (formatExtraData.activity.status === "Ongoing" || formatExtraData.activity.status === "Closed")
                ? "red" : "";
        return (
            <span style={{ color: color }}>
                {row.endDate}
            </span>
        );
    }
    deleteActionPlan = (row) => {
        // var dateToday=new Date();
        // console.log(dateToday);
        // console.log(row.startDate);
        // // row.startDate=fvDate;
        // // console.log(fvDate);
        // var startDate = row.startDate.split("/");
        // row.startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);
        //         if (row.startDate <= new Date() && row.status === 'Open') {
        //             toastr.error("Cannot Delete as StartDate cannot be before Today's date");
        //         }
        //         else{
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t("standardMessages.delete")}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button className={"buttonCancel-" + this.props.theme.name} onClick={() => onClose()}>
                                {I18n.t("standardMessages.no")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.props.dispatch(deleteActionPlan(row.actionPlanId, row.activityId));
                                    onClose();
                                }}>
                                {I18n.t("standardMessages.yes")}
                            </button>
                        </div>
                    </div>
                );
            }
        });
        //}






    }
    downloadAPDtls = (id) => {
        this.props.dispatch(downloadActionPlanDtls(id, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.theme.name, this.props.user.userMdl.activeProgram, this.props.activity.activityId));
    }
    downloadAPSummary = (id) => {
        this.props.dispatch(downloadActionPlanSummary(id, this.props.user.userMdl.lang, this.props.user.userMdl.nmsc, this.props.theme.name, this.props.user.userMdl.activeProgram));
    }
    headerAdd = (cell, row) => {
        let accessType = this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0] ? this.props.user.userMdl.accessControl.filter(obj => obj.function === 'ACTIVITY')[0].accessType : null;
        if (accessType === "R/W" && this.props.activity && this.props.activity.status && (this.props.activity.status !== "Completed") && (this.props.activity.status) !== "Rejected" && (this.props.activity.status) !== "Closed") {
            return (
                <div>
                    <a onClick={() => this.toggleDownload('Create')}><div title={I18n.t('tmeAdminMaster.add')} className="add-icon" /></a>
                </div>
            )
        } else {
            return (
                <div>
                    <a><div title={I18n.t('standardMessages.addActionPlanNotAllowed')} className="add-icon-disabled" /></a>
                </div>
            );
        }
    }
    toggleQuesInfo = action => {
        let QuesList = this.props.subQuesInfo
        let quesInfo = []
        quesInfo = action.map(function (x, i) {

            if (QuesList[x]) {
                return { ques: x, info: QuesList[x].subQuesDescription }
            } else {
                return { ques: x, info: "" }


            }
        })

        this.setState({
            quesModal: !this.state.quesModal,
            modalFor: quesInfo

        });
    }


    toggleDownload = action => {
        this.setState({
            modalAction: !this.state.modalAction,
            modalFor: action
        });
        if (action === "Create") {
            const selectedRow = { ...this.state.selectedRow };
            selectedRow["activityId"] = this.props.activity.activityId;
            selectedRow["actionPlanId"] = 0;
            selectedRow["actionPlanDisplayId"] = 0;
            selectedRow["description"] = "";
            selectedRow["counterMeasure"] = "";
            selectedRow["questionReference"] = "";
            selectedRow["userId"] = "";
            selectedRow["status"] = "Open";
            selectedRow["followUp"] = "";
            selectedRow["assignee"] = "";
            selectedRow["endDate"] = "";
            selectedRow["startDate"] = "";
            selectedRow["userMdl"] = this.props.user.userMdl;
            selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
            selectedRow["langCode"] = this.props.user.userMdl.lang;
            selectedRow["userId"] = this.props.user.userMdl.username;
            this.setState({ selectedRow });
        }

    }
    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    }
    backToActivity = () => {
        if (this.props.searchCriteria.program) {
            this.props.dispatch(searchActivity(this.props.searchCriteria));
            this.props.dispatch(
                push({
                    pathname: "/activity",
                    query: { activityDto: this.props.searchCriteria }
                })
            );
        }
        else {
            this.props.dispatch(
                push({ pathname: "/certification" })
            );
        }

    }
    quesRefFormatter = (cell, row) => {
        let questionRef = cell.split(",")
        questionRef = questionRef.map(x => x.trim())
        return (
            <div className="btn-toolbar  queslink" style={{ display: 'flex', textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }} onClick={() => this.toggleQuesInfo(questionRef)}>
                <u> {cell}</u>
            </div>
        );

    }
    isCriticalFormatter = (cell, row) => {
        return row.isCritical ? "Yes" : "No";
    };

   
      requestReAudit = () => {
        confirmAlert({
            customUI: ({ title, message, onClose }) => {
                return (
                    <div className="deleteConfirmBox">
                        <div className={"titleClass-" + this.props.theme.name}>
                            {I18n.t("standardMessages.firstAuditComplete")}
                        </div>
                        <div className="modal-footer" style={{ borderTop: "0px" }}>
                            <button
                                className={"buttonCancel-" + this.props.theme.name}
                                onClick={() => onClose()}
                            >
                                {I18n.t("standardMessages.goBack")}
                            </button>
                            <button
                                className={"button-" + this.props.theme.name}
                                onClick={() => {
                                    this.submitRequestReAudit();
                                    onClose();
                                }}
                            >
                                {I18n.t("standardMessages.confirm")}
                            </button>
                        </div>
                    </div>
                );
            }
        });

    }
    
    submitRequestReAudit = (e) => {
        let bodyForReAudit = {
          activityId: this.props.activity.activityId,
          loginId: this.props.user.userMdl.username,
        }
        this.props.dispatch(reAuditReEvaluate(bodyForReAudit));
      };



    disableReAuditButton() {
        // Check if all actions in actionPlanList are closed
        return !((this.state.actionPlanList).every(action => action.status === "Closed"));
    }

    render() {
        let isBrit = (this.props.user.userMdl.activeProgram === "BRiT" || this.props.user.userMdl.activeProgram === "EA") ? true : false

        // Disable Add Action Plan buttons and Request Re-Audit button if Kaizen section is completed
        const disableButton = this.props.activityDetailsData && (this.props.activityDetailsData.kaizen === "COMPLETED");

        const columns = [{
            dataField: 'actionPlanDisplayId',
            text: I18n.t('Table.id'),
            sort: true,
            style: { width: "5%", textAlign: "center" },
            headerStyle: { width: "5%", textAlign: "center", paddingLeft: "0px" }
        }, {
            dataField: 'counterMeasure',
            text: I18n.t('Table.counterMeasure'),
            title: I18n.t('Table.counterMeasure'),
            formatter: this.counterMeasureFormatter,
            style: { width: "21.5%", textAlign: "left", wordWrap: "break-word" },
            headerStyle: { width: "22%", textAlign: "left" }
        }, {
            dataField: 'questionReference',
            text: isBrit ? I18n.t('Table.kpi') : I18n.t('Table.questionReference'),
            title: isBrit ? I18n.t('Table.kpi') : I18n.t('Table.questionReference'),
            formatter: isBrit ? null : this.quesRefFormatter,
            style: { width: "10.5%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "10.3%", textAlign: "left" }
        }, {
            dataField: 'isCritical',
            text: I18n.t('questionnaire.subQuestionTable.isCritical'),
            sort: true,
            formatter: this.isCriticalFormatter,
            style: { width: "9%", textAlign: "center" },
            headerStyle: { width: "8%", textAlign: "center" },
        }, {
            dataField: 'status',
            text: I18n.t('Table.status'),
            sort: true,
            style: { width: "7%", textAlign: "center" },
            headerStyle: { width: "7%", textAlign: "center" },
            formatter: this.statusFormatter,
        }, {
            dataField: 'startDate',
            text: I18n.t('Table.startDate'),
            sort: true,
            style: { width: "8.5%", textAlign: "center" },
            headerStyle: { width: "8.5%", textAlign: "center" }
        }, {
            dataField: 'endDate',
            text: I18n.t('Table.dueDate'),
            sort: true,
            formatter: this.dueDateFormatter,
            formatExtraData: this.props,
            style: { width: "11%", textAlign: "center" },
            headerStyle: { width: "11%", textAlign: "center" }
        }, {
            dataField: 'assignee',
            text: I18n.t('Table.assignedTo'),
            title: I18n.t('Table.assignedTo'),
            sort: true,
            style: { width: "10.8%", textAlign: "left", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" },
            headerStyle: { width: "11%", textAlign: "left" }
        }, {
            // dataField: "actionPlanDisplayId",
            dataField: "",

            text: "",
            isDummyField: 'formatter',
            headerFormatter: disableButton ? null : this.headerAdd,
            formatter: this.buttonFormatter,
            formatExtraData: {...this.props, disableButton},
            style: { width: "15%" },
            headerStyle: { width: "11%" }
        }];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                const selectedRow = { ...this.state.selectedRow };
                selectedRow["activityId"] = row.activityId;
                selectedRow["actionPlanId"] = row.actionPlanId;
                selectedRow["actionPlanDisplayId"] = row.actionPlanDisplayId;
                selectedRow["description"] = row.description;
                selectedRow["counterMeasure"] = row.counterMeasure;
                selectedRow["questionReference"] = row.questionReference;
                selectedRow["userId"] = row.userId;
                selectedRow["status"] = row.status;
                selectedRow["followUp"] = row.followUp;
                selectedRow["assignee"] = row.assignee;
                selectedRow["endDate"] = row.endDate;
                selectedRow["startDate"] = row.startDate;
                selectedRow["userMdl"] = this.props.user.userMdl;
                selectedRow["nmscCode"] = this.props.user.userMdl.nmsc;
                selectedRow["langCode"] = this.props.user.userMdl.lang;
                selectedRow["userId"] = this.props.user.userMdl.username;
                this.setState({ selectedRow });
            }
        };

        return (
            <div>
                <div className="col-md-12 row">
                    {/*if parentComponent is KaizenSection then launch kaizen is req before showin table , for other section its independent of launch kaizen*/}
                     {this.props.isLaunched ||
                    //  (localStorage.getItem(`kaizenLaunched_${this.props.activityId}`) === "true")  && 
                      this.props.parentComponent === "KaizenSection" || this.props.parentComponent !== "KaizenSection"?
                        <div 
                       // className="col-md-12 actionPlanTable">
                        className={ `col-md-12 actionPlanTable ${
                            (this.props.activityDetailsData.kaizen === "COMPLETED" || this.props.activityDetailsData.kaizen === "REJECTED")
                                ? "disable-pointer-events" : ""
                          }`}>
                            <BootstrapTable
                                keyField='actionPlanId'
                                noDataIndication={I18n.t('tabsDetails.noActionAvailable')}
                                data={this.state.actionPlanList}
                                columns={columns}
                                hover
                                condensed
                                rowEvents={rowEvents}
                            />
                        </div>
                    : null}
                </div>
                <hr></hr>
                {this.state.actionPlanList.length > 0 ?
                    <div className="col-md-12 modal-footer" style={{ borderTop: '0px' }}>


                        <div className="col-md-4 redLine " style={{ textAlign: "left", margin: 'unset' }}>
                            <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />

                        </div>

                        <div className="col-md-4" style={{ textAlign: 'left', margin: 'unset' }}>
                            {this.props?.user?.userMdl?.activeProgramName === "Network Assessment" && (this.props.user.userMdl.roles.includes("KET NMSC Admin") || this.props.user.userMdl.roles.includes("KET Dealer Champion")) && (
                                <button
                                    onClick={() => this.requestReAudit()}
                                    className={"button-" + this.props.theme.name}
                                    disabled={this.disableReAuditButton() || disableButton}>
                                    {"Request Re-Audit"}
                                </button>
                            )}
                        </div>

                        <div className="col-md-4" style={{ textAlign: 'right', margin: 'unset' }}>
                            <button onClick={() => this.downloadAPSummary(this.props.activity.activityId)} className={"button-" + this.props.theme.name} >{I18n.t('tmeAdminMaster.download')}</button>
                        </div>

                    </div>
                    : <div className="col-md-12 redLine " style={{ textAlign: "left", borderTop: '0px' }}>
                        <img src={'images/back-' + this.props.theme.name + '.png'} alt="Back" onClick={this.backToActivity} style={{ cursor: "pointer" }} />

                    </div>
                }
                {this.state.modalAction === true ?
                    <ActionPlanModal
                        modal={this.state.modalAction}
                        toggle={this.toggleDownload}
                        modalFor={this.state.modalFor}
                        selectedRow={this.state.selectedRow}
                        activity={this.props.activity}
                    /> : null}

                {this.state.quesModal === true ?
                    <QuestionInfo
                        modal={this.state.quesModal}
                        toggle={this.toggleQuesInfo}
                        modalFor={this.state.modalFor}

                    /> : null}
                {this.state.toggleAttachment ?
                    <UploadAttachment
                        activityId={this.props.activity.activityId}
                        selectedRow={this.state.selectedRow}
                        subQuesInfo={this.props.subQuesInfo}
                        toolbox={this.props.toolbox}
                        toggleAttachment={this.toggleAttachment}
                    />
                    : ""}
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        actionPlanList: state.activityReducer.actionPlanList,
        actionPlanRefreshRequired: state.activityReducer.actionPlanRefreshRequired,
        subQuesInfo: state.activityReducer.subQuesInfo,
        kaizen: state.activityReducer.kaizen,
        activityDetailsData: state.evaluationReducer.activityDetails,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(ActionPlanTable)));