import React from "react";
import 'react-multi-carousel/lib/styles.css';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { fetchFilters, filtersDashboard } from '../../actions/dashboardAction';
import { withTheme } from '../../themes/theming';
import './index.css';
import Select from "react-select";
import $ from 'jquery';
var I18n = require("react-redux-i18n").I18n;

class SearchFilter extends React.Component {
    /* eslint-disable */
    constructor(props) {
        super(props);
        this.state = {
            nmscList: [],
            brandList: [],
            countryList: [],
            regionList: [],
            selectedNmsc: [],
            selectedBrand: [],
            selectedCountry:[],
            userMdl: {},
            // activityDto: {
            //     nmscList: null,
            //     loggedInUser: this.props.user.userMdl.username,
            //     brand: null,
            //     country: null,
            //     region: null
            // },
            filterData: {},
            defaultAllObj: [{ "value": "All", "label": I18n.t('standardMessages.selectAll') },{ "value": "None", "label": I18n.t('standardMessages.unSelectAll') }],
        };
    }

    componentWillMount() {

        let activityDto = {}
        if (this.props.userMdl.activeProgram === 'TSM') {
            activityDto = { ...this.props.TsmActivityDto }
        }
        if (this.props.userMdl.activeProgram === 'TSM_BNP') {
            activityDto = { ...this.props.BnpActivityDto }
        }
        if (this.props.userMdl.activeProgram === 'TSM_USED_CAR') {
            activityDto = { ...this.props.UsedcarActivityDto }
        }
        if (this.props.userMdl.activeProgram === 'TSM_SURVEYS') {
            activityDto = { ...this.props.tmeSurveysActivityDto }
        }
        activityDto["loggedInUser"] = this.props.user.userMdl.username;
        activityDto["businessArea"] = this.props.user.userMdl.businessAreaKey;
        this.props.dispatch(fetchFilters(activityDto, this.props.userMdl.activeProgram));
        this.setState({ activityDto: activityDto })


    }
    componentDidMount() {
        let filterProps = null

        if (this.props.userMdl.activeProgram === 'TSM') {
            filterProps = this.props.Tsmfilters
        }
        if (this.props.userMdl.activeProgram === 'TSM_BNP') {
            filterProps = this.props.Bnpfilters

        }
        if (this.props.userMdl.activeProgram === 'TSM_USED_CAR') {
            filterProps = this.props.Usedcarfilters
        }
        if (this.props.userMdl.activeProgram === 'TSM_SURVEYS') {
            filterProps = this.props.tmeSurveysfilters
        }
        if (filterProps) {
            if (filterProps.selectedNmsc && filterProps.selectedNmsc.length > 0) {
                this.setSelectedValue("nmscCodeList", "selectedNmsc", filterProps.selectedNmsc, false);
            }
            if (filterProps.selectedBrand && filterProps.selectedBrand.length > 0) {
                this.setSelectedValue("brandList", "selectedBrand", filterProps.selectedBrand, false);
            }
            if (filterProps.selectedCountry && filterProps.selectedCountry.length > 0) {
                this.setSelectedValue("countryList", "selectedCountry", filterProps.selectedCountry, false);
            }
            if (filterProps.selectedRegion && filterProps.selectedRegion.length > 0) {
                this.setSelectedValue("regionList", "selectedRegion", filterProps.selectedRegion, false);
            }
        }

    }
    componentWillUnmount() {

    }

    getSelectedList(selectedList) {
        let filteredList = [];
        if (selectedList !== undefined) {
            selectedList.map(value => {
                let obj = {
                    label: I18n.t("status." + value.toLowerCase()),
                    value: value,
                    selected: 'selected'
                };
                filteredList.push(obj);
            });
        }
        return filteredList;
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.flag) {
            $("#searchId").show();
            $("#hrId").hide();
        } else {
            $("#searchId").hide();
            $("#hrId").show();
        }

        let roles = [];

        roles = nextProps.user.userMdl.roles;
        if (nextProps.user.userMdl.roles && nextProps.user.userMdl.activeProgram !== this.state.userMdl.activeProgram) {
            for (var i = 0; i < roles.length; i++) {
                if (roles[i].toUpperCase() === "KET NMSC ADMIN" || roles[i].toUpperCase() === "KET NMSC USER" || roles[i].toUpperCase() === "KET NMSC EXTERNAL" || roles[i].toUpperCase() === "KET NMSC FIELD") {
                    this.setState({ nmscFlag: true });
                    break;
                }
            }
            this.setState({ userMdl: nextProps.user.userMdl });
        }


        if (nextProps.filters.nmscCode && (nextProps.filters.nmscCode.length !== this.state.nmscList.length
            || nextProps.filters.l1.length !== this.state.brandList.length
            || nextProps.filters.l2.length !== this.state.countryList.length
            || nextProps.filters.l3.length !== this.state.regionList.length)) {
            this.setState({
                nmscList:  this.props.nmscFlag ? nextProps.filters.nmscCode : this.state.defaultAllObj.concat(nextProps.filters.nmscCode),
                brandList:  nextProps.filters.l1,
                countryList: this.state.defaultAllObj.concat( nextProps.filters.l2),
                regionList: nextProps.filters.l3
            });

        }
    }

    setSelectedValue(key1, key2, list, isReloadReq) {
        var valueOptions = [];
        list.map(function (item) {
            valueOptions.push(item.value);
        });
        let activityDto = { ...this.state.activityDto };
        activityDto[key1] = valueOptions;
        // console.log(activityDto)

        this.setState({ activityDto, [key2]: list });

        if (isReloadReq) {
            this.reloadDropDowns(activityDto);
        }

    }

    onNmscChange = (selectedOption,event) => {
        console.log("selectedOption",selectedOption)
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedNmsc: this.state.nmscList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedNmsc: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedNmsc: [] })
            }else{
                this.setState({  selectedNmsc: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.nmscList.length - 1) {
            this.setState({ selectedNmsc : this.state.nmscList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }        
            })      
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedNmsc: selectedOpt})            
        }  
        const activityDto = { ...this.state.activityDto };
        activityDto["nmscCodeList"] = valueOptions;
        this.setState({ activityDto });
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(activityDto);
        }        
        // this.setSelectedValue("nmscList", "selectedNmsc", selectedOption, true);
    }

    onBrandChange = (selectedOption) => {
        this.setSelectedValue("brandList", "selectedBrand", selectedOption, true);
    }

    onCountryChange = (selectedOption,event) => {
        var valueOptions = [];
        if(event.action === "select-option" && event.option.value === "All"){  
            this.setState({ selectedCountry: this.state.countryList})  
        }else if(event.action === "select-option" && event.option.value === "None"){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option" && (event.option.value === "None" || event.option.value === "All")){
            this.setState({ selectedCountry: [] })
        }else if(event.action === "deselect-option") {            
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                }
            })
            if (selectedOption.length === 1 && selectedOption[0].value === "None") {
                this.setState({ selectedCountry: [] })
            }else{
                this.setState({  selectedCountry: selectedOption.filter(o => o.value !== "All") })
            }
        }
        else if (selectedOption.length === this.state.countryList.length - 1) {
            this.setState({ selectedCountry : this.state.countryList}) 
        }
        else{
            var selectedOpt = [];
            selectedOption.map(function (item) {
                if(item.value !== "All" && item.value !== "None"){
                    valueOptions.push(item.value);
                    selectedOpt.push(item);
                }
            })      
            selectedOpt.push({ "value": "None", "label": I18n.t('standardMessages.unSelectAll') });    
            this.setState({ selectedCountry: selectedOpt})           
        } 
        const activityDto = { ...this.state.activityDto };
        activityDto["countryList"] = valueOptions;
        this.setState({ activityDto });
        if(event.action === "select-option" && event.option.value === "None"){}else{
            this.reloadDropDowns(activityDto);
        }
    }

    onRegionChange = (selectedOption) => {
        this.setSelectedValue("regionList", "selectedRegion", selectedOption, true);
    }

    reloadDropDowns = (activityDto) => {
        // console.log(activityDto)
        this.props.dispatch(fetchFilters(activityDto, this.props.userMdl.activeProgram));

        //this.setState({activityDto:activityDto})

    }

    refresh = (e) => {
        // console.log(e)
        // e.preventDefault();

        let activeProgram = this.props.userMdl.activeProgram;

        let filterData = {
            selectedNmsc: this.state.selectedNmsc,
            selectedBrand: this.state.selectedBrand,
            selectedCountry: this.state.selectedCountry,
            selectedRegion: this.state.selectedRegion
        };
        this.setState({ filterData: filterData })
        this.props.history.push('/redirect', { 'filterData': filterData });
        // console.log(this.state.activityDto)
        this.props.dispatch(filtersDashboard(filterData, activeProgram, this.state.activityDto))


    }
    render() {
        // console.log(this.props.flag)


        return (
            <div className="col-md-12" style={{ padding: "0px" }}>
                {this.props.nmscFlag ?
                    <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchDash">
                        <div className="col-md-12 row control-label">
                            <label className="col-md-3 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}></label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('Table.nmsc')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.1rem', width: '6%', overflowWrap: 'normal', fontWeight: '400' }}>{I18n.t('Table.franchise')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.2rem', fontWeight: '400' }}>{I18n.t('Table.country')}</label>
                            <label className="col-md-2 control-label" style={{ paddingLeft: '0.1rem', fontWeight: '400' }}>{I18n.t('dealerCertification.region')}</label>

                        </div>
                        <div className="col-md-12 input-group control-label">
                            <div className={"col-md-3 noPadding bigDD-" + this.props.theme.name}>
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>

                                <div className="disabledNmsc"><Select
                                    placeholder={this.props.userMdl.nmsc.toUpperCase()}
                                    isDisabled={true}
                                /> </div>
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedBrand}
                                    isSearchable={true}
                                    options={this.state.brandList}
                                    onChange={this.onBrandChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')} />
                            </div>
                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedCountry}
                                    options={this.state.countryList}
                                    isSearchable={true}
                                    onChange={this.onCountryChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            </div>

                            <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                <ReactMultiSelectCheckboxes
                                    value={this.state.selectedRegion}
                                    isSearchable={true}
                                    options={this.state.regionList}
                                    onChange={this.onRegionChange}
                                    placeholderButtonLabel={I18n.t('standardMessages.all')}
                                />
                            </div>
                            <div className="col-md-1" style={{ padding: '5px' }}>
                                <button className={"button-" + this.props.theme.name}
                                    onClick={(e) => this.refresh(e)}>
                                    {I18n.t('dashboard.go')}
                                </button>
                            </div>
                        </div>
                    </div>
                    : <div id="searchId" style={{ display: "none", borderColor: this.props.theme.primaryColor }} className="col-md-12 searchDash">
                        <div>
                            <div className="col-md-12 row control-label">
                                <label className="col-md-5 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}></label>
                                <label className="col-md-2 control-label" style={{ paddingLeft: '0rem', fontWeight: '400' }}>{I18n.t('Table.nmsc')}</label>
                                <label className="col-md-2 control-label" style={{ paddingLeft: '0.1rem', width: '6%', overflowWrap: 'normal', fontWeight: '400' }}>{I18n.t('Table.franchise')}</label>
                                <label className="col-md-2 control-label" style={{ paddingLeft: '0.2rem', fontWeight: '400' }}>{I18n.t('Table.country')}</label>
                            </div>
                            <div className="col-md-12 input-group control-label">
                                <div className={"col-md-5 noPadding bigDD-" + this.props.theme.name}>
                                </div>
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>

                                    <ReactMultiSelectCheckboxes
                                        value={this.state.selectedNmsc}
                                        isSearchable={true}
                                        options={this.state.nmscList}
                                        onChange={this.onNmscChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}

                                    />
                                </div>
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    <ReactMultiSelectCheckboxes
                                        value={this.state.selectedBrand}
                                        isSearchable={true}
                                        options={this.state.brandList}
                                        onChange={this.onBrandChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')} />
                                </div>
                                <div className={"col-md-2 noPadding bigDD-" + this.props.theme.name}>
                                    {this.state.selectedNmsc.length > 0 ? <ReactMultiSelectCheckboxes
                                        value={this.state.selectedCountry}
                                        isSearchable={true}
                                        options={this.state.countryList}
                                        onChange={this.onCountryChange}
                                        placeholderButtonLabel={I18n.t('standardMessages.all')}
                                    /> : <div className="disabledNmsc"><Select
                                        placeholder={I18n.t('standardMessages.all')}
                                        isDisabled={true}
                                    /> </div>}
                                </div>
                                <div className="col-md-1" style={{ padding: '5px' }}>
                                    <button className={"button-" + this.props.theme.name}
                                        onClick={(e) => this.refresh(e)}>
                                        {I18n.t('dashboard.go')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>}

                <div id="hrId" className="col-md-12 hrClass">
                    <hr style={{ margin: '0px', borderTop: "1px solid" }} />
                </div>
            </div>

        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        router: state.routing,

        Tsmfilters: state.dashboardReducer.Tsmfilters,
        Bnpfilters: state.dashboardReducer.Bnpfilters,
        Usedcarfilters: state.dashboardReducer.Usedcarfilters,
        tmeSurveysfilters: state.dashboardReducer.tmeSurveysfilters,
        filters: state.dashboardReducer.filters,
        TsmActivityDto: state.dashboardReducer.TsmActivityDto,
        BnpActivityDto: state.dashboardReducer.BnpActivityDto,
        UsedcarActivityDto: state.dashboardReducer.UsedcarActivityDto,
        tmeSurveysActivityDto: state.dashboardReducer.tmeSurveysActivityDto,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(SearchFilter)));