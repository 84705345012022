// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  /* display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center; */
  align-items: center;

  /* padding-top: 7%; */
  /* padding-bottom: 40px; */
  /* background-color: #f5f5f5; */
  font-size: 15px;
  /* font-weight: 600; */
}
.loginForm {
  font-weight: 600;
  padding-top: 7%;
}
.form-signin {
  width: 100%;
  max-width: 450px;
  padding: 10px;
  margin: auto;
  border: 1px solid black;
  border-radius: 2%;
  background-color: rgb(236, 232, 232);
}

.label{
    margin-bottom: 0px;
}

.centerAlign {
    padding-top: 0.5%;
    text-align: center;
}

.multiple-roles .css-1r4vtzz,.multiple-roles .css-48ayfv{
  width:100% !important ;
}

.multiple-roles .css-10nd86i{
  width:19rem !important ;
}

`, "",{"version":3,"sources":["webpack://./src/components/login/components/index.css"],"names":[],"mappings":"AAAA;EACE;;2BAEyB;EACzB,mBAAmB;;EAEnB,qBAAqB;EACrB,0BAA0B;EAC1B,+BAA+B;EAC/B,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,eAAe;AACjB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,aAAa;EACb,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;EACjB,oCAAoC;AACtC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".container {\n  /* display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center; */\n  align-items: center;\n\n  /* padding-top: 7%; */\n  /* padding-bottom: 40px; */\n  /* background-color: #f5f5f5; */\n  font-size: 15px;\n  /* font-weight: 600; */\n}\n.loginForm {\n  font-weight: 600;\n  padding-top: 7%;\n}\n.form-signin {\n  width: 100%;\n  max-width: 450px;\n  padding: 10px;\n  margin: auto;\n  border: 1px solid black;\n  border-radius: 2%;\n  background-color: rgb(236, 232, 232);\n}\n\n.label{\n    margin-bottom: 0px;\n}\n\n.centerAlign {\n    padding-top: 0.5%;\n    text-align: center;\n}\n\n.multiple-roles .css-1r4vtzz,.multiple-roles .css-48ayfv{\n  width:100% !important ;\n}\n\n.multiple-roles .css-10nd86i{\n  width:19rem !important ;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
