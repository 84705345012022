import React from "react";
import { connect } from "react-redux";
import { Container, Row } from "mdbreact";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from 'react-bootstrap-table2-paginator';
import moment from 'moment';
import { fetchCopyKpiHistory } from "../../../actions/ceaNetKPIAction";
import { withTheme } from "../../../themes/theming";
import "./../index.css";
var I18n = require("react-redux-i18n").I18n;

class CopyKpiHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sizePerPage: 10,
            page: 1
        };
    }

    componentDidMount() {
        
        let page = this.state.page;
        let sizePerPage = this.state.sizePerPage;
        let programID = this.props.user.userMdl.activeProgramID;

        this.props.dispatch(fetchCopyKpiHistory(programID, page, sizePerPage));
    }

    componentWillReceiveProps(nextProps) {
    }

    handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {

        let programID = this.props.user.userMdl.activeProgramID;

        this.props.dispatch(fetchCopyKpiHistory(programID, page, sizePerPage));
        this.setState({ page, sizePerPage });
    }

    dateFormatterUploaded = (columns, row) => {
        return moment(row.createdTimestamp).format("DD/MM/YYYY hh:mm:ss A")
    }

    dateFormatterUpdated = (columns, row) => {
        return moment(row.updatedTimestamp).format("DD/MM/YYYY hh:mm:ss A")
    }

    render() {

        let data = this.props.data && this.props.data.dataList && this.props.data.dataList.length > 0 ? this.props.data.dataList : [];
        let page = this.state.page;
        let sizePerPage = this.state.sizePerPage;
        let totalSize = this.props.data ? this.props.data.recordCount : 10;

        console.log(page + ":" + sizePerPage + ":" + totalSize)
        const columns = [

            {
                dataField: "id",
                text: I18n.t('Execution id'),
                style: { width: "13%", textAlign: "left" },
                headerStyle: { width: "12.8%", textAlign: "left" },
                title: true
            },
            {
                dataField: "kpiCount",
                text: I18n.t('No of KPI copied'),
                style: { width: "21%", textAlign: "left" },
                headerStyle: { width: "20.9%", textAlign: "left" },
                title: true
            },
            {
                dataField: "fromYear",
                text: I18n.t('From Year'),
                style: { width: "14%", textAlign: "center" },
                headerStyle: { width: "13.8%", textAlign: "center" },
                title: true,
            },
            {
                dataField: "toYear",
                text: I18n.t('To Year'),
                style: { width: "15%" },
                headerStyle: { width: "14.9%" },
                title: true,
            },
            {
                dataField: "actionBy",
                text: I18n.t('Action By'),
                style: { width: "16%" },
                headerStyle: { width: "15.9%" },
                title: true,
            }
        ];

        const RemotePagination = ({
            data,
            page,
            sizePerPage,
            onTableChange,
            totalSize
        }) => (
            <div className={'historyTable parent-' + this.props.theme.name}>
                <BootstrapTable
                    remote
                    keyField="id"
                    noDataIndication={I18n.t('No data to view')}
                    data={data}
                    columns={columns}
                    pagination={paginationFactory({ page, sizePerPage, totalSize })}
                    onTableChange={onTableChange}
                />
            </div>
        );
        return (

            <Container
                fluid
                className="ml-0 mb-2 pb-0"
                style={{ border: "1px solid grey", borderRadius: "10px" }}>

                <Row>
                    <span className="sectionLabel">Copy KPI History</span>
                </Row>
                <div className="col-md-12 searchHistoryTable">
                    <RemotePagination
                        data={data}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={totalSize}
                        onTableChange={this.handleTableChange}
                    />
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
    };
};
export default withRouter(
    connect(mapStateToProps)(withTheme(CopyKpiHistory))
);