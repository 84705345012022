import React from 'react';
import {
    Modal,
    ModalBody,
    ModalHeader
} from 'mdbreact';
import { withTheme } from '../../themes/theming';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css';
import "moment-timezone";
import BootstrapTable from 'react-bootstrap-table-next';
var I18n = require('react-redux-i18n').I18n;

class QuestionInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }
    preventDefault() { }
    render() {

        const columns = [{
            dataField: 'ques',
            text: "",
            sort: true,
            style: { textAlign: "left" ,width:"6rem"},
            headerStyle: { textAlign: "left", paddingLeft: "0px" ,width:"6rem"}
        }, {
            dataField: 'info',
            text:"",
            style: {  textAlign: "left", wordWrap: "break-word" ,width:"22rem"},
            headerStyle: {  textAlign: "left" ,width:"22rem"}
        }
    ]
        var content=this.props.modalFor
        return (
            <Modal className={"InfoClass quesInfoModal-" + this.props.theme.name} isOpen="true" toggle={() => this.preventDefault()} ref="download">
                <ModalHeader toggle={() => this.props.toggle(this.props.modalFor)}>{this.props.user.userMdl.flagCEA ? I18n.t('ceaActionPlan.questionReference'): I18n.t("Table.questionReference")} </ModalHeader>
                <ModalBody>
                    <form className="form-horizontal">
                    <div className="col-md-12 questionInfoTable">
                        <BootstrapTable
                            keyField='actionPlanId'
                            data={content}
                            columns={columns}
                            hover
                            condensed
                        />
                    </div>                    </form>
                </ModalBody>
            </Modal >

        );
    }
}
const mapStateToProps = (state) => {
    return {
        user: state.loginReducer,
    }
}
export default withRouter(connect(mapStateToProps)(withTheme(QuestionInfo)));