import { MDBContainer } from 'mdbreact';
import React from "react";
import 'react-multi-carousel/lib/styles.css';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { withTheme } from '../../themes/theming';
import './index.css';
import SearchFilter from './searchFilter';
import WidgetContainer from './widgetContainer';
import DealerWidgetContainer from './dealerWidgetContainer';
import BritDashboard from './britDashboard';
import { Fa } from 'mdbreact';
import BootstrapTable from 'react-bootstrap-table-next';
import { getBritVideoList, getDownloadPreSignedUrlForBritVideo, playBritVideoList } from '../../actions/libraryAction';
var I18n = require("react-redux-i18n").I18n;

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSearchFlag: false,
            selectedNmsc: [],
            selectedBrand: [],
            selectedCountry: [],
            selectedRegion: [],
            fileData: [],
            videoLink: null,
            britVideoList: [],
            video: [],
            playBritVideo: false,
        };
    }

    refresh(data) {
        // refreshWidgets(data) {

        console.log("Refresh Widgets!!!");
        // let filterData = {
        //     selectedNmsc: data.selectedNmsc,
        //     selectedBrand: data.selectedBrand,
        //     selectedCountry: data.selectedCountry,
        //     selectedRegion: data.selectedRegion
        // };
        this.props.history.push('/redirect', { 'filterData': this.props.filtersDashboard });
    }
    onSuccessGetBritVideoList=(response)=>{
        if (response && Array.isArray(response)) {
            const fileData = this.convertToFileDataFormat(response);
            console.log('britVideoList',response);
            this.setState({ fileData });
        } else {
            console.log('britVideoList is undefined or not an array',response);
        }
    }
    onFailureGetBritVideoList=(error)=>{
        console.log("Error while fetching list of video",error)
    }
    componentDidMount() {
        // Get call for fetching Brti video list
        this.props.dispatch(getBritVideoList(this.onSuccessGetBritVideoList,this.onFailureGetBritVideoList));
    }

    componentWillUpdate(nextProps) {
        /* if(nextProps.user.userMdl && nextProps.user.userMdl.activeProgram){
            this.setState({userMdl: nextProps.user.userMdl});
        } */
        // alert("Refresh Widgets");
    }
    toggleSearch = toggle => {
        this.setState({
            showSearchFlag: !this.state.showSearchFlag
        });
    };
    onSuccessGetSignedUrl=(response)=>{
        console.log("preSignedurl",response)
        this.setState({videoLink:response});
    }
    onFailureGetSignedUrl=(error)=>{
        console.log("Error while collecting preSignedurl",error)
    }
    playVideo = (row) => {
        if (!row.fileName) {
            console.error("fileName is missing in row", row);
            return;
        }
        const config = {"fileList": [{"name": row.fileName}],
            "userId": this.props?.user?.userMdl?.username,
        }
        
        /** POST api for play Brit videos */
        this.props.dispatch(playBritVideoList(config,this.onSuccessGetSignedUrl,this.onFailureGetSignedUrl));
        /** */
        console.log("inside playVideo function")
        console.log("row details--", row.fileId, row.fileName, row.filePath)
        if (row.fileId) {
            console.log(row.fileId)
            this.setState({ video: row }, this.onCallback)

        }

    }
    downloadVideo = (row) => {
        console.log("Inside download video function");
        console.log("row details--", row.fileId, row.fileName, row.filePath)
        let fileDetail = {
            fileName: row.fileName,
            filePath: row.filePath,
        }
        this.props.dispatch(getDownloadPreSignedUrlForBritVideo(fileDetail));
    }

    onCallback = () => {
        console.log("Inside onCallback")
        this.setState({
            playBritVideo: !this.state.playBritVideo
        })
    }
    infoFormatter = (cell, row) => {
        // let linkIcon = 'eye';
        // const pack = <span>&nbsp;&nbsp;</span>
        return (
            <div className="btn-toolbar float-right" style={{ cursor: "pointer" }}>
                <Fa icon="play" fixed size="lg" title={I18n.t("britDashboard.playVideo")} onClick={() => this.playVideo(row)} >
                </Fa>
                {/* <Fa icon="download" fixed size="lg" title={I18n.t("britDashboard.download")} onClick={() => this.downloadVideo(row)}></Fa> */}


            </div >
        );
    }
    /** Function to convert file name into required path */
    convertToFileDataFormat(fileNames) {
        if (!fileNames || fileNames.length === 0) {
            return [];  // Return an empty array if fileNames is undefined or empty
        }

        return fileNames.map((fileName, index) => {
            return {
                fileId: index + 1,
                fileName: fileName,
                filePath: `videos/${fileName}`,
            };
        });
    }
    fileNameFormator=(cell,row)=>{
        let labelDisplay=row && row.fileName?row.fileName.replace(/_/g, ' ').replace('.mp4', '') + ".mp4":"";
        return <span className='fileNameForVideo'>{labelDisplay}</span>
    }
    britVideo = () => {
        const columns = [
            {
                dataField: "fileId",
                text: "id",
                title: true,
                hidden: true,
                style: {
                    width: "10%", textAlign: "left", wordWrap: "break-word", paddingLeft: "12px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                headerStyle: { width: "12%", textAlign: "left", paddingTop: "1rem" },
            },
            {
                dataField: "fileName",
                text: "Video",
                title: true,
                style: {
                    width: "12%", textAlign: "left", wordWrap: "break-word", paddingLeft: "12px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellisis"
                },
                headerStyle: { width: "12%", textAlign: "left", paddingTop: "1rem" },
                formatter:this.fileNameFormator
            },
            {
                dataField: "",
                text: "",
                title: true,
                style: { width: "5%", textAlign: "center", wordWrap: "break-word", },
                formatter: this.infoFormatter,
                headerStyle: {
                    width: "5%",
                    textAlign: "center",
                    wordWrap: "break-word", paddingTop: "1rem"
                }
            }
        ]
        const { fileData } = this.state;

        return (
            <React.Fragment>
                <div className="britVideoTable">
                    <div className="col-md-12 row control-label noPadding britVideoLabel">
                        <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                            {I18n.t("britDashboard.trainingVideos")}
                        </label>

                    </div>
                    <BootstrapTable

                        keyField='fileId'
                        data={fileData}
                        columns={columns}
                        hover
                        condensed
                        noDataIndication={I18n.t('britDashboard.noVideo')}
                    />
                </div>
            </React.Fragment>
        )
    }
    render() {
        let nmscList = [];
        let filtersDashboard = []
        if (this.props.user.userMdl.activeProgram === 'TSM') {
            filtersDashboard = this.props.Tsmfilters
        }
        else if (this.props.user.userMdl.activeProgram === 'TSM_BNP') {
            filtersDashboard = this.props.Bnpfilters

        } else if (this.props.user.userMdl.activeProgram === 'TSM_USED_CAR') {
            filtersDashboard = this.props.Usedcarfilters

        } else if (this.props.user.userMdl.activeProgram === 'TSM_SURVEYS') {
            filtersDashboard = this.props.tmeSurveysfilters

        }

        if (filtersDashboard.selectedNmsc && filtersDashboard.selectedNmsc.length > 0) {
            filtersDashboard.selectedNmsc.map((item) => {
                nmscList.push(item.value);
                return null;
            });
        }
        let brandList = [];
        if (filtersDashboard.selectedBrand && filtersDashboard.selectedBrand.length > 0) {
            filtersDashboard.selectedBrand.map((item) => {
                brandList.push(item.value);
                return null;
            });
        }
        let countryList = [];
        if (filtersDashboard.selectedCountry && filtersDashboard.selectedCountry.length > 0) {
            filtersDashboard.selectedCountry.map((item) => {
                countryList.push(item.value);
                return null;
            });
        }
        let regionList = [];
        if (filtersDashboard.selectedRegion && filtersDashboard.selectedRegion.length > 0) {
            filtersDashboard.selectedRegion.map((item) => {
                regionList.push(item.value);
                return null;
            });
        }

        var flagNmsc = false;

        //Check for length undefined
        var length;
        if (this.props.user.userMdl && this.props.user.userMdl.roles && this.props.user.userMdl.roles.length) {
            length = this.props.user.userMdl.roles.length;
        }
        for (let i = 0; i < length; i++) {
            if (this.props.user.userMdl.roles[i] === "KET NMSC Field" || this.props.user.userMdl.roles[i] === "KET NMSC External" ||
                this.props.user.userMdl.roles[i] === "KET NMSC User" || this.props.user.userMdl.roles[i] === "KET NMSC Admin") {
                flagNmsc = true;
            }
        }
        const nmscFlag = length > 0 && flagNmsc;

        let ifCEA = false;
        let ifNET = false;
        let ifUC = false;
        if (this.props && this.props.user && this.props.user.userMdl && this.props.user.userMdl.activeProgram) {
            let regex = new RegExp(/^CEA/)
            let regexNet = new RegExp(/^NET/)
            let regexUc = new RegExp(/^UC/)
            ifCEA = regex.test(this.props.user.userMdl.activeProgram.toUpperCase())
            ifNET = regexNet.test(this.props.user.userMdl.activeProgram.toUpperCase())
            ifUC = regexUc.test(this.props.user.userMdl.activeProgram.toUpperCase())
        }
        if (length > 0
            && ((this.props.user.userMdl.roles.filter(r => r === "KET TME Admin" || r === "KET TME User" || r === "TSM KET User").length > 0 ||
                nmscFlag) && this.props.user.userMdl.activeProgram.toUpperCase() !== 'BRIT' && !ifCEA && !ifNET && !ifUC)) {
            return (
                <MDBContainer className="mt-5 b-1" style={{ maxWidth: '100%', marginBottom: '3rem' }}>
                    {/* <SearchFilter filterProps={this.props.filterData} refreshWidgets={() => this.refreshWidgets()} /> */}
                    <div className="col-md-12 row control-label noPadding dashboardSearch">
                        <label md="0.5" className={'control-label ' + this.props.theme.name + '-FontWithBold'}>
                            {I18n.t("tmeAdminMaster.search")}
                        </label>
                        <label
                            md="0.5"
                            className="control-label boldFont"
                            style={{ marginTop: "0.2rem" }}
                        >
                            <a onClick={this.toggleSearch}>
                                {this.state.showSearchFlag ? (
                                    <div title={I18n.t("tmeAdminMaster.hide")} className={this.props.theme.name + "-hide-icon"} />
                                ) : (
                                    <div title={I18n.t("tmeAdminMaster.show")} className={this.props.theme.name + "-show-icon"} />
                                )}
                            </a>
                        </label>
                        {/* <label className="control-label" style={{ width: "92%" }} /> */}
                        <SearchFilter flag={this.state.showSearchFlag} filterProps={filtersDashboard} nmscFlag={nmscFlag} refreshWidgets={() => this.refreshWidgets()} userMdl={this.props.user.userMdl} />
                    </div>

                    <WidgetContainer
                        userMdl={this.props.user.userMdl}
                        nmscFilter={nmscList}
                        brandFilter={brandList}
                        countryFilter={countryList}
                        regionFilter={regionList}
                        nmscFlag={nmscFlag} />
                </MDBContainer >

            );
        } else if (length > 0
            && ((this.props.user.userMdl.roles.filter(r => r === "KET Dealer Champion" || r === "KET Dealer Internal").length > 0
            ) && !nmscFlag && this.props.user.userMdl.activeProgram.toUpperCase() !== 'BRIT')) {

            return (
                <MDBContainer className="mt-5 b-1" style={{ maxWidth: '100%', marginBottom: '3rem' }}>
                    <div className="col-md-12  control-label noPadding dealerDashboard">
                        <DealerWidgetContainer
                            userMdl={this.props.user.userMdl}
                            nmscFilter={nmscList}
                            brandFilter={brandList}
                            countryFilter={countryList}
                            regionFilter={regionList} />
                    </div>
                </MDBContainer >

            );
        }
        else if (length > 0 && this.props.user.userMdl.activeProgram.toUpperCase() === 'BRIT' && this.props.user.userMdl.flagBRiT) {
            // console.log("Brit program")
            // console.log(this.state)
            return (
                <div className="britDashboardTable">
                    {this.state.playBritVideo ?
                    <BritDashboard className="britVideoPlayer" onToggle={this.onCallback}
                        video={this.state.videoLink}
                        width="720"
                        height="420"
                    /> : this.britVideo()}
                </div>
            )
        }
        else {
            return (
                <div>

                </div>
            )
        }
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        blocking: state.loginReducer,
        router: state.routing,
        Tsmfilters: state.dashboardReducer.Tsmfilters,
        Bnpfilters: state.dashboardReducer.Bnpfilters,
        Usedcarfilters: state.dashboardReducer.Usedcarfilters,
        tmeSurveysfilters: state.dashboardReducer.tmeSurveysfilters,
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(Dashboard)));
