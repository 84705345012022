import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { MDBCol, MDBRow } from 'mdbreact';
import LoadingOverlay from 'react-loading-overlay';
import '../index.css';
import { withTheme } from "../../../themes/theming";
import { fetchScrollableWidgetData } from '../../../actions/dashboardAction';
var I18n = require("react-redux-i18n").I18n;
class OverdueActivitiesForNMSC extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true,
            data: {}
        };
    }
    componentWillMount() {
        let activeProgram = this.props.user.userMdl.activeProgram;
        let nmscCode = this.props.user.userMdl.nmsc;
        let langCode = this.props.user.userMdl.lang;
        // Fetch OverdueActivities.
        let queryDto = {
            userNmscCode: nmscCode,
            userLangCode: langCode,
            programList: [activeProgram],
            nmscCode:this.props.nmscFlag ? [nmscCode.toUpperCase()] : this.props.nmscFilter,
            brand: this.props.brandFilter,
            country: this.props.countryFilter,
            region: this.props.regionFilter,
            nmscFlag : true,
            userId:this.props.userMdl.username,
            businessArea: this.props.userMdl.businessAreaKey
        };
        this.props.dispatch(fetchScrollableWidgetData("overDueReport", "WIDGET_OVERDUE_ACTIVITIES_REPORT", queryDto));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.dashboardReducer && nextProps.dashboardReducer.overdueActivitiesReport
            && this.state.data && this.state.data.value !== nextProps.dashboardReducer.overdueActivities.value) {
            this.setState({ data: nextProps.dashboardReducer.overdueActivitiesReport, isLoading: false });
        }
    }
    
    render() {
        return (
            <div style={{ width: "100%" }}>
                <div className={"widget-header left-align-header widget-header-" + this.props.theme.name} title={I18n.t('dashboard.overDueAct')}>
                {I18n.t('dashboard.overDueAct')}
                </div>
                <LoadingOverlay active={this.state.isLoading} spinner text='Loading...'>
                    <div className="medium-widget-content ml-1 mr-1">
                        {this.state.data.length > 0 ?
                            <div>
                                {this.state.data.map((rollOut, index) => {
                                    return (
                                        <MDBRow key={index} className="br-table-row pl-0 report-bottom-border" style={{height:"25px"}}>
                                            <MDBCol md="6" lg="6" className="text-style-Report" title={rollOut.dealerName}>{rollOut.dealerName}</MDBCol>
                                            <MDBCol md="3" lg="3" className="text-style-Report" title={rollOut.toolboxName} >
                                                {rollOut.toolboxName}
                                            </MDBCol>
                                            <MDBCol md="3" lg="3" className="text-style-Report" >
                                                {rollOut.completionDateStr}
                                            </MDBCol>
                                        </MDBRow>);
                                })}
                            </div>
                            : <div></div>}
                    </div>
                </LoadingOverlay>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.loginReducer,
        dashboardReducer: state.dashboardReducer,
        router: state.routing
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(OverdueActivitiesForNMSC)));