import React from "react";
import { Modal, ModalBody, ModalHeader } from "mdbreact";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTheme } from "../../themes/theming";
import { toastr } from "react-redux-toastr";
import './index.css';
import { getCurrentMonthLatestComment } from "../../actions/bulkCeaKpiEntryAction";
var I18n = require("react-redux-i18n").I18n;

class addMonthlyComments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: '',
            monthName: ''
        }
    }
    componentWillMount() {
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const d = new Date();
        this.setState({ monthName:month[d.getMonth()-1]});
        this.props.dispatch(getCurrentMonthLatestComment(this.props.activityId,d.getMonth() + 1));
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.kpiCurrentMonthComment) {
            this.setState({ comment: nextProps.kpiCurrentMonthComment.comment })
        }
    } 
    onCommentsChange = (e) => {
        this.setState({ comment: e.target.value });
    }
    submitComment = (e) => {
        e.preventDefault();
        if (!this.state.comment || this.state.comment === "") {
            toastr.error(I18n.t("evaluation.commentMsg"));
        }else{
            this.props.submitComment(this.state.comment);
        }
    }

    preventDefault() { }
    render() {        
        return (
            <Modal centered
                className={"addMonthComments uploadModal-" + this.props.theme.name}
                isOpen={true} style={{ maxWidth: '30%!important' }}
                toggle={() => this.preventDefault()} >
                <ModalHeader toggle={() => this.props.toggleCommentsCancel()}>
                    {I18n.t("usedCarComment.addComment")} - {this.state.monthName}
                </ModalHeader>
                <ModalBody>
                    <form className="form-horizontal">
                        <label className="col-md-12 control-label">{I18n.t("usedCarComment.comment")} <span className="redFont">*</span></label>
                        <div className="col-md-12">
                            <textarea className="form-control" rows="3" maxLength="2000" onChange={this.onCommentsChange} value={this.state.comment}></textarea>
                        </div>                        
                        <button style={{ marginTop: '50px', float:'right' }}
                            className={"button-" + this.props.theme.name}
                            onClick={this.submitComment}
                        >
                            {I18n.t("evaluation.submit")}
                        </button>
                    </form>
                </ModalBody>
            </Modal >
        );
    }
}
const mapStateToProps = state => {
    return {
        kpiCurrentMonthComment: state.ceaKpiEntryReducer.kpiCurrentMonthComment,  
    };
};
export default withRouter(connect(mapStateToProps)(withTheme(addMonthlyComments)));

